import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import Close from '../../icons/Close';
import * as lowerPriorityAttendsActions from '../../actions/lowerPriorityAttendsActions';
import * as attendsActions from '../../actions/attendsActions';
import * as dictionary from '../../BLL/dictionary';
import '../../css/notification.scss';

const ModalNotification = (props) => {
  let clientName = '';
  const { lowerPriorityAttends, loggedAttendant  } = useSelector(state => ({
    lowerPriorityAttends: state.lowerAlarms,
    loggedAttendant: state.loggedAttendant
  }));

  const dispatch = useDispatch();

  const removeLowerAlarm = (index) => dispatch(lowerPriorityAttendsActions.removeLowerAlarm(index));
  const editAttend = (attend) => dispatch(attendsActions.editAttend(attend));

  const refIntervalId = useRef();
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const carousel = (carouselLength) => {
      if (counter < carouselLength -1) {
        setCounter(counter + 1);
      } else {
        setCounter(0);
      }
    }

    if (lowerPriorityAttends.length > 0 && lowerPriorityAttends[0].alarmEvent.length > 1) {
      const interval = setInterval(() => carousel(lowerPriorityAttends[0].alarmEvent.length), 1000)
      refIntervalId.current = interval;
    } else {
      refIntervalId.current = 0;
    }
  }, [lowerPriorityAttends, counter]);

  const saveAttend = () => {
    const attend = Object.assign(
      {},
      lowerPriorityAttends[0].attend,
      { attend_ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS') },
      { attendant: loggedAttendant.id },
      { status: 'CLOSE' }
    );
    editAttend(attend);
  };

  const path = window.location.pathname;
  let showModal = lowerPriorityAttends.length > 0;
  showModal = (path === '/app' || path === '/app/attends') ? false : showModal;
  const alarm = lowerPriorityAttends[0];
  if (alarm?.attend) {
    clientName = alarm.attend.is_from_secondary
      ? `${alarm.attend.secondary_first_name} ${alarm.attend.secondary_last_name}`
      : `${alarm.attend.client_first_name} ${alarm.attend.client_last_name}`;
  }

  return (
    <div className={showModal ? 'notification-wrapper no-print' : 'notification-wrapper notification-hide no-print'}>
      <Close className="list-table-body-action-icons right clickable" width="28" height="28" onClick={() => removeLowerAlarm(0)} />
      <div className="notification-text-container">
        <div className="notification-text-alarm">
          {showModal && alarm.alarmEvent.length > 1
            ? alarm.alarmEvent.map((alarm, index) =>
            <div key={alarm.id} className={index === counter ? 'notification-text-alarm-item' : 'notification-text-alarm-item opacity-0'}>
              { showModal ? `${dictionary.alarmNameLang(alarm.event_type)} - ${alarm.tr_description}` : '' }
            </div>
            )
            :
            <div className="notification-text-alarm-item">
              { showModal ? `${dictionary.alarmNameLang(alarm.alarmEvent[0].event_type)} - ${alarm.alarmEvent[0].tr_description}` : '' }
            </div>
          }
        </div>
        <div className="notification-text-client">
          {showModal ? clientName : ''}
        </div>
        <div className="notification-button-wrapper">
          <button type="button" onClick={() => saveAttend()} className="btn btn--attend-emergency btn--color-tecno">
            <FormattedMessage id="modal.notification.solveAttend" defaultMessage="Fechar Atendimento" />
          </button>
        </div>
      </div>
    </div>
  )
};

ModalNotification.propTypes = {
  channelId: PropTypes.string.isRequired,
};

export default ModalNotification;
