import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import style from './attendReminders.module.scss';
import '../../../../css/attendComponent.scss';

const AttendReminders = ({ reminders, reminderAudioList }) => (
  <div className={style['reminder-wrapper']}>
    {reminders.length > 0 && reminders.map((reminder, index) => {
      const singleReminderAudioList = reminderAudioList.length > 0 
        ? reminderAudioList.filter((audio) => {
          const file = audio.audio_file;
          const reminderId = parseInt(file.split('-')[file.split('-').length - 1].split('.')[0], 10);
          if (reminderId === reminder.id) {
            return audio;
          }
        })
        : [];
      return (
      <div key={reminder.id} className="col-sm-12 col-md-8 col-lg-8" style={{ float: 'left', marginBottom: '15px' }}>
        <div className="component-header component-left" />
        <span className="component-header component-right" >
          <FormattedMessage
            id="attend.reminder.cardHeader"
            defaultMessage="LEMBRETE NÚMERO {reminderNumber}"
            values={{
              reminderNumber: index + 1,
            }}
          />
        </span>
        <div className="component-row">
          <span className="component-label"><FormattedMessage id="attend.reminder.openAttendant" defaultMessage="Solicitado por" /></span>
          <span className="component-text">{reminder.op_full_name}</span>
        </div>
        {reminder.status === 'CLOSED' &&
          <div className="component-row">
            <span className="component-label"><FormattedMessage id="attend.reminder.closeAttendant" defaultMessage="Fechado por" /></span>
            <span className="component-text">{reminder.cl_full_name}</span>
          </div>
        }
        <div className="component-row">
          <span className="component-label"><FormattedMessage id="attend.reminder.title" defaultMessage="Lembrete" /></span>
          <span className="component-text">{reminder.title}</span>
        </div>
        <div className="component-row">
          <span className="component-label"><FormattedMessage id="attend.reminder.opening" defaultMessage="Abertura" /></span>
          <span className="component-text">{moment(reminder.open_ts).format('HH:mm:ss - DD/MM/YYYY')}</span>
        </div>
        {singleReminderAudioList.map((elm, index) => (
          <div className="component-row">
            <span className="component-label">
              <FormattedMessage id="attend.reminder.returnHour" defaultMessage="Hora de retorno" /> - {index + 1}
            </span>
            <span className="component-text">{moment(elm.audio_ts).format('HH:mm:ss - DD/MM/YYYY')}</span>
            <a className={style['reminder-btn-download']} style={{ color: 'white', padding: '5px' }} href={elm.audio_url}>
              <i className="fas fa-download" />
            </a>
          </div>
        ))}
        {reminder.status === 'CLOSED' &&
          <div className="component-row">
            <span className="component-label"><FormattedMessage id="attend.reminder.close" defaultMessage="Fechamento" /></span>
            <span className="component-text">{moment(reminder.close_ts).format('HH:mm:ss - DD/MM/YYYY')}</span>
          </div>
        }
        <div className="component-row">
          <span className="component-label"><FormattedMessage id="attend.reminder.requestedTime" defaultMessage="Tempo Solicitado" /></span>
          <span className={`component-text ${style['reminder-minutes']}`}>{reminder.requested_time}</span> <FormattedMessage id="attend.reminder.minutes" defaultMessage="Minutos" />
        </div>
        {reminder.status === 'CLOSED'
        ?
          <div className="component-row">
            <span className="component-label"><FormattedMessage id="attend.reminder.report" defaultMessage="Relatório" /></span>
            <span className="component-text">{reminder.report}</span>
          </div>
        :
          <div className="component-row">
            <span className="component-label">STATUS</span>
            <span className="component-text">Ainda em andamento</span>
          </div>
        }
      </div>
    )}
    )}
    {reminders.length === 0 &&
      <div className={style.noReminder}>
        <FormattedMessage id="attend.detail.noReminders" defaultMessage="Sem lembretes" />
      </div>
    }
  </div>
);

AttendReminders.defaultProps = {
  reminders: [],
};

AttendReminders.propTypes = {
  reminders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      op_full_name: PropTypes.string,
      cl_full_name: PropTypes.string,
      title: PropTypes.string,
      open_ts: PropTypes.string,
      close_ts: PropTypes.string,
      requested_time: PropTypes.number,
      report: PropTypes.string,
    }).isRequired,
  ),
};

export default AttendReminders;
