import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Edit from '../../../../icons/Edit';
import { alarmNameLang, actionPriority } from '../../../../BLL/dictionary';

import '../../../../css/row.scss';
import style from './alarmManagement.module.scss';
import styleManagement from '../management.module.scss';

const AlarmRow = ({
    isAdmin,
    isOwner,
    alarm,
    showModal,
    setTextIconDetail,
    setModalInfo,
    isSystemUser,
}) => {
  const setEmptyText = () => {
    setTextIconDetail('');
  };
  return (
    <div key={alarm.id} className={`${style.row} ${alarm.is_deleted ? styleManagement.removed : ''}`}>
      <div className={`${style.column} ${style.name}`}>{alarmNameLang(alarm)}</div>
      <div className={`${style.column} ${style.code}`}>{alarm.code}</div>
      <div className={`${style.column} ${style.priority}`}>{actionPriority(alarm.priority, 'fromServer')}</div>
      <div className={style['column-icons']}>
        {(isAdmin || isOwner) && isSystemUser && alarm.is_deleted === false &&
          <Link
            className="actions-container"
            onMouseOver={() => { setTextIconDetail(<FormattedMessage id="general.columnEdit" defaultMessage="Editar" />); }}
            onMouseOut={setEmptyText}
            to={`/app/alarms/form/edit/${alarm.id}`}
          >
            <Edit className="action-icons" width="28" height="28" />
          </Link>
        }
        {(isAdmin || isOwner) && isSystemUser && alarm.is_original === false &&
          <button
            className={alarm.is_deleted === false ? 'btn-delete' : 'btn-delete btn-delete--alarm'}
            onMouseOver={() => { setTextIconDetail(alarm.is_deleted === false ? <FormattedMessage id="general.columnDeactivate" defaultMessage="Desativar" /> : <FormattedMessage id="general.columnRecover" defaultMessage="Recuperar" />); }}
            onMouseOut={setEmptyText}
            onClick={() => { if (!showModal) { setModalInfo(true, alarm); } }}
          >
            {alarm.is_deleted === false ?
              // <Delete className="action-icons" width="28" height="28" />
              <i className="far fa-trash-alt" style={{ fontSize: 23, color: '#666', paddingTop: 3 }} />
              :
              <i className="fas fa-trash-restore-alt" style={{ fontSize: 23, color: '#666', paddingTop: 3 }} />
            }
          </button>
        }
      </div>
    </div>
  );
};

AlarmRow.defaultProps = {
  isAdmin: false,
  isOwner: false,
  isSystemUser: false,
};

AlarmRow.propTypes = {
  alarm: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    code: PropTypes.string,
    type: PropTypes.string,
    priority: PropTypes.number,
    is_original: PropTypes.bool,
    is_deleted: PropTypes.bool,
    created_date: PropTypes.string,
    modified_date: PropTypes.string,
    created_by: PropTypes.number,
    modified_by: PropTypes.number,
  }).isRequired,
  isAdmin: PropTypes.bool,
  isOwner: PropTypes.bool,
  isSystemUser: PropTypes.bool,
  showModal: PropTypes.bool.isRequired,
  setTextIconDetail: PropTypes.func.isRequired,
  setModalInfo: PropTypes.func.isRequired,
};

export default AlarmRow;
