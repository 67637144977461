import messages from './locale/messages';
import messagesMonth from './locale/messagesMonthsAbrv';
import { host, getDomain, getToken } from './environment'

export const formatDate = (date, format) => {
  let [, year, month, day, hour, minute] =
    /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):\d{2}[.]\w*$/i.exec(date) || [];
  if (
    year === undefined &&
    month === undefined &&
    day === undefined &&
    hour === undefined &&
    minute === undefined
  ) {
    [, year, month, day, hour, minute] =
      /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):\d{2}$/i.exec(date) || [];
  }
  if (format === 'time') {
    return `${hour}:${minute}`;
  } else if (format === 'date') {
    return `${day}/${month}/${year}`;
  } else if (format === 'card') {
    return `${day}/${messagesMonth[localStorage.contacttoLang][month]} ${hour}:${minute}`;
    // return `${day} | ${messagesMonth[localStorage.contacttoLang][month]} | ${year} ${hour}h${minute}`;
  }

  return `${day}/${month}/${year} - ${hour}:${minute}`;
};

export const formatDateFromString = (dateString) => {
  const date = new Date(dateString);

    const DD = String(date.getDate()).padStart(2, '0');
    const MM = String(date.getMonth() + 1).padStart(2, '0');  // January is 0!
    const YYYY = date.getFullYear();

    const HH = String(date.getHours()).padStart(2, '0');
    const mm = String(date.getMinutes()).padStart(2, '0');
    const SS = String(date.getSeconds()).padStart(2, '0');

    return `${DD}/${MM}/${YYYY} - ${HH}:${mm}:${SS}`;
}

export function broadcast(axios, channelId, msgObj, callback = () => {}) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      responseType: 'json',
      url: `https://${getDomain()}${host()}/api/publish/`,
      data: msgObj,
      headers: { Authorization: `Token ${getToken()}` },
    }).then((res) => {
      resolve(res);
      callback();
    }).catch((error) => {
      console.log(error);
    });
  });
}

export function removeByKey(myObj, arrayKeys) {
  const obj = Object.assign({}, myObj);

  arrayKeys.forEach((el) => {
    delete obj[el];
  });

  return obj;
}

export function setMask(value, mask) {
  if (!value) {
    return '';
  }

  const mask2 = mask.split('');
  const data = value.split('');
  const maskIndex = [];

  mask2.forEach((val, index) => {
    if (!/\d/.test(val)) {
      maskIndex.push([index, val]);
    }
  });

  maskIndex.forEach((el, index) => {
    if (data[el[0]]) {
      data.splice(el[0], 0, el[1]);
    }
  });
  return data.join('');
}

export function removeNonDigit(value, join) {
  let arr;
  if (typeof value === 'string') {
    arr = value.split('');
  } else {
    arr = value;
  }
  if (join === true || join === 'join') {
    return arr.filter(el => /\d/.test(el)).join('');
  }

  return arr.filter(el => /\d/.test(el));
}

export function adjustDigitCountry(value, join, country) {
  const arr = removeNonDigit(value, join);
  switch (country) {
    case 'Brasil':
    case 'BR':
      return arr;
    case 'MU':
      return `00${arr}`;
    // case 'GB':
    //   return `00${arr}`;
    default:
      return arr;
  }
}

export function formatOnlyNumbersDate(date, format) {
  let [, year, month, day, hour, minute] =
    /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):\d{2}[.]\w*$/i.exec(date) || [];
  if (
    year === undefined &&
    month === undefined &&
    day === undefined &&
    hour === undefined &&
    minute === undefined
  ) {
    [, year, month, day, hour, minute] =
      /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):\d{2}$/i.exec(date) || [];
  }
  if (format === 'time') {
    return `${hour}:${minute}`;
  } else if (format === 'date') {
    return `${day}/${month}/${year}`;
  }

  return `${day}/${month}/${year} - ${hour}:${minute}`;
}

export function phonePT(value) {
  switch (value) {
    // From server
    case 'Cellphone':
      return messages[localStorage.contacttoLang]['contact.form.cellphone'];  // 'Celular';
    case 'Home':
      return messages[localStorage.contacttoLang]['contact.form.home'];  // 'Casa';
    case 'Primary':
      return messages[localStorage.contacttoLang]['contact.form.primary'];  // 'Primário';
    case 'Work':
      return messages[localStorage.contacttoLang]['contact.form.work'];  //  'Trabalho';
    // To server
    case messages[localStorage.contacttoLang]['contact.form.cellphone']:  // 'Celular':
      return 'Cellphone';
    case messages[localStorage.contacttoLang]['contact.form.home']:  // 'Casa':
      return 'Home';
    case messages[localStorage.contacttoLang]['contact.form.primary']:  // 'Primário':
      return 'Primary';
    case messages[localStorage.contacttoLang]['contact.form.work']:  // 'Trabalho':
      return 'Work';
    default:
      return value;
  }
}

export function getAttendantName(attend) {
  if (attend.master_id > 0) {
    return attend.master_name;
  } else if (attend.att_first_name && attend.att_last_name) {
    return `${attend.att_first_name} ${attend.att_last_name}`;
  }
  return '-';
}

export const CellNumber = (value) => {
  const val = removeNonDigit(value, 'join');
  return setMask(val, '(00) 0 0000-0000');
};

export const PhoneNumber = (value) => {
  const val = removeNonDigit(value, 'join');
  return val.length === 11 ? setMask(val, '(00) 0 0000-0000') : setMask(val, '(00) 0000-0000');
};

export const PhoneNumberGlobal = (value, country) => {
  const val = removeNonDigit(value, 'join');
  switch (country) {
    case 'Brasil':
    case 'BR': {
      return val.length === 11 ? setMask(val, '(00) 0 0000-0000') : setMask(val, '(00) 0000-0000');
    }
    case 'GB': {
      return setMask(val, '(00) 0000 000000');
    }
    case 'MU': {
      return val.length === 11 ? setMask(val, '(000) 00000000') : setMask(val, '(000) 0000000');
    }
    default:
      return value;
  }
};

export const generalPhoneNumber = (value) => {
  const val = removeNonDigit(value, 'join');
  if (val.length === 11) {
    return setMask(val, '(00) 0 0000-0000');
  } else if (val.length === 10) {
    return setMask(val, '(00) 0000-0000');
  } else {
    return val;
  }
};

export const zipCode = (value) => {
  const val = removeNonDigit(value, 'join');
  return setMask(val, '00000-000');
};

export const cpfMask = (value) => {
  const val = removeNonDigit(value, 'join');
  return setMask(val, '000.000.000-00');
};

export const birthJStoDjango = (birthdate) => {
  if (/^\d\d[/]\d\d[/]\d\d\d\d$/.test(birthdate)) {
    const [, day, month, year] = /^(\d\d)[/](\d\d)[/](\d\d\d\d)$/.exec(birthdate);

    return `${year}-${month}-${day}`;
  }
  return birthdate;
};

export const getBrowserMaxWidth = () => (
  Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth,
  )
);

export const renderPhoneDigit = (phone, country) => {
  let val = removeNonDigit(phone, 'join');
    switch (country) {
      case 'Brasil':
      case 'BR': {
        if (val.length === 10) {
          return setMask(val, '00 0000-0000');
        }
        return setMask(val, '00 0 0000-0000');
      }
      case 'GB': {
        return setMask(val, '(00) 0000 000000');
      }
      case 'MU': {
        if (val.substring(0, 2) === '00') {
          val = val.substring(2);
        }
        return setMask(val, '(000) 00000000');
      }
      default:
        return val;
    }
}

export const renderNinethDigit = (value) => {
  const val = removeNonDigit(value, 'join');
  if(val.length === 10){
      return setMask(val, '00 0000-0000');
  }
  if(val.length === 11){
      return setMask(val, '00 0 0000-0000');
  }
}

export const booleanPT = (value, order) => {
  const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
  if (order === 'fromServer') {
    if (value === true) {
      return messages[lang]['general.yes'];
    } else if (value === false) {
      return messages[lang]['general.no'];
    }
  } else if (order === 'toServer') {
    if (value === messages[lang]['general.yes']) {
      return true;
    } else if (value === messages[lang]['general.no']) {
      return false;
    }
  } else {
    return value;
  }
}

export const generateRandomNumberString = (size) => {
  let result = '';
  for (let i = 0; i < size; i++) {
    result += Math.floor(Math.random() * 10);
  }
  return result;
}

export function alarmPriority(alarm) {
  const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
  switch (alarm) {
    case 1:
      return messages[lang]['general.highPriority'];
    case 2:
      return messages[lang]['general.mediumPriority'];
    case 3:
      return messages[lang]['general.lowPriority'];
    case 4:
      return messages[lang]['general.sysPriority'];
    default:
      return '';
  }
}

export function naturalSort(a, b) {
  let NUMBER_GROUPS = /(-?\d*\.?\d+)/g,
      aa = String(a).split(NUMBER_GROUPS),
      bb = String(b).split(NUMBER_GROUPS),
      min = Math.min(aa.length, bb.length);

    for (var i = 0; i < min; i++) {
        var x = parseFloat(aa[i]) || aa[i].toLowerCase(),
            y = parseFloat(bb[i]) || bb[i].toLowerCase();
        if (x < y) return -1;
        else if (x > y) return 1;
    }

  return 0;
}

export function returnColor(colorList, position) {
  return colorList[position % colorList.length];
}

export function formatBirthdate(dateString) {
  const [year, month, day] = dateString.split("-");
  return `${day}/${month}/${year}`;
}
