import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { FormattedMessage } from 'react-intl';
import ReportButton from '../ReportButtonComponent';

import style from '../reportComponent.module.scss';

const ClientMonthlyFollowupComponent = (props) => {
  const currentYear = new Date().getFullYear();
  const [years, setYears] = useState([]);
  const [selectFocus, setSelectFocus] = useState(false);
  const [focusedInput, setFocusedInput] = useState(false);
  const [selectedYear, setSelectedYear] = useState('');

  useEffect(() => {
    calcYearList();
  }, [])

  const calcYearList = () => {
    const dt = new Date();
    const current = dt.getFullYear();
    let begin = current - 4;
    const yearList = [];

    while (begin <= current) {
      yearList.push({ value: `${begin}`, label: `${begin}` });
      begin++;
    }
    setYears(yearList);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event);
  }

  return (
    <div className={style.wrapper} style={focusedInput ? { height: '400px' } : {}}>
      <div className={style.code} style={selectFocus ? { height: '200px' } : {}}>
        <Select
          value={selectedYear}
          options={years}
          name="clientMonthly"
          onChange={handleYearChange}
          placeholder={<FormattedMessage id="reports.selectYear" defaultMessage="Selecione o Ano" />}
          onClose={() => setSelectFocus(false)}
          onOpen={() => setSelectFocus(true)}
          clearable={false}
        />
      </div>

      <ReportButton
        themeColor={props.themeColor}
        url={`/app/reports/list/clients-month/year=${selectedYear ? selectedYear.value : currentYear}`}
        text={<FormattedMessage id="reports.generateReport" defaultMessage="Gerar Relatório" />}
      />
    </div>
  );
};

export default ClientMonthlyFollowupComponent;
