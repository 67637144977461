import React, { useState, useEffect } from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';
import { FormattedMessage } from 'react-intl';
import { firstIcon, lastIcon } from '../../../../icons/trackerIcons';
import { Link } from "react-router-dom";
import './clientLocation.module.scss';

const MapMarker = ({ client, isHovered, clusterer, selectedClient, setSelectedClient, isGps }) => {
  const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(false);

  useEffect(() => {
    if (selectedClient?.id === client.id) {
      setIsInfoWindowOpen(true);
    }
  }, [selectedClient, client]);

  const closeInfoWindow = () => {
    setIsInfoWindowOpen(false);
    if (selectedClient?.id === client.id) {
      setSelectedClient(null);
    }
  }

  return (
    <Marker
      onClick={() => setIsInfoWindowOpen(true)}
      position={{ lat: parseFloat(client.lat), lng: parseFloat(client.lng) }}
      icon={isHovered || selectedClient?.id === client.id ? lastIcon : firstIcon}
      clusterer={clusterer}
    >
      {isInfoWindowOpen &&
        <InfoWindow onCloseClick={() => closeInfoWindow()}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>Nome: {client.first_name} {client.last_name}</span>
            <span>Conta: {client.account}</span>
            <span>Tipo do Sinal: {isGps ? 'GPS' : 'Wi-Fi'}</span>
            {client.battery && <span>Bateria: {client.battery}%</span>}
            {client.is_charging !== null && 
              <>
                <span>Carregando: {client.is_charging ?
                    <FormattedMessage id="general.yes" defaultMessage="Sim" /> :
                    <FormattedMessage id="general.no" defaultMessage="Não" />
                  }
                </span>
              </>
            }
            <Link to={`/app/clients?account=${client.account}`}>
              <span>Ir para filtro</span>
            </Link>
          </div>
        </InfoWindow>
      }
    </Marker>
  );
};

export default MapMarker;
