import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import ReportAlarmRow from './ReportAlarmRow';
import Paginate from '../../../Common/Paginate';
import Order from '../../../../icons/Order';
import Loading from '../../../../icons/Loading';
import Download from '../../../../icons/Download';
import { host, getDomain, getToken } from '../../../../BLL/environment';
import { reportPriority } from '../../../../BLL/dictionary';
import { naturalSort } from '../../../../BLL/general';

import style from './reportAlarm.module.scss';
import '../../../../css/print.scss';

const ReportAlarm = (props) => {
  const priority = props.match.params.filter.includes('all') ? 'all' : parseInt(props.match.params.filter.charAt(9), 10);
  const title = props.match.params.filter.includes('priority') ? reportPriority(priority) : 'Código Selecionado';
  const numPerPage = 50;
  const [report, setReport] = useState(undefined);
  const [page, setPage] = useState(1);
  const [ordering, setOrdering] = useState('-id');
  const [loading, setLoading] = useState(true);
  const [csvUrl, setCsvUrl] = useState('');
  const [pdfUrl, setPdfUrl] = useState('');

  useEffect(() => {
    if (props.match.params.filter) {
      fetchAlarmReport(props.match.params.filter);
    }
  }, [props.match.params.filter]);

  const fetchAlarmReport = (filter) => {
    axios({
      method: 'GET',
      url: `https://${getDomain()}${host()}/api/reports/alarms/?${filter}`,
      headers: { Authorization: `Token ${getToken()}` },
    }).then((resp) => {
      setReport(resp.data.alarmList.sort((a, b) => b.id - a.id));
      setCsvUrl(resp.data.csv_url);
      setPdfUrl(resp.data.pdf_url);
      setPage(1);
      setLoading(false);
    }).catch((err) => {
      console.log(err.response);
    })
  };

  const handleIdChange = (newOrder) => {
    if (ordering !== newOrder) {
      setReport(report.sort((a, b) => a.id - b.id));
      setOrdering(newOrder);
    } else {
      setReport(report.sort((a, b) => b.id - a.id));
      setOrdering(`-${newOrder}`);
    }
  };

  const handleCodeChange = (newOrder) => {
    if (ordering !== newOrder) {
      setReport(report.sort((a, b) => {
        const nameA = `${a.code}${a.attendant.toLowerCase()}${a.id}`;
        const nameB = `${b.code}${b.attendant.toLowerCase()}${b.id}`;
        return naturalSort(nameA, nameB);
      }));
      setOrdering(newOrder);
    } else {
      setReport(report.sort((a, b) => {
        const nameA = `${a.code}${a.attendant.toLowerCase()}${a.id}`;
        const nameB = `${b.code}${b.attendant.toLowerCase()}${b.id}`;
        return naturalSort(nameB, nameA);
      }));
      setOrdering(`-${newOrder}`);
    }
  };

  const handleOrderChange = (newOrder) => {
    if (ordering !== newOrder) {
      setReport(report.sort((a, b) => {
        const nameA = `${a[newOrder].toLowerCase()}${a.id}`
        const nameB = `${b[newOrder].toLowerCase()}${b.id}`;
        return naturalSort(nameA, nameB)
      }));
      setOrdering(newOrder);
    } else {
      setReport(report.sort((a, b) => {
        const nameA = `${a[newOrder].toLowerCase()}${a.id}`
        const nameB = `${b[newOrder].toLowerCase()}${b.id}`;
        return naturalSort(nameB, nameA)
      }));
      setOrdering(`-${newOrder}`);
    }
  };

  const firstItem = (page - 1) * numPerPage;
  const lastItem = page * numPerPage;

  return (
    <div className="contactto-middle-content scroll print">
      {report &&
        <div className={`${style.title} col-sm-12 col-md-6 row`}>
          <div className="col-sm-12 md-6">
            <FormattedMessage id="reports.reportOf" defaultMessage="RELATÓRIO DE" />&nbsp;
            <span style={{ color: '#999', fontSize: '1.1rem', width: '100%' }}>
              <FormattedMessage id="reports.alarms.alarmsOf" defaultMessage="ALARMES DE" /> &nbsp; {title.toUpperCase()}
            </span>
          </div>
        </div>
      }
      <div className="col-sm-12 col-md-6 row">
        <div style={{ marginLeft: '5px' }}>
          <a href={csvUrl}>
            <div className={`${style.download} no-print`} title="Download">
              <Download
                fill="#fff"
                width="33px"
                height="33px"
              />
            </div>
          </a>
        </div>
        <div style={{ marginLeft: '5px' }}>
          <a href={pdfUrl}>
            <div className={`${style.download} no-print`} title="PDF" style={{ padding: '8px' }}>
              <span style={{ color: "#fff" }}><i className="far fa-file-pdf fa-lg" /></span>
            </div>
          </a>
        </div>
      </div>
      {loading 
        ? <Loading text={<FormattedMessage id="attend.list.loading" defaultMessage="Carregando" />} /> 
        : report && 
          <div className={`${style.table} print`}>
            <Paginate
              outerClass={style.paginate}
              numberOfElements={report.length}
              numPerPage={numPerPage}
              setPage={(ev) => setPage(ev)}
              page={page}
            />
            <div className={style.header}>
              <button
                className={style['header-column']}
                onClick={() => handleIdChange('id')}
                style={{ color: ordering === 'id' ? '#00b8cb' : '#333' }}
              >
                ID
                <Order
                  width="15"
                  height="10"
                  className={`${ordering === 'id' ? style.rotate : ''}  no-print`} 
                />
              </button>
              <button
                className={style['header-column']}
                onClick={() => handleOrderChange('user')}
                style={{ color: ordering === 'user' ? '#00b8cb' : '#333' }}
              >
                <FormattedMessage id="reports.client" defaultMessage="Usuário" />
                <Order
                  width="15"
                  height="10"
                  className={`${ordering === 'user' ? style.rotate : ''} no-print`}
                />
              </button>
              <button
                className={style['header-column']}
                onClick={() => handleOrderChange('city')}
                style={{ color: ordering === 'city' ? '#00b8cb' : '#333' }}
              >
                <FormattedMessage id="reports.city" defaultMessage="Cidade" />
                <Order
                  width="15"
                  height="10"
                  className={`${ordering === 'city' ? style.rotate : ''} no-print`}
                />
              </button>
              <button
                className={style['header-column']}
                onClick={() => handleOrderChange('company')}
                style={{ color: ordering === 'company' ? '#00b8cb' : '#333' }}
              >
                <FormattedMessage id="reports.company" defaultMessage="Empresa" />
                <Order
                  width="15"
                  height="10"
                  className={`${ordering === 'company' ? style.rotate : ''} no-print`}
                />
              </button>
              <button
                className={style['header-column']}
                onClick={() => handleCodeChange('code')}
                style={{ color: ordering === 'code' ? '#00b8cb' : '#333' }}
              >
                <FormattedMessage id="reports.alarm" defaultMessage="Alarme" />
                <Order
                  width="15"
                  height="10"
                  className={`${ordering === 'code' ? style.rotate : ''} no-print`}
                />
              </button>
              <div className={style['header-column']}>
                <FormattedMessage id="reports.category" defaultMessage="Categoria" />
              </div>
              <button
                className={style['header-column']}
                onClick={() => handleOrderChange('attendant')}
                style={{ color: ordering === 'attendant' ? '#00b8cb' : '#333' }}
              >
                <FormattedMessage id="reports.attendant" defaultMessage="Atendente" /><Order
                  width="15"
                  height="10"
                  className={`${ordering === 'attendant' ? style.rotate : ''} no-print`}
                />
              </button>
              <button
                className={style['header-column']}
                onClick={() => handleIdChange('id')}
                style={{ color: ordering === 'id' ? '#00b8cb' : '#333' }}
              >
                <FormattedMessage id="reports.date" defaultMessage="Data" />
                <Order
                  width="15"
                  height="10"
                  className={`${ordering === 'id' ? style.rotate : ''}  no-print`} 
                />
              </button>
              <div className={style['header-column']}>
                <FormattedMessage id="reports.duration" defaultMessage="Duração" />
              </div>
            </div>
            <div className={`${style.body} no-print`}>
              {typeof report[0] !== 'undefined'
                ? report
                  .slice(firstItem, lastItem)
                  .map((alarm, index) =>
                    <ReportAlarmRow key={alarm.alarm_event_id} alarm={alarm} />,
                  )
                : <div className={style.row}><FormattedMessage id="reports.emptyReport" defaultMessage="Relatório vazio" /></div>}
            </div>
          </div>
      }
    </div>
  );
};

ReportAlarm.defaultProps = {
  user: undefined,
};

ReportAlarm.propTypes = {
  params: PropTypes.shape({
    filter: PropTypes.string.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.number),
    is_active: PropTypes.bool,
    is_staff: PropTypes.bool,
    is_superuser: PropTypes.bool,
    user_info: PropTypes.shape({
      id: PropTypes.number,
      user: PropTypes.number,
      phone: PropTypes.string,
      mobile: PropTypes.string,
      email: PropTypes.string,
      extension_id: PropTypes.number,
    }),
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        priority: PropTypes.number,
        user: PropTypes.number,
        receive_sms: PropTypes.bool,
        receive_email: PropTypes.bool,
      }),
    ),
  }),
};

export default ReportAlarm;
