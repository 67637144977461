import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import CheckboxInput from '../Common/CheckboxInput';
import Loading from '../../icons/Loading';

import style from './modalDeleteCompany.module.scss';

const ModalDeleteCompany = (props) => {
  const stages = {
    start: 0,
    processing: 1,
    success: 2,
    error: -1
  }
  const deleteMap = {
    company: props.deleteCompany,
    companyAndUsers: props.deleteCompanyAndUsers
  }
  const [deleteStage, setStage] = useState(stages.start);
  const [deleteUsers, setDeleteUsers] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const performDelete = () => {
    setStage(stages.processing);
    const selection = deleteUsers ? 'companyAndUsers' : 'company';
    deleteMap[selection](props.companyId).then((res) => {
      props.setSelectedCompany('0');
      setStage(stages.success);
      console.log(res);
    }).catch((err) => {
      const data = err.data;
      setStage(stages.error);
      if (data.haveClients) {
        setErrorMsg(
          <FormattedMessage id="company.delete.errorHasClients" defaultMessage="Esta empresa ainda possui usuários. Verifique também usuários inativos." />
        );
      }

      if (data.haveUsers) {
        setErrorMsg(
          <FormattedMessage id="company.delete.errorHasUsers" defaultMessage="Esta empresa ainda possui colaboradores ativos." />
        );
      }
    });
  }

  return (
    <div className={style.wrapper}>
      {deleteStage === stages.start &&
        <div>
          <div className={style.title}><FormattedMessage id="company.delete.warningHeader" defaultMessage="AVISO" /></div>
          <div className={style.text}><FormattedMessage id="company.delete.warningMsg" defaultMessage="Para remover uma empresa por completo, primeiro, você precisa inativar seus colaboradores e excluir todos os seus usuários (ou alterar sua empresa)." /></div>
          <div className={style.text}><FormattedMessage id="company.delete.warning" defaultMessage="Você já realizou estas ações? Deseja prosseguir?" /></div>
          <div className={style.textOption}>
            <FormattedMessage id="company.delete.deleteUsers" defaultMessage="Excluir acessos dos colaboradores (login, email)?" />
            <div className={style.checkboxContainer}>
              <CheckboxInput name="deleteUsers" className="form-control" checked={deleteUsers} onChange={() => setDeleteUsers(!deleteUsers)} />
            </div>
          </div>
          <div className="col-sm-12 row" style={{paddingTop: '1rem' }}>
            <button id="modalBtnCancel" type="button" className="btn btn--confirm-remove-modal btn--color-red col-6" onClick={() => props.closeModal()}>
              <span>
                <i className="far fa-window-close" aria-hidden="true" style={{paddingRight:'1rem',paddingTop: '0.2rem'}}/>
              </span>
              <FormattedMessage id="modal.confirm.no" defaultMessage="NÃO" />
            </button>
            <button id="modalBtnConfirm" type="button" className="btn btn--confirm-remove-modal btn--color-green col-6" onClick={() => performDelete()}>
              <span>
                <i className="far fa-save fa-lg" aria-hidden="true" style={{paddingRight:'1rem',paddingTop: '0.2rem'}}/>
              </span>
              <FormattedMessage id="modal.confirm.yes" defaultMessage="SIM" />
            </button>
          </div>
        </div>
      }
      {deleteStage === stages.processing &&
        <Loading text={<FormattedMessage id="company.delete.loading" defaultMessage="Aguarde..." /> } />
      }
      {deleteStage === stages.success &&
        <div>
          <div className={style.text}><FormattedMessage id="company.delete.success" defaultMessage="Empresa removida com sucesso!" /></div>
          <div className="col-sm-12 row" style={{paddingTop: '1rem' }}>
            <button id="modalBtnCloseErr" type="button" className="btn btn--confirm-remove-modal col-12" onClick={() => props.closeModal()}>
              OK
            </button>
          </div>
        </div>
      }
      {deleteStage === stages.error &&
        <div>
          <div className={style.text}><FormattedMessage id="company.delete.errorTitle" defaultMessage="OPS! Ocorreu um erro ao remover esta empresa." /></div>
          <div className={style.text}>{errorMsg}</div>
          <div className="col-sm-12 row" style={{paddingTop: '1rem' }}>
            <button id="modalBtnCloseErr" type="button" className="btn btn--confirm-remove-modal col-12" onClick={() => props.closeModal()}>
              OK
            </button>
          </div>
        </div>
      }
    </div>
  )
};

ModalDeleteCompany.propTypes = {
  closeModal: PropTypes.func.isRequired,
  deleteCompany: PropTypes.func.isRequired,
  deleteCompanyAndUsers: PropTypes.func.isRequired,
  setSelectedCompany: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired,
}

export default ModalDeleteCompany;
