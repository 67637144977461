export const pagination = 50;

export const clientsOrdering = 'account';
export const contactsOrdering = 'priority';
export const transmissorsOrdering = 'zone';
export const transmittersOrdering = 'zone';
export const attendantsOrdering = 'username';
export const attendsOrdering = '-status,-id';
export const attendsClientOrdering = '-status,-id';
export const alarmsOrdering = 'code';
export const nameOrdering = 'name';
export const limit = 'limit=50';

export const maxTransmitters = 16;
export const maxClosedAttends = 6;
export const maxDashboardAttends = 6;

export const screenResolution = () => {
  const res = window.innerWidth;
  if (res < 768) {
    return 'sm';
  } else if (res < 1280) {
    return 'md';
  }
  return 'lg';
};


export const brazilCountryStateList = [
  'AC',
  'AL',
  'AM',
  'AP',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MG',
  'MS',
  'MT',
  'PA',
  'PB',
  'PE',
  'PI',
  'PR',
  'RJ',
  'RN',
  'RO',
  'RR',
  'RS',
  'SC',
  'SP',
  'SE',
  'TO',
];

export const alarmBackgroundColor = ['#EC8981', '#F9BE7D', '#59C599', '#59D7F2'];
export const alarmFooterIconColor = ['#D8342B', '#F69B38', '#008D53', '#3D94CC'];
export const zoneBackgroundColor = ['#59D7F2', '#59C599', '#F9BE7D', '#EC8981'];
export const zoneFooterIconColor = ['#3D94CC', '#008D53', '#F69B38', '#D8342B'];
export const lightGrayBackgroundColor = '#A09E9B';
export const grayFooterIconColor = '#807C78';


export const callStates = {
  IDLE: 9,
  NOCALL: -1,
  CLOSED: 0,
  OPEN: 1,
  INPROGRESS: 2,
}
