import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import MonthlyFollowupChart from '../../../Charts/MonthlyFollowupChart';
import Loading from '../../../../icons/Loading';
import Download from '../../../../icons/Download';
import messages from '../../../../BLL/locale/messages';
import messagesMonth from '../../../../BLL/locale/messagesMonths';
import { host, getDomain, getToken } from '../../../../BLL/environment';

import style from './reportClientMonthlyFollowup.module.scss';

const ReportClienthMonthlyFollowup = (props) => {
  const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
  const [report, setReport] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [csvUrl, setCsvUrl] = useState('');
  const [pdfUrl, setPdfUrl] = useState('');
  const [showChart, setShowChart] = useState(false);

  useEffect(() => {
    if (props.match.params.filter) {
      fetchFollowupReport(props.match.params.filter);
    }
  }, [props.match.params.filter]);

  const fetchFollowupReport = (filter) => {
    axios({
      method: 'GET',
      url: `https://${getDomain()}${host()}/api/reports/clients-monthly-report/?${filter}`,
      headers: { Authorization: `Token ${getToken()}` },
    }).then((resp) => {
      setReport(resp.data.data);
      setCsvUrl(resp.data.csv_url);
      setPdfUrl(resp.data.pdf_url);
      setLoading(false);
    }).catch((err) => {
      console.log(err.response);
    });
  };

  const getMonthString = (month) => {
    switch (month) {
      case 1:
        return messagesMonth[lang].january;
      case 2:
        return messagesMonth[lang].february;
      case 3:
        return messagesMonth[lang].march;
      case 4:
        return messagesMonth[lang].april;
      case 5:
        return messagesMonth[lang].may;
      case 6:
        return messagesMonth[lang].june;
      case 7:
        return messagesMonth[lang].july;
      case 8:
        return messagesMonth[lang].august;
      case 9:
        return messagesMonth[lang].september;
      case 10:
        return messagesMonth[lang].october;
      case 11:
        return messagesMonth[lang].november;
      case 12:
        return messagesMonth[lang].december;
      default:
        return '';
    }
  };

  const loadRows = () => {
    return report.map(data => (
      <tr className={style.row} key={data.month}>
        <td style={{ width: '3rem' }}>{getMonthString(data.month)}</td>
        <td>{data.added}</td>
        <td>{data.inactivations}</td>
        <td>{data.reactivations}</td>
        <td>{data.disabled}</td>
      </tr>
    ));
  };

  return (
    <div className="contactto-middle-content scroll print">
      <div className={`${style.title} col-sm-12 col-md-9 row`}>
        <FormattedMessage id="reports.reportOf" defaultMessage="RELATÓRIO DE" /> &nbsp;
        <span style={{ color: '#999', fontSize: '1.1rem', width: '100%' }}>
          <FormattedMessage id="reports.userTracking.header" defaultMessage="ACOMPANHAMENTO MENSAL DE USUÁRIOS" />
        </span>
      </div>
      <div className="col-sm-12 col-md-3 row">
        <div style={{ marginLeft: '5px' }}>
          <button
            type="button"
            className={`${style.download} no-print`}
            onClick={() => setShowChart(!showChart)}
            style={{ border: 'solid 1px #efefef' }}
            title={`${showChart ? messages[lang]['reports.reportButton'] : messages[lang]['reports.graphicButton']}`}
          >
            <i
              className={` ${showChart ? 'far fa-file-alt' : 'fas fa-chart-bar'} fa-lg`}
              aria-hidden="true"
              style={{ transition: '.3s ease' }}
            />
          </button>
        </div>
        <div style={{ marginLeft: '5px' }}>
          <a href={csvUrl}>
            <div className={`${style.download} no-print`} title="Download">
              <Download fill="#fff" width="33px" height="33px" />
            </div>
          </a>
        </div>
        <div style={{ marginLeft: '5px' }}>
          <a href={pdfUrl}>
            <div className={`${style.download} no-print`} title="PDF" style={{ padding: '8px' }}>
              <span style={{ color: "#fff" }}><i className="far fa-file-pdf fa-lg" /></span>
            </div>
          </a>
        </div>
      </div>
      {loading
        ? <Loading text={<FormattedMessage id="attend.list.loading" defaultMessage="Carregando" />} />
        : (report &&
            (!showChart
              ?
                <div className={`${style.table} print`}>
                  <tr className={style.header}>
                    <td><FormattedMessage id="reports.userTracking.month" defaultMessage="Mês" /></td>
                    <td><FormattedMessage id="reports.userTracking.addedUsers" defaultMessage="Usuários Adicionados" /></td>
                    <td><FormattedMessage id="reports.userTracking.inactivations" defaultMessage="Inativações" /></td>
                    <td><FormattedMessage id="reports.userTracking.reactivations" defaultMessage="Reativações" /></td>
                    <td><FormattedMessage id="reports.userTracking.disabled" defaultMessage="Desabilitados" /></td>
                  </tr>
                  <tbody className={style.body}>{loadRows()}</tbody>
                </div>
              :
                <div>
                  {report.length > 0 &&
                    <MonthlyFollowupChart report={report} width="100%" height={`${35 * report.length < 200 ? 200 : 35 * report.length}px`}/>
                  }
                </div>
            )
          )
      }
    </div>
  );

};

export default ReportClienthMonthlyFollowup;
