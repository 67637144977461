import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import DateRangePickerWrapper from '../ReportDateWrapper';
import ReportButton from '../ReportButtonComponent';

import style from '../reportComponent.module.scss';

const AttendComponent = (props) => {
  const [urlDate, setUrlDate] = useState('');
  const [focusedInput, setFocusedInput] = useState(null);

  const setDate = (startDate, endDate) => {
    const firstDate = startDate
      ? `&start=${startDate.format('YYYY-MM-DD')}`
      : '&start=2012-11-28';
    const lastDate = endDate
      ? `&end=${endDate.format('YYYY-MM-DD')}`
      : `&end=${moment().format('YYYY-MM-DD')}`;
    setUrlDate(`${firstDate}${lastDate}`);
  };

  return (
    <div className={style.wrapper} style={focusedInput ? { height: '400px' } : {}}>
        <DateRangePickerWrapper
          focusedInput={(focused) => setFocusedInput(focused)}
          setDate={setDate}
          resetDate={urlDate === ''}
          orientation={props.browserWidth > 390 ? 'horizontal' : 'vertical'}
          style={{ marginTop: '10px', width: '100%' }}
        />
        {!!urlDate &&
          <ReportButton
            themeColor={props.themeColor}
            url={`/app/reports/list/attends/${urlDate}`}
            text={<FormattedMessage id="reports.generateReport" defaultMessage="Gerar Relatório" />}
          />
        }
    </div>
  );
};

AttendComponent.propTypes = {
  themeColor: PropTypes.string.isRequired,
  browserWidth: PropTypes.number.isRequired,
  attendTags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })).isRequired,
  lang: PropTypes.string.isRequired,
  browserWidth: PropTypes.number.isRequired,
};

export default AttendComponent;
