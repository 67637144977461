import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../../../BLL/locale/messages';

import style from './clientPage.module.scss';

const ClientFilterComponent = (props) => {
  const mobileEquipmentIds = props.equipment[0]?.data.filter(eq => eq.is_mobile).map(eq => eq.id).toString();
  const [filter, setFilter] = useState({});

  useEffect(() => {
    if (props.filter) {
      let newFilter = {};
      props.filter.split('&').forEach((elm) => {
        const key = elm.split('=')[0];
        const value = elm.split('=')[1];
        newFilter[key] = value
      });
      setFilter(newFilter);
    } else {
      setFilter({});
    }
  }, [props.filter]);

  if (Object.keys(filter).length > 0) {
    return (
      <div className={style.subtitleContainer}>
        <div className={style.subtitle} style={{ display: 'flex' }}>
          <FormattedMessage id="client.page.headerSubtitle" defaultMessage="Filtrados por" />:
          {Object.keys(filter).map((elm) => {
            if (filter[elm]) {
              let value = '';
              if (elm === 'equipment_ids') {
                value = filter[elm] === mobileEquipmentIds ? messages[props.lang]['general.yes'] : messages[props.lang]['general.no'];
              } else if (elm === 'group_id') {
                const company = props.companies?.find(company => company.id === parseInt(filter[elm]));
                value = company ? company.name : messages[props.lang]['client.filter.noGroup'];
              } else {
                value = filter[elm]
              }
              return (
                <div key={elm} style={{ marginLeft: '5px' }}>
                  |{messages[props.lang][`client.filter.${elm}`]} - {value}|
                </div>
              );
            }
            return (<></>);
          })}
        </div>
      </div>
    )
  } else {
    return (<></>);
  }
};

export default ClientFilterComponent;
