import * as types from '../actions/actionTypes';
import initialState from './initialState';


/*
Inputs:
  state = Estado atual do objeto, caso não seja passado, é utilizado o estado padrão do arquivo
  (initialState.errors) que é uma lista
  action = Ação aplicada sobre o objeto
Types:
  LOGOUT = Realiza ação de Logout no Programa
  REMOVE_ERROR = Procura na lista do estado e remove o erro da ação

errorsReducer = Realiza o logout da aplicação caso ocorra algum erro muito grave
(utilizado primariamente nas actions)
 */
export default function errorsReducer(state = initialState.errors, action) {
  switch (action.type) {
    case types.LOGOUT:
      return [
        ...state,
        'LOGOUT',
      ];

    case types.REMOVE_ERROR:
      return state.filter(el => el !== action.error);

    default:
      return state;
  }
}
