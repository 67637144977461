import * as types from '../actions/actionTypes';
import initialState from './initialState';


/*
Inputs:
  state = Estado atual do objeto, caso não seja passado, é utilizado o estado padrão do arquivo
  (initialState.transmitters) que é uma lista
  action = Ação aplicada sobre o objeto
Types:
  LOAD_transmitters_SUCCESS = Carrega a lista de Transmissores de um cliente específico, adiciona
    na lista quando não houver ou quando um transmissor novo for adicionado

transmitterReducer = Adiciona um transmissor a uma lista de transmissores por cliente e retorna
esta lista
 */
export default function transmitterReducer(state = initialState.transmitters, action) {
  let newState = [];
  switch (action.type) {
    case types.LOAD_TRANSMITTERS_SUCCESS:
      return [
        ...state,
        {
          clientId: action.clientId,
          ordering: action.ordering,
          data: action.transmitters
        }
      ]
    case types.EDIT_TRANSMITTER:
      newState = state.map(dataList => {
        if (dataList.data && dataList.clientId === action.clientId) {
          return {
            clientId: dataList.clientId,
            ordering: dataList.ordering,
            data: dataList.data.map(
              transmitter => transmitter.id === action.transmitter.id
                ? action.transmitter
                : transmitter
            )
          }
        }
        return dataList
      });
      return newState;
    case types.NEW_TRANSMITTER:
      newState = state.map(dataList => {
        if (dataList.data && dataList.clientId === action.clientId) {
          return {
            clientId: dataList.clientId,
            ordering: dataList.ordering,
            data: [...dataList.data, action.transmitter]
          }
        }
        return dataList;
      });
      return newState;
    case types.REMOVE_TRANSMITTER:
      newState = state.map(dataList => {
        if (dataList.data && parseInt(dataList.clientId) === parseInt(action.clientId)) {
          return {
            clientId: dataList.clientId,
            ordering: dataList.ordering,
            data: dataList.data.filter(
              transmitter => transmitter.id !== action.transmitterId
            )
          };
        }
        return dataList;
      });
      return newState;
    default:
      return state;
  }
}
