import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ModalConfirm from './ModalConfirm';
import ToggleButton from '../Common/ToggleButton';
import style from './modalReminderNote.module.scss';

const ModalReminderNote = (props) => {
  const wrapperRef = useRef(null);
  const [title, setTitle] = useState('');
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [limit, setLimit] = useState(280);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isIndividual, setIsIndividual] = useState(true);
  

  useEffect(() => {
    document.addEventListener('mousedown', clickedOutside);

    return () => {
      document.removeEventListener('mousedown', clickedOutside);
    }
  }, []);

  const clickedOutside = (ev) => {
    if (wrapperRef.current && !wrapperRef.current.contains(ev.target)) {
      // props.closeModal();
      setShowConfirmModal(true);
    }
  };

  const handleTitle = (event) => {
    const textareaLen = parseInt(document.querySelector('textarea#notetext').getAttribute('maxlength'), 10);
    setTitle(event.target.value);
    setLimit(textareaLen - event.target.value.length);
  }

  const generateAndSendReminder = () => {
    const reminder = {
      requested_time: (parseInt(hours, 10) * 60) + parseInt(minutes, 10),
      is_individual: isIndividual,
      title,
    }
    props.createReminder(reminder);
  };

  const disabled = !(title && (hours > 0 || minutes > 0));
  return (
    <div className={style['reminder-container']} ref={wrapperRef}>
      {showConfirmModal &&
        <ModalConfirm
          setModalInfo={() => setShowConfirmModal(!showConfirmModal)}
          action={() => props.closeModal()}
          message={<FormattedMessage id="modal.confirm.reminderClose" defaultMessage="Você deseja fechar esta janela de lembrete?" />}
        />
      }
      <div className={style['reminder-header']}>
        <FormattedMessage id="reminder.header" defaultMessage="LEMBRETE PARA O ATENDIMENTO" />
        {props.attendId}
      </div>
      <div className={style['reminder-text-container']} >
        <label className={style['reminder-text-label']} htmlFor="notetext">
          <FormattedMessage id="reminder.reminderTitle" defaultMessage="Lembrete" />:
        </label>
        <div className={style['reminder-text-block']}>
          <textarea
            id="notetext"
            type="textarea"
            name="notetext"
            className={style['reminder-textarea']}
            value={title}
            maxLength="280"
            onChange={handleTitle}
            rows="2"
            cols="50"
          />
        </div>
        <label className={style['reminder-text-limit']} htmlFor="title">
          {limit}
        </label>
      </div>
      <div className={style['reminder-timer-container']} >
        <label className={style['reminder-text-label']} htmlFor="title">
          <FormattedMessage id="reminder.timer" defaultMessage="Tempo" />:
        </label>
        <div className={style['reminder-timer-block']}>
          <div className={style['reminder-select-container']}>
            <span className={style['reminder-timer-label']}>H:</span>
            <select
              id="hours"
              name="hours"
              className={style['reminder-select']}
              onChange={(e) => setHours(e.target.value)}
            >
              {[...Array(24).keys()].map(hour => (
                <option>
                  {hour}
                </option>
              ))}
            </select>
          </div>
          <div className={style['reminder-select-container']}>
            <label className={style['reminder-timer-label']} htmlFor="minutes">M:</label>
            <select
              id="minutes"
              name="minutes"
              className={style['reminder-select']}
              onChange={(e) => setMinutes(e.target.value)}
            >
              {[...Array(60).keys()].map(min => (
                <option>
                  {min}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className={style['reminder-inform-container']}>
        <span className={style['reminder-inform-label']}>
          <FormattedMessage id="reminder.informAll" defaultMessage="Informar todos os atendentes?" />
        </span>
        <div style={{ float: 'left' }}>
          <ToggleButton
            text=""
            isChecked={!isIndividual}
            handleChange={() => setIsIndividual(!isIndividual)}
            hasPagination={false}
          />
        </div>
      </div>
      {props.reminderError &&
        <div className={style['reminder-inform-container']}>
          <span className={style['reminder-error-label']}> {props.reminderError} </span>
        </div>
      }
      <div className={style['reminder-button-container']}>
        {disabled && <span className={style['reminder-help-label']}>
          <FormattedMessage id="reminder.warning" defaultMessage="Por favor, digite um lembrete e escolha um tempo." />
        </span>}
        <button
          disabled={disabled}
          className={`${style['reminder-btn']} ${disabled ? style['reminder-btn-disabled'] : ''}`}
          onClick={generateAndSendReminder}
          type="button"
        >
          <span className={style['reminder-button-bold']}>
            <FormattedMessage id="reminder.btn" defaultMessage="GERAR" />
          </span>
        </button>
      </div>
    </div>
  );
};

ModalReminderNote.propTypes = {
  reminderError: PropTypes.string.isRequired,
  createReminder: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  attendId: PropTypes.number.isRequired,
};

export default ModalReminderNote;
