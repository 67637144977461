export default {
  en: {
    'broadcast.updated': 'updated',
    'broadcast.added': 'added',
    'broadcast.deleted': 'deleted',
    'broadcast.recovered': 'recovered',
    'broadcast.client': 'User',
    'broadcast.contact': 'Contact',
    'broadcast.transmitter': 'Transmitter',
    'broadcast.alarm': 'Alarm',
    'broadcast.attendant': 'Attendant',
    'broadcast.zone': 'zone',
    'broadcast.attendChanged': 'This ticket was assigned to the administrator',
    'broadcast.settingsChanged': 'Settings updated',
  },
  pt: {
    'broadcast.updated': 'atualizado',
    'broadcast.added': 'adicionado',
    'broadcast.deleted': 'excluido',
    'broadcast.recovered': 'recuperado',
    'broadcast.client': 'Usuário',
    'broadcast.contact': 'Contato',
    'broadcast.transmitter': 'Transmissor',
    'broadcast.alarm': 'Alarme',
    'broadcast.attendant': 'Atendente',
    'broadcast.zone': 'zona',
    'broadcast.attendChanged': 'O atendimento foi atribuido para o administrador',
    'broadcast.settingsChanged': 'Atualização de configurações',
  },
};
