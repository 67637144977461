import React from 'react';
import PropTypes from 'prop-types';

const AlarmCancelIcon = ({ height, svgClass, width, fill, transform }) => (
  <svg className={svgClass} width={width} height={height} fill={fill} transform={transform} viewBox="0 0 140 140" xmlns="http://www.w3.org/2000/svg">
<g>
	<path d="M131.9,135.9c-1.3,0-2.6-0.5-3.5-1.5L6.2,12.2c-2-2-2-5.1,0-7.1c2-2,5.1-2,7.1,0l122.2,122.2c2,2,2,5.1,0,7.1
		C134.5,135.4,133.2,135.9,131.9,135.9z"/>
</g>
<g id="ku5000_1_">
	<g>
		<g>
			<path d="M63,22.9c0-1.8,0-3.4,0-5c0-4.1,3.4-7.4,7.6-7.4s7.5,3.3,7.6,7.4c0,1.6,0,3.2,0,4.7c3.1,1.2,6.3,2,9.1,3.5
				c13.6,7,20.7,18.2,21,33.6c0.2,8.2,0.9,16.2,4.2,23.8c2,4.6,5,8.5,8.6,12.1c4.5,4.5,2,10.9-2.4,12.5c-1,0.3-2,0.5-3.1,0.5
				c-30,0-60,0-90,0c-3.5,0-5.9-1.6-7.3-4.7c-1.3-3.1-0.7-5.9,1.7-8.2c8.4-8.1,11.9-18.2,12.5-29.6c0.3-4.4,0.1-8.8,0.9-13.1
				c3-15.5,12.5-25.1,27.5-29.6C61.7,23.2,62.3,23.1,63,22.9z M34.5,97.1c24,0,48.3,0,72.5,0c-1.7-3.1-3.5-6-4.9-9.1
				c-3.9-8.9-5-18.3-5.1-27.8c0-2.1-0.2-4.2-0.6-6.2c-2.8-13.7-16.6-23-30.2-20.4C53.1,36,44.2,46.8,44.3,60
				c0,8.5-0.9,16.9-3.9,24.9C38.8,89.1,36.5,93.1,34.5,97.1z"/>
			<path d="M55.5,116.1c10.2,0,20.1,0,30.2,0c-0.1,5-2,9.1-6,12c-5.2,3.8-10.9,4.2-16.6,1C58.1,126.3,55.8,121.9,55.5,116.1z"/>
		</g>
	</g>
</g>
  </svg>
);

AlarmCancelIcon.defaultProps = {
  height: '75',
  svgClass: '',
  width: '75',
  fill: '#3D94CC',
  transform: 'translate(0,0)',
};

AlarmCancelIcon.propTypes = {
  height: PropTypes.string,
  svgClass: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
  transform: PropTypes.string,
};

export default AlarmCancelIcon;
