import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import { registerLocale } from 'react-datepicker';
import br from 'date-fns/locale/pt-BR';
import us from 'date-fns/locale/en-US';
import moment from 'moment';
import { host, getDomain, getToken } from '../../../../BLL/environment';
import HistoryMenu from './TrackerHistoryMenu';
import MapPoint from './TrackerMapPoint';
import IGOProgrammingComponent from './TrackerIGOProgram';
import CustomModal from '../../../Modal/CustomModal';
import Order from '../../../../icons/Order';
import * as clientsActions from '../../../../actions/clientsActions';

import style from './tracker.module.scss';
import '../../../../css/middle.scss';
import './stylesheets/datepicker-cssmodules.scss';

registerLocale('pt-br', br);
registerLocale('en-us', us);

const TrackerIGO = (props) => {
  const [startDate, setStartDate] = useState(moment().locale('pt-br').subtract(1, 'hours').toDate());
  const [endDate, setEndDate] = useState(moment().locale('pt-br').toDate());
  const [pointList, setPointList] = useState([]);
  const [trackerParams, setTrackerParams] = useState(undefined);
  const [modifiedParams, setModifiedParams] = useState(new Set());
  const [client, setClient] = useState(undefined);
  const [showMap, setShowMap] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [showNormalized, setShowNormalized] = useState(false);
  const [showProgramming, setShowProgramming] = useState(false);
  const [errorComponent, setErrorComponent] = useState(undefined);
  const [helpUrl, setHelpUrl] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchClient(parseInt(props.match.params.clientId, 10));
  }, [props.match.params.clientId]);

  useEffect(() => {
    if (client) {
      fetchLatestPoint();
      fetchParams();
    }
  }, [client])

  const fetchClient = (clientId) => {
    axios.get(`https://${getDomain()}${host()}/api/clients/${clientId}/`, {
      headers: { Authorization: `Token ${getToken()}` },
    }).then(res => setClient(Object.assign({}, res.data)))
    .catch(error => console.log(error));
  };

  const fetchLatestPoint = () => {
    axios({
      url: `https://${getDomain()}${host()}/api/clients/${client.id}/tracker-iot/last/`,
      method: 'GET',
      responseType: 'json',
      headers: { Authorization: `Token ${getToken()}` },
    }).then((res) => {
      setPointList(res.data);
      setErrorComponent(undefined);
    }).catch((err) => {
      console.log(err);
      let errorMessage = '';
      let newHelpUrl = '';
      let newErrorComponent = {};
      if (err.response.data.imei) {
        errorMessage = 'ESTE USUÁRIO NÃO ESTÁ COM O IMEI CADASTRADO.\n';
        newErrorComponent = <FormattedMessage id="client.tracker.errorNoIMEI" defaultMessage={errorMessage} />;
        newHelpUrl = 'https://tecnosenior.zendesk.com/hc/pt-br/articles/360007618312-Solicitando-o-IMEI-e-cadastrando-o-iGO-no-contactto-care';
      } else if (err.respose.data.noData) {
        errorMessage = 'NÃO FORAM ENCONTRADOS DADOS.\n\n O RASTREADOR DEVE SER CONFIGURADO COM O SERVIDOR DO CONTACTTO.CARE.\n\n\n';
        newErrorComponent = <FormattedMessage id="client.tracker.errorNoDataLatest" defaultMessage={errorMessage} />;
        newHelpUrl = 'https://tecnosenior.zendesk.com/hc/pt-br/articles/360007605972-Configurando-o-iGO-no-Contactto';
      } else if (err.response.data.doesNotExist) {
        errorMessage = 'ESTE USUÁRIO NÃO EXISTE';
        newErrorComponent = <FormattedMessage id="client.tracker.errorNoUser" defaultMessage={errorMessage} />;
      } else {
        errorMessage = 'ERRO INESPERADO';
        newErrorComponent = <FormattedMessage id="client.tracker.errorUnexpected" defaultMessage={errorMessage} />;
      }
      setErrorComponent(newErrorComponent);
      setHelpUrl(newHelpUrl);
    })
  };

  const fetchParams = () => {
    axios({
      url: `https://${getDomain()}${host()}/api/clients/${client.id}/tracker-iot/program/`,
      method: 'GET',
      responseType: 'json',
      headers: { Authorization: `Token ${getToken()}` },
    }).then((res) => {
      if (res.data.length > 0) {
        const newTrackerParams = res.data[0];
        newTrackerParams.deviceInterval = (parseInt(newTrackerParams.INTERVAL, 10) / 10).toString().padStart(3, '0');
        setTrackerParams(newTrackerParams);
      }
    }).catch((err) => {
      console.log(err.response);
    })
  };

  const fetchPoints = () => {
    const startDateString = moment(startDate).format('YYYY-MM-DD');
    const endDateString = moment(endDate).format('YYYY-MM-DD');
    const params = `?start_date=${startDateString}&start_hour=${startDate.getHours()}&end_date=${endDateString}&end_hour=${endDate.getHours()}`;

    axios({
      url: `https://${getDomain()}${host()}/api/clients/${client.id}/tracker-iot/${params}`,
      method: 'GET',
      responseType: 'json',
      headers: { Authorization: `Token ${getToken()}` },
    }).then((res) => {
      if (res.data && res.data.length > 0) {
        setPointList(res.data);
        setErrorComponent(undefined);
      } else {
        const errorMessage = 'SEM DADOS PARA AS DATAS ENTRE {startDate} AS {startHour} HORAS E {endDate} AS {endHour} HORAS';
        const newErrorComponent = (<FormattedMessage
          id="client.tracker.errorEmptyPointsHours"
          defaultMessage={errorMessage}
          values={{
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD'),
            startHour: startDate.hour(),
            endHour: endDate.hour(),
          }}
        />);
        setPointList([]);
        setErrorComponent(newErrorComponent);
        setHelpUrl('');
      }
    }).catch((err) => {
      let errorMessage = '';
      let newHelpUrl = '';
      let newErrorComponent = {};
      if (err.response.data.imei) {
        errorMessage = 'ESTE USUÁRIO NÃO ESTÁ COM O IMEI CADASTRADO.\n';
        newErrorComponent = <FormattedMessage id="client.tracker.errorNoIMEI" defaultMessage={errorMessage} />;
        newHelpUrl = 'https://tecnosenior.zendesk.com/hc/pt-br/articles/360007618312-Solicitando-o-IMEI-e-cadastrando-o-iGO-no-contactto-care';
      } else if (err.response.data.noData) {
        errorMessage = 'NÃO HÁ DADOS PARA O DIA SELECIONADO.\n\n O RASTREADOR DEVE SER CONFIGURADO COM O SERVIDOR DO CONTACTTO.CARE.\n\n\n';
        newErrorComponent = <FormattedMessage id="client.tracker.errorNoData" defaultMessage={errorMessage} />;
        newHelpUrl = 'https://tecnosenior.zendesk.com/hc/pt-br/articles/360007605972-Configurando-o-iGO-no-Contactto';
      } else if (err.response.data.doesNotExist) {
        errorMessage = 'ESTE USUÁRIO NÃO EXISTE';
        newErrorComponent = <FormattedMessage id="client.tracker.errorNoUser" defaultMessage={errorMessage} />;
      } else {
        errorMessage = 'ERRO INESPERADO';
        newErrorComponent = <FormattedMessage id="client.tracker.errorUnexpected" defaultMessage={errorMessage} />;
      }
      setErrorComponent(newErrorComponent);
      setHelpUrl(newHelpUrl);
    });
  };

  const fetchNormalizedPoints = () => {
    axios({
      url: `https://${getDomain()}${host()}/api/clients/${client.id}/tracker-iot/normalized/?date=${startDate.format('YYYY-MM-DD')}`,
      method: 'GET',
      responseType: 'json',
      headers: { Authorization: `Token ${getToken()}` },
    }).then((res) => {
      setPointList(res.data);
      setErrorComponent(undefined);
    }).catch((err) => {
      let errorMessage = '';
      let newHelpUrl = '';
      let newErrorComponent = {};
      if (err.response.data.imei) {
        errorMessage = 'ESTE USUÁRIO NÃO ESTÁ COM O IMEI CADASTRADO.\n';
        newErrorComponent = <FormattedMessage id="client.tracker.errorNoIMEI" defaultMessage={errorMessage} />;
        newHelpUrl = 'https://tecnosenior.zendesk.com/hc/pt-br/articles/360007618312-Solicitando-o-IMEI-e-cadastrando-o-iGO-no-contactto-care';
      } else if (err.response.data.noData) {
        errorMessage = 'NÃO HÁ DADOS PARA O DIA SELECIONADO.\n\n O RASTREADOR DEVE SER CONFIGURADO COM O SERVIDOR DO CONTACTTO.CARE.\n\n\n';
        newErrorComponent = <FormattedMessage id="client.tracker.errorNoData" defaultMessage={errorMessage} />;
        newHelpUrl = 'https://tecnosenior.zendesk.com/hc/pt-br/articles/360007605972-Configurando-o-iGO-no-Contactto';
      } else if (err.response.data.noNormalizedDataToday) {
        errorMessage = 'NÃO É POSSÍVEL PESQUISAR POR DADOS SIMPLIFICADOS PARA A DATA DE HOJE.\n ESSA INFORMAÇÃO ESTÁ DISPONÍVEL APENAS PARA DIAS ANTERIORES.';
        newErrorComponent = <FormattedMessage id="client.tracker.errorNoSimpleToday" defaultMessage={errorMessage} />;
      } else if (err.response.data.doesNotExist) {
        errorMessage = 'ESTE USUÁRIO NÃO EXISTE';
        newErrorComponent = <FormattedMessage id="client.tracker.errorNoUser" defaultMessage={errorMessage} />;
      } else {
        errorMessage = 'ERRO INESPERADO';
        newErrorComponent = <FormattedMessage id="client.tracker.errorUnexpected" defaultMessage={errorMessage} />;
      }
      setErrorComponent(newErrorComponent);
      setHelpUrl(newHelpUrl);
    });
  };

  const setParameters = (event) => {
    let newTrackerParams = Object.assign({}, trackerParams);
    let newModifiedParams = modifiedParams;
    if (event.target.name === 'INTERVAL') {
      newTrackerParams = event.target.value;
    }
    newTrackerParams[event.target.name] = event.target.value;
    newModifiedParams.add(event.target.name);
    setTrackerParams(newTrackerParams);
    setModifiedParams(newModifiedParams);
  };

  const convertAndSendParams = () => {
    const cmdList = [];
    modifiedParams.forEach((param) => {
      switch (param) {
        case 'GSM':
          if (trackerParams[param] === true) {
            cmdList.push('123456GSM1');
          } else {
            cmdList.push('123456GSM0');
          }
          break;
        case 'POWER_SAVING':
          if (trackerParams[param] === true) {
            cmdList.push('123456SP1');
          } else {
            cmdList.push('123456SP0');
          }
          break;
        case 'FALL_DETECTION':
          if (trackerParams[param] === true) {
            cmdList.push('123456F1');
          } else {
            cmdList.push('123456F0');
          }
          break;
        case 'LED_INDICATOR':
          if (trackerParams[param] === true) {
            cmdList.push('123456LED1');
          } else {
            cmdList.push('123456LED0');
          }
          break;
        case 'VOICE_MONITOR':
          if (trackerParams[param] === true) {
            cmdList.push('123456P1');
          } else {
            cmdList.push('123456P0');
          }
          break;
        case 'OVERSPEED_INDICATOR':
          cmdList.push(`123456J1,${trackerParams[param].padStart(3, '0')}`);
          break;
        case 'INTERVAL':
          cmdList.push(`123456M,${trackerParams[param].padStart(3, '0')}`);
          break;
        default:
          break;
      }
    });
    cmdList.push('123456G');
    const data = {
      commands: `MCMD,${client.imei},123456J1,132`,
    };
    sendParams(data);
  };

  const sendParams = (params) => {
    axios({
      url: `https://${getDomain()}${host()}/api/clients/${client.id}/tracker-iot/program/`,
      method: 'PUT',
      responseType: 'json',
      data: params,
      headers: { Authorization: `Token ${getToken()}` },
    }).then((res) => {
      if (res.data) {
        setShowModal(true);
      }
    }).catch((err) => {
      console.log(err.response);
    })
  }

  const helpUrlComponent = () => {
    return (
      <div>
        {helpUrl &&
          <div>
            <FormattedMessage id="client.tracker.toKnowMore" defaultMessage="PARA SABER MAIS" />
            <a target="_blank" rel="noopener noreferrer" href={helpUrl}>
              <FormattedMessage id="client.tracker.clickHere" defaultMessage=" CLIQUE AQUI " />
            </a>
          </div>
        }
      </div>
    );
  };

  const rotateMap = showMap ? style.rotate : '';
  const rotateHistory = showHistory ? style.rotate : '';
  const rotateNormalized = showNormalized ? style.rotate : '';

  return (
    <div className="contactto-middle-content">
      <div className={showModal ? style.modal : style.hideModal}>
        <CustomModal
          text={<FormattedMessage id="client.tracker.igoConfigSaved" defaultMessage="Configurações enviadas para o dispositivo. \n Por favor, aguarde 5 minutos para que o equipamento seja atualizado." />}
          height={'160px'}
          btnText="OK"
          btnAction={() => setShowModal(false)}
        />
      </div>
      <div className={style.trackerTitle}>
        <span className="blue"><FormattedMessage id="client.tracker.trackerFrom" defaultMessage="RASTREADOR DE " /></span>
        <span className="grey">{client && `${client.first_name} ${client.last_name}`.toUpperCase()}</span>
      </div>
      <div className={style.trackerContent}>
        <div className={style.trackerMenu}>
          <div style={{ width: '100%', float: 'left' }}>
            <button
              className={style.trackerMenuOption}
              onClick={() => setShowMap(!showMap)}
            >
              <FormattedMessage id="client.tracker.mapHeader" defaultMessage="Localização" />
              <Order width="25" height="15" className={`${style.trackerSvg} ${rotateMap}`} />
            </button>
          </div>
          {showMap && (errorComponent 
            ?
              <div>
                <div className={style.trackerError}>{errorComponent} { helpUrlComponent() }</div>
              </div>
            :
              <MapPoint 
                pointList={pointList}
                lang={!localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang}
                fetchLatestPoint={fetchLatestPoint}
              />
            )
          }
          <div style={{ width: '100%', float: 'left', marginTop: '15px' }}>
            <button
              className={style.trackerMenuOption}
              onClick={() => setShowHistory(!showHistory)}
            >
              <FormattedMessage id="client.tracker.history" defaultMessage="Histórico" />
              <Order width="25" height="15" className={`${style.trackerSvg} ${rotateHistory}`} />
            </button>
          </div>
          {showHistory &&
            <HistoryMenu
              startDate={startDate}
              endDate={endDate}
              handleStartChange={setStartDate}
              handleEndChange={setEndDate}
              fetchPoints={fetchPoints}
              lang={!localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang}
            />
          }
          {!props.isOperator &&
            <div>
              <div style={{ width: '100%', float: 'left', marginTop: '15px' }}>
                <button
                  className={style.trackerMenuOption}
                  onClick={() => setShowProgramming(!showProgramming)}
                >
                  <FormattedMessage id="client.tracker.programming" defaultMessage="Programação" />
                  <Order width="25" height="15" className={`${style.trackerSvg} ${rotateNormalized}`} />
                </button>
              </div>
              {showProgramming &&
                <IGOProgrammingComponent
                  trackerParams={trackerParams}
                  setParameters={setParameters}
                  convertAndSendParams={convertAndSendParams}
                />
              }
            </div>
          }

        </div>
      </div>
    </div>
  )

};

TrackerIGO.propTypes = {
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          account: PropTypes.string,
          first_name: PropTypes.string,
          last_name: PropTypes.string,
          is_test: PropTypes.boolean,
          equipment_id: PropTypes.number,
          equipment_name: PropTypes.string,
        }),
      ),
      ordering: PropTypes.string,
    }),
  ).isRequired,
  params: PropTypes.shape({
    clientId: PropTypes.string.isRequired,
  }).isRequired,
  loadClients: PropTypes.func.isRequired,
  isOperator: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    clients: state.clients,
    isOperator: state.loggedAttendant.groups.includes(3),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadClients: params => dispatch(clientsActions.loadClients(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackerIGO);
