import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Loading from '../../../../icons/Loading';
import RenderSecondaryChangedRow from './SecondaryChangedRow';

import style from './history.module.scss';

const history = gql`
  query($token: String!, $clientId: Int!, $offset: Int) {
    secondaryHistory(token:$token, clientId:$clientId, offset:$offset) {
      historyId
      actionDate
      actionType
      actionUser {
        id
        firstName
        lastName
      }
      secondary {
        id
        firstName
        lastName
        cpf
        rg
        birthdate
        healthPlan
        generalInfo
        hospital
        gender
        limitations
        medications
        removalPlan
      }
    }
  }
`;

const RenderSecondaryAuditLog = ({ clientId }) => {
  let stopFetching = false;
  let offset = 0;

  return (
    <Query query={history} variables={{ token: sessionStorage.activeToken, offset, clientId }} fetchPolicy="cache-and-network">
      {({ loading, error, data, fetchMore }) => {
        if (error) {
          return <div>Error ${error}</div>;
        }
        console.log(data);
        return (
          <div>
            <div className={style.tableHeader}>
              <div className={style.tableHeaderColumn}>
                <FormattedMessage id="history.type" defaultMessage="Tipo" />
              </div>
              <div className={style.tableHeaderColumn}>
                <FormattedMessage id="history.date" defaultMessage="Data" />
              </div>
              <div className={style.headerChangedByColumn}>
                <FormattedMessage id="history.modifiedBy" defaultMessage="Modificado Por" />
              </div>
              <div className={style.tableHeaderColumn} />
            </div>
            <div
              id="table-body"
              className={style.tableBody}
              onScroll={() => {
                const doc = document.getElementById('table-body');
                if ((doc.scrollTop + doc.offsetHeight) >= doc.scrollHeight) {
                  if (data.secondaryHistory && !loading && !stopFetching) {
                    fetchMore({
                      variables: { offset: data.secondaryHistory.length },
                      updateQuery: (prev, { fetchMoreResult }) => {
                        if (fetchMoreResult.secondaryHistory
                          && fetchMoreResult.secondaryHistory.length === 0) {
                          stopFetching = true;
                          return prev;
                        }
                        return Object.assign({}, prev, {
                          secondaryHistory: [...prev.secondaryHistory, ...fetchMoreResult.secondaryHistory],
                        });
                      },
                    })
                  }
                }
              }}
            >
              {data?.secondaryHistory?.map((log, index, array) => (
                <RenderSecondaryChangedRow
                  key={log.historyId}
                  log={log}
                  index={index}
                  array={array}
                />
              ))
              }
              {loading && <Loading text={<FormattedMessage id="attend.list.loading" defaultMessage="Carregando" />} />}
            </div>
          </div>
        )
      }}
    </Query>
  )

}

RenderSecondaryAuditLog.propTypes = {
  clientId: PropTypes.string.isRequired,
};

export default RenderSecondaryAuditLog;
