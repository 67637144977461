import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function attendantsReducer(state = initialState.attendants, action) {
  let newState = [];
  switch (action.type) {
    case types.LOAD_ATTENDANTS_SUCCESS:
      return [{
        ordering: action.ordering,
        data: action.attendants
      }]
    case types.EDIT_ATTENDANT:
      newState = state.map(dataList => {
        if (dataList.data && dataList.data.length > 0) {
          return {
            ordering: dataList.ordering,
            data: dataList.data.map(attendant =>  attendant.id === action.attendant.id ? action.attendant : attendant)
          };
        }
        return dataList;
      });
      return newState;
    case types.NEW_ATTENDANT:
      // myArray.filter(f => f !== newArrayItem).concat([newArrayItem])
      newState = state.map(dataList => {
        return {
          ordering: dataList.ordering,
          // data: [...dataList.data, action.attendant],
          data: [...dataList.data].filter(att => att.id !== action.attendant.id).concat([action.attendant]) ,
        }
      });
      return newState;
    default:
      return state;
  }
}



// /*
//   Inputs:
//     state = Estado atual do objeto, caso não seja passado é utilizado o estado default do arquivo(initalstate.attendants)
//     action = Ação aplicada sobre o objeto

//   Types:
//     LOAD_ATTENDANTS_SUCCESS = Atendentes carregados com sucesso
//     RELOAD_ATTENDANTS_SUCCESS = Atendentes recarregados com sucesso

//    attendantsReducer:
//     Caso atendentes sejam carregados com sucesso, retorna a listagem de atendentes ordernados.
//     Caso atendentes sejam recarregado com sucesso, retorno a listagem de atendimentos ordernados.
// */
// export default function attendantsReducer(
//   state = initialState.attendants,
//   action,
// ) {
//   const hasOrdering = state.some(
//     attendant => attendant.ordering === action.ordering,
//   );
//   if (action.type === types.LOAD_ATTENDANTS_SUCCESS && !hasOrdering) {
//     return [
//       ...state,
//       {
//         ordering: action.ordering,
//         data: action.attendants,
//       },
//     ];
//   }
//   if (action.type === types.RELOAD_ATTENDANTS_SUCCESS) {
//     return [
//       {
//         ordering: action.ordering,
//         data: action.attendants,
//       },
//     ];
//   }
//   return state;
// }
