import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import style from './attendSolvedBy.module.scss';
import '../../../../css/attendComponent.scss';

const AttendSolvedBy = ({ attendant, contact }) => (
  <div className={style['solved-wrapper']}>
    {attendant &&
      <div className="col-sm-12 col-md-8 col-lg-8">
        <span className="component-header" >
          <FormattedMessage id="attend.closed.attendant" defaultMessage="ATENDENTE" />
        </span>
        <div className="component-row">
          <span className="component-label"><FormattedMessage id="general.fullName" defaultMessage="Nome" /> </span>
          <span className="component-text">{attendant.first_name} {attendant.last_name}</span>
        </div>
        <div className="component-row">
          <span className="component-label"><FormattedMessage id="general.cellphone" defaultMessage="Celular" /> </span>
          <span className="component-text">{!attendant.user_info.mobile ? 'Sem celular' : attendant.user_info.mobile}</span>
        </div>
      </div>
    }
    {contact &&
      <div className="col-sm-12 col-md-8 col-lg-8">
        <span className="component-header" >
          <FormattedMessage id="attend.closed.emergencyContact" defaultMessage="CONTATO DE EMERGÊNCIA" />
        </span>
        <div className="component-row">
          <span className="component-label"><FormattedMessage id="general.fullName" defaultMessage="Nome" /> </span>
          <span className="component-text">{contact.first_name} {contact.last_name}</span>
        </div>
        <div className="component-row">
          <span className="component-label"><FormattedMessage id="contact.form.priority" defaultMessage="Prioridade" /> </span>
          <span className="component-text">{contact.priority}</span>
        </div>
        <div className="component-row">
          <span className="component-label"><FormattedMessage id="contact.form.relationship" defaultMessage="Relação" /> </span>
          <span className="component-text">{contact.relationship}</span>
        </div>
        <div className="component-row">
          <span className="component-label"><FormattedMessage id="general.cellphone" defaultMessage="Celular" /> </span>
          <span className="component-text">{!contact.phones[0].phone ? 'Sem celular' : contact.phones[0].phone}</span>
        </div>
      </div>
    }
  </div>
);

AttendSolvedBy.defaultProps = {
  attendAudio: '',
  contact: null,
  attendant: null,
};

AttendSolvedBy.propTypes = {
  attendant: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    user_info: PropTypes.shape({
      mobile: PropTypes.string,
      phone: PropTypes.string,
    }),
  }),
  contact: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    relationship: PropTypes.string,
    priority: PropTypes.number,
  }),
};

export default AttendSolvedBy;
