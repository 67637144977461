import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import style from './attendCallType.module.scss';

const AttendContacts = ({ contacts, contactId, setContacted, setPerson }) => (
  <div className="table-responsive">

    {/* <!--Custom Responsive Table--> */}
    <div className="custom-table-wrapper">
      <div className="table-headers">
        <div className="custom-table-header flex-basis-30">
          <p><FormattedMessage id="client.page.name" defaultMessage="NOME" /></p>
        </div>
        <div className="custom-table-header flex-basis-20">
          <p><FormattedMessage id="contact.form.relationship" defaultMessage="RELAÇÃO" /></p>
        </div>
        <div className="custom-table-header flex-basis-20">
          <p><FormattedMessage id="contact.form.hasKeys" defaultMessage="CHAVES" /></p>
        </div>
        <div className="custom-table-header flex-basis-20">
          <p><FormattedMessage id="contact.page.phoneOne" defaultMessage="TELEFONE 1" /></p>
        </div>
        <div className="custom-table-header flex-basis-20">
          <p><FormattedMessage id="contact.page.phoneTwo" defaultMessage="TELEFONE 2" /></p>
        </div>
        <div className="custom-table-header flex-basis-10" >
          <p className={style['answered-column']}>
            <FormattedMessage id="contact.page.answered" defaultMessage="ATENDEU" /></p>
        </div>
        <div className="custom-table-header flex-basis-10">
          <p className={style['contacted-column']}>
            <FormattedMessage id="contact.page.contacted" defaultMessage="CONTATADO" /></p>
        </div>
      </div>
      <div className="table-bar" />
      {contacts.filter(contact => !contact.is_deleted).map(contact => (
        <div className="custom-table-body">
          {/*
              <!--Row Starts--> */}
          <div className="custom-table-row">

            <div className="custom-table-cell username-cell flex-basis-30">
              <span className="mobile-column-name">
                <FormattedMessage id="client.page.name" defaultMessage="NOME" />
              </span>
              <div className="center-wrapper">
                <span className="profile_name">{contact.first_name} {contact.last_name}</span>
              </div>
            </div>

            <div className="custom-table-cell flex-basis-20">
              <span className="mobile-column-name">
                <FormattedMessage id="contact.form.relationship" defaultMessage="RELAÇÃO" />
              </span>
              <div className="center-wrapper">
                <p>{contact.relationship}</p>
              </div>
            </div>

            {/* <!--Text in table cell--> */}
            <div className="custom-table-cell flex-basis-20">
              <span className="mobile-column-name">
                <FormattedMessage id="contact.form.hasKeys"defaultMessage="CHAVES" />
              </span>
              <div className="center-wrapper">
                <p>{contact.has_keys
                  ? <FormattedMessage id="general.yes" defaultMessage="Sim" />
                  : <FormattedMessage id="general.no" defaultMessage="Não" />
                }</p>
              </div>
            </div>

            <div className="custom-table-cell flex-basis-20">
              <span className="mobile-column-name">
                <FormattedMessage id="contact.page.phoneOne" defaultMessage="TELEFONE 1" />
              </span>
              <div className="center-wrapper">
                <p>{contact.phones
                  ? contact.phones.find(phone => phone.phone_type === 'Primary').phone
                  : '-'
                }</p>
              </div>
            </div>


            <div className="custom-table-cell flex-basis-20">
              <span className="mobile-column-name">
                <FormattedMessage id="contact.page.phoneTwo" defaultMessage="TELEFONE 2" />
              </span>
              <div className="center-wrapper">
                <p>{contact.phones && contact.phones.filter(phone => phone.phone_type !== 'Primary').length >= 1
                  ? contact.phones.filter(phone => phone.phone_type !== 'Primary')[0].phone
                  : '-'
                }</p>
              </div>
            </div>

            <div className="custom-table-cell flex-basis-10">
              <span className="mobile-column-name">
                <FormattedMessage id="contact.page.answered" defaultMessage="ATENDEU" />
              </span>
              <div className="center-wrapper">
                <p>
                  <input
                    data-testid={`contact-answered-${contact.id}`}
                    type="checkbox"
                    disabled={(contactId && contactId !== contact.id)}
                    checked={
                      (contactId && contactId === contact.id)
                    }
                    name={`${contact.id}`}
                    onChange={(ev) => {
                      const cont = {
                        type: 'contact',
                        cont: contact,
                        checked: ev.target.checked,
                      };
                      setPerson(cont);
                      if (ev.target.checked && !document.querySelector(`input#contacted${contact.id}`).checked) {
                        document.querySelector(`input#contacted${contact.id}`).checked = true;
                        setContacted(cont);
                      }
                    }}
                  />
                </p>
              </div>
            </div>

            <div className="custom-table-cell flex-basis-10">
              <span className="mobile-column-name">
                <FormattedMessage id="contact.page.contacted" defaultMessage="CONTATADO" />
              </span>
              <div className="center-wrapper">
                <p>
                  <input
                    id={`contacted${contact.id}`}
                    type="checkbox"
                    name={`${contact.id}`}
                    onChange={(ev) => {
                      const cont = {
                        type: 'contact',
                        cont: contact,
                        checked: ev.target.checked,
                      };
                      setContacted(cont);
                    }}
                  />
                </p>
              </div>
            </div>
            {/* <div className="custom-table-cell flex-basis-10">
              <span className="mobile-column-name" />
              <div className="center-wrapper">
                <button className="btn btn--color-tecno">
                  Lembrete
                </button>
              </div> */}
            {/* </div> */}
          </div>
          {/* <!--Row Ends--> */}
        </div>))}
    </div>
  </div>);

AttendContacts.propTypes = {
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      has_keys: PropTypes.bool,
      priority: PropTypes.number,
      client: PropTypes.number,
      is_active: PropTypes.bool,
      phones: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          phone_type: PropTypes.string,
          phone: PropTypes.string,
          contact: PropTypes.number,
        }),
      ),
    }),
  ).isRequired,
  attendantId: PropTypes.number.isRequired,
  contactId: PropTypes.number.isRequired,
  setContacted: PropTypes.func.isRequired,
  setPerson: PropTypes.func.isRequired,
};

export default AttendContacts;
