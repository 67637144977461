import React, { useState } from 'react';
import useAllOnlineUsers from './OnlineHooks';
import ManagementMenu from '../ManagementMenu';
import Order from '../../../../icons/Order';
import { formatDateFromString } from '../../../../BLL/general';
import { userGroup } from '../../../../BLL/dictionary';
import { FormattedMessage } from 'react-intl';

import '../../../../css/icons.scss';
import style from './onlinePage.module.scss';

const OnlineAttendantsPage = (props) => {
  const [ordering, setOrdering] = useState('-last_online_at')
  const { data, loading, error} = useAllOnlineUsers(sessionStorage.activeToken, ordering);

  const svgNameRotation = ordering === '-name' ? 'icons-order--rotate' : '';
  const svgCompanyNameRotation = ordering === '-company_name' ? 'icons-order--rotate' : '';
  const svgGroupRotation = ordering === '-group' ? 'icons-order--rotate' : '';
  const svgLastOnlineAtRotation = ordering === '-last_online_at' ? 'icons-order--rotate' : '';

  if (loading) {
    return (
      <div className="contactto-middle-content" style={{ overflowX: 'hidden' }}>
        <div className="row">
          <div className="pagetitle"><FormattedMessage id="management.header" defaultMessage="GERENCIAR" /></div>
        </div>
        <ManagementMenu history={props.history} />
        <FormattedMessage id="attend.list.loading" defaultMessage="Carregando ..." />
      </div>
    )
  }

  if (error) {
    return (
      <div className="contactto-middle-content" style={{ overflowX: 'hidden' }}>
        Erro ao carregar lista de usuários online: {error.message}
      </div>
    )
  }

  return (
    <div className="contactto-middle-content" style={{ overflowX: 'hidden' }}>
      <div className="row">
        <div className="pagetitle">
          <FormattedMessage id="management.header" defaultMessage="GERENCIAR" />
        </div>
      </div>
      <ManagementMenu history={props.history} />
      <div className={style.content}>
        <div className={style.table}>
          <div className={style.header}>
            <div className={style.headerStatus}> Status </div>
            <div className={style.headerName}>
              <button
                className={style.headerBtn}
                style={{ color: ordering.indexOf('name') !== -1 ? '#00b8cb' : '#333' }}
                onClick={() => setOrdering(ordering === 'name' ? '-name' : 'name')}
              >
                <FormattedMessage id="general.fullName" defaultMessage="Nome" />
                <Order width="15" height="10" className={svgNameRotation} />
              </button>
            </div>
            <div className={style.headerCompanyName}>
              <button
                className={style.headerBtn}
                style={{ color: ordering.indexOf('company_name') !== -1 ? '#00b8cb' : '#333' }}
                onClick={() => setOrdering(ordering === 'company_name' ? '-company_name' : 'company_name')}
              >
                <FormattedMessage id="client.page.company" defaultMessage="Empresa" />
                <Order width="15" height="10" className={svgCompanyNameRotation} />
              </button>
            </div>
            <div className={style.headerGroupName}>
              <button
                className={style.headerBtn}
                style={{ color: ordering.indexOf('group') !== -1 ? '#00b8cb' : '#333' }}
                onClick={() => setOrdering(ordering === 'group' ? '-group' : 'group')}
              >
                <FormattedMessage id="attendant.group" defaultMessage="Grupo" />
                <Order width="15" height="10" className={svgGroupRotation} />
              </button>
            </div>
            <div className={style.headerLastOnlineAt}>
              <button
                className={style.headerBtn}
                style={{ color: ordering.indexOf('last_online_at') !== -1 ? '#00b8cb' : '#333' }}
                onClick={() => setOrdering(ordering === 'last_online_at' ? '-last_online_at' : 'last_online_at')}
              >
                <FormattedMessage id="management.iot.lastEntry" defaultMessage="Último Registro" />
                <Order width="15" height="10" className={svgLastOnlineAtRotation} />
              </button>
            </div>
          </div>

          <div className={style.body}>
            {data.map((item) => (
              <div className={style.row} key={item.name}>
                <div className={style.bodyStatus}>
                  <div className={item.isOnline ? style.online : style.offline}/>
                </div>
                <div className={style.bodyName}>
                  {item.name}
                </div>
                <div className={style.bodyCompanyName}>
                  {!item.companyName ? 'Sistema' : item.companyName}
                </div>
                <div className={style.bodyGroupName}>
                  {userGroup(parseInt(item.group))}
                </div>
                <div className={style.bodyLastOnline}>
                  {formatDateFromString(item.lastOnlineAt)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OnlineAttendantsPage;