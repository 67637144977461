export const host = () =>{
  if(process.env.REACT_APP_STAGE  === 'development'){
    return '.contactto.local';
  } else if (process.env.REACT_APP_STAGE === 'homologation') {
    return '-homologation.contactto.care';
  }
  else {
    return '.contactto.care';
  }
};

export const getDomain = () => {
  if(sessionStorage.activeDomain) {
    return sessionStorage.activeDomain;
  }

  if (!localStorage.domainList) {
    return ''
  }

  const domainList = JSON.parse(localStorage.domainList);
  if (domainList.length > 0) {
    const vidafoneDomain = domainList.find(domain => domain === 'vidafone');
    const defaultDomain = !vidafoneDomain ? domainList[0] : vidafoneDomain;
    sessionStorage.setItem('activeDomain', defaultDomain);
    return !defaultDomain ? domainList[0] : defaultDomain;
  }

  return 'error';
}

export const getToken = () => {
  if(sessionStorage.activeToken) {
    return sessionStorage.activeToken;
  }

  if(!localStorage.domainInfo) {
    return 'error'
  }

  if (!localStorage.domainList) {
    return ''
  }

  if(sessionStorage.activeDomain) {
    const domainInfo = JSON.parse(localStorage.domainInfo);
    const token = domainInfo[sessionStorage.activeDomain].token;
    sessionStorage.setItem('activeToken', token);
    return token;
  }

  const domainList = JSON.parse(localStorage.domainList);
  if (domainList.length > 0) {
    const vidafoneDomain = domainList.find(domain => domain === 'vidafone');
    const defaultDomain = !vidafoneDomain ? domainList[0] : vidafoneDomain;
    const domainInfo = JSON.parse(localStorage.domainInfo);
    const token = domainInfo[defaultDomain].token;
    sessionStorage.setItem('activeToken', token);
    return token
  }
  return 'error';
}

export const getInfo = () => {
  if(sessionStorage.activeDomain) {
    const domainInfo = JSON.parse(localStorage.domainInfo);
    return {
      user: domainInfo[sessionStorage.activeDomain].user,
      token: domainInfo[sessionStorage.activeDomain].token,
      status: domainInfo[sessionStorage.activeDomain].status,
      isPaid: domainInfo[sessionStorage.activeDomain].is_paid,
      isActive: domainInfo[sessionStorage.activeDomain].is_active,
    }
  }

  if (!localStorage.domainList) {
    return ''
  }

  const domainList = JSON.parse(localStorage.domainList);
  if (domainList.length > 0) {
    const vidafoneDomain = domainList.find(domain => domain === 'vidafone');
    const defaultDomain = !vidafoneDomain ? domainList[0] : vidafoneDomain;
    const domainInfo = JSON.parse(localStorage.domainInfo);
    return {
      user: domainInfo[defaultDomain].user,
      token: domainInfo[defaultDomain].token,
      status: domainInfo[defaultDomain].status,
      isPaid: domainInfo[defaultDomain].is_paid,
      isActive: domainInfo[defaultDomain].is_active,
    }
  }
  return 'error'
}
