/* eslint-disable import/no-anonymous-default-export */
export default {
  en: {
    attendDashboardId: 'Event Analysis',
    timeDashboardId: 'Time Analysis',
    contactDashboardId: 'Contact Analysis',
    equipmentDashboardId: 'Equipment Analysis',
    ev07BDashboardId: 'EV07B Battery Analysis',
    ataDashboardId: 'Ata Analysis',
    clientDashboardId: 'User Analysis',
    attendCountingDashboardId: 'Event counting Analysis'
  },
  pt: {
    attendDashboardId: 'Análise de Atendimentos',
    timeDashboardId: 'Análise de Tempo',
    contactDashboardId: 'Análise de Contatos',
    equipmentDashboardId: 'Análise de Equipamentos',
    ev07BDashboardId: 'Análise de baterias - EV07B',
    ataDashboardId: 'Análise de Atas',
    clientDashboardId: 'Análise de Usuários',
    attendCountingDashboardId: 'Análise da Contagem de Atendimentos'
  }
}
