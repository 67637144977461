import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import messages from '../../BLL/locale/messages';
import messageTags from '../../BLL/locale/messagesTags';
import style from './modalAttendFilter.module.scss';
import formStyle from '../../css/form.module.scss';

const ModalAttendFilter = (props) => {
  const wrapperRef = useRef(null);
  const [attendId, setAttendId] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [attendTag, setAttendTag] = useState('');
  const [hasAudio, setHasAudio] = useState(-1);

  useEffect(() => {
    document.addEventListener('mousedown', clickedOutside);

    return () => {
      document.removeEventListener('mousedown', clickedOutside);
    }
  }, []);

  const clickedOutside = (ev) => {
    if (wrapperRef.current && !wrapperRef.current.contains(ev.target)) {
      props.closeModal();
    }
  }

  const generateAndSendFilter = () => {
    let attendTest = '';
    let attendAudio = '';
    let id = '';
    let companyIdUrl = '';
    let tag = '';

    switch (parseInt(hasAudio, 10)) {
      case -1:
        break;
      case 1: {
        attendAudio = 'has_audio=true';
        break;
      }
      case 0: {
        attendAudio = 'has_audio=false';
        break;
      }
      default:
        break;
    }

    if (companyId) {
      companyIdUrl = `company=${companyId}`;
    }

    if (attendId) {
      id = `id=${attendId}`;
    }

    if (attendTag) {
      tag = `attend_tag=${attendTag}`;
    }

    let url = `${attendTest}`;
    url = `${url}${attendAudio ? `&${attendAudio}` : ''}`;
    url = `${url}${companyIdUrl ? `&${companyIdUrl}` : ''}`;
    url = `${url}${id ? `&${id}` : ''}`;
    url = `${url}${tag ? `&${tag}` : ''}`;
    props.filterAttends(url);
  };

  const tagOptions = props.attendTags.map(tag => ({
    value: tag.id,
    label: messageTags[props.lang][tag.id],
    name: messageTags[props.lang][tag.id],
  }));

  return (
    <div className={style['filter-wrapper']}>
      <div className={style['filter-container']} ref={wrapperRef}>
        <div className={style['filter-header']}>
          <FormattedMessage id="attend.filter.header" defaultMessage="FILTRAR ATENDIMENTOS" />
        </div>

        <div className="form-group">
          <label className="text-muted" style={{ fontSize: '0.8rem'}} htmlFor={attendId}>
            <FormattedMessage id="inputContainer.attendId" defaultMessage="Identificação" />
          </label>
          <input
            className="form-control"
            maxLength="15"
            name="number"
            onChange={(ev) => setAttendId(ev.target.value)}
            type="number"
            value={attendId}
            style={{ fontSize: '0.8rem'}}
          />
        </div>

        <div className="form-group" >
          <label className="text-muted" style={{ fontSize: '0.8rem'}} htmlFor="hasAudio">
            <FormattedMessage id="attend.filter.hasAudio" defaultMessage="Tem áudio" />:
            </label>
          <select
            className={`custom-select input-sm ${
              formStyle['select--enable']
              }`}
            name="hasAudio"
            value={hasAudio}
            onChange={(ev) => setHasAudio(ev.target.value)}
            style={{ fontSize: '0.8rem'}}
          >
            <option value={-1}>{messages[props.lang]['filter.ignore']}</option>
            <option value={1} >{messages[props.lang]['general.yes']}</option>
            <option value={0} >{messages[props.lang]['general.no']}</option>
          </select>
        </div>

        {props.loggedUser && props.loggedUser.groups.length === 1 &&
          <div className="form-group" >
            <label className="text-muted" style={{ fontSize: '0.8rem'}} htmlFor="companyId">
              <FormattedMessage id="attend.filter.company" defaultMessage="Empresa" />:
          </label>
            <select
              className={`custom-select input-sm ${
                formStyle['select--enable']
                }`}
              name="companyId"
              value={companyId}
              onChange={(ev) => setCompanyId(ev.target.value)}
              style={{ fontSize: '0.8rem'}}
            >
              <option value={''}>{messages[props.lang]['filter.ignore']}</option>
              {props.companies && props.companies
              .sort((compA, compB) => compA.name.localeCompare(compB.name))
              .map(item =>
                <option key={item.id} value={item.id} >
                  {item.name}
                </option>,
              )}
            </select>
          </div>
        }

        <div className="form-group" >
          <label className="text-muted" style={{ fontSize: '0.8rem'}} htmlFor="attendTag">
            <FormattedMessage id="attend.filter.attendTag" defaultMessage="Tipo de Atendimento" />:
            </label>
          <select
            className={`custom-select input-sm ${
              formStyle['select--enable']
              }`}
            name="attendTag"
            value={attendTag}
            onChange={(ev) => setAttendTag(ev.target.value)}
            style={{ fontSize: '0.8rem'}}
          >
            <option value={''}>{messages[props.lang]['filter.ignore']}</option>
            {tagOptions && tagOptions
            .sort((optionA, optionB) => optionA.name.localeCompare(optionB.name))
            .map(item =>
              <option key={item.value} value={item.value} >
                {item.name}
              </option>,
            )}
          </select>
        </div>

        <div className="form-group" >
          <button
            className={style['filter-btn']}
            onClick={generateAndSendFilter}
            type="button"
          >
            <span className={style['filter-button-bold']}>
            <i className="fa fa-filter" aria-hidden="true" />
              <FormattedMessage id="filter.btn" defaultMessage="FILTRAR" />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

ModalAttendFilter.propTypes = {
  filterAttends: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      user_set: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          is_active: PropTypes.bool,
          first_name: PropTypes.string,
          last_name: PropTypes.string,
          email: PropTypes.string,
          groups: PropTypes.arrayOf(
            PropTypes.number,
          ),
        }),
      ),
    }),
  ).isRequired,
  loggedUser: PropTypes.shape({
    groups: PropTypes.arrayOf(
      PropTypes.number,
    ),
    id: PropTypes.number,
    is_active: PropTypes.bool,
    username: PropTypes.string,
  }).isRequired,
  attendTags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })).isRequired,
};

function mapStateToProps(state) {
  return {
    loggedUser: state.loggedAttendant,
    companies: state.groups.filter(grp => ![1, 2, 3, 4].includes(grp.id)),
    attendTags: state.attendTags,
    lang: state.locale.lang,
  };
}

export default connect(mapStateToProps)(ModalAttendFilter);
