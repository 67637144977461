import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import style from './errorComponent.module.scss';

const ErrorComponent = ({ errorCode, errorMsg, handleButton }) =>
  <div className={style.container}>
    <button id='error-component-close-btn' onClick={() => handleButton()} className={style.button}>
      <i className="far fa-window-close fa-2x" aria-hidden="true"/>
    </button>
    <span className={`${style.text} ${style['text-title']}`}>
      <FormattedMessage id="error.component.title" defaultMessage="Oops! Parece que ocorreu um erro ao tentar salvar" />
    </span>
    <span className={style.text}>
      <span className={style['text-subtitle']}>
        <FormattedMessage id="error.component.errorcodetitle" defaultMessage="Código do erro" />:
      </span> {errorCode}
    </span>
    <span className={style.text}>
      <span className={style['text-subtitle']}>
        <FormattedMessage id="error.component.infotitle" defaultMessage="Informações" />:
      </span> {errorMsg}
    </span>
  </div>;

export default ErrorComponent;
