export default class WarningTimer {
  constructor(current, alarm, limit) {
    this.setInitialTimes(current, alarm, limit);
    this.duration = this.limit - this.alarm;
  }

  setInitialTimes(current, alarm, limit) {
    this.alarm = new Date(alarm);
    this.limit = new Date(limit);
    this.current = new Date(current);

    // Adjust the current time if it is before the alarm time (discrepancy in the equipment)
    if (this.current < this.alarm) {
      this.current = new Date(this.alarm);
    }
  }

  timer(counter) {
    const time = ((this.limit - this.current) - (counter * 1000));
    if (this.current < this.limit && time <= this.duration && time >= 1000) {
      return time;
    }
    return -1;
  }

}
