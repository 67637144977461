import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Edit from '../../../icons/Edit';

import style from './transmitterPage.module.scss';

const TransmitterRow = ({
  transmitter,
  isAdmin,
  isOwner,
  isManager,
  setTextIconDetail,
  setModalInfo,
  showModal,
}) => {
  const setEmptyText = () => {
    setTextIconDetail('');
  };
  return (
    <div id={`transmitter-table-row-container-${transmitter.zone}`} className={style.row}>
      <Link className={style.actions} to={`/app/transmitters/detail/${transmitter.client}/${transmitter.id}`}>
        <div className={`${style.column} ${style.zone}`}>
          {transmitter.zone}
        </div>
        <div className={`${style.column} ${style.description}`}>
          {transmitter.description}
        </div>
      </Link>
      <div className={`${(isAdmin || isOwner || isManager) ? style.adminActions : style.otherActions}`}>
        {/* <Link
          className={style.actions}
          onMouseOver={() => setTextIconDetail(<FormattedMessage id="general.columnView" defaultMessage="Visualizar" />)}
          onMouseOut={() => setTextIconDetail('')}
          to={`/app/transmitters/detail/${transmitter.client}/${transmitter.id}`}
        >
          <i className="far fa-eye" style={{ fontSize: 23, color: '#666', paddingTop: '0.5rem' }} />
        </Link> */}
        {(isAdmin || isOwner || isManager) &&
          <Link
            className={style.actions}
            onMouseOver={() => setTextIconDetail(<FormattedMessage id="general.columnEdit" defaultMessage="Editar" />)}
            onMouseOut={() => setTextIconDetail('')}
            to={`/app/transmitters/form/edit/${transmitter.client}/${transmitter.id}`}
          >
            <Edit 
            className={style.icons} 
            width="28" 
            height="28"
            style={{paddingTop: '0.5rem'}} />
          </Link>
        }
        {(isAdmin || isOwner || isManager) &&
          <button
            id={`transmitter-table-body-delete-${transmitter.zone}`}
            onMouseOver={() =>
              setTextIconDetail(<FormattedMessage id="general.columnDelete" defaultMessage="Deletar" />)}
            onMouseOut={() => setTextIconDetail('')}
            onClick={() => {
              if (!showModal) {
                setModalInfo(true, transmitter);
              }
            }}
            className={style.actions}
            style={{ border: 0, backgroundColor: 'transparent', padding: 0 }}>
            <div className={style.actions}>
              <i className="far fa-trash-alt" style={{ fontSize: 23, color: '#666', paddingTop: 3 }} />
            </div>
          </button>}
      </div>
    </div>
  );
};

TransmitterRow.propTypes = {
  showModal: PropTypes.bool.isRequired,
  transmitter: PropTypes.shape({
    id: PropTypes.number,
    zone: PropTypes.string,
    client: PropTypes.number,
    description: PropTypes.string,
    identifier: PropTypes.string,
    general_info: PropTypes.string,
    created_date: PropTypes.string,
    modified_date: PropTypes.string,
    created_by: PropTypes.number,
    modified_by: PropTypes.number,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool.isRequired,
  isManager: PropTypes.bool.isRequired,
  setTextIconDetail: PropTypes.func.isRequired,
  setModalInfo: PropTypes.func.isRequired,
};

export default TransmitterRow;
