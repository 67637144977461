import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Order from '../../../../icons/Order';
import ManagementMenu from '../ManagementMenu';

import RenderAtaData from './AtaData';

import '../../../../css/icons.scss';
import '../../../../css/contactto.scss';
import style from './ataDeviceList.module.scss';

const AtaDeviceList = (props) => {
  const [ordering, setOrdering] = useState('account');
  const svgNameRotation = ordering === '-full_name' ? 'icons-order--rotate' : '';
  const svgAccountRotation = ordering === '-account' ? 'icons-order--rotate' : '';
  const svgNumberRotation = ordering === '-number' ? 'icons-order--rotate' : '';
  const svgStatusRotation = ordering === '-status' ? 'icons-order--rotate' : '';

  return (
    <div className="contactto-middle-content" style={{ overflowX: 'hidden' }}>
      <div className="row">
        <div className="pagetitle"><FormattedMessage id="management.header" defaultMessage="GERENCIAR" /></div>
      </div>
      <ManagementMenu history={props.history} />
      <div className={style.content}>
        <div className={style.table}>
          <div className={style.header}>
            <div className={style.headerAtaContainer}>
              <button
                className={style.headerBtn}
                style={{ color: ordering.indexOf('number') !== -1 ? '#00b8cb' : '#333' }}
                onClick={() => setOrdering(ordering === 'number' ? '-number' : 'number')}
              >
                ATA <Order width="15" height="10" className={svgNumberRotation} />
              </button>
            </div>
            <div className={style.headerAccountContainer}>
              <button
                className={style.headerBtn}
                style={{ color: ordering.indexOf('account') !== -1 ? '#00b8cb' : '#333' }}
                onClick={() => setOrdering(ordering === 'account' ? '-account' : 'account')}
              >
                <FormattedMessage id="client.page.columnAccount" defaultMessage="Conta" /> <Order width="15" height="10" className={svgAccountRotation} />
              </button>
            </div>
            <div className={style.headerNameContainer}>
              <button
                className={style.headerBtn}
                style={{ color: ordering.indexOf('full_name') !== -1 ? '#00b8cb' : '#333' }}
                onClick={() => setOrdering(ordering === 'full_name' ? '-full_name' : 'full_name')}
              >
                <FormattedMessage id="general.fullName" defaultMessage="Nome" /> <Order width="15" height="10" className={svgNameRotation} />
              </button>
            </div>
            <div className={style.headerPortText}> <FormattedMessage id="ata.header.port" defaultMessage="Porta" /> </div>
            <div className={style.headerStatusContainer}>
              <button
                className={style.headerBtn}
                style={{ color: ordering.indexOf('status') !== -1 ? '#00b8cb' : '#333' }}
                onClick={() => setOrdering(ordering === 'status' ? '-status' : 'status')}
              >
                Status <Order width="15" height="10" className={svgStatusRotation} />
              </button>
            </div>
          </div>
        </div>

        <RenderAtaData order={ordering}/>
      </div>
    </div>
  )

}

export default AtaDeviceList;
