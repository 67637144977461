import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ManagementMenu from '../ManagementMenu';
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import style from './clientLocation.module.scss';
import ClientLocationMap from './ClientLocationMap';

const ClientLocationPage = (props) => {
  const { loggedAttendant, lang, companies, isSysAdm } = useSelector(
    (state) => ({
      loggedAttendant: state.loggedAttendant,
      lang: state.locale.lang,
      companies: state.groups.filter((grp) => ![1, 2, 3, 4].includes(grp.id)),
      isSysAdm: state.loggedAttendant.groups
        ? (state.loggedAttendant.groups[0] === 1 ||
            state.loggedAttendant.groups[0] === 4) &&
          state.loggedAttendant.groups.length === 1
        : false,
    })
  );
  const [selectedCompany, setSelectedCompany] = useState('0');

  useEffect(() => {
    if (!isSysAdm) {
      setSelectedCompany(companies.find(company => loggedAttendant.groups.includes(company.id).id))
    }
  }, [companies, isSysAdm, loggedAttendant]);

  return (
    <div className="contactto-middle-content" style={{ overflowX: 'hidden' }}>
      <div className="row">
        <div className="pagetitle"><FormattedMessage id="management.header" defaultMessage="GERENCIAR" /></div>
      </div>
      <ManagementMenu history={props.history} />
      <div className={style.content}>
        {isSysAdm &&
          <select
            id="companySelection"
            className="custom-select"
            value={selectedCompany}
            onChange={(e) => setSelectedCompany(e.target.value)}
          >
            <option value={0}> Selecionar uma empresa </option>
            {companies.length > 0 && companies.sort((compA, compB) => compA.name.localeCompare(compB.name)).map(company => (
              <option key={company.id} value={company.id}>{company.name}</option>
            ))}
          </select>
        }
        {(parseInt(selectedCompany) !== 0) &&
          <ClientLocationMap selectedCompany={selectedCompany} />
        }
      </div>
    </div>
  )
}

export default ClientLocationPage;
