import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import moment from 'moment';

import style from './attendAudioInformation.module.scss';
import '../../../../css/attendComponent.scss';

const AudioDuration = ({ url }) => {
  const [duration, setDuration] = useState(null);

  useEffect(() => {
    const audio = new Audio(url);
    audio.addEventListener('loadedmetadata', () => {
      setDuration(audio.duration);
    });
  }, [url]);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60).toString().padStart(2, '0');
    const seconds = Math.floor(timeInSeconds % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  if (duration === Infinity) {
    return (
      <span className="profile_name">
        <FormattedMessage id="attend.closed.noAudio" defaultMessage="Sem áudio"/>
      </span>
    )
  }

  return (
    <span className="profile_name">
      {duration ?
        formatTime(duration.toFixed(0)) :
        <FormattedMessage id="attend.closed.audioLoading" defaultMessage="Carregando..."/>
      }
    </span>
  );
};

const AttendantOrigin = ({ fromId }) => {
  const { attendants } = useSelector((state) => ({
    attendants: state.attendants[0]?.data
  }));
  const [attendant, setAttendant] = useState(undefined);

  useEffect(() => {
    if (attendants.length > 0) {
      const newAttendant = attendants.find((att) => att?.user_info?.extension_id === parseInt(fromId, 10));
      setAttendant(newAttendant);

    }
  }, [fromId, attendants]);

  if (!attendant) {
    return (
      <span className="profile_name">
        <FormattedMessage id="attend.closed.searchingFor" defaultMessage="Searching..."/>
      </span>
    );
  }

  return (
    <span className="profile_name">{attendant.first_name} {attendant.last_name}</span>
  );
}

const AttendAudioInformation = ({ aditionalAudio }) => {
  console.log(aditionalAudio);

  return (
    <div className={style.wrapper}>
      <div className="custom-table-wrapper">
        <div className="table-headers">
          <div className="custom-table-header flex-basis-30">
            <p><FormattedMessage id="attend.closed.audioDate" defaultMessage="DATA DO ÁUDIO" /></p>
          </div>
          <div className="custom-table-header flex-basis-30">
            <p><FormattedMessage id="attend.closed.audioOrigin" defaultMessage="ATENDENTE" /></p>
          </div>
          <div className="custom-table-header flex-basis-30">
            <p><FormattedMessage id="attend.closed.audioDestiy" defaultMessage="DESTINO" /></p>
          </div>
          <div className="custom-table-header flex-basis-30">
            <p><FormattedMessage id="attend.closed.audioType" defaultMessage="TIPO" /></p>
          </div>
          <div className="custom-table-header flex-basis-20">
            <p><FormattedMessage id="attend.closed.audioDuration" defaultMessage="DURAÇÃO" /></p>
          </div>
          <div className="custom-table-header flex-basis-10">
            <p><FormattedMessage id="attend.closed.downloadAudio" defaultMessage="BAIXAR" /></p>
          </div>
        </div>
        <div className="table-bar" />
        {aditionalAudio.map((elm) => (
          <div className="custom-table-body" key={elm.audio_file}>
            <div className="custom-table-row">
              {/* Date */}
              <div className="custom-table-cell username-cell flex-basis-30">
                <span className="mobile-column-name">
                  <FormattedMessage id="attend.closed.audioDate" defaultMessage="DATA DO ÁUDIO" />
                </span>
                <div className="center-wrapper">
                  <span className="profile_name">{moment(elm.audio_ts).format('HH:mm:ss - DD/MM/YYYY')}</span>
                </div>
              </div>
              {/* Origin */}
              <div className="custom-table-cell username-cell flex-basis-30">
                <span className="mobile-column-name">
                  <FormattedMessage id="attend.closed.audioOrigin" defaultMessage="ORIGEM" />
                </span>
                <div className="center-wrapper">
                  <AttendantOrigin fromId={elm.from_id}/>
                </div>
              </div>
              {/* Destiny */}
              <div className="custom-table-cell username-cell flex-basis-30">
                <span className="mobile-column-name">
                  <FormattedMessage id="attend.closed.audioDestiy" defaultMessage="DESTINO" />
                </span>
                <div className="center-wrapper">
                  <span className="profile_name">
                    {elm.to_id}
                  </span>
                </div>
              </div>
              {/* Type */}
              <div className="custom-table-cell username-cell flex-basis-30">
                <span className="mobile-column-name">
                  <FormattedMessage id="attend.closed.audioType" defaultMessage="TIPO" />
                </span>
                <div className="center-wrapper">
                  {elm.audio_file.includes('REMINDER') ? 
                    <FormattedMessage id="attend.closed.audioTypeReminder" defaultMessage="Lembrete" /> :
                    <FormattedMessage id="attend.closed.audioTypeAttend" defaultMessage="Atendimento" />
                  }
                </div>
              </div>
              {/* Duration */}
              <div className="custom-table-cell username-cell flex-basis-20">
                <span className="mobile-column-name">
                  <FormattedMessage id="attend.closed.audioDuration" defaultMessage="DURAÇÃO" />
                </span>
                <div className="center-wrapper">
                  <AudioDuration url={elm.audio_url} />
                </div>
              </div>
              {/* Dl */}
              <div className="custom-table-cell username-cell flex-basis-10">
                <span className="mobile-column-name">
                  <FormattedMessage id="attend.closed.downloadAudio" defaultMessage="BAIXAR" />
                </span>
                <div className="center-wrapper">
                  <span className="profile_name">
                    <a className={style['btn-download']} style={{ color: 'white', padding: '5px' }} href={elm.audio_url}>
                      <i className="fas fa-download" />
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))

        }
      </div>
    </div>
  )
};

AttendAudioInformation.propTypes = {
  aditionalAudio: PropTypes.arrayOf(PropTypes.shape({
    audio_file: PropTypes.string,
    audio_ts: PropTypes.string,
    audio_url: PropTypes.string
  })).isRequired,
};

export default AttendAudioInformation;
