import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import ReportCompanyDetailsRow from './ReportCompanyDetailsRow';
import Loading from '../../../../icons/Loading';
import Download from '../../../../icons/Download';
import { host, getDomain, getToken } from '../../../../BLL/environment';
import { naturalSort } from '../../../../BLL/general';

import style from './reportClientByCompany.module.scss';

const ReportCompanyDetails = (props) => {
  const [report, setReport] = useState(undefined);
  const [csvUrl, setCsvUrl] = useState('');
  const [pdfUrl, setPdfUrl] = useState('');
  const [ordering, setOrdering] = useState('-id');
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState('');

  useEffect(() => {
    if (props.match.params) {
      fetchCompanyDetailsReport(props.match.params.companyId, props.match.params.filter);
    }
  }, [props.match.params.companyId, props.match.params.filter]);

  const fetchCompanyDetailsReport = (companyId, filter) => {
    axios({
      method: 'GET',
      url: `https://${getDomain()}${host()}/api/reports/clients-by-company-detailed/${companyId}/?${filter}`,
      headers: { Authorization: `Token ${getToken()}` },
    }).then((resp) => {
      setReport(resp.data.cli_list);
      setCompany(resp.data.company);
      setCsvUrl(resp.data.csv_url);
      setPdfUrl(resp.data.pdf_url);
      setLoading(false);
    }).catch((err) => {
      console.log(err.response);
      setLoading(false);
    });
  };

  const handleOrderChange = (newOrder) => {
    if (ordering !== newOrder) {
      setReport(report.sort((a, b) => {
        const nameA = `${a[newOrder].toLowerCase()}${a.attend_id}`
        const nameB = `${b[newOrder].toLowerCase()}${b.attend_id}`;
        return naturalSort(nameA, nameB)
      }));
      setOrdering(newOrder);
    } else {
      setReport(report.sort((a, b) => {
        const nameA = `${a[newOrder].toLowerCase()}${a.attend_id}`
        const nameB = `${b[newOrder].toLowerCase()}${b.attend_id}`;
        return naturalSort(nameB, nameA)
      }));
      setOrdering(`-${newOrder}`);
    }
  };

  return (
    <div className="contactto-middle-content scroll print">
      <div className={`${style.title} col-sm-12 col-md-6 row`}>
        <FormattedMessage id="reports.reportOf" defaultMessage="RELATÓRIO DE" /> &nbsp; <span className={style.subtitle}>
          <FormattedMessage id="reports.companiesDetail.header" defaultMessage="USUÁRIOS CONTABILIZADOS DA EMPRESA" /> {company.toUpperCase()}
        </span>
      </div>
      <div className="col-sm-12 col-md-6 row">
        <div style={{ marginLeft: '5px' }}>
          <a href={csvUrl}>
            <div
              className={`${style.download} no-print`}
              title="Download"
            >
              <Download fill="#fff" width="33px" height="33px" />
            </div>
          </a>
        </div>
        <div style={{ marginLeft: '5px' }}>
          <a href={pdfUrl}>
            <div className={`${style.download} no-print`} title="PDF" style={{ padding: '8px' }}>
              <span style={{ color: "#fff" }}><i className="far fa-file-pdf fa-lg" /></span>
            </div>
          </a>
        </div>
      </div>
      {loading
        ? <Loading text={<FormattedMessage id="attend.list.loading" defaultMessage="Carregando" />} />
        : report &&
          <div className={`${style.table} print`}>
            <div className={style.header}>
              <button className={style['header-column']} onClick={() => handleOrderChange('account')}>
                <FormattedMessage id="general.account" defaultMessage="Conta" />
              </button>
              <button className={style['header-column']} onClick={() => handleOrderChange('first_name')}>
                <FormattedMessage id="reports.fullName" defaultMessage="Nome" />
              </button>
              <button className={style['header-column']} onClick={() => handleOrderChange('created_date')}>
                <FormattedMessage id="reports.length.createdDate" defaultMessage="Data de Criação" />
              </button>
              <div className={`${style['header-column']} ${style['no-pointer']}`}>
                <FormattedMessage id="reports.length.deletedDate" defaultMessage="Data de Deleção" />
              </div>
              <div className={`${style['header-column']} ${style['no-pointer']}`}>
                <FormattedMessage id="reports.length.activeDays" defaultMessage="Dias ativos" />
              </div>
            </div>
            <div className={`${style.body} print scroll`}>
              {report && report.length > 0
                ? report.map(row => <ReportCompanyDetailsRow key={row.id} client={row} />)
                : <div className="list-table-body-row"><FormattedMessage id="reports.emptyReport" defaultMessage="Relatório vazio" /></div>
              }
            </div>
          </div>
      }
    </div>
  );

};

ReportCompanyDetails.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }).isRequired,
  params: PropTypes.shape({
    companyId: PropTypes.string.isRequired,
  }).isRequired,
};

export default ReportCompanyDetails;
