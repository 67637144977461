import moment from 'moment';
import messages from './locale/messages';
import messagesMonths from './locale/messagesMonths';
import messagesAlarms from './locale/messagesAlarms';
import messagesLogs from './locale/messagesLogs';

export const actionPriority = value => {
  const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
  switch (value) {
    case "":
      return "Selecione a Prioridade";
    case "1":
    case 1:
      return messages[lang]['general.highPriority'];
    case "2":
    case 2:
      return messages[lang]['general.mediumPriority'];
    case "3":
    case 3:
      return messages[lang]['general.lowPriority'];
    case "4":
    case 4:
      return messages[lang]['general.sysPriority'];
    case "all":
      return messages[lang]['general.allPriorities'];

    case "Selecione a Prioridade":
      return "";
    case messages[lang]['general.highPriority']:
      return 1;
    case messages[lang]['general.mediumPriority']:
      return 2;
    case messages[lang]['general.lowPriority']:
      return 3;
    case messages[lang]['general.sysPriority']:
      return 4;
    case messages[lang]['general.allPriorities']:
      return "all";

    default:
      return value;
  }
};

export const userActionPriority = (value, order) => {
  const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
  if (order === 'fromServer') {
    if (value === 1) {
      return messages[lang]['general.highPriority'];
    } else if (value === 2) {
      return messages[lang]['general.mediumPriority'];
    } else if (value === 3) {
      return messages[lang]['general.lowPriority'];
    } else if (value === 4) {
      return messages[lang]['general.sysPriority'];
    } else if (value === 'all') {
      return messages[lang]['general.allPriorities'];
    } else {
      return value;
    }
  } else if (order === 'toServer') {
    if (value === messages[lang]['general.highPriority']) {
      return 1;
    } else if (value === messages[lang]['general.mediumPriority']) {
      return 2;
    } else if (value === messages[lang]['general.lowPriority']) {
      return 3;
    } else if (value === messages[lang]['general.sysPriority']) {
      return 4;
    } else if (value === messages[lang]['general.allPriorities']) {
      return 'all';
    } else {
      return value;
    }
  }
}

export const reportPriority = (value) => {
  const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
  switch (value) {
    case 1:
      return messages[lang]['general.highPriority'];
    case 2:
      return messages[lang]['general.mediumPriority'];
    case 3:
      return messages[lang]['general.lowPriority'];
    case 4:
      return messages[lang]['general.sysPriority'];
    case 'all':
      return messages[lang]['general.allPriorities'];

    default:
      return value;
  }
};

export const userGroup = value => {
  const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
  switch (value) {
    case 1:
      return messages[lang]['general.admin'];
    case 2:
      return messages[lang]['general.manager'];
    case 3:
      return messages[lang]['general.operator'];
    case 4:
      return messages[lang]['general.owner'];

    case messages[lang]['general.admin']:
      return 1;
    case messages[lang]['general.manager']:
      return 2;
    case messages[lang]['general.operator']:
      return 3;
    case messages[lang]['general.owner']:
      return 4;

    default:
      return value;
  }
};

export const monitoringStatus = (status) => {
  const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
  switch (status) {
    case 'PENDING':
      return messages[lang]['monitoring.pending'];
    case 'APPROVED':
      return messages[lang]['monitoring.approved'];
    case 'DELETED':
      return messages[lang]['monitoring.deleted'];
    case messages[lang]['monitoring.pending']:
      return 'PENDING';
    case messages[lang]['monitoring.approved']:
      return 'APPROVED';
    case messages[lang]['monitoring.deleted']:
      return 'DELETED';
    default:
      return status;
  }
};

export const equipmentTypes = type => {
  switch (type) {
    case 1:
      return 'PERS3600';
    case 2:
      return 'PERS4200';
    case 3:
      return 'IGO';
    case 4:
      return 'iHelp';

    case 'PERS3600':
      return 1;
    case 'PERS4200':
      return 2;
    case 'IGO':
      return 3;
    case 'iHelp':
      return 4;

    default:
      return type;
  }
};

export const phoneTypes = (type) => {
  const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
  switch (type) {
    case 'Primary':
      return 'Principal';
    case 'Home':
      return 'Casa';
    case 'Work':
      return 'Trabalho';
    case 'Cellphone':
      return 'Celular';
    default:
      return type;
  }
};

export const userPlan = value => {
  switch (value) {
    case 1:
      return "Basic Care R$15/mês";
    case 2:
      return "Care R$25/mês";
    case 3:
      return "Total Care R$35/mês";

    case "Basic Care R$15/mês":
      return 1;
    case "Care R$25/mês":
      return 2;
    case "Total Care R$35/mês":
      return 3;

    default:
      return value;
  }
};

export const checkMonth = (num, language) => {
  const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
  switch (num) {
    case 1:
      if (language === 'pt') {
        return 'Janeiro';
      } else if (language === 'en') {
        return 'January';
      }
      return messagesMonths[lang].january;
    case 2:
      if (language === 'pt') {
        return 'Fevereiro';
      } else if (language === 'en') {
        return 'February';
      }
      return messagesMonths[lang].february;
    case 3:
      if (language === 'pt') {
        return 'Março';
      } else if (language === 'en') {
        return 'March';
      }
      return messagesMonths[lang].march;
    case 4:
      if (language === 'pt') {
        return 'Abril';
      } else if (language === 'en') {
        return 'April';
      }
      return messagesMonths[lang].april;
    case 5:
      if (language === 'pt') {
        return 'Maio';
      } else if (language === 'en') {
        return 'May';
      }
      return messagesMonths[lang].may;
    case 6:
      if (language === 'pt') {
        return 'Junho';
      } else if (language === 'en') {
        return 'June';
      }
      return messagesMonths[lang].june;
    case 7:
      if (language === 'pt') {
        return 'Julho';
      } else if (language === 'en') {
        return 'July';
      }
      return messagesMonths[lang].july;
    case 8:
      if (language === 'pt') {
        return 'Agosto';
      } else if (language === 'en') {
        return 'August';
      }
      return messagesMonths[lang].august;
    case 9:
      if (language === 'pt') {
        return 'Setembro';
      } else if (language === 'en') {
        return 'September';
      }
      return messagesMonths[lang].september;
    case 10:
      if (language === 'pt') {
        return 'Outubro';
      } else if (language === 'en') {
        return 'October';
      }
      return messagesMonths[lang].october;
    case 11:
      if (language === 'pt') {
        return 'Novembro';
      } else if (language === 'en') {
        return 'November';
      }
      return messagesMonths[lang].november;
    case 12:
      if (language === 'pt') {
        return 'Dezembro';
      } else if (language === 'en') {
        return 'December';
      }
      return messagesMonths[lang].december;
    default:
      return '';
  }
};

export const getMonth = (lang) => {
  switch (lang) {
    case 'pt':
      return [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ];
    case 'en':
      return [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
    default:
      return [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ];
  }
};

export const contactActionPriority = (value, order) => {
  const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
  if (order === 'fromServer') {
    if (value === 1) {
      return messages[lang]['general.highPriority'];
    } else if (value === 2) {
      return messages[lang]['general.mediumPriority'];
    } else if (value === 3) {
      return messages[lang]['general.lowPriority'];
    } else if (value === 4) {
      return messages[lang]['general.sysPriority'];
    } else if (value === 'all') {
      return messages[lang]['general.allPriorities'];
    } else {
      return value;
    }
  } else if (order === 'toServer') {
    if (value === messages[lang]['general.highPriority']) {
      return 1;
    } else if (value === messages[lang]['general.mediumPriority']) {
      return 2;
    } else if (value === messages[lang]['general.lowPriority']) {
      return 3;
    } else if (value === messages[lang]['general.sysPriority']) {
      return 4;
    } else if (value === messages[lang]['general.allPriorities']) {
      return 'all';
    } else {
      return value;
    }
  }
};

export const fallDetectionMode = (mode) => {
  const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
  switch (mode) {
    case -1:
      return messages[lang]['client.tracker.disabled'];
    case 0:
      return messages[lang]['client.tracker.highSensitivity'];
    case 1:
      return messages[lang]['client.tracker.mediumHighSensitivity'];
    case 2:
      return messages[lang]['client.tracker.normalSensitivity'];
    case messages[lang]['client.tracker.disabled']:
      return -1;
    case messages[lang]['client.tracker.highSensitivity']:
      return 0;
    case messages[lang]['client.tracker.mediumHighSensitivity']:
      return 1;
    case messages[lang]['client.tracker.normalSensitivity']:
      return 2;
    default:
      return mode;
  }
};


export const geoFenceMode = (mode) => {
  const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
  switch (mode) {
    case 0:
      return messages[lang]['client.tracker.disabled'];
    case 1:
      return messages[lang]['client.tracker.enterPerimeter'];
    case 2:
      return messages[lang]['client.tracker.exitPerimeter'];
    case 3:
      return messages[lang]['client.tracker.bothPerimeter'];
    case messages[lang]['client.tracker.disabled']:
      return 0;
    case messages[lang]['client.tracker.enterPerimeter']:
      return 1;
    case messages[lang]['client.tracker.exitPerimeter']:
      return 2;
    case messages[lang]['client.tracker.bothPerimeter']:
      return 3;
    default:
      return mode;
  }
};

export const ev07bGeoFenceMode = (mode) => {
  const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
  switch (mode) {
    case '1':
      return messages[lang]['client.tracker.exitPerimeter'];
    case '0':
      return messages[lang]['client.tracker.enterPerimeter'];
    case messages[lang]['client.tracker.exitPerimeter']:
      return '1';
    case messages[lang]['client.tracker.enterPerimeter']:
      return '0';
    default:
      return mode;
  }
}

export const ev07bAlarmClockAudio = (mode) => {
  const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
  switch (mode) {
    case 1:
    case '1':
      return messages[lang]['client.tracker.appointmentAudio'];
    case 2:
    case '2':
      return messages[lang]['client.tracker.medicationAudio'];
    case 3:
    case '3':
      return messages[lang]['client.tracker.noonAudio'];
    case 4:
    case '4':
      return messages[lang]['client.tracker.wakeUpAudio'];
    case messages[lang]['client.tracker.appointmentAudio']:
      return 1;
    case messages[lang]['client.tracker.medicationAudio']:
      return 2;
    case messages[lang]['client.tracker.noonAudio']:
      return 3;
    case messages[lang]['client.tracker.wakeUpAudio']:
      return 4;
    default:
      return mode;

  }
}

export const contactHistoryType = (type) => {
  const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
  switch (type) {
    case 'Contact':
      return messages[lang]['history.contact'];
    case 'Phone':
      return messages[lang]['history.contact.phone'];
    case 'Action':
      return messages[lang]['history.contact.action'];
    default:
      return type;
  }
};

export const historyActionType = (action) => {
  const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
  switch (action) {
    case 'changed':
      return messages[lang]['history.actionType.changed'];
    case 'deleted':
      return messages[lang]['history.actionType.deleted'];
    case 'created':
      return messages[lang]['history.actionType.created'];
    default:
      return action;
  }
};

export const alarmNameLang = (alarm) => {
  const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
  if (lang === 'pt' || !alarm.is_original) {
    return alarm.name;
  }
  return messagesAlarms[lang][alarm.code];
}

export const alarmPriorityLang = (value) => {
  const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
  if (value === 1) {
    return messages[lang]['general.highPriority'];
  } else if (value === 2) {
    return messages[lang]['general.mediumPriority'];
  } else if (value === 3) {
    return messages[lang]['general.lowPriority'];
  } else if (value === 4) {
    return messages[lang]['general.sysPriority'];
  } else if (value === 'all') {
    return messages[lang]['general.allPriorities'];
  }
  return value;
}

export const ataStatusLang = (value) => {
  const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
  switch (value) {
    case 'UNKNOWN':
      return messages[lang]['ata.status.unknown'];
    case 'UNREACHABLE':
        return messages[lang]['ata.status.unreachable'];
    default:
      return value;
  }
}

export const trDescriptionLang = (value) => {
  const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
  switch (value) {
    case 'SOS':
      return messages[lang]['attend.description.sosAlarm'];
    case 'FD':
      return messages[lang]['attend.description.falldownAlarm'];
    case 'OS':
      return messages[lang]['attend.description.overspeedAlarm'];
    default:
      return value;
  }
}

export const historyField = (value) => {
  const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
  if (messagesLogs[lang][value]) {
    return messagesLogs[lang][value];
  }
  return value;
}

export const historyText = (value, key) => {
  const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
  switch (key) {
    case 'gender': {
      let type = 'male';
      if (value === 'f') {
        type = 'female'
      } else if (value === 'n') {
        type = 'notApplicable'
      }
      return messages[lang][`client.form.${type}`];
    }
    case 'birthdate': {
      return moment(value).format('DD/MM/YYYY');
    }
    default:
      return !value ? messages[lang]['history.empty'] : value;
  }
}
