import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { getDomain, host, getToken } from '../../../../BLL/environment';
import messages from '../../../../BLL/locale/messageDashboards';

const QuickSightPage = (props) => {
  const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
  const [embedUrl, setEmbedUrl] = useState('');
  const [dashboardIds, setDashboardIds] = useState({});
  const [sessionId, setSessionId] = useState('');

  useEffect(() => {
    const fetchEmbedUrl = () => {
      axios.get(`https://${getDomain()}${host()}/api/reports/quicksight/`, {
        headers: { Authorization: `Token ${getToken()}` },
      })
        .then((res) => {
          console.log(res);
          const sessionId = res.data.embedUrl.split('/')[4];
          setSessionId(sessionId);
          setEmbedUrl(res.data.embedUrl);
          setDashboardIds(res.data.dashboards);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const isAdmin = props.loggedAttendant.groups.some(grp => grp === 1);
    const isOwner = props.loggedAttendant.groups.some(grp => grp === 4);
    if (isAdmin || isOwner) {
      fetchEmbedUrl();
    } else {
      props.history.push('/app');
    }

  }, [props.loggedAttendant, props.history]);

  const changeDashboard = (event) => {
    const value = event.target.value;
    const dashboardId = dashboardIds[value];
    const newUrl = `https://sa-east-1.quicksight.aws.amazon.com/embed/${sessionId}/dashboards/${dashboardId}`;
    setEmbedUrl(newUrl);
  }

  return (
    <div className="contactto-middle-content" style={{ overflow: 'hidden' }}>
      <div style={{ width: '100%', height: '5%' }}>
        {Object.keys(dashboardIds).length > 0 &&
          <select className='custom-select' onChange={(e) => changeDashboard(e)}>
            {Object.keys(dashboardIds).map((dashboard) => (
              <option value={dashboard}>{messages[lang][dashboard]}</option>
            ))}
          </select>
        }
      </div>
      {embedUrl &&
        <div style={{ width: '100%', height: '95%' }}>
          <iframe
            title="time-dashboard"
            width="100%"
            height="100%"
            src={embedUrl}
          />
        </div>
      }
    </div>
  );
};

QuickSightPage.propTypes = {
  loggedAttendant: PropTypes.shape({
    id: PropTypes.number,
    groups: PropTypes.arrayOf(
      PropTypes.number
    ),
  }),
};

function mapStateToProps(state) {
  return {
    loggedAttendant: state.loggedAttendant,
  };
};

export default connect(mapStateToProps)(QuickSightPage);
