import moment from "moment";
import { validateDate } from "../../../../BLL/validate";
import {
  validateClientBR,
  validateClientMU,
  validateClientGB,
  validateClientGeneral,
  validateSecondaryGeneral,
  validateSecondaryBR,
} from "../ClientValidations";
import { initialState } from "./ClientReducer";
import * as general from "../../../../BLL/general";

const reducerActions = {
  loadClients: (state, payload) => {
    const c = payload.value;
    console.log(c);
    let accountPhone = c.account_phone;
    let contactPhone = c.contact_phone;
    let extraPhone = c.extra_phone;
    let zipCode = c.zip_code;
    if (!c.use_voip) {
      switch (c.country) {
        case "Brasil":
        case "BR": {
          accountPhone =
            accountPhone.length === 10
              ? general.setMask(accountPhone, "(00) 0000-0000")
              : general.setMask(accountPhone, "(00) 0 0000-0000");
          contactPhone =
            contactPhone.length === 10
              ? general.setMask(contactPhone, "(00) 0000-0000")
              : general.setMask(contactPhone, "(00) 0 0000-0000");
          extraPhone =
              extraPhone?.length === 10
                ? general.setMask(extraPhone, "(00) 0000-0000")
                : general.setMask(extraPhone, "(00) 0 0000-0000");
          zipCode = general.setMask(zipCode, "00000-000");
          break;
        }
        case "GB": {
          accountPhone = general.setMask(accountPhone, "(00) 0000 000000");
          contactPhone = general.setMask(contactPhone, "(00) 0000 000000");
          break;
        }
        default:
          break;
      }
    }

    state.client = Object.assign(
      {},
      c,
      { cpf: general.setMask(c.cpf, "000.000.000-00") },
      { zip_code: zipCode },
      { birthdate: moment(c.birthdate).format("DD/MM/YYYY") },
      { number: c.number.toString() },
      { account_phone: accountPhone },
      { contact_phone: contactPhone },
      { extra_phone: extraPhone },
      { gender: !c.gender ? "m" : c.gender }
    );
    const secondary = c.secondary[0];
    if (secondary) {
      state.secondary = Object.assign(
        {},
        secondary,
        { cpf: general.setMask(secondary.cpf, "000.000.000-00") },
        { birthdate: moment(secondary.birthdate).format("DD/MM/YYYY") },
        { gender: !secondary.gender ? "m" : secondary.gender }
      );
    }
  },
  editClient: (state, payload) => {
    let stateClient = state.client;
    const { name, value } = payload.data;
    const ihelpId = payload.ihelpId;
    switch (name) {
      case "zip_code": {
        stateClient[name] =
          stateClient.country === "BR" || stateClient.country === "Brasil"
            ? general.zipCode(value)
            : value;
        break;
      }
      case "cpf": {
        stateClient[name] = general.cpfMask(value);
        break;
      }
      case "account": {
        const arr = value.split("");
        const val = arr.filter((el) => /\d/.test(el)).join("");
        stateClient[name] = val;
        break;
      }
      case "birthdate": {
        const arr = value.split("");
        const val = arr.filter((el) => /\d/.test(el)).join("");
        const dateMasked = general.setMask(val, "00/00/0000");
        stateClient[name] =
          dateMasked.length === 10 ? validateDate(dateMasked) : dateMasked;
        break;
      }
      case "account_phone":
      case "contact_phone": 
      case "extra_phone": {
        if (stateClient.use_voip) {
          stateClient[name] = value;
        } else {
          stateClient[name] = general.PhoneNumberGlobal(
            value,
            stateClient.country
          );
        }
        break;
      }
      case "country": {
        if (value !== stateClient.country) {
          stateClient.account_phone = "";
          stateClient.zip_code = "";
          stateClient.state = "-";
        }
        stateClient[name] = value;
        break;
      }
      case "equipment_id": {
        const equipmentId = parseInt(value, 10);
        if (stateClient.equipment_id !== equipmentId) {
          stateClient.imei = "";
          stateClient.account_phone = "";
          stateClient.use_voip = false;
        }
        stateClient[name] = equipmentId;
        break;
      }
      case "imei": {
        if (stateClient.equipment_id === ihelpId && stateClient.use_voip) {
          stateClient.account_phone = value;
        }
        stateClient[name] = value;
        break;
      }
      case "use_voip": {
        const useVoip = value === "true";
        if (useVoip) {
          if (state.client.equipment_id === ihelpId) {
            stateClient.account_phone = stateClient.imei;
          } else {
            stateClient.account_phone = "";
          }
        } else {
          stateClient.account_phone = "";
        }
        let newValidator = state.clientValidator;
        newValidator.account_phone = { validate: true, message: "" };
        state.clientValidator = Object.assign({}, newValidator);

        stateClient[name] = useVoip;
        break;
      }
      default: {
        stateClient[name] = value;
        break;
      }
    }
    state.client = Object.assign({}, stateClient);
  },
  editClientValidator: (state, payload) => {
    let newValidator = state.clientValidator;
    const client = state.client;
    const { name, value } = payload.data;
    const equipmentList = payload.equipmentList;
    switch (client.country) {
      case "BR":
      case "Brasil": {
        newValidator = validateClientBR(
          equipmentList,
          newValidator,
          [],
          client,
          { name, value }
        );
        break;
      }
      case "MU": {
        newValidator = validateClientMU(
          equipmentList,
          newValidator,
          [],
          client,
          { name, value }
        );
        break;
      }
      case "GB": {
        newValidator = validateClientGB(
          equipmentList,
          newValidator,
          [],
          client,
          { name, value }
        );
        break;
      }
      default: {
        newValidator = validateClientGeneral(
          equipmentList,
          newValidator,
          [],
          client,
          { name, value }
        );
        break;
      }
    }
    state.clientValidator = Object.assign({}, newValidator);
  },
  editSecondary: (state, payload) => {
    let stateSecondary = state.secondary;
    const { name, value } = payload.data;
    switch (name) {
      case "cpf": {
        stateSecondary[name] = general.cpfMask(value);
        break;
      }
      case "birthdate": {
        const arr = value.split("");
        const val = arr.filter((el) => /\d/.test(el)).join("");
        const dateMasked = general.setMask(val, "00/00/0000");
        stateSecondary[name] =
          dateMasked.length === 10 ? validateDate(dateMasked) : dateMasked;
        break;
      }
      default: {
        stateSecondary[name] = value;
        break;
      }
    }
    state.secondary = Object.assign({}, stateSecondary);
  },
  editSecondaryValidator: (state, payload) => {
    let newValidator = state.secondaryValidator;
    const secondary = state.secondary;
    const client = state.client;
    const { name, value } = payload.data;
    switch (client.country) {
      case "BR":
      case "Brasil": {
        newValidator = validateSecondaryBR(newValidator, { name, value });
        break;
      }
      default: {
        newValidator = validateSecondaryGeneral(newValidator, { name, value });
        break;
      }
    }
    state.secondaryValidator = Object.assign({}, newValidator);
  },
  removeSecondary: (state) => {
    state.secondary = Object.assign({}, initialState.secondary);
    state.secondaryValidator = Object.assign(
      {},
      initialState.secondaryValidator
    );
    state.removeSecondary = !state.removeSecondary;
  },
  invertClients: (state) => {
    let newClient = Object.assign({}, state.client);
    let newSecondary = Object.assign({}, state.secondary);
    Object.keys(newSecondary).forEach((key) => {
      if (key !== "id") {
        newClient[key] = state.secondary[key];
        newSecondary[key] = state.client[key];
      }
    });

    state.client = Object.assign({}, newClient);
    state.secondary = Object.assign({}, newSecondary);
    state.invertClients = !state.invertClients;
  },
};

export default reducerActions;
