import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import { getDomain, host, getToken } from '../../../BLL/environment';
import ToggleButton from '../../Common/ToggleButton';
import CustomModal from '../../Modal/CustomModal';
import ModalZoneInfo from '../../Modal/ModalZoneInfo';
import { broadcast } from '../../../BLL/general';
import broadcastMessages from '../../../BLL/locale/messagesBroadcast';

import style from './configuration.module.scss';

const AdministratorOptions = (props) => (
  <div className={style.outerCard}>
    <div className={style.row}>
      <div className={style.labelText}>
        <FormattedMessage id="config.globalOptHeader" defaultMessage="CONFIGURAÇÕES ADMINISTRATIVAS" />
      </div>
    </div>
    <div className={style.item}>
      <div className={style.options}>
        <label className={style.text}>
          <FormattedMessage id="config.viewAttendantsList" defaultMessage="Visualizar Lista de Atendentes" />
        </label>
        <div id='attendants-view-toggle' className={style.toggle}>
          <ToggleButton
            text=""
            isChecked={props.viewAttendantList}
            handleChange={props.handleAttendantChange}
            hasPagination={false}
          />
        </div>
      </div>
      <div className={style.divider} />
      <div className={style.options}>
        <label className={style.text}>
          <FormattedMessage id="config.viewContactsList" defaultMessage="Visualizar Lista de Contatos" />
        </label>
        <div id='contacts-view-toggle' className={style.toggle}>
          <ToggleButton
            text=""
            isChecked={props.viewContactList}
            handleChange={props.handleContactChange}
            hasPagination={false}
          />
        </div>
      </div>
      <div className={style.divider} />
      <div className={style.options}>
        <label className={style.text}>
          <FormattedMessage id="config.viewIcons" defaultMessage="Visualizar Ícones de Alarme" />
        </label>
        <div id='icons-view-toggle' className={style.toggle}>
          <ToggleButton
            text=""
            isChecked={props.viewIcons}
            handleChange={props.handleViewIconsChange}
            hasPagination={false}
          />
        </div>
      </div>
      <div className={style.divider} />
      <div className={style.options}>
        <label className={style.text}>
          <FormattedMessage id="config.playSecondAudio" defaultMessage="Ligar som para alarmes secundários" />
        </label>
        <div id='icons-sound-toggle' className={style.toggle}>
          <ToggleButton
            text=""
            isChecked={props.playSecondaryAlarmSounds}
            handleChange={props.handleSecondaryAlarmSoundsChange}
            hasPagination={false}
          />
        </div>
      </div>
      <div className={style.divider} />
      {props.isOwner &&
        <>
          <div className={style.options}>
            <label className={style.text}>
              <FormattedMessage id="config.autoCloseAlarms" defaultMessage="Fechar alarmes secundários automaticamente" />
            </label>
            <div id='icons-auto-toggle' className={style.toggle}>
              <ToggleButton
                text=""
                isChecked={props.automaticAlarmClosing}
                handleChange={props.handleAutomaticAlarmClosing}
                hasPagination={false}
              />
            </div>
          </div>
          <div className={style.divider} />
          <div className={style.options}>
            <label className={style.text}>
              <FormattedMessage id="config.useAlarmZones" defaultMessage="Utilizar as zonas no alarme" />
            </label>
            <div id='icons-auto-toggle' className={style.toggle}>
              <ToggleButton
                text=""
                isChecked={props.useAlarmZones}
                handleChange={props.handleUseAlarmZonesChange}
                hasPagination={false}
              />
            </div>
          </div>
          <div className={style.divider} />
        </>
      }
    </div>
  </div>
);

const LocalOptions = (props) => (
  <div className={style.outerCard}>
    <div className={style.row}>
      <span className={style.labelText}>
        <FormattedMessage id="config.localOptHeader" defaultMessage="CUSTOMIZAÇÃO DO PAINEL PRINCIPAL" />
      </span>
    </div>
    <div className={style.item}>
      <div className={style.options}>
        <label className={style.text}>
          <FormattedMessage id="config.viewClosedAttends" defaultMessage="Visualizar Atendimentos Fechados" />
        </label>
        <div className={style.toggle}>
          <ToggleButton
            text=""
            isChecked={props.viewClosedAttends}
            handleChange={props.handleCloseAtendsChange}
            hasPagination={false}
          />
        </div>
      </div>
      <div className={style.divider} />
      <div className={style.options}>
        <label className={style.text}>
          <FormattedMessage id="config.viewResume" defaultMessage="Visualizar Resumo dos Atendimentos" />
        </label>
        <div className={style.toggle}>
          <ToggleButton
            text=""
            isChecked={props.viewAttendSummary}
            handleChange={props.handleAttendsSummaryChange}
            hasPagination={false}
          />
        </div>
      </div>
      <div className={style.divider} />
    </div>
  </div>
);

const EchoTest = (props) => (
  <div className={style.outerCard}>
    <div className={style.row}>
      <span className={style.labelText}>
        <FormattedMessage id="config.micandaudioconfig" defaultMessage="CONFIGURAÇÕES DE MICROFONE E ÁUDIO" />
      </span>
    </div>
    <div className={style.item}>
      <div className={style.options}>
        <label className={style.text}>
          <FormattedMessage id="config.audioEcho" defaultMessage="Teste de eco" />
        </label>
        <div className={style.toggle}>
          {props.echoCall ?
          <button onClick={props.handleEchoTestHangup} className={`btn btn--color-red ${style.btnSave}`}>
            <FormattedMessage id="config.hangupAudio" defaultMessage="Desligar Teste" />
          </button>
          :
          <button onClick={props.handleEchoTestCall} className={`btn btn--color-tecno ${style.btnSave}`}>
            <FormattedMessage id="config.makeAudio" defaultMessage="Testar" />
          </button>
          }
        </div>
      </div>
      <div className={style.divider} />
    </div>
  </div>
);

const Configuration = (props) => {
  const loggedAttendant = useSelector(state => state.loggedAttendant);
  const configurationLocal = useSelector(state => state.localConfiguration.local);
  const configurationGlobal = useSelector(state => state.globalConfiguration.global);

  const [viewClosedAttends, setViewClosedAttends] = useState(false);
  const [viewAttendSummary, setViewAttendSummary] = useState(false);
  const [viewAttendantList, setViewAttendantList] = useState(false);
  const [viewContactList, setViewContactList] = useState(false);
  const [viewIcons, setViewIcons] = useState(false);
  const [playSecondaryAlarmSounds, setPlaySecondaryAlarmSounds] = useState(false);
  const [automaticAlarmClosing, setAutoCloseAlarms] = useState(false);
  const [useAlarmZones, setUseAlarmZones] = useState(false);
  const [showConfigEndModal, setShowConfigEndModal] = useState(false);
  const [showZoneInfoModal, setShowZoneInfoModal] = useState(false);
  const [echoCall, setEchoCall] = useState(false);
  const [modalText, setModalText] = useState('');

  useEffect(() => {
    if (configurationLocal) {
      setViewClosedAttends(configurationLocal.last_attends);
      setViewAttendSummary(configurationLocal.attends_quantity);
    }
  }, [configurationLocal]);

  useEffect(() => {
    if (configurationGlobal) {
      setViewIcons(configurationGlobal.show_icons);
      setViewAttendantList(configurationGlobal.attendant_list);
      setViewContactList(configurationGlobal.contacts_list);
      setPlaySecondaryAlarmSounds(configurationGlobal.secondary_alarm_sound);
      setAutoCloseAlarms(configurationGlobal.automatic_alarm_closing);
      setUseAlarmZones(configurationGlobal.use_alarm_zones);
    }
  }, [configurationGlobal]);

  const handleAlarmZonesChange = () => {
    if (!useAlarmZones) {
      setShowZoneInfoModal(true);
    }
    setUseAlarmZones(!useAlarmZones);
  };

  const handleEchoTestCall = () => {
    setEchoCall(true);
    props.testEchoSip();
  };

  const handleEchoTestHangup = () => {
    setEchoCall(false);
    props.hangupSip()
  };

  const makeUpdate = () => {
    if (!viewAttendantList && !viewContactList) {
      setModalText(<FormattedMessage id="config.twoGlobalUncheck" defaultMessage="Não é possível desabilitar as duas configurações globais de atendimento. Por favor habilite uma." />);
      setShowConfigEndModal(true);
      return;
    }

    updateGlobalConfig();
  };

  const updateGlobalConfig = () => {
    const data = {
      attendant_list: viewAttendantList,
      contacts_list: viewContactList,
      show_icons: viewIcons,
      secondary_alarm_sound: playSecondaryAlarmSounds,
      automatic_alarm_closing: automaticAlarmClosing,
      use_alarm_zones: useAlarmZones,
    }
    axios.put(
      `https://${getDomain()}${host()}/api/global-config/`,
      JSON.stringify(data), 
      { 
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Token ${getToken()}`,
        },
      }
    ).then(() => {
      updateLocalConfig();
    }).catch((err) => {
      console.log(err);
      setModalText(<FormattedMessage id="config.saveError" defaultMessage="Ocorreram erros ao salvar as configurações" />);
      setShowConfigEndModal(true);
    });
  };

  const updateLocalConfig = () => {
    const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
    const data = {
      last_attends: viewClosedAttends,
      attends_quantity: viewAttendSummary
    }
    axios.put(
      `https://${getDomain()}${host()}/api/dashboard-config/${loggedAttendant.id}/`,
      JSON.stringify(data),
      { 
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Token ${getToken()}`,
        },
      }
    ).then(() => {
      setModalText(<FormattedMessage id="config.saveSuccess" defaultMessage="Configurações salvas!" />)
      setShowConfigEndModal(true);
      broadcast(axios, props.channelId, {
        type: 'config',
        update: 'configurations',
        localConfiguration: configurationLocal,
        globalConfiguration:configurationGlobal,
        text: broadcastMessages[lang]['broadcast.settingsChanged'],
      });
    }).catch((err) => {
      console.log(err);
      setModalText(<FormattedMessage id="config.saveError" defaultMessage="Ocorreram erros ao salvar as configurações" />);
      setShowConfigEndModal(true);
    });
  };

  return (
    <div className="contactto-middle-content" style={{ overflow: 'hidden' }} >
      <div className={style.wrapper}>
        <div className={showZoneInfoModal ? style.modal : style.hideModal}>
          <ModalZoneInfo btnText="OK" btnAction={() => { setShowZoneInfoModal(false) }} />
        </div>
        <div className={showConfigEndModal ? style.modal : style.hideModal}>
          <CustomModal
            text={modalText}
            btnText="OK"
            btnAction={() => { setShowConfigEndModal(false) }}
          />
        </div>
        <LocalOptions
          viewClosedAttends={viewClosedAttends}
          viewAttendSummary={viewAttendSummary}
          handleCloseAtendsChange={() => setViewClosedAttends(!viewClosedAttends)}
          handleAttendsSummaryChange={() => setViewAttendSummary(!viewAttendSummary)}
        />
        {(props.isAdmin || props.isOwner) && (loggedAttendant.groups.length === 1) &&
          <AdministratorOptions
            viewAttendantList={viewAttendantList}
            viewContactList={viewContactList}
            viewIcons={viewIcons}
            isOwner={props.isOwner}
            playSecondaryAlarmSounds={playSecondaryAlarmSounds}
            automaticAlarmClosing={automaticAlarmClosing}
            useAlarmZones={useAlarmZones}
            handleAttendantChange={() => setViewAttendantList(!viewAttendantList)}
            handleContactChange={() => setViewContactList(!viewContactList)}
            handleViewIconsChange={() => setViewIcons(!viewIcons)}
            handleSecondaryAlarmSoundsChange={() => setPlaySecondaryAlarmSounds(!playSecondaryAlarmSounds)}
            handleAutomaticAlarmClosing={() => setAutoCloseAlarms(!automaticAlarmClosing)}
            handleUseAlarmZonesChange={handleAlarmZonesChange}
          />
        }
        <EchoTest
          echoCall={echoCall}
          handleEchoTestHangup={handleEchoTestHangup}
          handleEchoTestCall={handleEchoTestCall}
        />
        <div className="col-12" style={{ position: 'absolute', bottom: '1rem' }}>
          <button
            className={`btn btn--color-tecno ${style.btnSave}`} style={{ width: '90%' }}
            onClick={() => makeUpdate()}
          >
            <span>
              <i className="far fa-save fa-lg" aria-hidden="true" style={{ paddingRight: '1rem', paddingTop: '0.2rem' }} />
            </span>
            <FormattedMessage id="general.saveButton" defaultMessage="SALVAR" />
          </button>
        </div>
      </div>
    </div>
  )

}

Configuration.propTypes = {
  channelId: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool.isRequired,
  testEchoSip: PropTypes.func.isRequired,
  hangupSip: PropTypes.func.isRequired,
};

export default Configuration;
