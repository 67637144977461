import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import { broadcast } from '../../../../BLL/general';
import Paginate from '../../../Common/Paginate';
import ToggleButton from '../../../Common/ToggleButton';
import ConfirmRemoveModal from '../../../Modal/ModalConfirmRemove';
import Order from '../../../../icons/Order';
import AlarmRow from './AlarmRow';
import ManagementMenu from '../ManagementMenu';
import * as alarmsActions from '../../../../actions/alarmsActions';
import * as globals from '../../../../BLL/globals';
import broadcastMessages from '../../../../BLL/locale/messagesBroadcast';

import style from './alarmManagement.module.scss';
import '../../../../css/contactto.scss';
import '../../../../css/middle.scss';
import '../../../../css/mixins/_default_buttons.scss';
import '../../../../css/error.scss';
import '../../../../css/paginate.scss';
import '../../../../css/listPage.scss';
import '../../../../css/table.scss';
import '../../../../css/icons.scss';

export class AlarmManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: this.props.userGroup === 1,
      isOwner: this.props.userGroup === 4,
      alarms: [...this.props.alarms],
      alarmsOrdered: undefined,
      ordering: globals.alarmsOrdering,
      nameIsOrdered: false,
      codeIsOrdered: false,
      iconDetail: '',
      priorityIsOrdered: false,
      page: 1,
      numPerPage: globals.pagination,
      urlSearch: '',
      showModal: false,
      alarm: '',
      isChecked: false,
    };
    this.orderByName = this.orderByName.bind(this);
    this.orderByCode = this.orderByCode.bind(this);
    this.orderByPriority = this.orderByPriority.bind(this);
    this.setTextIconDetail = this.setTextIconDetail.bind(this);
    this.setModalInfo = this.setModalInfo.bind(this);
    this.toggleAlarm = this.toggleAlarm.bind(this);
    this.setPage = this.setPage.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.setAlarm(this.state.ordering);
  }

  componentDidUpdate(prevProps) {
    if (this.props.alarms !== prevProps.alarms) {
      this.setState({ alarms: [...this.props.alarms] });
      const alarmsOrdered = this.props.alarms.filter(alarm => alarm.ordering === this.state.ordering)[0];
      this.setState({ alarmsOrdered: alarmsOrdered.data });
    }

    if (this.props.userGroup !== prevProps.userGroup) {
      this.setState({
        isAdmin: this.props.userGroup === 1,
        isOwner: this.props.userGroup === 4,
      });
    }
  }

  setPage(page) {
    this.setState({ page });
  }

  setAlarm(ordering) {
    const alarmsOrdered = this.state.alarms.filter(alarm => alarm.ordering === ordering)[0];
    if (this.state.alarms.length <= 0 || !alarmsOrdered) {
      this.props.loadAlarms(ordering);
    } else {
      this.setState({ alarmsOrdered: alarmsOrdered.data });
    }
  }

  setTextIconDetail(iconDetail) {
    this.setState({ iconDetail });
  }

  setModalInfo(showModal, alarm) {
    this.setState({
      showModal,
      alarm: Object.assign({}, alarm),
    });
  }

  toggleAlarm() {
    const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
    const { ordering } = this.state;
    const alarm = Object.assign(
      {},
      this.state.alarm,
      { is_deleted: !this.state.alarm.is_deleted },
    );
    this.props.editAlarm(alarm).then(() => {
      const text = `${broadcastMessages[lang]['broadcast.alarm']} (${alarm.code}) ${alarm.name} ${
        alarm.is_deleted ? broadcastMessages[lang]['broadcast.deleted'] : broadcastMessages[lang]['broadcast.recovered']}`;
      broadcast(axios, this.props.channelId, {
        type: 'alarm',
        method: 'edit',
        userId: this.props.user.id,
        text,
        alarm
      });
    });
    this.setModalInfo(false, '');
  }

  orderByName() {
    const ordering = this.state.ordering === 'name' ? '-name' : 'name';
    this.setState({ ordering });
    this.setAlarm(ordering);
  }

  orderByCode() {
    const ordering = this.state.ordering === 'code' ? '-code' : 'code';
    this.setState({ ordering });
    this.setAlarm(ordering);
  }

  orderByPriority() {
    const ordering = this.state.ordering === 'priority' ? '-priority' : 'priority';
    this.setState({ ordering });
    this.setAlarm(ordering);
  }

  handleChange() {
    this.setState(prevState => ({
      isChecked: !prevState.isChecked,
    }));
  }

  render() {
    const alarms = this.state.alarmsOrdered;
    const firstItem = (this.state.page - 1) * this.state.numPerPage;
    const lastItem = this.state.page * this.state.numPerPage;
    const svgNameRotation = this.state.ordering === '-name' ? 'icons-order--rotate' : '';
    const svgCodeRotation = this.state.ordering === '-code' ? 'icons-order--rotate' : '';
    const svgPriorityRotation = this.state.ordering === '-priority' ? 'icons-order--rotate' : '';
    const isSystemUser = this.props.loggedAttendant
      && this.props.loggedAttendant.groups.length === 1;
    const { isAdmin, isOwner } = this.state;
    return (
      <div className="contactto-middle-content" style={{ overflowX: 'hidden' }}>
        <div className="row">
          <div className="pagetitle"><FormattedMessage id="management.header" defaultMessage="GERENCIAR" /></div>
          { this.props.loggedAttendant && this.props.loggedAttendant.groups.length === 1 &&
            <button type="button" className={style.btn} onClick={() => this.props.history.push('/app/alarms/form/add')}>
              <div>
                <span style={{ fontWeight: 'bold' }}>+ <FormattedMessage id="general.add" defaultMessage="ADICIONAR" /></span>
              </div>
            </button>
          }
        </div>
        <ManagementMenu history={this.props.history} /> {(isAdmin || isOwner) &&
               this.props.loggedAttendant && this.props.loggedAttendant.groups.length === 1 &&
                 <ToggleButton isChecked={this.state.isChecked} handleChange={this.handleChange} />
          }
        <div className={style.content}>
          { alarms &&
          <div className={style.table}>
            <Paginate
              outerClass={style.paginate}
              numberOfElements={alarms.length}
              numPerPage={this.state.numPerPage}
              setPage={this.setPage}
              page={this.state.page}
            />
            {this.state.showModal &&
              <ConfirmRemoveModal
                removeAction={this.toggleAlarm}
                setModalInfo={this.setModalInfo}
              />
             }
            <div className={style.header}>
              <div className={style.name}>
                <button
                  className={style['header-column']}
                  onClick={this.orderByName}
                  style={{ color: this.state.ordering === 'name' || this.state.ordering === '-name' ? '#00b8cb' : '#333' }}
                >
                  <FormattedMessage id="alarms.name" defaultMessage="Nome" /><Order width="15" height="10" className={svgNameRotation} />
                </button>
              </div>
              <div className={style.code}>
                <button
                  className={style['header-column']}
                  onClick={this.orderByCode}
                  style={{ color: this.state.ordering === 'code' || this.state.ordering === '-code' ? '#00b8cb' : '#333' }}
                >
                  <FormattedMessage id="alarms.code" defaultMessage="Código" /><Order width="15" height="10" className={svgCodeRotation} />
                </button>
              </div>
              <div className={style.priority}>
                <button
                  className={style['header-column']}
                  onClick={this.orderByPriority}
                  style={{ color: this.state.ordering === 'priority' || this.state.ordering === '-priority' ? '#00b8cb' : '#333' }}
                >
                  <FormattedMessage id="alarms.priority" defaultMessage="Prioridade" /><Order width="15" height="10" className={svgPriorityRotation} />
                </button>
              </div>
              <div className={style['column-icons']}>{this.state.iconDetail}</div>
            </div>
            <div className="list-table-body list-table-body--management scroll">
              {alarms
                .slice(firstItem, lastItem)
                .map(alarm =>
                  (alarm.is_deleted === false ||
                    (this.state.isChecked === true &&
                      alarm.is_deleted === true &&
                      (isAdmin || isOwner) === true) ?
                        <AlarmRow
                          key={alarm.code}
                          alarm={alarm}
                          showModal={this.state.showModal}
                          setTextIconDetail={this.setTextIconDetail}
                          isAdmin={isAdmin}
                          isOwner={isOwner}
                          isSystemUser={isSystemUser}
                          setModalInfo={this.setModalInfo}
                        />
                        :
                        null
                  ),
                )
              }
            </div>
          </div>
        }
        </div>
      </div>
    );
  }
}

AlarmManagement.propTypes = {
  alarms: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          code: PropTypes.string,
          type: PropTypes.string,
          priority: PropTypes.number,
          is_original: PropTypes.bool,
          is_deleted: PropTypes.bool,
          created_date: PropTypes.string,
          modified_date: PropTypes.string,
          created_by: PropTypes.number,
          modified_by: PropTypes.number,
        }),
      ),
      ordering: PropTypes.string,
    }),
  ).isRequired,
  channelId: PropTypes.string.isRequired,
  userGroup: PropTypes.number.isRequired,
  loadAlarms: PropTypes.func.isRequired,
  toggleAlarm: PropTypes.func.isRequired,
  loggedAttendant: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    groups: PropTypes.arrayOf(
      PropTypes.number,
    ),
    is_active: PropTypes.bool,
    is_staff: PropTypes.bool,
  }).isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    alarms: state.alarms,
    loggedAttendant: state.loggedAttendant,
    user: state.loggedAttendant,
    userGroup: state.loggedAttendant.groups === undefined ? -1 : state.loggedAttendant.groups[0],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadAlarms: ordering => dispatch(alarmsActions.loadAlarms(ordering)),
    editAlarm: alarm => dispatch(alarmsActions.editAlarm(alarm)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AlarmManagement);
