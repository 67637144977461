import * as types from '../actions/actionTypes';
import initialState from './initialState';


export default function globalServerReducer(state = initialState.asteriskServer, action) {
  if (action.type === types.LOAD_SERVER_CONFIG_SUCCESS) {
    return Object.assign({}, state, {
      realm: action.server.server_url,
      name: action.server.server_name,
      server: action.server.asterisk_server,
    });
  }
  return state;
}
