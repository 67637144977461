import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Edit from '../../../icons/Edit';

import style from './clientPage.module.scss';
import '../../../css/row.scss';

const ClientMobileRow = (props) => {
  const {
    client,
    equipment,
    isAdmin,
    isOwner,
    isManager,
    buttonStatus,
    setButtonStatus,
    setModalInfo,
    showModal,
    performFilter,
  } = props;
  const list = `/app/clients/detail/${client.client_id}`;
  const contacts = `/app/contacts/list/${client.client_id}`;
  const transmitters = `/app/transmitters/list/${client.client_id}`;
  const edit = `/app/clients/form/edit/${client.client_id}`;
  const logs = `/app/clients/logs/${client.client_id}`;
  const btnStyle = { border: 0, backgroundColor: 'transparent', padding: 0 };

  const [expanded, setExpanded] = useState(false);

  const toggleLogExtra = () => {
    setExpanded(!expanded);
  }

  return (
    <div className={`${style.tableRowWrapper} ${client.is_deleted ? style.removed : ''}`} style={{ height: `${expanded ? 'auto' : ''}`, overflow: 'hidden' }}>
      <div
        className={`${style.tableRow} ${client.is_deleted ? style.removed : ''}`}
        style={{ paddingRight: '20px' }}
      >
        <div className={`${style.column} ${style.account}`}>{client.account}</div>
        <div className={`${style.column} ${style.name}`}>
          {client.first_name.split(' ')[0]} {client.last_name.split(' ').pop()}
        </div>
        <div className={`${style.column} ${style.toggleButtonWrapper}`}>
          <button id="client-mobile-toggle" className={style.toggleButton} onClick={toggleLogExtra}>
            { !expanded
              ? '+'
              : '-'
            }
          </button>
        </div>
      </div>
      <div className={style.buttonMenuWrapper}>
        <div className={`${style.tracker}`} style={{ width: '28px' }}>
          {equipment[0].data.find(eq => eq.id === client.equipment_id).is_mobile &&
            <Link
              id={`client-table-body-tracker-${client.account}`}
              className={style.actions}
              onMouseOver={() => {}}
              to={`/app/clients/${equipment[0].data.find(eq => eq.id === client.equipment_id).name.toLowerCase()}/${client.client_id}`}
            >
              <i className="fas fa-map-marker-alt" style={{ fontSize: 23, color: '#666', display: 'block', paddingTop: '0.1rem' }} />
            </Link>
          }
        </div>
        <Link
          id={`client-table-body-details-${client.account}`}
          className={style.actions}
          to={list}
        >
          <i className="far fa-eye" style={{ fontSize: 23, color: '#666', paddingTop: 3 }} />
        </Link>
        <Link
          id={`client-table-body-contacts-${client.account}`}
          className={style.actions}
          to={contacts}
        >
          <i className="fas fa-user-friends" style={{ fontSize: 23, color: '#666', paddingTop: 3 }} />
        </Link>
        <button
          id={`client-table-body-attends-${client.account}`}
          style={btnStyle}
          onClick={() => performFilter(client.client_id)}
        >
          <i className="far fa-bell" style={{ fontSize: 23, color: '#666', paddingTop: 3 }} />
        </button>
        <Link
          id={`client-table-body-transmitters-${client.account}`}
          className={style.actions}
          to={transmitters}
        >
            <i className="fas fa-broadcast-tower" style={{ fontSize: 23, color: '#666', paddingTop: 3 }} />
        </Link>
        {(isAdmin || isOwner || isManager) &&
          <Link
            id={`client-table-body-edit-${client.account}`}
            className={style.actions}
            to={edit}
          >
            <Edit className={style.icons} width="28" height="28" />
          </Link>
        }
        {(isAdmin || isOwner || isManager) && buttonStatus &&
          <button
            id={`client-table-body-delete-restore-${client.account}`}
            onClick={() => {
              if (!showModal) {
                setButtonStatus();
                setModalInfo({ showModal: true, client });
              }
            }}
            style={btnStyle}
          >
            {client.is_deleted === false ?
              <div className={style.actions}>
                <i className="far fa-trash-alt" style={{ fontSize: 23, color: '#666' }} />
              </div>
              :
              <div className={style.actions}>
                <i className="fas fa-trash-restore-alt" style={{ fontSize: 23, color: '#666' }} />
              </div>
            }
          </button>
        }
        {(isAdmin || isOwner) &&
          <Link
            id={`client-table-body-logs-${client.account}`}
            className={style.actions}
            to={logs}
          >
            <i className="fas fa-list" style={{ fontSize: 23, color: '#666', paddingTop: 3 }} />
          </Link>
        }
      </div>
    </div>
  )
};


ClientMobileRow.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool.isRequired,
  isManager: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  buttonStatus: PropTypes.bool.isRequired,
  setButtonStatus: PropTypes.func.isRequired,
  setModalInfo: PropTypes.func.isRequired,
  client: PropTypes.shape({
    id: PropTypes.number,
    is_deleted: PropTypes.bool,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    account: PropTypes.string,
    cpf: PropTypes.string,
    rg: PropTypes.string,
    birthdate: PropTypes.string,
    street: PropTypes.string,
    number: PropTypes.number,
    complement: PropTypes.string,
    district: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    state: PropTypes.string,
    zip_code: PropTypes.string,
    health_plan: PropTypes.string,
    account_phone: PropTypes.string,
    contact_phone: PropTypes.string,
    key_box: PropTypes.string,
    general_info: PropTypes.string,
    group_name: PropTypes.string,
  }).isRequired,
  equipment: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.name,
          is_active: PropTypes.bool,
          created_date: PropTypes.string,
          modified_date: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    equipment: state.equipment,
  };
}

export default connect(mapStateToProps)(ClientMobileRow);
