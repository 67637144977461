import immer from "immer";
import reducerActions from './ClientActions';

export const initialState = {
  client: {
    id: undefined,
    aditional_phones: [],
    first_name: '',
    last_name: '',
    account: '',
    cpf: '',
    rg: '',
    gender: 'm',
    birthdate: '',
    street: '',
    number: '',
    complement: '',
    district: '',
    city: '',
    state: 'RS',
    country: 'BR',
    zip_code: '',
    health_plan: '',
    account_phone: '',
    contact_phone: '',
    extra_phone: '',
    key_box: '',
    general_info: '',
    hospital: '',
    limitations: '',
    medications: '',
    removal_plan: '',
    is_test: false,
    equipment_id: 1,
    group_id: 0,
    imei: '',
    use_voip: false,
  },
  secondary: {
    id: undefined,
    first_name: '',
    last_name: '',
    cpf: '',
    rg: '',
    gender: 'm',
    birthdate: '',
    health_plan: '',
    general_info: '',
    hospital: '',
    limitations: '',
    medications: '',
    removal_plan: '',
  },
  clientValidator: {
    account: {
      validate: true,
      message: '',
    },
    account_phone: {
      validate: true,
      message: '',
    },
    birthdate: {
      validate: true,
      message: '',
    },
    cpf: {
      validate: true,
      message: '',
    },
    city: {
      validate: true,
      message: '',
    },
    district: {
      validate: true,
      message: '',
    },
    first_name: {
      validate: true,
      message: '',
    },
    last_name: {
      validate: true,
      message: '',
    },
    number: {
      validate: true,
      message: '',
    },
    phone: {
      validate: true,
      message: '',
    },
    street: {
      validate: true,
      message: '',
    },
    zip_code: {
      validate: true,
      message: '',
    },
    imei: {
      validate: true,
      message: '',
    }
  },
  secondaryValidator: {
    birthdate: {
      validate: true,
      message: '',
    },
    cpf: {
      validate: true,
      message: '',
    },
    first_name: {
      validate: true,
      message: '',
    },
    last_name: {
      validate: true,
      message: '',
    },
  },
  removeSecondary: false,
  invertClients: false,
};

const reducer = (state, action) => {
  const func = reducerActions[action.type];

  if (func) {
    return immer(state, draftState => func(draftState, action.payload));
  }
  console.log('[WARNING] Action without reducer', action);
  return state;
};

export default reducer;
