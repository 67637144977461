import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ReportButton from '../ReportButtonComponent';
import style from '../reportComponent.module.scss';

const ClientLengthOfStayComponent = ({ themeColor }) => (
  <div className={style.wrapper}>
    <ReportButton
      themeColor={themeColor}
      url="/app/reports/list/clients-length/"
      text={<FormattedMessage id="reports.generateReport" defaultMessage="Gerar Relatório" />}
    />
  </div>
);

ClientLengthOfStayComponent.propTypes = {
  themeColor: PropTypes.string.isRequired,
};

export default ClientLengthOfStayComponent;
