import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Tooltip from '../../../Tooltips/TooltipContainer';
import CheckboxInput from '../../../Common/CheckboxInput';

import style from './inNumbers.module.scss';

const IHelpInNumbers = (props) => {
  const [vin1, setVin1] = useState('');
  const [vin1Auto, setVin1Auto] = useState(false);
  const [vin2, setVin2] = useState('');
  const [vin2Auto, setVin2Auto] = useState(false);

  useEffect(() => {
    setVin1(props.params.param.VIN1);
    setVin1Auto(props.params.param.VIN1AUTO);
    setVin2(props.params.param.VIN2);
    setVin2Auto(props.params.param.VIN2AUTO);
  }, [props.params]);

  const convertAndSendParams = () => {
    const commands = [{
      code: 'vin1',
      params: `${vin1},${vin1Auto}`,
    },
    {
      code: 'vin2',
      params: `${vin2},${vin2Auto}`,
    },
    {
      code: 'getparams',
      params: '',
    },
    ];
    props.sendParams(commands);
  };

  return (
    <div className={style.wrapper}>
      <div className={style.inputContainer}>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <span className={style.textLabel}><FormattedMessage id="client.tracker.inboundNumber" defaultMessage="Número de entrada" /> 1:</span>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <input
              name="vin1"
              className={`${style.input} form-control col-8`}
              type="text"
              value={vin1}
              onChange={(ev) => setVin1(ev.target.value)}
            />
            <CheckboxInput name="vin1Auto" className={style.checkbox} checked={vin1Auto} onChange={() => setVin1Auto(!vin1Auto)} />
            <span className={style.tooltipContainer}>
              <Tooltip bottom="15px" tooltipMessage={<FormattedMessage id="client.tracker.inboundTooltip" defaultMessage="Ativa/Desativa atendimento automático. Caso seja desativado, será necessário o usuário pressionar a tecla de SOS para atender." />} />
            </span>
          </div>
        </div>
      </div>
      <div className={style.inputContainer}>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <span className={style.textLabel}><FormattedMessage id="client.tracker.inboundNumber" defaultMessage="Número de entrada" /> 2:</span>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <input
              name="vin2"
              className={`${style.input} form-control col-8`}
              type="text"
              value={vin2}
              onChange={(ev) => setVin2(ev.target.value)}
            />
            <CheckboxInput name="vin1Auto" className={style.checkbox} checked={vin2Auto} onChange={() => setVin2Auto(!vin2Auto)} />
            <span className={style.tooltipContainer}>
              <Tooltip bottom="15px" tooltipMessage={<FormattedMessage id="client.tracker.inboundTooltip" defaultMessage="Ativa/Desativa atendimento automático. Caso seja desativado, será necessário o usuário pressionar a tecla de SOS para atender." />} />
            </span>
          </div>
        </div>
      </div>
      <div className="col-12">
        <button className={style.button} onClick={convertAndSendParams}>
          <span><i className="far fa-save fa-lg" aria-hidden="true" style={{ paddingRight: '0.5rem', paddingTop: '0.2rem' }} /></span>
          <FormattedMessage id="client.tracker.saveInbound" defaultMessage="Salvar Telefones de Entrada" />
        </button>
      </div>
    </div>
  );

};

IHelpInNumbers.propTypes = {
  sendParams: PropTypes.func.isRequired,
  params: PropTypes.shape({
    param: PropTypes.shape({
      VOUT1: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      VOUT2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      VOUT1TIME: PropTypes.number,
      VOUT2TIME: PropTypes.number,
      VIN1: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      VIN2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      VIN1AUTO: PropTypes.bool,
      VIN2AUTO: PropTypes.bool,
      FDMODE: PropTypes.number,
      GEO1MODE: PropTypes.number,
      GEO1LAT: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      GEO1LON: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      GEO1RADIUS: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      GEO2MODE: PropTypes.number,
      GEO2LAT: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      GEO2LON: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      GEO2RADIUS: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      GEO3MODE: PropTypes.number,
      GEO3LAT: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      GEO3LON: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      GEO3RADIUS: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      SOSBREADCRUMB: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
      AUTOPOST: PropTypes.number,
      SUBSCRIPTIONTOPIC: PropTypes.string,
    }),
  }).isRequired,
};

export default IHelpInNumbers;
