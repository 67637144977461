import React from "react";
import PropTypes from 'prop-types';

const AlarmMovementIco = ({ height, svgClass, width, fill, transform }) => {
  return (
    <svg
      className={svgClass}
      width={width}
      height={height}
      fill={fill}
      transform={transform}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 141.7 141.7"
      version="1.1"
      viewBox="0 0 141.7 141.7"
      xmlSpace="preserve"
    >
      <g>
        <path d="M78.6 66c-.7 2.9-1.4 5.4-2 8-.3 1.1-.5 2.3-.9 3.4-.3.9 0 1.5.6 2.2 2.3 2.4 4.5 4.9 6.8 7.3 2 2.1 3.2 4.5 3.8 7.4 1.1 5 2.4 9.9 3.7 14.9.8 3.1-1.1 6.3-4.1 7-3.3.8-6.4-1-7.2-4.2-1.2-4.6-2.3-9.1-3.4-13.7-.6-2.4-2.2-4.3-3.9-6.1-3.1-3.3-6.1-6.7-9.2-10-3.6-3.8-4.4-8.1-3-13.1.9-3.1 1.6-6.2 2.4-9.6-3.4 1.5-7 1.9-8.5 5.8-.4 1-1 2.1-1.8 2.9-1.9 2-4.9 2.3-7.1.9-2.4-1.5-3.5-4.4-2.4-7 2.1-5.4 5.4-9.7 11-11.7 4-1.5 7.9-3 12-4 10.1-2.4 20.3 3.5 23.5 13.4.1.4.3.8.4 1.2.5 3.2 2.3 5.3 5.4 6.4 2.4.9 3.6 2.9 3.5 5.5 0 4-4.1 6.9-7.8 5.4-2.1-.8-4.1-2-6.1-3.1-2.4-1.4-3.8-3.6-4.7-6.2-.4-1-.7-1.8-1-3z"></path>
        <path d="M56.5 84c2.7 3 5.4 5.9 8 8.7.6.6.8 1.1.4 1.9-.6 1.4-1.2 2.8-1.7 4.3-.8 2.2-2.1 3.9-3.7 5.5l-10.1 10.1c-3.3 3.2-8.4 2-9.9-2.3-.8-2.3-.1-4.3 1.5-5.9 2.5-2.6 5-5.2 7.7-7.7 2.1-2 3.8-4.3 4.8-7.1.9-2.5 2-5 3-7.5zM67.7 34.2c0-4.8 4-8.9 8.8-8.8 5.4.1 8.8 4.2 8.9 8.8 0 4.8-4 8.8-8.8 8.8-4.9.1-8.9-4-8.9-8.8z"></path>
      </g>
    </svg>
  );
};

AlarmMovementIco.defaultProps = {
  height: '75',
  svgClass: '',
  width: '75',
  fill: '#008D53',
  transform: 'translate(0,0)',
};

AlarmMovementIco.propTypes = {
  height: PropTypes.string,
  svgClass: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
  transform: PropTypes.string,
};


export default AlarmMovementIco;