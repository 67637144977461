import React, { useState }  from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import style from './history.module.scss';
import { historyActionType } from '../../../../BLL/dictionary';


export default function TransmitterChangedRow(props) {
  const [expanded, expandInfo] = useState(false);
  const rowTransmitter = props.log.transmitter;
  let nextTransmitter = {};
  if (props.index !== props.array.length -1) {
    const nextAction = props.array.find((ac) =>
      (ac.transmitter.zone === rowTransmitter.zone
        && moment(ac.actionDate) < moment(props.log.actionDate))
    );
    nextTransmitter = nextAction ? nextAction.transmitter : {};
  }
  const transmitter = Object.assign({}, rowTransmitter, {
    identifier: nextTransmitter.identifier === rowTransmitter.identifier ? undefined : rowTransmitter.identifier,
    description: nextTransmitter.description === rowTransmitter.description ? undefined : rowTransmitter.description,
    generalInfo: nextTransmitter.generalInfo === rowTransmitter.generalInfo ? undefined : rowTransmitter.generalInfo,
  });
  const actionType = historyActionType(props.log.actionType);
  return(
  <div className={style.tableRowWrapper} style={{ height: `${expanded ? 'auto' : '3rem'}`, overflow: 'hidden' }}>
    <div className={style.tableRow}>
      <div className={style.tableBodyColumn}>
        {transmitter.zone}
      </div>
      <div className={style.tableBodyColumn}>
        {actionType}
      </div>
      <div className={style.bodyDataColumn}>{moment(props.log.actionDate).format('DD/MM/YYYY HH:mm:ss')}</div>
      <div className={style.bodyChangedByColumn}>
        {props.log.actionUser ? props.log.actionUser.firstName : ''} {props.log.actionUser ? props.log.actionUser.lastName : 'Contactto Vendas'}
      </div>
      <div className={style.bodyToggleButton}>
        <button className={style.toggleButton} onClick={() => expandInfo(!expanded)}>
          { !expanded
            ? <FormattedMessage id="history.expand" defaultMessage="Expandir" />
            : <FormattedMessage id="history.hide" defaultMessage="Ocultar" />
          }
        </button>
      </div>
    </div>
    <div className={style.rowExtraInfoWrapper}>
      {transmitter.identifier !== undefined &&
        <div className={style.extraInfoTextWrapper}>
          <span className={style.infoLabel}>
            <FormattedMessage id="transmitter.detail.identifier" defaultMessage="Identificador" />:
          </span>
          <span className={style.infoText}>{transmitter.identifier}</span>
        </div>
      }
      {transmitter.description !== undefined &&
        <div className={style.extraInfoTextWrapper}>
          <span className={style.infoLabel}>
            <FormattedMessage id="transmitter.detail.description" defaultMessage="Descrição" />:
          </span>
          <span className={style.infoText}>{transmitter.description}</span>
        </div>
      }
      {transmitter.generalInfo !== undefined &&
        <div className={style.extraInfoTextWrapper}>
          <span className={style.infoLabel}>
            <FormattedMessage id="transmitter.detail.observations" defaultMessage="Observações" />:
          </span>
          <span className={style.infoText}>{transmitter.generalInfo}</span>
        </div>
      }
    </div>
  </div>);
}
