import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import FemaleIcon from '../../../../../icons/FemaleIcon';
import MaleIcon from '../../../../../icons/MaleIcon';
import UnisexIcon from '../../../../../icons/TransexIcon';
import MobileIcon from '../../../../../icons/MobileDeviceIcon';
import HomeIcon from '../../../../../icons/HomeDeviceIcon';
import style from './attendHeader.module.scss';

const ClientInfo = ({ client, secondary, transmitterList, alarms, equipment, isAttendOperator, isAdmin, changeAttendant }) => {
  let showSecondary = false;
  const highestAlarm = alarms && alarms.sort((a, b) => a.event_type.priority - b.event_type.priority)[0];
  if (highestAlarm?.tr_zone && transmitterList.length > 0) {
    const mainTransmitter = transmitterList.find(tr => tr.zone === highestAlarm.tr_zone && tr.identifier === highestAlarm.tr_identifier);
    if (mainTransmitter?.secondary > 0 && secondary) {
      showSecondary = true;
    }
  }
  return (
    <div className={style['client-info-container']}>
      <span className={style['client-name']} id='client-name'>
        {!showSecondary ? client.first_name : secondary.first_name} {!showSecondary ? client.last_name : secondary.last_name}
      </span>
      {!isAttendOperator && isAdmin &&
        <div className={style['btn-content']}>
          <button
            style={{ top: 0 }}
            className={style.btnTakeCall}
            onClick={changeAttendant}
          >
            <FormattedMessage id="attend.open.takeAttend" defaultMessage="PEGAR ATENDIMENTO" />
          </button>
        </div>
      }
      {!showSecondary
        ?
          <div style={{ float: 'right' }}>
            {client.gender === 'm' && <MaleIcon color="#00b8cb" height={'4rem'} width={'4rem'} />}
            {client.gender === 'f' && <FemaleIcon color="#00b8cb" height={'4rem'} width={'4rem'} />}
            {client.gender === 'n' && <UnisexIcon color="#00b8cb" height={'4rem'} width={'4rem'} />}
          </div>
        :
          <div style={{ float: 'right' }}>
            {secondary.gender === 'm' && <MaleIcon color="#00b8cb" height={'4rem'} width={'4rem'} />}
            {secondary.gender === 'f' && <FemaleIcon color="#00b8cb" height={'4rem'} width={'4rem'} />}
            {secondary.gender === 'n' && <UnisexIcon color="#00b8cb" height={'4rem'} width={'4rem'} />}
          </div>
      }
      <div className={style['age-number']} >
        {!showSecondary ? moment().year() - moment(client.birthdate).year() : moment().year() - moment(secondary.birthdate).year()}
        <span className={style['age-years']}>
          <FormattedMessage id="attend.open.years" defaultMessage="anos" />
        </span>
      </div>
      {equipment.is_mobile
        ? <MobileIcon color="#00b8cb" height={'4rem'} width={'4rem'} />
        : <HomeIcon color="#00b8cb" height={'4rem'} width={'4rem'} />
      }
      
    </div>
  )
};

ClientInfo.propTypes = {
  client: PropTypes.shape({
    birthdate: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    gender: PropTypes.string,
  }).isRequired,
  secondary: PropTypes.shape({
    birthdate: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    gender: PropTypes.string,
  }),
  isAdmin: PropTypes.bool.isRequired,
  isAttendOperator: PropTypes.bool.isRequired,
  changeAttendant: PropTypes.func.isRequired
};

export default ClientInfo;
