import React from 'react';

const ComponentFailed = (props) => {
  const { error } = props;

  if (error?.name === 'NotFoundError' && error?.message === "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.") {
    return (
      <div style={{ width: '25%' }}>
        Ocorreu um erro, se você estiver utilizando google translate, por favor desative-o e verifique se o erro persiste.
      </div>
    );
  }

  return (
    <div>
      Ocorreu um erro com este componente, o erro está sendo catalogado e será resolvido o mais breve possível...
    </div>
  );
}

export default ComponentFailed;
