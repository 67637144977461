import * as types from './actionTypes';
import * as errorsActions from './errorsActions';
import * as globals from '../globals/globals';
import { host, getDomain, getToken } from '../BLL/environment';

export function loadAttendsSuccess(results, ordering, page, filter, pageUrl) {
  return { type: types.LOAD_ATTENDS_SUCCESS, results, ordering, page, filter, pageUrl };
}

export function setAttendPage(pageNumber, pageUrl) {
  return { type: types.SET_ATTEND_PAGE, pageNumber, pageUrl };
}

export function newAttendtPage(pageNumber, newPage, pageUrl) {
  return { type: types.RECEIVE_NEW_ATTEND_PAGE, pageNumber, newPage, pageUrl };
}

export function attendUpdated(attend) {
  return { type: types.EDIT_ATTEND, attend };
}

export function loadAttends(ordering = globals.attendantsOrdering, filter = '') {
  const domain = getDomain();
  const token = getToken();

  let url = `https://${domain}${host()}/api/attend/list/?ordering=${ordering}&limit=${globals.pagination}`;
  if (filter) {
    url = `${url}&${filter}`;
  }
  return (dispatch, getState, { axios }) => Promise.resolve(
    axios.get(url, {
      headers: { Authorization: `Token ${token}` },
    }).then(res => {
      const page = 1;
      dispatch(loadAttendsSuccess(res.data, ordering, page, filter, url));
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(errorsActions.logout());
        }
      }
    }),
  );
}

export function reloadAttends() {
  const domain = getDomain();
  const token = getToken();

  return (dispatch, getState, { axios }) => {
    const { attends } = getState();
    return Promise.resolve(
      axios.get(attends.activeUrl, {
        headers: { Authorization: `Token ${token}` },
      }).then(res => dispatch(loadAttendsSuccess(
        res.data,
        attends.ordering,
        attends.activePage,
        attends.filter,
        attends.activeUrl,
      ))),
    );
  };
}

export function changePage(pageNumber, pageUrl) {
  const token = getToken();

  return (dispatch, getState, { axios }) => {
    const { attends } = getState();
    const activePage = attends.pages
      ? attends.pages.find(data => data.page === pageNumber)
      : undefined;
    if (activePage) {
      return Promise.resolve(dispatch(setAttendPage(pageNumber, pageUrl)));
    }
    return Promise.resolve(
      axios.get(pageUrl, {
        headers: { Authorization: `Token ${token}` },
      }).then((res) => {
        dispatch(newAttendtPage(pageNumber, res.data, pageUrl));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch(errorsActions.logout());
          }
        }
      }),
    );
  };
}

export function editAttend(attend) {
  const domain = getDomain();
  const token = getToken();

  return (dispatch, getState, { axios }) => new Promise((resolve, reject) =>
      axios({
        method: 'put',
        responseType: 'json',
        url: `https://${domain}${host()}/api/attend/${attend.id}/`,
        data: attend,
        headers: { Authorization: `Token ${token}` },
      })
      .then((resp) => resolve(dispatch(attendUpdated(resp.data))))
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            return reject(dispatch(errorsActions.logout()));
          }
        }
        return reject(error);
      }),
  );
}

export function patchAttend(attendId, data) {
  const domain = getDomain();
  const token = getToken();

  return (dispatch, getState, { axios }) => new Promise((resolve, reject) =>
      axios({
        method: 'put',
        responseType: 'json',
        url: `https://${domain}${host()}/api/attend/${attendId}/`,
        headers: { Authorization: `Token ${token}` },
        data,
      })
      .then((resp) => resolve(dispatch(attendUpdated(resp.data))))
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            return reject(dispatch(errorsActions.logout()));
          }
        }
        return reject(error);
      }),
  );
}

export function updateTag(attendId, tag) {
  const domain = getDomain();
  const token = getToken();

  return (dispatch, getState, { axios }) => new Promise((resolve, reject) =>
    axios({
      method: 'put',
      responseType: 'json',
      url: `https://${domain}${host()}/api/attend/${attendId}/tag-update/`,
      data: tag,
      headers: { Authorization: `Token ${token}` },
    })
    .then((resp) => resolve(dispatch(attendUpdated(resp.data))))
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          return reject(dispatch(errorsActions.logout()));
        }
      }
      return reject(error);
    }),
  );
}
