import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Edit from '../../../../icons/Edit';

import style from '../management.module.scss';
import styleScss from './companiesManagement.module.scss';

const CompanyUserRow = ({
  isAdmin,
  isOwner,
  attendant,
  showModal,
  setTextIconDetail,
  setModalInfo,
  loggedAttendant,
  companyId,
}) => {
  const btnStyle = { border: 0, backgroundColor: 'transparent', padding: 0 };
  const ownerOrAdm = isOwner || isAdmin;
  const managerOption = (attendant.groups[0] === 3 && loggedAttendant.groups[0] === 2)
     || attendant.groups[0] === loggedAttendant.groups[0];

  return (
    <div key={attendant.id} className={`${style.row} ${attendant.is_active ? '' : style.removed}`}>
      <div className={`${style.column} ${styleScss.username}`}>{attendant.username}</div>
      <div className={`${style.column} ${styleScss.name}`}>{attendant.first_name} {attendant.last_name}</div>
      <div className={`${style.column} ${styleScss.email}`}>{attendant.email}</div>
      <div className={style['column-icons']}>
        {(ownerOrAdm || managerOption) &&
        <div>
          <Link
            className={style.actions}
            to={`/app/companies/${companyId}/user/form/edit/${attendant.id}`}
          >
            <Edit className={style.icons} width="28" height="28" />
          </Link> <span style={{ paddingLeft: '1rem' }} />
          {(attendant.groups[0] !== 4 && attendant.id !== loggedAttendant.id) &&
            <button
              onClick={() => { if (!showModal) { setModalInfo(true, attendant.id); } }}
              style={btnStyle}
            >
              {attendant.is_active === true ?
                <div className={style.actions}>
                  <i className="far fa-trash-alt" style={{ fontSize: 23, color: '#666', paddingTop: '0.4rem' }} />
                  {/* <Delete className={style.icons} width="28" height="28" /> */}
                </div>
                :
                <div className={style.actions}>
                  <i className="fas fa-trash-restore-alt" style={{ fontSize: 23, color: '#666', paddingTop: '0.4rem' }} />
                  {/* <Restore className={style.icons} width="28" height="28" /> */}
                </div>
              }
            </button>
          }

        </div>
        }
      </div>
    </div>
  );
};

CompanyUserRow.defaultProps = {
  isAdmin: false,
  isOwner: false,
};

CompanyUserRow.propTypes = {
  attendant: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    groups: PropTypes.arrayOf(
      PropTypes.number,
    ),
    is_active: PropTypes.bool,
    is_staff: PropTypes.bool,
    is_superuser: PropTypes.bool,
    user_info: PropTypes.shape({
      id: PropTypes.number,
      user: PropTypes.number,
      phone: PropTypes.string,
      mobile: PropTypes.string,
      email: PropTypes.string,
      extension_id: PropTypes.number,
    }),
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        priority: PropTypes.number,
        user: PropTypes.number,
        receive_sms: PropTypes.bool,
        receive_email: PropTypes.bool,
      }),
    ),
  }).isRequired,
  isAdmin: PropTypes.bool,
  isOwner: PropTypes.bool,
  showModal: PropTypes.bool.isRequired,
  setTextIconDetail: PropTypes.func.isRequired,
  setModalInfo: PropTypes.func.isRequired,
  loggedAttendant: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    groups: PropTypes.arrayOf(
      PropTypes.number,
    ),
    is_active: PropTypes.bool,
  }).isRequired,
  companyId: PropTypes.number.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    loggedAttendant: state.loggedAttendant,
  };
}

export default connect(mapStateToProps)(CompanyUserRow);
