import React from 'react';
import { FormattedMessage } from 'react-intl';
import DatePicker, { registerLocale } from 'react-datepicker';
import br from 'date-fns/locale/pt-BR';
import us from 'date-fns/locale/en-US';
import CustomDatePickerInput from '../../../Common/CustomDatePickerInput';
import messages from '../../../../BLL/locale/messages';

import style from './tracker.module.scss';
import '../../../../css/middle.scss';
import './stylesheets/datepicker-cssmodules.scss';

registerLocale('pt-br', br);
registerLocale('en-us', us);


const HistoryMenu = (props) => {
  return (
    <div className={style.trackerMenuContainer}>
      <div className={style.trackerMenuValue}>
        <span className={style.trackerMenuText}><FormattedMessage id="client.tracker.begining" defaultMessage="Início: " /></span>
        <div className={style.trackerMenuDate}>
          <DatePicker
            wrapperClassName="datepicker-custom"
            customInput={<CustomDatePickerInput />}
            selected={props.startDate}
            onChange={(ev) => props.handleStartChange(ev)}
            locale={props.lang === 'pt' ? 'pt-br' : 'en-us'}
            showTimeSelect
            timeFormat="HH"
            timeIntervals={60}
            dateFormat="dd/MM/yyyy - HH:00"
            timeCaption={messages[props.lang]['client.tracker.hour']}
            previousMonthButtonLabel=""
            nextMonthButtonLabel=""
          />
        </div>
      </div>
      <div className={style.trackerMenuValue}>
        <span className={style.trackerMenuText}><FormattedMessage id="client.tracker.end" defaultMessage="Fim: " /></span>
        <div className={style.trackerMenuDate}>
          <DatePicker
            wrapperClassName="datepicker-custom"
            customInput={<CustomDatePickerInput />}
            selected={props.endDate}
            onChange={(ev) => props.handleEndChange(ev)}
            locale={props.lang === 'pt' ? 'pt-br' : 'en-us'}
            showTimeSelect
            timeFormat="HH"
            timeIntervals={60}
            dateFormat="dd/MM/yyyy - HH:00"
            timeCaption={messages[props.lang]['client.tracker.hour']}
            previousMonthButtonLabel=""
            nextMonthButtonLabel=""
          />
        </div>
      </div>
      <button className={style.trackerMenuBtn} onClick={props.fetchPoints}>
      <span><i className="fas fa-search fa-lg" aria-hidden="true" style={{ paddingRight: '0.5rem', paddingTop: '0.2rem' }} /></span>
         <FormattedMessage id="client.tracker.search" defaultMessage="Procurar" />
      </button>
    </div>
  );
};

export default HistoryMenu;
