import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import messages from '../../BLL/locale/messages';
import style from './modalClientFilter.module.scss';
import formStyle from '../../css/form.module.scss';

const ModalClientFilter = (props) => {
  const wrapperRef = useRef(null);
  const [account, setAccount] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [tracker, setTracker] = useState('-1');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [disableFilter, setDisableFilter] = useState(true);

  useEffect(() => {
    document.addEventListener('mousedown', clickedOutside);

    return () => {
      document.removeEventListener('mousedown', clickedOutside);
    }
  }, []);

  useEffect(() => {
    if (account || companyId || firstName || lastName || parseInt(tracker) !== -1) {
      setDisableFilter(false);
    } else {
      setDisableFilter(true);
    }
  }, [account, companyId, tracker, firstName, lastName]);

  useEffect(() => {
    if (props.filter) {
      props.filter.split('&').forEach((elm) => {
        const key = elm.split('=')[0];
        const value = elm.split('=')[1];
        switch (key) {
          case 'account': {
            if (value) {
              setAccount(value);
            }
            break;
          }
          case 'first_name': {
            if (value) {
              setFirstName(value);
            }
            break;
          }
          case 'last_name': {
            if (value) {
              setLastName(value)
            }
            break;
          }
          case 'group_id': {
            if (value) {
              setCompanyId(value);
            }
            break;
          }
          case 'equipment_ids': {
            const mobileEquipmentIds = props.equipment.filter(eq => eq.is_mobile).map(eq => eq.id).toString();
            if (value === mobileEquipmentIds) {
              setTracker(1);
            } else if (!value) {
              setTracker(-1);
            } else {
              setTracker(0);
            }
            break;
          }
          default:
            break;
        }
      });
    }
  }, [props.filter, props.equipment]);

  const clickedOutside = (evt) => {
    if (wrapperRef.current && !wrapperRef.current.contains(evt.target)) {
      props.closeModal();
    }
  }

  const generateAndSendFilter = () => {
    let trackerFilter = '';
    switch (parseInt(tracker, 10)) {
      case -1: {
        trackerFilter = '';
        break;
      }
      case 1: {
        trackerFilter = props.equipment.filter(eq => eq.is_mobile).map(eq => eq.id).toString();
        break;
      }
      case 0: {
        trackerFilter = props.equipment.filter(
          eq => !eq.is_mobile).map(eq => eq.id).toString();
        break;
      }
      default: {
        trackerFilter = '';
        break;
      }
    }
    const url = `group_id=${!companyId ? '' : companyId}&first_name=${!firstName ? '' : firstName}&last_name=${!lastName ? '' : lastName}&equipment_ids=${trackerFilter}&account=${!account ? '' : account}`;
    props.filterClients(url);
  }

  return (
    <div className={style['filter-wrapper']}>
      <div className={style['filter-container']} ref={wrapperRef}>
        <div className={style['filter-header']}>
          <FormattedMessage id="filter.header" defaultMessage="FILTRAR USUÁRIOS" />
        </div>
        {props.loggedUser && props.loggedUser.groups.length === 1 &&
          <div className="form-group" >
            <label className="text-muted" style={{ fontSize: '0.8rem' }} htmlFor="companyId">
              <FormattedMessage id="filter.company" defaultMessage="Empresa" />:
            </label>
            <select
              id="companyId"
              className={`custom-select ${formStyle['select--enable']}`}
              name="companyId"
              onChange={(e) => setCompanyId(e.target.value)}
              style={{ fontSize: '0.8rem' }}
              value={companyId}
            >
              <option value={''}>{messages[props.lang]['filter.ignore']}</option>
              <option value={'0'}>-</option>
              {props.companies &&
                props.companies
                .sort((compA, compB) => compA.name.localeCompare(compB.name))
                .map(item =>
                  <option key={item.id} value={item.id} >
                    {item.name}
                  </option>,
                )
              }
            </select>
          </div>
        }
        <div className="form-group" >
          <label className="text-muted" style={{ fontSize: '0.8rem' }} htmlFor="account">
            <FormattedMessage id="inputContainer.account" defaultMessage="Conta: " />
          </label>
          <input
            id="account"
            style={{ fontSize: '0.8rem' }}
            className="form-control"
            maxLength="4"
            name="account"
            onChange={(e) => setAccount(e.target.value)}
            type="text"
            value={account}
          />
        </div>
        <div className="form-group" >
          <label className="text-muted" style={{ fontSize: '0.8rem' }} htmlFor="firstName">
            <FormattedMessage id="inputContainer.firstName" defaultMessage="Nome: " />
          </label>
          <input
            id='firstName'
            style={{ fontSize: '0.8rem' }}
            className="form-control"
            maxLength="15"
            name="name"
            onChange={(e) => setFirstName(e.target.value)}
            type="text"
            value={firstName}
          />
        </div>
        <div className="form-group">
          <label className="text-muted" style={{ fontSize: '0.8rem' }} htmlFor="lastName">
            <FormattedMessage id="inputContainer.lastName" defaultMessage="Sobrenome: " />
          </label>
          <input
            id="lastName"
            style={{ fontSize: '0.8rem' }}
            className="form-control"
            maxLength="15"
            name={lastName}
            onChange={(e) => setLastName(e.target.value)}
            type="text"
            value={lastName}
          />
        </div>
        <div className="form-group">
          <label className="text-muted" style={{ fontSize: '0.8rem' }} htmlFor="tracker">
            <FormattedMessage id="filter.useTracker" defaultMessage="Usa Rastreador" />: </label>
          <select
            id="tracker"
            style={{ fontSize: '0.8rem' }}
            className={`custom-select ${
              formStyle['select--enable']
              }`}
              name="tracker"
              value={tracker}
              onChange={(e) => setTracker(e.target.value)}
            >
            <option value={-1}>{messages[props.lang]['filter.ignore']}</option>
            <option value={1} >{messages[props.lang]['general.yes']}</option>
            <option value={0} >{messages[props.lang]['general.no']}</option>
          </select>
        </div>
        <div className="form-group">
          <button
            disabled={disableFilter}
            className={`${style['filter-btn']} ${disableFilter && style['filter-btn-disabled']}`}
            onClick={generateAndSendFilter}
            type="button"
          >
            <span className={style['filter-button-bold']}>
            <i className="fa fa-filter" aria-hidden="true" /> <FormattedMessage id="filter.btn" defaultMessage="FILTRAR" />
            </span>
          </button>
        </div>
      </div>
    </div>
  )
};

ModalClientFilter.propTypes = {
  filter: PropTypes.string.isRequired,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      user_set: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          is_active: PropTypes.bool,
          first_name: PropTypes.string,
          last_name: PropTypes.string,
          email: PropTypes.string,
          groups: PropTypes.arrayOf(
            PropTypes.number,
          ),
        }),
      ),
    }),
  ).isRequired,
  equipment: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.name,
      is_active: PropTypes.bool,
      is_mobile: PropTypes.bool,
    }),
  ).isRequired,
  filterClients: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  loggedUser: PropTypes.shape({
    groups: PropTypes.arrayOf(
      PropTypes.number,
    ),
    id: PropTypes.number,
    is_active: PropTypes.bool,
    username: PropTypes.string,
  }).isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    companies: state.groups.filter(grp => ![1, 2, 3, 4].includes(grp.id)),
    equipment: state.equipment[0].data,
    lang: state.locale.lang,
    loggedUser: state.loggedAttendant,
  };
}

export default connect(mapStateToProps)(ModalClientFilter);
