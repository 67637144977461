import React from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';
import AttendWarningMessages from '../AttendWarningMessages';
import ClientInfo from './ClientInfo';
import CallClock from './CallClock';
import CallControls from './CallControls';
import ComponentFailedErrorBoundary from '../../../../Error/ComponentFailedErrorBoundary';

import style from './attendHeader.module.scss';
import '../../../../../css/detailPage.scss';

const AttendHeader = (props) => {
  return (
    <div className={style.row} style={{ display: props.display ? '' : 'none' }}>
      <ClientInfo
        client={props.client}
        secondary={props.secondary}
        transmitterList={props.transmitterList}
        alarms={props.alarms}
        equipment={props.equipment}
        isAttendOperator={props.isAttendOperator}
        isAdmin={props.isAdmin}
        changeAttendant={props.changeAttendant}
      />
      <CallClock callState={props.callState} callTimer={props.callTimer} duration={props.duration}/>
      {props.isAttendOperator &&
        <CallControls
          equipment={props.equipment}
          callState={props.callState}
          audioStatus={props.audioStatus}
          callMsg={props.callMsg}
          muteAudio={props.muteAudio}
          mutePhone={props.mutePhone}
          autoCall={props.autoCall}
          attendSip={props.attendSip}
          hangupSip={props.hangupSip}
          toggleReminderNote={props.toggleReminderNote}
          setAudioStatus={(val) => props.setAudioStatus(val)}
          openModalReturn={() => props.openModalReturn(!props.showReturnCallModal)}
          openModalExtension={() => props.openModalExtension(true)}
          clientMessages={props.clientMessages}
          replyMessage={props.replyMessage}
          setReplyMessage={props.setReplyMessage}
          sendResponseToAlexa={props.sendResponseToAlexa}
          setCallState={props.setCallState}
        />
      }
      <ErrorBoundary FallbackComponent={ComponentFailedErrorBoundary}>
        <AttendWarningMessages
          sendWarningMessages={props.sendWarningMessages}
          warningLimit={props.warningLimit}
          currentServerTime={props.currentServerTime}
          alarmTime={props.alarmTime}
          stopWarningMessages={props.stopWarningMessages}
        />
      </ErrorBoundary>
    </div>
  )
};

AttendHeader.propTypes = {
  client: PropTypes.shape({
    birthdate: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    gender: PropTypes.string,
  }).isRequired,
  equipment: PropTypes.shape({
    name: PropTypes.string,
    is_mobile: PropTypes.bool,
  }).isRequired,
  callTimer: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  callState: PropTypes.number.isRequired,
  display: PropTypes.bool.isRequired,
  audioStatus: PropTypes.string.isRequired,
  isAttendOperator: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  lang: PropTypes.string.isRequired,
  callMsg: PropTypes.string.isRequired,
  attendSip: PropTypes.func.isRequired,
  autoCall: PropTypes.func.isRequired,
  hangupSip: PropTypes.func.isRequired,
  muteAudio: PropTypes.func.isRequired,
  mutePhone: PropTypes.func.isRequired,
  setAudioStatus: PropTypes.func.isRequired,
  toggleReminderNote: PropTypes.func.isRequired,
  changeAttendant: PropTypes.func.isRequired,
  setCallState: PropTypes.func.isRequired,
};

export default AttendHeader;
