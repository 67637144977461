import * as types from '../actions/actionTypes';
import initialState from './initialState';

export const reminderComponentReducer = (state = initialState.showReminderComponent, action) => {
  if (action.type === types.TOGGLE_REMINDER_COMPONENT) {
    return !state;
  }
  return state;
};

export default reminderComponentReducer;
