import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import style from './reportLengthOfStay.module.scss';

const ReportLengthStayRow = ({ report }) => {
  return (
    <div className={`${style.row} page-break`}>
      <div className={`${style.account} ${style.column}`}>{report.account}</div>
      <div className={`${style.user} ${style.column}`}>{report.client}</div>
      <div className={`${style.day} ${style.column}`}>{report.days_length}</div>
      <div className={`${style['date-created']} ${style.column}`}>{moment(report.created_date).format('DD/MM/YYYY - HH:mm')}</div>
      <div className={`${style['date-deleted']} ${style.column}`}>{report.deleted_date === '-' ? report.deleted_date : moment(report.deleted_date).format('DD/MM/YYYY - HH:mm')}</div>
    </div>
  );
};

ReportLengthStayRow.propTypes = {
  report: PropTypes.shape({
    account: PropTypes.string,
    client: PropTypes.string,
    days_length: PropTypes.number,
    created_date: PropTypes.string,
    deleted_date: PropTypes.string,
  }).isRequired,
};

export default ReportLengthStayRow;
