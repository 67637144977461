import React from 'react';
import PropTypes from 'prop-types';
import '../css/loading.scss';

const Loading = ({ text }) => (

  <div className="loading-bro">
    <h1>{text}</h1>
    <div className="loading-svg-wrapper">
      <svg id="load" x="0px" y="0px" viewBox="0 0 150 150">
        <circle id="loading-inner" cx="75" cy="75" r="45" />
      </svg>
    </div>
  </div>

);

Loading.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

Loading.defaultProps = { text: 'Carregando' };

export default Loading;
