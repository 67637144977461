import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ConfirmModal from '../Modal/ModalConfirm';
import style from './headerActions.module.scss';

const HeaderActions = (props) => {
  const [showConfirm, setShowModal] = useState(false);
  const sessionDescription = props.sessionListener?.description;

  const closeModal = ({ showModal, client = {} }) => {
    setShowModal(showModal);
  };

  const hangUpCall = () => {
    setShowModal(false);
    props.hangupSip();
  };

  return (
    <div className={style.container}>
      {showConfirm &&
        <ConfirmModal
          action={hangUpCall}
          setModalInfo={closeModal}
          message={"Você está desligando esta chamada fora do atendimento, você tem certeza disso?"}
        />
      }
      {props.audioAction === 'start' &&
        <button className={style.iconButton} onClick={props.turnOffAlarmAudio}>
          <i class="fas fa-bell-slash fa-2x" />
        </button>
      }
      {sessionDescription === 'In Call' &&
        <button className={style.iconButton} onClick={() => setShowModal(true)}>
          <i class="fas fa-phone-slash fa-2x" />
        </button>
      }
    </div>
  )
}

HeaderActions.propTypes = {
  audioAction: PropTypes.string.isRequired,
  turnOffAlarmAudio: PropTypes.func.isRequired,
}

export default HeaderActions
