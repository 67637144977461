/* eslint no-useless-escape: 0 */
/* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */

export const specialCharReg = /[`~˜!@#$%^ˆ&*()_ |+\-=?;:'.",<>\{\}\[\]\\\/]/gi;
export const specialCharReg2 = /[`~˜!@#$%^ˆ&*()_ |+\-=?;:'.",<>\{\}\[\]\\\/]$/;
export const usernameReg = /^[a-z]+([.][a-z]+)?$/;
export const dotReg = /[a-z]?[.]$/;
export const twoCharReg = /[a-z]{2}$/;
export const usernameRegNeg = /[`~˜!@#$%^ˆ&*()_ |+\-=?;:'",\d<>\{\}\[\]\\\/]/gi;
export const domainReg = /^[a-z][a-z0-9]+$/;
export const digitReg = /\d+/gi;
export const companyReg = /^[a-zA-Z0-9][a-zA-Z0-9\s]+$/;

export const validateEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const validateMobile = (phone) => {
  const val = /[1-9]{2}[9]{1}[0-9]{8}/;
  if (!val.test(phone)) {
    return false;
  }
  return true;
};

export const validateMobileGB = (phone) => {
  const val = /[1-9]{2}[7]{1}[0-9]{9}/;
  if (!val.test(phone)) {
    return false;
  }
  return true;
};

export const validateCPF = (cpf) => {
  let strCPF;
  let Soma;
  let Resto;
  if (/^\d\d\d[.]\d\d\d[.]\d\d\d-\d\d$/.test(cpf)) {
    const [, first, second, third, fourth] = /^(\d\d\d)[.](\d\d\d)[.](\d\d\d)-(\d\d)$/.exec(cpf);

    strCPF = first + second + third + fourth;
  } else {
    strCPF = cpf;
  }

  Soma = 0;
  if (strCPF === '00000000000') return false;

  for (let i = 1; i <= 9; i++) Soma += parseInt(strCPF.substring(i - 1, i), 10) * (11 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(9, 10), 10)) return false;

  Soma = 0;
  for (let i = 1; i <= 10; i++) Soma += parseInt(strCPF.substring(i - 1, i), 10) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(10, 11), 10)) return false;
  return true;
};

export const validateDate = (value) => {
  const maxYear = new Date().getFullYear() - 18;
  if (/^\d\d\d\d-\d\d-\d\d$/.test(value)) {
    const [, year, month, day] = /^(\d\d\d\d)-(\d\d)-(\d\d)$/.exec(value);
    return `${day}/${month}/${year}`;
  } else if (/^\d\d[/]\d\d[/]\d\d\d\d$/.test(value)) {
    let [, day, month, year] = /^(\d\d)[/](\d\d)[/](\d\d\d\d)$/.exec(value);
    const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) monthLength[1] = 29;

    // if (year <= maxYear && month > 0 && month <= 12 && day > 0 && day < monthLength[month - 1]) {
    //   // return ""
    //   return true;
    // }
    if (day < 1) {
      day = '01';
    } else if (day > monthLength[month - 1]) {
      day = monthLength[month - 1];
    }
    if (month < 1) {
      month = '01';
    } else if (month > 12) {
      month = 12;
    }
    if (year > maxYear) {
      year = maxYear;
    }
    return `${day}/${month}/${year}`;
  }
  return '01/01/1900';
};
