import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import { FormattedMessage } from 'react-intl';
import { callStates } from '../../../../../BLL/globals';
import messages from '../../../../../BLL/locale/messages';
import Speak from '../../../../../icons/Speak';
import Listen from '../../../../../icons/Listen';
import TwoWayAudio from '../../../../../icons/TwoWayAudio';
import AlexaStateComponent from './AlexaStateComponent';
import style from './attendHeader.module.scss';

const CLIMAX_EQUIP_ID = 7;

const CallClosedComponent = ({toggleReminderNote, setAudioStatus, canReturn, openModalReturn, openModalExtension, audioStatus, lang}) => {
  const { useZones } = useSelector((state) => ({
    useZones: state.globalConfiguration?.global?.use_alarm_zones
  }));

  return (
    <div className={style['call-controls-container']}>
      <div className={style['closed-call']}>
        <span className={style['call-info-text']}>
          <FormattedMessage id="attend.open.closedCall" defaultMessage="Chamada Encerrada" />
        </span>
        <button
          id="create-reminder-button"
          className={style['btn-clock']}
          onClick={toggleReminderNote}
          onMouseOver={() => setAudioStatus(messages[lang]['attend.reminder.createReminder'])}
          onMouseOut={() => setAudioStatus('')}
        >
          <i className={`far fa-clock ${style.btnIcon}`} aria-hidden="true" />
        </button>
        {audioStatus &&
          <div className={style['call-audio-status']}>
            {audioStatus}
          </div>
        }
        {canReturn &&
          <button className={`${style['btn-call']} ${style['btn-wide']}`} onClick={openModalReturn}>
            <FormattedMessage id="attend.open.callUser" defaultMessage="RETORNAR PARA USUÁRIO" />
          </button>
        }
        {!useZones &&
          <button className={`${style['btn-external-call']} ${style['btn-wide']}`} onClick={openModalExtension}>
            <FormattedMessage id="attend.open.extensionCall" defaultMessage="CHAMADAS DE EXTENSÃO" />
          </button>
        }
      </div>
    </div>
  );
};

const CallOpenComponent = ({attendSip}) => (
  <div className={style['call-controls-container']}>
    <div className={style['btn-content']}>
      <button className={style['btn-call']} onClick={attendSip}>
        <FormattedMessage id="attend.open.takeCall" defaultMessage="ATENDER CHAMADO" />
      </button>
    </div>
  </div>
);

const CallInProgressComponent = ({hangupSip, equipment, muteAudio, setAudioStatus, mutePhone, autoCall, callMsg, audioStatus}) => (
  <div className={style['call-controls-container']}>

    <div className={style['btn-hangup-container']}>
      <div className={style['btn-content']}>
        <button className={style['btn-call']} onClick={hangupSip}>
          <FormattedMessage id="attend.open.hangUp" defaultMessage="DESLIGAR" />
        </button>
      </div>
      {(!equipment.is_mobile || (equipment.is_mobile && equipment.id === CLIMAX_EQUIP_ID)) &&
        <div className={style['btn-audio-control']}>
          <button
            className={`${style['btn-audio-single']} ${style['btn-speak-color']}`}
            onClick={muteAudio}
            onMouseOver={() => setAudioStatus(<FormattedMessage id="attend.open.speak" defaultMessage="Falar" />)}
            onMouseOut={() => setAudioStatus('')}
          >
            <Speak />
          </button>
          <button
            className={`${style['btn-audio-single']} ${style['btn-listen-color']}`}
            onClick={mutePhone}
            onMouseOver={() => setAudioStatus(<FormattedMessage id="attend.open.listen" defaultMessage="Ouvir" />)}
            onMouseOut={() => setAudioStatus('')}
          >
            <Listen />
          </button>
          <button
            className={`${style['btn-audio-single']} ${style['btn-two-way-audio-color']}`}
            onClick={autoCall}
            onMouseOver={() => setAudioStatus(<FormattedMessage id="attend.open.speakAndListen" defaultMessage="Falar e Ouvir" />)}
            onMouseOut={() => setAudioStatus('')}
          >
            <TwoWayAudio />
          </button>
          <div className={style['audio-status-text']}>
            {audioStatus}
          </div>
        </div>
        }
      <span className={style['call-message-text']}>
        {callMsg}
      </span>
    </div>
  </div>
);

const NoCallComponent = ({toggleReminderNote, setAudioStatus, lang, openModalExtension}) => {
  const { useZones } = useSelector((state) => ({
    useZones: state.globalConfiguration?.global?.use_alarm_zones
  }));

  return (
    <div className={style['call-controls-container']}>
      <div className={style['closed-call']}>
        <span className={style['call-info-text']}>
          <FormattedMessage id="attend.open.noAudioAlarm" defaultMessage="Sem alarme de áudio" />
        </span>
        <button
          id="create-reminder-button"
          className={style['btn-clock']}
          onClick={toggleReminderNote}
          onMouseOver={() => setAudioStatus(messages[lang]['attend.reminder.createReminder'])}
          onMouseOut={() => setAudioStatus('')}
        >
          <i className={`far fa-clock ${style.btnIcon}`} aria-hidden="true" />
        </button>
        {!useZones &&
          <button className={`${style['btn-external-call']} ${style['btn-wide']}`} onClick={openModalExtension}>
            <FormattedMessage id="attend.open.extensionCall" defaultMessage="CHAMADAS DE EXTENSÃO" />
          </button>
        }
      </div>
    </div>
  );
};

const IdleComponent = ({ callMsg }) => (
  <div className={style['call-controls-container']}>
    <div className={style['closed-call']}>
      <span className={style['call-message-text']}>
        {callMsg}
      </span>
    </div>
  </div>
);


const CallControls = (props) => {
  const { lang } = useSelector((state) => ({
    lang: state.locale.lang
  }));

  const computeState = (props) => {
    if (props.callState === -1 && props.clientMessages.length === 1) return 'alexaReplyState';
    if (props.callState === -1 && props.clientMessages.length === 2) return 'alexaClosedState';
    return props.callState;
  };

  const stateComponentMapping = {
    'alexaReplyState': AlexaStateComponent,
    'alexaClosedState': () => <CallClosedComponent {...props} lang={lang} />, // wrap the component in a function so we can pass the lang prop
    [callStates.NOCALL]: NoCallComponent,
    [callStates.CLOSED]: () => <CallClosedComponent {...props} lang={lang} />,
    [callStates.OPEN]: CallOpenComponent,
    [callStates.INPROGRESS]: CallInProgressComponent,
    [callStates.IDLE]: IdleComponent
  };

  const currentState = computeState(props);
  const ComponentToRender = stateComponentMapping[currentState];
  return <ComponentToRender {...props} lang={lang} />;
}

CallControls.propTypes = {
  display: PropTypes.bool.isRequired,
  canReturn: PropTypes.bool.isRequired,
  audioStatus: PropTypes.string.isRequired,
  callMsg: PropTypes.string.isRequired,
  toggleReminderNote: PropTypes.func.isRequired,
  setAudioStatus: PropTypes.func.isRequired,
  attendSip: PropTypes.func.isRequired,
  autoCall: PropTypes.func.isRequired,
  hangupSip: PropTypes.func.isRequired,
  muteAudio: PropTypes.func.isRequired,
  mutePhone: PropTypes.func.isRequired,
  openModalExtension: PropTypes.func.isRequired,
  openModalReturn: PropTypes.func.isRequired,
  equipment: PropTypes.shape({
    id: PropTypes.number,
    is_mobile: PropTypes.bool,
  }).isRequired,
  clientMessages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    message: PropTypes.string
  })).isRequired
}

export default CallControls;
