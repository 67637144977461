import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { getDomain } from "../../BLL/environment";

const AudioControl = (props) => {
  const audioRef = useRef(null);
  const timeoutRef = useRef(null);

  const hospitalDomains = ["tenant5", "campello", "hsl"];
  const domain = getDomain();
  const playHslAudio = hospitalDomains.includes(domain);

  const sources = [
    {
      id: "emergency",
      src: "https://s3-sa-east-1.amazonaws.com/contactto-frontend/sounds/alarm4.mp3",
      type: "audio/mp3",
    },
    {
      id: "emergency-hsl",
      src: "https://s3-sa-east-1.amazonaws.com/contactto-frontend/sounds/beep-09.wav",
      type: "audio/wav",
    },
  ];

  useEffect(() => {
    const toggleAudio = () => {
      if (audioRef.paused || audioRef.stopped) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
        clearTimeout(timeoutRef.current);
      }
    };

    if (audioRef) {
      switch (props.action) {
        case "start":
          if (!playHslAudio) {
            audioRef.current.play();
          } else {
            audioRef.current.play();
            audioRef.current.onended = () => {
              audioRef.current.currentTime = 0;
              console.log("Fim de áudio");
              timeoutRef.current = setTimeout(() => {
                audioRef.current.play();
                clearTimeout(timeoutRef.current);
              }, 1000);
            };
          }
          break;
        case "stop":
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
          clearTimeout(timeoutRef.current);
          break;
        case "pause":
          audioRef.current.pause();
          clearTimeout(timeoutRef.current);
          break;
        case "toggle":
          toggleAudio();
          break;
        default:
          break;
      }
    }
  }, [props.action, playHslAudio]);

  const audioSrc = sources.find((src) =>
    playHslAudio ? src.id === "emergency-hsl" : src.id === "emergency"
  );

  return (
    <audio
      ref={(a) => {
        audioRef.current = a;
      }}
      loop={!playHslAudio}
    >
      <source src={audioSrc.src} type={audioSrc.type} />
    </audio>
  );
};

AudioControl.defaultProps = {
  action: "",
};

AudioControl.propTypes = {
  action: PropTypes.string,
};

export default AudioControl;
