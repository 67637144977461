import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { GoogleMap, useJsApiLoader, Marker, Circle } from '@react-google-maps/api';
import { StandaloneSearchBox } from 'react-google-maps/lib/components/places/StandaloneSearchBox';
import { ev07bGeoFenceMode } from '../../../../BLL/dictionary';
import SelectInput from '../../../Common/SelectInput2';
import ToggleButton from '../../../Common/ToggleButton';
import messages from '../../../../BLL/locale/messages';
import config from '../../../../BLL/config';

import formStyle from '../../../../css/form.module.scss';
import style from './geofence.module.scss';
import '../../../../css/block.scss';

const MapComponent = ({geofenceList, openedGeofence, handleMapClick}) => {
  const geofence = geofenceList.find(geo => geo.index === openedGeofence);
  const geoPoint = geofence?.point_list[0];
  const modeColor = geofence?.direction === '0' ? '#a10c0c' : '#187e25b3'
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: config.googleMapsApiKey
  });
  const [map, setMap] = useState(null);
  const center = useMemo(() => {
    if (geoPoint) {
      return ({
        lat: parseFloat(geoPoint.lat),
        lng: parseFloat(geoPoint.lng),
      })
    }
  }, [geoPoint]);

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback((map) => {
    setMap(null);
  }, []);

  if (!isLoaded) {
    return <></>
  }

  return (
    <GoogleMap
      mapContainerClassName={style.leafletContainer}
      zoom={16}
      onLoad={onLoad}
      onUnmount={onUnmount}
      center={center}
      options={{
        disableDefaultUI: true,
        clickableIcons: false,
      }}
      onClick={(ev) => handleMapClick(ev, openedGeofence)}
    >
      {geofence?.enabled &&
        <div>
          <Marker position={{lat: geoPoint.lat, lng: geoPoint.lng }}/>
          <Circle
            center={{lat: geoPoint.lat, lng: geoPoint.lng }}
            radius={geofence?.radius}
            options={{
              strokeColor: modeColor,
              fillColor: modeColor,
              clickable: false
            }}
          />
        </div>
      }
    </GoogleMap>
  );
}

const GeofenceMenu = ({ geofence, setParameters, handleToggle, setPlacesLatLng, lang, saveGeofences }) => {
  const [searchbox, setSearchbox] = useState({});
  const [places, setPlaces] = useState({});

  const onSearchboxMounted = (ref) => {
    setSearchbox(ref);
  };

  const onPlacesChanged = () => {
    const newPlaces = searchbox.getPlaces();
    setPlaces(newPlaces);
    const points = {
      lat: newPlaces[0].geometry.location.lat(),
      lng: newPlaces[0].geometry.location.lng()
    }
    setPlacesLatLng(geofence.index, points);
  };

  const setSearchboxLatLng = (ev) => {
    if (ev.charCode === 13 || ev.keyCode === 13) {
      const points = {
        lat: places[0].geometry.location.lat(),
        lng: places[0].geometry.location.lng()
      }
      setPlacesLatLng(geofence.index, points);
    }
  };

  return (
    <div className="col-sm-12">
      <div className={`${style.geofenceSubmenuOption} col-sm-12`}>
        <div className="col-sm-12">
          <FormattedMessage id="client.tracker.enabled" defaultMessage="Habilitado" />:
          <ToggleButton
            text=""
            isChecked={geofence.enabled}
            hasPagination={false}
            handleChange={() => handleToggle(geofence.index)}
          />
        </div>

      </div>
      <div className={`${style.geofenceSubmenuOption} col-sm-12`}>
        <div className="col-sm-12"> <FormattedMessage id="client.tracker.geofenceMode" defaultMessage="Modo" />:</div>
        <div className={`${style['block-value']} col-sm-12`}>
          <SelectInput
            dictionary={ev07bGeoFenceMode}
            items={['0', '1']}
            selectClass={`${style.selectInput} ${
              formStyle['select--enable']
              } custom-select`}
            value={geofence.direction}
            onChange={setParameters}
            name={`direction.${geofence.index}`}
            emptyOption={false}
          />
        </div>
      </div>
      <div className={`${style.geofenceSubmenuOption} col-sm-12`}>
        <div className={style['block-value']} style={{ height: 'auto', width: '100%', float: 'left', padding: '10px 0 0' }} >
          <StandaloneSearchBox
            ref={onSearchboxMounted}
            onPlacesChanged={onPlacesChanged}
          >
            <input
              className={`${style.input} form-control`}
              type="text"
              placeholder={messages[lang]['client.tracker.searchAddress']}
              onKeyPress={setSearchboxLatLng}
            />
          </StandaloneSearchBox>
        </div>
      </div>
      <div className={`${style.geofenceSubmenuOption} col-sm-12`}>
       <FormattedMessage id="client.tracker.geofenceRadius" defaultMessage="Raio" />: {geofence.radius} <FormattedMessage id="client.tracker.meters" defaultMessage="Metros" />
       <div className={style['block-value']} style={{ height: 'auto', width: '100%', float: 'left', padding: '1rem' }} >
         <input
          style={{ float: 'left', width: '100%' }}
          name={`radius.${geofence.index}`}
          onChange={setParameters}
          value={geofence.radius}
          className={style.geofenceSlider}
          type="range"
          min="100"
          max="5000"
          step="10"
         />
       </div>
      </div>
      <div className={`${style.geofenceSubmenuOption} col-sm-12`}>
        <button
          className={style.geofenceSubMenuBtn}
          onClick={saveGeofences}
        >
          <span><i className="fas fa-crosshairs fa-lg" aria-hidden="true" style={{ paddingRight: '0.5rem', paddingTop: '0.2rem' }} /></span>
          <FormattedMessage id="client.tracker.saveFence" defaultMessage="Salvar Cerca" />
        </button>
      </div>
    </div>
  )

}

const TrackerEv07bGeofence = (props) => {
  const [openedGeofence, setOpenedGeofence] = useState(0);
  const [geofenceList, setGeoList] = useState([]);

  useEffect(() => {
    if (props.geoList.length > 0) {
      setGeoList(props.geoList);
    }
  }, [props.geoList]);

  if (geofenceList.length === 0) {
    return (
      <div>
        Carregando
      </div>
    )
  }

  const setParameters = (ev) => {
    const name = ev.target.name.split('.')[0];
    const index = parseInt(ev.target.name.split('.')[1], 10);
    let geofence = geofenceList.find(geo => geo.index === index);
    switch (name) {
      case 'direction':
        const value = ev.target.value;
        if (value === '-1') {
          geofence[name] = '0';
          geofence.enabled = false;
        } else {
          geofence[name] = value;
          geofence.enabled = true;
        }
        break;
      case 'radius':
        geofence.radius = parseInt(ev.target.value, 10);
        break;
      default:
        geofence[name] = ev.target.value;
        break;
    }
    const newGeoList = geofenceList.map(geo => {
      return geo.index === index ? geofence : geo
    });
    setGeoList(newGeoList);
  };

  const handleToggle = (index) => {
    let geofence = geofenceList.find(geo => geo.index === index);
    geofence.enabled = !geofence.enabled;
    const newGeoList = geofenceList.map(geo => {
      return geo.index === index ? geofence : geo
    });
    setGeoList(newGeoList);
  };

  const handleMapClick = (ev, index) => {
    let geofence = geofenceList.find(geo => geo.index === index);
    const lat = ev.latLng.lat();
    const lng = ev.latLng.lng();
    geofence.point_list = [{ lat, lng }];
    const newGeoList = geofenceList.map(geo => {
      return geo.index === index ? geofence : geo
    });
    setGeoList(newGeoList);
  };

  const setPlacesLatLng = (index, points) => {
    let geofence = geofenceList.find(geo => geo.index === index);
    geofence.point_list = [{ lat: points.lat, lng: points.lng }];
    const newGeoList = geofenceList.map(geo => {
      return geo.index === index ? geofence : geo
    });
    setGeoList(newGeoList);
  }

  const saveGeofences = () => {
    const data = {
      IMEI: props.imei,
      SET_GEOFENCE: true,
      GEO_ALERT: geofenceList
    };
    props.sendConfiguration(data);
  };

  console.log(geofenceList);

  return (
    <div>
      <div className={style.trackerMenu}>
        {geofenceList.map(geofence => 
          <div className={`${style.trackerMenuValue} col-sm-12 col-md-12 col-lg-3`} key={geofence.index}>
            <button className={style.geofenceMenuOption} onClick={() => setOpenedGeofence(geofence.index)}>
              <span className={style.trackerMenuText}>
                <FormattedMessage id="client.tracker.geofence" defaultMessage="Cerca Geográfica" /> {geofence.index + 1}
                <div className={`${style.geofenceTriangle} ${openedGeofence === geofence.index ? style.rotate : ''}`} />
              </span>
            </button>
            {openedGeofence === geofence.index &&
              <GeofenceMenu
                geofence={geofence}
                setParameters={setParameters}
                handleToggle={handleToggle}
                setPlacesLatLng={setPlacesLatLng}
                lang={props.lang}
                saveGeofences={saveGeofences}
              />
            }
          </div>
        )}
      </div>
      <MapComponent openedGeofence={openedGeofence} geofenceList={geofenceList} handleMapClick={handleMapClick} />

    </div>
  )
};

TrackerEv07bGeofence.propTypes = {
  sendConfiguration: PropTypes.func.isRequired,
  imei: PropTypes.string.isRequired,
  geoList: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number,
      type: PropTypes.string,
      radius: PropTypes.number,
      enabled: PropTypes.bool,
      points: PropTypes.number,
      direction: PropTypes.string,
      point_list: PropTypes.arrayOf(
        PropTypes.shape({
          lat: PropTypes.number,
          lng: PropTypes.number,
        }),
      ),
    }),
  ).isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    lang: state.locale.lang,
  };
};

export default connect(mapStateToProps)(TrackerEv07bGeofence);
