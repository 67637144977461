export default {
  en: {
    '001': 'SOS - mPERS',
    '003': 'Geo-fence 1 Entered',
    '004': 'Geo-fence 1 Exited',
    '005': 'Geo-fence 2 Entered',
    '006': 'Geo-fence 2 Exited',
    '007': 'Geo-fence 3 Entered',
    '008': 'Geo-fence 3 Exited',
    '009': 'Geo-fence 4 Entered',
    '010': 'Geo-fence 4 Exited',
    '019': 'Recharge the device',
    '042': 'Medication 1 Missed',
    '045': 'Medication 2 Missed',
    '048': 'Medication 3 Missed',
    '051': 'Medication 4 Missed',
    100: 'Medical Emergency',
    101: 'Pendant Transmitter',
    102: 'Fail to report in',
    111: 'Fire Alarm',
    120: 'Panic',
    158: 'High Temperature',
    159: 'Low Temperature',
    162: 'Carbon Monoxide Detected',
    200: 'Fire Supervisory Lost / CO',
    301: 'AC Loss',
    302: 'Low system battery',
    356: 'Reminder Timeout',
    371: 'Away Mode',
    372: 'Home Mode',
    381: 'Transmitter Supervision Lost',
    384: 'Transmitter Low Battery',
    406: 'Canceled Alarm',
    602: 'Periodic Test - Ok',
    606: 'Listen-in to follow',
    608: 'Periodic Test - Fail',
    645: 'Position Follow Up After Alarm',
    646: 'Location request',
    648: 'Ring request',
    671: 'Geo-fence',
    700: 'Movement Alert',
    701: 'Tilt Alert'
  },
  pt: {
    '001': 'SOS - mPERS',
    '003': 'Zona de segurança 1 Entrada',
    '004': 'Zona de segurança 1 Saída',
    '005': 'Zona de segurança 2 Entrada',
    '006': 'Zona de segurança 2 Saída',
    '007': 'Zona de segurança 3 Entrada',
    '008': 'Zona de segurança 3 Saída',
    '009': 'Zona de segurança 4 Entrada',
    '010': 'Zona de segurança 4 Saída',
    '019': 'Recarregar dispositivo',
    '042': 'Medicação 1 Perdida',
    '045': 'Medicação 2 Perdida',
    '048': 'Medicação 3 Perdida',
    '051': 'Medicação 4 Perdida',
    100: 'Emergência Console',
    101: 'Sensor de Emergência',
    102: 'Inatividade',
    111: 'Alarme de Incêndio',
    120: 'Pânico',
    158: 'Temperatura Elevada',
    159: 'Temperatura Baixa',
    162: 'Monóxido de Carbono',
    200: 'Perda Supervisão Incêndio / CO',
    301: 'Energia Elétrica',
    302: 'Bateria Console',
    356: 'Tempo de lembrete esgotado',
    371: 'Modo Ausente',
    372: 'Modo em casa',
    381: 'Perda supervisão sensor',
    384: 'Bateria fraca sensor',
    406: 'Alarme Cancelado',
    602: 'Teste Automático - Ok',
    606: 'Requisição Viva-Voz',
    608: 'Teste Automático - Falha',
    645: 'Posição pós-alarme',
    646: 'Requisição de localização',
    648: 'Requisição de apito',
    671: 'Geo-fence',
    700: 'Alerta de movimentação',
    701: 'Alerta de tilt'
  },
};
