import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { alarmPriority } from "../../../BLL/general";
import Paginate from "../../Common/Paginate";
import Loading from "../../../icons/Loading";
import AttendRow from "./AttendRow";
import Order from "../../../icons/Order";
import ModalFilter from "../../Modal/ModalAttendFilter";
import * as attendsActions from "../../../actions/attendsActions";
import messageTags from "../../../BLL/locale/messagesTags";

import style from "./attendsPage.module.scss";

const AttendsPage = (props) => {
  const numPerPage = 50;
  const [loading, setLoading] = useState(true);
  const [attendsList, setAttendsList] = useState([]);
  const [filter, setFilter] = useState("");
  const [ordering, setOrdering] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [totalAttends, setTotalAttends] = useState(0);
  const [next, setNext] = useState("");
  const [previous, setPrevious] = useState("");
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [iconDetail, setIconDetail] = useState("");

  useEffect(() => {
    if (!props.attends || Object.keys(props.attends).length === 0) {
      props.loadAttends();
    }
  }, []);

  useEffect(() => {
    setAttends(props.attends);
  }, [props.attends]);

  const setTextIconDetail = (iDetail) => {
    if (iDetail === "Teste") {
      setIconDetail(
        <FormattedMessage id="general.isTest" defaultMessage="Teste" />
      );
    } else {
      const icon = alarmPriority(iDetail);
      setIconDetail(icon);
    }
  };

  const setEmptyText = () => {
    setIconDetail("");
  };

  const setAttends = (attends) => {
    const activePage = attends.pages
      ? attends.pages.find((data) => data.page === attends.activePage)
      : undefined;
    setAttendsList(activePage ? activePage.results : []);
    setNext(activePage ? activePage.next : "");
    setPrevious(activePage ? activePage.previous : "");
    setOrdering(attends.ordering);
    setFilter(attends.filter);
    setTotalAttends(attends.count);
    setActivePage(attends.activePage);
    setLoading(false);
  };

  const changePage = (page) => {
    setLoading(true);
    const pageUrl = page > activePage ? next : previous;
    props.changePage(page, pageUrl);
  };

  const filterAttends = (filter) => {
    setShowFilterModal(false);
    setLoading(true);
    setActivePage(1);

    props.loadAttends(ordering, filter);
  };

  const removeFilter = () => {
    setLoading(true);
    props.loadAttends(ordering);
  };

  const orderById = () => {
    const newOrdering =
      ordering === "-status,id" ? "-status,-id" : "-status,id";
    setLoading(true);
    setOrdering(newOrdering);
    props.loadAttends(newOrdering, filter);
  };

  const orderByUser = () => {
    const newOrdering =
      ordering === "-status,client__first_name,client__last_name,id"
        ? "-status,-client__first_name,-client__last_name,-id"
        : "-status,client__first_name,client__last_name,id";
    setLoading(true);
    setOrdering(newOrdering);
    props.loadAttends(newOrdering, filter);
  };

  const orderByAttendant = () => {
    const newOrdering =
      ordering === "-status,attendant__first_name,attendant__last_name,id"
        ? "-status,-attendant__first_name,-attendant__last_name,-id"
        : "-status,attendant__first_name,attendant__last_name,id";
    setLoading(true);
    setOrdering(newOrdering);
    props.loadAttends(newOrdering, filter);
  };

  const orderByAlarm = () => {
    const newOrdering =
      ordering === "-status,events__event_type__name,id"
        ? "-status,-events__event_type__name,-id"
        : "-status,events__event_type__name,id";
    setLoading(true);
    setOrdering(newOrdering);
    props.loadAttends(newOrdering, filter);
  };

  const orderByStartDate = () => {
    const newOrdering =
      ordering === "-status,open_ts" ? "-status,-open_ts" : "-status,open_ts";
    setLoading(true);
    setOrdering(newOrdering);
    props.loadAttends(newOrdering, filter);
  };

  const orderByEndDate = () => {
    const newOrdering =
      ordering === "-status,close_ts"
        ? "-status,-close_ts"
        : "-status,close_ts";
    setLoading(true);
    setOrdering(newOrdering);
    props.loadAttends(newOrdering, filter);
  };

  const svgIdRotation = ordering === "-status,-id" ? style.rotate : "";
  const svgUserRotation =
    ordering === "-status,-client__first_name,-client__last_name,-id"
      ? style.rotate
      : "";
  const svgAttendantRotation =
    ordering === "-status,-attendant__first_name,-attendant__last_name,-id"
      ? style.rotate
      : "";
  const svgSuccessRotation =
    ordering === "-status,-events__event_type__name,-id" ? style.rotate : "";
  const svgStartRotation = ordering === "-status,-open_ts" ? style.rotate : "";
  const svgEndRotation = ordering === "-status,-close_ts" ? style.rotate : "";

  return (
    <div className="contactto-middle-content" style={{ overflow: "hidden" }}>
      <div className={style.title}>
        <FormattedMessage
          id="attend.list.mainHeader"
          defaultMessage="ATENDIMENTOS"
        />
      </div>
      {filter ? (
        <button className={style.filterBtn} onClick={removeFilter}>
          <i
            className="fas fa-filter"
            aria-hidden="true"
            style={{ transition: ".3s ease" }}
          />
          <span style={{ marginLeft: "3px", fontWeight: "bold" }}>
            <FormattedMessage
              id="client.page.undoFilt1"
              defaultMessage="DESFAZER"
            />
          </span>
        </button>
      ) : (
        <button
          className={style.filterBtn}
          onClick={() => setShowFilterModal(!showFilterModal)}
        >
          <i
            className="fas fa-filter"
            aria-hidden="true"
            style={{ transition: ".3s ease" }}
          />
          <span style={{ marginLeft: "3px", fontWeight: "bold" }}>
            <FormattedMessage
              id="attend.filter.button1"
              defaultMessage="FILTRAR"
            />
          </span>
        </button>
      )}
      {showFilterModal && (
        <ModalFilter
          filterAttends={filterAttends}
          closeModal={() => setShowFilterModal(!showFilterModal)}
        />
      )}
      {filter && (
        <div
          style={{
            float: "left",
            width: "600px",
            marginTop: "10px",
            marginLeft: "10px",
            color: "#b4b4b4",
          }}
        >
          <div style={{ float: "left" }}>
            <FormattedMessage
              id="attend.filter.filteredBy"
              defaultMessage="Filtrados por"
            />
            :
          </div>
          {filter.includes("id") && (
            <div style={{ float: "left", marginLeft: "10px" }}>
              <FormattedMessage
                id="attend.filter.id"
                defaultMessage="Identificação"
              />
            </div>
          )}
          {filter.includes("company") && (
            <div style={{ float: "left", marginLeft: "10px" }}>
              {
                props.companies.find(
                  (company) =>
                    company.id ===
                    parseInt(
                      filter
                        .split("&")
                        .find((filt) => filt.includes("company="))
                        .split("=")[1],
                      10
                    )
                ).name
              }
            </div>
          )}
          {filter.includes("has_audio") && (
            <div style={{ float: "left", marginLeft: "10px" }}>
              {" "}
              {filter.includes("has_audio=true") ? (
                <FormattedMessage
                  id="attend.filter.hasAudio"
                  defaultMessage="Tem áudio"
                />
              ) : (
                <FormattedMessage
                  id="attend.filter.noAudio"
                  defaultMessage="No áudio"
                />
              )}
            </div>
          )}
          {filter.includes("attend_tag") && (
            <div style={{ float: "left", marginLeft: "10px" }}>
              {
                messageTags[props.lang][
                  props.attendTags.find(
                    (tag) =>
                      tag.id ===
                      parseInt(
                        filter
                          .split("&")
                          .find((filt) => filt.includes("attend_tag="))
                          .split("=")[1],
                        10
                      )
                  ).id
                ]
              }
            </div>
          )}
          {filter.includes("is_test") && (
            <div style={{ float: "left", marginLeft: "10px" }}>
              {" "}
              {filter.includes("is_test=true") ? (
                <FormattedMessage
                  id="attend.filter.isTest"
                  defaultMessage="Usuário de Teste"
                />
              ) : (
                <FormattedMessage
                  id="attend.filter.notTest"
                  defaultMessage="Usuário Comum"
                />
              )}
            </div>
          )}
          {filter.includes("client") && (
            <div style={{ float: "left", marginLeft: "10px" }}>
              <FormattedMessage
                id="general.accountColumn"
                defaultMessage="Conta"
              />
            </div>
          )}
        </div>
      )}
      <div className={style.table}>
        <Paginate
          outerClass={style.paginate}
          numberOfElements={totalAttends}
          numPerPage={numPerPage}
          setPage={changePage}
          page={activePage}
        />
        <div className={style.header}>
          <div className={`${style.column} ${style.id}`}>
            <button
              className={style["header-column"]}
              onClick={orderById}
              style={{
                color:
                  ordering === "-status,id" || ordering === "-status,-id"
                    ? "#4196CD"
                    : "#333",
              }}
            >
              ID
              <Order width="15" height="10" className={svgIdRotation} />
            </button>
          </div>
          <div className={`${style.column} ${style.client}`}>
            <button
              className={style["header-column"]}
              onClick={orderByUser}
              style={{
                color:
                  ordering ===
                    "-status,-client__first_name,-client__last_name,-id" ||
                  ordering === "-status,client__first_name,client__last_name,id"
                    ? "#4196CD"
                    : "#333",
              }}
            >
              <FormattedMessage
                id="general.clientColumn"
                defaultMessage="Usuário"
              />
              <Order width="15" height="10" className={svgUserRotation} />
            </button>
          </div>
          <div className={`${style.column} ${style.attendant}`}>
            <button
              className={style["header-column"]}
              onClick={orderByAttendant}
              style={{
                color:
                  ordering ===
                    "-status,attendant__first_name,attendant__last_name,id" ||
                  ordering ===
                    "-status,-attendant__first_name,-attendant__last_name,-id"
                    ? "#4196CD"
                    : "#333",
              }}
            >
              <FormattedMessage
                id="general.attendant"
                defaultMessage="Atendente"
              />
              <Order width="15" height="10" className={svgAttendantRotation} />
            </button>
          </div>
          <div className={`${style.column} ${style.resolvedBy}`}>
            <button
              className={style["header-column"]}
              onClick={orderByAlarm}
              style={{
                color:
                  ordering === "-status,events__event_type__name,id" ||
                  ordering === "-status,-events__event_type__name,-id"
                    ? "#4196CD"
                    : "#333",
              }}
            >
              <FormattedMessage
                id="general.alarmType"
                defaultMessage="Tipo de Alarme"
              />
              <Order width="15" height="10" className={svgSuccessRotation} />
            </button>
          </div>
          <div className={`${style.column} ${style.dateOpen}`}>
            <button
              className={style["header-column"]}
              onClick={orderByStartDate}
              style={{
                color:
                  ordering === "-status,open_ts" ||
                  ordering === "-status,-open_ts"
                    ? "#4196CD"
                    : "#333",
              }}
            >
              <FormattedMessage
                id="general.opening"
                defaultMessage="Abertura"
              />
              <Order width="15" height="10" className={svgStartRotation} />
            </button>
          </div>
          <div className={`${style.column} ${style.dateClosed}`}>
            <button
              className={style["header-column"]}
              onClick={orderByEndDate}
              style={{
                color:
                  ordering === "-status,close_ts" ||
                  ordering === "-status,-close_ts"
                    ? "#4196CD"
                    : "#333",
              }}
            >
              <FormattedMessage
                id="general.closure"
                defaultMessage="Fechamento"
              />
              <Order width="15" height="10" className={svgEndRotation} />
            </button>
          </div>
          <div
            className={`${style.column} ${style.priority}`}
            style={{ lineHeight: "1.9" }}
          >
            {iconDetail}
          </div>
          <div className={`${style.column} ${style.headerBtn}`} />
        </div>
        {!loading ? (
          <div className={style["table-body"]}>
            {attendsList &&
              attendsList.map((attend) => (
                <AttendRow
                  key={attend.id}
                  showClient
                  channelId={props.channelId}
                  attend={attend}
                  isUser={false}
                  user={props.user}
                  setUserBusy={props.setUserBusy}
                  isBusy={props.isBusy}
                  setTextIconDetail={setTextIconDetail}
                  setEmptyText={setEmptyText}
                  lang={props.lang}
                  history={props.history}
                  useZoneColors={props.globalConfiguration?.use_alarm_zones}
                />
              ))}
          </div>
        ) : (
          <Loading
            text={
              <FormattedMessage
                id="attend.list.loading"
                defaultMessage="Carregando"
              />
            }
          />
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    attends: state.attends,
    params: ownProps.params,
    user: state.loggedAttendant,
    channelId: ownProps.channelId,
    attendTags: state.attendTags,
    companies: state.groups.filter((grp) => ![1, 2, 3, 4].includes(grp.id)),
    lang: state.locale.lang,
    setUserBusy: ownProps.setUserBusy,
    globalConfiguration: state.globalConfiguration.global,
    isBusy:
      state.status.length > 0
        ? state.status.some(
            (value) => value.userId === state.loggedAttendant.id
          )
        : false,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadAttends: (ordering, filter) =>
      dispatch(attendsActions.loadAttends(ordering, filter)),
    changePage: (page, pageUrl) =>
      dispatch(attendsActions.changePage(page, pageUrl)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AttendsPage);
