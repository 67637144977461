import React from 'react';
import PropTypes from 'prop-types';

const Order = ({ className, height, width, fill }) => (
  <svg className={className} width={width} height={height} fill={fill} viewBox="0 5 24 8" xmlns="http://www.w3.org/2000/svg">
  <path stroke={fill} d="M7.41,7.84L12,12.42l4.59-4.58L18,9.25l-6,6l-6-6L7.41,7.84z" />
    <path fill="none" d="M0-0.75h24v24H0V-0.75z" />
  </svg>
);

Order.defaultProps = {
  height: '15',
  className: '',
  fill: '#333',
  width: '15',
};

Order.propTypes = {
  height: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
};

export default Order;
