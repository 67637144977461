import * as types from '../actions/actionTypes';
import initialState from './initialState';

/*
  Inputs:
    state = Estado atual do objeto, caso não seja passado é utilizado o estado default do arquivo(initalstate.browser)
    action = Ação aplicada sobre o objeto

  Types:
    ADD_BROWSER_DIMENTIONS = Dimensões do browser

   export default:
    Retorno das dimensões da página
*/
export default (state = initialState.browser, action) => {
  if (action.type === types.ADD_BROWSER_DIMENTIONS) {
    return Object.assign({}, state, action.dimentions);
  }
  return state;
};
