import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { getDomain } from "../../BLL/environment";
import TecnoLogoNew from "../../icons/TecnoLogoNew";
import VidapointLogo from "../../icons/Vidapoint-logo";
import VidapointText from "../../icons/Vidapoint-text";
import style from "./sidebar.module.scss";

const Sidebar = (props) => {
  const loggedAttendant = useSelector((state) => state.loggedAttendant);
  const [expanded, toggleSidebar] = useState(false);

  const path = window.location.pathname.split("/");
  const hostname = getDomain();
  // const hostname = localStorage.domain;

  const managementGroups = [1, 2, 4];
  const isManagement = managementGroups.some(
    (grp) => loggedAttendant?.groups[0] === grp
  );
  const isFromCompany = loggedAttendant?.groups.length > 1;

  console.log(hostname);

  return (
    <nav
      className={style.sidebar}
      style={{ width: expanded ? "270px" : "50px" }}
    >
      <ul className={style.ul}>
        <li
          className={`${style.li} print-logo`}
          style={expanded ? { backgroundColor: "transparent" } : {}}
        >
          <div className={style.link}>
            {hostname === "vidapoint" || hostname === "ihelp" ? (
              <VidapointLogo width="50" height="40" svgClass={style.svg} />
            ) : (
              <TecnoLogoNew width="50" height="40" svgClass={style.svg} />
            )}
            {hostname === "vidapoint" || hostname === "ihelp" ? (
              <VidapointText
                width={235}
                height={170}
                svgClass={style["vidapoint-text"]}
              />
            ) : (
              <span style={{ lineHeight: "2.60", fontSize: "23px" }}>
                CONTACTTO.CARE
              </span>
            )}
          </div>
        </li>
        <li className={`${style.li} no-print`}>
          <button
            id="sidebar-arrow-button"
            className={style.link}
            style={{
              cursor: "pointer",
              backgroundColor: "transparent",
              border: "0",
              outline: "0",
            }}
            onClick={() => toggleSidebar(!expanded)}
          >
            <div className={style.arrow}>
              <i
                className={`fas fa-arrow-right fa-lg ${style.icon} ${
                  expanded ? "fa-rotate-180" : ""
                }`}
                aria-hidden="true"
              />
            </div>
          </button>
        </li>
        <li
          className={`${style.li} ${
            window.location.pathname === "/app" ? style.active : ""
          } no-print`}
        >
          <Link id="sidebar-dashboard-button" to="/app" className={style.link}>
            <span className={`${style.icons} fas fa-th`} />
            <span className={style.text}>
              <FormattedMessage
                id="sidebar.dashboard"
                defaultMessage="Painel"
              />
            </span>
          </Link>
        </li>
        <li
          className={`${style.li} ${
            path.indexOf("clients") === 2 ? style.active : ""
          } no-print`}
        >
          <Link
            id="sidebar-client-button"
            to="/app/clients"
            className={style.link}
          >
            <span className={`${style.icons} fas fa-user-friends`} />
            <span className={style.text}>
              <FormattedMessage id="sidebar.users" defaultMessage="Usuários" />
            </span>
          </Link>
        </li>
        <li
          className={`${style.li} ${
            path.indexOf("attends") === 2 ? style.active : ""
          } no-print`}
        >
          <Link
            id="sidebar-attend-button"
            to="/app/attends"
            className={style.link}
          >
            <div className={`${style.icons} fas fa-phone-volume`} />
            <span className={style.text}>
              <FormattedMessage
                id="sidebar.attends"
                defaultMessage="Atendimentos"
              />
            </span>
          </Link>
        </li>
        {isManagement && (
          <li
            className={`${style.li} ${
              path.indexOf("reports") === 2 ? style.active : ""
            } no-print`}
          >
            <Link
              id="sidebar-report-button"
              to="/app/reports"
              className={style.link}
            >
              <span className={`${style.icons} fas fa-clipboard`} />
              <span className={style.text}>
                <FormattedMessage
                  id="sidebar.reports"
                  defaultMessage="Relatórios"
                />
              </span>
            </Link>
          </li>
        )}
        {isManagement && ( // loggedAttendantGroup !== 3 &&
          <li
            className={`${style.li} ${
              path.indexOf("manage") === 2 && path.indexOf("quicksight") !== 3
                ? style.active
                : ""
            } no-print`}
          >
            <Link
              id="sidebar-management-button"
              to={
                isFromCompany ? "/app/manage/companies" : "/app/manage/attend"
              }
              className={style.link}
            >
              <span className={`${style.icons} fas fa-tools`} />
              <span className={style.text}>
                <FormattedMessage
                  id="sidebar.manage"
                  defaultMessage="Gerenciar"
                />
              </span>
            </Link>
          </li>
        )}
        <li
          className={`${style.li} ${
            path.indexOf("configuration") === 2 ? style.active : ""
          } no-print`}
        >
          <Link
            id="sidebar-configuration-button"
            to="/app/configuration"
            className={style.link}
          >
            <span className={`${style.icons} fas fa-cogs`} />
            <span className={style.text}>
              <FormattedMessage
                id="sidebar.config"
                defaultMessage="Configurar"
              />
            </span>
          </Link>
        </li>
        {(loggedAttendant?.user_info?.can_access_quicksight ||
          loggedAttendant?.groups[0] === 4) &&
          (hostname === "vidafone" || hostname === "tecnosenior") && (
            <li
              className={`${style.li} ${
                path.indexOf("quicksight") === 3 ? style.active : ""
              } no-print`}
            >
              <Link
                id="sidebar-configuration-button"
                to="/app/manage/quicksight"
                className={style.link}
              >
                <span className={`${style.icons} fas fa-chart-pie`} />
                <span className={style.text}>Quicksight</span>
              </Link>
            </li>
          )}
      </ul>
    </nav>
  );
};

export default Sidebar;
