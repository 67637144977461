import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Close from '../../icons/Close';
import '../../css/contactto.scss';

function ModalAlarm(props) {
  let clientName = '';
  const { pathname, emergencyList, isBusy, showAttendModal, busyUsers } = props;

  const userId = props.loggedAttendant ? props.loggedAttendant.id : '';

  const shouldHideContainer = !emergencyList[0] || pathname === '/app' || pathname.indexOf('/attends/call') !== -1
  || !showAttendModal || (busyUsers && busyUsers.some((user) => user.userId === userId));

  const emergency = emergencyList[0];

  const closeModal = () => {
    props.takeEmergency('stop-audio');
    props.toggleAttendModal();
  };

  const takeEmergency = () => {
    closeModal();

    const attend = Object.assign(
      {},
      props.emergencyList[0].attend,
      { status: 'IN_PROGRESS' },
      { attendant: props.userId }
    );

    props.editAttend(attend).then(() => {
      props.setUserBusy(true);
      props.history.push(`/app/attends/call/${attend.id}/`);
    })
  }

  if (emergency?.attend) {
    if (emergency.attend.is_from_secondary) {
      clientName = `${emergency.attend.secondary_first_name} ${emergency.attend.secondary_last_name} - ${emergency.attend.client_account}`
    } else {
      clientName = `${emergency.attend.client_first_name} ${emergency.attend.client_last_name} - ${emergency.attend.client_account}`
    }
  }

  return (
    <div className={shouldHideContainer ? 'alarm-modal-container contactto-hide' : 'alarm-modal-container'}>
      <div className="alarm-modal-content">
        <button className="alarm-modal-close-btn" onClick={closeModal} style={{ border: 0, backgroundColor: 'transparent' }}>
          <Close className="list-table-body-action-icons" width="28" height="28" />
        </button>
        <div className="alarm-modal-emergency"><FormattedMessage id="attend.modal.emergencyCall" defaultMessage="Chamada de Emergência" /></div>
        { !shouldHideContainer &&
          <div className="alarm-modal-client">
            { emergency.attend && clientName }
          </div>
        }
        <div className="alarm-modal-button-container contactto-flex-row">
          <button type="button" className="btn btn--multi btn--modal-attend btn--color-tecno" onClick={takeEmergency}> <FormattedMessage id="call.button.answer" defaultMessage="ATENDER" /> </button>
        </div>
      </div>
    </div>
  );

}

export default ModalAlarm;
