import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import style from './attendContacted.module.scss';
import '../../../../css/attendComponent.scss';

const AttendSolvedBy = ({
  contactedContacts,
  contactedAttendants,
}) => (
  <div className={style['contactted-wrapper']}>
    {contactedContacts.length > 0 &&
      <div className="col-sm-12 col-md-8 col-lg-8" style={{ float: 'left', marginBottom: '15px' }}>
        <span className="component-header" >
          <FormattedMessage id="attend.detail.emergencyContacts" defaultMessage="CONTATOS DE EMERGÊNCIA" />
        </span>
        {contactedContacts.sort((a, b) => (a.priority - b.priority)).map(contact => (
          <div className="component-row">
            <span className="component-label"><FormattedMessage id="general.fullName" defaultMessage="Nome" /> </span>
            <span className={`component-text ${style.text}`}>{contact.first_name} {contact.last_name}</span>
            <span className={`component-label ${style.label}`}><FormattedMessage id="contact.form.priority" defaultMessage="Prioridade" /></span>
            <span className="component-text">{contact.priority}</span>
          </div>
        ))}
      </div>
    }
    {contactedAttendants.length > 0 &&
      <div className="col-sm-12 col-md-8 col-lg-8">
        <span className="component-header" >
          <FormattedMessage id="attend.detail.attendants" defaultMessage="ATENDENTES" />
        </span>
        {contactedAttendants.map(attendant => (
          <div className="component-row">
            <span className="component-label"><FormattedMessage id="general.fullName" defaultMessage="Nome" /></span>
            <span className="component-text">{attendant.first_name} {attendant.last_name}</span>
          </div>
        ))}
      </div>
    }
    {contactedAttendants.length === 0 && contactedContacts.length === 0 &&
      <div className={style['no-contactted']}>
        <FormattedMessage id="attend.detail.noContacted" defaultMessage="Sem pessoas contatadas" />
      </div>
    }
  </div>
);

AttendSolvedBy.defaultProps = {
  attendAudio: '',
};

AttendSolvedBy.propTypes = {
  contactedAttendants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
  ).isRequired,
  contactedContacts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      priority: PropTypes.number,
    }),
  ).isRequired,
};

export default AttendSolvedBy;
