import * as types from '../actions/actionTypes';
import initialState from './initialState';

/*
  Inputs:
    state = Estado atual do objeto, caso não seja passado é utilizado o estado default do
    arquivo(initalstate.contacts)
    action = Ação aplicada sobre o objeto

  Types:
    LOAD_CONTACTS_SUCCESS = Carregamento de contatos realizado com sucesso

   clientsReducer:
    Retornado array de contatos ordenado ou não mediante o contato estar ativo ou não.
*/
export default function contactsReducer(state = initialState.contacts, action) {
  let newState = [];
  switch (action.type) {
    case types.LOAD_CONTACTS_SUCCESS:
      // Eu tenho que, retirar a lista antiga de contatos e adicionar a nova lista carregada
      newState = state.filter(dataList => dataList.clientId !== action.clientId);
      return [
        ...newState,
        {
          clientId: action.clientId,
          ordering: action.ordering,
          data: action.contacts,
        },
      ];
    case types.NEW_CONTACT:
      newState = state.map(dataList => {
        if (dataList && dataList.clientId === action.clientId) {
          return {
            ordering: dataList.ordering,
            clientId: dataList.clientId,
            data: [...dataList.data, action.contact]
          }
        }
        return dataList;
      });
      return newState;
    case types.EDIT_CONTACT:
      newState = state.map(dataList => {
        if (dataList.data && dataList.clientId === action.clientId) {
          return {
            ordering: dataList.ordering,
            clientId: dataList.clientId,
            data: dataList.data.map(
              contact => contact.id === action.contact.id ? action.contact : contact
            )
          };
        }
        return dataList;
      });
      return newState;
    case types.REMOVE_CONTACT:
      newState = state.map(dataList => {
        if (dataList && dataList.clientId === action.clientId) {
          const newContactList = dataList.data.filter((elm) => elm.id !== action.contact.id);
          return {
            ordering: dataList.ordering,
            clientId: dataList.clientId,
            data: newContactList
          }
        }
        return dataList;
      });
      return newState;
    default:
      return state;
  }
}
