import React from 'react';
import PropTypes from 'prop-types';

const AlarmLostMed2Ico = ({ height, svgClass, width, fill, transform }) => (
  <svg className={svgClass} width={width} height={height} fill={fill} transform={transform} viewBox="0 0 140 140" xmlns="http://www.w3.org/2000/svg">
    <g id="WTx1kz_1_">
      <g>
        <g>
          <path
            d="M2.6,82.6c0-8.9,0-17.7,0-26.6c0-13.4,10.3-24.7,23.9-26.2c13.1-1.4,25.6,7.8,28.3,20.8c0.4,1.8,0.6,3.7,0.6,5.6
c0,17.6,0,35.3,0,52.9c0,12.3-8.7,23-20.7,25.6c-15.6,3.4-30.7-7.5-32.1-23.5c-0.4-4.3-0.1-8.7-0.1-13
C2.6,93.1,2.6,87.8,2.6,82.6z M40.3,82.5c0-0.4,0.1-0.6,0.1-0.8c0-8.6,0.1-17.3-0.1-25.9c0-1.8-0.7-3.8-1.6-5.5
c-2.5-4.4-7.9-6.4-12.7-5c-4.9,1.4-8.1,5.7-8.1,10.9c0,8.3,0,16.7,0,25c0,0.4,0,0.8,0,1.3C25.3,82.5,32.7,82.5,40.3,82.5z"
          />
          <path
            d="M101.3,60.2c17.9,0.3,32.6,12.2,36.4,29.8c2.3,10.6-0.1,20.5-6.3,29.5c-0.9,1.3-2.1,1.4-3.4,0.1
c-4.2-4.2-8.4-8.4-12.6-12.5c-9.8-9.8-19.6-19.6-29.4-29.4c-2.3-2.3-4.7-4.7-7-7c-1.4-1.5-1.3-2.5,0.4-3.7
C85.4,62.6,93.6,60.1,101.3,60.2z"
          />
          <path
            d="M63.1,98.1c0.1-7.9,2.4-15.1,6.8-21.6c1.2-1.7,2.3-1.9,3.8-0.3c6.5,6.5,13,12.9,19.5,19.4c9.6,9.6,19.2,19.3,28.9,28.9
c2.2,2.2,2.1,2.9-0.5,4.7c-22.7,15.1-53.1,2-57.9-24.8C63.3,102.3,63.3,100.2,63.1,98.1z"
          />
        </g>
      </g>
    </g>
    <g>
      <path
        d="M117,15c-0.2-6.4,3.4-11.3,10.1-11.3c5.1,0,9.6,3.3,9.6,8.7c0,4.2-2.2,6.5-5,8.4c-2.7,2-6,3.5-8,6.4h13.2v4.9h-20.5
c0-6.4,4-9.2,8.8-12.5c2.5-1.7,5.8-3.4,5.9-6.8c0-2.6-1.8-4.3-4.2-4.3c-3.3,0-4.4,3.5-4.4,6.4H117z"
      />
    </g>
  </svg>
);

AlarmLostMed2Ico.defaultProps = {
  height: '75',
  svgClass: '',
  width: '75',
  fill: '#008D53',
  transform: 'translate(0,0)',
};

AlarmLostMed2Ico.propTypes = {
  height: PropTypes.string,
  svgClass: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
  transform: PropTypes.string,
};

export default AlarmLostMed2Ico;
