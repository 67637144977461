import React from 'react';
import PropTypes from 'prop-types';
import style from './modalZoneInfo.module.scss';

const ModalZoneInfo = (props) => (
  <div className={style.wrapper} style={{ height: props.height }}>
    <div className={style.actions}>
      <div className={style.title}>Aviso</div>
      <div>
        <div className={style.text}>Você está alterando a forma com que os alarmes se comportam no sistema.</div>
        <div className={style.text}>Utilizando esta opção, o som e a cor do alarme apresentado na tela, serão definidos pelas zonas de 1 a 4</div>
        <div className={style.text}>Zona 1 -> cor Azul, som secundário</div>
        <div className={style.text}>Zona 2 -> cor Verde, som secundário</div>
        <div className={style.text}>Zona 3 -> cor Amarela, som secundário</div>
        <div className={style.text}>Zona 4 -> cor Vermelha, som primário</div>
        <div className={style.text}>A partir da Zona 5, as cores começam a se repetir na ordem que estão apresentadas acima (azul, verde, amarela e vermelha).</div>
        <div className={style.text}>Estas cores apenas aparecerão para os alarmes de Sensor de Emergência (código 101). Os demais alarmes aparecerão com uma cor Cinza.</div>
      </div>
    </div>
    <button className={style.btn} onClick={props.btnAction} data-testid={props.testId}>
      {props.btnText}
    </button>
  </div>
);

ModalZoneInfo.defaultProps = {
  height: '',
  testId: ''
};

ModalZoneInfo.propTypes = {
  btnText: PropTypes.string.isRequired,
  btnAction: PropTypes.func.isRequired,
  height: PropTypes.string,
  testId: PropTypes.string,
};

export default ModalZoneInfo;
