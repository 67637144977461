import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
// import { Map, TileLayer, Marker, Circle } from 'react-leaflet';
import { GoogleMap, useJsApiLoader, Marker, Circle } from '@react-google-maps/api';
import { StandaloneSearchBox } from 'react-google-maps/lib/components/places/StandaloneSearchBox';
import { geoFenceMode } from '../../../../BLL/dictionary';
import SelectInput from '../../../Common/SelectInput2';
import messages from '../../../../BLL/locale/messages';
import config from '../../../../BLL/config';

import formStyle from '../../../../css/form.module.scss';
import style from './geofence.module.scss';
import '../../../../css/block.scss';

const MapComponent = (props) => {
  const [modeColor, setModeColor] = useState('#187e25b3');
  const [map, setMap] = useState(null);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: config.googleMapsApiKey
  });

  const center = useMemo(() => {
    if (props.latitude && props.longitude) {
      return ({
        lat: parseFloat(props.latitude),
        lng: parseFloat(props.longitude)
      });
    }
  }, [props.latitude, props.longitude])

  useEffect(() => {
    switch (props.mode) {
      case 1:
      case '1':
        setModeColor('#187e25b3');
        break;
      case 2:
      case '2':
        setModeColor('#a10c0c');
        break;
      case 3:
      case '3':
        setModeColor('#00b8cb');
        break;
      default:
        break;
    }
  }, [props.mode]);

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback((map) => {
    setMap(null);
  }, []);

  if (!isLoaded) {
    return <></>
  }

  return (
    <GoogleMap
      mapContainerClassName={style.leafletContainer}
      zoom={16}
      onLoad={onLoad}
      onUnmount={onUnmount}
      center={center}
      onClick={props.changeGeofencePosition}
      options={{
        disableDefaultUI: true,
        clickableIcons: false,
      }}
    >
      {props.mode !== 0 &&
        <div>
          <Marker position={center} />
          <Circle
            center={center}
            radius={props.radius}
            options={{
              strokeColor: modeColor,
              fillColor: modeColor,
              clickable: false
            }}
          />
        </div>
      }
    </GoogleMap>
  )
}


const TrackeriHelpGeofence = (props) => {
  const [params, setParams] = useState({});
  const [searchbox, setSearchbox] = useState({});
  const [places, setPlaces] = useState({});
  const [openedGeofence, setOpenedGeofence] = useState(1);
  const geofenceList = [...Array(3).keys()].map(x => x+1);

  useEffect(() => {
    setParams(props.params.param);
  }, [props.params]);

  const onSearchboxMounted = (ref) => {
    setSearchbox(ref);
  }

  const onPlacesChanged = () => {
    const newPlaces = searchbox.getPlaces();
    setParams(prevState => ({
      ...prevState,
      [`GEO${openedGeofence}LAT`]: newPlaces[0].geometry.location.lat(),
      [`GEO${openedGeofence}LON`]: newPlaces[0].geometry.location.lng(),
    }));
    setPlaces(newPlaces);
  };

  const setParameters = (ev) => {
    if (ev.target.name.includes('RADIUS')) {
      setParams(prevState => ({ ...prevState, [ev.target.name]: parseInt(ev.target.value, 10) }));
    } else {
      setParams(prevState => ({ ...prevState, [ev.target.name]: ev.target.value }));
    }
  };

  const setLatLon = (ev) => {
    if (ev.charCode === 13 || ev.keyCode === 13) {
      setParams(prevState => ({
        ...prevState,
        [`GEO${openedGeofence}LAT`]: places[0].geometry.location.lat(),
        [`GEO${openedGeofence}LON`]: places[0].geometry.location.lng(),
      }));
    }
  };

  const convertAndSendGeofence = () => {
    const command = [
      {
        code: `geo${openedGeofence}`,
        params: `${params[`GEO${openedGeofence}MODE`]},${params[`GEO${openedGeofence}RADIUS`]},${params[`GEO${openedGeofence}LAT`]},${params[`GEO${openedGeofence}LON`]}`,
      },
      {
        code: 'getparams',
        params: '',
      },
    ];
    props.sendParams(command);
  };

  const changeGeofencePosition = (ev) => {
    setParams(prevState => ({
      ...prevState,
      [`GEO${openedGeofence}LAT`]: ev.latLng.lat(),
      [`GEO${openedGeofence}LON`]: ev.latLng.lng(),
    }));
  };

  const renderGeofenceMenu = (openedGeofence) => {
    const programmingData = props.programmingData;

    return (
      <div className="col-sm-12">
        <div className={`${style.geofenceSubmenuOption} col-sm-12`}>
          {params[`GEO${openedGeofence}MODE`] !== 0
            ? <div> {programmingData && programmingData[`GEO${openedGeofence}ADDR`]} </div>
            : <div> - </div>
          }
          <div className="col-sm-12"> <FormattedMessage id="client.tracker.geofenceMode" defaultMessage="Modo" />:</div>
          <div className={`${style['block-value']} col-sm-12`}>
            <SelectInput
              dictionary={geoFenceMode}
              items={[0, 1, 2, 3]}
              selectClass={`${style.selectInput} ${
                formStyle['select--enable']
                } custom-select`}
              value={params[`GEO${openedGeofence}MODE`]}
              onChange={setParameters}
              name={`GEO${openedGeofence}MODE`}
              emptyOption={false}
            />
          </div>
        </div>
        <div className={`${style.geofenceSubmenuOption} col-sm-12`}>
          <div className={style['block-value']} style={{ height: 'auto', width: '100%', float: 'left', padding: '10px 0 0' }} >
            <StandaloneSearchBox
              ref={onSearchboxMounted}
              onPlacesChanged={onPlacesChanged}
            >
              <input
                className={`${style.input} form-control`}
                type="text"
                placeholder={messages[props.lang]['client.tracker.searchAddress']}
                onKeyPress={setLatLon}
              />
            </StandaloneSearchBox>
          </div>
        </div>
        <div className={`${style.geofenceSubmenuOption} col-sm-12`}>
          <FormattedMessage id="client.tracker.geofenceRadius" defaultMessage="Raio" />: {params[`GEO${openedGeofence}RADIUS`]} <FormattedMessage id="client.tracker.meters" defaultMessage="Metros" />
          <div className={style['block-value']} style={{ height: 'auto', width: '100%', float: 'left', padding: '1rem' }} >
            <input
              style={{ float: 'left', width: '100%' }}
              name={`GEO${openedGeofence}RADIUS`}
              value={params[`GEO${openedGeofence}RADIUS`]}
              onChange={setParameters}
              className={style.geofenceSlider}
              type="range"
              min="100"
              max="5000"
              step="10"
            />
          </div>
        </div>
        <div className={`${style.geofenceSubmenuOption} col-sm-12`}>
          <button
            className={style.geofenceSubMenuBtn}
            onClick={convertAndSendGeofence}
          >
            <span><i className="fas fa-crosshairs fa-lg" aria-hidden="true" style={{ paddingRight: '0.5rem', paddingTop: '0.2rem' }} /></span>
            <FormattedMessage id="client.tracker.saveFence" defaultMessage="Salvar Cerca" />
          </button>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className={style.trackerMenu}>
        <div className={style.trackerMenuContainer}>
        {geofenceList.map(geofence => 
          <div className={`${style.trackerMenuValue} col-sm-12 col-md-12 col-lg-4`}>
            <button className={style.geofenceMenuOption} onClick={() => setOpenedGeofence(geofence)}>
              <span className={style.trackerMenuText}>
                <FormattedMessage id="client.tracker.geofence" defaultMessage="Cerca Geográfica" /> {geofence}
                <div className={`${style.geofenceTriangle} ${openedGeofence === geofence ? style.rotate : ''}`} />
              </span>
            </button>
            {openedGeofence === geofence && renderGeofenceMenu(openedGeofence)}
          </div>
        )}
        </div>
      </div>
      <MapComponent
        changeGeofencePosition={changeGeofencePosition}
        latitude={params[`GEO${openedGeofence}LAT`]}
        longitude={params[`GEO${openedGeofence}LON`]}
        radius={params[`GEO${openedGeofence}RADIUS`]}
        mode={params[`GEO${openedGeofence}MODE`]}
      />
    </div>
  )
};

TrackeriHelpGeofence.propTypes = {
  sendParams: PropTypes.func.isRequired,
  programmingData: PropTypes.arrayOf(
    PropTypes.shape({
      GEO1ADDR: PropTypes.string,
      GEO2ADDR: PropTypes.string,
      GEO3ADDR: PropTypes.string,
    }),
  ).isRequired,
  params: PropTypes.shape({
    param: PropTypes.shape({
      GEO1MODE: PropTypes.number,
      GEO1LAT: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      GEO1LON: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      GEO1RADIUS: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      GEO2MODE: PropTypes.number,
      GEO2LAT: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      GEO2LON: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      GEO2RADIUS: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      GEO3MODE: PropTypes.number,
      GEO3LAT: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      GEO3LON: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      GEO3RADIUS: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }).isRequired,
  lang: PropTypes.string.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    lang: state.locale.lang,
  };
}

export default connect(mapStateToProps)(TrackeriHelpGeofence);
