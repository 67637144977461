import * as types from './actionTypes';
import { host, getDomain, getToken } from '../BLL/environment';

export function localConfig(local) {
  return {
    type: types.RELOAD_LOCAL_CONFIGURATIONS,
    local,
  };
}

export function loadLocalConfiguration(user) {
  const domain = getDomain();
  const token = getToken();

  return (dispatch, state, { axios }) => new Promise((resolve, reject) => {
    return axios({
      method: 'get',
      responseType: 'json',
      url: `https://${domain}${host()}/api/dashboard-config/${user}/`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      resolve(dispatch(localConfig(res.data)));
    })
    .catch((error) => {
      console.log('ERRO AO REALIZAR O REALIZAR LOCAL DAS CONFIGURAÇÕES');
      reject(error);
    });
  });
}
