import React from 'react';
import style from './errorBoundary.module.scss';

const ErrorBoundaryComponent = (props) => {
  const { resetErrorBoundary } = props;
  return (
    <div className={style.main}>
      <span>
        Ocorreu um erro ao carregar os dados do Contactto...
      </span>
      <span>
        Por favor, clique no botão abaixo para tentar recarregar a página.
      </span>
      <button className={style['error-btn']} onClick={resetErrorBoundary}> Recarregar a página </button>
      <span>
        Caso o botão não esteja funcionando, você pode também recarregar utilizando F5 do seu teclado ou clicando no botão de recarregar no topo do seu navegador.
      </span>
    </div>
  )
}

export default ErrorBoundaryComponent
