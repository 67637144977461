import * as types from './actionTypes';
import * as errorsActions from './errorsActions';
import { host, getDomain, getToken } from '../BLL/environment';

export function loadRemindersSuccess(reminders, serverTime) {
  return { type: types.LOAD_REMINDERS_SUCCESS, reminders, serverTime };
}

export function reloadRemindersSuccess(reminders, serverTime) {
  return { type: types.RELOAD_REMINDERS_SUCCESS, reminders, serverTime };
}

export function loadReminders(reload = false) {
  const domain = getDomain();
  const token = getToken();

  return (dispatch, getState, { axios }) => Promise.resolve(
    axios.get(`https://${domain}${host()}/api/reminders/?status=OPEN`, {
      headers: { Authorization: `Token ${token}` },
    }).then((reminders) => {
      if (reload) {
        dispatch(loadRemindersSuccess(reminders.data.data, reminders.data.server_time));
      } else {
        dispatch(reloadRemindersSuccess(reminders.data.data, reminders.data.server_time));
      }
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(errorsActions.logout());
        }
      }
    }),
  );
}

export function createReminder(reminder) {
  const domain = getDomain();
  const token = getToken();

  return (dispatch, getState, { axios }) => new Promise((resolve, reject) =>
    axios({
      method: 'post',
      responseType: 'json',
      url: `https://${domain}${host()}/api/reminders/`,
      data: reminder,
      headers: { Authorization: `Token ${token}` },
    })
    .then(() => resolve(dispatch(loadReminders())))
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          return reject(dispatch(errorsActions.logout()));
        }
        return reject(error.response);
      }
      return reject({});
    }),
  );
}

export function updateReminder(reminder) {
  const domain = getDomain();
  const token = getToken();

  return (dispatch, getState, { axios }) => new Promise((resolve, reject) =>
    axios({
      method: 'put',
      responseType: 'json',
      url: `https://${domain}${host()}/api/reminders/${reminder.id}/`,
      data: reminder,
      headers: { Authorization: `Token ${token}` },
    })
    .then(() => resolve(dispatch(loadReminders())))
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          return reject(dispatch(errorsActions.logout()));
        }
        return reject(error.response);
      }
      return reject({});
    }),
  );
}

