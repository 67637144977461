import React from 'react';
import PropTypes from 'prop-types';

import CallButton from '../../Common/CallButtonV2';
import AlarmIcon from '../../../icons/AlarmIcon';
import messagesAlarms from '../../../BLL/locale/messagesAlarms';
import { trDescriptionLang } from '../../../BLL/dictionary';

import { formatDate } from '../../../BLL/general';
import style from './attendsCard.module.scss';

const AttendsOpenCard = ({
  attend,
  attendant,
  user,
  setUserBusy,
  channelId,
  iconColor,
  lang,
  history,
  showIcons,
}) => (
  <div className={style.info}>
    <div style={{ float: 'left', width: '100%' }}>
      <div className={style.iconwrapper} data-testid={`open-icon-${attend.id}`}>
        {showIcons &&
          <AlarmIcon alarmCode={attend.highest_code} color={iconColor} height={'4rem'} width={'4rem'} float={'none'} />
        }
      </div>
      <div className={style.text} style={{ paddingLeft: '0.5rem', display: 'inline-block' }}>
        <div className={style.value} style={{ color: 'black', fontSize: '1rem', fontWeight: '500', marginBottom: '0.5rem' }}>{attendant}</div>
        <div className={style.value} style={{ color: 'black', fontSize: '1rem', fontWeight: '500', marginTop: '0.5rem', marginBottom: '0.5rem' }} >{formatDate(attend.open_ts, 'card')}</div>
      </div>
      <div data-testid={`open-footer-${attend.id}`} className={style['card-footer']} style={{ backgroundColor: iconColor }}>
        {(attend.highest_code === '384' || attend.highest_code === '381') && `${messagesAlarms[lang][attend.highest_code]} - `}
        {attend.tr_description ? `${trDescriptionLang(attend.tr_description)}` : `${messagesAlarms[lang][attend.highest_code]}`}
      </div>
      <CallButton
        height={'30px'}
        borderRadius={'0px'}
        attend={attend}
        channelId={channelId}
        containerClass="dashboard-table-container-tr dashboard-table-container-tr-second"
        user={user}
        setUserBusy={setUserBusy}
        history={history}
      />
    </div>
  </div>
);

AttendsOpenCard.defaultProps = {
  iconColor: '#D8342B',
};

AttendsOpenCard.propTypes = {
  attend: PropTypes.string.isRequired,
  attendant: PropTypes.string.isRequired,
  channelId: PropTypes.string.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.number),
    is_active: PropTypes.bool,
    is_staff: PropTypes.bool,
    is_superuser: PropTypes.bool,
    user_info: PropTypes.shape({
      id: PropTypes.number,
      user: PropTypes.number,
      phone: PropTypes.string,
      mobile: PropTypes.string,
      email: PropTypes.string,
      extension_id: PropTypes.number,
    }),
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        priority: PropTypes.number,
        user: PropTypes.number,
        receive_sms: PropTypes.bool,
        receive_email: PropTypes.bool,
      }),
    ),
  }).isRequired,
  setUserBusy: PropTypes.func.isRequired,
  preAttendSetBusy: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  iconColor: PropTypes.string,
};

export default AttendsOpenCard;
