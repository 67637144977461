import React from 'react';
import PropTypes from 'prop-types';

const CustomDatePickerInput = ({
  onClick,
  value,
}) => (
  <button className="custom-date-input" onClick={onClick}>
    {value}
  </button>
);

CustomDatePickerInput.defaultProps = {
  value: '',
  onClick: () => {},
};

CustomDatePickerInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string,
};

export default CustomDatePickerInput;
