import * as types from './actionTypes';
import * as errorsActions from './errorsActions';
import * as globals from '../globals/globals';
import { host, getDomain, getToken } from '../BLL/environment';

export function authLoginUserSuccess(token, user, status, isPaid, isActive, config, userConfig, rcToken) {
  const us = Object.assign({}, user);
  delete us.password;
  return {
    type: types.AUTH_LOGIN_USER_SUCCESS,
    token,
    user: us,
    status,
    isPaid,
    isActive,
    config,
    userConfig,
    rcToken
  };
}

export function authLoginUserFailure(error, message) {
  return {
    type: types.AUTH_LOGIN_USER_FAILURE,
    payload: {
      status: error,
      statusText: message,
    },
  };
}

export function authLoginUserRequest() {
  return {
    type: types.AUTH_LOGIN_USER_REQUEST,
  };
}

export function authResetStore() {
  return {
    type: types.AUTH_RESET_STORE,
  };
}

export function authLogout() {
  return {
    type: types.AUTH_LOGOUT_USER,
  };
}

export function authLogoutAndRedirect() {
  const domain = getDomain();
  const token = getToken();

  return (dispatch, state, { axios }) => new Promise((resolve, reject) => axios({
    method: 'post',
    responseType: 'json',
    url: `https://${domain}${host()}/api/logout/`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  })
    .then(res => resolve(dispatch(authLogout())))
    .catch(error => reject(error)));
}

export function authLoginUser(username, password, redirect = '/app') {
  const domain = getDomain();

  return (dispatch, state, { axios }) => new Promise((resolve, reject) => {
    dispatch(authLoginUserRequest());
    const auth = btoa(`${username}:${password}`);
    return axios({
      method: 'post',
      responseType: 'json',
      url: `https://${domain}${host()}/api/login/`,
      data: {
        email_or_username: username,
        password,
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Basic ${auth}`,
      },
    })
    .then(res => resolve(dispatch(authLoginUserSuccess(
      res.data.token, res.data.user, res.data.status, res.data.is_paid, res.data.is_active, res.data.configuration, res.data.user_config, res.data.rc_token))))
    .catch((error) => {
      if (error && typeof error.response !== 'undefined' && error.response.status === 401) {
        return reject(error.response);
      } else if (error && typeof error.response !== 'undefined' && error.response.status >= 500) {
        return reject(dispatch(authLoginUserFailure(500, 'A server error occurred while sending your data!')));
      }
      return reject(dispatch(authLoginUserFailure('Connection Error', 'An error occurred while sending your data!')));
    });
  });
}
