import React from 'react';
import PropTypes from 'prop-types';

const ArrowDown = ({ height, className, width }) => (
  <svg className={className} fill="#666" width={width} height={height} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
    <polygon points="51.85 22.85 32.15 42.56 12.44 22.85 13.85 21.44 32.15 39.73 50.44 21.44 51.85 22.85" />
  </svg>
);

ArrowDown.defaultProps = {
  height: '24',
  className: '',
  width: '24',
};

ArrowDown.propTypes = {
  height: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
};

export default ArrowDown;
