import React, { useState, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
import axios from 'axios';
import L from 'leaflet';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import br from 'date-fns/locale/pt-BR';
import us from 'date-fns/locale/en-US';
import { host, getDomain, getToken } from '../../../../BLL/environment';
import CustomModal from '../../../Modal/CustomModal';
import CustomCardHOC from '../../../Cards/CustomCardHOC';
import CustomDatePickerInput from '../../../Common/CustomDatePickerInput';
import Loading from '../../../../icons/Loading';
import Order from '../../../../icons/Order';
import messages from '../../../../BLL/locale/messages';
import MapPoint from './TrackerMapPoint';

import ProgrammingComponent from './TrackerEv07bProgram';
import GeofenceComponent from './TrackerEv07bGeofence';
import AlarmClockComponent from './TrackerEv07bAlarmClock';

import style from './tracker.module.scss';
import '../../../../css/middle.scss';
import './stylesheets/datepicker-cssmodules.scss';
import { useSelector } from 'react-redux';

registerLocale('pt-br', br);
registerLocale('en-us', us);

const TrackerEV07B = (props) => {
  const { equipmentList } = useSelector(state => ({
    equipmentList: state.equipment[0]?.data
  }));

  const [client, setClient] = useState(undefined);
  const [clientEquipment, setClientEquipment] = useState({});
  const [pointList, setPointList] = useState([]);
  const [programming, setProgramming] = useState({});
  const [generalDataList, setGeneralData] = useState([]);
  const [startDate, setStartDate] = useState(moment().locale('pt-br').subtract(1, 'hours').toDate());
  const [endDate, setEndDate] = useState(moment().locale('pt-br').toDate());
  const [showMap, setShowMap] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [showProgramming, setShowProgramming] = useState(false);
  const [showAlarmClock, setShowAlarmClock] = useState(false);
  const [showGeofence, setShowGeofence] = useState(false);
  const [disableProgramming, setDisableProgramming] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    fetchClient(parseInt(props.match.params.clientId, 10));
  }, [props.match.params.clientId]);

  useEffect(() => {
    if (client) {
      fetchLatestPoint();
      fetchLatestGeneralData();
      fetchParams();
    }
  }, [client]);

  useEffect(() => {
    if(client && equipmentList.length > 0) {
      // equipmentList.find(equipment => equipment.name.toLowerCase() === 'pers4200').id
      setClientEquipment(equipmentList.find(eq => eq.id === client.equipment_id));
    }
  }, [client, equipmentList]);

  const fetchClient = (clientId) => {
    axios.get(`https://${getDomain()}${host()}/api/clients/${clientId}/`, {
      headers: { Authorization: `Token ${getToken()}` },
    }).then(res => setClient(Object.assign({}, res.data)))
    .catch(error => console.log(error));
  };

  const fetchLatestPoint = () => {
    axios({
      url: `https://${getDomain()}${host()}/api/clients/${client.id}/tracker-iot/last/`,
      method: 'GET',
      responseType: 'json',
      headers: { Authorization: `Token ${getToken()}` },
    }).then((res) => {
      console.log(res);
      if (res?.data?.length > 0) {
        setPointList(res.data);
        setErrorMessage('');
      } else {
        const newErrorMessage = 'ESTE DISPOSITIVO NÃO TEM DADOS PARA O DIA DE HOJE, VERIFIQUE A BATERIA DO SEU SISTEMA';
        const errorComponent = (<FormattedMessage
          id="client.tracker.errorEmptyPoint"
          defaultMessage={newErrorMessage}
        />);
        setPointList([]);
        setErrorMessage(errorComponent);
      }
    }).catch((err) => {
      console.log('AQUIIIIEEE' ,err);
      let newErrorMessage = '';
      let errorComponent = {};
      if (err.response.data.imei) {
        newErrorMessage = 'ESTE USUÁRIO NÃO ESTÁ COM O IMEI CADASTRADO.\n';
        errorComponent = <FormattedMessage id="client.tracker.errorNoIMEI" defaultMessage={newErrorMessage} />;
      } else if (err.response.data.noData) {
        newErrorMessage = 'NENHUM PONTO ACHADO PARA ESTE DISPOSITIVO. \n VERIFIQUE SE ESTE RASTREADOR ESTÁ LIGADO';
        errorComponent = <FormattedMessage id="client.tracker.ihelpNoData" defaultMessage={newErrorMessage} />;
      } else {
        newErrorMessage = 'ERRO INESPERADO';
        errorComponent = <FormattedMessage id="client.tracker.errorUnexpected" defaultMessage={newErrorMessage} />;
      }
      setErrorMessage(errorComponent);
    });
  };

  const fetchPoints = () => {
    const startDateString = moment(startDate).format('YYYY-MM-DD');
    const endDateString = moment(endDate).format('YYYY-MM-DD');
    const params = `?start_date=${startDateString}&start_hour=${startDate.getHours()}&end_date=${endDateString}&end_hour=${endDate.getHours()}`;
    if (client) {
      axios({
        url: `https://${getDomain()}${host()}/api/clients/${client.id}/tracker-iot/${params}`,
        method: 'GET',
        responseType: 'json',
        headers: { Authorization: `Token ${getToken()}` },
      }).then((res) => {
        if (res.data.length > 0) {
          setPointList(res.data);
          setErrorMessage('');
        } else {
          const newErrorMessage = 'SEM DADOS PARA AS DATAS ENTRE {startDate} AS {startHour} HORAS E {endDate} AS {endHour} HORAS';
          const errorComponent = (<FormattedMessage
            id="client.tracker.errorEmptyPointsDates"
            defaultMessage={newErrorMessage}
            values={{
              startDate: startDateString,
              endDate: endDateString,
              startHour: startDate.getHours(),
              endHour: endDate.getHours(),
            }}
          />);
          setPointList([]);
          setErrorMessage(errorComponent);
        }
      }).catch(err => console.log(err));
    }
  };

  const fetchParams = () => {
    axios.get(`https://${getDomain()}${host()}/api/clients/${client.id}/tracker-iot/program/`, {
      headers: { Authorization: `Token ${getToken()}` },
    }).then(res => {
      if (res.data.length > 0) {
        setDisableProgramming(false);
        setProgramming(res.data[0]);
      }
    }).catch(err => {
      console.log(err);
    })
  };

  const fetchLatestGeneralData = () => {
    axios.get(`https://${getDomain()}${host()}/api/clients/${client.id}/tracker/general-data/last/`, {
      headers: { Authorization: `Token ${getToken()}` },
    }).then(res => setGeneralData(res.data))
    .catch(error => console.log(error));
  };

  const sendConfiguration = (program) => {
    if (client) {
      axios({
        url: `https://${getDomain()}${host()}/api/clients/${client.id}/tracker-iot/ev07b/program/`,
        method: 'POST',
        responseType: 'json',
        data: program,
        headers: { Authorization: `Token ${getToken()}` },
      }).then((res) => {
        console.log(res)
        if (res.status === 200) {
          setShowConfirmationModal(true);
        }
      }).catch(err => console.log(err));
    }
  };

  const setParameters = (event) => {
    const { name, value } = event.target;
    const option = name.split('.')[0];
    const optionProperty = name.split('.')[1];
    let programObject = programming[option];
    programObject[optionProperty] = value;

    setProgramming(prevState => ({
      ...prevState,
      [option]: Object.assign({}, programObject)
    }));
  };

  const sendSingleLocate = () => {
    const data = {
      IMEI: programming.IMEI,
      SEND_SINGLE_LOCATE: true,
    };
    sendConfiguration(data)
  };

  const sentContinuousLocate = () => {
    const data = {
      IMEI: programming.IMEI,
      SEND_CONTINUOUS_LOCATE: true,
    };
    sendConfiguration(data)
  }

  const canProgram = props.loggedAttendantGroup !== 3;
  const rotateProgram = showProgramming ? style.rotate : '';
  const rotateAlarmClock = showAlarmClock ? style.rotate : '';
  const rotateGeofence = showGeofence ? style.rotate : '';
  const rotateMap = showMap ? style.rotate : '';
  const rotateHistory = showHistory ? style.rotate : '';
  const fullName = client ? `${client.first_name} ${client.last_name}`.toUpperCase() : '';
  return (
    <>
      <div className={showConfirmationModal ? style.modal : style.hideModal}>
        <CustomModal
          text={<FormattedMessage id="client.tracker.configsSent" defaultMessage="Requisição enviada com sucesso!" />}
          btnText="OK"
          btnAction={() => setShowConfirmationModal(false)}
        />
      </div>
      <div className="contactto-middle-content">
        <div className={style.trackerTitle}>
          <span className="blue"><FormattedMessage id="client.tracker.trackerFrom" defaultMessage="RASTREADOR DE " /></span>
          <span className="grey">{fullName}</span>
        </div>
        <div className={style.trackerContent}>
          <div className={style.trackerMenu}>
            <div style={{ width: '100%', float: 'left' }}>
              <button className={style.trackerMenuOption} onClick={() => setShowMap(!showMap)}>
                <FormattedMessage id="client.tracker.mapHeader" defaultMessage="Mapa" />
                <Order width="25" height="15" className={`${style.trackerSvg} ${rotateMap}`}/>
              </button>
            </div>
            {showMap &&
              <MapPoint
                pointList={pointList}
                equipment={clientEquipment}
                generalDataList={generalDataList}
                lastGeneralData={generalDataList ? generalDataList[0] : undefined}
                lang={!localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang}
                fetchLatestPoint={fetchLatestPoint}
                sendSingleLocate={sendSingleLocate}
                sendContinuousLocate={sentContinuousLocate}
              />
            }
            <div style={{ width: '100%', float: 'left', marginTop: '25px' }}>
              <button className={style.trackerMenuOption} onClick={() => setShowHistory(!showHistory)}>
                <FormattedMessage id="client.tracker.history" defaultMessage="Histórico" />
                <Order width="25" height="15" className={`${style.trackerSvg} ${rotateHistory}`} />
              </button>
            </div>
            {showHistory &&
              <div className={style.trackerMenuContainer}>
                <div className={style.trackerMenuValue}>
                  <span className={style.trackerMenuText}><FormattedMessage id="client.tracker.begining" defaultMessage="Início: " /></span>
                  <div className={style.trackerMenuDate}>
                    <DatePicker
                      customInput={<CustomDatePickerInput />}
                      selected={startDate}
                      onChange={(ev) => setStartDate(ev)}
                      locale={props.lang === 'pt' ? 'pt-br' : 'en-us'}
                      showTimeSelect
                      timeFormat="HH"
                      timeIntervals={60}
                      dateFormat="dd/MM/yyyy - HH:00"
                      timeCaption={messages[props.lang]['client.tracker.hour']}
                      previousMonthButtonLabel=""
                      nextMonthButtonLabel=""
                    />
                  </div>
                </div>
                <div className={style.trackerMenuValue}>
                  <span className={style.trackerMenuText}><FormattedMessage id="client.tracker.end" defaultMessage="Fim: " /></span>
                  <div className={style.trackerMenuDate}>
                    <DatePicker
                      customInput={<CustomDatePickerInput />}
                      selected={endDate}
                      onChange={(ev) => setEndDate(ev)}
                      locale={props.lang === 'pt' ? 'pt-br' : 'en-us'}
                      showTimeSelect
                      timeFormat="HH"
                      timeIntervals={60}
                      dateFormat="dd/MM/yyyy - HH:00"
                      timeCaption={messages[props.lang]['client.tracker.hour']}
                      previousMonthButtonLabel=""
                      nextMonthButtonLabel=""
                    />
                  </div>
                </div>
                <button className={style.trackerMenuBtn} onClick={() => fetchPoints()}>
                  <span>
                    <i className="fas fa-search fa-lg" aria-hidden="true" style={{ paddingRight: '0.5rem', paddingTop: '0.2rem' }} />
                  </span>
                  <FormattedMessage id="client.tracker.search" defaultMessage="Procurar" />
                </button>
              </div>
            }
            {canProgram &&
              <div style={{ width: '100%', float: 'left', marginTop: '25px' }}>
                <button
                  className={`${style.trackerMenuOption} ${disableProgramming ? style.disabled : ''}`}
                  onClick={() => setShowProgramming(!showProgramming)}
                  disabled={disableProgramming}
                >
                  <FormattedMessage id="client.tracker.programming" defaultMessage="Programação" />
                  <Order width="25" height="15" className={`${style.trackerSvg} ${rotateProgram}`} />
                </button>
              </div>
            }
            {showProgramming &&
              <ProgrammingComponent programming={programming}  sendConfiguration={sendConfiguration} setParameters={setParameters}/>
            }
            {canProgram &&
              <div style={{ width: '100%', float: 'left', marginTop: '25px' }}>
                <button
                  className={`${style.trackerMenuOption} ${disableProgramming ? style.disabled : ''}`}
                  onClick={() => setShowAlarmClock(!showAlarmClock)}
                  disabled={disableProgramming}
                >
                  <FormattedMessage id="client.tracker.alarmClock" defaultMessage="Programar Lembretes" />
                  <Order width="25" height="15" className={`${style.trackerSvg} ${rotateAlarmClock}`} />
                </button>
              </div>
            }
            {showAlarmClock &&
              <AlarmClockComponent alarmClockList={programming.ALARM_CLOCK}  sendConfiguration={sendConfiguration} imei={programming.IMEI} />
            }
            {canProgram &&
              <div style={{ width: '100%', float: 'left', marginTop: '25px' }}>
                <button
                  className={`${style.trackerMenuOption} ${disableProgramming ? style.disabled : ''}`}
                  onClick={() => setShowGeofence(!showGeofence)}
                  disabled={disableProgramming}
                >
                  <FormattedMessage id="client.tracker.geofences" defaultMessage="Cercas Geográficas" />
                  <Order width="25" height="15" className={`${style.trackerSvg} ${rotateGeofence}`} />
                </button>
              </div>
            }
            {showGeofence &&
              <GeofenceComponent geoList={programming.GEO_ALERT} sendConfiguration={sendConfiguration} imei={programming.IMEI}/>

            }
          </div>
        </div>
      </div>
    </>
  )
};

TrackerEV07B.propTypes = {
  loggedAttendantGroup: PropTypes.number.isRequired,
  lang: PropTypes.string.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    lang: state.locale.lang,
    loggedAttendantGroup: state.loggedAttendant.groups[0],
  };
}

export default connect(mapStateToProps)(TrackerEV07B);
