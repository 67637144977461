import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function statusReducer(state = initialState.status, action) {
  switch (action.type) {
    case types.LOAD_BUSY_USERS_SUCCESS: {
      if (action.response.length === 0) {
        return state;
      }
      const newState = action.response.map(attend => {
        return {
          userId: attend.attendant,
          attendId: attend.id
        };
      });
      return newState;
    }
    case types.ADD_BUSY_USER: {
      const newState = state.some(value => value.userId === action.userId)
        ? state
        : [...state, {
        userId: action.userId,
        attendId: action.attendId
      }];
      return newState;
    }
    case types.REMOVE_BUSY_USER: {
      const newState = state.filter(value => value.userId !== action.userId);
      return newState;
    }
    default:
      return state;
  }
}
// /*
// Inputs:
//   state = Estado atual do objeto, caso não seja passado, é utilizado o estado padrão do arquivo
//   (initialState.search) que é um objeto
//   action = Ação aplicada sobre o objeto
// Types:
//   SET_ATTTENDANT_BUSY_STATUS = Define o atendimento que está em progresso

// searchReducer = Define o atendimento que está em progresso (Não parece estar sendo utilizado)
// Deprecated(?)
//  */
// export default function statusReducer(state = initialState.status, action) {
//   switch (action.type) {
//     case types.SET_ATTTENDANT_BUSY_STATUS: {
//       const attend = action.attends.find(a => a.attendant === action.id && a.status === 'IN_PROGRESS');
//       const isBusy = !!attend;
//       const attendId = isBusy ? attend.id : 0;
//       return { isBusy, attendId };
//     }
//     case types.PRE_ATTEND_SET_STATUS: {
//       return { isBusy: action.status, attendId: action.attend };
//     }
//     default:
//       return state;
//   }
// }
