import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ToggleButton from '../../../Common/ToggleButton';
import CompanyUserRow from './CompanyUserRow';

import style from './companiesManagement.module.scss';
import '../../../../css/contactto.scss';

const CompanyRow = (props) => {
  const [showInactives, setShowInactives] = useState(false);

  return (
    <div className={style.companyContainer}>
        <div className={style.companyTitleContainer}>
          {props.isCompanyAdm &&
            <div className={style.companyTitle} > {props.company.name} </div>
          }
        </div>
        <div>
          <button
            type="button"
            className={style.personBtn}
            onClick={() => props.history.push(`/app/companies/${props.company.id}/user/form/add`)}
          >
            <>
              <span style={{ fontWeight: 'bold' }}>+ </span> <FormattedMessage id="companies.addPerson" defaultMessage="PESSOA" />
            </>
          </button>
        </div>
        <div>
          <button
            type="button"
            className={style.editBtn}
            onClick={() => props.history.push(`/app/companies/form/edit/${props.company.id}`)}
          >
            <>
              <i
                className="far fa-edit"
                style={{ color: '#fff', lineHeight: '.9', marginRight: '10px' }}
                aria-hidden="true"
              />
              <FormattedMessage id="companies.edit" defaultMessage="EDITAR" />
            </>
          </button>
        </div>
        {props.loggedAttendantGroup === 4 && 
          <div>
            <button
              type="button"
              className={style.removeBtn}
              onClick={props.openDeleteModal}
            >
              <>
                <i
                  className="fas fa-eraser"
                  style={{ color: '#fff', lineHeight: '.9', marginRight: '10px' }}
                  aria-hidden="true"
                />
                <FormattedMessage id="companies.remove" defaultMessage="REMOVER EMPRESA" />
              </>
            </button>
          </div>
        }
      {(props.loggedAttendantGroup === 1 || props.loggedAttendantGroup === 4) &&
        <ToggleButton
          isChecked={showInactives}
          handleChange={() => setShowInactives(!showInactives)}
        />
      }
      <div className={style.table}>
        <div className={style.header}>
          <div className={style.username}>
            <FormattedMessage id="attendant.userName" defaultMessage="Nome do Atendente" />
          </div>
          <div className={style.name}>
            <FormattedMessage id="general.fullName" defaultMessage="Nome" />
          </div>
          <div className={style.email}> Email </div>
          <div className={style.columnIcons}></div>
        </div>

        <div className={style.body}>
          {props.company?.user_set
            .sort((userA, userB) => userB.is_active - userA.is_active)
            .filter(user => {
              if (!showInactives) {
                return user.is_active=== true;
              }
              return user;
            })
            .map(user => (
              <CompanyUserRow
                key={user.id}
                attendant={user}
                companyId={props.company.id}
                isAdmin
                isOwner
                setModalInfo={props.setModalInfo}
                showModal={false}
              />
            ))
          }
        </div>
      </div>
    </div>
  );
};

CompanyRow.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    user_set: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        is_active: PropTypes.bool,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        email: PropTypes.string,
        groups: PropTypes.arrayOf(
          PropTypes.number,
        ),
      }),
    ),
  }).isRequired,
  loggedAttendantGroup: PropTypes.number.isRequired,
  iconDetail: PropTypes.shape({
    id: PropTypes.number,
    text: PropTypes.string,
  }).isRequired,
  setTextIconDetail: PropTypes.func.isRequired,
  setModalInfo: PropTypes.func.isRequired,
};

export default CompanyRow;
