import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import style from './history.module.scss';
import { historyActionType } from '../../../../BLL/dictionary';


export default function ContactChangedRow(props) {
  const [expanded, expandInfo] = useState(false);
  const rowContact = props.log.contact;
  const nextContact = props.index === props.array.length -1 ? {} : props.array[props.index + 1].contact;
  const phones = props.log.phone.length > 0 ? props.log.phone : []
  const nextPhoneList = props.index === props.array.length -1 ? [] : props.array[props.index + 1].phone;
  const rowActions = props.log.actions.length > 0 ? props.log.actions : [];
  const nextActions = props.index === props.array.length -1 ? [] : props.array[props.index + 1].actions;
  const actions = rowActions.map((action) => {
    const nextAction = nextActions.find((item) => item.priority === action.priority);
    if (!nextAction) {
      return {
        priority: action.priority,
        receiveSms: action.receiveSms,
        receiveEmail: action.receiveEmail,
        receiveTelegram: action.receiveTelegram
      }
    }
    return {
      priority: action.priority,
      receiveSms: action.receiveSms === nextAction.receiveSms ? undefined : action.receiveSms,
      receiveEmail: action.receiveEmail === nextAction.receiveEmail ? undefined : action.receiveEmail,
      receiveTelegram: action.receiveTelegram === nextAction.receiveTelegram ? undefined : action.receiveTelegram
    }
  });
  const contact = Object.assign({}, rowContact, {
    firstName: nextContact.firstName === rowContact.firstName ? '' : rowContact.firstName,
    lastName: nextContact.lastName === rowContact.lastName ? '' : rowContact.lastName,
    relationship: nextContact.relationship === rowContact.relationship ? '' : rowContact.relationship,
    priority: nextContact.priority === rowContact.priority ? '' : rowContact.priority,
    email: nextContact.email === rowContact.email ? '' : rowContact.email,
    hasKeys: nextContact.hasKeys === rowContact.hasKeys ? undefined : rowContact.hasKeys,
    isContractor: nextContact.isContractor === rowContact.isContractor ? undefined : rowContact.isContractor,
    zipCode: nextContact.zipCode === rowContact.zipCode ? '' : rowContact.zipCode,
    street: nextContact.street === rowContact.street ? '' : rowContact.street,
    number: nextContact.number === rowContact.number ? undefined : rowContact.number,
    complement: nextContact.complement === rowContact.complement ? '' : rowContact.complement,
    district: nextContact.district === rowContact.district ? '' : rowContact.district,
    city: nextContact.city === rowContact.city ? '' : rowContact.city,
    state: nextContact.state === rowContact.state ? '' : rowContact.state,
  });

  const rowPhoneHasEqual = phones.every((pho1) =>
    nextPhoneList.some((pho2) =>
      pho1['phone'] === pho2['phone'] && pho1['phoneType'] === pho2['phoneType']
    )
  );

  const rowActionsHasEqual = rowActions.every((act1) => 
    nextActions.some((act2) =>
      act1['priority'] === act2['priority'] &&
      act1['receiveEmail'] === act2['receiveEmail'] && act1['receiveSms'] === act2['receiveSms']
    )
  );

  const rowContactHasEqual = JSON.stringify(rowContact) === JSON.stringify(nextContact);

  if (rowContactHasEqual && rowActionsHasEqual && rowPhoneHasEqual) {
    return (<></>);
  }

  let actionType = '';
  if (contact.isDeleted && !nextContact.isDeleted) {
    actionType = 'Inativação'
  } else if (!contact.isDeleted && nextContact.isDeleted) {
    actionType = 'Reativação'
  } else {
    actionType = historyActionType(props.log.actionType)
  }
  return (
    <div className={style.tableRowWrapper} style={{ height: `${expanded ? 'auto' : '3rem'}`, overflow: 'hidden' }}>
      <div className={style.tableRow}>
        <div className={style.tableBodyColumn}>
          {actionType}
        </div>
        <div className={style.bodyDataColumn}>{moment(props.log.actionDate).format('DD/MM/YYYY HH:mm:ss')}</div>
        <div className={style.bodyChangedByColumn}>
          {props.log.actionUser ? props.log.actionUser.firstName : ''} {props.log.actionUser ? props.log.actionUser.lastName : 'Contactto Vendas'}
        </div>
        <div className={style.bodyToggleButton}>
          <button className={style.toggleButton} onClick={() => expandInfo(!expanded)}>
            { !expanded
              ? <FormattedMessage id="history.expand" defaultMessage="Expandir" />
              : <FormattedMessage id="history.hide" defaultMessage="Ocultar" />
            }
          </button>
        </div>
      </div>
      <div className={style.rowExtraInfoWrapper}>
        {contact.firstName &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>Nome:</span>
            <span className={style.infoText}>{contact.firstName}</span>
          </div>
        }
        {contact.lastName &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>Sobrenome:</span>
            <span className={style.infoText}>{contact.lastName}</span>
          </div>
        }
        {contact.relationship &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>Relação:</span>
            <span className={style.infoText}>{contact.relationship}</span>
          </div>
        }
        {contact.priority &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>Prioridade:</span>
            <span className={style.infoText}>{contact.priority}</span>
          </div>
        }
        {contact.email &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>Email:</span>
            <span className={style.infoText}>{contact.email}</span>
          </div>
        }
        {contact.hasKeys !== undefined &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>Possui Chaves:</span>
            <span className={style.infoText}>
              {contact.hasKeys
                ? <FormattedMessage id="general.yes" defaultMessage="Sim" />
                : <FormattedMessage id="general.no" defaultMessage="Não" />
              }
            </span>
          </div>
        }
        {contact.isContractor !== undefined &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>Contratante:</span>
            <span className={style.infoText}>
              {contact.isContractor
                ? <FormattedMessage id="general.yes" defaultMessage="Sim" />
                : <FormattedMessage id="general.no" defaultMessage="Não" />
              }
            </span>
          </div>
        }
        {contact.street &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>Logradouro:</span>
            <span className={style.infoText}>{contact.street}</span>
          </div>
        }
        {contact.number && contact.number !== 0 &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>Número:</span>
            <span className={style.infoText}>{contact.number}</span>
          </div>
        }
        {contact.complement &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>Complemento:</span>
            <span className={style.infoText}>{contact.complement}</span>
          </div>
        }
        {contact.district &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>Bairro:</span>
            <span className={style.infoText}>{contact.district}</span>
          </div>
        }
        {contact.zipCode &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>CEP:</span>
            <span className={style.infoText}>{contact.zipCode}</span>
          </div>
        }
        {contact.city &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>Cidade:</span>
            <span className={style.infoText}>{contact.city}</span>
          </div>
        }
        {contact.state &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>Estado:</span>
            <span className={style.infoText}>{contact.state}</span>
          </div>
        }
        <div style={{ float: 'left', width: '100%' }}/>
        {phones.map((item) => (
          <div className={item.actionType === 'deleted' && style.deletedText}>
            {item.actionType !== 'changed' &&
              <div>
                <div className={style.extraInfoTextWrapper}>
                  <span className={`${style.infoLabel} ${item.actionType === 'deleted' && style.deletedText}`}>Número:</span>
                  <span className={style.infoText}>{item.phone}</span>
                </div>
                <div className={style.extraInfoTextWrapper}>
                  <span className={`${style.infoLabel} ${item.actionType === 'deleted' && style.deletedText}`}>Tipo:</span>
                  <span className={style.infoText}>{item.phoneType}</span>
                </div>
              </div>
            }
          </div>
        ))}
        <div style={{ float: 'left', width: '100%' }}/>
        {actions
        .filter((item) => item.receiveEmail !== undefined || item.receiveSms !== undefined)
        .map((item) => (
          <div style={{ float: 'left', width: '100%' }}>
            <div style={{ float: 'left', width: '33%'}}>
              <span className={style.infoLabel}>Prioridade:</span>
              <span className={style.infoText}>{item.priority}</span>
            </div>
            {item.receiveEmail !== undefined ?
              <div style={{ float: 'left', width: '33%'}}>
                <span className={style.infoLabel}>Recebe Email:</span>
                <span className={style.infoText}>
                  {item.receiveEmail
                    ? <FormattedMessage id="general.yes" defaultMessage="Sim" />
                    : <FormattedMessage id="general.no" defaultMessage="Não" />
                  }
                </span>
              </div>
              :
              <div style={{ float: 'left', width: '33%'}}>-</div>
            }
            {item.receiveSms !== undefined ?
              <div style={{ float: 'left', width: '33%'}}>
                <span className={style.infoLabel}>Recebe Sms:</span>
                <span className={style.infoText}>
                  {item.receiveSms
                    ? <FormattedMessage id="general.yes" defaultMessage="Sim" />
                    : <FormattedMessage id="general.no" defaultMessage="Não" />
                  }
                </span>
              </div>
              :
              <div style={{ float: 'left', width: '33%'}}>-</div>
            }
          </div>
        ))}
      </div>
    </div>
  )
};
