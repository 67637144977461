import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import componentStyle from './formButton.module.scss';

const FormButton = ({ cancelAction, submitAction, activeSubmit, style, nameBtn, btnStyle }) =>

  <div className="col-12 row">
    <button
      id="form-button-first"
      style={btnStyle}
      type="button"
      className={`${componentStyle.btn} ${componentStyle.active} btn--color-red col-6`}
      onClick={cancelAction}
    >
      <i className="far fa-window-close" style={{ paddingRight: '1rem', paddingTop: '0.2rem' }} /> {nameBtn.first}
    </button>
    <button
      id="form-button-second"
      style={btnStyle}
      type="button"
      disabled={!activeSubmit}
      className={`${componentStyle.btn} ${activeSubmit
        ? componentStyle.active
        : componentStyle.disable} btn--color-green col-6`}
      onClick={submitAction}
    >
      <i className="far fa-save fa-lg" style={{ paddingRight: '1rem', paddingTop: '0.2rem' }} /> {nameBtn.second}
    </button>
  </div>;

FormButton.defaultProps = {
  style: {},
  btnStyle: {},
  nameBtn: {
    first: <FormattedMessage id="form.button.cancel" defaultMessage="Cancelar" />,
    second: <FormattedMessage id="form.button.save" defaultMessage="Salvar" />,
  },
};

FormButton.propTypes = {
  cancelAction: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired,
  activeSubmit: PropTypes.bool.isRequired,
  style: PropTypes.shape({
    color: PropTypes.string,
  }),
  btnStyle: PropTypes.shape({
    color: PropTypes.string,
  }),
  nameBtn: PropTypes.shape({
    first: PropTypes.string,
    second: PropTypes.string,
  }),
};

export default FormButton;
