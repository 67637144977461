import React, { Component } from 'react';
import PropTypes from 'prop-types';
import style from './tooltipContainer.module.scss';

const TooltipContainer = ({ tooltipMessage, bottom, transform }) => (
  <div className={style.icon}>
    <i className="fas fa-info-circle" style={{ lineHeight: '.9' }} aria-hidden="true" />
    <span className={style.iconText} style={{ bottom, transform: `translateX(${transform}%)` }}>
      {tooltipMessage}
    </span>
  </div>
);

TooltipContainer.defaultProps = {
  bottom: '45px',
  transform: '-100',
};

TooltipContainer.propTypes = {
  bottom: PropTypes.string,
  transform: PropTypes.string,
  tooltipMessage: PropTypes.shape({
    key: PropTypes.number,
  }).isRequired,
};

export default TooltipContainer;
