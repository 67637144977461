import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { companyReg } from '../../../../BLL/validate';
import TextInput from '../../../Common/TextInputV2';
import InputContainer from '../../../Common/InputContainer';
import ErrorComponent from '../../../Common/ErrorComponent';
import * as groupsActions from '../../../../actions/groupsActions';

import '../../../../css/error.scss';
import '../../../../css/formPage.scss';
import '../../../../css/block.scss';
import style from './companyForm.module.scss';

const NUMBERS_REGEX = /^[0-9]+$/;

const CompanyForm = (props) => {
  const {
    companies,
    loadCompanies,
    match,
    loggedAttendant
  } = props;

  const isCompanyAdm = companies.some(company => loggedAttendant.groups.includes(company.id));

  const [companyId, setCompanyId] = useState(0);
  const [companyName, setCompanyName] = useState('');
  const [clientValue, setClientValue] = useState('31.50')
  const [rangeList, setRangeList] = useState([]);
  const [rangeValidatorList, setRangeValidatorList] = useState([]);
  const [companyNameList, setCompanyNameList] = useState([]);
  const [validator, setValidator] = useState({
    companyName: match.params.method === 'edit',
  });
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [errorCode, setErrorCode] = useState(0);

  useEffect(() => {
    if (companies.length <= 0) {
      loadCompanies();
    }
  }, [companies, loadCompanies]);

  useEffect(() => {
    if(companies) {
      setCompanyNameList(companies.map(company => company.name));
    }

    if (match.params.method === 'edit') {
      const company = companies.find(cmpny => cmpny.id === parseInt(match.params.companyId, 10));
      setCompanyName(company.name);
      if (company.range_list.length > 0) {
        setRangeList(company.range_list);
        setRangeValidatorList(company.range_list.map(() => ({
          max_account: { validate: true, message: ''},
          min_account: { validate: true, message: '' }
        })));
      }

      if (company?.client_value?.value) {
        setClientValue(company.client_value.value);
      }

      setCompanyId(parseInt(match.params.companyId));
    }
  }, [companies, match.params.companyId, match.params.method]);

  const handleChange = (ev, isValid) => {
    // const field = ev.target.name;
    const value = ev.target.value;
    const name = ev.target.name;
    console.log('HANDLE CHANGE')
    console.log(ev.target.name);
    switch (name) {
      case 'companyName': {
        if (!companyReg.test(value)) {
          setCompanyName(value);
          setValidator({
            companyName: false
          });
        } else {
          setCompanyName(value);
          setValidator({
            companyName: isValid
          });
        }
        break;
      }
      case 'clientValue': {
        setClientValue(value);
        break;
      }
      default:
        break;
    }
    
  };

  const validateName = (value) => {
    if (value.length <= 2) {
      return [false, ''];
    }
    return !companyNameList.includes(value);
  };

  const isFormValid = () => {
    return Object.keys(validator).every(el => validator[el] === true);
  };

  const convertDataToServer = () => {
    const company = {
      id: companyId,
      name: companyName,
      range_list: rangeList,
      client_value: {
        value: clientValue
      }
    };
    return company;
  };

  const saveCompany = () => {
    props.saveCompany(convertDataToServer()).then(() => {
      props.history.push('/app/manage/companies');
    }).catch((err) => {
      const data = err.data;
      const dataKey = data ? Object.keys(data)[0] : undefined;
      switch (dataKey) {
        case 'minMax': {
          setErrorMsg(<FormattedMessage id="companies.error.minMax" defaultMessage="Conta mínima não pode ser maior do que conta máxima" />);
          setError(true);
          setErrorCode(400);
          break;
        }
        case 'noMinOrMax': {
          setErrorMsg(<FormattedMessage id="companies.error.noMinOrMax" defaultMessage="Conta mínima ou máxima não enviada" />);
          setError(true);
          setErrorCode(400);
          break;
        }
        case 'rangeUsed': {
          setErrorMsg(<FormattedMessage id="companies.error.rangeUsed" defaultMessage="Esta faixa de contas já está sendo utilizada ou está dentro de outra faixa" />);
          setError(true);
          setErrorCode(400);
          break;
        }
        default:
          break;

      }
      console.log(err);
    });
  };

  const handleRange = (ev, index) => {
    const attribute = ev.target.name.split('-')[0];
    const value = ev.target.value;

    const newRangeList = rangeList.map((el, i) => {
      if (i === index) {
        return Object.assign({}, el, { [attribute]: value });
      }
      return Object.assign({}, el);
    });

    const newRangeValidatorList = rangeValidatorList.map((validator, i) => {
      if (i === index) {
        let isValid = true;
        let message = '';
        if (value.length < 4) {
          isValid = false;
          message = 'Uma conta necessita de 4 caracteres numéricos';
        }
        if (!NUMBERS_REGEX.test(value)) {
          isValid = false;
          message = 'Apenas números são aceitos';
        }
        return Object.assign({}, validator, {
          [attribute]: { validate: isValid, message }
        });
      }
      return Object.assign({}, validator);
    });

    setRangeValidatorList(newRangeValidatorList);
    setRangeList(newRangeList);
  };

  const addRange = () => {
    setRangeList([...rangeList, { min_account: '', max_account: '' }]);
    setRangeValidatorList([...rangeValidatorList, {
      max_account: { validate: true, message: ''},
      min_account: { validate: true, message: '' }
    }]);
  };

  const removeRange = (ev, index) => {
    ev.preventDefault();
    setRangeList([...rangeList.slice(0, index), ...rangeList.slice(index + 1)]);
    setRangeValidatorList([
      ...rangeValidatorList.slice(0, index),
      ...rangeValidatorList.slice(index + 1)
    ]);
  };

  const closeErrorModal = () => {
    setError(false);
    setErrorMsg('');
    setErrorCode(0);
  };

  const activeSaveBtn = isFormValid();
  const title = match.params.method === 'edit' ? <FormattedMessage id="companies.editHeader" defaultMessage="EDITAR EMPRESA" /> : <FormattedMessage id="companies.addHeader" defaultMessage="ADICIONAR EMPRESA" />;

  console.log(companies);

  return (
    <div>
      {error &&
        <ErrorComponent errorCode={errorCode} errorMsg={errorMsg} handleButton={closeErrorModal} />
      }
      <div className="contactto-middle-content" style={{ overflowX: 'hidden' }}>
        <div className="form-container">
          <div className="form-title">{title}</div>
          <form className="col-12">
            <div className="block-title"><FormattedMessage id="alarms.informations" defaultMessage="INFORMAÇÕES" /></div>
            <div className="form-row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="text-muted" htmlFor="companyName"><FormattedMessage id="companies.name" defaultMessage="Nome" /> <span className="form-required">*</span></label>
                  <TextInput
                    type="text"
                    name="companyName"
                    required
                    maxCharacters={5}
                    validate={validateName}
                    value={companyName}
                    onChange={handleChange}
                    onPaste
                    onDrop
                    inputClass="form-input form-input--ok form-control"
                    errorClass="form-input form-input--error form-control"
                    textErrorClass={style['form-text-error']}
                  />
                  <br />
                </div>
              </div>
              {!isCompanyAdm &&
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="text-muted" htmlFor="clientValue"><FormattedMessage id="companies.clientValue" defaultMessage="Valor por Usuário (R$)" /> <span className="form-required">*</span></label>
                    <TextInput
                      type="text"
                      name="clientValue"
                      required
                      maxCharacters={6}
                      value={clientValue}
                      onChange={handleChange}
                      mask={['00.00', '000.00']}
                      onPaste
                      onDrop
                      inputClass="form-input form-input--ok form-control"
                      errorClass="form-input form-input--error form-control"
                      textErrorClass={style['form-text-error']}
                    />
                    <br />
                  </div>
                </div>
              }
            </div>
            {!isCompanyAdm &&
              <div style={{ marginTop: '10px'}}>
                <div className="block-title"><FormattedMessage id="companies.form.ranges" defaultMessage="FAIXAS DE CONTA" /></div>
                {rangeList?.map((el, index) => 
                  <div key={`range-${index}`} className="form-row col-sm-12">
                    <div className="col-sm-5">
                      <InputContainer
                        name={`min_account-${index}`}
                        maxLength="4"
                        title={<FormattedMessage id="companies.form.minAccount" defaultMessage="Conta Mínima" />}
                        value={el.min_account}
                        validator={rangeValidatorList[index].min_account}
                        onChange={(ev) => handleRange(ev, index)}
                      />
                    </div>
                    <div className="col-sm-5">
                      <InputContainer
                        name={`max_account-${index}`}
                        maxLength="4"
                        title={<FormattedMessage id="companies.form.maxAccount" defaultMessage="Conta Máxima" />}
                        value={el.max_account}
                        validator={rangeValidatorList[index].max_account}
                        onChange={(ev) => handleRange(ev, index)}
                      />
                   </div>
                    <div className="col-sm-2">
                      <button className="btn" style={{ width: '35px', marginTop: '33px' }} onClick={(ev) => removeRange(ev, index)}>
                        <i class="far fa-trash-alt"></i>
                      </button>
                    </div>
                  </div>
                )}
                <div className="form-row" />
                <div className="row" style={{ marginBottom: '15px', marginTop: '15px' }}>
                  <div className="col-12">
                    <button type="button" className="btn btn--form btn--color-green col-6" onClick={addRange}>
                      <i class="fas fa-list-ol" style={{ paddingRight: '1rem',}}/>
                      <FormattedMessage id="companies.form.addRange" defaultMessage="Adicionar Faixa" />
                    </button>
                  </div>
                </div>
              </div>
            }
            <div className="col-12 row">
              <button type="button" className="btn btn--form btn--color-red col-6" onClick={() => props.history.push('/app/manage/companies')}>
                <span>
                  <i className="far fa-window-close" aria-hidden="true" style={{ paddingRight: '1rem', paddingTop: '0.2rem' }} />
                </span>
                <FormattedMessage id="form.button.cancel" defaultMessage="Cancelar" />
              </button>
              {activeSaveBtn ?
                <button type="button" disabled={false} className={'btn btn--form btn--color-green col-6'} onClick={saveCompany}>
                  <span>
                    <i className="far fa-save fa-lg" aria-hidden="true" style={{ paddingRight: '1rem', paddingTop: '0.2rem' }} />
                  </span>
                  <FormattedMessage id="form.button.save" defaultMessage="Salvar" />
                </button>
                :
                <button type="button" disabled className={'btn btn--form btn--color-invalid col-6'}>
                  <span>
                    <i className="far fa-save fa-lg" aria-hidden="true" style={{ paddingRight: '1rem', paddingTop: '0.2rem' }} />
                  </span>
                  <FormattedMessage id="form.button.save" defaultMessage="Salvar" />
                </button>
              }
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

CompanyForm.propTypes = {
  loadCompanies: PropTypes.func.isRequired,
  saveCompany: PropTypes.func.isRequired,
  params: PropTypes.shape({
    companyId: PropTypes.string,
    method: PropTypes.string.isRequired,
  }).isRequired,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      user_set: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          is_active: PropTypes.bool,
          first_name: PropTypes.string,
          last_name: PropTypes.string,
          email: PropTypes.string,
          groups: PropTypes.arrayOf(
            PropTypes.number,
          ),
        }),
      ),
    }),
  ).isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    companies: state.groups.filter(grp => ![1, 2, 3, 4].includes(grp.id)),
    params: ownProps.params,
    loggedAttendant: state.loggedAttendant,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadCompanies: () => dispatch(groupsActions.loadGroups()),
    saveCompany: company => dispatch(groupsActions.saveGroup(company)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyForm);
