import React from 'react';
import PropTypes from 'prop-types';

const Edit = ({ height, className, width }) => (
  <svg className={className} fill="#666" width={width} height={height} viewBox="0 0 141.7 141.7" xmlns="http://www.w3.org/2000/svg">
<g id="NbJLT3_1_" stroke="#666" strokeWidth="3.3">
  <g stroke="#666" strokeWidth="3.3">
   <g stroke="#666" strokeWidth="3.3">
    <path d="m138.3 71.4c0 2.3-1.1 4-2.6 5.5l-34.7 34.7c-3.5 3.5-7 6.9-10.4 10.4-0.8 0.8-1.6 1.2-2.7 1.3-4.1 0.4-8.3 0.9-12.4 1.4-2.8 0.3-4.9-1.7-4.6-4.6 0.5-4 0.9-8.1 1.4-12.1 0.1-1.1 0.4-2.1 1.3-3 15.2-15.1 30.3-30.3 45.5-45.5 3-3 7-2.9 10 0l6.9 6.9c1.5 1.5 2.4 3.1 2.3 5zm-26.3 5.1c-0.1 0.1-0.3 0.2-0.4 0.3-10.6 10.6-21.3 21.2-31.9 31.8-0.3 0.3-0.6 0.7-0.6 1-0.3 2.6-0.6 5.2-0.9 7.9 2.1-0.3 4-0.6 5.9-0.6s3.1-0.9 4.3-2.1c10-10.1 20.1-20.2 30.2-30.2 0.3-0.3 0.6-0.6 0.7-0.8l-7.3-7.3zm19.2-5c-2.4-2.3-4.8-4.7-7.1-6.9-2.3 2.3-4.7 4.6-7.1 6.9 2.4 2.4 4.7 4.8 7.1 7.2 2.4-2.4 4.8-4.8 7.1-7.2z"/>
    <path d="m64.3 118c0.1 2.3 0.2 4.5 0.3 6.8h-1.3-48.7c-6.7 0-11-4.2-10.9-10.9 0-3.8-0.2-7.6 0.3-11.3 1.2-10.1 6.6-17.5 15.7-22 6.3-3.1 12.9-3.8 19.7-1.4 7.7 2.7 15.4 2.7 23 0 8.4-2.9 15.9-1.1 23.2 3.5 0.2 0.1 0.3 0.2 0.4 0.3s0.1 0.1 0.1 0.2c-1.6 1.5-3.1 3.1-4.5 4.5-2.4-0.9-4.8-1.9-7.2-2.6-3.2-0.9-6.3-0.7-9.5 0.4-9.2 3.1-18.4 3.4-27.6 0.1-8.4-3-17.8 1.2-22.4 7.4-3 3.9-4.4 8.4-4.4 13.3v8.1c0 2.3 1.3 3.6 3.6 3.8h1.3 47.5c0.5-0.2 1-0.2 1.4-0.2z"/>
    <path d="m77.7 44c0 14.8-12.1 26.8-26.9 26.8-15 0-26.9-12.1-26.9-27.3 0-14.5 12.3-26.6 27-26.6 14.8 0.1 26.8 12.2 26.8 27.1zm-6.7-0.1c0-11.1-9-20.2-20.2-20.2-11.1 0-20.2 9-20.2 20.2 0 11.1 9 20.2 20.2 20.2s20.2-9 20.2-20.2z"/>
   </g>
  </g>
 </g>  </svg>
);

Edit.defaultProps = {
  height: '24',
  className: '',
  width: '24',
};

Edit.propTypes = {
  height: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
};

export default Edit;
