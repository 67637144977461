import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import AttendsOpenCard from '../../../Cards/Attends/AttendsOpenCard';
import { returnColor } from '../../../../BLL/general';
import {
  alarmBackgroundColor,
  alarmFooterIconColor,
  zoneBackgroundColor,
  zoneFooterIconColor,
  lightGrayBackgroundColor,
  grayFooterIconColor
} from '../../../../BLL/globals';
import style from './openAttends.module.scss';

import CustomCardHOC from '../../../Cards/CustomCardHOC';

const OpenAttends = ({
  attends,
  user,
  setUserBusy,
  channelId,
  lang,
  history,
  showIcons,
  useZoneColors
}) => {
  const fillColor = {
    1: '#D8342B',
    2: '#F69B38',
    3: '#008D53',
    4: '#3D94CC',
  };
  const priorityColor = ['#EC8981', '#F9BE7D', '#59C599', '#59D7F2'];
  return (
    <div className="container-fluid" style={{ float: 'left' }}>
      <div className="row" style={{ height: '100%', border: 'none' }}>
        <div className={style.title}>
          <FormattedMessage id="dashboard.open.header" defaultMessage="ATENDIMENTOS ABERTOS" />
          <hr className="hrcolor" />
        </div>
      </div>
      <div className="row" style={{ height: '100%', border: 'none' }}>
        {attends.length > 0 ? (
          attends.slice(0, 6).map((open) => {
            let theme = returnColor(alarmBackgroundColor, open.highest_priority - 1);
            let iconColor = returnColor(alarmFooterIconColor, open.highest_priority - 1);
            let clientName = `${open.client_first_name.split(' ')[0]} ${open.client_last_name.split(' ').pop()}`
            if (open.is_from_secondary && open.secondary_first_name) {
              clientName = `${open.secondary_first_name.split(' ')[0]} ${open.secondary_last_name.split(' ').pop()}`
            }
            if (useZoneColors) {
              if (open.highest_code === '101' && open.tr_zone) {
                theme = returnColor(zoneBackgroundColor, parseInt(open.tr_zone) -1);
                iconColor = returnColor(zoneFooterIconColor, parseInt(open.tr_zone) -1);
              } else if (open.highest_code === '100') {
                const redColor = 3;
                theme = returnColor(zoneBackgroundColor, redColor);
                iconColor = returnColor(zoneFooterIconColor, redColor);
              } else {
                theme = lightGrayBackgroundColor;
                iconColor = grayFooterIconColor;
              }
            }
            const AttendsOpen = (
              <AttendsOpenCard
                attend={open}
                key={open.id}
                lang={lang}
                attendant={
                  open.att_first_name === null
                    ? <FormattedMessage id="dashboard.open.attendant" defaultMessage="Sem Atendente" />
                    : `${open.att_first_name} ${open.att_last_name}`
                }
                user={user}
                setUserBusy={setUserBusy}
                channelId={channelId}
                iconColor={iconColor}
                history={history}
                showIcons={showIcons}
              />
            );

            return (
              <div key={open.id} className="col-12 col-md-6 col-lg-4 col-xl-4" style={{ height: '100%' }} data-testid={`attend-open-${open.id}`}>
                <CustomCardHOC
                  headers={[clientName]}
                  childComponent={[AttendsOpen]}
                  maxHeight="33px"
                  headerFontSize="1.1rem"
                  theme={theme}
                  onClick={() => {}}
                />
              </div>
            );
          })
        ) : (
          <div className={style.empty}>
            <div
              className={style.border}
              style={{ backgroundColor: 'themeColor' }}
            />
            <div className={style.text} />
          </div>
        )}
      </div>
    </div>
  );
};

OpenAttends.propTypes = {
  channelId: PropTypes.string.isRequired,
  attends: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      cli_info: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
      }),
      open_ts: PropTypes.string,
      tenant_from: PropTypes.string,
    }),
  ).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.number),
    is_active: PropTypes.bool,
    is_staff: PropTypes.bool,
    is_superuser: PropTypes.bool,
    user_info: PropTypes.shape({
      id: PropTypes.number,
      user: PropTypes.number,
      phone: PropTypes.string,
      mobile: PropTypes.string,
      email: PropTypes.string,
      extension_id: PropTypes.number,
    }),
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        priority: PropTypes.number,
        user: PropTypes.number,
        receive_sms: PropTypes.bool,
        receive_email: PropTypes.bool,
      }),
    ),
  }).isRequired,
  setUserBusy: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};

export default OpenAttends;
