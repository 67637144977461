import React from 'react';
import PropTypes from 'prop-types';

const Listen = ({ className, height, width }) => (
  <svg className={className} fill="#fff" width={width} height={height} viewBox="100 100 841.891 595.279" enableBackground="new 0 0 526.347 520.844" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        fill="transparent" d="M715.444,297.139c0,162.919-132.08,295-295,295c-162.92,0-295-132.08-295-295c0-162.92,132.08-295,295-295
                        C583.364,2.139,715.444,134.219,715.444,297.139z"
      />
      <g>
        <g>
          <path
            fill="#FAFDFF" d="M408.512,469.233v-74.387c-0.129-12.646-10.417-22.865-23.077-23.078h-40.127
                                c-2.987-0.409-5.694-1.955-7.589-4.245c-10.083-10.099-20.977-36.359-20.636-70.262c-0.136-18.672,3.45-37.224,10.75-54.418
                                c6.3-13.866,13.54-20.098,17.876-20.098h40.135c12.721,0,23.009-10.28,23.085-22.941v-0.068v-74.379
                                c-0.144-12.653-10.432-22.873-23.085-23.077l-43.046-0.068c-49.612,0-88.996,85.683-88.996,195.125
                                c-0.341,76.403,19.833,146.12,51.226,177.505c9.613,10.766,23.153,17.134,37.565,17.68l43.046-0.076
                                c6.087-0.076,11.91-2.577,16.17-6.898C406.071,481.211,408.512,475.389,408.512,469.233z M385.298,480.801l-16.785-0.334v0.137
                                l-26.254-0.061c-11.099-0.606-21.523-5.701-28.907-13.949c-29.172-29.172-47.981-95.629-47.981-169.257
                                c0-99.163,35.397-183.42,77.359-183.488h25.784l16.724-0.197c6.292,0,11.364,5.072,11.364,11.364v74.387
                                c0,6.285-5.004,11.296-11.304,11.364c0,0,0,0-0.061,0h-17.126l-0.076,0.273l-22.661,0.129
                                c-10.288-0.068-20.644,9.818-28.505,26.875c-8.051,18.604-12.176,38.778-12.039,59.02c0,32.28,9.954,63.667,24.563,78.237
                                c4.071,4.594,9.818,7.369,15.981,7.718l22.873,0.061v0.137h17.119c6.231,0.136,11.167,5.14,11.379,11.372v74.371
                                C396.806,475.449,391.659,480.664,385.298,480.801z"
          />
        </g>
        <path
          id="telephone_receive_1_" fill="#FAFDFF" d="M625.698,290.559h-200.25l42.545-42.568c2.457-2.843,2.107-7.104-0.727-9.545
                            c-2.518-2.168-6.309-2.168-8.811,0l-54.136,54.145c-2.639,2.638-2.639,6.914-0.061,9.552c0,0,0,0,0.061,0.061l54.136,54.145
                            c2.852,2.441,7.111,2.093,9.537-0.742c2.184-2.502,2.184-6.293,0-8.795l-42.545-42.713h200.25c3.715,0,6.777-3.047,6.777-6.761
                            C632.475,293.607,629.413,290.559,625.698,290.559z"
        />
      </g>
    </g>
  </svg>
);

Listen.defaultProps = {
  height: '64',
  className: '',
  width: '64',
};

Listen.propTypes = {
  height: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
};

export default Listen;
