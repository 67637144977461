import React from "react";
import style from "./clientForm.module.scss";

const SecondaryActionsForm = (props) => {
  const { secondary, localDispatch, showForm, method } = props;

  if (method !== "edit") {
    return <></>;
  }

  return (
    <div className="col-12" style={{ display: showForm ? "" : "none" }}>
      <div className="form-row">
        <button
          type="button"
          className={`${style.btn} ${style["btn--color-red"]} ${style["btn--secondary"]}`}
          onClick={() => localDispatch({ type: "removeSecondary" })}
          disabled={!secondary.first_name && !secondary.last_name}
        >
          <i class="fas fa-user-alt-slash"></i>
          Remover secundário
        </button>
        <button
          type="button"
          className={`${style.btn} ${style["btn--color-yellow"]} ${style["btn--secondary"]}`}
          onClick={() => localDispatch({ type: "invertClients" })}
          disabled={!secondary.first_name && !secondary.last_name}
        >
          <i class="fas fa-people-arrows"></i>
          Inverter usuários
        </button>
      </div>
    </div>
  );
};

export default SecondaryActionsForm;
