import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Loading from '../../../../icons/Loading';
import RenderTransmitterChangedRow from './TransmitterChangedRow';
import style from './history.module.scss';


const history = gql`
  query($token: String!, $clientId: Int!, $offset: Int) {
    transmitterHistory(token:$token, clientId:$clientId, offset:$offset) {
      historyId
      actionDate
      actionType
      actionUser {
        id
        firstName
        lastName
      }
      transmitter {
        identifier
        zone
        description
        generalInfo
      }
    }
  }
`;

const RenderTransmittersHistory = ({ clientId }) => {
  let stopFetching = false;
  let offset = 0;
  return (
    <Query query={history} variables={{ token: sessionStorage.activeToken, offset, clientId }} fetchPolicy="cache-and-network">
      {({ loading, error, data, fetchMore }) => {
        if (error) return `Error ${error}`;
        console.log('Data', data);
        return (
          <div>
            <div className={style.tableHeader}>
              <div className={style.tableHeaderColumn}>
                <FormattedMessage id="history.transmitter" defaultMessage="Transmissor" />
              </div>
              <div className={style.tableHeaderColumn}>
                <FormattedMessage id="history.type" defaultMessage="Tipo" />
              </div>
              <div className={style.headerDataColumn}> Data </div>
              <div className={style.headerChangedByColumn}>
                <FormattedMessage id="history.modifiedBy" defaultMessage="Modificado Por" />
              </div>
              <div className={style.tableHeaderColumn} />
            </div>
            <div
              id="table-body"
              className={style.tableBody}
              onScroll={() => {
                const doc = document.getElementById('table-body');
                if ((doc.scrollTop + doc.offsetHeight) >= doc.scrollHeight) {
                  if (data.transmittersHistory && !loading && !stopFetching) {
                    offset += 20;
                    fetchMore({
                      variables: { offset },
                      updateQuery: (prev, { fetchMoreResult }) => {
                        if (fetchMoreResult.transmittersHistory
                            && fetchMoreResult.transmittersHistory.length === 0) {
                          stopFetching = true;
                          return prev;
                        }
                        return Object.assign({}, prev, {
                          transmittersHistory: [
                            ...prev.transmittersHistory,
                            ...fetchMoreResult.transmittersHistory,
                          ],
                        });
                      },
                    });
                  }
                }
              }}
            >
              {data && data.transmitterHistory && data.transmitterHistory
                .map((log, index, array) => (
                  <RenderTransmitterChangedRow
                    key={log.historyId}
                    log={log}
                    index={index}
                    array={array}
                  />)
                )
              }
              {loading && <Loading text={<FormattedMessage id="attend.list.loading" defaultMessage="Carregando" />} />}
            </div>
          </div>
        );
      }}
    </Query>
  );
};

RenderTransmittersHistory.propTypes = {
  clientId: PropTypes.string.isRequired,
};

export default RenderTransmittersHistory;
