import React from 'react';
import { FormattedMessage } from 'react-intl';
import '../../css/login.scss';

const LoginForm = (props) => (
  <form id="contactto-login-content-container-form" className={props.loginFormClass}>
    <img
      className="contactto-login-logo"
      src={props.logo}
      alt="contactto logo"
    />
    <div id="login-error-title" className="login-error-title">
      {props.errorElm}
    </div>
    <input
      id="login-form-input-domain"
      className={props.inputDomainClass}
      type="text"
      value={props.domain}
      name="domain"
      placeholder={props.domainText}
      onChange={props.handleInputChange}
    />
    <input
      id="login-form-input-user"
      className={props.inputUserClass}
      type="text"
      value={props.username}
      name="username"
      placeholder={props.usernameText}
      onChange={props.handleInputChange}
    />
    <input
      id="login-form-input-pass"
      className={props.inputPassClass}
      type="password"
      value={props.password}
      name="password"
      data-el="8"
      placeholder={props.passwordText}
      onChange={props.handleInputChange}
    />
    <button onClick={props.handleSubmit} className="contactto-login-content-container-form-button">
      <FormattedMessage id="login.form.enter" defaultMessage="entrar" />
    </button>
  </form>
)

export default LoginForm;
