import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import ManagementMenu from '../ManagementMenu';
import DeleteCompanyModal from '../../../Modal/ModalDeleteCompany';
import ConfirmRemoveModal from '../../../Modal/ModalConfirmRemove';
import CompanyRow from './CompanyRow';
import { broadcast, removeByKey } from '../../../../BLL/general';
import * as attendantsActions from '../../../../actions/attendantsActions';
import * as groupsActions from '../../../../actions/groupsActions';

import broadcastMessages from '../../../../BLL/locale/messagesBroadcast';

import style from './companiesManagement.module.scss';

const CompanyPage = (props) => {
  const isCompanyAdm = props.companies.some(
    company => props.loggedAttendant.groups.includes(company.id)
  );

  const [selectedCompany, setSelectedCompany] = useState('0');
  const [attendantId, setAttendantId] = useState(undefined);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (isCompanyAdm) {
      setSelectedCompany(
        props.companies.find(company => 
          props.loggedAttendant.groups.includes(company.id)
        ).id
      )
    } else {
      const selected = localStorage.getItem('selectedCompany');
      if (props.companies.some(company => company.id === parseInt(selected))) {
        setSelectedCompany(selected);
      }
    }
  }, [props.companies, props.loggedAttendant, isCompanyAdm]);
  
  const setModalInfo = (show, attendant) => {
    setShowModal(show);
    setAttendantId(attendant);
  }

  const toggleAttendant = () => {
    const attendant = props.attendants.find(att => att.id === attendantId);
    const userInfo = removeByKey(attendant.user_info, ['user', 'email', 'extension_id']);
    const attendantToToggle = Object.assign(
      {},
      attendant,
      { is_active: !attendant.is_active },
      { user_info: userInfo },
    );
    const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
    const text = `${broadcastMessages[lang]['broadcast.attendant']} ${attendantToToggle.first_name} ${attendantToToggle.last_name} ${
      attendantToToggle.is_active ? broadcastMessages[lang]['broadcast.recovered'] : broadcastMessages[lang]['broadcast.deleted']}`;
    
    props.editAttendant(attendantToToggle).then(() => {
      props.loadGroups();
      broadcast(axios, props.channelId, {
        type: 'attendant',
        method: 'toggle',
        userId: props.loggedAttendant.id,
        attendant: attendantToToggle,
        text,
      });
    });
    setModalInfo(false, null);
  }

  return (
    <div className="contactto-middle-content" style={{ overflowX: 'hidden' }}>
      <div className="row">
        <div className="pagetitle">
          <FormattedMessage id="management.header" defaultMessage="GERENCIAR" />
        </div>
        {!isCompanyAdm &&
          <button type="button" className={style.btn} onClick={() => props.history.push('/app/companies/form/add')}>
            <div>
              <span style={{ fontWeight: 'bold' }}>+ <FormattedMessage id="general.add" defaultMessage="ADICIONAR" /></span>
            </div>
          </button>
        }
      </div>
      <ManagementMenu history={props.history} />
      {showDeleteModal &&
        <DeleteCompanyModal
          closeModal={() => setShowDeleteModal(false)}
          companyId={selectedCompany}
          deleteCompany={props.deleteGroup}
          deleteCompanyAndUsers={props.deleteGroupAndUsers}
          setSelectedCompany={setSelectedCompany}
        />
      }
      {showModal &&
        <ConfirmRemoveModal
          removeAction={toggleAttendant}
          setModalInfo={setModalInfo}
        />
      }
      <div className={style.content}>
        {!isCompanyAdm &&
          <select
            id="companySelection"
            className="custom-select"
            value={selectedCompany}
            onChange={(e) => {
              const value = e.target.value;
              setSelectedCompany(e.target.value);
              localStorage.setItem('selectedCompany', value);
            }}
          >
            <option value={0}> - </option>
            {props.companies?.length > 0 && props.companies.sort((compA, compB) => compA.name.localeCompare(compB.name)).map(company => (
              <option key={company.id} value={company.id}>{company.name}</option>
            ))}
          </select>
        }
        {(parseInt(selectedCompany) !== 0) &&
          <CompanyRow
            isCompanyAdm={isCompanyAdm}
            company={props.companies.find(company => company.id === parseInt(selectedCompany))}
            loggedAttendantGroup={props.loggedAttendantGroup}
            setModalInfo={setModalInfo}
            openDeleteModal={() => setShowDeleteModal(true)}
            history={props.history}
          />
        }
      </div>
    </div>
  )
};

CompanyPage.propTypes = {
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      user_set: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          is_active: PropTypes.bool,
          first_name: PropTypes.string,
          last_name: PropTypes.string,
          email: PropTypes.string,
          groups: PropTypes.arrayOf(
            PropTypes.number,
          ),
        }),
      ),
    }),
  ).isRequired,
  loggedAttendant: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    groups: PropTypes.arrayOf(
      PropTypes.number,
    ),
    is_active: PropTypes.bool,
    is_staff: PropTypes.bool,
  }).isRequired,
  loggedAttendantGroup: PropTypes.number.isRequired,
  toggleAttendant: PropTypes.func.isRequired,
  channelId: PropTypes.string.isRequired,
  attendants: PropTypes.arrayOf(
    PropTypes.shape({
      ordering: PropTypes.string,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          username: PropTypes.string,
          first_name: PropTypes.string,
          last_name: PropTypes.string,
          email: PropTypes.string,
          password: PropTypes.string,
          groups: PropTypes.arrayOf(
            PropTypes.number,
          ),
          is_active: PropTypes.bool,
          is_staff: PropTypes.bool,
          is_superuser: PropTypes.bool,
          user_info: PropTypes.shape({
            id: PropTypes.number,
            user: PropTypes.number,
            phone: PropTypes.string,
            mobile: PropTypes.string,
            email: PropTypes.string,
            extension_id: PropTypes.number,
          }),
          actions: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number,
              priority: PropTypes.number,
              user: PropTypes.number,
              receive_sms: PropTypes.bool,
              receive_email: PropTypes.bool,
            }),
          ),
        }),
      ),
    }),
  ).isRequired,
  loadGroups: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    loggedAttendant: state.loggedAttendant,
    loggedAttendantGroup: state.loggedAttendant.groups[0],
    companies: state.groups.filter(grp => ![1, 2, 3, 4].includes(grp.id)),
    channelId: ownProps.channelId,
    attendants: state.attendants[0].data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    editAttendant: attendant => dispatch(attendantsActions.editAttendant(attendant)),
    deleteGroup: (groupId) => dispatch(groupsActions.deleteGroup(groupId)),
    deleteGroupAndUsers: (groupId) => dispatch(groupsActions.deleteGroupAndUsers(groupId)),
    loadGroups: () => dispatch(groupsActions.loadGroups()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyPage);
