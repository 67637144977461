import React from 'react';
import PropTypes from 'prop-types';

const Download = ({ height, className, width, fill, transform }) => (
  <svg
    className={className}
    width={width}
    height={height}
    fill={fill}
    transform={transform}
    viewBox="0 0 140 140"
    xmlns="http://www.w3.org/2000/svg">
    <path d="m70.9 132.4v-62.8" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="4" />
    <path d="m70.9 130.9c-4.5-4.8333-9-9.6667-13.5-14.5-1.6108 1.4616-4.1283 2.891-1.2103 4.5133 4.9034 5.2622 9.8069 10.524 14.71 15.787 5.4333-5.8667 10.867-11.733 16.3-17.6-1.5728-1.5025-3.1798-3.9131-4.5822-0.8797-3.9059 4.2266-7.8119 8.4531-11.718 12.68z" />
    <path d="m125.2 55.7c2.1957-16.151-8.8135-32.727-24.576-36.904-13.229-3.9415-28.527 1.2064-36.624 12.404-13.842-6.3912-31.846 0.4111-37.976 14.406-0.75137 8.1249-10.347 8.2229-14.199 14.655-11.072 12.822-7.4298 35.454 8.0767 43.076 8.8859 4.7796 19.133 2.866 28.77 3.2636 3.1707 1.0448 1.9067-2.345 2.1286-4.249 0.95694-3.1722-1.4911-2.9395-3.8949-2.751-8.5301-0.39607-17.833 1.5466-25.413-3.4488-11.965-7.55-12.014-27.072-0.50975-35.083 2.2476-2.9857 7.9868-2.252 9.3553-5.1211 0.85537-12.899 14.598-22.768 27.09-19.472 3.2819 0.66369 6.0972 2.5172 8.9722 4.1258 4.3445-8.4339 12.562-15.085 22.254-15.775 14.822-1.8597 29.354 10.638 29.71 25.577 1.3448 3.2578-3.0876 8.4304 1.1354 9.9975 11.096 6.7424 12.818 23.388 5.262 33.334-6.1882 7.2848-16.338 5.9507-24.914 6.0661h-8.948v7c7.0632-0.1327 14.41 0.26624 21.3-0.2 10.53-0.37449 20.538-7.7107 22.861-18.199 3.2921-11.668 0.24095-25.292-9.8608-32.701z" />
  </svg>
);

Download.defaultProps = {
  className: '',
  width: '24',
  height: '24',
  fill: '#00b8cb',
  transform: 'translate(0,0)',
};

Download.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  transform: PropTypes.string,
};

export default Download;
