import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Transmitter from './TransmitterComponent';
import * as transmittersActions from '../../../actions/transmittersActions';
import { getDomain, host, getToken } from '../../../BLL/environment';

import '../../../css/detailPage.scss';
import '../../../css/block.scss';

const TransmitterDetail = (props) => {
  const {
    clientId,
    transmitterId,
  } = props.match.params;

  const {
    clients,
    transmitters,
    attendants
  } = useSelector(state => ({
    clients: state.clients,
    transmitters: state.transmitters,
    attendants: state.attendants.length > 0 ? [...state.attendants[0].data] : [],
  }));

  const dispatch = useDispatch();

  const [transmitter, setTransmitter] = useState(undefined);
  const [client, setClient] = useState(undefined);
  const [useForSecondary, setUseForSecondary] = useState(false);

  useEffect(() => {
    const loadTransmitters = (clientId, order) => dispatch(transmittersActions.loadTransmitters(clientId, order));
    const loadClient = (clientId) => {
      axios.get(`https://${getDomain()}${host()}/api/clients/${clientId}/`, {
        headers: { Authorization: `Token ${getToken()}` },
      }).then(res => {
        setClient(res.data);
      })
      .catch(error => console.log(error));
    };
    loadClient(clientId);
    loadTransmitters(clientId, 'zone');
  }, [clientId, dispatch]);

  useEffect(() => {
    if (transmitters.length > 0) {
      const clientTransmitters = transmitters.filter(tr => tr.clientId === clientId)[0].data;
      const newTransmitter = clientTransmitters.find(tr => tr.id === parseInt(transmitterId, 10));
      setTransmitter(newTransmitter);
      if (newTransmitter.secondary > 0) {
        setUseForSecondary(true);
      }
    }
  }, [transmitters, clientId, transmitterId]);

  const creator = transmitter ? attendants.filter(attendant => attendant.id === transmitter.created_by)[0] : undefined;
  const modifier = transmitter ? attendants.filter(attendant => attendant.id === transmitter.modified_by)[0] : undefined;

  return (
    <div className="contactto-middle-content" style={{ overflowX: 'hidden' }}>
      {client &&
        <div className="detail-container">
          <div htmlFor="detailTitle" className="detail-title">
            <FormattedMessage id="transmitters.details.header" defaultMessage="DETALHES DO TRANSMISSOR" /> <span className="grey">{`${client.first_name} ${client.last_name}`.toUpperCase()}</span>
          </div>
          {transmitter &&
            <Transmitter
              transmitter={transmitter}
              creator={creator ? `${creator.first_name} ${creator.last_name}` : '-'}
              modifier={modifier ? `${modifier.first_name} ${modifier.last_name}` : '-'}
              useForSecondary={useForSecondary}
              persNumber={client.account}
            />
          }
        </div>
      }
    </div>
  );
};

TransmitterDetail.propTypes = {
  params: PropTypes.object.isRequired,
};

export default TransmitterDetail;
