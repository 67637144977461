import React from 'react';
import PropTypes from 'prop-types';
import AlarmiHelpEmergency from './alarms/AlarmiHelpEmergency';
import AlarmEmerConsIco from './alarms/AlarmEmerConsIco';
import AlarmGeo1InIco from './alarms/AlarmGeo1InIco';
import AlarmGeo1OutIco from './alarms/AlarmGeo1OutIco';
import AlarmGeo2InIco from './alarms/AlarmGeo2InIco';
import AlarmGeo2OutIco from './alarms/AlarmGeo2OutIco';
import AlarmGeo3InIco from './alarms/AlarmGeo3InIco';
import AlarmGeo3OutIco from './alarms/AlarmGeo3OutIco';
import AlarmGeo4InIco from './alarms/AlarmGeo4InIco';
import AlarmGeo4OutIco from './alarms/AlarmGeo4OutIco';
import AlarmChargeDeviceIco from './alarms/AlarmChargeDeviceIco';
import AlarmLostMed1Ico from './alarms/AlarmLostMed1Ico';
import AlarmLostMed2Ico from './alarms/AlarmLostMed2Ico';
import AlarmLostMed3Ico from './alarms/AlarmLostMed3Ico';
import AlarmEmerSensIco from './alarms/AlarmEmerSensIco';
import AlarmInactIco from './alarms/AlarmInactIco';
import AlarmFireIcon from './alarms/AlarmFireIcon';
import AlarmHighTemp from './alarms/AlarmHighTemp';
import AlarmLowTemp from './alarms/AlarmLowTemp';
import AlarmCO2Ico from './alarms/AlarmCO2Ico';
import AlarmLostFireIco from './alarms/AlarmLostFireIco';
import AlarmEnergyIco from './alarms/AlarmEnergyIco';
import AlarmBatConIcon from './alarms/AlarmBatConIcon';
import AlarmExpReminderIco from './alarms/AlarmExpReminderIco';
import AlarmAwayIco from './alarms/AlarmAwayIco';
import AlarmAtHomeIco from './alarms/AlarmAtHomeIco';
import AlarmLostSensorIco from './alarms/AlarmLostSensorIco';
import AlarmCancelIcon from './alarms/AlarmCancelIcon';
import AlarmAutoTestOkIco from './alarms/AlarmAutoTestOkIco';
import AlarmVoiceReqIco from './alarms/AlarmVoiceReqIco';
import AlarmAutoTestFailIco from './alarms/AlarmAutoTestFailIco';
import AlarmLowBatSensIco from './alarms/AlarmLowBatSensIco';
import AlarmMovementIco from './alarms/AlarmMovementIco';
import AlarmTiltIco from './alarms/AlarmTiltIco';

class AlarmIcon extends React.Component {

  alarmComponents = {
    '001': AlarmiHelpEmergency,
    '003': AlarmGeo1InIco,
    '004': AlarmGeo1OutIco,
    '005': AlarmGeo2InIco,
    '006': AlarmGeo2OutIco,
    '007': AlarmGeo3InIco,
    '008': AlarmGeo3OutIco,
    '009': AlarmGeo4InIco,
    '010': AlarmGeo4OutIco,
    '019': AlarmChargeDeviceIco,
    '042': AlarmLostMed1Ico,
    '045': AlarmLostMed2Ico,
    '048': AlarmLostMed3Ico,
    '051': AlarmLostMed3Ico,
    100: AlarmEmerConsIco,
    101: AlarmEmerSensIco,
    102: AlarmInactIco,
    111: AlarmFireIcon,
    158: AlarmHighTemp,
    159: AlarmLowTemp,
    162: AlarmCO2Ico,
    200: AlarmLostFireIco,
    301: AlarmEnergyIco,
    302: AlarmBatConIcon,
    356: AlarmExpReminderIco,
    371: AlarmAwayIco,
    372: AlarmAtHomeIco,
    381: AlarmLostSensorIco,
    384: AlarmLowBatSensIco,
    406: AlarmCancelIcon,
    602: AlarmAutoTestOkIco,
    606: AlarmVoiceReqIco,
    608: AlarmAutoTestFailIco,
    700: AlarmMovementIco,
    701: AlarmTiltIco,
  }

  render() {
    let Alarm = this.alarmComponents[this.props.alarmCode || '100'];
    if (!Alarm) {
      Alarm = this.alarmComponents['101'];
    }

    return (
      <Alarm
        height={this.props.height}
        svgClass={this.props.svgClass}
        width={this.props.width}
        fill={this.props.color}
        transform={this.props.transform}
        float={this.props.float}
      />
    );
  }
}

export default AlarmIcon;
