import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import AlarmIcon from '../../../../icons/AlarmIcon';
import { formatDate } from '../../../../BLL/general';
import AttendClientComponent from './AttendClientComponent';
import messagesAlarms from '../../../../BLL/locale/messagesAlarms';
import style from './attendDetail.module.scss';
import { trDescriptionLang } from '../../../../BLL/dictionary';

import '../../../../css/attendComponent.scss';

const AttendDetail = ({ client, alarms, lang, showIcons, secondary, transmitterList }) => {
  const highestAlarm = alarms && alarms.sort((a, b) => {
    if (a.event_type.priority === b.event_type.priority) {
      if (a.event_type.code === '606' && b.event_type.code !== '606') {
        return 1; // prioritize b higher than a
      } else if (a.event_type.code !== '606' && b.event_type.code === '606') {
        return -1; // prioritize a higher than b
      }
    }
    return a.event_type.priority - b.event_type.priority;
  })[0];
  const fillColor = {
    1: '#D8342B',
    2: '#F69B38',
    3: '#008D53',
    4: '#3D94CC',
  };
  return (
    <div className={style.row}>
      {highestAlarm &&
        <div className={style.row}>
          <AttendClientComponent client={client} secondary={secondary} transmitterList={transmitterList} highestAlarm={highestAlarm}/>
          {/* <AttendAccountDetails client={client} key={client.id} /> */}
          <div className="col-sm-12 col-md-4 col-lg-4">
            <span className={style['alarm-header']} >
              <FormattedMessage id="attend.detail.alarmDetailHeader" defaultMessage="DETALHES DO ALARME" />
            </span>
            <div style={{ textAlign: 'center' }} data-testid={`alarm-icon-${highestAlarm ? highestAlarm.event_type.code : '100'}`}>
              {highestAlarm && showIcons &&
                <AlarmIcon
                  alarmCode={highestAlarm ? highestAlarm.event_type.code : '100'}
                  color={fillColor[highestAlarm.event_type.priority]}
                  height={'8rem'}
                  width={'8rem'}
                  float={'none'}
                />
              }
            </div>
            {(showIcons || highestAlarm.event_type.code === '302' || highestAlarm.event_type.code === '384') &&
              <div className={style['alarm-row']} >
                <span className={style.label}>
                  <FormattedMessage id="attend.detail.alarmType" defaultMessage="TIPO" />
                </span>
                <span className={style.value}>
                  {messagesAlarms[lang][highestAlarm.event_type.code]}({highestAlarm.event_type.code})
                </span>
              </div>
            }
            {highestAlarm &&
              <div className={style['alarm-row']} >
                <span className={style.label}>
                  <FormattedMessage id="attend.detail.alarmCategory" defaultMessage="CATEGORIA" />
                </span>
                <span className={style.value}>
                  {highestAlarm.code.substring(6, 7) === '1'
                    ? <FormattedMessage id="attend.detail.eventCategory" defaultMessage="Evento" />
                    : <FormattedMessage id="attend.detail.restorationCategory" defaultMessage="Restauração" />
                  }
                </span>
              </div>
            }
            {highestAlarm && highestAlarm.tr_description &&
              <div className={style['alarm-row']} >
                <span className={style.label}>
                  <FormattedMessage id="attend.detail.alarmZone" defaultMessage="ZONA" />
                </span>
                <span className={style.value}>
                  {highestAlarm.tr_description
                    ? `(${highestAlarm.tr_zone}) ${trDescriptionLang(highestAlarm.tr_description)}`
                    :
                    <FormattedMessage
                      id="attend.detail.alarmNoRecord"
                      defaultMessage={'{alarmCode} - sem cadastro'}
                      values={{ alarmCode: highestAlarm.code.slice(12, 15) }}
                    />
                  }
                </span>
              </div>
            }
            <div className={style['alarm-row']} >
              <span className={style.label}>
                <FormattedMessage id="attend.detail.alarmDate" defaultMessage="DATA" />
              </span>
              <span className={style.value}>{formatDate(highestAlarm.timestamp, 'card')}</span>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

AttendDetail.propTypes = {
  lang: PropTypes.string.isRequired,
  client: PropTypes.shape({
    account: PropTypes.string,
    account_phone: PropTypes.string,
    aditional_phones: PropTypes.arrayOf(
      PropTypes.shape({
        client: PropTypes.number,
        id: PropTypes.number,
        number: PropTypes.string,
      }),
    ),
    birthdate: PropTypes.string,
    city: PropTypes.string,
    contact_phone: PropTypes.string,
    country: PropTypes.string,
    complement: PropTypes.string,
    cpf: PropTypes.string,
    created_by: PropTypes.number,
    created_date: PropTypes.string,
    district: PropTypes.string,
    first_name: PropTypes.string,
    general_info: PropTypes.string,
    health_plan: PropTypes.string,
    id: PropTypes.number,
    is_deleted: PropTypes.bool,
    key_box: PropTypes.string,
    last_name: PropTypes.string,
    modified_by: PropTypes.number,
    modified_date: PropTypes.string,
    number: PropTypes.number,
    rg: PropTypes.string,
    state: PropTypes.string,
    street: PropTypes.string,
    zip_code: PropTypes.string,
  }).isRequired,
  alarms: PropTypes.shape({
    id: PropTypes.number,
    code: PropTypes.string,
    type: PropTypes.string,
    timestamp: PropTypes.string,
    attend: PropTypes.number,
    event_type: PropTypes.shape({
      id: PropTypes.number,
      zone: PropTypes.string,
      client: PropTypes.number,
      description: PropTypes.string,
      identifier: PropTypes.string,
      general_info: PropTypes.string,
      created_date: PropTypes.string,
      modified_date: PropTypes.string,
      created_by: PropTypes.number,
      modified_by: PropTypes.number,
    }).isRequired,
    tr_zone: PropTypes.string,
    tr_identifier: PropTypes.string,
    tr_description: PropTypes.string,
    tr_gen_info: PropTypes.string,
  }).isRequired,
};

export default AttendDetail;
