import * as types from '../actions/actionTypes';
import initialState from './initialState';

/*
  Inputs:
    state = Estado atual do objeto, caso não seja passado é utilizado o estado default do arquivo(initalstate.groups)
    action = Ação aplicada sobre o objeto

  Types:
    LOAD_GROUPS_SUCCESS = Grupos de usuário carregados com sucesso

   groupsReducer:
    Caso os grupos sejam carregados com sucesso, retorna a listagem de grupos e usuários destes grupos
*/
export default function groupsReducer(state = initialState.groups, action) {
  switch (action.type) {
    case types.LOAD_GROUPS_SUCCESS:
      return [...action.groups];

    default:
      return state;
  }
}

export function getCompanies(state) {
  return state.groups.filter(grp => ![1, 2, 3, 4].includes(grp.id))
}
