import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { getDomain } from '../../BLL/environment';
import style from './rocketChat.module.scss'

const RocketChat = (props) => {
  useEffect(() => {
    window.addEventListener('message', (e) => {
      if (e.data && e.data.eventName === 'new-message') {
        props.toggleNewMessage();
      }
    });
  }, []);
  let realm;
  if (window.location.hostname === 'localhost' || window.location.hostname === '172.20.31.143') {
    realm = 'rocket-chat.test';
  } else if (window.location.hostname.indexOf('homologation') !== -1) {
    realm = 'rocket-chat-homologation.contactto.care';
  } else {
    realm = 'rocket-chat.contactto.care';
  }
  return (
    <div className={`${style.wrapper} ${props.showChat ?  style.isVisible : style.isHidden}`}>
      {props.rcToken ?
        <iframe
          title='rocket-chat'
          id="rcChannel"
          name="rcChannel"
          width="100%"
          height="100%"
          src={`https://${realm}/group/${getDomain()}?resumeToken=${props.rcToken}`}
        />
      :
      <div className={style.errorMessageWrapper}>
        <div className={style.errorTitle}> 
          <FormattedMessage id="chat.error.unavailable" defaultMessage="Opção indisponível no momento." />
        </div>
        <div className={style.errorText}>
          <FormattedMessage id="chat.error.toActivate" defaultMessage="Para ativar o seu chat, faça o logout da plataforma e acesse novamente." />
        </div>
        <div className={style.errorText}> 
          <FormattedMessage id="chat.error.loggingOut" defaultMessage="Para fazer logout, utilize o menu superior a direita - onde está o seu nome - e clique em Sair." />
        </div>
        <div className={style.errorText}>
          <FormattedMessage id="chat.error.supportEmail" defaultMessage="Se o chat permanecer indisponível, envie um email para suporte@tecnosenior.zendesk.com relatando a situação." />
        </div>
      </div>
      }

    </div>
  )
}

export default RocketChat
