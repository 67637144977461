import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import style from './reminderModal.module.scss';

const ReminderInfo = ({ reminder }) => (
  <div className={style['reminder-info-wrapper']}>
    <div className={style['reminder-info-container']}>
      <label className={style['reminder-text-label']} htmlFor="title">
        <FormattedMessage id="reminder.reminderAttendant" defaultMessage="Atendente" />
      </label>
      {reminder.op_full_name}
    </div>
    <div className={style['reminder-info-container']}>
      <label className={style['reminder-text-label']} htmlFor="title">
        <FormattedMessage id="reminder.reminderTime" defaultMessage="Tempo solicitado" />
      </label>
      {reminder.requested_time} <FormattedMessage id="attend.reminder.minutes" defaultMessage="Minutos" />
    </div>
    <div className={style['reminder-info-container']}>
      <label className={style['reminder-text-label']} htmlFor="title">
        <FormattedMessage id="reminder.reminderOpening" defaultMessage="Data de início" />
      </label>
      {moment(reminder.open_ts).format('DD/MM/YYYY HH:mm:ss')}
    </div>
    <div className={style['reminder-info-container']}>
      <label className={style['reminder-text-label']} htmlFor="title">
        <FormattedMessage id="reminder.reminderTitle" defaultMessage="Lembrete" />
      </label>
      {reminder.title}
    </div>
  </div>
);

ReminderInfo.propTypes = {
  reminder: PropTypes.shape({
    op_full_name: PropTypes.string,
    requested_time: PropTypes.number,
    open_ts: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

export default ReminderInfo;