import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { setMask } from '../../BLL/general';
import formStyle from '../../css/form.module.scss';

const TextInput = (props) => {
  const [value, setValue] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const onChange = (ev) => {
    let isValid = true;
    let errorMsg = '';
    let validate = undefined;
    let newValue = ev.target.value;
    let mask = undefined;
    let minLength = props.minLength;
    let oldValue = ev.target.value;

    if (props.mask) {
      if (typeof props.mask === 'string') {
        mask = props.mask;
        if (props.required && !minLength) {
          minLength = props.mask.length;
        }
      }  else if (Array.isArray(props.mask)) {
        const tempValue = oldValue.split('').filter(el => /\d/.test(el)).join('');
        const tempMask = props.mask[0].split('').filter(el => /\d/.test(el)).join('');
        mask = tempValue.length <= tempMask.length ? props.mask[0] : props.mask[1];
        if (props.required) {
          minLength = props.mask[0].length;
        }
      }

      const arr = oldValue.split('');
      const val = arr.filter(el => /\d/.test(el)).join('');
      newValue = setMask(val, mask);
    }

    if (props.validate) {
      validate = props.validate(newValue);
      if (Array.isArray(validate)) {
        isValid = false;
        errorMsg = validate[1];
      } else if (typeof validate === 'string') {
        newValue = validate;
      } else if (typeof validate === 'boolean') {
        isValid = validate;
      }
    }

    if (props.required && oldValue.length === 0) {
      isValid = false;
      errorMsg = 'Campo obrigatório';
    } else if (minLength > 1 && oldValue.length < minLength && oldValue.length > 0) {
      isValid = false;
      errorMsg = `Campo deve possuir pelo menos ${minLength} caracteres`;
    }

    if (props.name === 'zip_code') {
      const zipValue = oldValue.split('').filter(el => /\d/.test(el)).join('');
      if ((minLength > 1 && zipValue.length < minLength && zipValue.length > 0)) {
        isValid = false;
        errorMsg = `Campo deve possuir pelo menos ${minLength} caracteres`;
      }
    }

    const event = {
      target: {
        name: ev.target.name,
        value: newValue,
      },
    };

    props.onChange(event, isValid);

    setErrorMessage(errorMsg);
    setHasError(!isValid);
  };

  const setMaxLength = () => {
    if (props.mask) {
      if (typeof props.mask === 'string') {
        return props.mask.length;
      } else if (Array.isArray(props.mask)) {
        // return props.mask[1].length;
        return props.mask.reduce((a, b) => (a.length > b.length ? a : b)).length;
      }
    }
    return props.maxLength;
  };

  const maxLength = setMaxLength();

  return (
    <div>
      <input
        id={props.id}
        className={hasError ? props.errorClass : props.inputClass}
        type={props.type}
        name={props.name}
        placeholder={props.text}
        disabled={props.disabled}
        maxLength={maxLength}
        onChange={onChange}
        value={value}
        onPaste={(e) => { props.onPaste ? e.preventDefault() : e; }}  // eslint-disable-line no-unused-expressions
        onDrop={(e) => { props.onDrop ? e.preventDefault() : e; }}  // eslint-disable-line no-unused-expressions
      />
      {hasError &&
        <div
          className={`${formStyle.message} ${props.textErrorClass}`}
          style={{ position: 'relative' }}
        >
          {errorMessage}
        </div> }
    </div>
  );
};

TextInput.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.node.isRequired,
  onPaste: PropTypes.bool,
  onDrop: PropTypes.bool,
  text: PropTypes.string,
  disabled: PropTypes.string,
  inputClass: PropTypes.string,
  errorClass: PropTypes.string,
  textErrorClass: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  mask: PropTypes.node,
  required: PropTypes.bool,
  validate: PropTypes.func,
};

export default TextInput;
