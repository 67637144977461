import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';

import style from './trial.module.scss';

const Trial = ({ activate, isAdmin, isOwner }) => (
  <div className={style.content}>
    <div className={style.trial}><FormattedMessage id="header.trial.info" defaultMessage=" VERSÃO DE TESTES " /></div>
    {(isAdmin || isOwner) &&
      <button className={style.btn} onClick={activate}><FormattedMessage id="header.trial.sign" defaultMessage=" ASSINAR " /></button>
    }
  </div>
);


Trial.propTypes = {
  activate: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool.isRequired,
};

export default Trial;
