import { useQuery } from 'react-apollo';
import { GET_ONLINE_USERS } from './OnlineQueries';

function useAllOnlineUsers(token, ordering) {
  const { data, loading, error } = useQuery(GET_ONLINE_USERS, {
    variables: { token, ordering },
    skip: !token,
    pollInterval: 30000
  });

  return {
    data: data?.allUsersOnlineStatus,
    loading,
    error
  }
};

export default useAllOnlineUsers;
