import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ev07bAlarmClockAudio } from '../../../../BLL/dictionary';
import CheckboxInput from '../../../Common/CheckboxInput';
import SelectInput from '../../../Common/SelectInput2';
import ToggleButton from '../../../Common/ToggleButton';

import formStyle from '../../../../css/form.module.scss';
import style from './alarmClock.module.scss';

const TrackerEv07bAlarmClock = (props) => {
  const [openedAlarmClock, setOpenedAlarmClock] = useState(0);
  const [alarmClockList, setAlarmClockList] = useState([]);

  useEffect(() => {
    if (props.alarmClockList?.length > 0) {
      setAlarmClockList(props.alarmClockList);
    }
  }, [props.alarmClockList]);

  if (alarmClockList.length === 0) {
    return (
      <div>
        Carregando
      </div>
    );
  }

  const setParameters = (ev) => {
    const name = ev.target.name.split('.')[0];
    const index = parseInt(ev.target.name.split('.')[1], 10);
    let alarmClock = alarmClockList.find(alm => alm.index === index);
    alarmClock[name] = ev.target.value;
    const newAlarmClockList = alarmClockList.map(alm => {
      return alm.index === index ? alarmClock : alm
    });
    setAlarmClockList(newAlarmClockList);
  };

  const setDays = (ev) => {
    const day = ev.target.name.split('.')[0];
    const index = parseInt(ev.target.name.split('.')[1], 10);
    let alarmClock = alarmClockList.find(alm => alm.index === index);
    alarmClock.days[day] = ev.target.value;
    const newAlarmClockList = alarmClockList.map(alm => {
      return alm.index === index ? alarmClock : alm
    });
    setAlarmClockList(newAlarmClockList);
  }

  const handleToggle = (index) => {
    let alarmClock = alarmClockList.find(rem => rem.index === index);
    alarmClock.enabled = !alarmClock.enabled;
    const newAlarmClockList = alarmClockList.map(geo => {
      return geo.index === index ? alarmClock : geo
    });
    setAlarmClockList(newAlarmClockList);
  };

  const saveAlarmClock = () => {
    const data = {
      IMEI: props.imei,
      SET_ALARM_CLOCK: true,
      ALARM_CLOCK: alarmClockList
    };
    props.sendConfiguration(data);
  };

  return (
    <div className={style.menuContainer}>
      {alarmClockList.map(alarmClock =>
        <div className="col-12" key={alarmClock.index}>
          <button className={style.menuOptionBtn} onClick={() => setOpenedAlarmClock(alarmClock.index)}>
            <span className={style.menuOptionBtnText}>
              <FormattedMessage id="client.tracker.reminder" defaultMessage="Lembrete" /> {alarmClock.index + 1}
              <div className={`${style.menuTriangle} ${openedAlarmClock === alarmClock.index ? style.rotate : ''}`}/>
            </span>
          </button>
          {openedAlarmClock === alarmClock.index &&
            <div className="col-12" style={{fontSize: '.9rem'}}>
              <div className="row">
                <div className={`col-md-6 ${style.submenuOption}`}>
                  <span className={style.submenuText}>
                    <FormattedMessage id="client.tracker.enabled" defaultMessage="Habilitado" />:
                  </span>
                  <div className={style.toggleContainer}>
                    <ToggleButton
                      text=""
                      isChecked={alarmClock.enabled}
                      hasPagination={false}
                      handleChange={() => handleToggle(alarmClock.index)}
                    />
                  </div>
                </div>
                <div className={`col-md-6 ${style.submenuOption}`}>
                  <span className={style.submenuText}>
                    <FormattedMessage id="client.tracker.medicationTimer" defaultMessage="Horário" />:
                  </span>
                  <input
                    name={`time.${alarmClock.index}`}
                    id={`time.${alarmClock.index}`}
                    type="time"
                    className={`${style.input} form-control`}
                    value={alarmClock.time}
                    onChange={setParameters}
                  />
                </div>
              </div>
              <div className="row">
                <div className={`col-md-6 ${style.submenuOption}`}>
                  <span className={style.submenuText}>
                    <FormattedMessage id="client.tracker.audioSelection" defaultMessage="Seleção de Áudio" />:
                  </span>
                  <SelectInput
                    dictionary={ev07bAlarmClockAudio}
                    items={[1, 2, 3, 4]}
                    selectClass={`${style.selectInput} ${
                      formStyle['select--enable']
                      } custom-select`}
                    value={alarmClock.ring_audio}
                    onChange={setParameters}
                    name={`ring_audio.${alarmClock.index}`}
                    id={`ring_audio.${alarmClock.index}`}
                    emptyOption={false}
                  />
                </div>
                <div className={`col-md-6 ${style.submenuOption}`}>
                  <span className={style.submenuText}>
                    <FormattedMessage id="client.tracker.audioDuration" defaultMessage="Duração do Áudio" />:
                  </span>
                  <SelectInput
                    items={['10', '20', '30', '40', '50', '60', '70', '80', '90', '100', '110', '120']}
                    selectClass={`${style.selectInput} ${
                      formStyle['select--enable']
                      } custom-select`}
                    value={alarmClock.ring_time}
                    onChange={setParameters}
                    name={`ring_time.${alarmClock.index}`}
                    id={`ring_time.${alarmClock.index}`}
                    emptyOption={false}
                  />
                </div>
              </div>
              <div className="row">
                <div className={`col-md-12 ${style.submenuOption}`}>
                  <span className={style.submenuText}>
                    <FormattedMessage id="client.tracker.daySelection" defaultMessage="Repetir nos dias" />
                  </span>
                </div>
                <div className="col-md-12">
                  <div className={style.workdayContainer}>
                    <span style={{ float: 'left', marginRight: '5px' }}>
                      <FormattedMessage id="client.tracker.monAbbrv" defaultMessage="SEG" />
                    </span>
                    <CheckboxInput name={`mon.${alarmClock.index}`} className="form-control" checked={alarmClock.days.mon} onChange={setDays} />
                  </div>
                  <div className={style.workdayContainer}>
                    <span style={{ float: 'left', marginRight: '5px' }}>
                      <FormattedMessage id="client.tracker.tueAbbrv" defaultMessage="TER" />
                    </span>
                    <CheckboxInput name={`tue.${alarmClock.index}`} className="form-control" checked={alarmClock.days.tue} onChange={setDays} />
                  </div>
                  <div className={style.workdayContainer}>
                    <span style={{ float: 'left', marginRight: '5px' }}>
                      <FormattedMessage id="client.tracker.wedAbbrv" defaultMessage="QUA" />
                    </span>
                    <CheckboxInput name={`wed.${alarmClock.index}`} className="form-control" checked={alarmClock.days.wed} onChange={setDays} />
                  </div>
                  <div className={style.workdayContainer}>
                    <span style={{ float: 'left', marginRight: '5px' }}>
                      <FormattedMessage id="client.tracker.thuAbbrv" defaultMessage="QUI" />
                    </span>
                    <CheckboxInput name={`thu.${alarmClock.index}`} className="form-control" checked={alarmClock.days.thu} onChange={setDays} />
                  </div>
                  <div className={style.workdayContainer}>
                    <span style={{ float: 'left', marginRight: '5px' }}>
                      <FormattedMessage id="client.tracker.friAbbrv" defaultMessage="SEX" />
                    </span>
                    <CheckboxInput name={`fri.${alarmClock.index}`} className="form-control" checked={alarmClock.days.fri} onChange={setDays} />
                  </div>
                  <div className={style.workdayContainer}>
                    <span style={{ float: 'left', marginRight: '5px' }}>
                      <FormattedMessage id="client.tracker.satAbbrv" defaultMessage="SAB" />
                    </span>
                    <CheckboxInput name={`sat.${alarmClock.index}`} className="form-control" checked={alarmClock.days.sat} onChange={setDays} />
                  </div>
                  <div className={style.workdayContainer}>
                    <span style={{ float: 'left', marginRight: '5px' }}>
                      <FormattedMessage id="client.tracker.sunAbbrv" defaultMessage="DOM" />
                    </span>
                    <CheckboxInput name={`sun.${alarmClock.index}`} className="form-control" checked={alarmClock.days.sun} onChange={setDays} />
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginBottom: '15px'}}>
                <button className={style.menuSaveBtn} onClick={saveAlarmClock}>
                  <span>
                    <i
                      className="far fa-save fa-lg"
                      aria-hidden="true"
                      style={{ paddingRight: '0.5rem', paddingTop: '0.2rem' }}
                    />
                  </span>
                  <FormattedMessage id="client.tracker.saveReminder" defaultMessage="Salvar Lembrete" />
                </button>
              </div>
            </div>

          }
        </div>
      )}
    </div>
  )
}

TrackerEv07bAlarmClock.propTypes = {
  sendConfiguration: PropTypes.func.isRequired,
  imei: PropTypes.string.isRequired,
  alarmClockList: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number,
      ring_time: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      ring_audio: PropTypes.number,
      time: PropTypes.string,
      enabled: PropTypes.bool,
      days: PropTypes.shape({
        mon: PropTypes.bool,
        tue: PropTypes.bool,
        wed: PropTypes.bool,
        thu: PropTypes.bool,
        fri: PropTypes.bool,
        sat: PropTypes.bool,
        sun: PropTypes.bool,
      }),
    }),
  ).isRequired,
};

export default TrackerEv07bAlarmClock;
