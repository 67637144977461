import React from 'react';
import PropTypes from 'prop-types';

const AlarmExpReminderIco = ({ height, svgClass, width, fill, transform }) => (
  <svg className={svgClass} width={width} height={height} fill={fill} transform={transform} viewBox="0 0 140 140" xmlns="http://www.w3.org/2000/svg">
<g id="oIZjXW.tif">
<g>
<g>
<path d="M142.1,16.7c-2,2-4,4.1-6,6.1c-0.3,0.3-1.4,0.3-1.7,0.1c-1.4-1.2-2.8-2.6-4-4c-0.3-0.3-0.2-1.3,0.1-1.7
c1.7-1.9,3.5-3.6,5.3-5.4c0.6-0.6,1.2-0.6,1.8,0c1.5,1.5,3,3.1,4.5,4.6C142.1,16.5,142.1,16.6,142.1,16.7z"/>
<path d="M78,45.2c-0.3-16.4,13.5-30.6,30.7-30.6c17,0,31,14.3,30.6,31.4c-0.4,16.3-14,30-30.8,29.9C91.7,76,77.6,61.9,78,45.2z
 M107.2,21.3C96.3,20.8,82.9,34.4,84.8,44c1.2,0,2.4,0,3.6,0c0,0.5,0,0.8,0.1,1.1c0.2,1.3-0.3,1.8-1.6,1.6c-0.7-0.1-1.5,0-2.2,0
c0.4,12.6,11.8,22.6,22.6,22.5c0-1-0.3-2.1,0.1-3.1c0.5-1.4,1.7-0.4,2.8-0.6c0,1.4,0,2.6,0,3.8c11.7-0.3,22.2-10.5,22.7-22.6
c-1.2,0-2.5,0-3.8,0c0-1,0-1.8,0-2.8c1.3,0,2.5,0,3.8,0C132.3,31.5,121,21.3,110,21.4c0,1.2,0,2.4,0,3.7c-0.5,0-0.8,0-1.1,0.1
c-1.2,0.2-1.9-0.2-1.7-1.6C107.3,22.8,107.2,22.1,107.2,21.3z"/>
<path d="M106.1,12.4c0-0.7-0.1-1.3-0.1-2.2c-1.6,0-3.3,0-4.9,0c-1.3,0-1.9-0.5-1.9-1.8c0-2.2,0-4.3,0-6.5c0-1.3,0.5-1.9,1.8-1.9
c5.3,0,10.6,0,15.9,0c1.2,0,1.7,0.6,1.7,1.7c0,2.3,0,4.5,0,6.8c0,1.2-0.6,1.8-1.8,1.7c-1.6,0-3.3,0-4.9,0
c-0.1,0.8-0.1,1.4-0.2,2.2C109.8,12.4,108,12.4,106.1,12.4z"/>
<path d="M121.6,32.1c-0.2,0.4-0.2,0.8-0.5,1.2c-2.4,3.1-4.9,6.2-7.3,9.3c-0.4,0.5-0.6,1.2-0.6,1.9c0,2.4-1.8,4.5-4.2,4.5
c-2.4,0-4.4-2-4.3-4.4c0.1-2.5,2.3-4.3,4.7-4.2c0.6,0,1.4-0.4,1.9-0.8c3-2.3,5.9-4.7,8.8-7c0.3-0.3,0.7-0.5,1-0.7
C121.3,32,121.4,32.1,121.6,32.1z"/>
</g>
</g>
</g>
<path d="M76.8,53.4c-0.1,0-0.1,0-0.2,0c-2.5,0-5.1,0-7.6,0c-0.4,0-0.8,0-1.3,0c0-3.3,0-6.4,0-9.5c0-1.7-0.9-2.7-2.6-2.8
c-2.4-0.1-4.7-0.1-7.1,0c-1.7,0-2.6,1-2.6,2.8c0,1.8,0,3.6,0,5.5c0,1.3,0,2.6,0,3.9c-8.2,0-16.2,0-24.3,0c0-3.1,0-6.2,0-9.2
c0-2-0.9-2.9-2.9-3c-2.1,0-4.2,0-6.4,0c-2.1,0-3,0.9-3,3c0,3,0,6.1,0,9.3c-3.1,0-5.9,0-8.7,0c-5.7,0-9.6,3.9-9.6,9.6
c0,22.1,0,44.1,0,66.2c0,5.7,3.8,9.7,9.5,9.7c22.2,0,44.3,0,66.5,0c5.7,0,9.5-4,9.5-9.7c0-19.8,0-39.6,0-59.4
C81.3,65.5,78,59.8,76.8,53.4z M76.7,73.2c0,18.2,0,36.3,0,54.5c0,1.7-0.3,2-2,2c-21,0-42,0-63,0c-1.7,0-2-0.3-2-1.8
c0-18.3,0-36.6,0-54.9c0-0.3,0-0.7,0.1-1.1c22.4,0,44.6,0,67,0C76.7,72.3,76.7,72.7,76.7,73.2z"/>
<g>
<path d="M30.4,91.1c0,1.3,0,2.5,0,3.8c0,1.3-0.9,2.3-2.2,2.3c-2.6,0.1-5.3,0.1-7.9,0c-1.3,0-2.2-1-2.2-2.3c0-2.5,0-5,0-7.5
c0-1.4,0.9-2.4,2.3-2.4c2.6,0,5.2,0,7.8,0c1.4,0,2.2,1,2.2,2.4C30.4,88.7,30.4,89.9,30.4,91.1z"/>
<path d="M48.7,91.2c0,1.2,0,2.4,0,3.6c0,1.6-0.9,2.5-2.4,2.5c-2.5,0-5,0-7.5,0c-1.5,0-2.4-1-2.4-2.4c0-2.5,0-4.9,0-7.4
c0-1.5,0.9-2.4,2.3-2.5c2.5,0,5.1,0,7.6,0c1.4,0,2.3,1,2.3,2.5C48.7,88.7,48.7,89.9,48.7,91.2z"/>
<path d="M54.7,91c0-1.2,0-2.4,0-3.6c0-1.5,0.9-2.4,2.3-2.5c2.5,0,5.1,0,7.6,0c1.4,0,2.3,1,2.3,2.5c0,2.5,0,4.9,0,7.4
c0,1.5-0.9,2.4-2.4,2.4c-2.5,0-5,0-7.5,0c-1.5,0-2.4-1-2.4-2.5C54.7,93.5,54.7,92.3,54.7,91z"/>
<path d="M48.7,109.5c0,1.2,0,2.4,0,3.6c0,1.6-0.9,2.5-2.4,2.5c-2.5,0-5,0-7.5,0c-1.5,0-2.4-1-2.4-2.4c0-2.5,0-5,0-7.5
c0-1.3,0.9-2.3,2.2-2.3c2.6-0.1,5.3-0.1,7.9,0c1.3,0,2.2,1,2.2,2.3C48.7,106.9,48.7,108.2,48.7,109.5z"/>
<path d="M54.7,109.4c0-1.2,0-2.4,0-3.6c0-1.6,0.9-2.5,2.4-2.5c2.5,0,5,0,7.5,0c1.5,0,2.4,1,2.4,2.4c0,2.5,0,4.9,0,7.4
c0,1.5-0.9,2.4-2.3,2.5c-2.5,0-5.1,0-7.6,0c-1.4,0-2.3-1-2.3-2.5C54.7,111.9,54.7,110.6,54.7,109.4z"/>
<path d="M30.4,109.4c0,1.3,0,2.5,0,3.8c0,1.3-0.9,2.3-2.2,2.3c-2.6,0.1-5.3,0.1-7.9,0c-1.3,0-2.2-1-2.2-2.3c0-2.5,0-5,0-7.5
c0-1.4,0.9-2.4,2.3-2.4c2.6-0.1,5.2-0.1,7.7,0c1.4,0,2.2,1,2.3,2.4c0,0.6,0,1.3,0,1.9C30.4,108.2,30.4,108.8,30.4,109.4z"/>
</g>
  </svg>
);

AlarmExpReminderIco.defaultProps = {
  height: '75',
  svgClass: '',
  width: '75',
  fill: '#F69B38',
  transform: 'translate(0,0)',
};

AlarmExpReminderIco.propTypes = {
  height: PropTypes.string,
  svgClass: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
  transform: PropTypes.string,
};

export default AlarmExpReminderIco;
