import React from 'react';
import PropTypes from 'prop-types';

const AlarmAwayIco = ({ height, svgClass, width, fill, transform }) => (
  <svg className={svgClass} width={width} height={height} fill={fill} transform={transform} viewBox="0 0 140 140" xmlns="http://www.w3.org/2000/svg">
<g>
	<g>
		<path d="M77.6,44.2c-0.5,0-1-0.2-1.4-0.5L56.5,27.9L37,43.7c-1,0.8-2.4,0.6-3.2-0.3c-0.8-1-0.6-2.4,0.3-3.2l20.9-16.9
			c0.8-0.7,2-0.7,2.9,0L79,40.1c1,0.8,1.1,2.2,0.4,3.2C78.9,43.9,78.3,44.2,77.6,44.2z"/>
	</g>
	<g>
		<path d="M21.3,70.4C21.3,70.4,21.3,70.4,21.3,70.4L3.2,70.3c-1,0-1.8-0.6-2.2-1.5s0-1.9,0.7-2.5l18.1-14.6c1-0.8,2.4-0.6,3.2,0.3
			c0.8,1,0.6,2.4-0.3,3.2L9.6,65.7l11.7,0.1c1.3,0,2.3,1,2.3,2.3C23.6,69.4,22.6,70.4,21.3,70.4z"/>
	</g>
	<g>
		<path d="M110.6,70.7C110.6,70.7,110.6,70.7,110.6,70.7l-18.1-0.1c-1.3,0-2.3-1-2.3-2.3c0-1.3,1-2.3,2.3-2.3c0,0,0,0,0,0l11.5,0.1
			l-13-10.4c-1-0.8-1.1-2.2-0.4-3.2c0.8-1,2.2-1.1,3.2-0.4L112,66.6c0.8,0.6,1.1,1.6,0.7,2.6C112.4,70.1,111.6,70.7,110.6,70.7z"/>
	</g>
	<g>
		<path d="M21.3,55.7c-0.7,0-1.3-0.3-1.8-0.9c-0.8-1-0.6-2.4,0.4-3.2l14.3-11.5c1-0.8,2.4-0.6,3.2,0.4c0.8,1,0.6,2.4-0.4,3.2
			L22.7,55.2C22.3,55.5,21.8,55.7,21.3,55.7z"/>
	</g>
	<g>
		<path d="M92.5,56.2c-0.5,0-1-0.2-1.4-0.5l-14.9-12c-1-0.8-1.1-2.2-0.3-3.2c0.8-1,2.2-1.1,3.2-0.3l14.9,12c1,0.8,1.1,2.2,0.3,3.2
			C93.8,55.9,93.2,56.2,92.5,56.2z"/>
	</g>
	<g>
		<path d="M92.5,118.6H21.3c-1.3,0-2.3-1-2.3-2.3V68.1c0-1.3,1-2.3,2.3-2.3s2.3,1,2.3,2.3V114h66.6V68.3c0-1.3,1-2.3,2.3-2.3
			s2.3,1,2.3,2.3v48C94.8,117.6,93.8,118.6,92.5,118.6z"/>
	</g>
</g>
<g id="kIU9vP.tif_1_">
	<g>
		<g>
			<path d="M119.9,106.6c0-1.6,0-3.3,0-4.9c0.1-2.1,0.1-3.9-2.2-5.2c-1-0.6-1.7-2.5-1.8-3.9c-0.4-4.6-0.5-9.3-0.5-14
				c0-5.2,2.9-7.7,8.1-7.3c2.7,0.2,5.5,0.2,8.2,0c5.2-0.5,8.3,2.1,8.3,7.4c0,4,0.3,8.1-0.3,12c-0.3,2.3-2,4.5-3.1,6.7
				c-0.4,0.9-1.1,1.8-1.1,2.7c-0.1,4.2,0,8.5-0.1,12.7c-0.1,3.9-1.3,5-5.1,5.1c-2.1,0-4.1,0-6.2,0c-2.8,0-4.2-1.5-4.3-4.2
				C119.8,111.4,119.9,109,119.9,106.6z"/>
			<path d="M119.8,59.8c0.1-4.5,3.8-8.2,8.2-8c3.9,0.1,7.8,4.1,7.8,8.1c0.1,4.2-4.2,8.5-8.4,8.2C123.6,67.8,119.2,64.5,119.8,59.8z"
				/>
		</g>
	</g>
</g>
  </svg>
);

AlarmAwayIco.defaultProps = {
  height: '75',
  svgClass: '',
  width: '75',
  fill: '#3D94CC',
  transform: 'translate(0,0)',
};

AlarmAwayIco.propTypes = {
  height: PropTypes.string,
  svgClass: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
  transform: PropTypes.string,
};

export default AlarmAwayIco;
