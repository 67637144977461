import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import { validateEmail, usernameReg } from '../../../BLL/validate';
import { userActionPriority } from '../../../BLL/dictionary';
import { getDomain, host, getToken } from '../../../BLL/environment';
import InputContainer from '../../Common/InputContainer';
import SelectInput from '../../Common/SelectInput2'
import CheckboxInput from '../../Common/CheckboxInput'
import ModalPasswordChange from '../../Modal/ModalPasswordChange';
import ErrorComponent from '../../Common/ErrorComponent';
import broadcastMessages from '../../../BLL/locale/messagesBroadcast';
import countryList from '../../../BLL/locale/messagesCountries';
import * as general from '../../../BLL/general';
import * as attendantsActions from '../../../actions/attendantsActions';
import * as authActions from '../../../actions/authActions';
import * as groupsActions from '../../../actions/groupsActions';

import detail from '../../../css/detail.module.scss';
import formStyle from '../../../css/form.module.scss';
import style from './userForm.module.scss';
import '../../../css/block.scss';
import '../../../css/formPage.scss';
import '../../../css/contactto.scss';

const UserForm = (props) => {
  const {
    loadAttendants,
    loadCompanies,
    attendants,
    match,
    isAdmin,
    isOwner,
    isManager,
    loggedAttendantId,
    company,
  } = props;
  const { userId } = match.params;
  const [attendant, setAttendant] = useState({
    id: 0,
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password2: '',
    is_active: false,
    is_staff: false,
    is_superuser: false,
    user_info: {
      mobile: '',
      country: 'BR',
    },
    groups: [3],
  });
  const [actions, setActions] = useState([
    {
      priority: 1,
      receive_sms: false,
      receive_email: false,
    },
    {
      priority: 2,
      receive_sms: false,
      receive_email: false,
    },
    {
      priority: 3,
      receive_sms: false,
      receive_email: false,
    },
    {
      priority: 4,
      receive_sms: false,
      receive_email: false,
    },
  ]);
  const [validator, setValidator] = useState({
    groups: { validate: true, message: '' },
    email: { validate: true, message: '' },
    first_name: { validate: true, message: '' },
    last_name: { validate: true, message: '' },
    password: { validate: true, message: '' },
    password2: { validate: true, message: '' },
    mobile: { validate: true, message: '' },
    country: { validate: true, message: '' },
    username: { validate: true, message: '' },
  });
  const [uniqueFields, setUniqueFields] = useState([]);
  const [showChangePasswordModal, setShowPasswordModal] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [showLogoutMessage, setShowLogoutMessage] = useState(false);
  const [saveError, setSaveError] = useState({
    message: '',
    code: 0,
    hasError: false,
  });

  useEffect(() => {
    if (props.match.params.method !== 'add' && props.match.params.method !== 'edit') {
      props.history.push('/app');
    } else if (props.match.params.method === 'edit' && !props.match.params.userId) {
      props.history.push('/app');
    }
  }, [props.match.params, props.history]);

  useEffect(() => {
    const setUpAttendant = (attendants, userId) => {
      const attendantsList = attendants[0].data;
      const { method } = match.params;
      const filteredAttendants =
      method === 'edit'
        ? attendantsList.filter(attendant => attendant.id !== parseInt(userId, 10))
        : attendantsList;
      const uniqueFields = filteredAttendants.map(atd => ({
        email: atd.email,
        username: atd.username,
      }));
      setUniqueFields(uniqueFields);

      if (method === 'edit') {
        const newAttendant = attendantsList.filter(a => a.id === parseInt(userId, 10))[0];
        const mobile = general.setMask(newAttendant.user_info.mobile, '(00) 0 0000-0000');
        const userInfo = Object.assign({}, newAttendant.user_info, { mobile });
        setAttendant(Object.assign(
          {},
          newAttendant,
          { user_info: userInfo },
          { password2: newAttendant.password },
        ));
        setActions([...newAttendant.actions]);
      }
    };

    if (attendants.length <= 0) {
      loadAttendants();
    } else {
      setUpAttendant(attendants, match.params.userId)
    }

  }, [attendants, loadAttendants, match.params]);

  useEffect(() => {
    if (!company) {
      loadCompanies();
    }
  }, [loadCompanies, company]);

  const setForm = (ev) => {
    const name = ev.target.name;
    const value = ev.target.value;
    
    setFormValue({ name, value });
    setFormValidator({ name, value });
  };

  const setFormValue = ({ name, value }) => {
    let newAttendant = attendant;

    switch (name) {
      case 'groups': {
        newAttendant[name] = [value];
        break;
      }
      case 'mobile': {
        newAttendant.user_info[name] = general.CellNumber(value);
        break;
      }
      case 'country': {
        newAttendant.user_info[name] = value;
        break;
      }
      default: {
        newAttendant[name] = value;
        break;
      }
    }

    setAttendant(Object.assign({}, newAttendant));
  };

  const setFormValidator = ({ name, value }) => {
    let newValidator = validator;

    switch (name) {
      case 'email': {
        const validate = validateEmail(value);
        newValidator[name].validate = validate;
        if (!validate) {
          newValidator[name].message = <FormattedMessage id="attendant.error.invalidEmail" defaultMessage="Email inválido" />;
        } else if (uniqueFields.findIndex(el => el.email === value) !== -1) {
          newValidator[name].validate = false;
          newValidator[name].message = <FormattedMessage id="attendant.error.emailUsed" defaultMessage="Email já cadastrado, por favor digite outro" />;
        } else {
          newValidator[name].validate = true;
          newValidator[name].message = '';
        }
        break;
      }
      case 'first_name':
      case 'last_name': {
        const validate = value.length >= 2;
        newValidator[name].validate = validate;
        if (!validate) {
          newValidator[name].message = <FormattedMessage id="error.atLeastTwoChar" defaultMessage="Campo deve possuir pelo menos dois caracteres" />;
        } else {
          newValidator[name].validate = true;
          newValidator[name].message = '';
        }
        break;
      }
      case 'username': {
        if (value.length < 2) {
          newValidator[name].validate = false;
          newValidator[name].message = <FormattedMessage id="error.atLeastTwoChar" defaultMessage="Campo deve possuir pelo menos dois caracteres" />;          
          break;
        }
        if (!usernameReg.test(value)) {
          newValidator[name].validate = false;
          newValidator[name].message =
            <FormattedMessage id="attendant.error.usernameIncorrect" defaultMessage="Campo deve ser escrito no formato 'nome' ou 'nome.outronome'" />;
        } else if (uniqueFields.findIndex(el => el.username === value) !== -1) {
          newValidator[name].validate = false;
          newValidator[name].message = <FormattedMessage id="attendant.error.usernameUsed" defaultMessage="Nome de usuário já cadastrado, por favor digite outro" />;
        } else {
          newValidator[name].validate = true;
          newValidator[name].message = '';
        }
        break;
      }
      case 'password': {
        const validate = value.length >= 8;
        newValidator[name].validate = validate;
        if (!validate) {
          newValidator[name].message = <FormattedMessage id="attendant.error.passwordEightCharacters" defaultMessage="Campo deve possuir pelo menos 8 caracteres" />;
        } else {
          const validateConfirm = value === attendant.password2;
          newValidator.password2.validate = validateConfirm;
          if (!validateConfirm) {
            newValidator.password2.message = <FormattedMessage id="attendant.error.passwordMustBeEqual" defaultMessage="Campo deve ser igual ao campo de senha" />;
          }
        }
        break;
      }
      case 'password2': {
        const validate = value === attendant.password;
        newValidator[name].validate = validate;
        if (!validate) {
          newValidator[name].message = <FormattedMessage id="attendant.error.passwordMustBeEqual" defaultMessage="Campo deve ser igual ao campo de senha" />;
        }
        break;
      }
      case 'mobile': {
        const validate = general.removeNonDigit(value).length === 11;
        newValidator[name].validate = validate;
        if (!validate) {
          newValidator[name].message = <FormattedMessage id="error.cellPhoneNumbers" defaultMessage="Campo deve possuir 11 números" />;
        }
        break;
      }
      default: {
        newValidator[name].validate = true;
        break;
      }
    }

    setValidator(Object.assign({}, newValidator));
  };

  const handleActions = (event, index) => {
    const name = event.target.name;
    const value = event.target.value;

    setActions(actions.map((el, i) => {
      if (i === index) {
        return Object.assign({}, el, { [name]: value });
      }
      return Object.assign({}, el);
    }));
  };

  const processError = (err) => {
    const response = err;
    if (response) {
      const errorCode = response.status;
      let errorMsg = '';
      if (response.data) {
        const dataKey = Object.keys(response.data)[0];
        switch (dataKey) {
          case 'email': {
            errorMsg = <FormattedMessage  id="user.error.email" defaultMessage="este email já está sendo usado por outro atendente ou familiar-adm" />
            break;
          }
          case 'username': {
            errorMsg = <FormattedMessage  id="user.error.username" defaultMessage="este login já está sendo usado por outro atendente ou familiar-adm" />
            break;
          }
          default: {
            errorMsg = response.data[dataKey][0];
            break;
          }
        }
      }
      setSaveError({
        message: errorMsg,
        code: errorCode,
        hasError: true
      });
    }
  };

  const performSave = () => {
    const mobile = general.removeNonDigit(attendant.user_info.mobile, 'join');
    const userInfo = Object.assign(
      {},
      general.removeByKey(attendant.user_info, ['email', 'user', 'extension_id']),
      { mobile },
    );
    const removeFields = props.match.params.method === 'add' ? ['password2', 'id'] : ['password2'];
    const serverAttendant = Object.assign(
      {},
      general.removeByKey(attendant, removeFields),
      { groups: [1, company.id] }, // If company user is adding, this will not be used, check serializer
      { user_info: userInfo },
      { actions },
    );

    const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
    const text = `${broadcastMessages[lang]['broadcast.attendant']} ${serverAttendant.first_name} ${serverAttendant.last_name} ${match.params.method === 'edit'
      ? broadcastMessages[lang]['broadcast.updated']
      : broadcastMessages[lang]['broadcast.added']}`;

    
    if (props.match.params.method === 'edit') {
      props.editAttendant(serverAttendant).then(() => {
        general.broadcast(axios, props.channelId, {
          type: 'attendant',
          method: 'edit',
          userId: loggedAttendantId,
          text,
          serverAttendant
        });
        props.history.push('/app/manage/companies');
      }).catch((err) => processError(err));
    } else {
      props.createAttendant(serverAttendant).then((res) => {
        general.broadcast(axios, props.channelId, {
          type: 'attendant',
          method: 'create',
          userId: loggedAttendantId,
          attendant: res.attendant,
          text
        });
        props.history.push('/app/manage/companies');
      }).catch((err) => processError(err));
    }
  };

  const changePassword = (passwords) => {
    axios.put(`https://${getDomain()}${host()}/api/password-change/`,
      passwords,
      { headers: { Authorization: `Token ${getToken()}` } })
      .then((res) => {
        setShowPasswordModal(false);
        setShowLogoutMessage(true);
        setTimeout(() => {
          logout();
        }, 3000);
      })
      .catch((err) => {
        if (err.response.data.old_password) {
          setPasswordError(true);
        }
      });
  };

  const logout = () => {
    props.authLogoutAndRedirect().then(() => {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('status');
      localStorage.removeItem('is_paid');
      props.history.push('/login');
      window.location.reload();
    });
  };

  const title = props.match.params.method === 'edit' ? <FormattedMessage id="companies.editPersonHeader" defaultMessage="EDITAR PESSOA" /> : <FormattedMessage id="companies.addPersonHeader" defaultMessage="ADICIONAR PESSOA" />;
  const saveEnable =
      attendant.user_info.mobile !== '' &&
      Object.values(attendant).every(el => el !== '') &&
      Object.values(validator).every(el => el.validate);
  let creator = '';
  let modifier = '';
  if (props.match.params.method === 'edit') {
    const attendants = props.attendants[0].data;
    creator = attendant
      ? attendants.filter(a => a.id === attendant.user_info.created_by)[0]
      : undefined;
    modifier = attendant
      ? attendants.filter(a => a.id === attendant.user_info.modified_by)[0]
      : undefined;
  }

  return (
    <div className="contactto-middle-content">
      {saveError.hasError &&
        <ErrorComponent errorCode={saveError.code} errorMsg={saveError.message} handleButton={() => setSaveError({ message: '', code: 0, saveError: false})}/>
      }
      {showLogoutMessage &&
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            color: '#ef5134',
            marginBottom: '8px',
          }}
        >
          <FormattedMessage id="attendant.form.passwordChanged" defaultMessage="Sua senha foi alterada, acesse novamente." />
        </div>
      }
      {showChangePasswordModal &&
        <ModalPasswordChange
          changePassword={changePassword}
          closeModal={() => setShowPasswordModal(false)}
          wrongPassword={passwordError}
        />
      }
      {isAdmin || isManager || isOwner || loggedAttendantId === parseInt(userId, 10) 
      ? <div className={formStyle.container}>
        <div className={formStyle.title}>
          {title}
        </div>
        {loggedAttendantId === parseInt(userId, 10) && match.params.method === 'edit' &&
          <div className={detail.container}>
            <button
              onClick={() => setShowPasswordModal(true)}
              style={{ float: 'right' }}
              className={'btn btn--form btn--color-tecno'}
            >
              <FormattedMessage id="attendant.form.changePassword" defaultMessage="Alterar Senha" />
            </button>
          </div>
        }
        <form className={formStyle.container}>
          <div className={detail.container}>
            <div className={detail['container-title']}><FormattedMessage id="general.personalInfoHeader" defaultMessage="INFORMAÇÕES PESSOAIS" /></div>
            <div className={detail['container-content']}>
              {match.params.method === 'add'
                ? <InputContainer
                  name="username"
                  onChange={setForm}
                  title={<FormattedMessage id="inputContainer.username" defaultMessage="Nome de usuário: " />}
                  validator={validator.username}
                  value={attendant.username}
                />
                : <div className="form-row" style={{ width: '100%' }}>
                  <span className="text-muted" style={{ marginLeft: '0.5rem' }}><FormattedMessage id="attendant.userName" defaultMessage="Nome de Usuário" />: </span>
                  <span className={detail.value}>
                    {attendant.username}
                  </span>
                </div>
              }
              <div className="form-row">
                <div className="form-group col-sm-12 col-md-6">
                  <InputContainer
                    name="first_name"
                    onChange={setForm}
                    title={<FormattedMessage id="inputContainer.firstName" defaultMessage="Nome: " />}
                    validator={validator.first_name}
                    value={attendant.first_name}
                  />
                </div>
                <div className="form-group col-sm-12 col-md-6">
                  <InputContainer
                    name="last_name"
                    onChange={setForm}
                    title={<FormattedMessage id="inputContainer.lastName" defaultMessage="Sobrenome: " />}
                    validator={validator.last_name}
                    value={attendant.last_name}
                  />
                </div>
              </div>
              <div className="form-row">
                  <div className="form-group col-sm-12 col-md-6">
                    <InputContainer
                      name="email"
                      onChange={setForm}
                      title="Email: "
                      validator={validator.email}
                      value={attendant.email}
                    />
                  </div>
                  <div className="form-group col-sm-12 col-md-6">
                    <InputContainer
                      maxLength="16"
                      name="mobile"
                      onChange={setForm}
                      title={<FormattedMessage id="inputContainer.mobile" defaultMessage="Celular: " />}
                      validator={validator.mobile}
                      value={attendant.user_info.mobile ? attendant.user_info.mobile : ''}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-sm-12 col-md-6">
                    <label className="text-muted" htmlFor="country">
                      <FormattedMessage id="attendant.country" defaultMessage="País" />: <span className={formStyle.required}>*</span>
                    </label>

                    <SelectInput
                      id="country"
                      selectClass={`custom-select ${formStyle['select--enable']}`}
                      items={countryList[props.lang]}
                      emptyOption={false}
                      value={!attendant.user_info.country ? '' : attendant.user_info.country}
                      onChange={setForm}
                      name="country"
                    />
                </div>
              </div>
            </div>
          </div>
          {match.params.method === 'add' &&
            <div className={detail.container}>
              <div className={detail['container-title']}><FormattedMessage id="attendant.form.passwordHeader" defaultMessage="SENHA" /></div>
              <div className={detail['container-content']}>
                <InputContainer
                  name="password"
                  onChange={setForm}
                  title={<FormattedMessage id="inputContainer.password" defaultMessage="Senha: " />}
                  type="password"
                  validator={validator.password}
                  value={attendant.password}
                />
                <InputContainer
                  name="password2"
                  onChange={setForm}
                  title={<FormattedMessage id="inputContainer.confirmPassword" defaultMessage="Confirmar senha: " />}
                  type="password"
                  validator={validator.password2}
                  value={attendant.password2}
                />
              </div>
            </div>
          }

          {!attendant.is_superuser &&
            <div className={detail.container}>
              <div className={detail['container-title']}><FormattedMessage id="general.actionsHeader" defaultMessage="AÇÕES" /></div>
              <div className="form-group form-row">
                <div className="form-group col-12">
                  <div className="form-group col-12">
                    <div className="form-row">
                      <div className="col-8" />
                      <div className="text-muted small col-2 text-center" style={{ textTransform: 'uppercase' }}><small>Email</small></div>
                      <div className="text-muted small col-2 text-center" style={{ textTransform: 'uppercase' }} ><small>SMS</small></div>
                    </div>

                    {actions &&
                      actions.map((action, index) =>
                        <div key={action.priority} className="block-content-container contactto-100">
                          <div className="left text-muted col-8">
                            {userActionPriority(action.priority, 'fromServer')}
                          </div>

                          <CheckboxInput
                            name="receive_email"
                            className={`${style.checkbox} col-2`}
                            checked={actions[index].receive_email}
                            onChange={ev => handleActions(ev, index)}
                          />
                          <CheckboxInput
                            name="receive_sms"
                            className={`${style.checkbox} col-2`}
                            checked={actions[index].receive_sms}
                            onChange={ev => handleActions(ev, index)}
                          />
                        </div>,
                      )}
                  </div>
                </div>
              </div>
            </div>
          }
          {match.params.method === 'edit' &&
            <div className={detail.container}>
              <div className={detail['container-title']}>LOGS</div>
              <div className="block-content">
                <div className="block-content-container block-content-container--100">
                  <span className="block-field"><FormattedMessage id="logs.createdDate" defaultMessage="Data de criação" />: </span>
                  <span className="block-value">
                    {attendant.user_info.created_date
                      ? general.formatOnlyNumbersDate(attendant.user_info.created_date)
                      : '-'}
                  </span>
                </div>
                <div className="block-content-container block-content-container--100">
                  <span className="block-field"><FormattedMessage id="logs.createdBy" defaultMessage="Criado por" />: </span>
                  {creator
                    ? <span className="block-value">{`${creator.first_name} ${creator.last_name}`}</span>
                    : <span className="block-value"> - </span>}
                </div>
                <div className="block-content-container block-content-container--100">
                  <span className="block-field"><FormattedMessage id="logs.modifiedDate" defaultMessage="Data de modificação" />: </span>
                  <span className="block-value">
                    {attendant.user_info.modified_date
                      ? general.formatOnlyNumbersDate(
                        attendant.user_info.modified_date,
                      )
                      : '-'}
                  </span>
                </div>
                <div className="block-content-container block-content-container--100">
                  <span className="block-field"><FormattedMessage id="logs.modifiedBy" defaultMessage="Modificado por" />: </span>
                  {modifier
                    ? <span className="block-value">{`${modifier.first_name} ${modifier.last_name}`}</span>
                    : <span className="block-value"> - </span>}
                </div>
              </div>
            </div>}
            <div className="col-12 row">
              <button
                type="button"
                className="btn btn--form btn--color-red col-6"
                onClick={props.history.goBack}
              >
                <span><i className="far fa-window-close" aria-hidden="true" style={{ paddingRight:'1rem', paddingTop: '0.2rem' }} /></span>
                <FormattedMessage id="form.button.cancel" defaultMessage="Cancelar" />
              </button>
              {saveEnable
                ? <button
                  type="button"
                  disabled={false}
                  className={'btn btn--form btn--color-green col-6'}
                  onClick={performSave}
                >
                  <span> <i className="far fa-save fa-lg" aria-hidden="true" style={{ paddingRight:'1rem', paddingTop: '0.2rem' }} /></span>
                  <FormattedMessage id="form.button.save" defaultMessage="Salvar" />
                </button>
                : <button type="button" disabled className={'btn btn--form btn--color-invalid col-6'}>
                  <span> <i className="far fa-save fa-lg" aria-hidden="true" style={{ paddingRight:'1rem', paddingTop: '0.2rem' }} /></span>
                  <FormattedMessage id="form.button.save" defaultMessage="Salvar" />
                </button>}
            </div>
        </form>
      </div>
      : <div className="transmissor-form-container">
        <div className="attend-title"><FormattedMessage id="attendant.form.unauthorized" defaultMessage="Você não tem autorização para acessar essa página" /></div>
      </div>}
    </div>
  );
};

UserForm.propTypes = {
  authLogoutAndRedirect: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  channelId: PropTypes.string.isRequired,
  loggedAttendantId: PropTypes.number.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool,
  isManager: PropTypes.bool.isRequired,
  loadAttendants: PropTypes.func.isRequired,
  loadCompanies: PropTypes.func.isRequired,
  attendants: PropTypes.arrayOf(
    PropTypes.shape({
      ordering: PropTypes.string,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          username: PropTypes.string,
          first_name: PropTypes.string,
          last_name: PropTypes.string,
          email: PropTypes.string,
          password: PropTypes.string,
          groups: PropTypes.arrayOf(PropTypes.number),
          is_active: PropTypes.bool,
          is_staff: PropTypes.bool,
          is_superuser: PropTypes.bool,
          user_info: PropTypes.shape({
            id: PropTypes.number,
            user: PropTypes.number,
            phone: PropTypes.string,
            mobile: PropTypes.string,
            email: PropTypes.string,
            extension_id: PropTypes.number,
          }),
          actions: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number,
              priority: PropTypes.number,
              user: PropTypes.number,
              receive_sms: PropTypes.bool,
              receive_email: PropTypes.bool,
            }),
          ),
        }),
      ),
    }),
  ).isRequired,
  company: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    user_set: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        is_active: PropTypes.bool,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        email: PropTypes.string,
        groups: PropTypes.arrayOf(
          PropTypes.number,
        ),
      }),
    ),
  }).isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    attendants: state.attendants,
    user: state.loggedAttendant,
    channelId: ownProps.channelId,
    clearList: ownProps.clearList,
    loggedAttendantId: state.loggedAttendant.id ? state.loggedAttendant.id : 0,
    isAdmin: state.loggedAttendant.groups ? state.loggedAttendant.groups[0] === 1 : false,
    isManager: state.loggedAttendant.groups ? state.loggedAttendant.groups[0] === 2 : false,
    lang: state.locale.lang,
    company: state.groups.filter(grp => ![1, 2, 3, 4].includes(grp.id))
      .find(company => company.id === parseInt(ownProps.match.params.companyId, 10)),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadAttendants: _ => dispatch(attendantsActions.loadAttendants()),
    editAttendant: attendant => dispatch(attendantsActions.editAttendant(attendant)),
    createAttendant: attendant => dispatch(attendantsActions.createAttendant(attendant)),
    authLogoutAndRedirect: () => dispatch(authActions.authLogoutAndRedirect()),
    loadCompanies: () => dispatch(groupsActions.loadGroups()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
