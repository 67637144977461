import * as types from '../actions/actionTypes';
import initialState from './initialState';

/*
  Inputs:
    state = Estado atual do objeto, caso não seja passado é utilizado o estado default do arquivo(initalstate.auth)
    action = Ação aplicada sobre o objeto

  Types:
    AUTH_LOGIN_USER_REQUEST = Requisição de autenticação de usuários
    AUTH_LOGIN_USER_SUCCESS = Requisição aceita com sucesso
    AUTH_LOGIN_USER_FAILURE = Requisição de login falhou
    AUTH_LOGOUT_USER = Requisição de logout de usuário

   authReducer:
    Método responsável por identificar a necessidade da operação, baseado no Type, retornando o estado do objeto de autenticação
    (Ex: Usuário e senha conferem(Login realizado com sucesso), não foi possível realizar login(Failure))
*/
export default function authReducer(state = initialState.auth, action) {
  if (action.type === types.AUTH_LOGIN_USER_REQUEST) {
    return Object.assign({}, state, {
      isAuthenticating: true,
      statusText: null,
    });
  }
  if (action.type === types.AUTH_LOGIN_USER_SUCCESS) {
    return Object.assign({}, state, {
      isAuthenticating: false,
      isAuthenticated: true,
      token: action.token,
      userName: action.user.username,
      status: action.status,
      isPaid: action.isPaid,
      isActive: action.isActive,
      configuration: action.config,
      userConfiguration: action.userConfig,
      statusText: 'Successful login.',
    });
  }
  if (action.type === types.AUTH_LOGIN_USER_FAILURE) {
    return Object.assign({}, state, {
      isAuthenticating: false,
      isAuthenticated: false,
      token: null,
      userName: null,
      status: null,
      isPaid: false,
      isActive: false,
      configuration: null,
      userConfiguration: null,
      statusText: `Authentication Error: ${action.payload.status} - ${
        action.payload.statusText
      }`,
    });
  }
  if (action.type === types.AUTH_LOGOUT_USER) {
    return Object.assign({}, state, {
      isAuthenticated: false,
      token: null,
      userName: null,
      status: null,
      isPaid: false,
      isActive: false,
      configuration: null,
      userConfiguration: null,
      statusText: 'You have been successfully logged out.',
    });
  }
  return state;
}
