import React from 'react';
import PropTypes from 'prop-types';

const AlarmInactIco = ({ height, svgClass, width, fill, transform }) => (
  <svg className={svgClass} width={width} height={height} fill={fill} transform={transform} viewBox="0 0 140 140" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M70.7,139.8c-14.1,0-28.2,0-42.3,0c-6.3,0-10.9-3.6-12.2-9.6c-0.2-0.8-0.2-1.6-0.2-2.4c0.1-4.9-0.2-9.8,0.4-14.7
c1.6-14.2,13.8-26.2,28-27.9c2.6-0.3,5.3-0.3,8-0.3c0.5,0,1,0.1,1.4,0.3c11.4,4.7,22.8,4.8,34.2,0c0.3-0.1,0.7-0.3,1.1-0.3
c14.1-0.9,25.9,5.1,32.8,17.3c2.7,4.8,4,10,3.9,15.4c0,3.2-0.2,6.4,0,9.6c0.4,6.1-4.4,12.6-12.6,12.6
C99,139.7,84.8,139.8,70.7,139.8z"
    />
    <g> <path
      d="M89.9,50.4c1.2,0,2.4,0,3.5,0c-0.4-11.7-10.9-21.3-21.3-21.2c0,1.1,0,2.2,0,3.4c-0.5,0-0.8,0-1.1,0.1
c-1.2,0.2-1.7-0.2-1.6-1.5c0.1-0.6,0-1.3,0-2.1c-10.2-0.5-22.8,12.3-21.1,21.4c1.1,0,2.2,0,3.4,0c0,0.5,0,0.8,0.1,1.1
c0.2,1.2-0.3,1.7-1.5,1.5c-0.7-0.1-1.4,0-2.1,0c0.3,11.8,11.1,21.2,21.2,21.1c0-1-0.2-2,0.1-2.9c0.4-1.3,1.6-0.4,2.6-0.6
c0,1.3,0,2.4,0,3.5c11-0.3,20.9-9.9,21.3-21.2c-1.2,0-2.3,0-3.5,0C89.9,52.2,89.9,51.4,89.9,50.4z M82.5,40.4
c-2.3,2.9-4.6,5.8-6.8,8.8c-0.3,0.5-0.5,1.2-0.5,1.7c0,2.3-1.7,4.2-4,4.2c-2.3,0-4.1-1.9-4-4.1c0.1-2.3,2.1-4,4.4-3.9
c0.6,0,1.3-0.4,1.8-0.7c2.8-2.2,5.5-4.4,8.3-6.6c0.3-0.2,0.6-0.4,1-0.6c0.1,0.1,0.2,0.1,0.3,0.2C82.8,39.7,82.7,40.1,82.5,40.4z"
    />
      <path
        d="M70.8,20.4c-17.1-0.1-31.3,14.1-31.3,31c0,17.7,13.9,31.8,31.3,31.8c17.1,0,31.3-14,31.5-30.9
C102.4,35.2,88.9,20.4,70.8,20.4z M70.7,80.5c-15.7-0.1-28.9-13.3-28.6-28.9c-0.2-15.4,12.7-28.7,28.8-28.7
c16,0,29.1,13.4,28.7,29.5C99.3,67.7,86.5,80.6,70.7,80.5z"
      />
    </g>
    <path
      d="M101.6,22.1c-1.9,1.9-3.7,3.9-5.7,5.7c-0.3,0.3-1.3,0.3-1.6,0.1c-1.3-1.1-2.6-2.4-3.7-3.7c-0.3-0.3-0.2-1.3,0.1-1.6
c1.6-1.8,3.3-3.4,5-5.1c0.6-0.6,1.2-0.6,1.7,0c1.4,1.4,2.8,2.9,4.2,4.3C101.6,21.9,101.6,22,101.6,22.1z"
    />
    <path
      d="M67.8,18c0-0.6-0.1-1.2-0.1-2c-1.5,0-3.1,0-4.6,0c-1.2,0-1.8-0.5-1.7-1.7c0-2,0-4.1,0-6.1c0-1.2,0.4-1.8,1.7-1.8
c5,0,10,0,14.9,0c1.1,0,1.6,0.5,1.6,1.6c0,2.1,0,4.2,0,6.3c0,1.1-0.6,1.7-1.7,1.6c-1.5,0-3.1,0-4.6,0c-0.1,0.7-0.1,1.3-0.1,2
C71.4,18,69.6,18,67.8,18z"
    />
  </svg>
);

AlarmInactIco.defaultProps = {
  height: '75',
  svgClass: '',
  width: '75',
  fill: '#D8342B',
  transform: 'translate(0,0)',
};

AlarmInactIco.propTypes = {
  height: PropTypes.string,
  svgClass: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
  transform: PropTypes.string,
};

export default AlarmInactIco;
