import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import style from './reportCompanyDetails.module.scss';

const ReportCompanyDetailsRow = ({ client }) => (
  <div className={style.row}>
    <div className={`${style.column} ${style.account}`}> {client.account} </div>
    <div className={`${style.column} ${style.name}`}> {client.first_name} {client.last_name} </div>
    <div className={`${style.column} ${style['date-created']}`}> {moment(client.created_date).format('DD/MM/YYYY - HH:mm')} </div>
    <div className={`${style.column} ${style['date-deleted']}`}> {client.is_deleted ? moment(client.modified_date).format('DD/MM/YYYY - HH:mm') : '-'} </div>
    <div className={`${style.column} ${style['date-deleted']}`}> {client.active_days} </div>
  </div>
);

ReportCompanyDetailsRow.propTypes = {
  client: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    account: PropTypes.string,
    created_date: PropTypes.string,
    modified_date: PropTypes.string,
    is_deleted: PropTypes.bool,
  }).isRequired,
};

export default ReportCompanyDetailsRow;
