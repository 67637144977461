import React from 'react';
import PropTypes from 'prop-types';
import style from './attendComment.module.scss';

const AttendComment = ({ attend, setAttendState, loggedAttendantId }) => (
  <div className={style.commentWrapper}>
    <textarea
      name="report"
      className={style.textArea}
      style={{ border: '1px solid #ccc' }}
      value={!attend.report ? '' : attend.report}
      maxLength="1024"
      onKeyDown={(e) => {
        const TABKEY = 9;
        if (e.keyCode === TABKEY) {
          e.preventDefault();
        }
      }}
      onChange={setAttendState}
      rows="10"
      cols="50"
      disabled={
        !(
          attend.attendant === loggedAttendantId &&
          !attend.close_ts
        )
      }
    />
  </div>
);

AttendComment.propTypes = {
  loggedAttendantId: PropTypes.number.isRequired,
  setAttendState: PropTypes.func.isRequired,
  attend: PropTypes.shape({
    attendant: PropTypes.number,
    report: PropTypes.string,
    close_ts: PropTypes.string,
  }).isRequired,
};

export default AttendComment;
