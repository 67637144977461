import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Audio from '../../../../icons/Audio';
import NoAudio from '../../../../icons/NoAudio';

import style from './reportAttend.module.scss';

const ReactAttendRow = ({ report }) => (
  <Link to={`/app/attends/closed/${report.attend_id}`} className={style.row}>
    <div className={`${style.column} ${style.id}`}>{report.attend_id}</div>
    <div className={`${style.column} ${style.client}`}>{report.client}</div>
    <div className={`${style.column} ${style.account}`}>{report.account}</div>
    <div className={`${style.column} ${style.dateOpen}`}>{moment(report.open).format('DD/MM/YYYY - HH:mm')}</div>
    <div className={`${style.column} ${style.dateClosed}`}>{moment(report.close).format('DD/MM/YYYY - HH:mm')}</div>
    <div className={`${style.column} ${style.attendant}`}>{report.attendant}</div>
    <div className={`${style.column} ${style.audioCall}`}>{report.audio_call ? <Audio /> : <NoAudio /> }</div>
  </Link>
);

ReactAttendRow.propTypes = {
  report: PropTypes.shape({
    attend_id: PropTypes.number,
    account: PropTypes.string,
    audio_call: PropTypes.bool,
    client: PropTypes.string,
    close: PropTypes.string,
    open: PropTypes.string,
    attendant: PropTypes.string,
  }).isRequired,
};

export default ReactAttendRow;
