import React from 'react';
import PropTypes from 'prop-types';
import CustomChart from './CustomChart';
import '../../css/contactto.scss';

const LengthStayChart = ({ height, report, width }) => {
  const names = [];
  const values = [];
  const colors = [];
  const rows = [
    ...report.map((rpt) => {
      if (rpt.deleted_date === '-') {
        names.push(rpt.client);
        values.push(rpt.days_length);
        colors.push('#90b5c1b3');
      } else {
        names.push(rpt.client);
        values.push(rpt.days_length);
        colors.push('#b71f26');
      }
    }),
  ];

  const calcClientsSize = () => {
    if (names.length > 35) {
      return '1700px';
    }
  };

  return (
    <div className="left" style={{ height, width }}>
      <CustomChart
        labels={names}
        data={values}
        colors={colors}
        styleChart="bar"
        isHorizontal
        minHeight={calcClientsSize()}
        legend
        tooltip
      />
    </div>
  );
};

LengthStayChart.defaultProps = {
  height: '350px',
  width: '350px',
};

LengthStayChart.propTypes = {
  height: PropTypes.string,
  report: PropTypes.arrayOf(
    PropTypes.shape({
      account: PropTypes.string,
      client: PropTypes.string,
      days_length: PropTypes.number,
      created_date: PropTypes.string,
    }),
  ).isRequired,
  width: PropTypes.string,
};

export default LengthStayChart;
