import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import AudioMenu from '../Common/AudioMenu';
import { FormattedMessage } from 'react-intl';
import style from './modalReturnCall.module.scss';

const IDLE = 1;
const FIRST_CALL = 2;
const FIRST_HANGUP = 3;
const SECOND_CALL = 4;
const SEND_PASSWORD = 5;
// const ERROR = -1;

const ModalPERS = (props) => {
  const [callStage, setCallStage] = useState(IDLE);
  const [durationLeft, setDuration] = useState(10);

  useEffect(() => {
    if (callStage === FIRST_HANGUP) {
      if (!durationLeft) {
        makeSecondCall();
        return;
      }
      const intervalId = setInterval(() => {
        setDuration(durationLeft - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [durationLeft, callStage]);

  const makeFirstCall = () => {
    setCallStage(FIRST_CALL);
    props.startCall(`${props.clientPhone}${props.clientAccount}pers`);
  };

  const makeFirstHangup = () => {
    setCallStage(FIRST_HANGUP);
    props.hangupSip();
  };

  const makeSecondCall = () => {
    setCallStage(SECOND_CALL);
    if (props.isIndependent) {
      props.startCall(`${props.clientPhone}${props.clientAccount}scall`);
    } else {
      props.startCall(`${props.clientPhone}${props.clientAccount}call${props.attendId}`);
    }
  };

  const sendPassword = () => {
    setCallStage(SEND_PASSWORD);
    props.sendPERSPassword();
  };

  const closeCall = () => {
    setCallStage(IDLE);
    props.hangupSip();
    props.closeModal();
  };

  const closeCallAndStartOver = () => {
    setCallStage(IDLE);
    props.hangupSip();
  }

  return (
    <div className={style['modal-middle-wrapper']}>
      {callStage === IDLE &&
        <div className={style['modal-middle-container']}>
          <FormattedMessage id="return.makeCallLabel" defaultMessage="Retorno ao usuário" />
          <button type="button" className={style['return-call-button']} onClick={makeFirstCall}>
            <FormattedMessage id="return.makeCallButton" defaultMessage="Realizar Ligação"/>
          </button>
        </div>
      }
      {callStage === FIRST_CALL &&
        <div className={style['modal-middle-container']}>
          <FormattedMessage id="return.firstHangupLabel" defaultMessage="Aguarde 3-4 toques e desligue esta chamada" />
          <span>Se você não ouviu nenhum toque ou sinal de ocupado, clique em voltar, aguarde alguns instantes e tente novamente</span>
          <button type="button" className={style['return-call-button']} onClick={makeFirstHangup}>
            <FormattedMessage id="return.firstHangupButton" defaultMessage="Desligar"/>
          </button>
          <button type="button" className={style['return-call-button']} onClick={closeCallAndStartOver}>
            VOLTAR
          </button>
        </div>
      }
      {callStage === FIRST_HANGUP &&
        <div className={style['modal-middle-container']}>
          <FormattedMessage
            id="return.waitSeconds"
            defaultMessage="Aguardando {duration} segundos..."
            values={{ duration: durationLeft }}
          />
        </div>
      }
      {callStage === SECOND_CALL &&
        <div className={style['modal-middle-container']}>
          <FormattedMessage id="return.waitToneLabel" defaultMessage="Aguarde o fim do tom" />
          <button type="button" className={style['return-call-button']} onClick={sendPassword}>
            <FormattedMessage id="return.sendPasswordButton" defaultMessage="Enviar senha" />
          </button>
        </div>
      }
      {callStage === SEND_PASSWORD &&
        <div className={style['modal-middle-container']}>
          <FormattedMessage id="return.ongoingCallLabel" defaultMessage="Chamada em andamento" />
          <AudioMenu muteAudio={props.muteAudio} mutePhone={props.mutePhone} autoCall={props.autoCall}/>
          <button type="button" className={style['return-call-button']} onClick={props.sendPERSPassword}>
            <FormattedMessage id="return.reSendPasswordButton" defaultMessage="Enviar novamente a senha" />
          </button>
          <button type="button" className={style['return-call-button']} onClick={closeCall}>
            <FormattedMessage id="return.hangupCall" defaultMessage="DESLIGAR" />
          </button>
        </div>
      }
    </div>
  )
};

const ModalClimax = (props) => {
  const [callStage, setCallStage] = useState(IDLE);

  const makeCall = () => {
    setCallStage(FIRST_CALL);
    if (props.isIndependent) {
      props.startCall(`005491${props.clientAccount}${props.clientPhone}scall`);
    } else {
      props.startCall(`005491${props.clientAccount}${props.clientPhone}call${props.attendId}`);
    }
  };

  const closeCall = () => {
    setCallStage(IDLE);
    props.hangupSip();
    props.closeModal();
  };

  console.log(props);

  return (
    <div className={style['modal-middle-wrapper']}>
      {callStage === IDLE &&
        <div className={style['modal-middle-container']}>
          <FormattedMessage id="return.makeCallLabel" defaultMessage="Retorno ao usuário" />
          <button type="button" className={style['return-call-button']} onClick={makeCall}>
            <FormattedMessage id="return.makeCallButton" defaultMessage="Realizar Ligação"/>
          </button>
        </div>
      }
      {callStage === FIRST_CALL &&
        <div className={style['modal-middle-container']}>
          <FormattedMessage id="return.ongoingCallLabel" defaultMessage="Chamada em andamento" />
          <AudioMenu muteAudio={props.muteAudio} mutePhone={props.mutePhone} autoCall={props.autoCall}/>
          <button type="button" className={style['return-call-button']} onClick={closeCall}>
            <FormattedMessage id="return.hangupCall" defaultMessage="DESLIGAR" />
          </button>
        </div>
      }
    </div>
  );

}

const ModalReturnCall = (props) => {
  console.log('RETURN CALL', props);
  let pos1 = 0;
  let pos2 = 0;
  let pos3 = 0;
  let pos4 = 0;
  const wrapperRef = useRef(null);
  const [posX, setPosX] = useState(props.posX);
  const [posY, setPosY] = useState(props.posY);

  const dragMouseDown = (e) => {
    e.preventDefault();
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    document.onmousemove = elementDrag;
  };

  const elementDrag = (e) => {
    e.preventDefault();
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;

    setPosX(wrapperRef.current.offsetLeft - pos1);
    setPosY(wrapperRef.current.offsetTop - pos2);
  };

  const closeDragElement = () => {
    document.onmouseup = null;
    document.onmousemove = null;
  };
  return (
    <div className={style['return-container']} style={{ left: posX, top: posY }} ref={wrapperRef}>
      <div id="header" className={style['modal-header']} onMouseDown={dragMouseDown}>
        <button onClick={props.closeModal} className={style['return-close-modal-button']}>
          <i class="fas fa-times" />
        </button>
      </div>
      {props.equipment?.name.includes('PERS') &&
        <ModalPERS
          clientPhone={props.clientPhone}
          clientAccount={props.clientAccount}
          attendId={props.attendId}
          startCall={props.startCall}
          sendPERSPassword={props.sendPERSPassword}
          hangupSip={props.hangupSip}
          sipStackType={props.sipStackType}
          closeModal={props.closeModal}
          isIndependent={props.isIndependent}
          muteAudio={props.muteAudio}
          mutePhone={props.mutePhone}
          autoCall={props.autoCall}
        />
      }
      {(props.equipment?.name.toLowerCase().includes('climax') || props.equipment?.name.toLowerCase().includes('ev07b')) &&
        <ModalClimax
          clientPhone={props.clientPhone}
          clientAccount={props.clientAccount}
          attendId={props.attendId}
          startCall={props.startCall}
          hangupSip={props.hangupSip}
          sipStackType={props.sipStackType}
          closeModal={props.closeModal}
          isIndependent={props.isIndependent}
          muteAudio={props.muteAudio}
          mutePhone={props.mutePhone}
          autoCall={props.autoCall}
        />
      }
    </div>
  )
};

ModalReturnCall.defaultProps = {
  posX: 570,
  posY: 15,
  isIndependent: false,
};

ModalReturnCall.propTypes = {
  posX: PropTypes.number,
  posY: PropTypes.number,
  isIndependent: PropTypes.bool,
  equipment: PropTypes.shape({
    name: PropTypes.string,
    is_mobile: PropTypes.bool,
  }).isRequired,
};

export default ModalReturnCall;
