import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import AttendantRow from './AttendantRow';
import AttendantMobileRow from './AttendantMobileRow';
import Loading from '../../../../icons/Loading';
import ToggleButton from '../../../Common/ToggleButton';
import ConfirmRemoveModal from '../../../Modal/ModalConfirmRemove'
import ManagementMenu from '../ManagementMenu';
import Order from '../../../../icons/Order';
import * as attendantsActions from '../../../../actions/attendantsActions';
import * as globals from '../../../../BLL/globals';
import { broadcast, removeByKey, getBrowserMaxWidth } from '../../../../BLL/general';
import broadcastMessages from '../../../../BLL/locale/messagesBroadcast';

import style from '../management.module.scss';

import '../../../../css/icons.scss';
import '../../../../css/middle.scss';
import '../../../../css/buttons.scss';
import '../../../../css/listPage.scss';

const AttendantManagement = (props) => {
  const { isAdmin, isOwner, isManager, attendants, loggedAttendantId, loggedAttendantGroup } = props;
  const browserWidth = getBrowserMaxWidth();
  const [orderedAttendants, setOrderedAttendants] = useState([]);
  const [ordering, setOrdering] = useState(globals.attendantsOrdering);
  const [selectedAttendant, setSelectedAttendant] = useState(null);
  const [showConfirmRemoveModal, setShowConfirmRemoveModal] = useState(false);
  const [toggleChecked, setToggleCheck] = useState(false);

  useEffect(() => {
    const newOrdered = attendants.filter(atts => atts.ordering === ordering)[0];
    if (newOrdered) {
      setOrderedAttendants(newOrdered.data);
    }
  }, [attendants, ordering]);

  const orderBy = (name) => {
    let newOrder = '';
    if (name === 'first_name,last_name') {
      newOrder = ordering === name ? `-first_name,-last_name` : name;
    } else {
      newOrder = ordering === name ? `-${name}` : name;
    }
    props.loadAttendants(newOrder);
    setOrdering(newOrder);
  };

  const checkAttendant = (attendant) => {
    if (attendant.is_superuser) {
      return false;
    }

    return attendant.is_active || (toggleChecked && !attendant.is_active && (isAdmin || isOwner));
  };

  const setConfirmModalInfo = (showModal, attendant) => {
    setShowConfirmRemoveModal(showModal);
    setSelectedAttendant(Object.assign({}, attendant));
  };

  const toggleAttendant = () => {
    const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
    const userInfo = removeByKey(selectedAttendant.user_info, ['user', 'email', 'extension_id']);
    const attendant = Object.assign(
      {},
      selectedAttendant,
      { is_active: !selectedAttendant.is_active },
      { user_info: userInfo },
    );
    const text = `${broadcastMessages[lang]['broadcast.attendant']} ${selectedAttendant.first_name} ${selectedAttendant.last_name} ${
      selectedAttendant.is_active ? broadcastMessages[lang]['broadcast.recovered'] : broadcastMessages[lang]['broadcast.deleted']}`;
    
    props.editAttendant(attendant).then(() => {
      broadcast(axios, props.channelId, {
        type: 'attendant',
        method: 'toggle',
        userId: loggedAttendantId,
        text,
        attendant
      });
    });
    setConfirmModalInfo(false, null);
  };

  const svgNameRotation = ordering === '-first_name,-last_name' ? 'icons-order--rotate' : '';
  const svgUserRotation = ordering === '-username' ? 'icons-order--rotate' : '';
  const svgEmailRotation = ordering === '-email' ? 'icons-order--rotate' : '';
  const svgGroupRotation = ordering === '-groups' ? 'icons-order--rotate' : '';

  return (
    <div className="contactto-middle-content" style={{ overflowX: 'hidden' }}>
      <div className="row">
        <div className="pagetitle"><FormattedMessage id="management.header" defaultMessage="GERENCIAR" /></div>
        <button type="button" className={`${style.btn} ${style['btn-attendant']}`} onClick={() => props.history.push('/app/user/form/add/')}>
          <div><span style={{ fontWeight: 'bold' }}>+ <FormattedMessage id="general.add" defaultMessage="ADICIONAR" /></span></div>
        </button>
      </div>
      <div className={style.content}>
        <div className="row">
          <div className="col-sm-12 col-md-8"> <ManagementMenu history={props.history} /> </div>
          <div className="col-sm-12 col-md-4">
            {(loggedAttendantGroup === 1 || loggedAttendantGroup === 4) &&
              <ToggleButton isChecked={toggleChecked} handleChange={() => setToggleCheck(!toggleChecked)} />
            }
          </div>
        </div>
        {orderedAttendants.length > 0 &&
          <div className={style.table}>
            {showConfirmRemoveModal &&
              <ConfirmRemoveModal removeAction={toggleAttendant} setModalInfo={setConfirmModalInfo} />
            }
            <div className={style.header}>
              <div className={style.username}>
                <button
                  className={style['header-column']}
                  style={{ color: ordering.indexOf('username') !== -1 ? '#00b8cb' : '#333' }}
                  onClick={() => orderBy('username')}
                >
                  <FormattedMessage id="attendant.userName" defaultMessage="Nome do Atendente" /><Order width="15" height="10" className={svgUserRotation} />
                </button>
              </div>
              <div className={style.name}>
                <button
                  className={style['header-column']}
                  style={{ color: ordering.indexOf('first_name') !== -1 ? '#00b8cb' : '#333' }}
                  onClick={() => orderBy('first_name,last_name')}
                >
                  <FormattedMessage id="general.fullName" defaultMessage="Nome" /><Order width="15" height="10" className={svgNameRotation} />
                </button>
              </div>
              <div className={style.email}>
                <button
                  className={style['header-column']}
                  style={{ color: ordering.indexOf('email') !== -1 ? '#00b8cb' : '#333' }}
                  onClick={() => orderBy('email')}
                >
                  Email<Order width="15" height="10" className={svgEmailRotation} />
                </button>
              </div>
              <div className={style.group}>
                <button
                  className={style['header-column']}
                  style={{ color: ordering.indexOf('groups') !== -1 ? '#00b8cb' : '#333' }}
                  onClick={() => orderBy('groups')}
                >
                  <FormattedMessage id="attendant.group" defaultMessage="Grupo" /><Order width="15" height="10" className={svgGroupRotation} />
                </button>
              </div>
              <div className={style['column-icons']} />

            </div>
            <div className={style['table-body']}>
              {orderedAttendants
                .filter(att => att.groups.length <= 1)
                .map(attendant => (
                  checkAttendant(attendant)
                   ?
                    <AttendantMobileRow
                      key={attendant.id}
                      attendant={attendant}
                      isManager={isManager}
                      isAdmin={isAdmin}
                      isOwner={isOwner}
                      setTextIconDetail={() => {}}
                      setModalInfo={setConfirmModalInfo}
                      showModal={showConfirmRemoveModal}
                    />
                   :
                    null
                ))
              }
            </div>
          </div>
        }
      </div>
    </div>
  );
};

AttendantManagement.propTypes = {
  channelId: PropTypes.string.isRequired,
  loggedAttendantId: PropTypes.number.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool.isRequired,
  loadAttendants: PropTypes.func.isRequired,
  toggleAttendant: PropTypes.func.isRequired,
  attendants: PropTypes.arrayOf(
    PropTypes.shape({
      ordering: PropTypes.string,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          username: PropTypes.string,
          first_name: PropTypes.string,
          last_name: PropTypes.string,
          email: PropTypes.string,
          password: PropTypes.string,
          groups: PropTypes.arrayOf(
            PropTypes.number,
          ),
          is_active: PropTypes.bool,
          is_staff: PropTypes.bool,
          is_superuser: PropTypes.bool,
          user_info: PropTypes.shape({
            id: PropTypes.number,
            user: PropTypes.number,
            phone: PropTypes.string,
            mobile: PropTypes.string,
            email: PropTypes.string,
            extension_id: PropTypes.number,
          }),
          actions: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number,
              priority: PropTypes.number,
              user: PropTypes.number,
              receive_sms: PropTypes.bool,
              receive_email: PropTypes.bool,
            }),
          ),
        }),
      ),
    }),
  ).isRequired,
  loggedAttendantGroup: PropTypes.number.isRequired,
  isManager: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    channelId: ownProps.channelId,
    attendants: state.attendants,
    loggedAttendantId: state.loggedAttendant.id ? state.loggedAttendant.id : 0,
    isOwner: state.loggedAttendant.groups ? state.loggedAttendant.groups[0] === 4 : false,
    isAdmin: state.loggedAttendant.groups ? state.loggedAttendant.groups[0] === 1 : false,
    isManager: state.loggedAttendant.groups ? state.loggedAttendant.groups[0] === 2 : false,
    loggedAttendantGroup: state.loggedAttendant.groups[0],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadAttendants: ordering => dispatch(attendantsActions.loadAttendants(ordering)),
    editAttendant: attendant => dispatch(attendantsActions.editAttendant(attendant)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AttendantManagement);
