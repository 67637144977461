import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import CheckboxInput from '../../Common/CheckboxInput';
import InputContainer from "../../Common/InputContainer";
import ErrorComponent from '../../Common/ErrorComponent';
import { getDomain, host, getToken } from '../../../BLL/environment';
import { broadcast } from '../../../BLL/general';
import * as transmittersActions from '../../../actions/transmittersActions';
import broadcastMessages from '../../../BLL/locale/messagesBroadcast';

import '../../../css/block.scss';
import '../../../css/formPage.scss';
 
import '../../../css/contactto.scss';

import style from './transmitterForm.module.scss';

const TransmitterForm = (props) => {
  const {
    clientId,
    transmitterId,
    method
  } = props.match.params;

  const {
    transmitters,
    lang,
    loggedUser
  } = useSelector(state => ({
    transmitters: state.transmitters,
    clients: state.clients,
    lang: state.locale.lang,
    loggedUser: state.loggedAttendant
  }));

  const dispatch = useDispatch();

  const editTransmitter = (clientId, transmitter) => dispatch(transmittersActions.editTransmitter(clientId, transmitter));
  const createTransmitter = (clientId, transmitter) => dispatch(transmittersActions.createTransmitter(clientId, transmitter));

  const [client, setClient] = useState(undefined);
  const [secondary, setSecondary] = useState(undefined);
  const [zoneList, setZoneList] = useState([]);
  const [error, setError] = useState(false);
  const [errorCode, setErrorCode] = useState(0);
  const [errorMsg, setErrorMsg] = useState('');
  const [useForSecondary, setUseForSecondary] = useState(false);
  const [transmitter, setTransmitter] = useState({
    id: 0,
    client: props.match.params.clientId,
    zone: '',    
    description: '',
    general_info: '',
    identifier: '',
    secondary: 0,
  });
  const [validator, setValidator] = useState({
    zone: props.match.params.method === 'edit',
    description: props.match.params.method === 'edit',
    general_info: true,
  });

  const [nValidator, setNValidator] = useState({
    identifier: { validate: true, message: '' },
    zone: { validate: true, message: '' },
    description: { validate: true, message: '' },
  });

  useEffect(() => {
    const loadTransmitters = (clientId, ordering) => dispatch(transmittersActions.loadTransmitters(clientId, ordering));
    const loadClient = (clientId) => {
      axios.get(`https://${getDomain()}${host()}/api/clients/${clientId}/`, {
        headers: { Authorization: `Token ${getToken()}` },
      }).then(res => {
        setClient(res.data);
        const secondaryList = res.data?.secondary;
        if (secondaryList.length > 0) {
          setSecondary(secondaryList[0]);
        }
      })
      .catch(error => console.log(error));
    };
    loadClient(clientId);
    loadTransmitters(clientId, 'zone');
  }, [clientId, dispatch]);

  useEffect(() => {
    if (transmitters.length > 0) {
      const clientTransmitters = transmitters.filter(tr => tr.clientId === clientId)[0].data;
      let newZoneList = clientTransmitters;
  
      if (method === 'edit') {
        newZoneList = newZoneList.filter(tr => tr.id !== parseInt(transmitterId, 10));
        const newTransmitter = clientTransmitters.find(tr => tr.id === parseInt(transmitterId, 10));
        setTransmitter(newTransmitter);
        if (newTransmitter.secondary > 0) {
          setUseForSecondary(true);
        }
      }
      newZoneList = newZoneList.map(tr => tr.zone);
      setZoneList(newZoneList);
    }

  }, [transmitters, clientId, transmitterId, method]);

  const setTransmitterState = (ev, isValid = true) => {
    const field = ev.target.name;
    const value = ev.target.value;

    let newTransmitter = Object.assign({}, transmitter);
    let newValidator = Object.assign({}, nValidator);

    switch (field) {
      case 'zone': {
        newTransmitter[field] = value;
        const validate = value.length === 3;
        newValidator[field].validate = validate;
        if (!validate) {
          newValidator[field].message =
            <FormattedMessage id="transmitter.error.zone" defaultMessage="Campo precisa de 3 caracteres" />;
        } else {
          newValidator[field].validate = true;
          newValidator[field].message = '';
        }
        break;
      }
      case 'description':
      case 'identifier': {
        newTransmitter[field] = value;
        const validate = value.length >= 2;
        newValidator[field].validate = validate;
        if (!validate) {
          newValidator[field].message =
            <FormattedMessage id="client.error.atLeastTwoChar" defaultMessage="Campo deve possuir pelo menos dois caracteres" />;
        } else {
          newValidator[field].validate = true;
          newValidator[field].message = '';
        }
        break;
      }
      default: {
        newTransmitter[field] = value;
        break;
      }
    };

    setTransmitter(Object.assign({}, newTransmitter));
    setNValidator(Object.assign({}, newValidator));
    // setTransmitter(Object.assign({}, transmitter, { [field]: value }));
    setValidator(Object.assign({}, validator, { [field]: isValid }));
  };

  const processError = (err) => {
    const response = err.response;
    if (response) {
      const newErrorCode = response.status;
      let newErrorMsg = '';
      if (newErrorCode === 400 && response.data) {
        const dataKey = Object.keys(response.data)[0]
        switch (dataKey) {
          default:
            newErrorMsg = response.data[dataKey][0]
            break;
        }
      } else {
        newErrorMsg = 'Generic error';
      }
      setError(true);
      setErrorCode(newErrorCode);
      setErrorMsg(newErrorMsg);
    }
  };

  const closeErrorModal = () => {
    setError(false);
    setErrorCode(0);
    setErrorMsg('');
  };

  const saveTransmitter = () => {
    const text = `${broadcastMessages[lang]['broadcast.transmitter']} ${transmitter.description} ${
      method === 'edit' ? broadcastMessages[lang]['broadcast.updated'] : broadcastMessages[lang]['broadcast.added']}`;
    const serverTransmitter = Object.assign(
      {},
      transmitter,
      { client: clientId },
      { secondary: useForSecondary ? secondary.id : '' }
    );
    if (method === 'edit') {
      editTransmitter(clientId, serverTransmitter).then(() => {
        broadcast(axios, props.channelId, {
          type: 'transmitter',
          method: 'edit',
          clientId: clientId,
          userId: loggedUser.id,
          text,
          serverTransmitter
        });
        props.history.push(`/app/transmitters/list/${clientId}/`);
      }).catch((err) => {
        processError(err);
      })
    } else {
      createTransmitter(clientId, serverTransmitter).then((res) => {
        broadcast(axios, props.channelId, {
          type: 'transmitter',
          method: 'create',
          clientId: clientId,
          userId: loggedUser.id,
          transmitter: res.transmitter,
          text
        });
        props.history.push(`/app/transmitters/list/${clientId}/`);
      }).catch((err) => {
        processError(err);
      })
    }
  };

  const isFormValid = () => {
    return Object.keys(validator).every(el => validator[el] === true);
  };

  const validateZone = (value) => {
    const arr = value.split('');
    const val = arr.filter(el => /\d/.test(el)).join('');

    if (value.length === 0) {
      return [false, <FormattedMessage id="transmitters.error.obligatory" defaultMessage="O campo é obrigatório" />];
    } else if (value === '000') {
      return [false, <FormattedMessage id="transmitters.error.invalidZone" defaultMessage="Zona inválida" />];
    } else if (val !== value) {
      return val;
    } else if (zoneList.indexOf(val) === -1) {
      return true;
    }
    return [false, <FormattedMessage id="transmitters.error.alreadyExists" defaultMessage="Número do identificador já existente" />];
  };

  const title = method === 'edit'?  <FormattedMessage id="transmitters.form.editTransmitter" defaultMessage="EDITAR TRASMISSOR" /> : <FormattedMessage id="transmitters.form.addTransmitter" defaultMessage="ADICIONAR TRASMISSOR" />;

  return (
    <div className="contactto-middle-content" style={{ overflowX: 'hidden' }}>
      {error && <ErrorComponent errorCode={errorCode} errorMsg={errorMsg} handleButton={closeErrorModal}/>}
      <div className="form-container">
        {client &&
          <div className={style.title}>
            {title} <span className="grey">{`${client.first_name} ${client.last_name}`.toUpperCase()}</span>
          </div>
        }
        {transmitter && (
          <form className="left block-content-container--100">
            <div className="block">
              <div className="block-title">
                <FormattedMessage id="transmitters.form.informations" defaultMessage="INFORMAÇÕES" />
              </div>
              <div className="col-12 form-row">
                {client &&
                <div className="form-group col-sm-12" style={{ paddingTop: '1rem' }}>
                  <span className="text-muted col-6" style={{marginLeft: '-1rem'}}>
                    <FormattedMessage id="contact.page.accountNumber" defaultMessage="Número da conta" />: </span><span className="col-6">{client.account}
                  </span>
                </div>
                }
                <div className="form-group col-sm-12 col-md-4">
                  <InputContainer
                    id="identifier"
                    name="identifier"
                    minLength={2}
                    maxLength={7}
                    value={transmitter.identifier}
                    validator={nValidator.identifier}
                    onChange={setTransmitterState}
                    isRequired
                    title={
                      <FormattedMessage
                        id="transmitters.identifier"
                        defaultMessage="Identificador TX: "
                      />
                    }
                  />
                </div>
                <div className="form-group col-sm-12 col-md-4">
                  <InputContainer
                    id="zone"
                    name="zone"
                    minLength={3}
                    maxLength={3}
                    validate={validateZone}
                    value={transmitter.zone}
                    validator={nValidator.zone}
                    onChange={setTransmitterState}
                    isRequired
                    title={
                      <FormattedMessage
                        id="transmitters.zone"
                        defaultMessage="Zona: "
                      />
                    }
                  />
                </div>
                <div className="form-group col-sm-12 col-md-4">
                  <InputContainer
                    id="description"
                    name="description"
                    minLength={2}
                    maxLength={30}
                    value={transmitter.description}
                    validator={nValidator.description}
                    onChange={setTransmitterState}
                    isRequired
                    title={
                      <FormattedMessage
                        id="transmitters.description"
                        defaultMessage="Descrição: "
                      />
                    }
                  />
                </div>
                {secondary &&
                  <div style={{ height: 'auto' }} className="form-group col-sm-12">
                    <label className="text-muted col-sm-12" htmlFor="general_info"><FormattedMessage id="transmitter.form.selectUser" defaultMessage="Utilizar para o usuário 2" /> ({secondary.first_name} {secondary.last_name}): </label>
                    <div style={{ height: 'auto' }} className="col-sm-12">
                      <CheckboxInput className="form-control" checked={useForSecondary} onChange={() => setUseForSecondary(!useForSecondary)} />
                    </div>
                  </div>
                }
                <div style={{ height: 'auto' }} className="form-group col-sm-12">
                  <label className="text-muted col-sm-12" htmlFor="general_info"><FormattedMessage id="transmitters.observations" defaultMessage="Observações" />: </label>
                  <div style={{ height: 'auto' }} className="col-sm-12">
                    <textarea
                      className="form-textarea form-control"
                      value={transmitter.general_info}
                      maxLength="140"
                      onChange={setTransmitterState}
                      name="general_info"
                      rows="10"
                      cols="50"
                    />
                  </div>
                </div>
                <div className="col-12 row" style={{paddingTop: '1rem'}}>
                  <button type="button" className="btn btn--form btn--color-red col-6" onClick={() => props.history.push(`/app/transmitters/list/${clientId}/`)}>
                    <span>
                      <i className="far fa-window-close" aria-hidden="true" style={{paddingRight:'1rem',paddingTop: '0.2rem'}}/>
                    </span>
                    <FormattedMessage id="form.button.cancel" defaultMessage="Cancelar" />
                  </button>
                  {isFormValid() ?
                    <button type="button" disabled={false} className={'btn btn--form btn--color-green col-6'} onClick={saveTransmitter}><span> <i className="far fa-save fa-lg" aria-hidden="true" style={{paddingRight:'1rem',paddingTop: '0.2rem'}}/></span><FormattedMessage id="form.button.save" defaultMessage="Salvar" /></button>
                    : <button type="button" disabled className={'btn btn--form btn--color-invalid col-6'}><span> <i className="far fa-save fa-lg" aria-hidden="true" style={{paddingRight:'1rem',paddingTop: '0.2rem'}}/></span><FormattedMessage id="form.button.save" defaultMessage="Salvar" /></button>
                  }
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

TransmitterForm.propTypes = {
  channelId: PropTypes.string.isRequired,
  params: PropTypes.shape({
    clientId: PropTypes.string.isRequired,
    transmitterId: PropTypes.string,
    method: PropTypes.string.isRequired,
  }).isRequired,
};

export default TransmitterForm;
