import * as types from '../actions/actionTypes';
import initialState from './initialState';


export default function globalServerReducer(state = initialState.attendTags, action) {
  if (action.type === types.LOAD_ATTEND_TAGS_SUCCESS) {
    return [...action.tags];
  }
  return state;
}
