import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { historyActionType, historyField, historyText } from '../../../../BLL/dictionary';

import style from './history.module.scss';

const SecondaryChangedRow = (props) => {
  const [expanded, expandInfo] = useState(false);

  const rowSecondary = props.log.secondary;
  const prevSecondary = props.index === props.array.length -1 ? {} : props.array[props.index + 1].secondary;

  const actionType = historyActionType(props.log.actionType);

  return (
    <div className={style.tableRowWrapper} style={{ height: `${expanded ? 'auto' : '3rem'}`, overflow: 'hidden' }}>
      <div className={style.tableRow}>
        <div className={style.tableBodyColumn}>
          {actionType}
        </div>
        <div className={style.bodyDataColumn}>{moment(props.log.actionDate).format('DD/MM/YYYY HH:mm:ss')}</div>
        <div className={style.bodyChangedByColumn}>
          {props.log.actionUser ? props.log.actionUser.firstName : ''} {props.log.actionUser ? props.log.actionUser.lastName : 'Contactto Vendas'}
        </div>
        <div className={style.bodyToggleButton}>
          <button className={style.toggleButton} onClick={() => expandInfo(!expanded)}>
            { !expanded
              ? <FormattedMessage id="history.expand" defaultMessage="Expandir" />
              : <FormattedMessage id="history.hide" defaultMessage="Ocultar" />
            }
          </button>
        </div>
      </div>
      <div className={style.rowExtraInfoWrapper}>
        {rowSecondary && Object.keys(rowSecondary).map((key) => {
          if (rowSecondary[key] && prevSecondary[key] !== rowSecondary[key] && key !== 'id' && props.log.actionType !== 'created' && key !== '__typename') {
            return (
              <div className={style.extraInfoTextWrapper}>
                <span className={style.infoLabel}>
                  {historyField(key)}:
                </span>
                <span className={style.infoText}>
                  <span style={{ color: 'red' }}>{historyText(prevSecondary[key], key)}</span> -> {historyText(rowSecondary[key], key)}
                </span>
              </div>
            )
          } else if (key !== 'id' && props.log.actionType === 'created' && key !== '__typename' && rowSecondary[key]) {
            return (
              <div className={style.extraInfoTextWrapper}>
                <span className={style.infoLabel}>
                  {historyField(key)}:
                </span>
                <span className={style.infoText}>{historyText(rowSecondary[key], key)}</span>
              </div>
            )
          }
          return <></>;
        })}
      </div>
    </div>
  )
};

export default SecondaryChangedRow;
