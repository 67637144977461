import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import cardStyle from './alarmsCard.module.scss';
import style from '../card.module.scss';

const AlarmsCard = ({ alarms, month, current, previous, urlCurrDate, urlPrevDate }) => (
  <div className={style.info} style={{ flexDirection: 'row' }}>
    <div className={cardStyle['subtitle-content']} style={{ flexDirection: 'column' }}>
      <div className={style.text}>
        <i className="fas fa-calendar" style={{ color: 'green', fontSize: '0.8rem' }} aria-hidden="true" />
        <span className={style.field} style={{ fontSize: '0.8rem' }}>
          <FormattedMessage id="card.alarms.month" defaultMessage=" Mês: " />
          {/* {' Mês: '} */}
        </span>
      </div>
      <div className={style.text}>
        <i className="fas fa-arrows-alt" style={{ color: 'steelblue', fontSize: '0.8rem' }} aria-hidden="true" />
        <span className={style.field} style={{ fontSize: '0.8rem' }}>
          <FormattedMessage id="card.alarms.totalAtt" defaultMessage=" Total de Atendimentos: " />
          {/* {' Total de Atendimentos: '} */}
        </span>
      </div>
      <div className={style.text}>
        <i className="fas fa-phone-volume" style={{ color: 'crimson', fontSize: '0.8rem', marginLeft: '4px' }} aria-hidden="true" />
        <span className={style.field} style={{ fontSize: '0.8rem' }}>
          <FormattedMessage id="card.alarms.totalVoice" defaultMessage=" Chamadas com voz: " />
          {/* {' Chamadas com voz: '} */}
        </span>
      </div>
    </div>
    <Link to={`/app/reports/list/attends/${urlPrevDate}`} className={cardStyle.prevDate}>
      <div className={style.text} style={{ fontSize: '0.8rem', marginTop: '3px', textAlign: 'center' }}>
        <span className={style.value}>{month[previous]}</span>
      </div>
      <div className={style.text} style={{ fontSize: '0.8rem', marginTop: '4px', textAlign: 'center' }}>
        <span className={style.value}>{alarms.prev_atts}</span>
      </div>
      <div className={style.text} style={{ fontSize: '0.8rem', marginTop: '4px', textAlign: 'center' }}>
        <span className={style.value}>{alarms.prev_voice}</span>
      </div>
    </Link>
    <Link to={`/app/reports/list/attends/${urlCurrDate}`} className={cardStyle.currDate}>
      <div className={style.text} style={{ fontSize: '0.8rem', marginTop: '3px', textAlign: 'center' }}>
        <span className={style.value}>{month[current]}</span>
      </div>
      <div className={style.text} style={{ fontSize: '0.8rem', marginTop: '4px', textAlign: 'center' }}>
        <span className={style.value}>{alarms.curr_atts}</span>
      </div>
      <div className={style.text} style={{ fontSize: '0.8rem', marginTop: '4px', textAlign: 'center' }}>
        <span className={style.value}>{alarms.curr_voice}</span>
      </div>
    </Link>
  </div>
);

AlarmsCard.defaultProps = {
  alarms: {
    curr_voice: 0,
    prev_voice: 0,
    curr_atts: 0,
    prev_atts: 0,
  },
};

AlarmsCard.propTypes = {
  alarms: PropTypes.shape({
    curr_voice: PropTypes.number,
    prev_voice: PropTypes.number,
    curr_atts: PropTypes.number,
    prev_atts: PropTypes.number,
  }).isRequired,
  current: PropTypes.number.isRequired,
  previous: PropTypes.number.isRequired,
  month: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  urlCurrDate: PropTypes.string.isRequired,
  urlPrevDate: PropTypes.string.isRequired,
};

export default AlarmsCard;
