import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Loading from '../../../../icons/Loading';
import RenderContactChangedRow from './ContactChangedRow';
import messages from '../../../../BLL/locale/messages';
import style from './history.module.scss';

const history = gql`
  query($token: String!, $contactId: Int!, $offset: Int) {
    contactHistory(token: $token, contactId: $contactId, offset: $offset) {
      historyId
      actionDate
      actionType
      contact {
        firstName
        lastName
        street
        number
        district
        complement
        zipCode
        city
        state
        relationship
        priority
        hasKeys
        isContractor
        email
        isDeleted
      }
      phone {
        historyId
        phoneType
        phone
        actionType
      }
      actions {
        historyId
        priority
        receiveSms
        receiveEmail
        receiveTelegram
        actionType
      }
      actionUser {
        id
        firstName
        lastName
      }
    }
  }
`;

const allContacts = gql`
  query($token: String!, $clientId: Int!) {
    allContacts(token: $token, clientId: $clientId) {
      id
      firstName
      lastName
      isDeleted
    }
  }
`;

const RenderSingleContact = ({ contactId }) => {
  let offset = 0;
  let stopFetching = false;
  return (
    <Query query={history} variables={{ token: sessionStorage.activeToken, contactId, offset }} fetchPolicy="cache-and-network">
      {({ loading, error, data, fetchMore }) => {
        if (error) return `Error ${error}`;
        return (
          <div>
            <div className={style.tableHeader}>
              <div className={style.tableHeaderColumn}>
                <FormattedMessage id="history.type" defaultMessage="Tipo" />
              </div>
              <div className={style.tableHeaderColumn}> Data </div>
              <div className={style.headerChangedByColumn}>
                <FormattedMessage id="history.modifiedBy" defaultMessage="Modificado Por" />
              </div>
              <div className={style.tableHeaderColumn} />
            </div>
            <div
              id="table-body"
              className={style.contactTableBody}
              onScroll={() => {
                const doc = document.getElementById('table-body');
                if ((doc.scrollTop + doc.offsetHeight) >= doc.scrollHeight) {
                  if (data.contactHistory && !loading && !stopFetching) {
                    offset += 20;
                    fetchMore({
                      variables: { offset },
                      updateQuery: (prev, { fetchMoreResult }) => {
                        if (fetchMoreResult.contactHistory
                            && fetchMoreResult.contactHistory.length === 0) {
                          stopFetching = true;
                          return prev;
                        }
                        return Object.assign({}, prev, {
                          contactHistory: [
                            ...prev.contactHistory,
                            ...fetchMoreResult.contactHistory,
                          ],
                        });
                      },
                    });
                  }
                }
              }}
            >
              {data && data.contactHistory && data.contactHistory
                .map((log, index, array) => (
                  <RenderContactChangedRow key={log.historyId} log={log} index={index} array={array} />))
              }
              {loading && <Loading text={<FormattedMessage id="attend.list.loading" defaultMessage="Carregando" />} />}
            </div>
          </div>
        );
      }}
    </Query>
  );
};

RenderSingleContact.propTypes = {
  contactId: PropTypes.string.isRequired,
};

class RenderContacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contactId: 0,
    };

    this.changeContactId = this.changeContactId.bind(this);
  }

  changeContactId(event) {
    if (event.target.value !== 0) {
      this.setState({
        contactId: event.target.value,
      });
    }
  }

  render() {
    const { contactId } = this.state;
    const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
    return (
      <Query query={allContacts} variables={{ token: sessionStorage.activeToken, clientId: this.props.clientId }} fetchPolicy="cache-and-network">
        {({ loading, error, data }) => {
          if (loading) return <div><FormattedMessage id="attend.list.loading" defaultMessage="Carregando" /></div>;
          if (error) return `Error ${error}`;
          return (
            <div className="form-group" style={{marginLeft: '1rem'}}>
              <select
                name="contacts"
                className={`${style.select} custom-select`}
                onChange={this.changeContactId}
              >
                <option value={0}> {messages[lang]['history.selectContact']} </option>
                {data.allContacts.map(contact => (
                  <option key={contact.id} value={contact.id}>
                    {contact.firstName} {contact.lastName}
                  </option>
                ))}
              </select>
              {contactId > 0 &&
                <RenderSingleContact contactId={contactId} />
              }
            </div>
          );
        }}
      </Query>
    );
  }
}

RenderContacts.propTypes = {
  clientId: PropTypes.string.isRequired,
};

export default RenderContacts;
