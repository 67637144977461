import React from 'react';
import PropTypes from 'prop-types';

const AlarmChargeDeviceIco = ({ height, svgClass, width, fill, transform }) => (
  <svg className={svgClass} width={width} height={height} fill={fill} transform={transform} viewBox="0 0 140 140" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M87.2,34.5c-16.9,0-33.8,0-50.8,0c-7.3,0-14.6,0-21.8,0c-6.2,0-11.1,4.1-12.2,10.2c-0.1,0.7-0.1,1.3-0.1,2
c0,16.1,0,32.2,0,48.4c0,7.4,5.2,12.6,12.6,12.6c12.1,0,24.1,0,36.2,0c12.1,0,24.2,0,36.3,0c6.3,0,11.2-4.1,12.3-10.3
c0.1-0.7,0.1-1.3,0.1-2c0-16.1,0-32.2,0-48.4C99.7,39.7,94.5,34.5,87.2,34.5z M39,60.1c0-0.1,0-0.3,0-0.4c0.1-0.2,0.2-0.5,0.2-0.7
c0.7-4.6,1.4-9.3,2.1-13.9c0.3-1.8,0.6-2.2,2.5-2.2c2.8,0,5.6,0,8.5,0c0.4,0,0.8,0,1.2,0.1c1,0.2,1.4,0.9,1.2,1.9
c-0.1,0.6-0.3,1.2-0.4,1.8c-0.4,1.8-0.8,3.6-1.3,5.5c0.3,0,0.6,0.1,0.9,0.1c2.5,0,4.9,0,7.4,0c0.7,0,1.6,0.2,1.7,0.9
c0.1,0.5-0.1,1.3-0.4,1.7c-3.2,4.9-6.5,9.7-9.7,14.5c-1.2,1.7-2.3,3.4-3.5,5.2c-0.5,0.8-1.6,1.1-2.4,0.6c-0.8-0.5-0.9-1.1-0.6-2
c0.7-2.3,1.3-4.5,1.9-6.8c0.4-1.6,0.9-3.2,1.3-4.9c-2.8,0-5.5,0-8.2,0C40.2,61.4,39.4,61.1,39,60.1z M63.1,92.4
c-4.4,0-8.7-0.1-13.1,0c-3.4,0.1-5.4-0.2-5.1-5.1c0.1-2.9,1.1-4,4-4c4.3,0,8.6,0,12.9,0c0.4,0,0.8,0,1.2,0.1
C63.1,86.4,63.1,89.3,63.1,92.4z M87.5,88.5c-0.1,2.7-1.1,3.8-3.9,3.8c-4.4,0-8.7,0-13.1,0c-0.4,0-0.8,0-1.2,0c0-3.1,0-6,0-9.1
c4.4,0,8.7,0.1,12.9,0C85.9,83.2,87.8,83.5,87.5,88.5z"
    />
    <g id="_x34_qdrug.tif">
      <g>
        <g>
          <path
            d="M98.5,80.8c1.6,0.2,3.1,0.2,4.5,0.6c6.8,1.7,11,7.1,11,14.1c0,1.4,0,2.7,0,4.1c0,2.6,2,4.6,4.4,4.6c2.4,0,4.6-2,4.6-4.5
c0.1-6.2,0-12.5,0-18.7c0-0.3-0.4-0.8-0.8-0.9c-4.5-1.9-7.3-5.2-8.1-10c-0.4-2.5-0.2-5.2-0.1-7.7c0-1.1,1.2-2,2.5-2.1
c0.8,0,1.6,0,2.6,0c0-0.9,0-1.8,0-2.6c0-1.7,0-3.3,0-5c0-1.6,1.1-2.8,2.6-2.8c1.5,0,2.6,1.2,2.6,2.8c0,2.5,0,4.9,0,7.5
c1.7,0,3.4,0,5.1,0c0-2.5,0-5,0-7.5c0-1.3,0.7-2.3,1.9-2.6c1.2-0.3,2.4,0.2,3,1.3c0.2,0.4,0.3,0.9,0.3,1.3c0,2.5,0,4.9,0,7.6
c0.8,0,1.6,0,2.4,0c1.7,0,2.8,1.1,2.8,2.8c0,2.4,0.2,4.9-0.2,7.3c-0.8,4.5-3.5,7.8-7.8,9.5c-0.7,0.3-1,0.7-1,1.5
c0,6.1,0,12.1,0,18.2c0,6-4.4,11.3-10,12.2c-6.5,1.1-12.1-2.9-13.9-8.5c-0.8-2.5-0.7-5-0.6-7.6c0.1-2.9-1.8-5.9-4.5-6.6
c-1-0.3-2.1-0.3-3.2-0.5"
          />
        </g>
      </g>
    </g>
  </svg>
);

AlarmChargeDeviceIco.defaultProps = {
  height: '75',
  svgClass: '',
  width: '75',
  fill: '#008D53',
  transform: 'translate(0,0)',
};

AlarmChargeDeviceIco.propTypes = {
  height: PropTypes.string,
  svgClass: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
  transform: PropTypes.string,
};

export default AlarmChargeDeviceIco;
