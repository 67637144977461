import React from 'react';
import PropTypes from 'prop-types';

const AlarmFireIcon = ({ height, svgClass, width, fill, transform }) => (
  <svg className={svgClass} width={width} height={height} fill={fill} transform={transform} viewBox="0 0 140 140" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M137.2,112.8c-4.6-7.8-9-15.6-13.5-23.5C109.4,64.5,95.1,39.8,80.7,15c-2.1-3.7-5.5-5.5-9.8-5.5c-4.2,0-7.6,1.8-9.8,5.5
C50.3,33.8,39.5,52.6,28.7,71.4C20.8,85,12.9,98.6,5.2,112.3c-1,1.7-1.8,3.7-2,5.7c-0.4,7,4.7,12.2,11.7,12.2c18.7,0,37.4,0,56.1,0
c18.6,0,37.3,0,55.9,0c1.5,0,3.2-0.2,4.6-0.7C137.9,127,141,119.3,137.2,112.8z M71.8,120.9c-4.6-2.3-9.5-4.2-13.9-6.9
c-7.4-4.5-12.9-10.7-14.4-19.6c-0.2-1.1-0.4-2.1-0.6-3.1c0-2.3,0-4.6,0-6.9c0.7-3.4,1.1-6.8,2.1-10c1.4-4.5,3.2-8.8,5.1-12.9
c0.1,2.8,0,5.7,0.5,8.5c0.6,3.2,2.8,5.2,6.1,6.1c8.3,2.1,15.4-3.4,16.3-12.4c0.7-7.7-1.4-16.9-7.1-23.3c0.1,0,0.3-0.1,0.4-0.1
c10,5.1,18,12,21,23.3c2.1,8,0.5,15.6-3.1,22.7c-2,3.9-4.4,7.6-7,11.1c-3.9,5.3-6.7,11-5.8,17.8c0.3,1.9,0.8,3.8,1.2,5.7
C72.4,120.9,72.1,120.9,71.8,120.9z M99,94.6c-0.3,1.4-0.6,2.7-1,4.1c-1.6,5-4.7,8.9-8.9,11.9c-4.2,3-8.4,6-12.8,9.1
c-1.5-3.3-1.6-6.6-0.6-10.1c1.2-4.2,3.4-7.8,6.2-11.1c5.7-6.9,8.6-14.9,9.8-23.6c0.1-1.1,0-2.3,0-3.7c3.5,5.3,5.9,10.7,6.9,16.7
c0.1,0.6,0.3,1.2,0.4,1.7C99,91.3,99,92.9,99,94.6z"
    />
  </svg>
);

AlarmFireIcon.defaultProps = {
  height: '75',
  svgClass: '',
  width: '75',
  fill: '#D8342B',
  transform: 'translate(0,0)',
};

AlarmFireIcon.propTypes = {
  height: PropTypes.string,
  svgClass: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
  transform: PropTypes.string,
};

export default AlarmFireIcon;
