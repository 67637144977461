const dotIcon = {
  path: 'M27.25,4.655C20.996-1.571,10.88-1.546,4.656,4.706C-1.571,10.96-1.548,21.076,4.705,27.3' +
    'c6.256,6.226,16.374,6.203,22.597-0.051C33.526,20.995,33.505,10.878,27.25,4.655z',
  fillOpacity: 1.0,
  scale: 0.6,
  fillColor: '#00b8cb',
  strokeColor: '#00b8cb',
  anchor: { x: 15, y: 35 },
}

export const firstIcon = {
  path: 'M13.29,19.16c-7.35,0-13.29,3.84-13.29,8.57v22.71c0,2.82,2.12,5.31,5.39,6.87v22c0,2.92,3.53,5.26,7.9,5.26s7.9-2.34,7.9-5.26v-22.01c3.25-1.56,5.38-4.05,5.38-6.86V27.73c0-4.73-5.96-8.57-13.27-8.57Z' +
  'M6.34,6.95C6.34,3.11,9.43,0,13.29,0s6.95,3.11,6.95,6.95-3.11,6.97-6.95,6.97-6.96-3.13-6.96-6.97Z',
  fillOpacity: 1.0,
  scale: 0.6,
  fillColor: '#4196CD',
  strokeColor: '#4196CD',
  anchor: { x: 15, y: 80 },
}

export const lastIcon = {
  path: 'M13.29,19.16c-7.35,0-13.29,3.84-13.29,8.57v22.71c0,2.82,2.12,5.31,5.39,6.87v22c0,2.92,3.53,5.26,7.9,5.26s7.9-2.34,7.9-5.26v-22.01c3.25-1.56,5.38-4.05,5.38-6.86V27.73c0-4.73-5.96-8.57-13.27-8.57Z' +
  'M6.34,6.95C6.34,3.11,9.43,0,13.29,0s6.95,3.11,6.95,6.95-3.11,6.97-6.95,6.97-6.96-3.13-6.96-6.97Z',
  fillOpacity: 1.0,
  scale: 0.6,
  fillColor: '#d8342b',
  strokeColor: '#d8342b',
  anchor: { x: 15, y: 80 },
}

export default dotIcon;
