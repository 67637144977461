import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Loading from '../../../../icons/Loading';
import RenderClientChangedRow from './ClientChangedRow';
import style from './history.module.scss';

const history = gql`
  query($token: String!, $clientId: Int!, $offset: Int) {
    clientHistory(token: $token, clientId: $clientId, offset: $offset) {
      historyId
      actionDate
      actionType
      actionUser {
        id
        firstName
        lastName
      }
      client {
        account
        firstName
        lastName
        isTest
        street
        number
        district
        complement
        zipCode
        city
        state
        cpf
        rg
        birthdate
        accountPhone
        contactPhone
        keyBox
        healthPlan
        generalInfo
        hospital
        gender
        limitations
        medications
        removalPlan
        imei
        equipmentId
        groupId
        isDeleted
      }
    }
  }
`;

const RenderClientHistory = ({ clientId }) => {
  let stopFetching = false;
  return (
    <Query query={history} variables={{ token: sessionStorage.activeToken, offset: 0, clientId }} fetchPolicy="cache-and-network">
      {({ loading, error, data, fetchMore }) => {
        if (error) return `Error ${error}`;
        return (
          <div>
            <div className={style.tableHeader}>
              <div className={style.tableHeaderColumn}>
                <FormattedMessage id="history.type" defaultMessage="Tipo" />
              </div>
              <div className={style.tableHeaderColumn}> <FormattedMessage id="history.date" defaultMessage="Data" /> </div>
              <div className={style.headerChangedByColumn}>
                <FormattedMessage id="history.modifiedBy" defaultMessage="Modificado Por" />
              </div>
              <div className={style.tableHeaderColumn} />
            </div>
            <div
              id="table-body"
              className={style.tableBody}
              onScroll={() => {
                const doc = document.getElementById('table-body');
                if ((doc.scrollTop + doc.offsetHeight) >= doc.scrollHeight) {
                  if (data.clientHistory && !loading && !stopFetching) {
                    fetchMore({
                      variables: { offset: data.clientHistory.length },
                      updateQuery: (prev, { fetchMoreResult }) => {
                        if (fetchMoreResult.clientHistory
                            && fetchMoreResult.clientHistory.length === 0) {
                          stopFetching = true;
                          return prev;
                        }
                        return Object.assign({}, prev, {
                          clientHistory: [...prev.clientHistory, ...fetchMoreResult.clientHistory],
                        });
                      },
                    });
                  }
                }
              }}
            >
              {data && data.clientHistory && data.clientHistory
              .map((log, index, array)  => (
                <RenderClientChangedRow
                  key={log.historyId}
                  log={log}
                  index={index}
                  array={array}
                />))
                }
              {loading && <Loading text={<FormattedMessage id="attend.list.loading" defaultMessage="Carregando" />} />}
            </div>
          </div>
        );
      }}
    </Query>
  );
};

RenderClientHistory.propTypes = {
  clientId: PropTypes.string.isRequired,
};

export default RenderClientHistory;
