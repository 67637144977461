/* eslint-disable import/no-anonymous-default-export */
export default {
  en: {
    'login.error.user': 'user field must be filled in',
    'login.error.password': 'password field must be filled in',
    'login.error.domain': 'domain field must be filled in',
    'login.error.invalidDomain': 'domain doesn\'t exist',
    'login.error.blocked': 'This Domain is blocked, please contact us.',
    'login.error.userorpassword': 'user or password incorrect',
    'login.error.minElPass': 'password must have at least {minEl} characters',
    'login.error.unauthorized': 'This username and password are not allowed in this login.',
    'login.error.unexpected': 'unexpected error',
    'login.success.redirect': 'Successfully logged in. You will be redirected...',
    'login.success.emailSent': 'An Email with the new password was successfully sent! Check your new password in your email.',
    'login.form.enter': 'login',
    'login.form.passwordForgotHeader': 'FORGOT MY PASSWORD',
    'login.form.passwordResetSend': 'send',
    'login.form.login': 'Login',
    'login.form.forgotPassword': 'Forgot my password',
    'login.form.resetPassMultipleUsers': 'Unfortunately your email is being used by multiple attendants, please add your login name',
    'login.form.username': 'Username',
    'login.form.domain': 'Domínio',
    'login.form.resetEmailInvalid': 'Invalid Email',
  },
  pt: {
    'login.error.user': 'usuário deve ser preenchido',
    'login.error.password': 'campo de senha deve ser preenchido',
    'login.error.domain': 'campo de domínio deve ser preenchido',
    'login.error.invalidDomain': 'domínio inexistente',
    'login.error.blocked': 'Este Domínio está bloqueado, favor entrar em contato conosco.',
    'login.error.userorpassword': 'usuário ou senha incorretos',
    'login.error.minElPass': 'senha deve conter no mínimo {minEl} caracteres',
    'login.error.unauthorized': 'Este usuário e senha não estão autorizados neste login.',
    'login.error.unexpected': 'erro inesperado',
    'login.success.redirect': 'Login realizado com sucesso Você será redirecionado...',
    'login.success.emailSent': 'Email com uma nova senha foi enviado com sucesso! Verifique sua nova senha em seu email.',
    'login.form.enter': 'entrar',
    'login.form.passwordForgotHeader': 'ESQUECI MINHA SENHA',
    'login.form.passwordResetSend': 'enviar',
    'login.form.login': 'Login',
    'login.form.forgotPassword': 'Esqueci minha senha',
    'login.form.resetPassMultipleUsers': 'Infelizmente seu e-mail é utilizado em múltiplos atendentes, por favor indique seu nome de login',
    'login.form.username': 'Usuário',
    'login.form.domain': 'Domínio',
    'login.form.resetEmailInvalid': 'Email inválido',
  },
};
