import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import WarningTimer from '../../../../BLL/warningTimer';
import style from './attendWarningMessage.module.scss';

const WarningMessagesComponent = (props) => {
  const [loading, setLoading] = useState(true);
  const [clock, setClock] = useState(undefined);
  const [clockInterval, setClockInterval] = useState(undefined);
  const [canDisableMsgs, setCanDisableMsgs] = useState(true);
  const [messagesDisabled, setMessagesDisabled] = useState(false);
  const [seconds, setSeconds] = useState(undefined);
  
  useEffect(() => {
    if (props.warningLimit) {
      const newClock = new WarningTimer(props.currentServerTime, props.alarmTime, props.warningLimit)
      setClock(newClock);
    }
  }, [props.warningLimit, props.currentServerTime, props.alarmTime, props.sendWarningMessages]);
  
  useEffect(() => {
    const startTimer = () => {
      let counter = 0;
      const interval = setInterval(() => {
        const newTimer = clock.timer(counter);
        setLoading(false);
        const newSeconds = parseInt(newTimer/1000);
        setSeconds(newSeconds);
        
        if (newTimer === -1) {
          clearInterval(interval);
          setCanDisableMsgs(false);
        }
        counter += 1;
      }, 1000);
      setClockInterval(interval);
    };
    
    if (clock) {
      startTimer();
    }
    
  }, [clock]);
  
  useEffect(() => {
    if (clockInterval && props.sendWarningMessages === false) {
      setLoading(false);
      clearInterval(clockInterval);
      setMessagesDisabled(true);
    }
    
  }, [props.sendWarningMessages, clockInterval])
  
  const disableWarningMessages = () => {
    setMessagesDisabled(true);
    clearInterval(clockInterval);
    props.stopWarningMessages();
  }
  
  if (loading) {
    return (
      <div className="col-sm-6 col-md-4 col-lg-4">
        <FormattedMessage id="attend.list.loading" defaultMessage="Carregando" />...
      </div>
    )
  }

  if (messagesDisabled) {
    return (
      <div className="col-sm-6 col-md-4 col-lg-4">
        <span style={{ width: '100%', float: 'left' }}>
          <FormattedMessage id="attend.warning.cancel" defaultMessage="Mensagens de aviso canceladas." />
        </span>
      </div>
    );
  }
  
  if (seconds === 0) {
    return (
      <div className="col-sm-6 col-md-4 col-lg-4">
        <span style={{ width: '100%', float: 'left' }}>
          <FormattedMessage id="attend.warning.sent" defaultMessage="Mensagens de aviso enviadas." />
        </span>
      </div>
    );
  }

  return (
    <div className="col-sm-6 col-md-4 col-lg-4">
      <span style={{ width: '100%', float: 'left' }}>
        <FormattedMessage id="attend.warning.sendingIn" values={{ seconds: seconds }} defaultMessage="Enviando mensagens de aviso em {seconds} segundos..." />
      </span>
      <div className={style.container}>
        <button
          className={`${style['button-warning']} ${!canDisableMsgs && style['button-warning-disabled']}`}
          onClick={() => disableWarningMessages()}
          disabled={!canDisableMsgs}>
            <FormattedMessage id="attend.warning.cancelMessages" defaultMessage="Cancelar envio" />
        </button>
      </div>
    </div>
  );
};

WarningMessagesComponent.propTypes = {
  warningLimit: PropTypes.string.isRequired,
  currentServerTime: PropTypes.string.isRequired,
  alarmTime: PropTypes.string.isRequired,
  stopWarningMessages: PropTypes.func.isRequired,
}

export default WarningMessagesComponent;
