import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import FormButton from '../Button/FormButton';
import style from './modalConfirm.module.scss';

const ConfirmModal = ({ setModalInfo, action, message, height }) =>
  <div className={style.wrapper} style={{ height }}>
    <div className={style.title}>{message}</div>
    <FormButton
      activeSubmit
      cancelAction={() => {
        setModalInfo({ client: {}, showModal: false });
      }}
      submitAction={action}
      style={{
        bottom: '20px',
        borderRadius: '5px',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '289px',
      }}
      nameBtn={{
        first: <FormattedMessage id="modal.confirm.no" defaultMessage="NÃO" />,
        second: <FormattedMessage id="modal.confirm.yes" defaultMessage="SIM" />,
      }}
      btnStyle={{ height: '35px', width: '130px' }}
    />
  </div>;

ConfirmModal.defaultProps = {
  message: <FormattedMessage id="modal.confirm.defaultMessage" defaultMessage="Tem certeza que deseja realizar esta ação?" />,
  height: '',
};


ConfirmModal.propTypes = {
  action: PropTypes.func.isRequired,
  setModalInfo: PropTypes.func.isRequired,
  // message: PropTypes.string,
  height: PropTypes.string,
  message: PropTypes.shape({
    key: PropTypes.number,
  }),
};

export default ConfirmModal;
