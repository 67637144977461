export default {
  en: {
    1: 'SOS Emergency',
    2: 'Fall Detecion',
    3: 'Geo-fence 1 Entered',
    4: 'Geo-fence 1 Exited',
    5: 'Geo-fence 2 Entered',
    6: 'Geo-fence 2 Exited',
    7: 'Geo-fence 3 Entered',
    8: 'Geo-fence 3 Exited',
    9: 'Interval Report',
    10: 'Status Report',
    13: 'Battery Dead',
    14: 'Post Parameters',
    17: 'Test Call',
    18: 'SOS Remote',
    19: 'Low Battery',
    20: 'Device Booted',
    21: 'SOS Breadcrumb',
    22: 'Wi-Fi Scan',
    23: 'Reboot',
    24: 'Power Off',
    25: 'Track',
    40: 'Medication 1 Alert',
    41: 'Medication 1 Acknowledged',
    42: 'Medication 1 Missed',
    43: 'Medication 2 Alert',
    44: 'Medication 2 Acknowledged',
    45: 'Medication 2 Missed',
    46: 'Medication 3 Alert',
    47: 'Medication 3 Acknowledged',
    48: 'Medication 3 Missed',
    49: 'Medication 4 Alert',
    50: 'Medication 4 Acknowledged',
    51: 'Medication 4 Missed',
  },
  pt: {
    1: 'Emergência SOS',
    2: 'Detecção de Queda',
    3: 'Entrou Cerca Geográfica 1',
    4: 'Saiu Cerca Geográfica 1',
    5: 'Entrou Cerca Geográfica 2',
    6: 'Saiu Cerca Geográfica 2',
    7: 'Entrou Cerca Geográfica 3',
    8: 'Saiu Cerca Geográfica 3',
    9: 'Relatório de Intervalo',
    10: 'Resultado de Status',
    13: 'Sem Bateria',
    14: 'Parâmetros',
    17: 'Chamada de Teste',
    18: 'SOS Remoto',
    19: 'Bateria Baixa',
    20: 'Dispositivo Ligado',
    21: 'Trilha de SOS',
    22: 'Wi-Fi Scan',
    23: 'Reiniciado',
    24: 'Desligar',
    25: 'Localização',
    40: 'Medicação 1 Alerta',
    41: 'Medicação 1 Reconhecida',
    42: 'Medicação 1 Perdida',
    43: 'Medicação 2 Alerta',
    44: 'Medicação 2 Reconhecida',
    45: 'Medicação 2 Perdida',
    46: 'Medicação 3 Alerta',
    47: 'Medicação 3 Reconhecida',
    48: 'Medicação 3 Perdida',
    49: 'Medicação 4 Alerta',
    50: 'Medicação 4 Reconhecida',
    51: 'Medicação 4 Perdida',
  },
};
