import React from 'react';
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl';
import SelectInput from '../../../Common/SelectInput2';
import CheckboxInput from '../../../Common/CheckboxInput';

import formStyle from '../../../../css/form.module.scss';
import style from './programming.module.scss';

const TrackerEv07bProgram = ({ programming, setParameters, sendConfiguration}) => (
  <div className={style.trackerMenuContainer}>
    {programming &&
      <>
        <div className={style.trackerMenuValue}>
          <span className={`${style.trackerMenuText} col-sm-12 col-md-6 col-lg-6`}><FormattedMessage id="client.tracker.falldownActivate" defaultMessage="Ativar detecção de quedas" />: </span>
          <CheckboxInput name="FALLDOWN_ALERT.enabled" className={`${style.checkbox} col-sm-12 col-md-6 col-lg-6`} checked={programming.FALLDOWN_ALERT.enabled} onChange={setParameters} />
        </div>
        {programming.FALLDOWN_ALERT.enabled &&
          <div className={style.trackerMenuValue}>
            <span className={`${style.trackerMenuText} col-sm-12 col-md-6 col-lg-6`}><FormattedMessage id="client.tracker.sensitivity" defaultMessage="Sensitividade" />: </span>
            <div className={`${style.blockValue} col-sm-12 col-md-6 col-lg-6`}>
              <SelectInput
                items={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
                selectClass={`${style.selectInput} ${
                  formStyle['select--enable'] 
                } custom-select`}
                value={programming.FALLDOWN_ALERT.sensitivity_level}
                onChange={setParameters}
                name="FALLDOWN_ALERT.sensitivity_level"
                emptyOption={false}
              />
            </div>
          </div>
        }
        <div className={style.trackerMenuValue}>
          <span className={`${style.trackerMenuText} col-sm-12 col-md-6 col-lg-6`}>
            <FormattedMessage id="client.tracker.motionActivate" defaultMessage="Ativar detecção de movimento" />:
          </span>
          <CheckboxInput
            name="MOTION_ALERT.enabled"
            className={`${style.checkbox} col-sm-12 col-md-6 col-lg-6`}
            checked={programming.MOTION_ALERT.enabled}
            onChange={setParameters}
          />
        </div>
        {programming.MOTION_ALERT.enabled &&
          <>
            <div className={style.trackerMenuValue}>
              <span className={`${style.trackerMenuText} col-sm-12 col-md-6 col-lg-6`}>
                <FormattedMessage id="client.tracker.movmentActivationPeriod" defaultMessage="Tempo para ativação (em segundos)" />:
              </span>
              <div className={`${style.blockValue} col-sm-12 col-md-6 col-lg-6`}>
                <SelectInput
                  items={[3, 4, 5, 6, 7, 8, 9, 10]}
                  selectClass={`${style.selectInput} ${
                    formStyle['select--enable'] 
                  } custom-select`}
                  value={programming.MOTION_ALERT.movement_activation}
                  onChange={setParameters}
                  name="MOTION_ALERT.movement_activation"
                  emptyOption={false}
                />
              </div>
            </div>
            <div className={style.trackerMenuValue}>
              <span className={`${style.trackerMenuText} col-sm-12 col-md-6 col-lg-6`}>
                <FormattedMessage id="client.tracker.movementAlertCooldown" defaultMessage="Tempo para envio entre alarmes (em segundos)" />:
              </span>
              <div className={`${style.blockValue} col-sm-12 col-md-6 col-lg-6`}>
                <SelectInput
                  items={[60, 120, 240, 480, 960, 1920, 3840, 7680]}
                  selectClass={`${style.selectInput} ${
                    formStyle['select--enable'] 
                  } custom-select`}
                  value={programming.MOTION_ALERT.cooldown_period}
                  onChange={setParameters}
                  name="MOTION_ALERT.cooldown_period"
                  emptyOption={false}
                />
              </div>
            </div>
          </>
        }
        <div className={style.trackerMenuValue}>
          <span className={`${style.trackerMenuText} col-sm-12 col-md-6 col-lg-6`}><FormattedMessage id="client.tracker.noMotionActivate" defaultMessage="Ativar detecção de inatividade" />: </span>
          <CheckboxInput name="NO_MOTION_ALERT.enabled" className={`${style.checkbox} col-sm-12 col-md-6 col-lg-6`} checked={programming.NO_MOTION_ALERT.enabled} onChange={setParameters} />
        </div>
        {programming.NO_MOTION_ALERT.enabled &&
          <div className={style.trackerMenuValue}>
            <span className={`${style.trackerMenuText} col-sm-12 col-md-6 col-lg-6`}>
              <FormattedMessage id="client.tracker.noMovementStaticPeriod" defaultMessage="Tempo parado para detecção de inatividade (em segundos, entre 300 e 36000)" />:
            </span>
            <div className={`${style.blockValue} col-sm-12 col-md-6 col-lg-6`}>
              <input
                className={`form-control ${style.programmingInput}`}
                name="NO_MOTION_ALERT.static_period"
                type="number"
                value={programming.NO_MOTION_ALERT.static_period}
                onChange={setParameters}
                min="60"
                max="36000"
              />
            </div>
          </div>
        }
        <div className={style.trackerMenuValue}>
          <span className={`${style.trackerMenuText} col-sm-12 col-md-6 col-lg-6`}><FormattedMessage id="client.tracker.overspeedActivate" defaultMessage="Ativar detecção excesso de velocidade" />: </span>
          <CheckboxInput name="OVER_SPEED_ALERT.enabled" className={`${style.checkbox} col-sm-12 col-md-6 col-lg-6`} checked={programming.OVER_SPEED_ALERT.enabled} onChange={setParameters} />
        </div>
        {programming.OVER_SPEED_ALERT.enabled &&
          <div className={style.trackerMenuValue}>
            <span className={`${style.trackerMenuText} col-sm-12 col-md-6 col-lg-6`}>
              <FormattedMessage id="client.tracker.detectionSpeed" defaultMessage="Velocidade para detecção (KM/h)" />:
            </span>
            <div className={`${style.blockValue} col-sm-12 col-md-6 col-lg-6`}>
              <SelectInput
                items={[40, 50, 60, 70, 80, 90, 100, 120, 140, 160, 180, 200, 240, 260, 300]}
                selectClass={`${style.selectInput} ${
                  formStyle['select--enable'] 
                } custom-select`}
                value={programming.OVER_SPEED_ALERT.treshold}
                onChange={setParameters}
                name="OVER_SPEED_ALERT.treshold"
                emptyOption={false}
              />
            </div>
          </div>
        }
        <div className={style.trackerMenuValue}>
          <span className={`${style.trackerMenuText} col-sm-12 col-md-6 col-lg-6`}><FormattedMessage id="client.tracker.tiltAlertActivate" defaultMessage="Ativar detecção de inclinação" />: </span>
          <CheckboxInput name="TILT_ALERT.enabled" className={`${style.checkbox} col-sm-12 col-md-6 col-lg-6`} checked={programming.TILT_ALERT.enabled} onChange={setParameters} />
        </div>
        {programming.TILT_ALERT.enabled &&
          <>
            <div className={style.trackerMenuValue}>
              <span className={`${style.trackerMenuText} col-sm-12 col-md-6 col-lg-6`}>
                <FormattedMessage id="client.tracker.tiltAlertAngle" defaultMessage="Limite de Ângulo (em Graus)" />:
              </span>
              <div className={`${style.blockValue} col-sm-12 col-md-6 col-lg-6`}>
                <SelectInput
                  items={[30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90]}
                  selectClass={`${style.selectInput} ${
                    formStyle['select--enable'] 
                  } custom-select`}
                  value={programming.TILT_ALERT.angle_treshold}
                  onChange={setParameters}
                  name="TILT_ALERT.angle_treshold"
                  emptyOption={false}
                />
              </div>
            </div>
            <div className={style.trackerMenuValue}>
              <span className={`${style.trackerMenuText} col-sm-12 col-md-6 col-lg-6`}>
                <FormattedMessage id="client.tracker.tiltTimeTreshold" defaultMessage="Tempo parado para detecção de inclinação (em segundos, entre 10 e 3600)" />:
              </span>
              <div className={`${style.blockValue} col-sm-12 col-md-6 col-lg-6`}>
                <input
                  className={`form-control ${style.programmingInput}`}
                  name="TILT_ALERT.time_treshold"
                  type="number"
                  value={programming.TILT_ALERT.time_treshold}
                  onChange={setParameters}
                  min="10"
                  max="3600"
                />
              </div>
              
            </div>
          </>
        }
        <div className="col-12">
          <button className={style.trackerMenuBtn} onClick={() => sendConfiguration(programming)}>
            <span>
              <i
                className="far fa-save fa-lg"
                aria-hidden="true"
                style={{ paddingRight: '0.5rem', paddingTop: '0.2rem' }}
              />
            </span>
            <FormattedMessage id="client.tracker.saveSettings" defaultMessage="Salvar Configurações" />
          </button>

        </div>
      </>
    }
  </div>
);

TrackerEv07bProgram.propTypes = {
  sendConfiguration: PropTypes.func.isRequired,
  programming: PropTypes.shape({
    FALLDOWN_ALERT: PropTypes.shape({
      sensitivity_level: PropTypes.number,
      enabled: PropTypes.bool,
    }),
    MOTION_ALERT: PropTypes.shape({
      movement_activation: PropTypes.number,
      cooldown_period: PropTypes.number,
      enabled: PropTypes.bool,
    }),
    NO_MOTION_ALERT: PropTypes.shape({
      static_period: PropTypes.number,
      enabled: PropTypes.bool,
    }),
    OVER_SPEED_ALERT: PropTypes.shape({
      treshold: PropTypes.number,
      enabled: PropTypes.bool,
    }),
    TILT_ALERT: PropTypes.shape({
      angle_treshold: PropTypes.number,
      time_treshold: PropTypes.number,
      enabled: PropTypes.bool,
    })
  }).isRequired,
};

export default TrackerEv07bProgram;
