import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Arrow from '../../icons/Arrow';
import style from './customCardHOC.module.scss'

export default class CustomCardHOC extends Component {
  constructor(props) {
    super(props);

    this.state = {
      current: 0,
    };

    this.Header = this.Header.bind(this);
    this.Body = this.Body.bind(this);
    this.Footer = this.Footer.bind(this);

    this.makeRandonNumber = this.makeRandonNumber.bind(this);
    this.randomColor = this.randomColor.bind(this);

    this.validateWordLenght = this.validateWordLenght.bind(this);
    this.handleClick = this.handleClick.bind(this);

    this.moveComponentToLeft = this.moveComponentToLeft.bind(this);
    this.moveComponentToRight = this.moveComponentToRight.bind(this);
  }

  /*
    Verifica quantidade de componentes internos para fazer o carrousel a direita
  */
  moveComponentToRight() {
    const atual = this.state.current + 1;
    if (atual < this.props.childComponent.length) {
      this.setState({ current: atual });
    }
  }

  /*
    Verifica quantidade de componentes internos para fazer o carrousel a esquerda
  */
  moveComponentToLeft() {
    const atual = this.state.current - 1;
    if (atual >= 0) {
      this.setState({ current: atual });
    }
  }

  /*
    Calcula a quantidade de linhas no header para melhor posicionamento
  */
  calcHeadersSize() {
    if (this.props.headers.length === 1) {
      return 46;
    } else if (this.props.headers.length === 2) {
      return 60;
    }
    return 80;
  }

  /*
    Calcula a margin de linhas no header para melhor posicionamento
  */
  calcMargin() {
    if (this.props.headers[this.state.current].length === 1) {
      return '0px';
    } else if (this.props.headers[this.state.current].length === 2) {
      return '8px';
    }
    return '13px';
  }

  /*
    Gera número aleatório baseado na stack máxima de 255 posições
  */
  makeRandonNumber(limit = 255) {
    return Math.floor(Math.random() * limit);
  }

  /*
    Gera cores(rgba) aleatórias baseado na stack máxima de 255 posições
  */
  randomColor() {
    return (
      `rgba(${ 
      this.makeRandonNumber(255) 
      },${ 
      this.makeRandonNumber(255) 
      },${ 
      this.makeRandonNumber(255) 
      },0.7)`
    );
  }

  /*
    Dispara o callback de click do component
  */
  handleClick() {
    this.props.onClick();
  }

  /*
    Valida quantidade de caracteres máximos para serem exibidos no header sem comprometer a boa leitura
  */
  validateWordLenght(word) {
    const len = word.length;
    let idx = 0;
    let result = '';
    if (len > 50) {
      while (idx < 47) {
        result += word.charAt(idx);
        idx++;
      }
      result += '...';
      return result;
    }
    return word;
  }

  Header() {
    if (this.props.childComponent.length > 1) {
      const theme = Array.isArray(this.props.theme) ? this.props.theme[this.state.current] : this.props.theme;
      return (
        <div
          className={style.headerWrapper}
          style={{
            backgroundColor: this.props.theme
              ? theme
              : this.randomColor(),
          }}
        >
          {this.state.current - 1 >= 0 &&
            <div className={style.leftContainer}>
              <Arrow
                side="left"
                headers={this.props.headers[this.state.current]}
                marginTop={this.calcMargin()}
                onClick={this.moveComponentToLeft}
              />
            </div>
          }
          {this.state.current + 1 < this.props.childComponent.length &&
            <div className={style.rightContainer}>
              <Arrow
                side="right"
                headers={this.props.headers[this.state.current]}
                marginTop={this.calcMargin()}
                onClick={this.moveComponentToRight}
              />
            </div>
          }
          {this.props.headers[this.state.current].map((h, index) => (
            <div
              className={style.headerItem}
              style={{
                maxHeight: this.props.maxHeight,
                fontSize: this.props.headerFontSize,
              }}
              key={`id_{${index}}`}
            >
              {this.validateWordLenght(h)}
            </div>
          ))}
        </div>
      );
    }
    return (
      <div
        className={style.header}
        style={{
          backgroundColor: this.props.theme
            ? this.props.theme
            : this.randomColor(),
        }}
      >
        {this.props.headers.map((h, index) => (
          <div
            className={style.headerItem}
            style={{
              maxHeight: this.props.maxHeight,
              fontSize: this.props.headerFontSize,
            }}
            key={`id_{${index}}`}
          >
            {this.validateWordLenght(h)}
          </div>
        ))}
      </div>
    );
  }

  Body() {
    return (
      <div
      className={style.body}
        style={{
          backgroundColor: this.props.theme
          ? this.props.theme
          : this.randomColor(),
        }}
      >
        {this.props.childComponent[this.state.current]}
      </div>
    );
  }

  Footer() {
    if (this.props.footerComponent) {
      return (
        <div className={style.footer}>
          <div style={{ float: this.props.footerFloat }}>
            {this.props.footerComponent}
          </div>
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <div
        className={style.wrapper}
        style={{
          borderColor: this.props.theme,
          float: this.props.float,
          minHeight: this.props.minHeight ? this.props.minHeight : '',
          width: this.props.width,
        }}
        onClick={this.handleClick}
      >
        {this.Header()}
        {this.Body()}
        {this.Footer()}
      </div>
    );
  }
}

CustomCardHOC.propTypes = {
  headers: PropTypes.any,
  childComponent: PropTypes.array.isRequired,
  footerComponent: PropTypes.element,
  onClick: PropTypes.func.isRequired,
  theme: PropTypes.string,
  float: PropTypes.string,
  footerFloat: PropTypes.string,
  minHeight: PropTypes.string,
  width: PropTypes.string,
  maxHeight: PropTypes.string,
  headerFontSize: PropTypes.string,
};

CustomCardHOC.defaultProps = {
  theme: '',
  float: 'left',
  footerFloat: 'left',
  minHeight: '',
  maxHeight: '1.1rem',
  headerFontSize: '1rem',
  width: '100%',
};
