import React, { useState, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
import axios from 'axios';
import L from 'leaflet';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import br from 'date-fns/locale/pt-BR';
import us from 'date-fns/locale/en-US';
import { host, getDomain, getToken } from '../../../../BLL/environment';
import CustomCardHOC from '../../../Cards/CustomCardHOC';
import CustomDatePickerInput from '../../../Common/CustomDatePickerInput';
import Loading from '../../../../icons/Loading';
import Order from '../../../../icons/Order';
import * as clientsActions from '../../../../actions/clientsActions';
import messages from '../../../../BLL/locale/messages';
import messagesAlarms from '../../../../BLL/locale/messagesAlarms';

import style from './tracker.module.scss';
import '../../../../css/middle.scss';
import './stylesheets/datepicker-cssmodules.scss';

registerLocale('pt-br', br);
registerLocale('en-us', us);

const MapPoint = (props) => {
  const pointList = props.pointList;
  const middleIcon = L.divIcon({
    className: 'custom-icon',
    html: ReactDOMServer.renderToString(<figure className={style.mapDot} />),
  });

  if (pointList.length > 0) {
    const positions = pointList.map(point => (
      [point.LATITUDE, point.LONGITUDE]
    ));

    const SinglePointToHoC = (
      <Map center={positions[0]} zoom={15} className={style.leafletContainer}>
          <TileLayer
            attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {pointList.map((position, index) => {
            const eventType = position.EVENT ? position.EVENT.substr(0, 1) : '';
            const eventCode = position.EVENT ? position.EVENT.substr(1, 3) : '';
            if (index === 0 || index === pointList.length -1) {
              return (
                <Marker position={[position.LATITUDE, position.LONGITUDE]}>
                  <Popup minWidth={90}>
                    <span>Bateria: {position.BATTERY}
                      <br />Data: {moment(parseInt(position.RCV_TIMESTAMP, 10)).local().format('YYYY-MM-DD HH:mm:ss')}
                      <br />Na base: {position.ON_BASE === '1' ? 'Sim' : 'Não'}
                      <br />Carregando: {position.IS_CHARGING === '1' ? 'Sim' : 'Não'}
                      <br />Localizado por: {position.LOCATION_BY === 'G' ? 'GPS' : 'Wi-Fi'}
                      <br />Sinal: {position.SIGNAL}
                      <br />Evento: {messagesAlarms[props.lang][eventCode]}
                      <br />{position.ADDRESS}
                    </span>
                  </Popup>
                </Marker>
              );
            }
            return (
              <Marker
                position={[position.LATITUDE, position.LONGITUDE]}
                icon={middleIcon}
                key={index}  // eslint-disable-line
              >
                <Popup minWidth={90}>
                  <span>Bateria: {position.BATTERY}
                    <br />Data: {moment(parseInt(position.RCV_TIMESTAMP, 10)).local().format('YYYY-MM-DD HH:mm:ss')}
                    <br />Na base: {position.ON_BASE === '1' ? 'Sim' : 'Não'}
                    <br />No carregador: {position.IS_CHARGING === '1' ? 'Sim' : 'Não'}
                    <br />Localizado por: {position.LOCATION_BY === 'G' ? 'GPS' : 'Wi-Fi'}
                    <br />Sinal: {position.SIGNAL}
                    <br />Evento: {position.EVENT}
                    <br />{position.ADDRESS}
                  </span>
                </Popup>
              </Marker>
            )
          })}
      </Map>
    )

    return (
      <div className={style.mapWrapper}>
        <CustomCardHOC
          childComponent={[SinglePointToHoC]}
          onClick={() => { }}
          headers={[<FormattedMessage id="client.tracker.mapHeader" defaultMessage="MAPA" />]}
          minHeight="380px"
          theme="#ffffff"
        />
        <button className={style.trackerPositionBtn}  style={{margin : '0.3rem 0'}} onClick={props.fetchLatestPoint}>
          <span style={{ paddingRight: '1rem'}}> <i className="fas fa-map-marker-alt fa-lg" aria-hidden="true" /></span><FormattedMessage id="client.tracker.latest" defaultMessage="Última Posição" />
        </button>
      </div>
    )
  }
  return (
    <Loading text={<FormattedMessage id="attend.list.loading" defaultMessage="Carregando" />} />
  );
}

const TrackerClimax = (props) => {
  const [startDate, setStartDate] = useState(moment().locale('pt-br').subtract(1, 'hours').toDate());
  const [endDate, setEndDate] = useState(moment().locale('pt-br').toDate());
  const [pointList, setPointList] = useState([]);
  const [client, setClient] = useState(undefined);
  const [showMap, setShowMap] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    fetchClient(parseInt(props.match.params.clientId, 10));
  }, [props.match.params.clientId]);

  useEffect(() => {
    if (client) {
      fetchLatestPoint();
    }
  }, [client])

  const fetchClient = (clientId) => {
    axios.get(`https://${getDomain()}${host()}/api/clients/${clientId}/`, {
      headers: { Authorization: `Token ${getToken()}` },
    }).then(res => setClient(Object.assign({}, res.data)))
    .catch(error => console.log(error));
  };

  const fetchLatestPoint = () => {
    axios({
      url: `https://${getDomain()}${host()}/api/clients/${client.id}/tracker-iot/last/`,
      method: 'GET',
      responseType: 'json',
      headers: { Authorization: `Token ${getToken()}` },
    }).then((res) => {
      if (res.data.length > 0) {
        setPointList(res.data);
        setErrorMessage('');
      } else {
        const newErrorMessage = 'ESTE DISPOSITIVO NÃO TEM DADOS PARA O DIA DE HOJE, VERIFIQUE A BATERIA DO SEU SISTEMA';
        const errorComponent = (<FormattedMessage
          id="client.tracker.errorEmptyPoint"
          defaultMessage={newErrorMessage}
        />);
        setPointList([]);
        setErrorMessage(errorComponent);
      }
    }).catch((err) => {
      let newErrorMessage = '';
      let errorComponent = {};
      if (err.response.data.imei) {
        newErrorMessage = 'ESTE USUÁRIO NÃO ESTÁ COM O IMEI CADASTRADO.\n';
        errorComponent = <FormattedMessage id="client.tracker.errorNoIMEI" defaultMessage={newErrorMessage} />;
      } else if (err.response.data.noData) {
        newErrorMessage = 'NENHUM PONTO ACHADO PARA ESTE DISPOSITIVO. \n VERIFIQUE SE ESTE RASTREADOR ESTÁ LIGADO';
        errorComponent = <FormattedMessage id="client.tracker.ihelpNoData" defaultMessage={newErrorMessage} />;
      } else {
        newErrorMessage = 'ERRO INESPERADO';
        errorComponent = <FormattedMessage id="client.tracker.errorUnexpected" defaultMessage={newErrorMessage} />;
      }
      setErrorMessage(errorComponent);
    });
  };

  const fetchPoints = () => {
    const startDateString = moment(startDate).format('YYYY-MM-DD');
    const endDateString = moment(endDate).format('YYYY-MM-DD');
    const params = `?start_date=${startDateString}&start_hour=${startDate.getHours()}&end_date=${endDateString}&end_hour=${endDate.getHours()}`;
    if (client) {
      axios({
        url: `https://${getDomain()}${host()}/api/clients/${client.id}/tracker-iot/${params}`,
        method: 'GET',
        responseType: 'json',
        headers: { Authorization: `Token ${getToken()}` },
      }).then((res) => {
        if (res.data.length > 0) {
          setPointList(res.data);
          setErrorMessage('');
        } else {
          const newErrorMessage = 'SEM DADOS PARA AS DATAS ENTRE {startDate} AS {startHour} HORAS E {endDate} AS {endHour} HORAS';
          const errorComponent = (<FormattedMessage
            id="client.tracker.errorEmptyPointsDates"
            defaultMessage={newErrorMessage}
            values={{
              startDate: startDateString,
              endDate: endDateString,
              startHour: startDate.getHours(),
              endHour: endDate.getHours(),
            }}
          />);
          setPointList([]);
          setErrorMessage(errorComponent);
        }
      }).catch(err => console.log(err));
    }
  };

  const rotateMap = showMap ? style.rotate : '';
  const rotateHistory = showHistory ? style.rotate : '';
  const fullName = client ? `${client.first_name} ${client.last_name}`.toUpperCase() : '';

  return (
    <div className="contactto-middle-content">
      <div className={style.trackerTitle}>
        <span className="blue"><FormattedMessage id="client.tracker.trackerFrom" defaultMessage="RASTREADOR DE " /></span>
        <span className="grey">{fullName}</span>
      </div>
      <div className={style.trackerContent}>
        <div className={style.trackerMenu}>
          <div style={{ width: '100%', float: 'left' }}>
            <button className={style.trackerMenuOption} onClick={() => setShowMap(!showMap)}>
              <FormattedMessage id="client.tracker.mapHeader" defaultMessage="Mapa" />
              <Order width="25" height="15" className={`${style.trackerSvg} ${rotateMap}`}/>
            </button>
          </div>
          {showMap &&
            <MapPoint
              pointList={pointList}
              lang={!localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang}
              fetchLatestPoint={fetchLatestPoint}
            />
          }
          <div style={{ width: '100%', float: 'left', marginTop: '25px' }}>
            <button className={style.trackerMenuOption} onClick={() => setShowHistory(!showHistory)}>
              <FormattedMessage id="client.tracker.history" defaultMessage="Histórico" />
              <Order width="25" height="15" className={`${style.trackerSvg} ${rotateHistory}`} />
            </button>
          </div>
          {showHistory &&
            <div className={style.trackerMenuContainer}>
              <div className={style.trackerMenuValue}>
                <span className={style.trackerMenuText}><FormattedMessage id="client.tracker.begining" defaultMessage="Início: " /></span>
                <div className={style.trackerMenuDate}>
                  <DatePicker
                    customInput={<CustomDatePickerInput />}
                    selected={startDate}
                    onChange={(ev) => setStartDate(ev)}
                    locale={props.lang === 'pt' ? 'pt-br' : 'en-us'}
                    showTimeSelect
                    timeFormat="HH"
                    timeIntervals={60}
                    dateFormat="dd/MM/yyyy - HH:00"
                    timeCaption={messages[props.lang]['client.tracker.hour']}
                    previousMonthButtonLabel=""
                    nextMonthButtonLabel=""
                  />
                </div>
              </div>
              <div className={style.trackerMenuValue}>
                <span className={style.trackerMenuText}><FormattedMessage id="client.tracker.end" defaultMessage="Fim: " /></span>
                <div className={style.trackerMenuDate}>
                  <DatePicker
                    customInput={<CustomDatePickerInput />}
                    selected={endDate}
                    onChange={(ev) => setEndDate(ev)}
                    locale={props.lang === 'pt' ? 'pt-br' : 'en-us'}
                    showTimeSelect
                    timeFormat="HH"
                    timeIntervals={60}
                    dateFormat="dd/MM/yyyy - HH:00"
                    timeCaption={messages[props.lang]['client.tracker.hour']}
                    previousMonthButtonLabel=""
                    nextMonthButtonLabel=""
                  />
                </div>
              </div>
              <button className={style.trackerMenuBtn} onClick={() => fetchPoints()}>
                <span>
                  <i className="fas fa-search fa-lg" aria-hidden="true" style={{ paddingRight: '0.5rem', paddingTop: '0.2rem' }} />
                </span>
                <FormattedMessage id="client.tracker.search" defaultMessage="Procurar" />
              </button>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

TrackerClimax.propTypes = {
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          account: PropTypes.string,
          first_name: PropTypes.string,
          last_name: PropTypes.string,
          is_test: PropTypes.boolean,
          equipment_id: PropTypes.number,
          equipment_name: PropTypes.string,
        }),
      ),
      ordering: PropTypes.string,
    }),
  ).isRequired,
  loadClients: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
}

function mapStateToProps(state, ownProps) {
  return {
    clients: state.clients,
    lang: state.locale.lang,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadClients: params => dispatch(clientsActions.loadClients(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackerClimax);
