import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Order from '../../../../icons/Order';
import * as general from '../../../../BLL/general';

import '../../../../css/attendComponent.scss';
import '../../../../css/attends.scss';
import '../../../../css/table.scss';

import style from './attendClientComponent.module.scss';

const ClientDetail = (props) => (
  <div className={style['client-wrapper']} style={{ display: props.show ? '' : 'none' }}>
    <div className={`${style.clientRow} ${style.contrastRow}`}>
      <span className={style.observationLabel}>
        <FormattedMessage id="attend.detail.clientObservations" defaultMessage="Observações" />
      </span>
      <span className={style.bigtext} style={{ float: 'left', whiteSpace: 'pre-line', wordWrap: 'break-word' }}>
        {props.client.general_info ? props.client.general_info : <FormattedMessage id="attend.detail.doesNotHave" defaultMessage="Não possui" />}
      </span>
    </div>
    <div className={style.clientRow}>
      <span className="component-label"><FormattedMessage id="client.detail.name" defaultMessage="Nome" /> </span>
      <span className="component-text" data-testid="client-name">{props.client.first_name} {props.client.last_name}</span>
    </div>
    <div className={style.clientRow}>
      <span className="component-label"><FormattedMessage id="client.detail.gender" defaultMessage="Sexo" /> </span>
      <span className="component-text" data-testid="client-gender">
        {props.client.gender === 'm' && <FormattedMessage id="client.form.male" defaultMessage="Masculino" />}
        {props.client.gender === 'f' && <FormattedMessage id="client.form.female" defaultMessage="Feminino" />}
        {props.client.gender !== 'f' && props.client.gender !== 'm' && <FormattedMessage id="client.form.notApplicable" defaultMessage="Não aplicável" />}
      </span>
    </div>
    <div className={style.clientRow}>
      <span className="component-label">CPF</span>
      <span className={style.bigtext} style={{ float: 'left', whiteSpace: 'pre-line', wordWrap: 'break-word' }}>
        {props.client.cpf ? general.cpfMask(props.client.cpf) : <FormattedMessage id="attend.detail.doesNotHave" defaultMessage="Não possui" />}
      </span>
    </div>
    <div className={style.clientRow}>
      <span className="component-label">RG</span>
      <span className={style.bigtext} style={{ float: 'left', whiteSpace: 'pre-line', wordWrap: 'break-word' }}>
        {props.client.rg ? props.client.rg : <FormattedMessage id="attend.detail.doesNotHave" defaultMessage="Não possui" />}
      </span>
    </div>
    <div className={style.clientRow}>
      <span className="component-label"><FormattedMessage id="attend.detail.birthdate" defaultMessage="Data de Nascimento" /></span>
      <span className={style.bigtext} style={{ float: 'left', whiteSpace: 'pre-line', wordWrap: 'break-word' }}>
        {props.client.birthdate ? general.formatBirthdate(props.client.birthdate) : <FormattedMessage id="attend.detail.doesNotHave" defaultMessage="Não possui" />}
      </span>
    </div>
    <div className={style.clientRow}>
      <span className="component-label"><FormattedMessage id="attend.detail.clientHealthplan" defaultMessage="Plano de Saúde" /> </span>
      <span className={style.bigtext} style={{ float: 'left', whiteSpace: 'pre-line', wordWrap: 'break-word' }}>
        {props.client.health_plan ? props.client.health_plan : <FormattedMessage id="attend.detail.doesNotHave" defaultMessage="Não possui" />}
      </span>
    </div>
    <div className={props.client.removal_plan ? style.contrastRow : style.clientRow}>
      <span className={props.client.removal_plan ? style.observationLabel : "component-label"}><FormattedMessage id="attend.detail.clientRemoval" defaultMessage="Plano de Remoção" /> </span>
      <span className={style.bigtext} style={{ float: 'left', whiteSpace: 'pre-line', wordWrap: 'break-word', width: '59%' }}>
        {props.client.removal_plan ? props.client.removal_plan : <FormattedMessage id="attend.detail.doesNotHave" defaultMessage="Não possui" />}
      </span>
    </div>
    <div className={style.clientRow}>
      <span className="component-label">Hospital </span>
      <span className={style.bigtext} style={{ float: 'left', whiteSpace: 'pre-line', wordWrap: 'break-word' }}>
        {props.client.hospital ? props.client.hospital : <FormattedMessage id="attend.detail.doesNotHave" defaultMessage="Não possui" />}
      </span>
    </div>
    <div className={style.clientRow}>
      <span className="component-label"><FormattedMessage id="attend.detail.limitations" defaultMessage="Limitações" /> </span>
      <span className={style.bigtext} style={{ float: 'left', whiteSpace: 'pre-line', wordWrap: 'break-word' }}>
        {props.client.limitations ? props.client.limitations : <FormattedMessage id="attend.detail.doesNotHave" defaultMessage="Não possui" />}
      </span>
    </div>
    <div className={style.clientRow}>
      <span className="component-label"><FormattedMessage id="attend.detail.medications" defaultMessage="Medicamentos" /> </span>
      <span className={style.bigtext} style={{ float: 'left', whiteSpace: 'pre-line', wordWrap: 'break-word' }}>
        {props.client.medications ? props.client.medications : <FormattedMessage id="attend.detail.doesNotHave" defaultMessage="Não possui" />}
      </span>
    </div>
  </div>
);

const AttendClientComponent = (props) => {
  const { highestAlarm, transmitterList, client, secondary } = props;
  const [showPrimary, setShowPrimary] = useState(true);
  const [showSecondary, setShowSecondary] = useState(false);

  useEffect(() => {
    if (highestAlarm?.tr_zone && transmitterList.length > 0)  {
      const mainTransmitter = transmitterList.find(tr => tr.zone === highestAlarm.tr_zone && tr.identifier === highestAlarm.tr_identifier);
      if (mainTransmitter?.secondary > 0 && secondary) {
        console.log('Entrei aqui', mainTransmitter);
        setShowPrimary(false);
        setShowSecondary(true);
      }
    }
  }, [highestAlarm, transmitterList, secondary]);

  const complement = client.complement ? `- ${client.complement}` : '';
  const rotatePrimary = showPrimary ? style.rotate : '';
  const rotateSecondary = showSecondary ? style.rotate : '';
  return (
    <div className="col-sm-12 col-md-8 col-lg-8">
      <span className="component-header" >
        <FormattedMessage id="attend.detail.accountDetailHeader" defaultMessage="DETALHES DA CONTA" />
      </span>
      <div className={style['client-wrapper']}>
        <div className={style.clientRow}>
          <span className="component-label"><FormattedMessage id="attend.detail.clientAccount" defaultMessage="Conta" /> </span>
          <span className="component-text" data-testid="client-account">{client.account}</span>
        </div>
        <div className={style.clientRow}>
          <span className="component-label"><FormattedMessage id="attend.detail.clientCompany" defaultMessage="Empresa" /> </span>
          <span className="component-text">{client.group_name}</span>
        </div>
        <div className={style.clientRow}>
          <span className="component-label"><FormattedMessage id="attend.detail.clientAddress" defaultMessage="Endereço" /> </span>
          <span className="component-text">{`${client.street}, ${
            client.number
          } ${complement}, ${client.city} - ${client.state}`}</span>
        </div>
        <div className={style.clientRow}>
          <span className="component-label"><FormattedMessage id="attend.detail.zipCode" defaultMessage="CEP" /> </span>
          <span className="component-text">
            {general.setMask(client.zip_code, '00000-000')}
          </span>
        </div>
        <div className={style.clientRow}>
          <span className="component-label">Keybox </span>
          <span className="component-text">
            {client.key_box ? client.key_box : <FormattedMessage id="attend.detail.doesNotHave" defaultMessage="Não possui" />}
          </span>
        </div>
        <div className={style.clientRow}>
          <span className="component-label" style={{ height: 'auto' }}><FormattedMessage id="attend.detail.clientAccountPhone" defaultMessage="Número do equipamento" /> </span>
          <span className="component-text">
            {client.use_voip ? client.account_phone : general.setMask(client.account_phone, '00 0000-0000')}
          </span>
        </div>
        <div className={style.clientRow}>
          <span className="component-label"><FormattedMessage id="attend.detail.clientContactPhone" defaultMessage="Telefone do usuário" /> 1 </span>
          <span className="component-text">
            {client.contact_phone
              ? general.setMask(client.contact_phone, '00 0000-0000')
              : '-'}
          </span>
        </div>
        <div className={style.clientRow}>
          <span className="component-label"><FormattedMessage id="attend.detail.clientContactPhone" defaultMessage="Telefone do usuário" /> 2 </span>
          <span className="component-text">
            {client.extra_phone
              ? general.setMask(client.extra_phone, '00 0000-0000')
              : '-'}
          </span>
        </div>
      </div>
      <span className="component-header" style={{ marginTop: '5px', cursor: 'pointer'}} onClick={() => setShowPrimary(!showPrimary)}>
        <FormattedMessage id="attend.detail.primaryHeader" defaultMessage="DETALHES DO USUÁRIO 1" /><Order width="18" height="18" className={rotatePrimary}/>
      </span>
      <ClientDetail client={client} show={showPrimary} />
      {secondary?.id &&
        <div className={style['client-wrapper']}>
          <span className="component-header" style={{ marginTop: '5px', cursor: 'pointer'}} onClick={() => setShowSecondary(!showSecondary)}>
            <FormattedMessage id="attend.detail.secondaryHeader" defaultMessage="DETALHES DO USUÁRIO 2" /><Order width="18" height="18" className={rotateSecondary}/>
          </span>
          <ClientDetail client={secondary} show={showSecondary} />
        </div>
      }
    </div>
  )
};

AttendClientComponent.propTypes = {
  client: PropTypes.shape({
    account: PropTypes.string,
    account_phone: PropTypes.string,
    aditional_phones: PropTypes.arrayOf(
      PropTypes.shape({
        client: PropTypes.number,
        id: PropTypes.number,
        number: PropTypes.string,
      }),
    ),
    birthdate: PropTypes.string,
    city: PropTypes.string,
    contact_phone: PropTypes.string,
    country: PropTypes.string,
    complement: PropTypes.string,
    cpf: PropTypes.string,
    created_by: PropTypes.number,
    created_date: PropTypes.string,
    district: PropTypes.string,
    first_name: PropTypes.string,
    general_info: PropTypes.string,
    health_plan: PropTypes.string,
    id: PropTypes.number,
    is_deleted: PropTypes.bool,
    key_box: PropTypes.string,
    last_name: PropTypes.string,
    modified_by: PropTypes.number,
    modified_date: PropTypes.string,
    number: PropTypes.number,
    rg: PropTypes.string,
    state: PropTypes.string,
    street: PropTypes.string,
    zip_code: PropTypes.string,
  }).isRequired,
};

export default AttendClientComponent;
