/* eslint-disable import/no-anonymous-default-export */
export default {
  en: {
    1: 'Emergency Call',
    2: 'Test Call',
    3: 'Support Call',
    4: 'Accidental Call',
    5: 'Communication Fail',
    6: 'Additional Services',
    7: 'Relay Message',
  },
  pt: {
    1: 'Chamada de Emergência',
    2: 'Chamada de Teste',
    3: 'Suporte Técnico',
    4: 'Chamada Acidental',
    5: 'Falha de Comunicação',
    6: 'Serviços Adicionais',
    7: 'Transmitir Recado'
  },
};
