import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux'
import { FormattedMessage, IntlProvider } from 'react-intl';
import * as authActions from '../../actions/authActions';
import * as localeActions from '../../actions/localeActions';
import config from '../../BLL/config';
import { host } from '../../BLL/environment';
import logo from '../../img/logo-contactto-01 2.png';
import Loading from '../../icons/Loading';
import LoginForm from './LoginForm';
import PasswordResetForm from './PasswordResetForm';
import messagesLogin from '../../BLL/locale/messagesLogin';

import '../../css/animation.css';
import '../../css/contactto.scss';
import '../../css/login.scss';

const LoginPage = (props) => {
  const { lang } = props;
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [domain, setDomain] = useState('');
  const [loginContainerClass, setLoginContainerClass] = useState('contactto-login-content-container');
  const [loginSuccessClass, setLoginSuccessClass] = useState('login-success');
  const [loginFormClass, setLoginFormClass] = useState('contactto-login-content-container-form');
  const [inputUserClass, setInputUserClass] = useState('login-form-input login-form-input--border')
  const [inputPassClass, setInputPassClass] = useState('login-form-input')
  const [inputDomainClass, setInputDomainClass] = useState('login-form-input login-form-input--border')
  const [errorElm, setErrorElm] = useState(undefined);
  const [loadingResetEmail, setLoadingResetEmail] = useState(false);
  const [showErrorResetEmail, setShowErrorResetEmail] = useState(false);
  const [showUsernameInput, setShowUsernameInput] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(true);
  const [resetEmailStatus, setResetEmailStatus] = useState('');

  const getCookie = (cName) => {
    if (document.cookie.length > 0) {
      // cName + '='
      let cStart = document.cookie.indexOf(`${cName}=`);
      if (cStart !== -1) {
        cStart = cStart + cName.length + 1;
        let cEnd = document.cookie.indexOf(';', cStart);
        if (cEnd === -1) cEnd = document.cookie.length;
        return unescape(document.cookie.substring(cStart, cEnd));
      }
    }
    return '';
  }

  const handleInputChange = (ev) => {
    const field = ev.target.name;
    const value = ev.target.value;

    switch(field) {
      case 'username': {
        setUsername(value);
        if (value) {
          setInputUserClass('login-form-input login-form-input--border');
        }
        break;
      }
      case 'password': {
        setPassword(value);
        const minEl = ev.target.getAttribute('data-el');
        if (value.length < minEl) {
          setInputPassClass('login-form-input login-form-input--error');
          setErrorElm(<FormattedMessage id="login.error.minElPass" defaultMessage="senha deve conter no mínimo {minEl} caracteres" values={{ minEl }} />);
        } else {
          setInputPassClass('login-form-input');
          setErrorElm(undefined);
        }
        break;
      }
      case 'domain': {
        setDomain(value);
        if (value) {
          setInputDomainClass('login-form-input login-form-input--border')
        }
        break;
      }
      default:
        break;
    }
  };

  const errorAnimation = () => {
    setLoginContainerClass('contactto-login-content-container error-animation');
    setTimeout(() => {
      setLoginContainerClass('contactto-login-content-container')
    }, 400);
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    const isDomainValid = await makeDomainValidationPost();
    if (!username) {
      setErrorElm(<FormattedMessage id="login.error.user" defaultMessage="usuário deve ser preenchido" />);
      setInputUserClass('login-form-input login-form-input--error');
      errorAnimation();
    } else if (!password) {
      setErrorElm(<FormattedMessage id="login.error.password" defaultMessage="campo de senha deve ser preenchido" />);
      setInputPassClass('login-form-input login-form-input--error');
      errorAnimation();
    } else if (!domain) {
      setErrorElm(<FormattedMessage id="login.error.domain" defaultMessage="campo de domínio deve ser preenchido" />);
      setInputDomainClass('login-form-input login-form-input--error');
      errorAnimation();
    } else if (isDomainValid !== true) {
      if (isDomainValid.response?.status === 404 && isDomainValid.response?.data?.error.includes('does not exist')) {
        setErrorElm(<FormattedMessage id="login.error.invalidDomain" defaultMessage="campo de domínio inválido" />);
        setInputDomainClass('login-form-input login-form-input--error');
        errorAnimation();
      } else {
        console.log(isDomainValid);
        setErrorElm(<FormattedMessage id="login.error.unexpected" defaultMessage="erro inesperado" />);
        setInputDomainClass('login-form-input login-form-input--error');
        errorAnimation();
      }
    }
    else {
      sessionStorage.setItem('activeDomain', domain);
      props.authLoginUser(username, password)
        .then((res) => {
          console.log(res);
          if (res.isActive) {
            let domainList = localStorage.domainList ? JSON.parse(localStorage.domainList) : [];
            domainList.indexOf(domain) === -1 && domainList.push(domain);
            localStorage.setItem('domainList', JSON.stringify(domainList));

            const usr = Object.assign({}, res.user);
            delete usr.password;
            const tenantInfo = {
              'token': res.token,
              'user': usr,
              'status': res.status,
              'is_paid': res.isPaid,
              'is_active': res.isActive,
              'rc_token': res.rcToken
            }
            const domainInfo = localStorage.domainInfo ? JSON.parse(localStorage.domainInfo) : {}
            const newDomain = Object.assign({}, domainInfo, { [domain]: tenantInfo });
            localStorage.setItem('domainInfo', JSON.stringify(newDomain));
            localStorage.setItem('token', res.token);
            localStorage.setItem('user', JSON.stringify(usr));
            localStorage.setItem('status', res.status);
            localStorage.setItem('is_paid', res.isPaid);
            localStorage.setItem('is_active', res.isActive);
            localStorage.setItem('rc_token', res.rcToken);

            sessionStorage.setItem('activeToken', res.token);

            setTimeout(() => {
              props.history.push('/app');
            }, 1500);

            setLoginFormClass('contactto-login-content-container-form contactto-hide');
            setLoginSuccessClass('login-success login-success-show');
          } else {
            setErrorElm(<FormattedMessage id="login.error.blocked" defaultMessage="Este Domínio está bloqueado, favor entrar em contato." />);
            inputUserClass('login-form-input login-form-input--border');
            errorAnimation();
          }
        })
        .catch((err) => {
          console.log(err);
          errorAnimation();
          if (err.data && err.data.trialLimit) {
            setErrorElm(<FormattedMessage id="login.error.blocked" defaultMessage="Este Domínio está bloqueado, favor entrar em contato." />);
          } else if (err.data && err.data.unauthorized) {
            setErrorElm(<FormattedMessage id="login.error.unauthorized" defaultMessage="Este usuário e senha não estão autorizados neste login." />);
          } else {
            setErrorElm(<FormattedMessage id="login.error.userorpassword" defaultMessage="usuário ou senha incorretos" />);
          }
        })

    };

  }

  const makePasswordReset = (domain, email, username) => {
    setLoadingResetEmail(true);
    let data = {
      email: email,
      domain: domain,
    };
    if (username) {
      data.username = username;
    }

    axios({
      method: 'post',
      responseType: 'json',
      url: `https://${domain}${host()}/api/password-reset/`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
      data
    }).then(() => {
      setLoadingResetEmail(false);
      setResetEmailStatus('success');
    }).catch((err) => {
      setResetEmailStatus('fail');
      if (err.response && err.response.status === 409) {
        setLoadingResetEmail(false);
        setShowUsernameInput(true);
      } else {
        setLoadingResetEmail(false);
        setShowErrorResetEmail(true);
        setTimeout(() => {
          setShowErrorResetEmail(false);
        }, 1200);
      }
    });
  };

  const makeDomainValidationPost = () => {
    const data = {
      tenant: domain
    };
    return axios({
      method: 'post',
      responseType: 'json',
      url: `https://app${host()}/api/tenant-validation/`,
      headers: {
        Accept: 'application/json',
        Authorization: `Api-Key ${config.contacttoPublicApiKey}`,
        'Content-Type': 'application/json',
      },
      data
    }).then(() => {
      return true;
    }).catch((err) => {
      return err;
    });
  }

  let usernameText = 'Usuário';
  let passwordText = 'Senha';
  let domainText = 'Domínio';

  if (lang === 'en') {
    usernameText = 'Username';
    passwordText = 'Password';
    domainText = 'Domain';
  }
  
  return (
    <IntlProvider locale={lang} messages={messagesLogin[lang]} key={lang}>
      <div id="contactto-login" className="contactto-login">
        <div className="contactto-login-content">
          <div id="contactto-login-content-container" className={loginContainerClass}>
            <div id="login-success" className={loginSuccessClass}>
              <FormattedMessage id="login.success.redirect" defaultMessage="Login realizado com sucesso Você será redirecionado..." />
            </div>
            {loadingResetEmail &&
              <div className="contactto-reset-container">
                <Loading text={<FormattedMessage id="login.request.loading" defaultMessage="Enviando solicitação" />} />
              </div>
            }
            {showLoginForm
              ? 
                <LoginForm
                  loginFormClass={loginFormClass}
                  logo={logo}
                  errorElm={errorElm}
                  inputDomainClass={inputDomainClass}
                  domain={domain}
                  domainText={domainText}
                  inputUserClass={inputUserClass}
                  username={username}
                  usernameText={usernameText}
                  inputPassClass={inputPassClass}
                  password={password}
                  passwordText={passwordText}
                  handleSubmit={handleSubmit}
                  handleInputChange={handleInputChange}
                />
              :
                <PasswordResetForm
                  errorEmailReset={showErrorResetEmail}
                  showUsernameInput={showUsernameInput}
                  loadingResetEmail={loadingResetEmail}
                  logo={logo}
                  domain={domain}
                  lang={lang}
                  makePasswordReset={makePasswordReset}
                  resetEmailStatus={resetEmailStatus}
                />
            }
            {!loadingResetEmail && loginSuccessClass !== 'login-success login-success-show' &&
              <div>
                <button
                  id="contactto-reset-pass-button"
                  className="login-reset contactto-button"
                  onClick={() => setShowLoginForm(!showLoginForm)}
                >
                  {showLoginForm
                    ? <FormattedMessage id="login.form.forgotPassword" defaultMessage="Esqueci minha senha" />
                    : <FormattedMessage id="login.form.login" defaultMessage="Login" />
                  }
                </button>
                <div className="contactto-login-locale-container">
                  <button className={`contactto-login-locale-button ${lang === 'pt' ? 'contactto-login-locale-chosen' : ''}`} onClick={() => props.setLocale('pt')}>PT</button>
                  <span> | </span>
                  <button className={`contactto-login-locale-button ${lang === 'en' ? 'contactto-login-locale-chosen' : ''}`} onClick={() => props.setLocale('en')}>EN</button>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </IntlProvider>
    
  )
}

function mapStateToProps(state, ownProps) {
  return {
    attendants: state.attendants,
    lang: state.locale.lang,
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    authLoginUser: (username, password, redirect) =>
      dispatch(authActions.authLoginUser(username, password, redirect)),
    setLocale: locale => dispatch(localeActions.setLocale(locale)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
