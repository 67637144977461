import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import '../../css/contactto.scss';
import '../../css/paginate.scss';
import '../../css/print.scss';

export default class Paginate extends React.Component {

  previousList() {
    if (this.props.page > 1) {
      const page = this.props.page - 1;
      this.props.setPage(page);
    }
  }

  nextList() {
    if ((this.props.page) * this.props.numPerPage + 1 <= this.props.numberOfElements) {
      const page = this.props.page + 1;
      this.props.setPage(page);
    }
  }

  render() {
    const startNumber = (this.props.page - 1) * this.props.numPerPage + 1;
    const endNumber = this.props.page * this.props.numPerPage;

    return (
      <div className={`${this.props.outerClass} no-print`}>
        {this.props.numberOfElements > this.props.numPerPage &&
        <div className="left contactto-100">
          <span className="left">
            {`${startNumber < this.props.numberOfElements ? startNumber : this.props.numberOfElements}-${endNumber < this.props.numberOfElements ? endNumber : this.props.numberOfElements} `}
            <FormattedMessage id="paginate.of" defaultMessage="de" />
            {` ${this.props.numberOfElements}`}
          </span>
          <span id="paginate-arrow-left" className="paginate-arrow paginate-arrow-left" onClick={this.previousList.bind(this)} />
          <span id="paginate-arrow-right" className="paginate-arrow paginate-arrow-right" onClick={this.nextList.bind(this)} />
        </div>
				}
      </div>
    );
  }
}

Paginate.defaultProps = {
  outerClass: 'paginate-wrapper',
};

Paginate.propTypes = {
  outerClass: PropTypes.string,
  numberOfElements: PropTypes.number.isRequired,
  numPerPage: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
};
