import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './customModal.module.scss';

export default class CustomModal extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={styles.wrapper} style={{ height: this.props.height }}>
        <div className={styles.title}>{this.props.text}</div>
        <div className={styles.actions}>
          <button className={styles.btn} onClick={this.props.btnAction} data-testid={this.props.testId}>
            {this.props.btnText}
          </button>
        </div>
      </div>
    );
  }

}

CustomModal.defaultProps = {
  height: '',
  testId: ''
};

CustomModal.propTypes = {
  text: PropTypes.shape({
    key: PropTypes.number,
  }).isRequired,  // PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
  btnAction: PropTypes.func.isRequired,
  height: PropTypes.string,
  testId: PropTypes.string,
};
