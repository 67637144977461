import * as types from './actionTypes';
import * as errorsActions from './errorsActions';
import { host, getDomain, getToken } from '../BLL/environment';

export function loadAttendsSuccess(open, closed) {
  return { type: types.LOAD_DASHBOARD_SUCCESS, open, closed };
}

export function newAttendDashboard(attend) {
  return { type: types.NEW_ATTEND_DASHBOARD, attend };
}

export function updateAttendDashboard(attend) {
  return { type: types.UPDATE_ATTEND_DASHBOARD, attend };
}

export function closeAttendDashboard(attend) {
  return { type: types.CLOSE_ATTEND_DASHBOARD, attend };
}

export function loadOpenDashboardAttendsSuccess(result) {
  return { type: types.LOAD_OPEN_DASHBOARD_SUCCESS, payload: result }
}

export function loadOpenDashboardAttends() {
  const domain = getDomain();
  const token = getToken();
  if (token === 'error') {
    errorsActions.logout();
  }

  const url = `https://${domain}${host()}/api/attend/list/?limit=6&status=OPEN&status=IN_PROGRESS`;
  return (dispatch, getState, { axios }) => new Promise((resolve, reject) => {
    axios.get(url, {
      headers: { Authorization: `Token ${token}` }
    }).then((resp) => {
      dispatch(loadOpenDashboardAttendsSuccess(resp.data.results));
    }).catch((err) => {
      dispatch(errorsActions.logout())
    })
  });
}

export function loadDashboardAttends() {
  const domain = getDomain();
  const token = getToken();

  if (token === 'error') {
    errorsActions.logout();
  }
  return (dispatch, getState, { axios }) => new Promise((resolve, reject) => {
    resolve(axios.all([
      axios.get(`https://${domain}${host()}/api/attend/list/?limit=6&status=OPEN&status=IN_PROGRESS`,
        { headers: { Authorization: `Token ${token}` } })
              .catch((error) => {
                if (error.response) {
                  if (error.response.status === 401) {
                    dispatch(errorsActions.logout());
                  }
                }
              }),
      axios.get(`https://${domain}${host()}/api/attend/list/?limit=6&status=CLOSE`,
      { headers: { Authorization: `Token ${token}` } })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                dispatch(errorsActions.logout());
              }
            }
          }),
    ]).then(axios.spread((open, closed) => {
      if (open && closed) {
        dispatch(loadAttendsSuccess(open.data.results, closed.data.results));
      }
    })));
  })
}
