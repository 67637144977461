import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import numeral from 'numeral';

import style from './reportAttendant.module.scss';

const ReportAttendantRow = ({ report }) => {
  const milliseconds = new Date(report.close) - new Date(report.open);
  const seconds = Math.floor(milliseconds / 1000);
  return (
    <Link to={`/app/attends/closed/${report.attend_id}/`} className={style.row}>
      <div className={`${style.column} ${style.id}`}>{report.attend_id}</div>
      <div className={`${style.column} ${style.client}`}>{report.client}</div>
      <div className={`${style.column} ${style.account}`}>{report.account}</div>
      <div className={`${style.column} ${style.dateOpen}`}>{moment(report.open).format('DD/MM/YYYY - HH:mm')}</div>
      <div className={`${style.column} ${style.dateClosed}`}>{moment(report.close).format('DD/MM/YYYY - HH:mm')}</div>
      <div className={`${style.column} ${style.duration}`}>{numeral(seconds).format('00:00:00')}</div>
      <div className={`${style.column} ${style.resolvedBy}`}>{report.success}</div>
    </Link>
  );
};

ReportAttendantRow.propTypes = {
  report: PropTypes.shape({
    success: PropTypes.string,
    attend_id: PropTypes.number,
    account: PropTypes.string,
    close: PropTypes.string,
    client: PropTypes.string,
    attendant: PropTypes.string,
    open: PropTypes.string,
  }).isRequired,
};

export default ReportAttendantRow;
