import React from 'react';
import PropTypes from 'prop-types';
import '../../css/print.scss';

const Phone = ({ sipSessionType, sipStackType }) => {
  const isLoading =
    sipSessionType === 'connecting' ||
    sipStackType === 'starting';

  const isOffline =
    sipSessionType === 'transport_error' ||
    sipStackType === 'failed_to_start' ||
    sipStackType === 'stopped' ||
    sipStackType === 'failed_to_stop' ||
    (sipSessionType === undefined && sipStackType === undefined);

  const isOnline =
    sipSessionType === 'connected' ||
    sipSessionType === 'sent_request' ||
    sipSessionType === 'terminated' ||
    sipStackType === 'started';
  return (
    <div className="no-print">
      {isOnline && !isOffline && !isLoading && <i className="far fa-check-circle fa-2x" style={{ color: '#94E027', lineHeight: '.9' }} aria-hidden="true" /> }
      {!isOnline && isOffline && !isLoading && <i className="far fa-times-circle fa-2x" style={{ color: '#ED2224', lineHeight: '.9' }} aria-hidden="true" /> }
      {!isOnline && !isOffline && isLoading && <i className="fas fa-sync fa-spin fa-2x" style={{ color: '#fff', lineHeight: '.9' }} aria-hidden="true" /> }
    </div>
  );
};

Phone.defaultProps = {
  sipSessionType: undefined,
  sipStackType: undefined,
};

Phone.propTypes = {
  sipSessionType: PropTypes.string,
  sipStackType: PropTypes.string,
};


export default Phone;
