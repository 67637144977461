import React from 'react';
import PropTypes from 'prop-types';

const AlarmHighTemp = ({ height, svgClass, width, fill, transform }) => (
  <svg className={svgClass} width={width} height={height} fill={fill} transform={transform} viewBox="0 0 140 140" xmlns="http://www.w3.org/2000/svg">
<g id="dUIJ5I.tif">
<g>
<g>
<path d="M97.2,54.7c0,8.5,0,16.9,0,25.4c0,1.7,0.4,3,1.5,4.4c9,11.5,9.2,28.2,0.4,39.7c-8.7,11.3-20.3,15.8-34.1,12.6
c-14-3.2-22.6-12.6-25.3-26.7c-1.8-9.3,0.5-18,6.3-25.6c1.1-1.4,1.5-2.8,1.5-4.5c-0.1-16.7,0-33.5,0-50.2
c0-15,13.6-26.7,28.5-24.4c12.6,1.9,21.3,12.1,21.3,24.9C97.2,38.4,97.2,46.6,97.2,54.7z M84.7,58C84.7,58,84.7,58,84.7,58
c0-9.4,0-18.8,0-28.1c-0.1-8.2-8.1-14-15.9-11.7c-5.3,1.6-8.8,6.3-8.8,11.8c0,18.5,0,37.1,0,55.6c0,1.3-0.4,2.2-1.3,3.2
c-3.8,4.1-6.7,8.7-7,14.4c-0.7,13.1,10.5,23.3,23,21.9c9.8-1.1,18.1-9.4,18.4-20.2c0.2-6.5-2.9-11.7-7.2-16.3
c-0.8-0.9-1.2-1.7-1.1-2.9C84.7,76.5,84.7,67.2,84.7,58z"/>
<path d="M64,59.2c0-9.7,0-18.5,0-28.2c0-4.6,3.7-8.3,8.3-8.3c4.6,0,8.4,3.7,8.4,8.3c0,19.4,0,37.8,0,57.2c0,1.4,0.3,2.2,1.5,3.1
c6.2,4.4,8.4,12.6,5.5,19.8c-2.8,6.9-10.1,11-17.5,10c-7.1-1-12.5-5.9-14-12.7c-1.5-6.6,1.1-13.4,6.8-17.4
c0.9-0.6,1.2-1.2,1.2-2.3C64,78.8,64,69,64,59.2z"/>
</g>
</g>
</g>
  </svg>
);

AlarmHighTemp.defaultProps = {
  height: '75',
  svgClass: '',
  width: '75',
  fill: '#F69B38',
  transform: 'translate(0,0)',
};

AlarmHighTemp.propTypes = {
  height: PropTypes.string,
  svgClass: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
  transform: PropTypes.string,
};

export default AlarmHighTemp;
