import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Edit from '../../../icons/Edit';


import style from './transmitterPage.module.scss';
import '../../../css/row.scss';

class TransmitterMobileRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      showModal: false,
    };
    this.toggleLogExtra = this.toggleLogExtra.bind(this);
  }

  toggleLogExtra() {
    this.setState(prevState => ({
      expanded: !prevState.expanded,
    }));
  }

  render() {
    const { expanded } = this.state;
    const {
      transmitter,
      isAdmin,
      isOwner,
      isManager,
      setModalInfo,
      showModal,
    } = this.props;
    const list = `/app/transmitters/detail/${transmitter.id}`;
    const transmitters = `/app/transmitters/list/${transmitter.id}`;
    const edit = `/app/transmitters/form/edit/${transmitter.id}`;
    const btnStyle = { border: 0, backgroundColor: 'transparent', padding: 0 };
    return (
      <div className={style.tableRowWrapper} style={{ height: `${expanded ? 'auto' : ''}`, overflow: 'hidden' }}>
        <div
          className={`${style.tableRow}`}
          style={{ paddingRight: '20px' }}
        >
          <div className={`${style.column} ${style.zone}`}>
            {transmitter.zone}
          </div>
          <div className={`${style.column} ${style.description}`}>
            {transmitter.description}
          </div>
          <div className={`${style.column} ${style.toggleButtonWrapper}`}>
            <button className={style.toggleButton} onClick={this.toggleLogExtra}>
              {!expanded
                ? '+'
                : '-'
              }
            </button>
          </div>
        </div>
        <div className={style.buttonMenuWrapper}>

          <Link
            className={style.actions}
            onMouseOver={() => this.props.setTextIconDetail(<FormattedMessage id="general.columnView" defaultMessage="Visualizar" />)}
            onMouseOut={() => this.props.setTextIconDetail('')}
            to={`/app/transmitters/detail/${transmitter.client}/${transmitter.id}`}
          >
            <i className="far fa-eye" style={{ fontSize: 23, color: '#666', paddingTop: 3 }} />
            {/* <DetailIcon className={style.icons} width="28" height="28" /> */}
          </Link>
          {/* <Link
        className={style.actions}
        onMouseOver={() => setTextIconDetail(<FormattedMessage id="general.columnView" defaultMessage="Visualizar" />)}
        onMouseOut={() => setTextIconDetail('')}
        to={`/app/contacts/detail/${contact.client}/${contact.id}`}
      >
        <DetailIcon className={style.icons} width="28" height="28" />
      </Link> */}
          {(isAdmin || isOwner || isManager) &&
            <Link
              className={style.actions}
              onMouseOver={() => this.props.setTextIconDetail(<FormattedMessage id="general.columnEdit" defaultMessage="Editar" />)}
              onMouseOut={() => this.props.setTextIconDetail('')}
              to={`/app/transmitters/form/edit/${transmitter.client}/${transmitter.id}`}
            >
              <Edit className={style.icons} width="28" height="28" />
            </Link>
          }
          {(isAdmin || isOwner || isManager) &&
            <button
              onMouseOver={() => this.props.setTextIconDetail(<FormattedMessage id="general.columnDelete" defaultMessage="Deletar" />)}
              onMouseOut={() => this.props.setTextIconDetail('')}
              onClick={() => {
                if (!showModal) {
                  setModalInfo(true, transmitter);
                }
              }}
              className={style.actions}
              style={{ border: 0, backgroundColor: 'transparent', padding: 0 }}
            >
              <div className={style.actions}>
                <i className="far fa-trash-alt" style={{ fontSize: 23, color: '#666', paddingTop: 3 }} />
              </div>
            </button>}
        </div>
      </div>
    );
  }
}

TransmitterMobileRow.propTypes = {
  transmitter: PropTypes.shape({
    id: PropTypes.number,
    zone: PropTypes.string,
    client: PropTypes.number,
    description: PropTypes.string,
    identifier: PropTypes.string,
    general_info: PropTypes.string,
    created_date: PropTypes.string,
    modified_date: PropTypes.string,
    created_by: PropTypes.number,
    modified_by: PropTypes.number,
    is_deleted: PropTypes.bool,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool.isRequired,
  isManager: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  setModalInfo: PropTypes.func.isRequired,
};

export default TransmitterMobileRow;
