import * as types from '../actions/actionTypes';
import initialState from './initialState';


export default function globalServerReducer(state = initialState.serverList, action) {
  if (action.type === types.LOAD_SERVER_LIST_SUCCESS) {
    return [...action.server];
  }
  return state;
}
