import React from 'react';
import PropTypes from 'prop-types';
import { checkMonth } from '../../BLL/dictionary';

import '../../css/contactto.scss';

import CustomChart2 from './CustomChart2';

const ClientTotalMonthlyChart = ({ height, report, width }) => {
  const lang = localStorage.contacttoLang ? localStorage.contacttoLang : 'pt';
  const month = [];
  const values = [];
  const colors = [];
  const data = [
    ['o', ''],
    ...report.map(rpt => [
      colors.push('#46b474'),
      month.push(`${checkMonth(rpt.month, lang)}/${rpt.year}`),
      values.push(rpt.total ? rpt.total : 0),
    ]),
  ];

  const columns = [];
  return (
    <div className="left" style={{ height, width, maxHeight: '600px' }}>
      <CustomChart2 data={values} labels={month} colors={colors} />
    </div>
  );
};

ClientTotalMonthlyChart.defaultProps = {
  height: '350px',
  width: '350px',
};

ClientTotalMonthlyChart.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

export default ClientTotalMonthlyChart;
