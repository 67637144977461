import React from "react";
import InputContainer from "../../../Common/InputContainer";
import { FormattedMessage } from "react-intl";
import messages from "../../../../BLL/locale/messages";

import formStyle from "../../../../css/form.module.scss";

const ClientInfoForm = (props) => (
  <div className="col-12" style={{ display: props.showForm ? "" : "none" }}>
    <div className="form-row">
      <div className="col-sm-12 col-md-6">
        <InputContainer
          id={props.client.street ? "first_name" : "secondary_f_name"}
          name="first_name"
          maxLength="50"
          onChange={props.setClientState}
          title={
            <FormattedMessage
              id="inputContainer.firstName"
              defaultMessage="Nome: "
            />
          }
          validator={props.validator.first_name}
          isRequired
          value={props.client.first_name}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InputContainer
          id={props.client.street ? "last_name" : "secondary_l_name"}
          name="last_name"
          maxLength="50"
          onChange={props.setClientState}
          title={
            <FormattedMessage
              id="inputContainer.lastName"
              defaultMessage="Sobrenome: "
            />
          }
          validator={props.validator.last_name}
          isRequired
          value={props.client.last_name}
        />
      </div>
    </div>
    <div className="form-row">
      <div className="col-sm-12 col-md-6">
        <InputContainer
          id={props.client.street ? "birthdate" : "secondary_bday"}
          name="birthdate"
          maxLength="10"
          onChange={props.setClientState}
          title={
            <FormattedMessage
              id="inputContainer.birthdate"
              defaultMessage="Data de nascimento: "
            />
          }
          validator={props.validator.birthdate}
          isRequired
          value={props.client.birthdate}
        />
      </div>
      <div className="form-group col-sm-12 col-md-6">
        <label
          className="text-muted"
          htmlFor="gender"
          style={{ width: "148px", padding: "0 4px" }}
        >
          <FormattedMessage
            id="inputContainer.gender"
            defaultMessage="Sexo: "
          />
          <span className="form-required">*</span>
        </label>
        <select
          id={props.client.street ? "gender" : "secondary_gender"}
          className={`custom-select ${formStyle["select--enable"]}`}
          name="gender"
          value={props.client.gender}
          onChange={props.setClientState}
        >
          <option value="m">{messages[props.lang]["client.form.male"]}</option>
          <option value="f">
            {messages[props.lang]["client.form.female"]}
          </option>
          <option value="n">
            {messages[props.lang]["client.form.notApplicable"]}
          </option>
        </select>
      </div>
    </div>
    <div className="form-row">
      <div className="col-sm-12 col-md-6">
        <InputContainer
          id={props.client.street ? "cpf" : "secondary_cpf"}
          name="cpf"
          maxLength="14"
          onChange={props.setClientState}
          title="CPF: "
          validator={props.validator.cpf}
          isRequired={false}
          value={props.client.cpf}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InputContainer
          id={props.client.street ? "rg" : "secondary_rg"}
          name="rg"
          onChange={props.setClientState}
          title="RG: "
          value={props.client.rg}
        />
      </div>
    </div>
    <div className="form-group">
      <label className="text-muted text-muted--full" htmlFor="health_plan">
        <FormattedMessage
          id="inputContainer.healthPlan"
          defaultMessage="Plano de Saúde: "
        />
      </label>
      <textarea
        type="textarea"
        name="health_plan"
        className="form-control"
        value={props.client.health_plan}
        maxLength="1024"
        onChange={props.setClientState}
        rows="4"
        cols="50"
      />
    </div>
    <div className="form-group">
      <label className="text-muted text-muted--full" htmlFor="hospital">
        Hospital:
      </label>
      <textarea
        type="textarea"
        name="hospital"
        className="form-control"
        value={props.client.hospital}
        maxLength="200"
        onChange={props.setClientState}
        rows="4"
        cols="50"
      />
    </div>
    <div className="form-group">
      <label className="text-muted text-muted--full" htmlFor="limitations">
        <FormattedMessage
          id="inputContainer.limitations"
          defaultMessage="Limitações: "
        />
      </label>
      <textarea
        type="textarea"
        name="limitations"
        className="form-control"
        value={props.client.limitations}
        maxLength="200"
        onChange={props.setClientState}
        rows="4"
        cols="50"
      />
    </div>
    <div className="form-group">
      <label className="text-muted text-muted--full" htmlFor="medications">
        <FormattedMessage
          id="inputContainer.medications"
          defaultMessage="Medicamentos: "
        />
      </label>
      <textarea
        type="textarea"
        name="medications"
        className="form-control"
        value={props.client.medications}
        maxLength="512"
        onChange={props.setClientState}
        rows="4"
        cols="50"
      />
    </div>
    <div className="form-group">
      <label className="text-muted text-muted--full" htmlFor="removal_plan">
        <FormattedMessage
          id="inputContainer.removalPlan"
          defaultMessage="Plano de Remoção: "
        />
      </label>
      <textarea
        type="textarea"
        name="removal_plan"
        className="form-control"
        value={props.client.removal_plan}
        maxLength="200"
        onChange={props.setClientState}
        rows="4"
        cols="50"
      />
    </div>
    <div className="form-group">
      <label className="text-muted text-muted--full" htmlFor="general_info">
        <FormattedMessage
          id="inputContainer.observations"
          defaultMessage="Observações: "
        />
      </label>
      <textarea
        type="textarea"
        name="general_info"
        className="form-control"
        value={props.client.general_info}
        maxLength="1024"
        onChange={props.setClientState}
        rows="10"
        cols="50"
      />
    </div>
  </div>
);

export default ClientInfoForm;
