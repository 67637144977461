import * as types from '../actions/actionTypes';
import initialState from './initialState';


/*
Inputs:

Types:

 */
export default function localeReducer(state = initialState.locale, action) {
  switch (action.type) {
    case types.LOCALE_SET:
      return { lang: action.lang };
    default:
      return state;
  }
}
