import React from 'react';
import PropTypes from 'prop-types';


const VidapointLogo = ({ height, svgClass, width }) => (
  <svg
    id="Layer_1"
    className={svgClass}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 226.771 226.771"
  >
    <path
      fill="#21556F"
      d="M22.864,53.873c2.551,5.83,40.453,83.006,40.453,83.006c40.183,81.549,85.102,22.777,92.662,8.291 c-6.834,3.464-19.499,4.281-29.431-18.131l-24.69-49.932C86.004,47.494,47.281,41.3,22.864,53.873"
    />
    <path
      fill="#61C2C7"
      d="M121.811,83.666c7.018,15.58,15.034,33.259,22.051,42.277c7.836,9.932,18.04,9.932,24.874-3.463 l35.171-68.609C158.075,35.834,131.835,59.795,121.811,83.666"
    />
  </svg>
);

VidapointLogo.defaultProps = {
  height: '200',
  svgClass: '',
  width: '300',
};

VidapointLogo.propTypes = {
  height: PropTypes.string,
  svgClass: PropTypes.string,
  width: PropTypes.string,
};

export default VidapointLogo;
