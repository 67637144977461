const countryCodes = [
  {
    dial_code: "+93",
    code: "AF"
  },
  {
    dial_code: "+358",
    code: "AX"
  },
  {
    dial_code: "+355",
    code: "AL"
  },
  {
    dial_code: "+213",
    code: "DZ"
  },
  {
    dial_code: "+1684",
    code: "AS"
  },
  {
    dial_code: "+376",
    code: "AD"
  },
  {
    dial_code: "+244",
    code: "AO"
  },
  {
    dial_code: "+1264",
    code: "AI"
  },
  {
    dial_code: "+672",
    code: "AQ"
  },
  {
    dial_code: "+1268",
    code: "AG"
  },
  {
    dial_code: "+54",
    code: "AR"
  },
  {
    dial_code: "+374",
    code: "AM"
  },
  {
    dial_code: "+297",
    code: "AW"
  },
  {
    dial_code: "+61",
    code: "AU"
  },
  {
    dial_code: "+43",
    code: "AT"
  },
  {
    dial_code: "+994",
    code: "AZ"
  },
  {
    dial_code: "+1242",
    code: "BS"
  },
  {
    dial_code: "+973",
    code: "BH"
  },
  {
    dial_code: "+880",
    code: "BD"
  },
  {
    dial_code: "+1246",
    code: "BB"
  },
  {
    dial_code: "+375",
    code: "BY"
  },
  {
    dial_code: "+32",
    code: "BE"
  },
  {
    dial_code: "+501",
    code: "BZ"
  },
  {
    dial_code: "+229",
    code: "BJ"
  },
  {
    dial_code: "+1441",
    code: "BM"
  },
  {
    dial_code: "+975",
    code: "BT"
  },
  {
    dial_code: "+591",
    code: "BO"
  },
  {
    dial_code: "+387",
    code: "BA"
  },
  {
    dial_code: "+267",
    code: "BW"
  },
  {
    dial_code: "+55",
    code: "BR"
  },
  {
    dial_code: "+246",
    code: "IO"
  },
  {
    dial_code: "+673",
    code: "BN"
  },
  {
    dial_code: "+359",
    code: "BG"
  },
  {
    dial_code: "+226",
    code: "BF"
  },
  {
    dial_code: "+257",
    code: "BI"
  },
  {
    dial_code: "+855",
    code: "KH"
  },
  {
    dial_code: "+237",
    code: "CM"
  },
  {
    dial_code: "+1",
    code: "CA"
  },
  {
    dial_code: "+238",
    code: "CV"
  },
  {
    dial_code: "+ 345",
    code: "KY"
  },
  {
    dial_code: "+236",
    code: "CF"
  },
  {
    dial_code: "+235",
    code: "TD"
  },
  {
    dial_code: "+56",
    code: "CL"
  },
  {
    dial_code: "+86",
    code: "CN"
  },
  {
    dial_code: "+61",
    code: "CX"
  },
  {
    dial_code: "+61",
    code: "CC"
  },
  {
    dial_code: "+57",
    code: "CO"
  },
  {
    dial_code: "+269",
    code: "KM"
  },
  {
    dial_code: "+242",
    code: "CG"
  },
  {
    dial_code: "+243",
    code: "CD"
  },
  {
    dial_code: "+682",
    code: "CK"
  },
  {
    dial_code: "+506",
    code: "CR"
  },
  {
    dial_code: "+225",
    code: "CI"
  },
  {
    dial_code: "+385",
    code: "HR"
  },
  {
    dial_code: "+53",
    code: "CU"
  },
  {
    dial_code: "+357",
    code: "CY"
  },
  {
    dial_code: "+420",
    code: "CZ"
  },
  {
    dial_code: "+45",
    code: "DK"
  },
  {
    dial_code: "+253",
    code: "DJ"
  },
  {
    dial_code: "+1767",
    code: "DM"
  },
  {
    dial_code: "+1849",
    code: "DO"
  },
  {
    dial_code: "+593",
    code: "EC"
  },
  {
    dial_code: "+20",
    code: "EG"
  },
  {
    dial_code: "+503",
    code: "SV"
  },
  {
    dial_code: "+240",
    code: "GQ"
  },
  {
    dial_code: "+291",
    code: "ER"
  },
  {
    dial_code: "+372",
    code: "EE"
  },
  {
    dial_code: "+251",
    code: "ET"
  },
  {
    dial_code: "+500",
    code: "FK"
  },
  {
    dial_code: "+298",
    code: "FO"
  },
  {
    dial_code: "+679",
    code: "FJ"
  },
  {
    dial_code: "+358",
    code: "FI"
  },
  {
    dial_code: "+33",
    code: "FR"
  },
  {
    dial_code: "+594",
    code: "GF"
  },
  {
    dial_code: "+689",
    code: "PF"
  },
  {
    dial_code: "+241",
    code: "GA"
  },
  {
    dial_code: "+220",
    code: "GM"
  },
  {
    dial_code: "+995",
    code: "GE"
  },
  {
    dial_code: "+49",
    code: "DE"
  },
  {
    dial_code: "+233",
    code: "GH"
  },
  {
    dial_code: "+350",
    code: "GI"
  },
  {
    dial_code: "+30",
    code: "GR"
  },
  {
    dial_code: "+299",
    code: "GL"
  },
  {
    dial_code: "+1473",
    code: "GD"
  },
  {
    dial_code: "+590",
    code: "GP"
  },
  {
    dial_code: "+1671",
    code: "GU"
  },
  {
    dial_code: "+502",
    code: "GT"
  },
  {
    dial_code: "+44",
    code: "GG"
  },
  {
    dial_code: "+224",
    code: "GN"
  },
  {
    dial_code: "+245",
    code: "GW"
  },
  {
    dial_code: "+595",
    code: "GY"
  },
  {
    dial_code: "+509",
    code: "HT"
  },
  {
    dial_code: "+379",
    code: "VA"
  },
  {
    dial_code: "+504",
    code: "HN"
  },
  {
    dial_code: "+852",
    code: "HK"
  },
  {
    dial_code: "+36",
    code: "HU"
  },
  {
    dial_code: "+354",
    code: "IS"
  },
  {
    dial_code: "+91",
    code: "IN"
  },
  {
    dial_code: "+62",
    code: "ID"
  },
  {
    dial_code: "+98",
    code: "IR"
  },
  {
    dial_code: "+964",
    code: "IQ"
  },
  {
    dial_code: "+353",
    code: "IE"
  },
  {
    dial_code: "+44",
    code: "IM"
  },
  {
    dial_code: "+972",
    code: "IL"
  },
  {
    dial_code: "+39",
    code: "IT"
  },
  {
    dial_code: "+1876",
    code: "JM"
  },
  {
    dial_code: "+81",
    code: "JP"
  },
  {
    dial_code: "+44",
    code: "JE"
  },
  {
    dial_code: "+962",
    code: "JO"
  },
  {
    dial_code: "+77",
    code: "KZ"
  },
  {
    dial_code: "+254",
    code: "KE"
  },
  {
    dial_code: "+686",
    code: "KI"
  },
  {
    dial_code: "+850",
    code: "KP"
  },
  {
    dial_code: "+82",
    code: "KR"
  },
  {
    dial_code: "+965",
    code: "KW"
  },
  {
    dial_code: "+996",
    code: "KG"
  },
  {
    dial_code: "+856",
    code: "LA"
  },
  {
    dial_code: "+371",
    code: "LV"
  },
  {
    dial_code: "+961",
    code: "LB"
  },
  {
    dial_code: "+266",
    code: "LS"
  },
  {
    dial_code: "+231",
    code: "LR"
  },
  {
    dial_code: "+218",
    code: "LY"
  },
  {
    dial_code: "+423",
    code: "LI"
  },
  {
    dial_code: "+370",
    code: "LT"
  },
  {
    dial_code: "+352",
    code: "LU"
  },
  {
    dial_code: "+853",
    code: "MO"
  },
  {
    dial_code: "+389",
    code: "MK"
  },
  {
    dial_code: "+261",
    code: "MG"
  },
  {
    dial_code: "+265",
    code: "MW"
  },
  {
    dial_code: "+60",
    code: "MY"
  },
  {
    dial_code: "+960",
    code: "MV"
  },
  {
    dial_code: "+223",
    code: "ML"
  },
  {
    dial_code: "+356",
    code: "MT"
  },
  {
    dial_code: "+692",
    code: "MH"
  },
  {
    dial_code: "+596",
    code: "MQ"
  },
  {
    dial_code: "+222",
    code: "MR"
  },
  {
    dial_code: "+230",
    code: "MU"
  },
  {
    dial_code: "+262",
    code: "YT"
  },
  {
    dial_code: "+52",
    code: "MX"
  },
  {
    dial_code: "+691",
    code: "FM"
  },
  {
    dial_code: "+373",
    code: "MD"
  },
  {
    dial_code: "+377",
    code: "MC"
  },
  {
    dial_code: "+976",
    code: "MN"
  },
  {
    dial_code: "+382",
    code: "ME"
  },
  {
    dial_code: "+1664",
    code: "MS"
  },
  {
    dial_code: "+212",
    code: "MA"
  },
  {
    dial_code: "+258",
    code: "MZ"
  },
  {
    dial_code: "+95",
    code: "MM"
  },
  {
    dial_code: "+264",
    code: "NA"
  },
  {
    dial_code: "+674",
    code: "NR"
  },
  {
    dial_code: "+977",
    code: "NP"
  },
  {
    dial_code: "+31",
    code: "NL"
  },
  {
    dial_code: "+599",
    code: "AN"
  },
  {
    dial_code: "+687",
    code: "NC"
  },
  {
    dial_code: "+64",
    code: "NZ"
  },
  {
    dial_code: "+505",
    code: "NI"
  },
  {
    dial_code: "+227",
    code: "NE"
  },
  {
    dial_code: "+234",
    code: "NG"
  },
  {
    dial_code: "+683",
    code: "NU"
  },
  {
    dial_code: "+672",
    code: "NF"
  },
  {
    dial_code: "+1670",
    code: "MP"
  },
  {
    dial_code: "+47",
    code: "NO"
  },
  {
    dial_code: "+968",
    code: "OM"
  },
  {
    dial_code: "+92",
    code: "PK"
  },
  {
    dial_code: "+680",
    code: "PW"
  },
  {
    dial_code: "+970",
    code: "PS"
  },
  {
    dial_code: "+507",
    code: "PA"
  },
  {
    dial_code: "+675",
    code: "PG"
  },
  {
    dial_code: "+595",
    code: "PY"
  },
  {
    dial_code: "+51",
    code: "PE"
  },
  {
    dial_code: "+63",
    code: "PH"
  },
  {
    dial_code: "+872",
    code: "PN"
  },
  {
    dial_code: "+48",
    code: "PL"
  },
  {
    dial_code: "+351",
    code: "PT"
  },
  {
    dial_code: "+1939",
    code: "PR"
  },
  {
    dial_code: "+974",
    code: "QA"
  },
  {
    dial_code: "+40",
    code: "RO"
  },
  {
    dial_code: "+7",
    code: "RU"
  },
  {
    dial_code: "+250",
    code: "RW"
  },
  {
    dial_code: "+262",
    code: "RE"
  },
  {
    dial_code: "+590",
    code: "BL"
  },
  {
    dial_code: "+290",
    code: "SH"
  },
  {
    dial_code: "+1869",
    code: "KN"
  },
  {
    dial_code: "+1758",
    code: "LC"
  },
  {
    dial_code: "+590",
    code: "MF"
  },
  {
    dial_code: "+508",
    code: "PM"
  },
  {
    dial_code: "+1784",
    code: "VC"
  },
  {
    dial_code: "+685",
    code: "WS"
  },
  {
    dial_code: "+378",
    code: "SM"
  },
  {
    dial_code: "+239",
    code: "ST"
  },
  {
    dial_code: "+966",
    code: "SA"
  },
  {
    dial_code: "+221",
    code: "SN"
  },
  {
    dial_code: "+381",
    code: "RS"
  },
  {
    dial_code: "+248",
    code: "SC"
  },
  {
    dial_code: "+232",
    code: "SL"
  },
  {
    dial_code: "+65",
    code: "SG"
  },
  {
    dial_code: "+421",
    code: "SK"
  },
  {
    dial_code: "+386",
    code: "SI"
  },
  {
    dial_code: "+677",
    code: "SB"
  },
  {
    dial_code: "+252",
    code: "SO"
  },
  {
    dial_code: "+27",
    code: "ZA"
  },
  {
    dial_code: "+211",
    code: "SS"
  },
  {
    dial_code: "+500",
    code: "GS"
  },
  {
    dial_code: "+34",
    code: "ES"
  },
  {
    dial_code: "+94",
    code: "LK"
  },
  {
    dial_code: "+249",
    code: "SD"
  },
  {
    dial_code: "+597",
    code: "SR"
  },
  {
    dial_code: "+47",
    code: "SJ"
  },
  {
    dial_code: "+268",
    code: "SZ"
  },
  {
    dial_code: "+46",
    code: "SE"
  },
  {
    dial_code: "+41",
    code: "CH"
  },
  {
    dial_code: "+963",
    code: "SY"
  },
  {
    dial_code: "+886",
    code: "TW"
  },
  {
    dial_code: "+992",
    code: "TJ"
  },
  {
    dial_code: "+255",
    code: "TZ"
  },
  {
    dial_code: "+66",
    code: "TH"
  },
  {
    dial_code: "+670",
    code: "TL"
  },
  {
    dial_code: "+228",
    code: "TG"
  },
  {
    dial_code: "+690",
    code: "TK"
  },
  {
    dial_code: "+676",
    code: "TO"
  },
  {
    dial_code: "+1868",
    code: "TT"
  },
  {
    dial_code: "+216",
    code: "TN"
  },
  {
    dial_code: "+90",
    code: "TR"
  },
  {
    dial_code: "+993",
    code: "TM"
  },
  {
    dial_code: "+1649",
    code: "TC"
  },
  {
    dial_code: "+688",
    code: "TV"
  },
  {
    dial_code: "+256",
    code: "UG"
  },
  {
    dial_code: "+380",
    code: "UA"
  },
  {
    dial_code: "+971",
    code: "AE"
  },
  {
    dial_code: "+44",
    code: "GB"
  },
  {
    dial_code: "+1",
    code: "US"
  },
  {
    dial_code: "+598",
    code: "UY"
  },
  {
    dial_code: "+998",
    code: "UZ"
  },
  {
    dial_code: "+678",
    code: "VU"
  },
  {
    dial_code: "+58",
    code: "VE"
  },
  {
    dial_code: "+84",
    code: "VN"
  },
  {
    dial_code: "+1284",
    code: "VG"
  },
  {
    dial_code: "+1340",
    code: "VI"
  },
  {
    dial_code: "+681",
    code: "WF"
  },
  {
    dial_code: "+967",
    code: "YE"
  },
  {
    dial_code: "+260",
    code: "ZM"
  },
  {
    dial_code: "+263",
    code: "ZW"
  }
]

export default countryCodes;
