import * as types from '../actions/actionTypes';
import initialState from './initialState';


/*
Inputs:
  state = Estado atual do objeto, caso não seja passado, é utilizado o estado padrão do arquivo
  (initialState.localConfigurations) que é um objeto
  action = Ação aplicada sobre o objeto
Types:
  RELOAD_LOCAL_CONFIGURATIONS = Realiza a ação de recarregar as configurações locais

globalConfigurationReducer = Recarrega as configurações locais do contactto, ou seja, as
configurações do Dashboard de cada atendente (se quer aparecer gráficos ou atendimentos fechados)
 */
export default function localConfigurationReducer(
  state = initialState.localConfigurations,
  action,
) {
  if (action.type === types.RELOAD_LOCAL_CONFIGURATIONS) {
    return { local: action.local };
  }
  return state;
}
