import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";
import axios from "axios";
import { FormattedMessage } from "react-intl";
import { broadcast } from "../../../BLL/general";
import { callStates } from "../../../BLL/globals";
import { getDomain, host, getToken } from "../../../BLL/environment";
import AttendHeader from "./OpenComponents/Header/AttendHeader";
import AttendDetail from "./OpenComponents/AttendDetail";
import AttendContacts from "./OpenComponents/AttendContacts";
import AttendAttendants from "./OpenComponents/AttendAttendants";
import AttendCallType from "./OpenComponents/AttendCallType";
import AttendComment from "./OpenComponents/AttendComment";
import AttendMap from "./OpenComponents/AttendMap";
import AttendExtensionCall from "./OpenComponents/AttendExtensionCall";
import ModalReminderNote from "../../Modal/ModalReminderNote";
import ModalReturnCall from "../../Modal/ModalReturnCall";
import ModalDraggable from "../../Modal/ModalDraggable";
import CallTimer from "../../../BLL/callTimer";
import CallStopwatch from "../../../BLL/callStopwatch";
import CustomModal from "../../Modal/CustomModal";
import messages from "../../../BLL/locale/messages";
import ComponentFailed from "../../Error/ComponentFailedErrorBoundary";
import messagesBroadcast from "../../../BLL/locale/messagesBroadcast";
import * as contactsActions from "../../../actions/contactsActions";
import * as attendsActions from "../../../actions/attendsActions";
import * as reminderActions from "../../../actions/reminderActions";
import * as statusActions from "../../../actions/userStatusActions";

import "../../../css/contactto.scss";
import "../../../css/middle.scss";
import "../../../css/attends.scss";
import "../../../css/callTimer.scss";
import "../../../css/listPage.scss";
import "../../../css/detailPage.scss";
import "../../../css/mixins/_default_buttons.scss";

import style from "./attendOpen.module.scss";
import AccordionSection from "./Accordion";

const AttendOpen = (props) => {
  const {
    contactList,
    attendants,
    loggedAttendantId,
    isAdmin,
    configurationGlobal,
    equipmentList,
    climaxId,
    lang,
  } = useSelector((state) => ({
    contactList: state.contacts,
    attendants:
      state.attendants.length > 0 ? [...state.attendants[0].data] : [],
    loggedAttendantId: state.loggedAttendant ? state.loggedAttendant.id : 0,
    isAdmin: state.loggedAttendant?.groups
      ? state.loggedAttendant.groups[0] === 1 ||
        state.loggedAttendant.groups[0] === 4
      : false,
    configurationGlobal: state.globalConfiguration.global,
    equipmentList: state.equipment[0] && state.equipment[0].data,
    climaxId:
      state.equipment[0]?.data.length > 0 &&
      state.equipment[0].data.find(
        (equip) => equip.name.toLowerCase() === "climax - mobile"
      ).id,
    lang: state.locale.lang,
  }));

  const dispatch = useDispatch();

  const editAttend = (attend) => dispatch(attendsActions.editAttend(attend));
  const patchAttend = (attendId, data) =>
    dispatch(attendsActions.patchAttend(attendId, data));
  const createReminderAction = (reminder) =>
    dispatch(reminderActions.createReminder(reminder));
  const removeUserFromBusy = (userId) =>
    dispatch(statusActions.removeUserFromBusy(userId));

  const refAttend = useRef();
  const refInterval = useRef();

  const [client, setClient] = useState(undefined);
  const [secondary, setSecondary] = useState(undefined);
  const [attend, setAttend] = useState(undefined);
  const [equipment, setEquipment] = useState(undefined);
  const [transmitterList, setTransmitterList] = useState([]);
  const [contactId, setContactId] = useState(0);
  const [attendantId, setAttendantId] = useState(0);
  const [replyMessage, setReplyMessage] = useState("");
  const [callState, setCallState] = useState(callStates.IDLE);
  const [callLimit, setCallLimit] = useState(90);
  const [isCallOpened, setIsCallOpened] = useState(false);
  const [isCallClosed, setIsCallClosed] = useState(false);
  const [permissionToSave, setPermissionToSave] = useState(true);
  const [interval, setAnInterval] = useState(undefined);
  const [callStateTimeout, setCallStateTimeout] = useState(undefined);
  const [stopwatch, setStopwatch] = useState(undefined);
  const [callStart, setCallStart] = useState(undefined);
  const [duration, setDuration] = useState(undefined);
  const [contacts, setContacts] = useState([]);
  const [contacttoTimer, setContacttoTimer] = useState("--:--");
  const [clock, setClock] = useState(undefined);
  const [pointList, setPointList] = useState([]);
  const [contactedCont, setContactedCont] = useState([]);
  const [contactedAtt, setContactedAtt] = useState([]);
  const [reminderError, setReminderError] = useState("");
  const [showReminderNote, setShowReminderNote] = useState(false);
  const [showReminderMessage, setShowReminderMessage] = useState(false);
  const [errorComponent, setErrorComponent] = useState("");
  const [clientMessages, setClientMessages] = useState([]);
  const [attendTag, setAttendTag] = useState(0);
  const [showReturnCallModal, setShowReturnCallModal] = useState(false);
  const [showExtensionCallModal, setShowExtensionCallModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [audioStatus, setAudioStatus] = useState("");
  const [callMsg, setCallMsg] = useState("");
  const [warningLimit, setWarningLimit] = useState(undefined);
  const [currentServerTime, setCurrentServerTime] = useState(undefined);
  const [alarmTime, setAlarmTime] = useState(undefined);
  const [sendWarningMessages, setSendWarningMessages] = useState(undefined);

  useEffect(() => {
    return () => {
      const currentAttend = refAttend.current;
      const currentInterval = refInterval.current;
      if (currentAttend && currentAttend.status === "IN_PROGRESS") {
        const report = {
          id: currentAttend.id,
          report: currentAttend.report,
        };
        sessionStorage.setItem(
          `callReport_${currentAttend.id}`,
          JSON.stringify(report)
        );
        clearInterval(currentInterval);
      }
    };
  }, []);

  useEffect(() => {
    const fetchAttend = (attendId) => {
      axios
        .get(`https://${getDomain()}${host()}/api/attend/${attendId}/`, {
          headers: { Authorization: `Token ${getToken()}` },
        })
        .then((res) => {
          if (res.data.attend.close_ts) {
            props.history.push(`/app/attends/closed/${attendId}/`);
          }
          const data = res.data;
          const client = data.client;
          setClient(Object.assign({}, client));
          setAttend(Object.assign({}, data.attend));
          setEquipment(Object.assign({}, data.equipment));
          setTransmitterList(data.transmitters);
          setClientMessages(data.client_message);
          setAttendantId(data.attendant.id);
          setWarningLimit(data.delay_warning_limit);
          setCurrentServerTime(data.current_time);
          setAlarmTime(data.voice_alarm_ts);
          setSendWarningMessages(data.attend.send_warning_messages);

          if (client.secondary.length > 0) {
            const secondary = client.secondary[0];
            setSecondary(Object.assign({}, secondary));
          }

          if (data.attend.audio_call) {
            const newClock = new CallTimer(
              data.current_time,
              data.voice_alarm_ts,
              data.voice_alarm_limit
            );
            setClock(newClock);
          }
        });
    };

    fetchAttend(props.match.params.id);
  }, [props.match.params.id, props.history]);

  useEffect(() => {
    const loadContacts = (clientId, ordering) =>
      dispatch(contactsActions.loadContacts(clientId, ordering));

    if (client) {
      loadContacts(client.id, "priority");
    }
  }, [client, dispatch]);

  useEffect(() => {
    if (client && contactList.length > 0) {
      const haveContactList = contactList.some((c) => c.clientId === client.id);
      if (haveContactList) {
        const filteredContacts = contactList.filter(
          (c) =>
            parseInt(c.clientId, 10) === client.id && c.ordering === "priority"
        )[0];
        setContacts([...filteredContacts.data]);
      }
    }
  }, [contactList, client]);

  useEffect(() => {
    const acceptedCall = () => {
      if (!isCallClosed) {
        clearTimeout(callStateTimeout);
        setCallState(callStates.INPROGRESS);
        if (equipment?.id === climaxId) {
          setCallMsg(messages[lang]["attend.open.calling"]);
        } else {
          setCallMsg(messages[lang]["attend.open.callConnected"]);
        }
      }
    };

    const requestedCall = () => {
      if (!isCallClosed) {
        const newCallStateTimeout = setTimeout(() => {
          setCallState(callStates.INPROGRESS);
          setCallMsg(messages[lang]["attend.open.stillConnecting"]);
        }, 5000);
        setCallStateTimeout(newCallStateTimeout);
      }
    };

    const errorCall = () => {
      setCallState(callStates.CLOSED);
      setIsCallOpened(false);
      setIsCallClosed(true);
      setPermissionToSave(true);
      setCallMsg(messages[lang]["attend.open.callNotConnected"]);
    };

    const stack = props.sipStackType;
    if (stack === "failed_to_start" && callState === callStates.IDLE) {
      errorCall();
    }
    if (stack === "m_permission_requested" && !showExtensionCallModal && callState !== callStates.NOCALL) {
      requestedCall();
    } else if (stack === "m_permission_accepted" && !showExtensionCallModal && callState !== callStates.NOCALL) {
      acceptedCall();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sipStackType, showExtensionCallModal]);

  useEffect(() => {
    if (clock) {
      startTimer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clock]);

  useEffect(() => {
    if (isCallOpened && clock) {
      clearInterval(interval);
      startTimer();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCallOpened]);

  useEffect(() => {
    if (attend) {
      const hasAudio = attend.events
        ? attend.events.some((alarm) => alarm.event_type.code === "606")
        : false;
      setCallState(hasAudio ? callStates.OPEN : callStates.NOCALL);
      if (sessionStorage[`callReport_${attend.id}`] !== undefined) {
        const callReport = JSON.parse(
          sessionStorage[`callReport_${attend.id}`]
        );
        setAttend(Object.assign({}, attend, { report: callReport.report }));
        sessionStorage.removeItem(`callReport_${attend.id}`);
      }

      if (attend.audio_call) {
        setPermissionToSave(false);

        if (sessionStorage[`answeredCall_${attend.id}`] !== undefined) {
          const answeredCall = JSON.parse(
            sessionStorage[`answeredCall_${attend.id}`]
          );
          if (answeredCall.isAnswered) {
            const watch = new CallStopwatch(answeredCall.callStart);
            setStopwatch(watch);
            setCallState(callStates.INPROGRESS);
            setIsCallOpened(answeredCall.isAnswered);
            setCallLimit(answeredCall.callLimit);
            setCallStart(answeredCall.callStart);
          } else {
            setDuration(answeredCall.duration);
            setIsCallOpened(answeredCall.isAnswered);
            setIsCallClosed(true);
            setCallState(callStates.CLOSED);
            setPermissionToSave(true);
          }
        }
      }
      refAttend.current = attend;
    }
  }, [attend]);

  useEffect(() => {
    if (equipment) {
      const equipmentName = equipment.name.toLowerCase();
      if (
        equipmentName === "ihelp" ||
        equipmentName === "igo" ||
        equipmentName === "climax - mobile" ||
        equipmentName === "ev07b" ||
        equipmentName === "ev07b - cb"
      ) {
        fetchLatestPoint(client.id, attend.id);
      }

      if (equipmentName === "contactto sos - app") {
        fetchSOSAppAttendPoint(attend.id);
      }
    }
  }, [equipment, client, attend]);

  const fetchLatestPoint = (clientId, attendId) => {
    console.log("FETCHING LATEST POINT");
    setErrorComponent("");
    axios({
      url: `https://${getDomain()}${host()}/api/clients/${clientId}/tracker-iot/attend/${attendId}/`,
      method: "GET",
      responseType: "json",
      headers: {
        Authorization: `Token ${getToken()}`,
      },
    })
      .then((res) => {
        if (res.data.length > 0) {
          setPointList(res.data);
          setErrorComponent("");
        }
      })
      .catch((err) => {
        if (err.response.data.error && err.response.data.error === 'No Points') {
          const errorMessage =
            "NÃO FOI POSSÍVEL ACHAR AS COORDENADAS PARA ESTE USUÁRIO. \n POR FAVOR, ESPERE ALGUNS SEGUNDOS E CLIQUE NO BOTÃO DE ATUALIZAR LOCALIZAÇÃO.\n";
          const newErrorComponent = (
            <FormattedMessage
              id="attend.open.errorNoPoints"
              defaultMessage={errorMessage}
            />
          );
          setErrorComponent(newErrorComponent);
        }
      });
  };

  const fetchSOSAppAttendPoint = (attendId) => {
    axios({
      url: `https://${getDomain()}${host()}/api/attend/${attendId}/app-location/`,
      method: "GET",
      responseType: "json",
      headers: {
        Authorization: `Token ${getToken()}`,
      },
    })
      .then((res) => {
        console.log("AQUI Ó", res);
        if (res.data) {
          const point = Object.fromEntries(
            Object.entries(res.data).map(([k, v]) => [k.toUpperCase(), v])
          );
          setPointList([point]);
          setErrorComponent("");
        }
      })
      .catch((err) => {
        if (
          err.response.data &&
          err.response.data.error.includes("No coordinates")
        ) {
          const errorMessage =
            "COORDENADAS NÃO ENCONTRADAS PARA ESTE ATENDIMENTO.";
          const newErrorComponent = (
            <FormattedMessage
              id="attend.closed.errorNoPoints"
              defaultMessage={errorMessage}
            />
          );
          setErrorComponent(newErrorComponent);
        }
      });
  };

  const startTimer = () => {
    // Only start the timer if the call is opened
    if (attend.status !== "CLOSE") {
      let counter = 0;
      const interval = setInterval(() => {
        const newTimer = clock.timer(counter);
        // setTimer(newTimer)
        if (isCallOpened) {
          if (newTimer === "00:02") {
            extendCall();
          } else {
            const newDuration = stopwatch.count();
            setDuration(newDuration);
          }
        } else if (newTimer === -1) {
          clearInterval(interval);
          setCallState(callStates.CLOSED);
          setIsCallOpened(false);
          setIsCallClosed(true);
          setPermissionToSave(true);
          setContacttoTimer("00:00");
        } else {
          setContacttoTimer(newTimer);
        }
        counter += 1;
      }, 1000);
      setAnInterval(interval);
      refInterval.current = interval;
    }
  };

  const changeAttendant = () => {
    const newAttend = Object.assign({}, attend, {
      attendant: loggedAttendantId,
    });
    broadcast(axios, props.channelId, {
      type: "admCall",
      update: "Admin take up call",
      oldUser: attend.attendant,
      text: messagesBroadcast[lang]["broadcast.attendChanged"],
    });

    setAttend(newAttend);
    // setActiveCall(true);

    editAttend(newAttend).catch((err) => {
      if (err.response.data && err.response.data.alreadyClosed) {
        setErrorMessage(
          "Este atendimento já está fechado, estamos voltando você para a tela inicial."
        );
        setTimeout(() => {
          props.history.push("/app");
        }, 5000);
      }
    });
  };

  const setAttendState = (ev) => {
    const field = ev.target.name;
    const value = ev.target.value;
    setAttend(Object.assign({}, attend, { [field]: value }));
  };

  const setPerson = (obj) => {
    if (obj.type === "contact") {
      setContactId(obj.checked ? obj.cont.id : 0);
      setAttendantId(0);
    } else if (obj.type === "attendant") {
      setContactId(0);
      setAttendantId(obj.checked ? obj.att.id : 0);
    }
  };

  const setContacted = (obj) => {
    if (obj.type === "contact") {
      if (obj.checked) {
        setContactedCont([obj.cont, ...contactedCont]);
      } else {
        setContactedCont(
          contactedCont.filter((cont) => cont.id !== obj.cont.id)
        );
      }
    } else if (obj.type === "attendant") {
      if (obj.checked) {
        setContactedAtt([obj.att, ...contactedAtt]);
      } else {
        setContactedAtt(contactedAtt.filter((att) => att.id !== obj.att.id));
      }
    }
  };

  const attendSip = () => {
    const sosAppId =
      equipmentList.length > 0
        ? equipmentList.find(
            (equipment) =>
              equipment.name.toLowerCase() === "contactto sos - app"
          ).id
        : 0;
    const climaxId =
      equipmentList.length > 0
        ? equipmentList.find(
            (equipment) => equipment.name.toLowerCase() === "climax - mobile"
          ).id
        : 0;
    const ev07bId =
      equipmentList.length > 0
        ? equipmentList.find(
            (equipment) => equipment.name.toLowerCase() === "ev07b - cb"
          ).id
        : 0;
    const ihelpId =
      equipmentList.length > 0
        ? equipmentList.find(
            (equipment) => equipment.name.toLowerCase() === "ihelp"
          ).id
        : 0;
    const date = new Date();
    let confNumber = "";
    if (client.use_voip && client.equipment_id === ihelpId) {
      confNumber = `${client.account}${client.imei}`;
    } else if (
      client.equipment_id === climaxId ||
      client.equipment_id === ev07bId ||
      client.equipment_id === sosAppId
    ) {
      confNumber = `005491${client.account}${client.account_phone}`;
    } else {
      confNumber = `${client.account}${client.account_phone}`;
    }
    const newCallStart = date.getTime();

    if (props.attendSip) {
      const newStopwatch = new CallStopwatch(newCallStart);
      setCallState(callStates.IDLE);
      setIsCallClosed(false);
      setPermissionToSave(false);
      setCallStart(newCallStart);
      setStopwatch(newStopwatch);
      setCallMsg(messages[lang]["attend.open.connecting"]);
      setIsCallOpened(true);
      const answeredCall = {
        id: attend.id,
        callLimit: callLimit,
        callStart: newCallStart,
        isAnswered: true,
        duration,
      };
      sessionStorage.setItem(
        `answeredCall_${attend.id}`,
        JSON.stringify(answeredCall)
      );

      props.attendSip(confNumber);
    }
  };

  const hangupSip = () => {
    if (props.hangupSip) {
      clearInterval(interval);
      props.hangupSip();
      setCallState(callStates.CLOSED);
      setIsCallOpened(false);
      setIsCallClosed(true);
      setPermissionToSave(true);

      const answeredCall = {
        id: attend.id,
        callLimit: callLimit,
        callStart: callStart,
        duration: duration,
        isAnswered: false,
      };

      sessionStorage.setItem(
        `answeredCall_${attend.id}`,
        JSON.stringify(answeredCall)
      );
    }
  };

  const extendCall = () => {
    if (props.extendCall) {
      const newLimit = callLimit + 75;
      setCallLimit(newLimit);
      clock.extendLimit(75);
      const answeredCall = {
        id: attend.id,
        callLimit: callLimit,
        callStart: callStart,
        duration: duration,
        isAnswered: true,
      };
      sessionStorage.setItem(
        `answeredCall_${attend.id}`,
        JSON.stringify(answeredCall)
      );
      props.extendCall();
    }
  };

  const closeEmergency = () => {
    const closedAttend = {
      id: attend.id,
      close_ts: moment().format("YYYY-MM-DDTHH:mm:ss.SSS"),
      status: "CLOSE",
      success_contact: contactId ? contactId : null,
      success_attendant: attendantId ? attendantId : null,
      audio_call: attend.audio_call,
      call_duration: duration,
      report: attend.report,
      contactedCont: contactedCont,
      contactedAtt: contactedAtt,
      attendTag: attendTag,
    };
    axios({
      method: "put",
      responseType: "json",
      url: `https://${getDomain()}${host()}/api/attend/${closedAttend.id}/`,
      data: closedAttend,
      headers: { Authorization: `Token ${getToken()}` },
    }).then(() => {
      setAttend(closedAttend);
      closeEmergencyCallback();
    });
  };

  const closeEmergencyCallback = () => {
    removeUserFromBusy(loggedAttendantId);
    props.setUserBusy(false);
    if (sessionStorage[`answeredCall_${attend.id}`] !== undefined) {
      sessionStorage.removeItem(`answeredCall_${attend.id}`);
    }
    if (sessionStorage[`callReport_${attend.id}`] !== undefined) {
      sessionStorage.removeItem(`callReport_${attend.id}`);
    }
    props.history.push("/app");
  };

  const createReminder = (reminder) => {
    const data = reminder;
    data.status = "OPEN";
    data.attend = attend.id;
    data.open_attendant = loggedAttendantId;
    createReminderAction(data)
      .then(() => {
        if (!data.is_individual) {
          broadcast(axios, props.channelId, {
            type: "reminders",
            update: "reminders",
            loggedAttendantId: loggedAttendantId,
            userId: loggedAttendantId,
          });
        }
        setShowReminderNote(false);
        setShowReminderMessage(true);
      })
      .catch((error) => {
        if (error.status === 400 && error.data) {
          if (error.data.open_attendant) {
            setReminderError(
              `Erro atendente não encontrado. Mensagem: ${error.data.open_attendant[0]}`
            );
          } else {
            setReminderError(`Erro. Mensagem: ${error.statusText}`);
          }
        }
      });
  };

  const sendResponseToAlexa = () => {
    const clientMessage = {
      attend: attend.id,
      user: attend.attendant,
      message: replyMessage,
    };

    axios({
      method: "post",
      responseType: "json",
      url: `https://${getDomain()}${host()}/api/attend/client-chat/`,
      data: clientMessage,
      headers: { Authorization: `Token ${getToken()}` },
    })
      .then(() => {
        setClientMessages([...clientMessages, clientMessage]);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
      });
  };

  const stopWarningMessages = () => {
    const data = {
      send_warning_messages: false,
    };
    patchAttend(attend.id, data)
      .then((resp) => {
        console.log(resp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (!attend || !client || !equipment) {
    return (
      <div className="contactto-middle-content" style={{ overflowX: "hidden" }}>
        Carregando atendimento
      </div>
    );
  }

  const reminderMessage = messages[lang]["attend.reminder.message"];
  const isAttendOperator = attend && attend.attendant === loggedAttendantId;

  console.log(!permissionToSave || attendTag === 0);

  let attendantsList = [];
  if (client) {
    attendantsList =
      client.group_id !== 0
        ? attendants.filter(
            (attendant) =>
              attendant.groups.some((grp) => grp === client.group_id) ||
              attendant.groups.length === 1
          )
        : attendants.filter((attendant) => attendant.groups.length === 1);
  }

  return (
    <div className="contactto-middle-content" style={{ overflowX: "hidden" }}>
      {showExtensionCallModal &&
        <ModalDraggable closeModal={() => setShowExtensionCallModal(false)} headerTitle={messages[lang]['extension.headerTitle']}>
          <AttendExtensionCall
            client={client}
            contacts={contacts}
            attendId={attend.id}
            clientAccount={client.account}
            startCall={props.startCall}
            hangupSip={props.hangupSip}
            sipEventType={props.sipEventType}
          />
        </ModalDraggable>
      }
      {showReturnCallModal && (
        <ModalReturnCall
          clientPhone={client.account_phone}
          clientAccount={client.account}
          attendId={attend.id}
          startCall={props.startCall}
          sendPERSPassword={props.sendPERSPassword}
          hangupSip={props.hangupSip}
          muteAudio={props.muteAudio}
          mutePhone={props.mutePhone}
          autoCall={() =>
            props.sendDTMF(
              equipment?.name.toLowerCase().includes("climax") ? 2 : 8
            )
          }
          sipStackType={props.sipStackType}
          closeModal={() => setShowReturnCallModal(!showReturnCallModal)}
          equipment={equipment}
        />
      )}
      {showReminderNote && (
        <ModalReminderNote
          attendId={attend.id}
          createReminder={createReminder}
          closeModal={() => setShowReminderNote(!showReminderNote)}
          reminderError={reminderError}
        />
      )}
      {showReminderMessage && (
        <CustomModal
          testId="reminder-success-message"
          text={reminderMessage}
          btnText={messages[lang]["reminder.closeBtn"]}
          btnAction={() => setShowReminderMessage(false)}
          height="225px"
        />
      )}
      <div
        className="detail-container detail-container--100 container-fluid"
        style={{ overflow: "hidden" }}
        id="detail-container"
      >
        <div className="detail-title detail-title--left">
          <FormattedMessage
            id="attend.detail.mainHeader"
            defaultMessage="ATENDIMENTO"
          />{" "}
          {attend.id}
        </div>
        <span className={style.errorMessage}>{errorMessage}</span>
        <div className="detail-line" />
        <AttendHeader
          display={true}
          client={client}
          secondary={secondary}
          transmitterList={transmitterList}
          equipment={equipment}
          callState={callState}
          callTimer={contacttoTimer}
          duration={duration}
          audioStatus={audioStatus}
          callMsg={callMsg}
          muteAudio={props.muteAudio}
          mutePhone={props.mutePhone}
          autoCall={() => props.sendDTMF(equipment.id === climaxId ? 2 : 8)}
          clientMessages={clientMessages}
          replyMessage={replyMessage}
          setReplyMessage={setReplyMessage}
          sendResponseToAlexa={sendResponseToAlexa}
          attendSip={attendSip}
          hangupSip={hangupSip}
          toggleReminderNote={() => setShowReminderNote(!showReminderNote)}
          setAudioStatus={(val) => setAudioStatus(val)}
          isAdmin={isAdmin}
          isAttendOperator={isAttendOperator}
          changeAttendant={changeAttendant}
          lang={lang}
          openModalReturn={() => setShowReturnCallModal(!showReturnCallModal)}
          openModalExtension={() => setShowExtensionCallModal(true)}
          warningLimit={warningLimit}
          currentServerTime={currentServerTime}
          alarmTime={alarmTime}
          stopWarningMessages={stopWarningMessages}
          sendWarningMessages={sendWarningMessages}
          alarms={attend.events}
          setCallState={setCallState}
        />
        <AccordionSection
          label={<FormattedMessage id="attend.detail.detailsHeader" defaultMessage="DETALHES"/>}
          isOpened
        >
          <AttendDetail
            client={client}
            secondary={secondary}
            transmitterList={transmitterList}
            alarms={attend.events}
            lang={lang}
            showIcons={configurationGlobal.show_icons}
          />
        </AccordionSection>
        {equipment.is_mobile && (
          <AccordionSection
            label={<FormattedMessage id="attend.detail.locationHeader" defaultMessage="LOCALIZAÇÃO"/>}
          >
            <ErrorBoundary FallbackComponent={ComponentFailed}>
              <AttendMap
                attend={attend}
                client={client}
                pointList={pointList}
                equipment={equipment}
                loadLatestPoint={fetchLatestPoint}
                errorComponent={errorComponent}
                lang={lang}
                isAttendOpen
                />
              </ErrorBoundary>
          </AccordionSection>
        )}
        {configurationGlobal &&
          configurationGlobal.contacts_list &&
          isAttendOperator && (
            <AccordionSection
              label={<FormattedMessage id="attend.detail.emergencyContacts" defaultMessage="CONTATOS DE EMERGÊNCIA"/>}
            >
              <ErrorBoundary FallbackComponent={ComponentFailed}>
                <AttendContacts
                  contacts={contacts}
                  setContacted={setContacted}
                  setPerson={setPerson}
                  contactId={contactId}
                  attendantId={attendantId}
                />
              </ErrorBoundary>
            </AccordionSection>
          )}
        {configurationGlobal &&
          configurationGlobal.attendant_list &&
          isAttendOperator && (
            <AccordionSection
              label={<FormattedMessage id="attend.detail.systemAttendants" defaultMessage="ATENDENTES DO SISTEMA"/>}
            >
              <AttendAttendants
                attendants={attendantsList}
                setContacted={setContacted}
                setPerson={setPerson}
                contactId={contactId}
                attendantId={attendantId}
              />
            </AccordionSection>
          )}
        {isAttendOperator && (
          <div>
            <AccordionSection
              label={<FormattedMessage id="attend.detail.callType" defaultMessage="TIPO DE ATENDIMENTO"/>}
            >
              <AttendCallType
                setAttendTag={setAttendTag}
              />
            </AccordionSection>
            <AccordionSection
              label={<FormattedMessage id="attend.detail.comments" defaultMessage="COMENTÁRIOS"/>}
            >
              <AttendComment
                attend={attend}
                setAttendState={setAttendState}
                loggedAttendantId={loggedAttendantId}
              />
            </AccordionSection>
            <div className={style.attendRow}>
              <button
                type="button"
                disabled={!permissionToSave || attendTag === 0}
                className={`${style.btnSave} ${(!permissionToSave || attendTag === 0) && style.btnDisabled}`}
                onClick={closeEmergency}
              >
                <span>
                  <i
                    className="far fa-save fa-lg"
                    aria-hidden="true"
                    style={{ paddingRight: "1rem", paddingTop: "0.2rem" }}
                  />
                  <FormattedMessage
                    id="attend.open.saveButton"
                    defaultMessage="SALVAR ATENDIMENTO"
                  />
                  {attendTag === 0 &&
                    <span className={style.saveTooltip}>
                      <FormattedMessage id="attend.open.toSaveTooltip" defaultMessage="Para salvar" /><br />
                      <FormattedMessage id="attend.open.toSaveTooltipInstruction" defaultMessage="Encerre a chamada e escolha o Tipo de Atendimento" />
                    </span>
                  }
                </span>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

AttendOpen.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  sipEventType: PropTypes.string.isRequired,
  channelId: PropTypes.string.isRequired,
  attendSip: PropTypes.func.isRequired,
  hangupSip: PropTypes.func.isRequired,
  extendCall: PropTypes.func.isRequired,
  muteAudio: PropTypes.func.isRequired,
  mutePhone: PropTypes.func.isRequired,
};

export default AttendOpen;
