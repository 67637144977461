import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import FemaleIcon from '../../../../icons/FemaleIcon';
import MaleIcon from '../../../../icons/MaleIcon';
import UnisexIcon from '../../../../icons/TransexIcon';
import MobileIcon from '../../../../icons/MobileDeviceIcon';
import HomeIcon from '../../../../icons/HomeDeviceIcon';

import style from './attendHeader.module.scss';
import '../../../../css/detailPage.scss';

const AttendHeader = ({
  client,
  secondary,
  transmitterList,
  alarms,
  duration,
  equipment,
  attendAudio,
  display,
  canMakeExtension,
  openModalExtension
}) => {
  const [showSecondary, setShowSecondary] = useState(false);
  const highestAlarm = alarms && alarms.sort((a, b) => a.event_type.priority - b.event_type.priority)[0];

  useEffect(() => {
    if (highestAlarm?.tr_zone && transmitterList.length > 0)  {
      const mainTransmitter = transmitterList.find(tr => tr.zone === highestAlarm.tr_zone && tr.identifier === highestAlarm.tr_identifier);
      if (mainTransmitter?.secondary > 0 && secondary) {
        console.log('Entrei aqui', mainTransmitter);
        setShowSecondary(true);
      }
    }
  }, [highestAlarm, transmitterList, secondary]);

  return (
    <div className={style.row} style={{ display: display ? '' : 'none' }}>
      <div className="col-sm-12 col-md-4 col-lg-4">
        <span className={style['client-name']}>{!showSecondary ? client.first_name : secondary.first_name} {!showSecondary ? client.last_name : secondary.last_name}</span>
        {attendAudio && <audio src={attendAudio} type="audio/wav" controls />}
      </div>

      <div className="col-sm-12 col-md-4 col-lg-4">
        {canMakeExtension &&
          <button className={`${style['btn-external-call']} ${style['btn-wide']}`} onClick={openModalExtension}>
            <FormattedMessage id="attend.open.extensionCall" defaultMessage="CHAMADAS DE EXTENSÃO" />
          </button>
        }
      </div>

      <div className={`col-sm-12 col-md-4 col-lg-4 ${style['right-wrapper']}`}>
        <div className={style['clock-frame']}>
          <div className={style['clock-digits']}>{!duration ? '00:00' : duration} </div>
        </div>
        <div style={{ float: 'right' }}>
          {equipment.is_mobile
            ? <MobileIcon color="#00b8cb" height={'4rem'} width={'4rem'} />
            : <HomeIcon color="#00b8cb" height={'4rem'} width={'4rem'} />
          }
        </div>
        <div className={style['age-number']} >
          {!showSecondary ? moment().year() - moment(client.birthdate).year() : moment().year() - moment(secondary.birthdate).year()}
          <span className={style['age-years']}>
            <FormattedMessage id="attend.open.years" defaultMessage="anos" />
          </span>
        </div>
        {!showSecondary
          ?
            <div style={{ float: 'right' }}>
              {client.gender === 'm' && <MaleIcon color="#00b8cb" height={'4rem'} width={'4rem'} />}
              {client.gender === 'f' && <FemaleIcon color="#00b8cb" height={'4rem'} width={'4rem'} />}
              {client.gender === 'n' && <UnisexIcon color="#00b8cb" height={'4rem'} width={'4rem'} />}
            </div>
          :
            <div style={{ float: 'right' }}>
              {secondary.gender === 'm' && <MaleIcon color="#00b8cb" height={'4rem'} width={'4rem'} />}
              {secondary.gender === 'f' && <FemaleIcon color="#00b8cb" height={'4rem'} width={'4rem'} />}
              {secondary.gender === 'n' && <UnisexIcon color="#00b8cb" height={'4rem'} width={'4rem'} />}
            </div>
        }
      </div>
    </div>
  )
};

AttendHeader.defaultProps = {
  display: true,
  attendAudio: '',
};

AttendHeader.propTypes = {
  client: PropTypes.shape({
    birthdate: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    gender: PropTypes.string,
  }).isRequired,
  equipment: PropTypes.shape({
    name: PropTypes.string,
    is_mobile: PropTypes.bool,
  }).isRequired,
  duration: PropTypes.string.isRequired,
  canMakeExtension: PropTypes.bool.isRequired,
  openModalExtension: PropTypes.func.isRequired,
  attendAudio: PropTypes.string,
  display: PropTypes.bool,
};

export default AttendHeader;
