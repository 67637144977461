import * as types from '../actions/actionTypes';
import initialState from './initialState';


/*
Inputs:
  state = Estado atual do objeto, caso não seja passado, é utilizado o estado padrão do arquivo
  (initialState.ownerAttendant) que é um objeto
  action = Ação aplicada sobre o objeto
Types:
  SET_OWNER_ATTENDANT = Define o usuário que está atualmente utilizando/modificando algum
    cliente/contato/atendimento

ownerAttendantReducer = Define e retorna o usuário atual que está logado no sistema, para
utilização em edições/deleções/criações
 */
export default function loggedAttendantReducer(state = initialState.loggedAttendant, action) {
  switch (action.type) {
    case types.SET_LOGGED_ATTENDANT:
      return Object.assign({}, action.attendant);

    default:
      return state;
  }
}
