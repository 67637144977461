import React from 'react';
import PropTypes from 'prop-types';
import style from './attendCallType.module.scss';
import messageTags from '../../../../BLL/locale/messagesTags';
import { useSelector } from 'react-redux';

const AttendCallType = ({ setAttendTag}) => {
  const { attendTags, lang } = useSelector(state => ({
    attendTags: state.attendTags,
    lang: state.locale.lang
  }));

  return (
    <div className={style.row}>
      {attendTags.map((tag) => (
        <div className={style['button-container']}>
          <input type="radio" value={tag.id} onClick={(ev) => setAttendTag(ev.target.value)} name="attType" /> {messageTags[lang][tag.id]}
        </div>
      ))}
    </div>
  );
};

AttendCallType.propTypes = {
  setAttendTag: PropTypes.func.isRequired,
};

export default AttendCallType;
