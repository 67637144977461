import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Speak from '../../icons/Speak'
import Listen from '../../icons/Listen';
import TwoWayAudio from '../../icons/TwoWayAudio';

import style from './audioMenu.module.scss';

const AudioMenu = (props) => {
  const [audioStatus, setAudioStatus] = useState('');
  return (
    <div className={style['btn-audio-control']}>
      <div className={style['btn-wrapper']}>
        <button
          className={`${style['btn-audio-single']} ${style['btn-speak-color']}`}
          onClick={props.muteAudio}
          onMouseOver={() => setAudioStatus(<FormattedMessage id="attend.open.speak" defaultMessage="Falar" />)}
          onMouseOut={() => setAudioStatus('')}
        >
          <Speak />
        </button>
        <button
          className={`${style['btn-audio-single']} ${style['btn-listen-color']}`}
          onClick={props.mutePhone}
          onMouseOver={() => setAudioStatus(<FormattedMessage id="attend.open.listen" defaultMessage="Ouvir" />)}
          onMouseOut={() => setAudioStatus('')}
        >
          <Listen />
        </button>
        <button
          className={`${style['btn-audio-single']} ${style['btn-two-way-audio-color']}`}
          onClick={props.autoCall}
          onMouseOver={() => setAudioStatus(<FormattedMessage id="attend.open.speakAndListen" defaultMessage="Falar e Ouvir" />)}
          onMouseOut={() => setAudioStatus('')}
        >
          <TwoWayAudio />
        </button>
      </div>
      <div className={style['audio-status-text']}>
        {audioStatus}
      </div>
    </div>
  )
};

export default AudioMenu;
