import React, { useState } from 'react';
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { FormattedMessage } from 'react-intl';
import styles from './softphoneKeyboard.module.scss';
import messages from '../../BLL/locale/messages';

const SoftphoneKeyboard = ({ onConfirm }) => {
  const { lang } = useSelector((state) => ({
    lang: state.locale.lang
  }));
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleButtonClick = (value) => {
    setPhoneNumber(phoneNumber + value);
  };

  const handleBackspace = () => {
    setPhoneNumber(phoneNumber.slice(0, -1));
  };

  const handleConfirm = () => {
    onConfirm(phoneNumber);
  };

  return (
    <div className={styles.softphoneKeyboard}>
      <input
        type="tel"
        className={styles.input}
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        onKeyPress={(event) => {
          if (!/[0-9\-()+\s]/.test(event.key)) {
            event.preventDefault();
          }
        }}
        placeholder={messages[lang]["extension.softphoneInput"]}
      />
      <div className={styles.keyboard}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, '*', 0, '#'].map((value) => (
          <button
            key={value}
            className={styles.key}
            onClick={() => handleButtonClick(value)}
          >
            {value}
          </button>
        ))}
      </div>
      <div className={styles.actions}>
        <button className={styles.backspace} onClick={handleBackspace}>
          Backspace
        </button>
        <button className={styles.confirm} onClick={handleConfirm}>
          <FormattedMessage id="extension.softphoneConfirm" defaultMessage="Confirmar" />
        </button>
      </div>
    </div>
  );
};

SoftphoneKeyboard.propTypes = {
  onConfirm: PropTypes.func.isRequired,
};

export default SoftphoneKeyboard;
