import gql from 'graphql-tag';

export const GET_ONLINE_USERS = gql`
  query($token: String!, $ordering: String) {
    allUsersOnlineStatus(token: $token, ordering: $ordering) {
      name
      group
      companyName
      isOnline
      lastOnlineAt
    }
  }
`;