import moment from 'moment';

// export default class CallTimer {
//   constructor(current, alarm, limit) {
//     this.alarm = new Date(alarm);
//     this.limit = new Date(limit);
//     this.duration = this.limit - this.alarm;
//     this.current = new Date(current);
//     this.extraTime = 0;
//   }

//   timer(counter) {
//     const time = ((this.limit - this.current) - (counter * 1000)) + this.extraTime;
//     if (this.current < this.limit && time < this.duration && time >= 1000) {
//       return moment(time).format('mm:ss');
//     }
//     return -1;
//   }

//   extendLimit(duration) {
//     this.extraTime += (duration * 1000);
//   }

// }

export default class CallTimer {
  constructor(current, alarm, limit) {
    this.setInitialTimes(current, alarm, limit);
    this.duration = this.limit - this.alarm;
    this.extraTime = 0;
  }

  setInitialTimes(current, alarm, limit) {
    this.alarm = new Date(alarm);
    this.limit = new Date(limit);
    this.current = new Date(current);

    // Adjust the current time if it is before the alarm time (discrepancy in the equipment)
    if (this.current < this.alarm) {
      this.current = new Date(this.alarm);
    }
  }

  timer(counter) {
    const time = ((this.limit - this.current) - (counter * 1000)) + this.extraTime;
    if (this.current < this.limit && time <= this.duration && time >= 1000) {
      return moment(time).format('mm:ss');
    }
    return -1;
  }

  extendLimit(duration) {
    this.extraTime += (duration * 1000);
  }
}