import React from "react";
import PropTypes from "prop-types";
import * as general from "../../../BLL/general";
import { getAttendantName } from "../../../BLL/general";
import CallButton from "../../Common/CallButtonV2";
import Audio from "../../../icons/Audio";
import PriorityIcon from "../../../icons/PriorityIcon";
import messagesAlarms from "../../../BLL/locale/messagesAlarms";

import style from "./attendsPage.module.scss";
import "../../../css/contactto.scss";
import "../../../css/attends.scss";

const AttendRow = ({
  isUser,
  attend,
  showClient,
  user,
  setUserBusy,
  channelId,
  isBusy,
  setTextIconDetail,
  setEmptyText,
  lang,
  history,
  useZoneColors,
}) => {
  let clientName = `${
    attend.client_first_name.split(" ")[0]
  } ${attend.client_last_name.split(" ").pop()}`;
  if (attend.is_from_secondary && attend.secondary_first_name) {
    clientName = `${
      attend.secondary_first_name.split(" ")[0]
    } ${attend.secondary_last_name.split(" ").pop()}`;
  }
  const action = isUser
    ? "left attend-item-default attend-item-default--attendant-action"
    : "left attend-item-default attend-item-default--user-action";

  const attendIsClosed =
    attend.status === "CLOSE"
      ? general.formatOnlyNumbersDate(attend.close_ts)
      : "-";
  let priorityIconColor = "high";
  switch (attend.highest_priority) {
    case 1:
      priorityIconColor = "high";
      break;
    case 2:
      priorityIconColor = "medium";
      break;
    case 3:
      priorityIconColor = "low";
      break;
    case 4:
      priorityIconColor = "system";
      break;
    default:
      break;
  }

  return (
    <div className={style.row}>
      <div className={`${style.column} ${style.id}`}>{attend.id}</div>
      {showClient === true && (
        <div className={`${style.column} ${style.client}`}>{clientName}</div>
      )}
      <div className={`${style.column} ${style.attendant}`}>
        {getAttendantName(attend)}
      </div>
      {useZoneColors ? (
        <div className={`${style.column} ${style.resolvedBy}`}>
          {attend.highest_code === "101" && attend.tr_description
            ? attend.tr_description
            : messagesAlarms[lang][attend.highest_code]}
        </div>
      ) : (
        <div className={`${style.column} ${style.resolvedBy}`}>
          {messagesAlarms[lang][attend.highest_code]}
        </div>
      )}
      <div className={`${style.column} ${style.dateOpen}`}>
        {general.formatOnlyNumbersDate(attend.open_ts)}
      </div>
      <div className={`${style.column} ${style.dateClosed}`}>
        {attendIsClosed}
      </div>
      {attend.status === "CLOSE" ? (
        <div className={`${style.column} ${style.audioIcon}`}>
          {attend.audio_file && attend.audio_file !== "" ? (
            <Audio />
          ) : (
            attend.test_attend && (
              <div
                onMouseOver={() => setTextIconDetail("Teste")}
                onMouseOut={setEmptyText}
              >
                <i
                  className={"fas fa-user-times fa-lg"}
                  aria-hidden="true"
                  style={{ transition: ".3s ease" }}
                />
              </div>
            )
          )}
        </div>
      ) : (
        <div
          className={`${style.column} ${style.priority}`}
          onMouseOver={
            useZoneColors
              ? () => {}
              : () => setTextIconDetail(attend.highest_priority)
          }
          onMouseOut={setEmptyText}
        >
          {!useZoneColors && <PriorityIcon className={priorityIconColor} />}
        </div>
      )}
      <div className="attend-det">
        <CallButton
          attend={attend}
          channelId={channelId}
          containerClass={action}
          user={user}
          setUserBusy={setUserBusy}
          isBusy={attend.status === "OPEN" ? isBusy : false}
          history={history}
        />
      </div>
    </div>
  );
};

AttendRow.defaultProps = {
  isBusy: false,
};

AttendRow.propTypes = {
  channelId: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  isBusy: PropTypes.bool,
  attend: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    highest_priority: PropTypes.number,
    client: PropTypes.number,
    client_first_name: PropTypes.string,
    client_last_name: PropTypes.string,
    client_account: PropTypes.string,
    success_contact: PropTypes.number,
    success_attendant: PropTypes.number,
    success_first_name: PropTypes.string,
    success_last_name: PropTypes.string,
    attend_ts: PropTypes.string,
    close_ts: PropTypes.string,
    attendant: PropTypes.number,
    att_first_name: PropTypes.string,
    att_last_name: PropTypes.string,
    open_ts: PropTypes.string,
    audio_file: PropTypes.string,
  }).isRequired,
  isUser: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.number),
    is_active: PropTypes.bool,
    is_staff: PropTypes.bool,
    is_superuser: PropTypes.bool,
    user_info: PropTypes.shape({
      id: PropTypes.number,
      user: PropTypes.number,
      phone: PropTypes.string,
      mobile: PropTypes.string,
      email: PropTypes.string,
      extension_id: PropTypes.number,
    }),
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        priority: PropTypes.number,
        user: PropTypes.number,
        receive_sms: PropTypes.bool,
        receive_email: PropTypes.bool,
      })
    ),
  }).isRequired,
  showClient: PropTypes.bool.isRequired,
  setUserBusy: PropTypes.func.isRequired,
  setTextIconDetail: PropTypes.func.isRequired,
  setEmptyText: PropTypes.func.isRequired,
};

export default AttendRow;
