import React from 'react';
import PropTypes from 'prop-types';

import '../../css/modal.scss';

const WarningModal = ({ message, onClick }) => (
  <div className="warning-modal-wrapper" >
    <button className="warning-modal-button" onClick={onClick} ><i className="fas fa-times" /></button>
    <span className="warning-modal-message">{message} </span>
  </div>
);

WarningModal.propTypes = {
  message: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default WarningModal;
