import React from 'react';
import PropTypes from 'prop-types';

const AlarmLowBatSensIco = ({ height, svgClass, width, fill, transform }) => (
  <svg className={svgClass} width={width} height={height} fill={fill} transform={transform} viewBox="0 0 140 140" xmlns="http://www.w3.org/2000/svg">
    <g id="bXdaTx.tif">
      <g>
        <g>
          <path
            d="M11,31.8c0-0.5,0-0.9,0-1.2c0.1-1.8,1.5-3.2,3.2-3.2c6.4,0,12.8,0,19.2,0c2.1,0,3.4,1.3,3.5,3.5c0,0.2,0,0.5,0,0.8
c0.7,0,1.3,0,2,0c3.8,0,6.6,2.8,6.6,6.6c0,7.7,0,15.4,0,23.1c0,15.2,0,30.4,0,45.6c0,3.4-2,5.9-5.3,6.6c-0.4,0.1-0.9,0.1-1.3,0.1
c-9.9,0-19.9,0-29.8,0c-4,0-6.8-2.7-6.8-6.8c0-10.6,0-21.2,0-31.8c0-12.3,0-24.5,0-36.8c0-3.4,2.1-6,5.4-6.6
C8.8,31.7,9.8,31.8,11,31.8z M11.1,105.1c8.6,0,17.2,0,25.7,0c0-21.5,0-43,0-64.6c-2.9,0-5.7,0-8.6,0c0-1.5,0-2.8,0-4.2
c-2.9,0-5.7,0-8.6,0c0,1.4,0,2.8,0,4.3c-2.9,0-5.7,0-8.6,0C11.1,62,11.1,83.5,11.1,105.1z"
          />
          <path d="M15.3,100.8c0-5.7,0-11.4,0-17.2c5.7,0,11.5,0,17.2,0c0,5.7,0,11.4,0,17.2C26.8,100.8,21.1,100.8,15.3,100.8z" />
        </g>
      </g>
    </g>
    <g>
      <path d="M89.1,62.1c-4.8,0-6.6,4.1-6.6,8.4c0,4.1,1.8,8.2,6.6,8.2c4.8,0,6.6-4.1,6.6-8.2C95.8,66.2,94,62.1,89.1,62.1z" />
      <path
        d="M89.1,19.1c-28,0-50.7,22.7-50.7,50.7c0,28,22.7,50.7,50.7,50.7s50.7-22.7,50.7-50.7C139.8,41.8,117.1,19.1,89.1,19.1z
 M64.8,83.2c-5.4,0-10.5-2.7-10.4-8.7h5.2c0,3.3,2.5,4.5,5.4,4.5c1.9,0,4.8-0.5,4.8-3c0-2.6-3.7-3.1-7.3-4c-3.6-1-7.3-2.4-7.3-7
c0-5,4.7-7.4,9.1-7.4c5.1,0,9.8,2.2,9.8,7.9H69c-0.2-2.9-2.3-3.7-4.8-3.7c-1.7,0-3.7,0.7-3.7,2.8c0,1.9,1.2,2.1,7.3,3.7
c1.8,0.4,7.3,1.6,7.3,7.1C75,79.8,71.5,83.2,64.8,83.2z M89.1,83.2c-7.6,0-12-5.7-12-12.7c0-7.3,4.5-12.9,12-12.9
c7.6,0,12,5.7,12,12.9C101.2,77.5,96.7,83.2,89.1,83.2z M113.7,83.2c-5.4,0-10.5-2.7-10.4-8.7h5.2c0,3.3,2.5,4.5,5.4,4.5
c1.9,0,4.8-0.5,4.8-3c0-2.6-3.7-3.1-7.3-4c-3.6-1-7.3-2.4-7.3-7c0-5,4.7-7.4,9.1-7.4c5.1,0,9.8,2.2,9.8,7.9h-5.2
c-0.2-2.9-2.3-3.7-4.8-3.7c-1.7,0-3.7,0.7-3.7,2.8c0,1.9,1.2,2.1,7.3,3.7c1.8,0.4,7.3,1.6,7.3,7.1
C123.9,79.8,120.3,83.2,113.7,83.2z"
      />
    </g>
  </svg>
);

AlarmLowBatSensIco.defaultProps = {
  height: '75',
  svgClass: '',
  width: '75',
  fill: '#008D53',
  transform: 'translate(0,0)',
};

AlarmLowBatSensIco.propTypes = {
  height: PropTypes.string,
  svgClass: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
  transform: PropTypes.string,
};

export default AlarmLowBatSensIco;
