import React from 'react';
import PropTypes from 'prop-types';

class CheckboxInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      className: this.props.className,
      checked: this.props.checked,
    };
    this.handleCheckbox = this.handleCheckbox.bind(this);
  }

  handleCheckbox(event) {
    const val = event.target.checked;
    const ev = { target: { value: val, name: event.target.name } };

    this.setState({ checked: val });
    this.props.onChange(ev, true);
  }

  componentDidUpdate(prevProps) {
    if (this.props.checked !== prevProps.checked) {
      this.setState({
        checked: this.props.checked,
      });
    }

    if (this.props.className !== prevProps.className) {
      this.setState({
        className: this.props.className,
      });
    }
  }

  render() {
    return (
      <input
        id={this.props.id}
        type="checkbox"
        disabled={this.props.disabled}
        className={this.state.className}
        checked={this.state.checked}
        name={this.props.name}
        onChange={this.handleCheckbox}
      />
    );
  }

}

CheckboxInput.defaultProps = {
  id: '',
  checked: false,
  disabled: false,
  className: '',
  onChange: () => {},
};

CheckboxInput.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  checked: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};


export default CheckboxInput;
