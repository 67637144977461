import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { alarmNameLang } from '../../../../BLL/dictionary';

import style from './reportAlarm.module.scss';

const ReportAlarmRow = ({ alarm }) => (
  <Link to={`/app/attends/${alarm.status === 'CLOSE' ? 'closed' : 'call'}/${alarm.id}/`} className={style.row}>
    <div className={`${style.column} ${style.id}`}>{alarm.id ? alarm.id : '-'}</div>
    <div className={`${style.column} ${style.client}`}>{alarm.user ? alarm.user : '-'}</div>
    <div className={`${style.column} ${style.client}`}>{alarm.city ? alarm.city : '-'}</div>
    <div className={`${style.column} ${style.client}`}>{alarm.company ? alarm.company : '-'}</div>
    <div className={`${style.column} ${style.alarm}`}>{`(${alarm.code}) ${alarmNameLang(alarm)}`}</div>
    <div className={`${style.column} ${style.alarm}`}>{alarm.category === '1'
      ? <FormattedMessage id="reports.event" defaultMessage="Evento" />
      : <FormattedMessage id="reports.restoration" defaultMessage="Restauração" />}</div>
    <div className={`${style.column} ${style.attendant}`}>{alarm.attendant ? alarm.attendant : '-'}</div>
    <div className={`${style.column} ${style.date}`}>{alarm.alarmDate ? moment(alarm.alarmDate).format('DD/MM/YYYY - HH:mm') : '-'}</div>
    <div className={`${style.column} ${style.duration}`}>{alarm.audioCallDuration ? alarm.audioCallDuration : '-'}</div>
  </Link>
);

ReportAlarmRow.propTypes = {
  alarm: PropTypes.object.isRequired,
};

export default ReportAlarmRow;
