import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import ReportButton from '../ReportButtonComponent';
import messagesMonths from '../../../../BLL/locale/messagesMonths';

import style from '../reportComponent.module.scss';
import { useEffect } from 'react';

const ClientByCompanyComponent = (props) => {
  const lang = useSelector(state => state.locale.lang);
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(moment().locale('pt-br').year())
  const [selectedMonth, setSelectedMonth] = useState(moment().locale('pt-br').month() + 1)
  const [urlDate, setUrlDate] = useState('');
  const [focusedInput, setFocusedInput] = useState(false);

  useEffect(() => {
    const now = moment().locale('pt-br')
    setUrlDate(`year=${now.year()}&month=${now.month()+1}`)
  }, []);

  useEffect(() => {
    const calcYearList = () => {
      const dt = new Date();
      const current = dt.getFullYear();
      let begin = current - 4;
      const yearList = [];
  
      while (begin <= current) {
        yearList.push({ value: `${begin}`, label: `${begin}` });
        begin++;
      }
      setYears(yearList);
    };

    calcYearList();
  }, []);

  useEffect(() => {
    setUrlDate(`year=${selectedYear}&month=${selectedMonth}`);
  }, [selectedYear, selectedMonth])

  const setFocusInput = (input) => {
    setFocusedInput(input.focused);
  }

  console.log('urlDate', urlDate);

  return (
    <div className={style.wrapper} style={focusedInput ? { height: 'auto' } : {}}>
      <div className="left contactto-100" style={{ marginTop: '10px', width: '100%' }}>
        <select
          className="custom-select"
          onChange={(e) => setSelectedYear(e.target.value)}
          value={selectedYear}
        >
          {years.map((item) => 
            <option key={item.value} value={item.value}>{item.value}</option>
          )}
        </select>
        <select
          className="custom-select"
          style={{ marginTop: '10px' }}
          onChange={(e) => setSelectedMonth(e.target.value)}
          value={selectedMonth}
        >
          <option value={1}> {messagesMonths[lang]['january']} </option>
          <option value={2}> {messagesMonths[lang]['february']} </option>
          <option value={3}> {messagesMonths[lang]['march']} </option>
          <option value={4}> {messagesMonths[lang]['april']} </option>
          <option value={5}> {messagesMonths[lang]['may']} </option>
          <option value={6}> {messagesMonths[lang]['june']} </option>
          <option value={7}> {messagesMonths[lang]['july']} </option>
          <option value={8}> {messagesMonths[lang]['august']} </option>
          <option value={9}> {messagesMonths[lang]['september']} </option>
          <option value={10}> {messagesMonths[lang]['october']} </option>
          <option value={11}> {messagesMonths[lang]['november']} </option>
          <option value={12}> {messagesMonths[lang]['december']} </option>
        </select>
      </div>
      <ReportButton
        themeColor={props.themeColor}
        url={`/app/reports/list/clients-company/${urlDate}`}
        text={<FormattedMessage id="reports.generateReport" defaultMessage="Gerar Relatório" />}
      />
    </div>
  );

};

ClientByCompanyComponent.propTypes = {
  themeColor: PropTypes.string.isRequired,
};

export default ClientByCompanyComponent;
