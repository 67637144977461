import React from "react";
import PropTypes from 'prop-types';

const AlarmTiltIco = ({ height, svgClass, width, fill, transform }) => {
  return (
    <svg
      className={svgClass}
      width={width}
      height={height}
      fill={fill}
      transform={transform}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 141.7 141.7"
      version="1.1"
      viewBox="0 0 141.7 141.7"
      xmlSpace="preserve"
    >
      <path
        d="M109.2 65.6c7.9 30-4.3 50.9-25 55.7-21.3 5.1-42.1-7.2-49.6-37.3-8.1-31 3.5-59.3 23.5-64 20.7-4.7 43.1 15.1 51.1 45.6z"
        className="st0"
      ></path>
      <path
        d="M96 85.8c2.9 11-3.8 22.3-14.8 25-11.4 2.8-22.3-3.9-25-15-3-11.3 4.3-22.4 15-25 11-2.7 21.9 3.8 24.8 15z"
        className="st1"
      ></path>
      <path d="M57.1 59.9L77.5 54" className="st2"></path>
      <path d="M55.6 54.6L76 48.7" className="st2"></path>
      <path d="M54.1 49.3L74.5 43.4" className="st2"></path>
      <path
        d="M89.1 19.4c17.7 4.7 25.7 35 25.7 35M100.6 19.4c14.2 10.1 16.8 24.3 16.4 23.9"
        className="st3"
      ></path>
    </svg>
  );
};

AlarmTiltIco.defaultProps = {
  height: '75',
  svgClass: '',
  width: '75',
  fill: '#008D53',
  transform: 'translate(0,0)',
};

AlarmTiltIco.propTypes = {
  height: PropTypes.string,
  svgClass: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
  transform: PropTypes.string,
};

export default AlarmTiltIco;