import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { FormattedMessage } from 'react-intl';
import ExtensionCaller from "../../Middle/Attends/OpenComponents/ExtensionCaller";
import SoftphoneKeyboard from "../../Common/SoftphoneKeyboard";
import ContactComponent from "./ReminderExtensionContactComponent";
import * as general from "../../../BLL/general"
import messages from "../../../BLL/locale/messages";
import styles from "./reminderModal.module.scss";

const ReminderExtensionCall = ({ selectedReminder, startCall, hangupSip }) => {
  const { lang } = useSelector((state) => ({
    lang: state.locale.lang
  }));
  const [selectedLine, setSelectedContact] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [showSoftphoneKeyboard, setShowSoftphoneKeyboard] = useState(false);

  const handleSelectButton = (type, data = null, phoneType="") => {
    let line = {
      type,
      phone: "",
      name: messages[lang]['extension.own'],
    }

    if (type === "client") {
      switch (phoneType) {
        case 'extra':
          line.phone = selectedReminder.client_extra_phone;
          break;
        default:
          line.phone = selectedReminder.client_contact_phone;
          break;
      }
    } else {
      line.phone = data.phone;
      line.name = data.name;
    }
    setSelectedContact(line);
    setShowDetails(true);
  }

  const handleBackButton = () => {
    setShowDetails(false);
  };

  const handleCustomPhoneConfirm = (customPhone) => {
    if (customPhone) {
      setSelectedContact({
        type: 'custom',
        phone: customPhone,
        name: 'custom',
      });
      setShowDetails(true);
    }
  };

  console.log(selectedReminder)

  return showDetails ? (
    <ExtensionCaller
      selectedLine={selectedLine}
      handleBackButton={handleBackButton}
      clientAccount={selectedReminder.client_account}
      attendId={selectedReminder.attend}
      startCall={startCall}
      hangupSip={hangupSip}
      callType="reminder"
    />
  ) : (
    <div className={styles['extension-container']}>
      <div className={styles["extension-client-info"]}>
        <div className={styles["extension-client-container"]}>
          <span className={styles['extension-client-title']}>
            <FormattedMessage id="extension.clientInfo" defaultMessage="INFORMAÇÕES DO USUÁRIO" />
          </span>
          <div>
            <span className={styles['extension-label']}><FormattedMessage id="client.page.columnAccount" defaultMessage="CONTA"/>:</span> <span>{selectedReminder.client_account}</span>
          </div>
          {selectedReminder.client_contact_phone && 
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
              <div>
                <span className={styles['extension-label']}><FormattedMessage id="client.detail.contactPhone" defaultMessage="Telefone de contato"/>:</span> <span>{
                    selectedReminder.client_contact_phone.length > 10 ? general.setMask(selectedReminder.client_contact_phone, '(00) 0 0000-0000') : general.setMask(selectedReminder.client_contact_phone, '(00) 0000-0000')
                  }
                </span>
              </div>
              <button onClick={() => handleSelectButton("client", null, 'contact')} className={styles['extension-select-btn']}>
                <FormattedMessage id="extension.select" defaultMessage="SELECIONAR" />
              </button>
            </div>
          }
          {selectedReminder.client_extra_phone && 
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
              <div>
                <span className={styles['extension-label']}><FormattedMessage id="client.detail.contactPhone" defaultMessage="Telefone de contato"/>:</span> <span>{
                  selectedReminder.client_extra_phone.length > 10 ? general.setMask(selectedReminder.client_extra_phone, '(00) 0 0000-0000') : general.setMask(selectedReminder.client_extra_phone, '(00) 0000-0000')
                }</span>
              </div>
              <button onClick={() => handleSelectButton("client", null, 'extra')} className={styles['extension-select-btn']}>
                <FormattedMessage id="extension.select" defaultMessage="SELECIONAR" />
              </button>
            </div>
          }
        </div>
      </div>
      {/* {selectedReminder.client_extra_phone !== "-" ? (
        <div className={styles["extension-client-info"]}>
          <div className={styles["extension-client-container"]}>
            <span className={styles['extension-client-title']}>
              <FormattedMessage id="extension.clientInfo" defaultMessage="INFORMAÇÕES DO USUÁRIO" />
            </span>
            <div>
              <span className={styles['extension-label']}><FormattedMessage id="client.page.columnAccount" defaultMessage="CONTA"/>:</span> <span>{selectedReminder.client_account}</span>
            </div>
            <div>
              <span className={styles['extension-label']}><FormattedMessage id="client.detail.contactPhone" defaultMessage="Telefone de contato"/>:</span> <span>{
                  selectedReminder.client_contact_phone.length > 10 ? general.setMask(selectedReminder.client_contact_phone, '(00) 0 0000-0000') : general.setMask(selectedReminder.client_contact_phone, '(00) 0000-0000')
                }</span>
            </div>
          </div>
          <button onClick={() => handleSelectButton("client")} className={styles['extension-select-btn']}>
            <FormattedMessage id="extension.select" defaultMessage="SELECIONAR" />
          </button>
        </div>
      )
      : (
        <div className={styles["extension-client-info"]}>
          <FormattedMessage id="extension.noContactPhone" defaultMessage="Este usuário não possui telefone de contato" />
        </div>
      )} */}
      <hr />
      <span className={styles['extension-contact-title']}>
        <FormattedMessage id="attend.open.contactsHeader" defaultMessage="CONTATOS DE EMERGÊNCIA" />
      </span>
      {selectedReminder?.contacts_info?.map((contact, index) => (
        <ContactComponent key={index} contact={contact} handleSelectButton={handleSelectButton} />
      ))}
      <hr />
      <span className={styles['extension-contact-title']}>
        <FormattedMessage id="extension.softphoneTitle" defaultMessage="TECLADO DE TELEFONE" />
      </span>
      <div className={styles['softphone-container']}>
        <button
          onClick={() => setShowSoftphoneKeyboard(!showSoftphoneKeyboard)}
          className={styles['extension-select-btn']}
        >
          {showSoftphoneKeyboard ? 
            <FormattedMessage id="extension.softphoneClose" defaultMessage="FECHAR" /> :
            <FormattedMessage id="extension.softphoneOpen" defaultMessage="ABRIR" />}
        </button>
      </div>
      {showSoftphoneKeyboard && (
        <SoftphoneKeyboard onConfirm={handleCustomPhoneConfirm} />
      )}
    </div>
  )
};

ReminderExtensionCall.propTypes = {
  startCall: PropTypes.func.isRequired,
  hangupSip: PropTypes.func.isRequired,
  selectedReminder: PropTypes.shape({
    client_account: PropTypes.string,
    client_contact_phone: PropTypes.string,
    contacts_info: PropTypes.arrayOf(PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      phone_list: PropTypes.arrayOf(PropTypes.shape({
        phone: PropTypes.string,
        phone_type: PropTypes.string
      }))
    }))
  }).isRequired,
}

export default ReminderExtensionCall;
