import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import moment from 'moment';
import gql from 'graphql-tag';
import Loading from '../../../../icons/Loading';
import style from './iotDeviceList.module.scss';

const allTrackerData = gql`
  query($token: String!, $tracker: String, $ordering: String) {
    trackerAllList(token: $token, tracker: $tracker, ordering: $ordering) {
      imei
      clientId
      account
      fullName
      companyName
      sendtime
      battery
      isOnline
      isDeleted
    }
  }
`;

const RenderTrackerData = ({ tracker, order}) => {
  const { loading, error, data } = useQuery(allTrackerData, {
    variables: { 
      token: sessionStorage.activeToken,
      ordering: order,
      tracker
    },
    pollInterval: 30000
  });

  if (loading) {
    return (<Loading text={<FormattedMessage id="attend.list.loading" defaultMessage="Carregando" />} />);
  }

  if (error) {
    return (<div>Error -> {error}</div>)
  }

  return (
    <div className={style.body}>
      {data.trackerAllList.length > 0 ?
        data.trackerAllList.sort((trackerA, trackerB) => trackerA.isDeleted - trackerB.isDeleted).map(trckr => (
          <Link to={`/app/clients/${tracker}/${trckr.clientId}`} key={trckr.clientId} className={`${style.row} ${trckr.isDeleted && style.removed}`}>
            <div className={style.statusTableBodyField}>
              <div className={trckr.isOnline ? style.online : style.offline} />
            </div>
            <div className={style.tableImeiField}>
              {trckr.imei}
            </div>
            <div className={style.tableNameField}>
              {trckr.fullName.split(' ')[0]} {trckr.fullName.split(' ').pop()}
            </div>
            <div className={style.tableCompanyField}>
              {trckr.companyName}
            </div>
            <div className={style.tableBatteryField}>
              {trckr.battery}
            </div>
            <div className={style.tableLastEntryField}>
              {trckr.datetime === '-1'
              ? <FormattedMessage id="tracker.noData" defaultMessage="Sem dados" />
              : (tracker === 'igo')
                ? moment(trckr.sendtime).format('DD/MM/YYYY HH:mm:ss')
                : moment(parseInt(trckr.sendtime, 10)).format('DD/MM/YYYY HH:mm:ss')
              } 
            </div>
          </Link>
        ))
        : <div className={style.row}> <FormattedMessage id="general.emptyList" defaultMessage="Lista Vazia" /> </div>
      }
    </div>
  );
};

RenderTrackerData.propTypes = {
  tracker: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
};

export default RenderTrackerData;
