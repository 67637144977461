import React from 'react';
import PropTypes from 'prop-types';

import AlarmIcon from '../../../icons/AlarmIcon';
import messagesAlarms from '../../../BLL/locale/messagesAlarms';
import { trDescriptionLang } from '../../../BLL/dictionary';

import { formatDate } from '../../../BLL/general';
import '../../../css/iconsAlarms.scss';
import style from './attendsCard.module.scss';

const AttendsClosedCard = ({ attend_ts, attendant, iconColor, alarmCode, lang, tr_description, showIcons, attend_id }) => (
  <div className={style.info}>
    <div style={{ float: 'left', width: '100%' }}>
        <div className={style.iconwrapper} data-testid={`closed-icon-${attend_id}`}>
          {showIcons &&
            <AlarmIcon alarmCode={alarmCode} color={iconColor} height={'4rem'} width={'4rem'} float={'none'} />
          }
        </div>
      <div className={style.text} style={{ paddingLeft: '0.5rem', display: 'inline-block' }}>
        <div className={style.value} style={{ color: 'black', fontSize: '1rem', fontWeight: '500', marginBottom: '0.5rem' }}>{attendant}</div>
        <div className={style.value} style={{ color: 'black', fontSize: '1rem', fontWeight: '500', marginTop: '0.5rem', marginBottom: '0.5rem' }} >{formatDate(attend_ts, 'card')}</div>
      </div>
      <div data-testid={`closed-footer-${attend_id}`} className={style['card-footer']} style={{ backgroundColor: iconColor }}>
        {tr_description 
          ? `${(alarmCode === '384' || alarmCode === '381') ? `${messagesAlarms[lang][alarmCode]} - ${trDescriptionLang(tr_description)}` : trDescriptionLang(tr_description) }`
          : messagesAlarms[lang][alarmCode] !== undefined ? messagesAlarms[lang][alarmCode] : messagesAlarms[lang][101]
        }
      </div>
    </div>
  </div>
);

AttendsClosedCard.defaultProps = {
  iconColor: '#D8342B',
};

AttendsClosedCard.propTypes = {
  attendant: PropTypes.string.isRequired,
  attend_ts: PropTypes.string.isRequired,
  alarmCode: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  iconColor: PropTypes.string,
};

export default AttendsClosedCard;
