import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { authResetStore } from '../../actions/authActions';
import { useHistory } from 'react-router';
import Order from '../../icons/Order';
import style from './headerDomain.module.scss';

const HeaderDomain = (props) => {
  const history = useHistory();
  const wrapperRef = useRef(null);
  const [domainList , setDomainList] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  
  useEffect(() => {
    const newDomainList = JSON.parse(localStorage.domainList).filter(domain => domain !== sessionStorage.activeDomain);
    setDomainList(newDomainList);
    document.addEventListener('mousedown', clickedOutside);

    return () => {
      document.removeEventListener('mousedown', clickedOutside);
    }
  }, []);

  useEffect(() => {
    const newDomainList = JSON.parse(localStorage.domainList).filter(domain => domain !== sessionStorage.activeDomain);
    setDomainList(newDomainList);
  }, [localStorage.domainList]);

  const changeDomains = (domain) => {
    const domainInfo = JSON.parse(localStorage.domainInfo);
    sessionStorage.removeItem('channelId');
    sessionStorage.setItem('activeDomain', domain);
    sessionStorage.setItem('activeToken', domainInfo[domain].token);
    props.history.push('/app');
    window.location.reload();
  };

  const gotoNewDomain = () => {
    history.push('/login');
    sessionStorage.removeItem('channelId');
    props.addNewDomain();
    window.location.reload();
  }

  const clickedOutside = (evt) => {
    if (wrapperRef.current && !wrapperRef.current.contains(evt.target)) {
      setIsExpanded(false);
    }
  };

  return (
    <nav className={`${style.container} ${isExpanded ? style['container-expanded'] : ''}`} ref={wrapperRef} >
      <ul className={style['selected-domain-container']}>
        <li className={`${style['selected-domain-item']} ${isExpanded && style['menu-button-expanded']}`} onClick={() => setIsExpanded(!isExpanded)}>
          <span>{sessionStorage.activeDomain.toUpperCase()}</span>
          <Order width="25" height="15" fill="#fff" className={`${isExpanded && style.rotate}`}/>
        </li>
      </ul>
      <ul className={style['menu-list']}>
        {domainList.map(domain => (
          <li className={style['menu-list-items']} key={domain} onClick={() => changeDomains(domain)}>
            <span className={style['menu-list-items__text']}>{domain.toUpperCase()}</span>
          </li>
        ))}
        <li className={style['menu-list-items']}>
          <div id="header-domain-add" className={style['menu-list-items__text']} onClick={gotoNewDomain}>
            <span style={{ marginRight: '10px'}}>
              <FormattedMessage id="header.domain.add" defaultMessage="NOVO DOMÍNIO" />
            </span>
            <i class="far fa-plus-square" />
          </div>
        </li>
      </ul>
    </nav>
  )
};


function mapStateToProps(state, ownProps) {
  return {
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addNewDomain: () => dispatch(authResetStore())
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(HeaderDomain);
