import React, { useState, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { getDomain, host, getToken } from "../../../../BLL/environment";
import { equipmentTypes } from "../../../../BLL/dictionary";
import * as general from "../../../../BLL/general";
import { validateMobile } from "../../../../BLL/validate";
import InputContainer from "../../../Common/InputContainer";
import SelectInput from "../../../Common/SelectInput";
import * as clientsActions from "../../../../actions/clientsActions";
import { broadcast } from "../../../../BLL/general";
import ConfirmRemoveModal from "../../../Modal/ModalConfirm";
import CheckboxInput from "../../../Common/CheckboxInput";
import ErrorComponent from "../../../Common/ErrorComponent";
import broadcastMessages from "../../../../BLL/locale/messagesBroadcast";
import countryList from "../../../../BLL/locale/messagesCountries";
import messages from "../../../../BLL/locale/messages";
import ClientInfoForm from "./ClientInfoForm";
import SecondaryActionsForm from "./SecondaryActionsForm";
import Order from "../../../../icons/Order";

import detail from "../../../../css/detail.module.scss";
import formStyle from "../../../../css/form.module.scss";

import "../../../../css/contactto.scss";
import "../../../../css/middle.scss";
import "../../../../css/style.scss";
import "../../../../css/block.scss";
import "../../../../css/formPage.scss";

import style from "./clientForm.module.scss";

import reducer, { initialState } from "./ClientReducer";

const ClientForm = (props) => {
  const [state, localDispatch] = useReducer(reducer, initialState);

  const { equipment, lang, loggedAttendant, companies, isSysAdm } = useSelector(
    (state) => ({
      clients: state.clients,
      equipment: state.equipment,
      lang: state.locale.lang,
      loggedAttendant: state.loggedAttendant,
      companies: state.groups.filter((grp) => ![1, 2, 3, 4].includes(grp.id)),
      isSysAdm: state.loggedAttendant.groups
        ? (state.loggedAttendant.groups[0] === 1 ||
            state.loggedAttendant.groups[0] === 4) &&
          state.loggedAttendant.groups.length === 1
        : false,
    })
  );

  const dispatch = useDispatch();

  const editClient = (client) => dispatch(clientsActions.editClient(client));
  const createClient = (client) =>
    dispatch(clientsActions.createClient(client));

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorCode, setErrorCode] = useState(0);
  const [showFirstDeleteModal, setFirstDeleteModal] = useState(false);
  const [showSecondDeleteModal, setSecondDeleteModal] = useState(false);
  const [showModalPhone, setModalPhone] = useState(false);
  const [showModalVoip, setModalVoip] = useState(false);
  const [showModalRange, setShowModalRange] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [showPrimaryUser, setShowPrimaryUser] = useState(false);
  const [showSecondaryUser, setShowSecondaryUser] = useState(false);
  const [equipmentList, setEquipment] = useState([]);
  const ihelpId =
    equipmentList.length > 0
      ? equipmentList.find(
          (equipment) => equipment.name.toLowerCase() === "ihelp"
        ).id
      : 0;
  const equipmentMsgDefaultText =
    "Usuário está marcado com {equipmentName} mas está com um celular.\n\nSalvar mesmo assim?";
  const message = (
    <FormattedMessage
      id="client.formMessage.equipment"
      defaultMessage={equipmentMsgDefaultText}
      values={{ equipmentName: equipmentTypes(state.client.equipment_id) }}
    />
  );
  const voipMessage = (
    <FormattedMessage
      id="client.formMessage.voip"
      defaultMessage="Este usuário está marcado como VoIP, certifique-se que este equipamento está conectado a um equipamento do tipo ATA para poder realizar chamadas de emergência."
    />
  );
  const countries = countryList[lang];
  const pers4200Id =
    equipmentList.length > 0
      ? equipmentList.find(
          (equipment) => equipment.name.toLowerCase() === "pers4200"
        ).id
      : 0;
  const pers3600Id =
    equipmentList.length > 0
      ? equipmentList.find(
          (equipment) => equipment.name.toLowerCase() === "pers3600"
        ).id
      : 0;
  const sosAppId =
    equipmentList.length > 0
      ? equipmentList.find(
          (equipment) => equipment.name.toLowerCase() === "contactto sos - app"
        ).id
      : 0;
  const canUseVoipEquipment = [ihelpId, pers3600Id, pers4200Id, sosAppId];

  useEffect(() => {
    if (props.match.params.method === "edit") {
      const clientId = parseInt(props.match.params.clientId, 10);
      axios
        .get(`https://${getDomain()}${host()}/api/clients/${clientId}/`, {
          headers: { Authorization: `Token ${getToken()}` },
        })
        .then((res) =>
          localDispatch({ type: "loadClients", payload: { value: res.data } })
        )
        .catch((error) => console.log(error));
    }
  }, [props.match.params.clientId, props.match.params.method]);

  useEffect(() => {
    if (equipment.length > 0) {
      setEquipment(equipment[0].data);
    }
  }, [equipment, equipment.length]);

  const setClientState = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    localDispatch({
      type: "editClient",
      payload: { data: { name, value }, ihelpId },
    });
    localDispatch({
      type: "editClientValidator",
      payload: { data: { name, value }, equipmentList },
    });
  };

  const setSecondaryState = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    localDispatch({
      type: "editSecondary",
      payload: { data: { name, value } },
    });
    localDispatch({
      type: "editSecondaryValidator",
      payload: { data: { name, value } },
    });
  };

  const closeErrorModal = () => {
    setError(false);
    setErrorMsg("");
    setErrorCode(0);
  };

  const openDeleteModal = () => {
    setFirstDeleteModal(true);
    setSecondDeleteModal(false);
  };

  const confirmDelete1 = () => {
    setFirstDeleteModal(false);
    setSecondDeleteModal(true);
  };

  const setDeleteModalInfo = () => {
    setFirstDeleteModal(false);
    setSecondDeleteModal(false);
  };

  const setModalInfo = () => {
    setModalPhone(false);
  };

  const confirmDelete2 = () => {
    const client = state.client;
    const lang = !localStorage.contacttoLang
      ? "pt"
      : localStorage.contacttoLang;
    const text = `${broadcastMessages[lang]["broadcast.client"]} ${client.first_name} ${client.last_name} ${broadcastMessages[lang]["broadcast.deleted"]}`;
    axios({
      url: `https://${getDomain()}${host()}/api/clients/${client.id}/`,
      method: "DELETE",
      responseType: "json",
      headers: {
        Authorization: `Token ${getToken()}`,
      },
    })
      .then(() => {
        broadcast(axios, props.channelId, {
          type: "client",
          update: "client",
          id: props.match.params.clientId,
          text,
        });
        props.history.push("/app/clients");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const verifyAccountRange = () => {
    const client = state.client;
    let modalRange = false;
    companies.forEach((company) => {
      if (
        parseInt(client.group_id) !== company.id &&
        company.range_list.length > 0
      ) {
        console.log(client.group_id);
        console.log(company);
        const clientAccount = parseInt(client.account);
        company.range_list.forEach((range) => {
          const maxRange = parseInt(range.max_account);
          const minRange = parseInt(range.min_account);
          if (clientAccount >= minRange && clientAccount <= maxRange) {
            modalRange = true;
          }
        });
      }
    });
    return modalRange;
  };

  const validateAndSaveClient = () => {
    const client = state.client;
    const phoneLength = general.removeNonDigit(client.account_phone).length;
    const persList = equipmentList.filter((eq) => eq.name.includes("PERS"));
    const clientEquipment = equipmentList.find(
      (eq) => eq.id === client.equipment_id
    );
    let showModal = false;
    let showVoipModal = false;
    let showAccountRangeModal = false;
    if (!clientEquipment.is_mobile && !clientEquipment.name === "Alexa") {
      if (phoneLength === 11) {
        const checkPhone = validateMobile(
          general.removeNonDigit(client.account_phone, true)
        );
        if (checkPhone) {
          showModal = true;
          setModalPhone(showModal);
        }
      }
    }

    if (client.use_voip) {
      showVoipModal = persList.some((eq) => eq.id === client.equipment_id);
      if (showVoipModal) {
        setModalVoip(true);
      }
    }

    if (isSysAdm) {
      showAccountRangeModal = verifyAccountRange();
      setShowModalRange(showAccountRangeModal);
    }

    if (!showModal && !showVoipModal && !showAccountRangeModal) {
      saveClient();
    }
  };

  const saveClient = () => {
    const client = state.client;
    const text = `${broadcastMessages[lang]["broadcast.client"]} ${
      client.first_name
    } ${client.last_name} ${
      props.match.params.method === "edit"
        ? broadcastMessages[lang]["broadcast.updated"]
        : broadcastMessages[lang]["broadcast.added"]
    }`;
    const serverClient = convertDataToServer();
    console.log("SERVER CLIENT", serverClient);
    if (props.match.params.method === "edit") {
      editClient(serverClient)
        .then(() => {
          broadcast(axios, props.channelId, {
            type: "client",
            method: "edit",
            userId: loggedAttendant.id,
            client: serverClient,
            text,
          });
          props.history.push("/app/clients");
        })
        .catch((err) => {
          processError(err);
        });
    } else {
      createClient(serverClient)
        .then(() => {
          broadcast(axios, props.channelId, {
            type: "client",
            method: "create",
            userId: loggedAttendant.id,
            text,
          });
          props.history.push("/app/clients");
        })
        .catch((err) => {
          console.log(err);
          processError(err);
        });
    }
  };

  const processError = (error) => {
    if (error.response) {
      const response = error.response;
      const errCode = response.status;
      let errMsg = "";
      if (response.data) {
        console.log(response.data);
        const dataKey = Object.keys(response.data)[0];
        switch (dataKey) {
          case "account":
            errMsg = (
              <FormattedMessage
                id="client.error.accountExists"
                defaultMessage="O número de conta já existe"
              />
            );
            break;
          case "outOfRange":
            errMsg = (
              <FormattedMessage
                id="client.error.accountOutOfRange"
                defaultMessage="O número de conta está fora da faixa de contas desta empresa"
              />
            );
            break;
          case "ataExists":
            errMsg = (
              <FormattedMessage
                id="client.error.ataExists"
                defaultMessage="O número deste ATA (VoIP) já está sendo utilizado em outro lugar"
              />
            );
            break;
          case "imei":
          case "client_account":
            const clientAccount = response.data.client_account;
            if (clientAccount) {
              errMsg = (
                <FormattedMessage
                  id="client.form.equipmentErrorAccount"
                  defaultMessage="Este IMEI já está sendo utilizado pela conta {clientAccount}."
                  values={{ clientAccount }}
                />
              );
            } else {
              errMsg = (
                <FormattedMessage
                  id="client.form.equipmentError"
                  defaultMessage="Este IMEI já está sendo utilizado em outro lugar."
                />
              );
            }
            break;
          default:
            if (response.data[0] === "IMEI already exists") {
              errMsg = (
                <FormattedMessage
                  id="client.form.equipmentError"
                  defaultMessage="Este IMEI já está sendo utilizado em outro lugar."
                />
              );
            } else {
              errMsg = response.data[0];
            }
            break;
        }
      }
      setErrorMsg(errMsg);
      setErrorCode(errCode);
      setError(true);
    }
  };

  const closeVoipModal = () => {
    setModalVoip(false);
  };

  const convertDataToServer = () => {
    const client = state.client;
    const secondary = Object.assign(
      {},
      state.secondary,
      { id: state.secondary.id },
      { cpf: general.removeNonDigit(state.secondary.cpf, true) },
      { birthdate: general.birthJStoDjango(state.secondary.birthdate) }
    );
    return Object.assign(
      {},
      client,
      { cpf: general.removeNonDigit(client.cpf, true) },
      {
        account_phone: client.account_phone
          ? general.adjustDigitCountry(
              client.account_phone,
              true,
              client.country
            )
          : client.imei,
      },
      {
        contact_phone: client.contact_phone
          ? general.adjustDigitCountry(
              client.contact_phone,
              true,
              client.country
            )
          : "",
      },
      {
        extra_phone: client.extra_phone
          ? general.adjustDigitCountry(
              client.extra_phone,
              true,
              client.country
            )
          : "",
      },
      { number: !client.number ? 0 : client.number },
      { birthdate: general.birthJStoDjango(client.birthdate) },
      { zip_code: general.removeNonDigit(client.zip_code, true) },
      {
        group_id:
          loggedAttendant.groups.length === 1
            ? client.group_id
            : loggedAttendant.groups.find((grp) => ![1, 2, 3, 4].includes(grp)),
      },
      { secondary: secondary.first_name ? [secondary] : [] }
    );
  };

  const title =
    props.match.params.method === "edit" ? (
      <FormattedMessage
        id="client.form.editHeader"
        defaultMessage="EDITAR USUÁRIO"
      />
    ) : (
      <FormattedMessage
        id="client.form.addHeader"
        defaultMessage="ADICIONAR USUÁRIO"
      />
    );
  const activeSubmit = Object.keys(state.clientValidator).every((el) => {
    if (el === "cpf") {
      return state.clientValidator[el].validate;
    }
    if (el === "account_phone" && state.client.equipment_id === ihelpId) {
      if (state.client.use_voip) {
        return state.client.imei !== "" || state.clientValidator[el].validate;
      } else {
        return state.clientValidator[el].validate;
      }
    }
    if (el === "imei") {
      return state.clientValidator[el].validate;
    }
    switch (state.client.country) {
      case "Brasil":
      case "BR":
        return state.client[el] !== "" && state.clientValidator[el].validate;
      case "GB":
      case "MU":
        if (el === "zip_code") {
          return state.clientValidator[el].validate;
        }
        if (el === "number") {
          return state.clientValidator[el].validate;
        }
        return state.client[el] !== "" && state.clientValidator[el].validate;
      default:
        return state.client[el] !== "" && state.clientValidator[el].validate;
    }
  });

  console.log(state);

  return (
    <div>
      {/* Mensagem de Telefone Celular para PERS */}
      {showModalPhone && (
        <ConfirmRemoveModal
          action={saveClient}
          setModalInfo={setModalInfo}
          message={message}
        />
      )}
      {/* Mensagem de telefone VoIP */}
      {showModalVoip && (
        <ConfirmRemoveModal
          action={saveClient}
          setModalInfo={closeVoipModal}
          message={voipMessage}
        />
      )}
      {showFirstDeleteModal && (
        <ConfirmRemoveModal
          action={confirmDelete1}
          setModalInfo={setDeleteModalInfo}
          message="Aviso, esta opção vai excluir TODOS os atendimentos deste usuário, você tem certeza disso?"
        />
      )}
      {showSecondDeleteModal && (
        <ConfirmRemoveModal
          action={confirmDelete2}
          setModalInfo={setDeleteModalInfo}
          message="Você realmente tem certeza disso? Esta opção vai realmente excluir todas as inserções deste usuário."
        />
      )}
      {showModalRange && (
        <ConfirmRemoveModal
          action={() => {
            setShowModalRange(false);
            saveClient();
          }}
          setModalInfo={() => setShowModalRange(false)}
          message="Este usuário está sendo salvo dentro de uma faixa de uma empresa. Deseja continuar?"
        />
      )}

      {error && (
        <ErrorComponent
          errorCode={errorCode}
          errorMsg={errorMsg}
          handleButton={closeErrorModal}
        />
      )}
      <div className="contactto-middle-content" style={{ overflowX: "hidden" }}>
        <div className="form-title">{title}</div>
        {state.client && (
          <form style={{ marginBottom: "10px" }}>
            <div
              className={`${detail["container-title"]} col-12`}
              style={{ float: "left", marginBottom: "10px" }}
            >
              <FormattedMessage
                id="client.form.personalInfoHeader"
                defaultMessage="INFORMAÇÕES PESSOAIS"
              />
            </div>
            <div className="col-12">
              <div className="col-12 row" style={{ paddingLeft: "0" }}>
                <div
                  className="col-md-6"
                  style={{ paddingLeft: "0", paddingRight: "0" }}
                >
                  <InputContainer
                    name="account"
                    maxLength="4"
                    onChange={setClientState}
                    title={
                      <FormattedMessage
                        id="inputContainer.accountNumber"
                        defaultMessage="Número da conta: "
                      />
                    }
                    validator={state.clientValidator.account}
                    isRequired
                    value={state.client.account}
                  />
                </div>
                <div
                  className="col-md-6"
                  style={{ paddingLeft: "10px", paddingRight: "5px" }}
                >
                  <label className="text-muted" htmlFor="is_test">
                    {" "}
                    <FormattedMessage
                      id="client.form.testOpt"
                      defaultMessage="Usuário de testes: "
                    />
                  </label>
                  <div className="form-control" style={{ border: "0" }}>
                    <CheckboxInput
                      name="is_test"
                      className="form-control"
                      checked={state.client.is_test}
                      onChange={(ev) => setClientState(ev)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="form-row">
                <div className="col-sm-12 col-md-6">
                  <InputContainer
                    name="key_box"
                    onChange={setClientState}
                    title="Keybox: "
                    value={state.client.key_box}
                  />
                </div>
                {loggedAttendant.groups.length === 1 && (
                  <div className="form-group col-sm-12 col-md-6">
                    <label
                      className="text-muted"
                      htmlFor="group_id"
                      style={{ width: "148px", padding: "0 4px" }}
                    >
                      <FormattedMessage
                        id="inputContainer.company"
                        defaultMessage="Empresa: "
                      />
                      <span className="form-required">*</span>
                    </label>

                    <select
                      id="group_id"
                      className={`custom-select ${formStyle["select--enable"]}`}
                      name="group_id"
                      value={state.client.group_id}
                      onChange={setClientState}
                    >
                      <option value={0}>-</option>
                      {companies &&
                        companies
                          .sort((compA, compB) =>
                            compA.name.localeCompare(compB.name)
                          )
                          .map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                    </select>
                  </div>
                )}
              </div>
            </div>
            <div className="col-12">
              <div className="form-row">
                <div className="col-sm-12 col-md-6">
                  <label
                    className="text-muted"
                    htmlFor="equipment_id"
                    style={{ width: "148px", padding: "0 4px" }}
                  >
                    <FormattedMessage
                      id="inputContainer.equipment"
                      defaultMessage="Equipamento: "
                    />
                    <span className="form-required">*</span>
                  </label>
                  <select
                    id="equipment_id"
                    className={`custom-select ${formStyle["select--enable"]}`}
                    name="equipment_id"
                    value={state.client.equipment_id}
                    onChange={setClientState}
                  >
                    {equipmentList.map((equipment) => (
                      <option key={equipment.id} value={equipment.id}>
                        {equipment.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-sm-12 col-md-6">
                  <InputContainer
                    name="imei"
                    maxLength="18"
                    onChange={setClientState}
                    title={
                      state.client.equipment_id === sosAppId &&
                      state.client.use_voip ? (
                        <FormattedMessage
                          id="inputContainer.appNumber"
                          defaultMessage="Número App:"
                        />
                      ) : (
                        "IMEI: "
                      )
                    }
                    value={state.client.imei ? state.client.imei : ""}
                    width={100}
                    validator={state.clientValidator.imei}
                    isRequired={
                      state.client.equipment_id === sosAppId &&
                      state.client.use_voip
                    }
                    disabled={
                      equipmentList.length > 0
                        ? !equipmentList.find(
                            (eq) => eq.id === state.client.equipment_id
                          ).is_mobile
                        : true
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="form-row">
                <div className="col-sm-12 col-md-6">
                  <label
                    className="text-muted"
                    htmlFor="use_voip"
                    style={{ width: "148px", padding: "0 4px" }}
                  >
                    <FormattedMessage
                      id="inputContainer.useVoIP"
                      defaultMessage="Utiliza VoIP? "
                    />
                    <span className="form-required">*</span>
                  </label>
                  <select
                    id="use_voip"
                    className={`custom-select ${formStyle["select--enable"]}`}
                    name="use_voip"
                    value={state.client.use_voip}
                    onChange={setClientState}
                  >
                    <option value={false}>
                      {messages[lang]["general.no"]}
                    </option>
                    {canUseVoipEquipment.some(
                      (equipId) => equipId === state.client.equipment_id
                    ) && (
                      <option value={true} data-testid="use_voip_yes">
                        {messages[lang]["general.yes"]}
                      </option>
                    )}
                  </select>
                </div>
                <div className="col-sm-12 col-md-6">
                  <InputContainer
                    name="account_phone"
                    maxLength={`${state.client.use_voip ? "20" : "16"}`}
                    onChange={setClientState}
                    title={
                      <FormattedMessage
                        id="inputContainer.accountPhone"
                        defaultMessage="Número do Equipamento: "
                      />
                    }
                    validator={state.clientValidator.account_phone}
                    isRequired={
                      state.client.equipment_id !== ihelpId ||
                      (state.client.equipment_id === ihelpId &&
                        !state.client.use_voip)
                    }
                    value={state.client.account_phone}
                    disabled={
                      state.client.equipment_id === ihelpId &&
                      state.client.use_voip
                    }
                    type="tel"
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label
                  className="text-muted text-muted--full"
                  htmlFor="internal_comments"
                >
                  <FormattedMessage
                    id="inputContainer.internalComments"
                    defaultMessage="Comentários internos: "
                  />
                </label>
                <textarea
                  type="textarea"
                  name="internal_comments"
                  className={`form-control ${style["placeholder-text"]}`}
                  placeholder={messages[lang]["client.form.placeholderText"]}
                  value={state.client.internal_comments}
                  maxLength="1024"
                  onChange={setClientState}
                  rows="2"
                  cols="50"
                />
              </div>
            </div>
            <button
              type="button"
              onClick={() => setShowAddress(!showAddress)}
              className={`${detail["container-title"]} detail-btn col-12`}
              style={{ marginBottom: "15px", zIndex: "998" }}
            >
              <FormattedMessage
                id="client.form.addressHeader"
                defaultMessage="ENDEREÇO"
              />
              <Order
                width="25"
                height="25"
                className={`${style["accordion-header"]} ${
                  showAddress && style.rotate
                }`}
              />
            </button>
            <div
              className="col-12"
              style={{ display: showAddress ? "" : "none" }}
            >
              <div
                className="form-row"
                style={{ width: "100%", paddingRight: 0 }}
              >
                <div className="col-sm-6 col-md-3" style={{ paddingRight: 0 }}>
                  <InputContainer
                    name="street"
                    maxLength="50"
                    onChange={setClientState}
                    title={
                      <FormattedMessage
                        id="inputContainer.street"
                        defaultMessage="Logradouro: "
                      />
                    }
                    validator={state.clientValidator.street}
                    value={state.client.street}
                    isRequired
                    width={100}
                  />
                </div>
                <div className="form-group col-sm-6 col-md-3">
                  <InputContainer
                    name="number"
                    maxLength="10"
                    onChange={setClientState}
                    title={
                      <FormattedMessage
                        id="inputContainer.number"
                        defaultMessage="Número: "
                      />
                    }
                    value={state.client.number}
                    validator={state.clientValidator.number}
                    isRequired
                    type="number"
                    className="form-group col-sm-6 col-md-3"
                  />
                </div>
                <div className="form-group col-sm-6 col-md-3">
                  <InputContainer
                    name="complement"
                    maxLength="30"
                    onChange={setClientState}
                    title={
                      <FormattedMessage
                        id="inputContainer.complement"
                        defaultMessage="Complemento: "
                      />
                    }
                    value={state.client.complement}
                  />
                </div>
                <div className="form-group col-sm-6 col-md-3">
                  <InputContainer
                    name="district"
                    maxLength="30"
                    onChange={setClientState}
                    title={
                      <FormattedMessage
                        id="inputContainer.district"
                        defaultMessage="Bairro: "
                      />
                    }
                    validator={state.clientValidator.district}
                    isRequired
                    value={state.client.district}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-sm-6 col-md-3">
                  <InputContainer
                    name="city"
                    maxLength="30"
                    onChange={setClientState}
                    title={
                      <FormattedMessage
                        id="inputContainer.city"
                        defaultMessage="Cidade: "
                      />
                    }
                    validator={state.clientValidator.city}
                    isRequired
                    value={state.client.city}
                  />
                </div>
                <div className="form-group col-sm-6 col-md-3">
                  <label className="text-muted" htmlFor="state">
                    <FormattedMessage
                      id="inputContainer.state"
                      defaultMessage="Estado: "
                    />
                    {(state.client.country === "BR" ||
                      state.client.country === "Brasil") && (
                      <span className="form-required">*</span>
                    )}
                  </label>
                  <SelectInput
                    className={`custom-select ${formStyle["select--enable"]}`}
                    items={
                      state.client.country === "BR" ||
                      state.client.country === "Brasil"
                        ? [
                            "RS",
                            "AC",
                            "AL",
                            "AM",
                            "AP",
                            "BA",
                            "CE",
                            "DF",
                            "ES",
                            "GO",
                            "MA",
                            "MG",
                            "MS",
                            "MT",
                            "PA",
                            "PB",
                            "PE",
                            "PI",
                            "PR",
                            "RJ",
                            "RN",
                            "RO",
                            "RR",
                            "SC",
                            "SP",
                            "SE",
                            "TO",
                          ]
                        : ["-"]
                    }
                    value={state.client.state}
                    onChange={setClientState}
                    name="state"
                  />
                </div>
                {state.client.country === "BR" ||
                state.client.country === "Brasil" ? (
                  <div className="form-group col-sm-6 col-md-3">
                    <InputContainer
                      name="zip_code"
                      maxLength="9"
                      onChange={setClientState}
                      title={
                        <FormattedMessage
                          id="inputContainer.zipCode"
                          defaultMessage="Zip Code: "
                        />
                      }
                      validator={state.clientValidator.zip_code}
                      isRequired
                      value={state.client.zip_code}
                    />
                  </div>
                ) : (
                  <div className="form-group col-sm-6 col-md-3">
                    <InputContainer
                      name="zip_code"
                      maxLength="8"
                      onChange={setClientState}
                      title={
                        <FormattedMessage
                          id="inputContainer.zipCode"
                          defaultMessage="Zip Code: "
                        />
                      }
                      isRequired
                      value={state.client.zip_code}
                    />
                  </div>
                )}
                <div className="form-group col-sm-6 col-md-3">
                  <label className="text-muted" htmlFor="country">
                    <FormattedMessage
                      id="general.country"
                      defaultMessage="País"
                    />
                    :<span className="form-required">*</span>
                  </label>

                  <select
                    id="country"
                    value={state.client.country}
                    onChange={setClientState}
                    className={`custom-select ${formStyle["select--enable"]}`}
                    name="country"
                  >
                    {countries.map((country) => (
                      <option key={country.code} value={country.code}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-sm-12 col-md-6">
                  <InputContainer
                    name="contact_phone"
                    maxLength="15"
                    onChange={setClientState}
                    title={
                      <FormattedMessage
                        id="inputContainer.contactPhone"
                        defaultMessage="Telefone de contato {number}: "
                        values={{ number: 1 }}
                      />
                    }
                    type="tel"
                    value={state.client.contact_phone}
                  />
                </div>
                <div className="form-group col-sm-12 col-md-6">
                  <InputContainer
                    name="extra_phone"
                    maxLength="15"
                    onChange={setClientState}
                    title={
                      <FormattedMessage
                        id="inputContainer.contactPhone"
                        defaultMessage="Telefone de contato {number}: "
                        values={{ number: 2 }}
                      />
                    }
                    type="tel"
                    value={state.client.extra_phone}
                  />
                </div>
              </div>
            </div>
            <button
              type="button"
              onClick={() => setShowPrimaryUser(!showPrimaryUser)}
              className={`${detail["container-title"]} detail-btn col-12`}
              style={{ marginBottom: "15px", zIndex: "998" }}
            >
              <FormattedMessage
                id="client.form.primaryUserInfo"
                defaultMessage="INFORMAÇÕES PESSOAIS USUÁRIO - 1"
              />
              <Order
                width="25"
                height="25"
                className={`${style["accordion-header"]} ${
                  showPrimaryUser && style.rotate
                }`}
              />
            </button>
            <ClientInfoForm
              setClientState={setClientState}
              client={state.client}
              validator={state.clientValidator}
              lang={lang}
              showForm={showPrimaryUser}
            />
            <button
              type="button"
              onClick={() => setShowSecondaryUser(!showSecondaryUser)}
              className={`${detail["container-title"]} detail-btn col-12`}
              style={{ marginBottom: "15px", zIndex: "998" }}
            >
              <FormattedMessage
                id="client.form.secondaryUserInfo"
                defaultMessage="INFORMAÇÕES PESSOAIS USUÁRIO - 2"
              />
              <Order
                width="25"
                height="25"
                className={`${style["accordion-header"]} ${
                  showSecondaryUser && style.rotate
                }`}
              />
            </button>
            <SecondaryActionsForm
              secondary={state.secondary}
              showForm={showSecondaryUser}
              method={props.match.params.method}
              localDispatch={localDispatch}
            />
            <ClientInfoForm
              setClientState={setSecondaryState}
              client={state.secondary}
              validator={state.secondaryValidator}
              lang={lang}
              showForm={showSecondaryUser}
            />
            <div className="col-12 row">
              <button
                type="button"
                className={`${style.btn} ${style["btn-active"]} ${
                  style["btn--color-red"]
                } ${
                  isSysAdm && props.match.params.method === "edit"
                    ? "col-4"
                    : "col-6"
                }`}
                onClick={() => props.history.push("/app/clients")}
              >
                <i
                  className="far fa-window-close"
                  style={{ paddingRight: "1rem", paddingTop: "0.2rem" }}
                />
                <FormattedMessage
                  id="form.button.cancel"
                  defaultMessage="Cancelar"
                />
              </button>
              <button
                type="button"
                disabled={!activeSubmit}
                className={`${style.btn}
                  ${activeSubmit ? style.active : style["btn-disabled"]}
                  ${style["btn--color-green"]}
                  ${
                    isSysAdm && props.match.params.method === "edit"
                      ? "col-4"
                      : "col-6"
                  }`}
                onClick={validateAndSaveClient}
              >
                <i
                  className="far fa-save fa-lg"
                  style={{ paddingRight: "1rem", paddingTop: "0.2rem" }}
                />
                <FormattedMessage
                  id="form.button.save"
                  defaultMessage="Salvar"
                />
                <span className={style["savetip-container"]} style={{ display: activeSubmit ? 'none' : '' }}>
                  <span
                    className={style["savetip-text"]}
                    style={{ marginBottom: "10px" }}
                  >
                    <FormattedMessage
                      id="form.info.save"
                      defaultMessage="Você precisa preencher as seguintes informações para poder salvar:"
                    />
                  </span>
                  <span className={style["savetip-text"]}>
                    <FormattedMessage
                      id="form.info.client_personal"
                      defaultMessage="Informações pessoais - Número da conta, nome, sobrenome, data de nascimento"
                    />
                  </span>
                  <span className={style["savetip-text"]}>
                    <FormattedMessage
                      id="form.info.client_address"
                      defaultMessage="Endereço - Logradouro, número, bairro, cidade, CEP"
                    />
                  </span>
                  {state.client.equipment_id !== ihelpId ? (
                    <span className={style["savetip-text"]}>
                      {state.client.equipment_id === sosAppId &&
                      state.client.use_voip ? (
                        <FormattedMessage
                          id="form.info.client_phone_sosapp"
                          defaultMessage="Telefone e Número do App - Telefone do equipamento E Número do App"
                        />
                      ) : (
                        <FormattedMessage
                          id="form.info.client_phone_normal"
                          defaultMessage="Telefone - Telefone do equipamento"
                        />
                      )}
                    </span>
                  ) : (
                    <span className={style["savetip-text"]}>
                      <FormattedMessage
                        id="form.info.client_phone_ihelp"
                        defaultMessage="Telefone ou IMEI - Telefone do equipamento OU IMEI do equipamento"
                      />
                    </span>
                  )}
                </span>
              </button>
              {isSysAdm && props.match.params.method === "edit" && (
                <button
                  type="button"
                  className={`${style.btn} ${style.active} ${style["btn--color-gray"]} col-4`}
                  onClick={openDeleteModal}
                >
                  <i
                    className="fas fa-user-times"
                    style={{ paddingRight: "1rem", paddingTop: "0.2rem" }}
                  />
                  <FormattedMessage
                    id="form.button.exclude"
                    defaultMessage="Excluir"
                  />
                </button>
              )}
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

ClientForm.propTypes = {
  channelId: PropTypes.string.isRequired,
};

export default ClientForm;
