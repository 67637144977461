import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import TransmitterRow from './TransmitterRow';
import TransmitterMobileRow from './TransmitterMobileRow';
import ConfirmRemoveModal from '../../Modal/ModalConfirmRemove';
import Order from '../../../icons/Order';
import { getDomain, host, getToken } from '../../../BLL/environment';
import { getBrowserMaxWidth, broadcast } from '../../../BLL/general';
import broadcastMessages from '../../../BLL/locale/messagesBroadcast';
import * as globals from '../../../BLL/globals';
import * as transmitterActions from '../../../actions/transmittersActions';

import style from './transmitterPage.module.scss';

import '../../../css/icons.scss';
import '../../../css/middle.scss';
import '../../../css/buttons.scss';

import '../../../css/listPage.scss';

const TransmitterPage = (props) => {
  const browserWidth = getBrowserMaxWidth();
  const {
    transmitters,
    loggedUser,
    lang,
    isOwner,
    isAdmin,
    isManager,
  } = useSelector(state => ({
    transmitters: state.transmitters,
    loggedUser: state.loggedAttendant,
    lang: state.locale.lang,
    isOwner: state.loggedAttendant.groups ? state.loggedAttendant.groups[0] === 4 : false,
    isAdmin: state.loggedAttendant.groups ? state.loggedAttendant.groups[0] === 1 : false,
    isManager: state.loggedAttendant.groups ? state.loggedAttendant.groups[0] === 2 : false,
  }));

  const dispatch = useDispatch();

  const deleteTransmitter = (clientId, transmitterId) => dispatch(transmitterActions.deleteTransmitter(clientId, transmitterId));

  const [transmitter, setTransmitter] = useState(undefined);
  const [client, setClient] = useState(undefined);
  const [order, setOrder] = useState('zone');
  const [transmitterList, setTransmitterList] = useState([]);
  const [iconDetail, setIconDetail] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const loadClient = (clientId) => {
      axios.get(`https://${getDomain()}${host()}/api/clients/${clientId}/`, {
        headers: { Authorization: `Token ${getToken()}` },
      }).then(res => setClient(res.data))
      .catch(error => console.log(error));
    }

    if (props.match.params.clientId) {
      loadClient(props.match.params.clientId);
    }
  }, [props.match.params.clientId]);

  useEffect(() => {
    const loadTransmitters = (clientId, ordering) => dispatch(transmitterActions.loadTransmitters(clientId, ordering));
    const orderedTransmitters = transmitters.filter(tr => tr.ordering === order && tr.clientId === props.match.params.clientId)[0]

    if (orderedTransmitters) {
      setTransmitterList([...orderedTransmitters.data]);
    } else {
      loadTransmitters(props.match.params.clientId, order);
    }
  }, [transmitters, order, props.match.params.clientId, dispatch]);

  const setModalInfo = (showModal, transmitter) => {
    setShowModal(showModal);
    setTransmitter(transmitter);
  };

  const orderByZone = () => {
    const ordering = order === 'zone' ? '-zone' : 'zone';
    setOrder(ordering);
  };

  const orderByDescription = () => {
    const ordering = order === 'description,zone' ? '-description,-zone' : 'description,zone';
    setOrder(ordering);
  };

  const removeTransmitter = () => {
    const clientId = client.id;
    const text = `${broadcastMessages[lang]['broadcast.transmitter']} ${transmitter.description} ${broadcastMessages[lang]['broadcast.zone']} (${transmitter.zone}) ${broadcastMessages[lang]['broadcast.deleted']}`;
    deleteTransmitter(clientId, transmitter.id).then(() => {
      broadcast(axios, props.channelId, {
        type: 'transmitter',
        method: 'delete',
        clientId: clientId,
        userId: loggedUser.id,
        transmitterId: transmitter.id,
        text
      });
      setShowModal(false);
      setTransmitter(undefined);
    });
  };

  const svgZoneRotation = order === '-zone' ? style.rotate : '';
  const svgDescriptionRotation = order === '-description,-zone' ? style.rotate : '';

  return (
    <div className="contactto-middle-content">
      {client &&
        <div className="row">
          <span className={style.title} style={{float: 'left'}}>
            <FormattedMessage id="transmitters.page.header" defaultMessage="TRANSMISSORES DE " /> &nbsp;
            <span className={style.subtitle}>{` ${client.first_name} ${client.last_name}`.toUpperCase()}</span>
          </span>
          {transmitterList.length < globals.maxTransmitters && (isAdmin || isOwner || isManager) &&
            <button
              className={style['btn-add']}
              onClick={() => props.history.push(`/app/transmitters/form/add/${props.match.params.clientId}`)}
              type="button"
            >
              <span className={style['btn--bold']}>
                + <FormattedMessage id="general.add" defaultMessage="ADICIONAR" />
              </span>
            </button>
          }
          <br />
          <div className="col-sm-12 row">
            <span className={style.subtitle}><FormattedMessage id="contact.page.accountNumber" defaultMessage="Número da conta" />: </span>
            <span className={style.subtitle}>
              {client.account}
            </span>
          </div>
        </div>
      }
      {transmitterList &&
        <div className={style.table}>
          {showModal &&
            <ConfirmRemoveModal
              removeAction={removeTransmitter}
              setModalInfo={setModalInfo}
            />
          }
          <div className={style.header}>
            <div style={{ display: 'flex' }}>
              <div className={style.zone}>
                <button
                  className={style['header-column']}
                  onClick={orderByZone}
                  style={{ color: order === 'zone' || order === '-zone' ? '#00b8cb' : '#333' }}
                >
                  <FormattedMessage id="transmitters.zone" defaultMessage="Zona" /><Order width="15" height="10" className={svgZoneRotation} />
                </button>
              </div>
              <div className={style.description}>
                <button
                  className={style['header-column']}
                  onClick={orderByDescription}
                  style={{ color: order === 'description,zone' ||order === '-description,-zone' ? '#00b8cb' : '#333' }}
                >
                  <FormattedMessage id="transmitters.description" defaultMessage="Descrição" /><Order width="15" height="10" className={svgDescriptionRotation} />
                </button>
              </div>
            </div>
            <div className={`${(isAdmin || isOwner) ? style.adminActions : style.otherActions}`}>
              {iconDetail}
            </div>
          </div>
        </div>
      }
      {browserWidth > 650
        ?
          <div className={style['table-body scroll']}>
            {transmitterList?.map(tr =>
              <TransmitterRow
                key={tr.id}
                transmitter={tr}
                isAdmin={isAdmin}
                isOwner={isOwner}
                isManager={isManager}
                setTextIconDetail={setIconDetail}
                setModalInfo={setModalInfo}
              />
            )}
          </div>
        :
          <div className={style['table-body']}>
            {transmitterList?.map(tr => 
              <TransmitterMobileRow
                key={transmitter.id}
                transmitter={transmitter}
                isAdmin={isAdmin}
                isOwner={isOwner}
                isManager={isManager}
                setTextIconDetail={setIconDetail}
                setModalInfo={setModalInfo}
              />
            )}
          </div>
      }
    </div>
  );
};

TransmitterPage.propTypes = {
  channelId: PropTypes.string.isRequired,
  params: PropTypes.shape({
    clientId: PropTypes.string.isRequired,
  }).isRequired,
};

export default TransmitterPage;
