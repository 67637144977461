import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import ClientByCompanyChart from '../../../Charts/ClientByCompanyChart';
import ReportClientByCompanyRow from './ReportClientByCompanyRow';
import Loading from '../../../../icons/Loading';
import Download from '../../../../icons/Download';
import { host, getDomain, getToken } from '../../../../BLL/environment';
import messages from '../../../../BLL/locale/messages';
import { clientsOrdering } from '../../../../BLL/globals';

import style from './reportClientByCompany.module.scss';

const ReportClientByCompany = (props) => {
  const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
  const [report, setReport] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [csvUrl, setCsvUrl] = useState('');
  const [pdfUrl, setPdfUrl] = useState('');
  const [showChart, setShowChart] = useState(false);

  useEffect(() => {
    if (props.match.params.filter) {
      fetchClientByCompanyReport(props.match.params.filter);
    }
  }, [props.match.params.filter]);

  const fetchClientByCompanyReport = (filter) => {
    axios({
      method: 'GET',
      url: `https://${getDomain()}${host()}/api/reports/clients-by-company/?${filter}`,
      headers: { Authorization: `Token ${getToken()}` },
    }).then((resp) => {
      setReport(resp.data.report);
      setCsvUrl(resp.data.csv_url);
      setPdfUrl(resp.data.pdf_url);
      setLoading(false);
    }).catch((err) => {
      console.log(err.response);
      setLoading(false);
    });
  };

  return (
    <div className="contactto-middle-content scroll print">
      <div className={`${style.title} col-sm-12 col-md-6 row`}>
        <FormattedMessage id="reports.reportOf" defaultMessage="RELATÓRIO DE" /> &nbsp;  <span className={style.subtitle}>
        <FormattedMessage id="reports.companies.header" defaultMessage="USUÁRIOS POR EMPRESA" /></span>
      </div>
      <div className="col-sm-12 col-md-6 row">
        <div style={{ margin: '0.5rem' }}>
          <button
            type="button"
            className={`${style.download} no-print`}
            onClick={() => setShowChart(!showChart)}
            style={{ border: 'solid 1px #efefef' }}
            title={`${showChart ? messages[lang]['reports.reportButton'] : messages[lang]['reports.graphicButton']}`}
          >
            <i
              className={`far ${showChart ? 'fa-file-alt' : 'fa-chart-bar'} fa-lg`}
              aria-hidden="true"
              style={{ transition: '.3s ease' }}
            />
          </button>
        </div>
        <div style={{  margin: '0.5rem' }}>
          <a href={csvUrl}>
            <div className={`${style.download} no-print`} title="Download">
              <Download fill="#fff" width="33px" height="33px" />
            </div>
          </a>
        </div>
        <div style={{ marginLeft: '5px', margin: '0.5rem' }}>
          <a href={pdfUrl}>
            <div className={`${style.download} no-print`} title="PDF" style={{ padding: '8px' }}>
              <span style={{ color: "#fff" }}><i className="far fa-file-pdf fa-lg" /></span>
            </div>
          </a>
        </div>
      </div>
      {loading
        ? <Loading text={<FormattedMessage id="attend.list.loading" defaultMessage="Carregando" />} />
        : (report &&
            (!showChart
              ?
                <div className={`${style.table} print`}>
                  <div className={style.header}>
                    <div className={style.name}>
                      <FormattedMessage id="reports.companies.name" defaultMessage="Nome da empresa" />
                    </div>
                    <div className={style['id-header']}>
                      <FormattedMessage id="reports.companies.id" defaultMessage="Identificação" />
                    </div>
                    <div className={style['total-clients']}>
                      <FormattedMessage id="reports.companies.total" defaultMessage="Total de usuários" />
                    </div>
                  </div>
                  <div className={`${style.body} print scroll`}>
                    {report.length > 0 ? report
                      .map(rpt =>
                        <ReportClientByCompanyRow
                          key={rpt.company_id}
                          report={rpt}
                          filterDate={props.match.params.filter}
                        />,
                      )
                      :
                      <div className="list-table-body-row"><FormattedMessage id="reports.emptyReport" defaultMessage="Relatório vazio" /></div>
                    }
                  </div>
                </div>
              :
              <div>{report.length > 0 &&
                <ClientByCompanyChart
                  report={report}
                  width="100%"
                  height={`${35 * report.length < 200 ? 200 : 35 * report.length}px`}
                />}
              </div>
            )
          )
      }
    </div>
  );

};

export default ReportClientByCompany;
