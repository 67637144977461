export const pagination = 50;

export const clientsOrdering = 'account,is_secondary';
export const contactsOrdering = 'priority';
export const transmissorsOrdering = 'zone';
export const transmittersOrdering = 'zone';
export const attendantsOrdering = 'username';
export const attendsOrdering = '-status,-id';
export const attendsClientOrdering = '-status,-id';
export const alarmsOrdering = 'code';
export const nameOrdering = 'name';
export const limit = 'limit=50';

export const maxTransmissors = 16;
export const maxClosedAttends = 6;
export const maxDashboardAttends = 6;

export const screenResolution = () => {
  const res = window.innerWidth;
  if (res < 768) {
    return 'sm';
  } else if (res < 1280) {
    return 'md';
  }
  return 'lg';
};
