import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Chart from 'chart.js/auto'

export default class CustomChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: '',
    };

    this.generateRandomId = this.generateRandomId.bind(this);
    this.makeRandonNumber = this.makeRandonNumber.bind(this);
    this.randomColor = this.randomColor.bind(this);
    this.paintChart = this.paintChart.bind(this);
    this.checkColors = this.checkColors.bind(this);
  }


  UNSAFE_componentWillMount() {
    this.generateRandomId();
  }

  componentDidMount() {
    this.paintChart();
  }


  makeRandonNumber(limit) {
    return Math.floor(Math.random() * limit);
  }

  randomColor() {
    return (
      `rgba(${
      this.makeRandonNumber(255) 
      },${
      this.makeRandonNumber(255) 
      },${
      this.makeRandonNumber(255) 
      },0.2)`
    );
  }

  generateRandomId() {
    const part1 = Math.random() * 100 * 1024 * 1024;
    const part2 = Math.random() * 100;
    const customId = Math.floor(part1 * part2);
    this.setState({ id: customId });
  }

  checkColors() {
    if (this.props.colors) {
      return [...this.props.colors.map(color => color)];
    }
    return [
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
      this.randomColor(),
    ];
  }

  paintChart() {
    const ctx = document.getElementById('attendChart');
    if (this.props.isMultiBar) {
      const chart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.props.labels,
          datasets: this.props.data,
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                // categoryPercentage: 1.0,
                barPercentage: 1.0,
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                stacked: false,
                gridLines: {
                  lineWidth: 1,
                },
              },
            ],
          },
          legend: {
            display: this.props.displayLegend,
          },
        },
      });
    } else {
      const chart = new Chart(ctx, {
        type: this.props.styleChart,
        data: {
          labels: [...this.props.labels],
          datasets: [
            {
              data: [...this.props.data],
              backgroundColor: this.checkColors(),
              borderWidth: 1,
            },
          ],
        },
        options: {
          indexAxis: this.props.isHorizontal ? 'y' : 'x',
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  lineWidth: 1,
                },
              },
            ],
          },
          legend: {
            display: this.props.displayLegend,
          },
          tooltips: {
            enabled: this.props.tooltipEnabled,
          },
        },
      });
    }
  }

  render() {
    return (
      <canvas
        id={'attendChart'}
        style={{
          display: 'block',
          height: this.props.height,
          minWidth: this.props.minWidth,
          minHeight: this.props.minHeight,
        }}
      />
    );
  }
}

CustomChart.propTypes = {
  styleChart: PropTypes.string,
  displayLegend: PropTypes.bool,
  tooltipEnabled: PropTypes.bool,
  height: PropTypes.string,
  minWidth: PropTypes.string,
  minHeight: PropTypes.string,
  isHorizontal: PropTypes.bool,
};

CustomChart.defaultProps = {
  styleChart: 'bar',
  isHorizontal: false,
  displayLegend: false,
  tooltipEnabled: true,
  height: '190px',
  minWidth: '100%',
  minHeight: '0px',
};
