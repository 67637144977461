import React from 'react';
import PropTypes from 'prop-types';
import style from './attendHeader.module.scss';

const CallClock = ({ callState, callTimer, duration }) => {
  return (
    <div className={style['clock-container']}>
      <div className={style['clock-frame']}>
        {callState === 1
          ? <div className={style['clock-digits']}>{callTimer}</div>
          : <div className={style['clock-digits']}>{!duration ? '00:00' : duration}</div>
        }
      </div>
    </div>
  )
};

CallClock.propTypes = {
  callTimer: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  callState: PropTypes.number.isRequired,
};

export default CallClock;
