import React, { useState, useEffect }  from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ToggleButton from '../../../Common/ToggleButton';

import style from './medications.module.scss';

const MedicationComponent = (props) => {
  const [medication, setMedication] = useState('');
  const [ackMedication, setAckMedication] = useState(false);
  const [medOn, setMedOn] = useState(true);

  useEffect(() => {
    setMedication(props.medication);
  }, [props.medication]);

  useEffect(() => {
    setAckMedication(props.ackMedication);
  }, [props.ackMedication]);

  useEffect(() => {
    if (medication === '' || medication === undefined || medication === 'off') {
      setMedOn(false);
    } else {
      setMedOn(true);
    }
  }, [medication]);

  const setHours = (ev) => {
    const hours = ev.target.value;
    if (medication === '' || medication === undefined || medication  === 'on') {
      setMedication(`${hours}:00`);
    } else {
      setMedication(`${hours}:${medication.split(':')[1]}`);
    }
  };

  const setMinutes = (ev) => {
    const minutes = ev.target.value;
    if (medication === '' || medication === undefined || medication === 'off') {
      setMedication(`00:${minutes}`);
    } else {
      setMedication(`${medication.split(':')[0]}:${minutes}`);
    }
  };

  const toggleMedication = () => {
    if (medication === '' || medication === undefined || medication === 'off') {
      setMedication('00:00');
    } else {
      setMedication('off');
    }
  };

  const convertAndSendData = () => {
    const command = [{
      code: `medication${props.openedMedication}`,
      params: `${medication},${ackMedication}`
    },
    {
      code: 'getparams',
      params: '',
    }];
    props.sendParams(command);
  };

  const hours = medication ? parseInt(medication.split(':')[0], 10) : 0;
  const minutes = medication ? parseInt(medication.split(':')[1], 10) : 0;

  return (
    <div className={style.submenuContainer}>
      <span className={`${style.submenuLabel} col-sm-12 col-md-12 col-lg-6`} style={{maxWidth: '45%'}}>
        <FormattedMessage id="client.tracker.medicationTimer" defaultMessage="Horário" />:
      </span>
      <span className="col-sm-12 col-md-12 col-lg-6">
        <select
          id="hours"
          name="hours"
          className={`${style.selectContainer} custom-select`}
          onChange={setHours}
          value={hours}
        >
          {[...Array(24).keys()].map(hour => (
            <option>
              {hour}
            </option>
          ))}
        </select>
        <span style={{ float: 'left', marginTop: '10px' }}>:</span>
        <select
          id="minutes"
          name="minutes"
          className={`${style.selectContainerMin} custom-select`}
          onChange={setMinutes}
          value={minutes}
        >
          {[...Array(60).keys()].map((min) => (
            <option key={min} value={min}>{`${min}`.padStart('2', 0)}</option>
          ))}
        </select>
      </span>
      <div className={`${style.submenuLabel} col-sm-12 col-md-12`}>
        <FormattedMessage id="client.tracker.medicationAck" defaultMessage="Reconhecimento de medicação" />:
        <ToggleButton
          text=""
          isChecked={ackMedication}
          handleChange={() => setAckMedication(!ackMedication)}
          hasPagination={false}
        />
      </div>
      <div className={`${style.submenuLabel} col-sm-12 col-md-12`}>
        <FormattedMessage id="client.tracker.reminderActive" defaultMessage="Lembrete Ativo" />:
        <ToggleButton
          text=""
          isChecked={medOn}
          handleChange={() => toggleMedication()}
          hasPagination={false}
        />
      </div>
      <button
        className={`${style.submenuBtn} col-sm-12 col-md-12 col-lg-12`}
        onClick={convertAndSendData}
      >
        <span><i className="fas fa-notes-medical fa-lg" aria-hidden="true" style={{ paddingRight: '0.5rem', paddingTop: '0.2rem' }} /></span>
        <FormattedMessage id="client.tracker.saveMed" defaultMessage="Salvar Lembrete" />
      </button>

    </div>
  )
}

const IHelpMedications = (props) => {
  const [params, setParams] = useState({});
  const [openedMedication, setOpenedMedication] = useState('');
  const medicationList = [...Array(4).keys()].map(x => x+1);

  useEffect(() => {
    setParams(props.params.param);
  }, [props.params]);
  
  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        {medicationList.map(med =>
          <div key={med} className={`${style.menuContainer} col-sm-12 col-md-6 col-lg-3`} >
            <button className={style.menuOption} onClick={() => setOpenedMedication(med)}>
              <span className={style.menuText}>
                <FormattedMessage id="client.tracker.medication" defaultMessage="Lembrete" /> {med}
                <div className={`${style.menuTriangle} ${openedMedication === med ? style.rotate : ''}`} />
              </span>
            </button>
            {openedMedication === med &&
              <MedicationComponent
                medication={params[`MEDICATION${med}`]}
                ackMedication={params[`MEDICATION${med}ACK`]}
                sendParams={props.sendParams}
                openedMedication={openedMedication}
              />
            }
          </div>
        )}
      </div>
    </div>
  )
};

IHelpMedications.propTypes = {
  sendParams: PropTypes.func.isRequired,
  params: PropTypes.shape({
    param: PropTypes.shape({
      MEDICATION1: PropTypes.string,
      MEDICATION1ACK: PropTypes.bool,
      MEDICATION2: PropTypes.string,
      MEDICATION2ACK: PropTypes.bool,
      MEDICATION3: PropTypes.string,
      MEDICATION3ACK: PropTypes.bool,
      MEDICATION4: PropTypes.string,
      MEDICATION4ACK: PropTypes.bool,
    }),
  }).isRequired,
};

export default IHelpMedications;
