import React from 'react';
import PropTypes from 'prop-types';

const MaleIcon = ({ height, className, width, fill, transform }) =>
  <svg
    className={className}
    width={width}
    height={height}
    fill={fill}
    transform={transform}
    viewBox="0 0 140 140"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M87.2,47.1c-0.9,0-1.7,0-2.6,0c0,0.4,0,0.9,0,1.3c0,27.7,0,55.5,0,83.2c0,3.1-1.9,5.6-4.8,6.3c-3.8,0.9-7.6-2-7.6-5.9
c0-10.7,0-21.3,0-32c0-5.6,0-11.2,0-16.8c0-0.2,0-0.3,0-0.6c-0.8,0-1.6,0-2.4,0c0,0.4,0,0.9,0,1.4c0,15.9,0,31.9,0,47.8
c0,3-1.7,5.2-4.6,6c-3.7,1-7.6-1.7-7.7-5.5c-0.1-5.8-0.1-11.5-0.1-17.3c0-22.3,0-44.6,0-66.9c0-0.3,0-0.6,0-1c-0.9,0-1.8,0-2.7,0
c0,1.8,0,3.5,0,5.2c0,8.4,0,16.8,0,25.1c0,2.5-1.1,4.1-3.2,4.8c-3.1,1.1-6.2-1.1-6.3-4.4c0-12.2-0.1-24.4,0-36.6
c0.1-5.7,4.2-10.8,10-12.1c2.3-0.5,4.8-0.4,7.2-0.5c6.6-0.1,13.1-0.1,19.7,0c1.6,0,3.2,0.1,4.8,0.6c5.9,1.8,9.7,6.8,9.7,13
c0,11.7,0,23.5,0,35.3c0,2.1-0.9,3.7-2.8,4.6c-1.8,0.8-3.6,0.5-5.1-0.8c-1.1-0.9-1.6-2.1-1.6-3.6c0-9.9,0-19.8,0-29.7
C87.2,47.9,87.2,47.5,87.2,47.1z"
    />
    <path
      d="M81.9,14.5c0,6.1-5,11.1-11.1,11c-6.3-0.1-11.1-5.1-11-11.2c0-6.3,5.3-11.1,11.2-11C77.1,3.4,81.9,8.3,81.9,14.5z"
    />
  </svg>;

MaleIcon.defaultProps = {
  height: '24',
  className: '',
  width: '24',
  fill: '#00b8cb',
  transform: 'translate(0,0)',
};

MaleIcon.propTypes = {
  height: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
  transform: PropTypes.string,
};

export default MaleIcon;
