import React from 'react';
import PropTypes from 'prop-types';

class SelectInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      select: this.props.dictionary ? this.props.dictionary(this.props.value) : this.props.value,
      items: [],
      disabled: this.props.disabled ? this.props.disabled : false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.setItems = this.setItems.bind(this);
  }

  componentDidMount() {
    const items = this.setDictionary(this.props.items);
    this.setItems(items);
  }

  UNSAFE_componentWillReceiveProps({ items, value, disabled }) {
    if (value !== this.props.value) {
      this.setState({ select: this.props.dictionary ? this.props.dictionary(value) : value });
    }
    if (items !== this.props.items) {
      this.setState({
        items: this.setDictionary(items),
      });
    }
    if (disabled !== this.props.disabled) {
      this.setState({
        disabled,
      });
    }
  }

  setItems(items) {
    this.setState({
      items,
    });
  }

  setDictionary(arr) {
    if (this.props.dictionary) {
      return arr.map(el => this.props.dictionary(el));
    }

    return arr;
  }

  handleChange(event) {
    let val = event.target.value;
    this.setState({
      select: val,
    });
    if (this.props.dictionary) {
      val = this.props.dictionary(val);
    }
    const ev = { target: { value: val, name: event.target.name, id: event.target.id } };

    this.props.onChange(ev, event.target.value !== '', this.props.index);
  }

  render() {
    const { disabled, items, select } = this.state;
    const { className, name, emptyOption, onBlur, onMouseOver, onMouseOut, style } = this.props;
    return (
      <select
        id={name}
        className={className}
        name={name}
        disabled={disabled}
        value={select}
        onChange={this.handleChange}
        onBlur={onBlur}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        style={style}
      >
        {emptyOption && <option value="" />}
        {items &&
          items.map(item =>
            <option key={item} value={item}>
              {item}
            </option>,
          )}
      </select>
    );
  }
}

SelectInput.defaultProps = {
  index: 0,
  className: '',
  disabled: false,
  dictionary: null,
  emptyOption: false,
  style: {},
  onBlur: () => {},
  onMouseOver: () => {},
  onMouseOut: () => {},
};

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  dictionary: PropTypes.func,
  items: PropTypes.node.isRequired,
  index: PropTypes.number,
  value: PropTypes.node.isRequired,
  emptyOption: PropTypes.bool,
  style: PropTypes.shape({
    color: PropTypes.string,
  }),
  onBlur: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
};

export default SelectInput;
