import React from 'react';
import PropTypes from 'prop-types';

const Audio = ({ height, className, width }) => (
  <svg className={className} width={width} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="#666666" d="M3,9v6h4l5,5V4L7,9H3z M16.5,12c0-1.77-1.02-3.29-2.5-4.03v8.049C15.48,15.29,16.5,13.77,16.5,12z M14,3.23
                       v2.06c2.891,0.86,5,3.54,5,6.71s-2.109,5.85-5,6.71v2.06c4.01-0.91,7-4.49,7-8.77C21,7.72,18.01,4.14,14,3.23z"
    />
    <path fill="none" d="M0,0h24v24H0V0z" />
  </svg>
);

Audio.defaultProps = {
  height: '24',
  className: '',
  width: '24',
};

Audio.propTypes = {
  height: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
};

export default Audio;
