import React from 'react';
import PropTypes from 'prop-types';

const Delete = ({ dataPos, height, className, onClick, width }) => (
  <svg
    onClick={onClick}
    data-pos={dataPos}
    className={className}
    fill="#666" width={width}
    height={height}
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12,14H50v2H12V14Zm28,1H38V12a2,2,0,0,0-2-2H26a2,2,0,0,0-2,2v3H22V12a4,4,0,0,1,4-4H36a4,4,0,0,1,4,4v3ZM25,20l1,32L24,52,23,20Zm12,0L39,20,38,52,36,52Zm-7,0h2V52H30V20ZM41,58H21a4.06,4.06,0,0,1-4-3.88l-3-39,2-.15,3,39A2.1,2.1,0,0,0,21,56H41a2.18,2.18,0,0,0,2-2.12l3-39,2,0.15-3,39A4.16,4.16,0,0,1,41,58Z" />
  </svg>
);

Delete.defaultProps = {
  dataPos: '',
  width: '24',
  height: '24',
  className: '',
  onClick: () => {},
};

Delete.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  dataPos: PropTypes.node,
};

export default Delete;
