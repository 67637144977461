/* eslint-disable import/no-anonymous-default-export */
export default {
  en: {
    firstName: 'First Name',
    lastName: 'Last Name',
    birthdate: 'Birthdate',
    generalInfo: 'Observations',
    gender: 'Gender',
    medications: 'Medication',
    healthPlan: 'Insurance',
    hospital: 'Hospital',
    limitations: 'Limitations',
    removalPlan: 'Removal Plan',
    cpf: 'CPF',
    rg: 'RG'
  },
  pt: {
    firstName: 'Nome',
    lastName: 'Sobrenome',
    birthdate: 'Data de nascimento',
    generalInfo: 'Observações',
    gender: 'Sexo',
    medications: 'Medicações',
    healthPlan: 'Plano de saúde',
    hospital: 'Hospital',
    limitations: 'Limitações',
    removalPlan: 'Plano de Remoção',
    cpf: 'CPF',
    rg: 'RG'
  }
}
