import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import style from './modalReturnCall.module.scss';

const ModalDraggable = (props) => {
  let pos1 = 0;
  let pos2 = 0;
  let pos3 = 0;
  let pos4 = 0;
  const wrapperRef = useRef(null);
  const [posX, setPosX] = useState(props.posX);
  const [posY, setPosY] = useState(props.posY);

  const dragMouseDown = (e) => {
    e.preventDefault();
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    document.onmousemove = elementDrag;
  };

  const elementDrag = (e) => {
    e.preventDefault();
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;

    setPosX(wrapperRef.current.offsetLeft - pos1);
    setPosY(wrapperRef.current.offsetTop - pos2);
  };

  const closeDragElement = () => {
    document.onmouseup = null;
    document.onmousemove = null;
  };
  return (
    <div className={style['return-container']} style={{ left: posX, top: posY }} ref={wrapperRef}>
      <div id="header" className={style['modal-header']} onMouseDown={dragMouseDown}>
        <span  style={{ marginLeft: '12px' }}>{props.headerTitle}</span>
        <button onClick={props.closeModal} className={style['return-close-modal-button']}>
          <i class="fas fa-times" />
        </button>
      </div>
      {props.children}
    </div>
  )
};

ModalDraggable.defaultProps = {
  posX: 570,
  posY: 15,
  isIndependent: false,
  headerTitle: ""
};

ModalDraggable.propTypes = {
  closeModal: PropTypes.func.isRequired,
  posX: PropTypes.number,
  posY: PropTypes.number,
  headerTitle: PropTypes.string,
};

export default ModalDraggable;
