import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow, Circle, MarkerClusterer } from '@react-google-maps/api';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import Loading from '../../icons/Loading';
import config from '../../BLL/config';
import { getDomain, host, getToken } from '../../BLL/environment';
import dotIcon, { firstIcon, lastIcon } from '../../icons/trackerIcons';
// import messages from '../../BLL/locale/messages';
import messagesTracker from '../../BLL/locale/messagesIHelpEventTypes';
import style from './mapComponent.module.scss';
import axios from 'axios';

const MapMarker = ({ position, icon, isFirstOrLastPoint, clientId }) => {
  const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
  const [isInfoWindowOpen, setOpenWindow] = useState(false);
  const [address, setAddress] = useState('');
  let timestamp = '';
  if (position.SENDTIME) {
    const sendtime = position.SENDTIME.length > 10  ? parseInt(position.SENDTIME, 10) : parseInt(position.SENDTIME, 10) * 1000;
    timestamp = moment(sendtime).local().format('YYYY-MM-DD HH:mm:ss');
  } else if (position.DATETIME) {
    timestamp = moment(position.DATETIME).local().format('YYYY-MM-DD HH:mm:ss')
  } else {
    timestamp = moment(position.TIMESTAMP).local().format('YYYY-MM-DD HH:mm:ss');
  }

  const openInfoWindow = () => {
    const point = `lat=${position.LATITUDE}&lon=${position.LONGITUDE}`
    console.log('AAAaa')
    if (!address) {
      axios.get(
        `https://${getDomain()}${host()}/api/clients/${clientId}/geolocation/?${point}`,
        { headers: { Authorization: `Token ${getToken()}` } }
      ).then((res) => {
        setAddress(res.data.address);
      })
    }
    setOpenWindow(true);
  };

  console.log(address);

  return (
    <Marker
      position={{
        lat: parseFloat(position.LATITUDE),
        lng: parseFloat(position.LONGITUDE)
      }}
      onClick={() => openInfoWindow()}
      icon={icon}
    >
      {isInfoWindowOpen &&
        <InfoWindow onCloseClick={() => setOpenWindow(false)}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {position.BATTERY &&
              <span>
                <FormattedMessage id="client.tracker.battery" defaultMessage="Bateria" /> (%): {position.BATTERY}%
              </span>
            }
            <span>
              <FormattedMessage id="client.tracker.sendTime" defaultMessage="Data de envio" />: {timestamp}
            </span>
            {position.ONCHARGER !== undefined &&
              <span>
                <FormattedMessage id="client.tracker.charging" defaultMessage="Carregando" />: {
                  position.ONCHARGER === 'true'
                    ? <FormattedMessage id="general.yes" defaultMessage="Sim" />
                    : <FormattedMessage id="general.no" defaultMessage="Não" />
                }
              </span>
            }
            {(position.CELLSIGNAL || position.GSM_SIGNAL) &&
              <span>
                <FormattedMessage id="client.tracker.cellSignal" defaultMessage="Sinal Celular" />: {position.CELLSIGNAL ? position.CELLSIGNAL : position.GSM_SIGNAL}
              </span>
            }
            <span>
              <FormattedMessage id="client.tracker.eventType" defaultMessage="Tipo de Evento" />: {position.EVENTTYPE ? messagesTracker[lang][position.EVENTTYPE] : 'Coordenadas'}
            </span>
            <span> {!position.ADDRESS ? address : position.ADDRESS} </span>
            {(position.ACCURACY || position.GPS_ACCURACY) &&
              <span>
                <FormattedMessage id="client.tracker.gpsAccuracy" defaultMessage="Precisão do GPS" />: {position.ACCURACY ? position.ACCURACY : position.GPS_ACCURACY}m
              </span>
            }
            {position.FROM_WIFI !== undefined &&
              <span>
                <FormattedMessage id="client.tracker.fromWifi" defaultMessage="Tipo do Sinal" />: {position.FROM_WIFI ? 'Wi-Fi' : 'GPS'}
              </span>
            }
          </div>
        </InfoWindow>
      }
    </Marker>
  )
};

const MapComponent = ({ pointsList, clientId }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: config.googleMapsApiKey
  });
  const [map, setMap] = useState(null);
  const center = useMemo(() => {
    if (pointsList.length > 0) {
      return ({
        lat: parseFloat(pointsList[0].LATITUDE),
        lng: parseFloat(pointsList[0].LONGITUDE),
      })
    }
  }, [pointsList]);

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback((map) => {
    setMap(null);
  }, []);

  if (!isLoaded || !pointsList.length > 0) {
    return <Loading text={<FormattedMessage id="attend.list.loading" defaultMessage="Carregando" />} />
  }

  return (
    <GoogleMap
      mapContainerClassName={style['map-container']}
      center={center}
      zoom={16}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{
        disableDefaultUI: true,
        clickableIcons: false
      }}
    >
      <MarkerClusterer>
        {(clusterer) => 
          pointsList.map((position, index) => {
            if (index === 0 || index === pointsList.length - 1) {
              let icon = firstIcon;
              if (index === 0) {
                icon = firstIcon;
              } else if (index === pointsList.length -1) {
                icon = lastIcon;
              }

              return (
                <div key={index}>
                  <MapMarker position={position} icon={icon} isFirstOrLastPoint clientId={clientId} />
                  <Circle
                    center={{lat: position.LATITUDE, lng: position.LONGITUDE }}
                    radius={position.ACCURACY ? position.ACCURACY : position.GPS_ACCURACY}
                    options={{
                      strokeColor: '#0d7380',
                      fillColor: '#0d7380',
                      clickable: false
                    }}
                  />
                </div>
              )
            }
            return (
              <Marker
                clusterer={clusterer}
                key={index}
                icon={dotIcon}
                position={{
                  lat: position.LATITUDE,
                  lng: position.LONGITUDE
                }}
              />
            )
          })
        }
      </MarkerClusterer>
    </GoogleMap>
  )
}

MapComponent.defaultProps = {
  height: '650px',
  clientId: 0,
};

MapComponent.propTypes = {
  clientId: PropTypes.number,
  pointsList: PropTypes.arrayOf(
    PropTypes.shape({
      gsm_signal: PropTypes.number,
      direction: PropTypes.string,
      battery: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      lot: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      address: PropTypes.string,
    }),
  ).isRequired,
  height: PropTypes.string,
};

export default MapComponent;
