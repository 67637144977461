import * as types from './actionTypes';
import * as errorsActions from './errorsActions';
import { host, getDomain, getToken } from '../BLL/environment';

export function loadBusyUsersSuccess(response) {
  return { type: types.LOAD_BUSY_USERS_SUCCESS, response};
}

export function removeUserFromBusy(userId) {
  return { type: types.REMOVE_BUSY_USER, userId};
}

export function addUserIntoBusy(userId, attendId) {
  return { type: types.ADD_BUSY_USER, userId, attendId };
}

export function loadBusyUsers() {
  const domain = getDomain();
  const token = getToken();

  if (token === 'error') {
    errorsActions.logout();
  }

  return (dispatch, getState, { axios }) => Promise.resolve(
    axios.get(`https://${domain}${host()}/api/attend/list/?status=IN_PROGRESS`, {
      headers: { Authorization: `Token ${token}` },
    }).then(res => dispatch(loadBusyUsersSuccess(res.data)))
    .catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(errorsActions.logout());
        }
      }
    }),
  );
}
