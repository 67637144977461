import React from 'react';
import PropTypes from 'prop-types';

const AlarmCO2Ico = ({ height, svgClass, width, fill, transform }) => (
  <svg className={svgClass} width={width} height={height} fill={fill} transform={transform} viewBox="0 0 140 140" xmlns="http://www.w3.org/2000/svg">
   <g>
<path d="M90.8,71.4c-8.2,0-11.3,7-11.3,14.2c0,6.9,3.1,13.9,11.3,13.9c8.2,0,11.3-7,11.3-13.9C102.1,78.4,99,71.4,90.8,71.4z"/>
<path d="M137,113.8c-4.6-7.8-9-15.6-13.5-23.5C109.2,65.5,94.9,40.8,80.6,16c-2.1-3.7-5.5-5.5-9.8-5.5c-4.2,0-7.6,1.8-9.8,5.5
C50.2,34.8,39.4,53.6,28.5,72.4C20.7,86,12.8,99.6,5,113.3C4,115,3.2,117,3,119c-0.4,7,4.7,12.2,11.7,12.2c18.7,0,37.4,0,56.1,0
c18.6,0,37.3,0,55.9,0c1.5,0,3.2-0.2,4.6-0.7C137.7,128,140.9,120.3,137,113.8z M48.9,107.2c-12.9,0-20.4-9.6-20.4-21.6
c0-12.3,7.6-21.9,20.4-21.9c9.1,0,16.8,5.4,17.9,14.9h-8.8c-0.6-4.1-4.5-7.3-9.1-7.3c-8.2,0-11.3,7-11.3,14.2
c0,6.9,3.1,13.9,11.3,13.9c5.6,0,8.7-3.8,9.4-9.3h8.8C66.2,100.6,59,107.2,48.9,107.2z M90.8,107.2c-12.9,0-20.4-9.6-20.4-21.6
c0-12.3,7.6-21.9,20.4-21.9c12.9,0,20.4,9.6,20.4,21.9C111.2,97.6,103.6,107.2,90.8,107.2z M121.6,120.3h-15.1
c0-4.8,2.9-6.8,6.5-9.2c1.8-1.2,4.3-2.5,4.3-5c0-1.9-1.3-3.2-3.1-3.2c-2.5,0-3.3,2.6-3.3,4.7h-4c-0.1-4.7,2.5-8.3,7.5-8.3
c3.8,0,7.1,2.4,7.1,6.4c0,3.1-1.6,4.8-3.7,6.2c-2,1.4-4.4,2.6-5.9,4.7h9.7V120.3z"/>
</g>
  </svg>
);

AlarmCO2Ico.defaultProps = {
  height: '75',
  svgClass: '',
  width: '75',
  fill: '#D8342B',
  transform: 'translate(0,0)',
};

AlarmCO2Ico.propTypes = {
  height: PropTypes.string,
  svgClass: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
  transform: PropTypes.string,
};

export default AlarmCO2Ico;
