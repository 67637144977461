import { useState, useEffect } from "react";

function usePageReloadDetection() {
  const [reloaded, setReloaded] = useState(true);

  useEffect(() => {
    const navigationEntries = performance.getEntriesByType("navigation");
    if (navigationEntries.length > 0) {
      const navigationEntry = navigationEntries[0];
      if (navigationEntry.type === 'reload') {
        setReloaded(true);
      } else {
        setReloaded(false);
      }
    }
  }, []);

  return reloaded;
}

export default usePageReloadDetection;
