import React from 'react';
import PropTypes from 'prop-types';

const Restore = ({ height, className, width }) => (
  <svg
    className={className}
    width={width}
    height={height}
    fill="#FF9E95"
    viewBox="0 0 65 65"
    enableBackground="new 0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36,8c-12.738-0.021-23.273,9.913-24,22.63L5.71,24.3c-0.359-0.419-0.99-0.468-1.41-0.109
      c-0.419,0.359-0.468,0.991-0.109,1.41c0.034,0.039,0.07,0.076,0.109,0.109l8,8c0.187,0.185,0.438,0.289,0.7,0.29
      c0.266,0.002,0.521-0.104,0.71-0.29l8-8c0.359-0.419,0.311-1.051-0.109-1.41c-0.375-0.32-0.927-0.32-1.301,0l-6.24,6.24
      c0.801-12.124,11.279-21.302,23.403-20.501c12.124,0.801,21.303,11.279,20.502,23.403c-0.803,12.124-11.279,21.302-23.404,20.501
      c-9.013-0.596-16.746-6.641-19.5-15.243c-0.168-0.528-0.732-0.819-1.26-0.65c-0.527,0.169-0.818,0.733-0.65,1.261
      c4.037,12.625,17.545,19.586,30.17,15.549c12.625-4.037,19.586-17.545,15.549-30.169C55.689,14.744,46.442,7.996,36,8z"
    />
  </svg>
);

Restore.defaultProps = {
  height: '24',
  className: '',
  width: '24',
};

Restore.propTypes = {
  height: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
};

export default Restore;
