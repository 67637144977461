import React from 'react';
import PropTypes from 'prop-types';

const HomeDeviceIcon = ({ height, className, width, fill, transform }) =>
  <svg
    className={className}
    width={width}
    height={height}
    fill={fill}
    transform={transform}
    viewBox="0 0 140 140"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="lXSZgC_1_">
      <path
        d="M90.8,45.6c-0.5,0-1-0.2-1.4-0.5L70.3,29.8l-19,15.3c-1,0.8-2.4,0.6-3.1-0.3c-0.8-1-0.6-2.4,0.3-3.1l20.4-16.5 c0.8-0.7,2-0.7,2.8,0l20.5,16.5c1,0.8,1.1,2.2,0.3,3.1C92.1,45.3,91.4,45.6,90.8,45.6z"
      />
      <path
        d="M35.9,71.1C35.9,71.1,35.9,71.1,35.9,71.1l-17.6-0.1c-0.9,0-1.8-0.6-2.1-1.5s0-1.9,0.7-2.5l17.6-14.2 c1-0.8,2.4-0.6,3.1,0.3c0.8,1,0.6,2.4-0.3,3.1L24.6,66.6l11.3,0c1.2,0,2.2,1,2.2,2.2C38.2,70.1,37.2,71.1,35.9,71.1z"
      />
      <path
        d="M122.9,71.4C122.9,71.4,122.9,71.4,122.9,71.4l-17.6-0.1c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2c0,0,0,0,0,0l11.2,0 l-12.6-10.1c-1-0.8-1.1-2.2-0.3-3.1c0.8-1,2.2-1.1,3.1-0.3l17.6,14.1c0.7,0.6,1,1.6,0.7,2.5C124.7,70.8,123.9,71.4,122.9,71.4z"
      />
      <path
        d="M35.9,56.9c-0.7,0-1.3-0.3-1.7-0.8c-0.8-1-0.6-2.4,0.3-3.1l14-11.3c1-0.8,2.4-0.6,3.1,0.3c0.8,1,0.6,2.4-0.3,3.1l-14,11.3 C36.9,56.7,36.4,56.9,35.9,56.9z"
      />
      <path
        d="M105.3,57.3c-0.5,0-1-0.2-1.4-0.5L89.4,45.1c-1-0.8-1.1-2.2-0.3-3.1s2.2-1.1,3.1-0.3l14.6,11.7c1,0.8,1.1,2.2,0.3,3.1 C106.6,57,106,57.3,105.3,57.3z"
      />
      <path
        d="M105.3,118.2H35.9c-1.2,0-2.2-1-2.2-2.2V68.9c0-1.2,1-2.2,2.2-2.2s2.2,1,2.2,2.2v44.8h64.9V69.1c0-1.2,1-2.2,2.2-2.2 s2.2,1,2.2,2.2V116C107.6,117.2,106.6,118.2,105.3,118.2z"
      />
    </g>
  </svg>;

HomeDeviceIcon.defaultProps = {
  height: '24',
  className: '',
  width: '24',
  fill: '#00b8cb',
  transform: 'translate(0,0)',
};

HomeDeviceIcon.propTypes = {
  height: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
  transform: PropTypes.string,
};

export default HomeDeviceIcon;
