import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import InputContainer from '../Common/InputContainer';

import '../../css/formPage.scss';

import detail from '../../css/detail.module.scss';
import style from './modalPasswordChange.module.scss';


export default class ModalPasswordChange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: '',
      newPassword: '',
      newPassword2: '',
      validator: {
        oldPassword: {
          validate: true,
          message: '',
        },
        newPassword: {
          validate: true,
          message: '',
        },
        newPassword2: {
          validate: true,
          message: '',
        },
      },
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.clickedOutside = this.clickedOutside.bind(this);
    this.setForm = this.setForm.bind(this);
    this.setValidator = this.setValidator.bind(this);
    this.changePasswordCallback = this.changePasswordCallback.bind(this);
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.clickedOutside);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.wrongPassword === true) {
      const newValidator = Object.assign({}, this.state.validator);
      newValidator.oldPassword.validate = false;
      newValidator.oldPassword.message = <FormattedMessage id="attendant.error.oldPassIncorrect" defaultMessage="Senha antiga incorreta" />;
      this.setState({ validator: newValidator });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.clickedOutside);
  }

  setForm(ev) {
    const name = ev.target.name;
    const value = ev.target.value;

    this.setFormValue({ name, value });
    this.setValidator({ name, value });
  }

  setFormValue({ name, value }) {
    if (name === 'oldPassword') {
      this.setState({ oldPassword: value });
    }

    if (name === 'newPassword') {
      this.setState({ newPassword: value });
    }

    if (name === 'newPassword2') {
      this.setState({ newPassword2: value });
    }
  }

  setValidator({ name, value }) {
    const newValidator = Object.assign({}, this.state.validator);
    switch (name) {
      case 'oldPassword': {
        // const oldValidate = newValidator[name].validate
        const validate = value.length >= 8;
        newValidator[name].validate = validate;
        if (!validate) {
          newValidator[name].message = <FormattedMessage id="attendant.error.passwordEightCharacters" defaultMessage="Campo deve possuir pelo menos 8 caracteres" />;
        }
        break;
      }
      case 'newPassword': {
        const validate = value.length >= 8;
        newValidator[name].validate = validate;
        if (!validate) {
          newValidator[name].message = <FormattedMessage id="attendant.error.passwordEightCharacters" defaultMessage="Campo deve possuir pelo menos 8 caracteres" />;
        } else {
          const validateConfirm = value === this.state.newPassword2;
          newValidator.newPassword2.validate = validateConfirm;
          if (!validateConfirm) {
            newValidator.newPassword2.message = <FormattedMessage id="attendant.error.passwordMustBeEqual" defaultMessage="Campo deve ser igual ao campo de senha" />;
          }
        }
        break;
      }
      case 'newPassword2': {
        const validate = value === this.state.newPassword;
        newValidator[name].validate = validate;
        if (!validate) {
          newValidator[name].message = <FormattedMessage id="attendant.error.passwordMustBeEqual" defaultMessage="Campo deve ser igual ao campo de senha" />;
        }
        break;
      }
      default:
        break;
    }
    this.setState({ validator: newValidator });
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  clickedOutside(ev) {
    if (this.wrapperRef && !this.wrapperRef.contains(ev.target)) {
      this.props.closeModal();
    }
  }

  changePasswordCallback() {
    const passwords = {
      old_password: this.state.oldPassword,
      new_password: this.state.newPassword,
    };
    this.props.changePassword(passwords);
  }

  render() {
    const { validator, oldPassword, newPassword, newPassword2 } = this.state;
    const saveEnable = Object.values(validator).every(el => el.validate);
    return (
      <div ref={this.setWrapperRef}>
        <div className={style.wrapper}>
          <div className={detail.container}>
            <div className={detail['container-title']}><FormattedMessage id="attendant.form.changePassModalHeader" defaultMessage="Troca de Senha" /></div>
            <div className={detail['container-content']}>
              <InputContainer
                name="oldPassword"
                onChange={this.setForm}
                title={<FormattedMessage id="inputContainer.oldPassword" defaultMessage="Senha Antiga: " />}
                type="password"
                validator={validator.oldPassword}
                value={oldPassword}
              />
              <InputContainer
                name="newPassword"
                onChange={this.setForm}
                title={<FormattedMessage id="inputContainer.newPassword" defaultMessage="Nova senha: " />}
                type="password"
                validator={validator.newPassword}
                value={newPassword}
              />
              <InputContainer
                name="newPassword2"
                onChange={this.setForm}
                title={<FormattedMessage id="inputContainer.confirmPassword" defaultMessage="Confirmar senha: " />}
                type="password"
                validator={validator.newPassword2}
                value={newPassword2}
              />
            </div>
          </div>
          {newPassword2 !== '' && saveEnable ?
            <button
              type="button"
              disabled={false}
              className={'btn btn--form btn--color-green'}
              onClick={this.changePasswordCallback}
              style={{ width: '100%' }}
            >
              <FormattedMessage id="form.button.save" defaultMessage="Salvar" />
            </button>
            : <button
              type="button"
              disabled className={'btn btn--form btn--color-invalid'}
              style={{ width: '100%' }}
            >
              <FormattedMessage id="form.button.save" defaultMessage="Salvar" />
            </button>
          }
        </div>
      </div>
    );
  }
}

ModalPasswordChange.propTypes = {
  changePassword: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  wrongPassword: PropTypes.bool.isRequired,
};
