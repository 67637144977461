import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { userGroup } from '../../../../BLL/dictionary';
import Edit from '../../../../icons/Edit';
import style from '../management.module.scss';

import '../../../../css/icons.scss';
import '../../../../css/middle.scss';
import '../../../../css/buttons.scss';
import '../../../../css/listPage.scss';

class AttendantMobileRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
    this.toggleLogExtra = this.toggleLogExtra.bind(this);
  }

  toggleLogExtra() {
    this.setState(prevState => ({
      expanded: !prevState.expanded,
    }));
  }
  render() {
    const { expanded } = this.state;
    const {
      isAdmin,
      isOwner,
      attendant,
      showModal,
      setTextIconDetail,
      setModalInfo,
      loggedAttendant,
      companies,
    } = this.props;
    const btnStyle = { border: 0, backgroundColor: 'transparent', padding: 0, paddingTop: '3.5px', marginLeft: '10px' };
    const ownerOrAdm = isOwner || isAdmin;
    const managerOption = (attendant.groups[0] === 3
      && loggedAttendant.groups[0] === 2) || attendant.groups[0] === loggedAttendant.groups[0];

    return (
      <div className={style.tableRowWrapper} style={{ height: `${expanded ? 'auto' : ''}`, overflow: 'hidden' }}>
        <div
          className={`${style.tableRow} ${attendant.is_active ? '' : style.removed}`}
          style={{ paddingRight: '20px' }}
        >

          <div className={`${style.column} ${style.username}`}>{attendant.username}</div>
          <div className={`${style.column} ${style.name}`}>{attendant.first_name} {attendant.last_name}</div>
          <div className={`${style.column} ${style.email}`}>{attendant.email}</div>
          <div className={`${style.column} ${style.group}`}>
            {
              attendant.groups.length <= 1
                ? userGroup(attendant.groups[0], 'fromServer')
                : companies.find(company => company.id === attendant.groups[1]).name
            }
          </div>
          <div className={`${style.column} ${style.toggleButtonWrapper}`}>
            {((isAdmin || isOwner)
              || (this.props.isManager && attendant.groups.some(
                group => group === 2 || group === 3))) ?
                  <button className={style.toggleButton} onClick={this.toggleLogExtra}>
                    {!expanded
                      ? '+'
                      : '-'
                    }
                  </button>
                :
                  <div className={style.emptyButton} />
            }
          </div>
        </div>
        <div className={style.buttonMenuWrapper}>
          {(ownerOrAdm || managerOption) &&
            <div>
              <Link
                className={style.actions}
                to={`/app/user/form/edit/${attendant.id}`}
              >
                <Edit className={style.icons} width="28" height="28" />
              </Link>
              {(attendant.groups[0] !== 4 && attendant.id !== loggedAttendant.id) &&
                <button
                  onClick={() => { if (!showModal) { setModalInfo(true, attendant); } }}
                  style={btnStyle}
                >
                  {attendant.is_active === true ?
                    <div className={style.actions}>
                      <i className="far fa-trash-alt" style={{ fontSize: 23, color: '#666', paddingTop: 3 }} />
                      {/* <Delete className={style.icons} width="28" height="28" /> */}
                    </div>
                    :
                    <div className={style.actions}>
                      <i className="fas fa-trash-restore-alt" style={{ fontSize: 23, color: '#666', paddingTop: 3 }} />
                      {/* <Restore className={style.icons} width="28" height="28" /> */}
                    </div>
                  }
                </button>
              }

            </div>
          }
        </div>
      </div>
    );
  }
}

AttendantMobileRow.defaultProps = {
  isAdmin: false,
  isOwner: false,
  isManager: false,
};

AttendantMobileRow.propTypes = {
  attendant: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    groups: PropTypes.arrayOf(
      PropTypes.number,
    ),
    is_active: PropTypes.bool,
    is_staff: PropTypes.bool,
    is_superuser: PropTypes.bool,
    user_info: PropTypes.shape({
      id: PropTypes.number,
      user: PropTypes.number,
      phone: PropTypes.string,
      mobile: PropTypes.string,
      email: PropTypes.string,
      extension_id: PropTypes.number,
    }),
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        priority: PropTypes.number,
        user: PropTypes.number,
        receive_sms: PropTypes.bool,
        receive_email: PropTypes.bool,
      }),
    ),
  }).isRequired,
  isAdmin: PropTypes.bool,
  isOwner: PropTypes.bool,
  isManager: PropTypes.bool,
  showModal: PropTypes.bool.isRequired,
  setTextIconDetail: PropTypes.func.isRequired,
  setModalInfo: PropTypes.func.isRequired,
  loggedAttendant: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    groups: PropTypes.arrayOf(
      PropTypes.number,
    ),
    is_active: PropTypes.bool,
    is_staff: PropTypes.bool,
    is_superuser: PropTypes.bool,
    user_info: PropTypes.shape({
      id: PropTypes.number,
      user: PropTypes.number,
      phone: PropTypes.string,
      mobile: PropTypes.string,
      email: PropTypes.string,
      extension_id: PropTypes.number,
    }),
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        priority: PropTypes.number,
        user: PropTypes.number,
        receive_sms: PropTypes.bool,
        receive_email: PropTypes.bool,
      }),
    ),
  }).isRequired,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      user_set: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          is_active: PropTypes.bool,
          first_name: PropTypes.string,
          last_name: PropTypes.string,
          email: PropTypes.string,
          groups: PropTypes.arrayOf(
            PropTypes.number,
          ),
        }),
      ),
    }),
  ).isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    loggedAttendant: state.loggedAttendant,
    companies: state.groups.filter(grp => ![1, 2, 3, 4].includes(grp.id)),
  };
}

export default connect(mapStateToProps)(AttendantMobileRow);
