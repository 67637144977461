import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Order from '../../../../icons/Order';
import ManagementMenu from '../ManagementMenu';

import RenderTrackerData from './TrackerData';

import '../../../../css/icons.scss';
import '../../../../css/contactto.scss';
import style from './iotDeviceList.module.scss';

const IoTDeviceList = (props) => {
  const [ordering, setOrdering] = useState('imei');
  const [selectedDevice, setSelectedDevice] = useState('igo');

  const svgNameRotation = ordering === '-full_name' ? 'icons-order--rotate' : '';
  const svgIMEIRotation = ordering === '-imei' ? 'icons-order--rotate' : '';
  const svgCompanyRotation = ordering === '-company_name' ? 'icons-order--rotate' : '';
  const svgBatteryRotation = ordering === '-battery' ? 'icons-order--rotate' : '';
  const svgDateRotation = ordering === '-sendtime' ? 'icons-order--rotate' : '';

  return (
    <div className="contactto-middle-content" style={{ overflowX: 'hidden' }}>
      <div className="row">
        <div className="pagetitle"><FormattedMessage id="management.header" defaultMessage="GERENCIAR" /></div>
      </div>
      <ManagementMenu history={props.history} />
      <div className={style.content}>
        <div className={style.iotHeaderText}>
          <FormattedMessage id="management.iot.header" defaultMessage="Dispositivos da Internet das Coisas" />
        </div>
        <div className={style.iotMenu}>
          <button className={`${style.iotButton} ${selectedDevice === 'igo' ? style.active : ''}`} onClick={() => setSelectedDevice('igo')}>IGO</button>
          <button className={`${style.iotButton} ${selectedDevice === 'ihelp' ? style.active : ''}`} onClick={() => setSelectedDevice('ihelp')}>iHelp</button>
          <button className={`${style.iotButton} ${selectedDevice === 'ev07b' ? style.active : ''}`} onClick={() => setSelectedDevice('ev07b')}>EV07B</button>
          <button className={`${style.iotButton} ${selectedDevice === 'climax' ? style.active : ''}`} onClick={() => setSelectedDevice('climax')}>Climax</button>
        </div>
        <div className={style.table}>
          <div className={style.header}>
            <div className={style.headerStatus}> Status </div>
            <div className={style.headerImeiContainer}>
              <button
                className={style.headerBtn}
                style={{ color: ordering.indexOf('imei') !== -1 ? '#00b8cb' : '#333' }}
                onClick={() => setOrdering(ordering === 'imei' ? '-imei' : 'imei')}
              >
                IMEI <Order width="15" height="10" className={svgIMEIRotation} />
              </button>
            </div>
            <div className={style.headerNameContainer}>
              <button
                className={style.headerBtn}
                style={{ color: ordering.indexOf('full_name') !== -1 ? '#00b8cb' : '#333' }}
                onClick={() => setOrdering(ordering === 'full_name' ? '-full_name' : 'full_name')}
              >
                <FormattedMessage id="general.fullName" defaultMessage="Nome" /><Order width="15" height="10" className={svgNameRotation} />
              </button>
            </div>
            <div className={style.headerCompanyContainer}>
              <button
                className={style.headerBtn}
                style={{ color: ordering.indexOf('company') !== -1 ? '#00b8cb' : '#333' }}
                onClick={() => setOrdering(ordering === 'company_name' ? '-company_name' : 'company_name')}
              >
                <FormattedMessage id="client.page.company" defaultMessage="Empresa" /> <Order width="15" height="10" className={svgCompanyRotation} />
              </button>
            </div>
            <div className={style.headerBatteryContainer}>
              <button
                className={style.headerBtn}
                style={{ color: ordering.indexOf('battery') !== -1 ? '#00b8cb' : '#333' }}
                onClick={() => setOrdering(ordering === 'battery' ? '-battery' : 'battery')}
              >
                <FormattedMessage id="client.tracker.battery" defaultMessage="Bateria" /> <Order width="15" height="10" className={svgBatteryRotation} />
              </button>
            </div>
            <div className={style.headerLastEntryContainer}>
              <button
                className={style.headerBtn}
                style={{ color: ordering.indexOf('sendtime') !== -1 ? '#00b8cb' : '#333' }}
                onClick={() => setOrdering(ordering === 'sendtime' ? '-sendtime' : 'sendtime')}
              >
                <FormattedMessage id="management.iot.lastEntry" defaultMessage="Último Registro" /> <Order width="15" height="10" className={svgDateRotation} />
              </button>
            </div>
          </div>
        </div>
        <RenderTrackerData tracker={selectedDevice} order={ordering} />
      </div>
    </div>
  );

};

export default IoTDeviceList;
