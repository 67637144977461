import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Order from '../../../icons/Order';
import style from './clientPage.module.scss';
import '../../../css/icons.scss';

const ClientTableHeader = ({
  isAdmin,
  isOwner,
  ordering,
  orderByAccount,
  orderByName,
  iconDetail,
}) => {
  const svgAccountRotation = ordering === '-account' ? 'icons-order--rotate' : '';
  const svgNameRotation = ordering === '-first_name,-last_name,-account' ? 'icons-order--rotate' : '';
  return (
    <div className={style.header}>
      <div className={`${style.column} ${style.account}`}>
        <button
          id="client-table-header-account"
          className={style['header-column']}
          onClick={() => orderByAccount()}
          style={{ color: ordering === 'account' || ordering === '-account' ? '#00b8cb' : '#333' }}
        >
          <FormattedMessage id="client.page.columnAccount" defaultMessage="Conta" /><Order width="15" height="10" className={svgAccountRotation} />
        </button>
      </div>
      <div className={`${style.column} ${style.name}`}>
        <button
          id="client-table-header-name"
          className={style['header-column']}
          onClick={() => orderByName()}
          style={{ color: ordering === 'first_name,last_name,account' || ordering === '-first_name,-last_name,-account' ? '#00b8cb' : '#333' }}
        >
          <FormattedMessage id="general.fullName" defaultMessage="Nome" /><Order width="15" height="10" className={svgNameRotation} />
        </button>
      </div>
      <div className={`${style.column} ${style.companyHeader}`}>
        <button
          id="client-table-header-company"
          className={style['header-column']}
          onClick={() => { }}
          style={{ color: '#333' }}
        >
          <FormattedMessage id="client.page.company" defaultMessage="Empresa" /><Order width="15" height="10" />
        </button>
      </div>
      <div className={`${style.column} ${style.trackerActions}`}>
        <FormattedMessage id="client.page.columnTracker" defaultMessage="Rastreador" />
      </div>
      <div
        className={`${style.column} ${(isAdmin || isOwner) ? style.adminActions : style.otherActions}`}
        style={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '0.7rem' }}
      >
        {iconDetail}
      </div>
    </div>
  );
};

ClientTableHeader.propTypes = {
  orderByAccount: PropTypes.func.isRequired,
  orderByName: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool.isRequired,
  ordering: PropTypes.string.isRequired,
  iconDetail: PropTypes.string.isRequired,
};

export default ClientTableHeader;
