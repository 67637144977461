import * as types from '../actions/actionTypes';
import initialState from './initialState';

export const lowerPriorityAttendsReducer = (state = initialState.lowerPriorityAttends, action) => {
  switch (action.type) {
    case types.ADD_LOWER_ALARM: {
      return [...state, action.attend];
    }
    case types.REMOVE_LOWER_ALARM: {
      const newState = [
        ...state.slice(0, action.index),
        ...state.slice(action.index + 1)
      ];
      return newState;
    }
    default:
      return state;
  }
};

export default lowerPriorityAttendsReducer;
