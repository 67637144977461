import React from "react";
import PropTypes from 'prop-types';

const AlarmiHelpEmergency = ({ height, svgClass, width, fill, transform }) => (
  <svg 
    className={svgClass}
    width={width}
    height={height}
    fill={fill}
    transform={transform}
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    enableBackground="new 0 0 141.7 141.7"
    viewBox="0 0 141.7 141.7"
  >
    <g>
      <path d="M70.9 4.7C34.3 4.7 4.7 34.3 4.7 70.9c0 36.5 29.6 66.1 66.1 66.1S137 107.4 137 70.9c0-36.6-29.6-66.2-66.1-66.2zm-.1 122.6c-30.6 0-55.5-25.3-55.5-56.5s24.8-56.5 55.5-56.5c30.6 0 55.5 25.3 55.5 56.5s-24.8 56.5-55.5 56.5z"></path>
      <path
        d="M10.5 66H131.1V75.7H10.5z"
        transform="rotate(-45.001 70.85 70.851)"
      ></path>
      <path
        d="M65.9 9.7H75.80000000000001V132H65.9z"
        transform="rotate(-45.001 70.85 70.851)"
      ></path>
    </g>
  </svg>
);

AlarmiHelpEmergency.defaultProps = {
  height: '75',
  svgClass: '',
  width: '75',
  fill: '#F69B38',
  transform: 'translate(0,0)',
};

AlarmiHelpEmergency.propTypes = {
  height: PropTypes.string,
  svgClass: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
  transform: PropTypes.string,
};

export default AlarmiHelpEmergency;
