import * as types from './actionTypes';
import * as errorsActions from './errorsActions';
import { host, getDomain, getToken } from '../BLL/environment';

export function loadGroupsSuccess(groups) {
  return { type: types.LOAD_GROUPS_SUCCESS, groups };
}

export function loadGroups() {
  const domain = getDomain();
  const token = getToken();

  return (dispatch, getState, { axios }) => Promise.resolve(
      axios.get(`https://${domain}${host()}/api/user-groups/`, {
        headers: { Authorization: `Token ${token}` },
      }).then((groups) => {
        const groupsData = groups.data.length > 0 ? groups.data : -1;
        dispatch(loadGroupsSuccess(groupsData));
      }).catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch(errorsActions.logout());
          }
        }
      }),
    );
}

export function saveGroup(group) {
  const domain = getDomain();
  const token = getToken();

  return (dispatch, getState, { axios }) => new Promise((resolve, reject) =>
    axios({
      method: group.id > 0 ? 'put' : 'post',
      responseType: 'json',
      url: `https://${domain}${host()}/api/user-groups/${group.id ? `${group.id}/` : ''}`,
      data: group,
      headers: { Authorization: `Token ${token}` },
    })
    .then(() => resolve(dispatch(loadGroups())))
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          return reject(dispatch(errorsActions.logout()));
        }
        return reject(error.response);
      }
      return reject({});
    }),
  );
}

export function deleteGroup(groupId) {
  const domain = getDomain();
  const token = getToken();

  return (dispatch, getState, { axios }) => new Promise((resolve, reject) => 
    axios({
      method: 'delete',
      responseType: 'json',
      url: `https://${domain}${host()}/api/user-groups/${groupId}/`,
      headers: { Authorization: `Token ${token}` },
    })
    .then(() => resolve(dispatch(loadGroups())))
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          return reject(dispatch(errorsActions.logout()));
        }
        return reject(error.response);
      }
      return reject({});
    }),
  );
}

export function deleteGroupAndUsers(groupId) {
  const domain = getDomain();
  const token = getToken();

  return (dispatch, getState, { axios }) => new Promise((resolve, reject) => 
    axios({
      method: 'delete',
      responseType: 'json',
      url: `https://${domain}${host()}/api/groups/${groupId}/force/`,
      headers: { Authorization: `Token ${token}` },
    })
    .then(() => resolve(dispatch(loadGroups())))
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          return reject(dispatch(errorsActions.logout()));
        }
        return reject(error.response);
      }
      return reject({});
    }),
  );
}
