import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import messagesLogin from '../../BLL/locale/messagesLogin';
import '../../css/login.scss';

const PasswordResetForm = (props) => {
  const { lang } = props;
  const [domain, setDomain] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');

  useEffect(() => {
    if (domain) {
      setDomain(domain);
    }
  }, [props.domain]);

  const handleEmail = (ev) => {
    if (ev.charCode === 13) {
      props.makePasswordReset(domain, email, username);
    }
    setEmail(ev.target.value);
  };

  const handleUsername = (ev) => {
    if (ev.charCode === 13) {
      props.makePasswordReset(domain, email, username);
    }
    setUsername(ev.target.value);
  };

  const handleDomain = (ev) => {
    if (ev.charCode === 13) {
      props.makePasswordReset(domain, email, username);
    }
    setDomain(ev.target.value);
  }

  const makePasswordReset = () => {
    props.makePasswordReset(domain, email, username);
  }

  if (props.loadingResetEmail) {
    return <></>
  }

  return (
    <form
      id="contactto-reset-pass-content-form"
      className="contactto-login-content-container"
      onSubmit={makePasswordReset}
    >
      <img
        className="contactto-login-logo"
        src={props.logo}
        alt="contactto logo"
      />
      <div className="contactto-login-reset-password">
        <FormattedMessage id="login.form.passwordForgotHeader" defaultMessage="ESQUECI MINHA SENHA" />
      </div>
      {props.errorEmailReset &&
        <div className="login-error-title">
          <FormattedMessage id="login.form.resetEmailInvalid" defaultMessage="email inválido" />
        </div>
      }
      {props.resetEmailStatus === 'success' &&
        <div className="login-success-reset">
          <FormattedMessage
            id="login.success.emailSent"
            defaultMessage="Email com uma nova senha foi enviado com sucesso! Verifique sua nova senha em seu email."
          />
        </div>
      }
      <input
        className="login-form-input login-form-input--border"
        type="text"
        onChange={handleDomain}
        placeholder={messagesLogin[lang]['login.form.domain']}
        name="domain"
        value={domain}
      />
      <input
        className={`login-form-input ${props.errorEmailReset && 'login-form-input--error'} ${props.showUsernameInput && 'login-form-input--border'}`}
        type="email"
        onChange={handleEmail}
        placeholder="E-mail"
        value={email}
      />
      {props.showUsernameInput &&
        <>
          <input
            className="login-form-input"
            type="text"
            onChange={handleUsername}
            placeholder={messagesLogin[lang]['login.form.username']}
            name="username"
            value={username}
          />
          <FormattedMessage
            id="login.form.resetPassMultipleUsers"
            defaultMessage="Infelizmente seu e-mail é utilizado em múltiplos atendentes, por favor indique seu nome de login"
          />
        </>
      }
      <button type="button" className="contactto-login-content-container-form-button" disabled={!email} onClick={() => makePasswordReset()}>
        <FormattedMessage id="login.form.passwordResetSend" defaultMessage="enviar" />
      </button>
    </form>
  )
};

PasswordResetForm.defaultProps = {
  domain: ''
};

PasswordResetForm.propTypes = {
  domain: PropTypes.string,
  lang: PropTypes.string.isRequired,
  errorEmailReset: PropTypes.bool.isRequired,
  showUsernameInput: PropTypes.bool.isRequired,
  makePasswordReset: PropTypes.func.isRequired,
};

export default PasswordResetForm;
