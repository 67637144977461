import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import TextInput from '../../../Common/TextInputV2';
import SelectInput from '../../../Common/SelectInput2';
import broadcastMessages from '../../../../BLL/locale/messagesBroadcast';
import { broadcast } from '../../../../BLL/general';
import { actionPriority, alarmNameLang, userActionPriority } from '../../../../BLL/dictionary';
import * as alarmsActions from '../../../../actions/alarmsActions';

import '../../../../css/error.scss';
import '../../../../css/formPage.scss';
import '../../../../css/block.scss';

const AlarmForm = (props) => {
  const loadAlarms = props.loadAlarms;
  const method = props.match.params.method;
  const alarmId = props.match.params.alarmId;
  const [alarm, setAlarmObject] = useState({
    name: '',
    code: '',
    type: 'CID',
    priority: 0,
    is_original: false
  });
  const [alarmList, setAlarmList] = useState([]);
  const [validator, setValidator] = useState({
    name: method === 'edit',
    code: method === 'edit',
    type: true,
    priority: method === 'edit',
    is_original: true,
  });

  useEffect(() => {
    const setAlarm = (alarms) => {
      if (!!alarms && !!alarms.data) {
        const filteredAlarms = method === 'edit'
          ? alarms.data.filter(alarm => alarm.id !== parseInt(alarmId, 10))
          : alarms.data;
        const codesList = filteredAlarms.map(alrm => alrm.code);
        setAlarmList(codesList);
        if (method === 'edit') {
          const newAlarm = alarms.data.find(alrm => alrm.id === parseInt(alarmId, 10));
          setAlarmObject(Object.assign({}, newAlarm));
        }
      }
    };

    if (props.alarms.length === 0) {
      loadAlarms();
    } else {
      setAlarm(props.alarms[0]);
    }
  }, [props.alarms, loadAlarms, alarmId, method]);

  const handleChange = (ev, isValid) => {
    const field = ev.target.name;
    const value = ev.target.value;
    if (field === 'priority') {
      setAlarmObject(Object.assign({}, alarm, { [field]: userActionPriority(value, 'toServer') }));
    } else {
      setAlarmObject(Object.assign({}, alarm, { [field]: value }));
    }
    setValidator(Object.assign({}, validator, { [field]: isValid  }));
  };

  const validateCode = (value) => {
    const arr = value.split('');
    const val = arr.filter(el => /\d/.test(el)).join('');
    if (val !== value) {
      return val;
    } else if (alarmList.indexOf(val) === -1) {
      return true;
    }
    return [false, 'Número de código já existente'];
  };

  const isFormValid = () => {
    return Object.keys(validator).every(elm => validator[elm] === true);
  };

  const saveAlarm = () => {
    const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
    const text = `${broadcastMessages[lang]['broadcast.alarm']} (${alarm.code}) ${alarm.name} ${
      method === 'edit' ? broadcastMessages[lang]['broadcast.updated'] : broadcastMessages[lang]['broadcast.added']}`;
    if (method === 'edit') {
      props.editAlarm(alarm).then(() => {
        broadcast(axios, props.channelId, {
          type: 'alarm',
          method: 'edit',
          userId: props.user.id,
          text,
          alarm
        });
        props.history.push('/app/manage/alarms');
      });
    } else {
      props.createAlarm(alarm).then((res) => {
        broadcast(axios, props.channelId, {
          type: 'alarm',
          method: 'create',
          userId: props.user.id,
          alarm: res.alarm,
          text
        });
        props.history.push('/app/manage/alarms');
      });
    }
  };

  const canSave = isFormValid();
  const title = method === 'edit'
    ? <FormattedMessage id="alarms.editHeader" defaultMessage="EDITAR ALARME" /> 
    : <FormattedMessage id="alarms.addHeader" defaultMessage="ADICIONAR ALARME" />;
  const disabled = alarm.is_original ? 'disabled' : '';

  return (
    <div className="contactto-middle-content" style={{ overflowX: 'hidden' }}>
      <div className="form-container">
        <div className="form-title">{title}</div>
        <form className="container">
          <div className="block col-12">
            <div className="block-title">
              <FormattedMessage id="alarms.informations" defaultMessage="INFORMAÇÕES" />
            </div>
            <div className="form-row col-12">
              <div className="form-group col-sm-12 col-md-4">
                <label className="text-muted" htmlFor="code">
                  <FormattedMessage id="alarms.code" defaultMessage="Código" />
                  <span className="form-required">*</span>
                </label>
                <TextInput
                  required
                  type="text"
                  name="code"
                  maxCharacters={5}
                  disabled={disabled}
                  validate={validateCode}
                  value={alarm.code}
                  onChange={handleChange}
                  onPaste
                  onDrop
                  inputClass="form-input form-input--ok form-control"
                  errorClass="form-input form-input--error form-control"
                  textErrorClass="form-text-error"
                />
                <br />
              </div>
              <div className="form-group col-sm-12 col-md-4">
                <label className="text-muted" htmlFor="name"><FormattedMessage id="alarms.name" defaultMessage="Nome" /> <span className="form-required">*</span> </label>
                <TextInput
                  required
                  type="text"
                  name="name"
                  minCharacters={2}
                  maxCharacters={30}
                  disabled={disabled}
                  value={alarmNameLang(alarm)}
                  onChange={handleChange}
                  inputClass="form-input form-input--ok form-control"
                  errorClass="form-input form-input--error form-control"
                  textErrorClass="form-text-error"
                />
                <br />
              </div>
              <div className="form-group col-sm-12 col-md-4">
                <label className="text-muted" htmlFor="priority"><FormattedMessage id="alarms.priority" defaultMessage="Prioridade" />
                  <span className="form-required">*</span> </label>
                <SelectInput
                  selectClass="form-select form-select-enable custom-select"
                  items={[1, 2, 3, 4]}
                  dictionary={actionPriority}
                  value={alarm.priority}
                  onChange={handleChange}
                  name="priority"
                />
              </div>
            </div>
          </div>
          <div className="col-12 row" style={{ position: 'relative' }}>
            <button type="button" className="btn btn--form btn--color-red col-6" onClick={() => props.history.push('/app/manage/alarms')}>
              <span>
                <i className="far fa-window-close" aria-hidden="true" style={{ paddingRight: '1rem', paddingTop: '0.2rem' }} />
              </span>
              <FormattedMessage id="form.button.cancel" defaultMessage="Cancelar" />
            </button>
            <button
              type="button"
              disabled={!canSave}
              onClick={() => saveAlarm()}
              className={`btn btn--form col-6 ${canSave ? 'btn--color-green' : 'btn--color-invalid'}`}
            >
              <span><i className="far fa-save fa-lg" aria-hidden="true" style={{ paddingRight: '1rem', paddingTop: '0.2rem' }} /></span>
              <FormattedMessage id="form.button.save" defaultMessage="Salvar" />
            </button>
          </div>
        </form>
      </div>
    </div>
  )
};

AlarmForm.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
  }),
  alarms: PropTypes.arrayOf(
    PropTypes.shape({
      ordering: PropTypes.string,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          code: PropTypes.string,
          type: PropTypes.string,
          priority: PropTypes.number,
          is_original: PropTypes.bool,
          is_deleted: PropTypes.bool,
          created_date: PropTypes.string,
          modified_date: PropTypes.string,
          created_by: PropTypes.number,
          modified_by: PropTypes.number,
        }),
      ),
    }),
  ).isRequired,
  channelId: PropTypes.string.isRequired,
  loadAlarms: PropTypes.func.isRequired,
  saveAlarm: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    alarms: state.alarms,
    user: state.loggedAttendant,
    params: ownProps.params,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadAlarms: params => dispatch(alarmsActions.loadAlarms(params)),
    editAlarm: alarm => dispatch(alarmsActions.editAlarm(alarm)),
    createAlarm: alarm => dispatch(alarmsActions.createAlarm(alarm)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AlarmForm);
