import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Select from 'react-select';
import { FormattedMessage } from 'react-intl';
import DateRangePickerWrapper from '../ReportDateWrapper';
import ReportButton from '../ReportButtonComponent';
import { alarmPriorityLang, alarmNameLang } from '../../../../BLL/dictionary';

import style from '../reportComponent.module.scss';

const AlarmComponent = (props) => {
  const [alarms, setAlarms] = useState([]);
  const [alarmsOptions, setAlarmsOptions] = useState([]);
  const [priority, setPriority] = useState('');
  const [alarmCode, setAlarmCode] = useState(',');
  const [url, setUrl] = useState('');
  const [urlDate, setUrlDate] = useState('');
  const [focusedInput, setFocusedInput] = useState(null);
  const [selectFocus, setSelectFocus] = useState(false);
  const [priorityFocus, setPriorityFocus] = useState(false);
  const priorityOptions = [
    { value: '1', label: alarmPriorityLang(1) },
    { value: '2', label: alarmPriorityLang(2) },
    { value: '3', label: alarmPriorityLang(3) },
    { value: '4', label: alarmPriorityLang(4) },
    { value: 'all', label: alarmPriorityLang('all') },
  ];

  useEffect(() => {
    if (props.alarms.length > 0) {
      setAlarms(props.alarms);
    }
  }, [props.alarms]);

  useEffect(() => {
    if (alarms.length > 0) {
      const filteredAlarms = alarms.filter(al => al.code !== '606');
      setAlarmsOptions(filteredAlarms.map(alarm => ({
        value: alarm.code,
        label: `(${alarm.code}) ${alarmNameLang(alarm)}`,
        name: `(${alarm.code}) ${alarmNameLang(alarm)}`,
      })));
    }
  }, [alarms]);

  const setDate = (startDate, endDate) => {
    const firstDate = startDate
      ? `&start=${startDate.format('YYYY-MM-DD')}`
      : '&start=2012-11-28';
    const lastDate = endDate
      ? `&end=${endDate.format('YYYY-MM-DD')}`
      : `&end=${moment().format('YYYY-MM-DD')}`;
    setUrlDate(`${firstDate}${lastDate}`);
  };

  const handleCode = (alrmCode) => {
    setAlarmCode(alrmCode);
    if (alrmCode.length > 0) {
      let urlCode = ''
      alrmCode.forEach(code => {
        urlCode = `${urlCode}${code.value},`;
      });
      setUrl(`/app/reports/list/alarms/code=${urlCode}`);
    } else {
      handlePriority(priority);
    }
  };

  const handlePriority = (prio) => {
    const { value } = prio;
    const newAlarms = value === 'all'
      ? props.alarms
      : props.alarms.filter(a => a.priority === parseInt(value, 10));
    setPriority(prio);
    setAlarms(newAlarms);
    setUrl(`/app/reports/list/alarms/priority=${value}`);
  };

  return (
    <div className={style.wrapper} style={focusedInput ? { height: 'auto' } : {}}>
      <div className={style.code} style={priorityFocus ? { height: 'auto' } : {}}>
        <Select
          value={priority}
          options={priorityOptions}
          name="client"
          onChange={handlePriority}
          simpleValue
          placeholder={<FormattedMessage id="reports.alarms.selectPriority" defaultMessage="Selecione a Prioridade" />}
          onClose={() => setPriorityFocus(false)}
          onOpen={() => setPriorityFocus(true)}
          clearable={false}
        />
      </div>
      {priority !== '' &&
        <div className={style.code} style={selectFocus ? { height: 'auto' } : {}}>
          <Select
            multi
            value={alarmCode}
            options={alarmsOptions}
            name="code"
            onChange={handleCode}
            isMulti
            simpleValue
            placeholder={<FormattedMessage id="reports.alarms.selectAlarmCode" defaultMessage="Selecione o Código de Alarme" />}
            onBlur={() => setSelectFocus(false)}
            onFocus={() => setSelectFocus(true)}
          />
        </div>
      }
      {priority !== '' &&
        <DateRangePickerWrapper
          focusedInput={(focused) => setFocusedInput(focused)}
          setDate={setDate}
          resetDate={urlDate === ''}
          orientation={props.browserWidth > 390 ? 'horizontal' : 'vertical'}
          style={{ marginTop: '10px', width: '100%' }}
        />
      }
      {priority !== '' && !focusedInput &&
        <ReportButton
          themeColor={props.themeColor}
          url={`${url}${urlDate}`}
          text={<FormattedMessage id="reports.generateReport" defaultMessage="Gerar Relatório" />}
        />
      }
    </div>
  );
};

AlarmComponent.propTypes = {
  themeColor: PropTypes.string.isRequired,
  alarms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      code: PropTypes.string,
      type: PropTypes.string,
      priority: PropTypes.number,
      is_original: PropTypes.boolean,
      created_date: PropTypes.string,
      modified_date: PropTypes.string,
      created_by: PropTypes.number,
      modified_by: PropTypes.number,
    }),
  ).isRequired,
  browserWidth: PropTypes.number.isRequired,
};

export default AlarmComponent;
