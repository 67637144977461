import React from 'react';
import PropTypes from 'prop-types';


const VidapointText = ({ height, svgClass, width }) => (
  <svg
    id="Layer_1"
    className={svgClass}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 841.889 595.281"
  >
    <g fill="#75777A">
      <polygon
        points="156.488,322.879 132.342,263.382 123.323,263.472 152.023,332.263 152.205,332.629 160.861,332.629 189.106,264.202 189.379,263.382 180.36,263.382"
      />
      <polygon
        points="217.169,263.291 208.696,263.291 208.786,331.99 208.786,332.538 217.26,332.538 217.169,263.928"
      />
      <path
        d="M292.976,273.404c-3.099-3.098-6.834-5.648-11.023-7.38c-4.191-1.822-8.656-2.733-13.485-2.733l0,0h-23.78 l0.091,68.608v0.639h23.78c4.738,0,9.294-0.911,13.485-2.733c4.19-1.822,7.835-4.282,10.934-7.471 c3.098-3.1,5.647-6.834,7.38-11.025c1.822-4.19,2.733-8.747,2.733-13.484s-0.911-9.294-2.733-13.484 C298.623,280.146,296.073,276.502,292.976,273.404z M292.52,308.301c-1.366,3.28-3.371,6.196-5.831,8.656s-5.376,4.373-8.563,5.831 c-3.28,1.458-6.834,2.096-10.479,2.096h-14.305l-0.091-54.03h14.305l0,0c3.735,0,7.198,0.729,10.479,2.096 c3.278,1.366,6.194,3.371,8.654,5.831s4.373,5.376,5.831,8.564c1.458,3.279,2.096,6.834,2.096,10.479 C294.615,301.558,293.978,305.112,292.52,308.301z"
      />
      <path
        d="M352.928,263.108h-8.838l-26.696,69.247h8.93l6.924-18.496h30.523l7.016,18.496h8.931l-26.605-68.791 L352.928,263.108z M336.254,306.297l12.301-32.71l12.481,32.71H336.254z"
      />
      <path
        d="M437.846,268.666c-1.731-1.729-3.826-3.098-6.104-4.1c-2.369-1.002-4.829-1.458-7.472-1.458l0,0h-24.235 l0.092,68.609v0.638h8.474v-30.797h15.671c2.643,0,5.104-0.547,7.472-1.549c2.369-1.003,4.373-2.369,6.104-4.102 c1.73-1.729,3.098-3.826,4.101-6.104c1.002-2.369,1.457-4.829,1.457-7.472l0,0c0-2.643-0.546-5.103-1.549-7.472 C440.944,272.402,439.576,270.397,437.846,268.666z M434.2,286.798c-0.547,1.366-1.274,2.643-2.187,3.735 c-0.911,1.093-2.097,1.913-3.371,2.46c-1.275,0.639-2.733,0.911-4.374,0.911h-15.671v-23.233h15.671l0,0 c1.55,0,3.007,0.272,4.374,0.91c1.275,0.639,2.46,1.458,3.371,2.46c0.911,1.095,1.64,2.278,2.187,3.735 c0.547,1.367,0.82,2.916,0.82,4.557C434.931,283.883,434.747,285.34,434.2,286.798z"
      />
      <path
        d="M525.406,271.764c-3.28-3.28-7.198-5.923-11.57-7.745c-4.374-1.913-9.203-2.824-14.123-2.824h-0.092 c-5.012,0-9.841,1.002-14.214,2.915c-4.373,1.914-8.291,4.556-11.57,7.836c-3.28,3.28-5.924,7.198-7.745,11.571 c-1.913,4.374-2.824,9.203-2.824,14.214c0,5.013,1.002,9.841,2.916,14.214c1.913,4.374,4.556,8.292,7.835,11.571 c3.28,3.28,7.198,5.923,11.572,7.745c4.373,1.913,9.202,2.824,14.213,2.824l0,0c5.012,0,9.841-1.002,14.214-2.915 c4.374-1.914,8.291-4.557,11.571-7.836c3.279-3.28,5.923-7.198,7.745-11.571c1.913-4.465,2.823-9.203,2.823-14.214 c0-5.013-1.002-9.842-2.915-14.214C531.327,278.871,528.687,275.044,525.406,271.764z M525.406,308.757 c-1.458,3.462-3.463,6.56-6.014,9.202c-2.551,2.642-5.558,4.737-8.929,6.195c-3.372,1.55-7.018,2.277-10.844,2.277l0,0 c-3.826,0-7.472-0.729-10.843-2.277c-3.371-1.458-6.378-3.554-8.838-6.195c-2.552-2.644-4.556-5.74-6.014-9.202 c-1.458-3.463-2.188-7.198-2.277-11.207c0-3.918,0.729-7.745,2.188-11.207c1.458-3.463,3.462-6.562,6.014-9.203 c2.46-2.643,5.467-4.737,8.838-6.195c3.371-1.549,7.016-2.278,10.843-2.278h0.091c3.826,0,7.472,0.729,10.844,2.278 c3.371,1.458,6.378,3.554,8.929,6.195c2.551,2.643,4.556,5.74,6.014,9.203c1.458,3.462,2.188,7.196,2.188,11.207 C527.593,301.468,526.865,305.204,525.406,308.757z"
      />
      <polygon
        points="568.866,262.836 560.394,262.836 560.485,331.536 560.485,332.083 568.958,332.083 568.866,263.472"
      />
      <polygon
        points="648.955,262.744 640.483,262.744 640.573,317.23 604.858,263.108 604.675,262.836 596.475,262.836 596.566,331.444 596.566,332.083 605.038,332.083 605.038,277.595 640.847,331.99 649.047,331.99 648.955,263.382"
      />
      <polygon
        points="718.567,270.216 718.567,262.653 670.003,262.744 670.003,270.306 690.048,270.306 690.14,331.352 690.14,331.899 698.614,331.899 698.522,270.216"
      />
    </g>
  </svg>
);

VidapointText.defaultProps = {
  height: '200',
  svgClass: '',
  width: '300',
};

VidapointText.propTypes = {
  height: PropTypes.string,
  svgClass: PropTypes.string,
  width: PropTypes.string,
};

export default VidapointText;
