import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import style from './modalConfirmRemove.module.scss';

const ModalConfirmRemove = ({ setModalInfo, removeAction }) => {
  const [loading, setLoading] = useState(false);
  const cancelRemove = () => {
    setModalInfo(false, 0);
  };

  const performRemove = () => {
    setLoading(true);
    removeAction();
  };

  return (
    <div className={style.wrapper}>
      <div className={style.text}><FormattedMessage id="modal.confirm.defaultMessage" defaultMessage="Tem certeza que deseja realizar esta ação?" /></div>
      <div className="col-sm-12 row" style={{paddingTop: '1rem' }}>
        <button id="modalBtnCancel" type="button" className="btn btn--confirm-remove-modal btn--color-red col-6" onClick={cancelRemove}>
          <span>
            <i className="far fa-window-close" aria-hidden="true" style={{paddingRight:'1rem',paddingTop: '0.2rem'}}/>
          </span>
          <FormattedMessage id="modal.confirm.no" defaultMessage="NÃO" />
        </button>
        <button disabled={loading} id="modalBtnConfirm" type="button" className="btn btn--confirm-remove-modal btn--color-green col-6" onClick={performRemove}>
          <span>
            <i className="far fa-save fa-lg" aria-hidden="true" style={{paddingRight:'1rem',paddingTop: '0.2rem'}}/>
          </span>
          <FormattedMessage id="modal.confirm.yes" defaultMessage="SIM" />
        </button>
  
      </div>
    </div>
  );
};

ModalConfirmRemove.propTypes = {
  removeAction: PropTypes.func.isRequired,
  setModalInfo: PropTypes.func.isRequired,
};

export default ModalConfirmRemove;
