import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function clientsReducer(state = initialState.clients, action) {
  switch (action.type) {
    case types.LOAD_CLIENTS_SUCCESS: {
      const results = Object.assign({}, action.results, { page: action.page });
      return {
        activePage: action.page,
        activeUrl: action.pageUrl,
        pages: [results],
        ordering: action.ordering,
        filter: action.filter,
        count: action.results.count,
        active: action.results.active,
        reload: false,
      };
    }
    case types.RECEIVE_NEW_CLIENT_PAGE: {
      const results = Object.assign({}, action.newPage, { page: action.pageNumber });
      const newState = Object.assign({}, state, {
        pages: [...state.pages, results],
        activePage: action.pageNumber,
        activeUrl: action.pageUrl,
      },
      {reload: false});
      return newState;
    }
    case types.SET_CLIENT_PAGE: {
      const newState = Object.assign({}, state, {
        activePage: action.pageNumber, activeUrl: action.pageUrl
      },
      {reload: false});
      return newState;
    }
    case types.EDIT_CLIENT: {
      const newState = Object.assign({}, state, {
        pages: state.pages.map(dataList => (
          Object.assign({}, dataList, {
            results: dataList.results.map((client) => {
              if (client.id === action.client.id) {
                return action.client;
              }
              return client;
            }),
          })
        )),
      },
      {reload: true});
      return newState;
    }
    default:
      return state;
  }
}

// export default function clientsReducer(state = initialState.clients, action) {
//   let newState = [];
//   switch (action.type) {
//     case types.LOAD_CLIENTS_SUCCESS:
//       return [
//         {
//           data: action.clients,
//           count: action.count,
//           next: action.next,
//           previous: action.previous,
//           ordering: action.ordering,
//           isFiltered: action.isFiltered,
//           filter: action.filter,
//           page: action.page
//         }
//       ];
//     case types.EDIT_CLIENT:
//       newState = state.map(dataList => {
//         return {
//           data: dataList.data.map(
//             client => client.id === action.client.id ? action.client : client
//           ),
//           count: dataList.count,
//           next: dataList.next,
//           previous: dataList.previous,
//           ordering: dataList.ordering,
//           isFiltered: dataList.isFiltered,
//           filter: dataList.filter,
//           page: dataList.page
//         }
//       });
//       return newState;
//     case types.NEW_PAGE_CLIENT:
//       return [
//         ...state
//       ]
//     default:
//       return state;
//   }
// }

// // /*
// //   Inputs:
// //     state = Estado atual do objeto, caso não seja passado é utilizado o estado default do arquivo(initalstate.browser)
// //     action = Ação aplicada sobre o objeto

// //   hasOrdering:
// //     Validação para identificar se é necessário ordernar os clientes. A tomada de decisão parte do state recebido por padrão

// //   Types:
// //     LOAD_CLIENTS_SUCCESS = Carregamento de clientes realizado com sucesso
// //     RELOAD_CLIENTS_SUCCESS = Recarregamento de clientes realizado com sucesso

// //    clientsReducer:
// //     Retornado array de clientes ordenado ou não mediante hasOrdering.
// // */
// // export default function clientsReducer(state = initialState.clients, action) {
// //   const hasOrdering = state.some(client => client.ordering === action.ordering);
// //   if (action.type === types.LOAD_CLIENTS_SUCCESS) {
// //     const clients = action.cliResponse.results ? action.cliResponse.results : action.cliResponse;
// //     return [
// //       ...state,
// //       {
// //         ...action.cliResponse,
// //         ordering: action.ordering,
// //         data: clients,
// //         isFiltered: action.isFiltered,
// //         filter: action.filter,
// //         page: action.page,
// //       },
// //     ];
// //   }

// //   if (action.type === types.RELOAD_CLIENTS_SUCCESS) {
// //     const clients = action.cliResponse.results ? action.cliResponse.results : action.cliResponse;
// //     return [
// //       {
// //         ...action.cliResponse,
// //         ordering: action.ordering,
// //         data: clients,
// //         isFiltered: action.isFiltered,
// //         filter: action.filter,
// //         page: action.page,
// //       },
// //     ];
// //   }

// //   return state;
// // }
