import React, { useState } from "react";
import PropTypes from "prop-types";
import Order from "../../../icons/Order";
import style from "./accordion.module.scss";

const AccordionSection = ({ label, children, isOpened }) => {
  const [isOpen, setIsOpen] = useState(isOpened);

  const rotateOrder = isOpen ? style.rotate : "";

  return (
    <div className={style.accordionRow}>
      <button
        className="detail-btn"
        style={{ marginTop: "2rem" }}
        onClick={() => setIsOpen(!isOpen)}
      >
        {label}
        <Order
          width="25"
          height="25"
          className={`${style.accordionHeader} ${rotateOrder}`}
        />
      </button>
      {isOpen && <>{children}</>}
    </div>
  );
};

AccordionSection.defaultProps = {
  isOpened: false,
};

AccordionSection.propTypes = {
  label: PropTypes.element.isRequired,
  children: PropTypes.node.isRequired,
  isOpened: PropTypes.bool
};


export default AccordionSection;
