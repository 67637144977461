import * as types from './actionTypes';
import * as errorsActions from './errorsActions';
import * as globals from '../globals/globals';
import { host, getDomain, getToken } from '../BLL/environment';

export function loadAttendantsSuccess(attendants, ordering) {
  return { type: types.LOAD_ATTENDANTS_SUCCESS, attendants, ordering };
}

export function newAttendant(attendant) {
  return { type: types.NEW_ATTENDANT, attendant };
}

export function updatedAttendant(attendant) {
  return { type: types.EDIT_ATTENDANT, attendant };
}


export function loadAttendants(ordering = globals.attendantsOrdering) {
  const domain = getDomain();
  const token = getToken();

  return (dispatch, getState, { axios }) => Promise.resolve(
    axios.get(`https://${domain}${host()}/api/users/?ordering=-is_active,${ordering}`, {
      headers: { Authorization: `Token ${token}` },
    }).then((users) => {
      dispatch(loadAttendantsSuccess(users.data, ordering));
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(errorsActions.logout());
        }
      }
    }),
  );
}

export function createAttendant(attendant) {
  const domain = getDomain();
  const token = getToken();

  return (dispatch, getState, { axios }) => new Promise((resolve, reject) =>
    axios({
      method: 'post',
      responseType: 'json',
      url: `https://${domain}${host()}/api/users/`,
      data: attendant,
      headers: { Authorization: `Token ${token}` },
    })
    .then((res) => resolve(dispatch(newAttendant(res.data))))
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          return reject(dispatch(errorsActions.logout()));
        }
        return reject(error.response);
      }
      return reject({});
    }),
  );
}

export function editAttendant(attendant) {
  const domain = getDomain();
  const token = getToken();

  return (dispatch, getState, { axios }) => new Promise((resolve, reject) =>
    axios({
      method: 'put',
      responseType: 'json',
      url: `https://${domain}${host()}/api/users/${attendant.id}/`,
      data: attendant,
      headers: { Authorization: `Token ${token}` },
    })
    .then(() => resolve(dispatch(updatedAttendant(attendant))))
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          return reject(dispatch(errorsActions.logout()));
        }
        return reject(error.response);
      }
      return reject({});
    }),
  );
}
