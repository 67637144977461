import * as types from '../actions/actionTypes';
import initialState from './initialState';


/*
Inputs:
  state = Estado atual do objeto, caso não seja passado, é utilizado o estado padrão do arquivo
  (initialState.tenantStatus) que é um objeto
  action = Ação aplicada sobre o objeto
Types:
  LOAD_TENANT_STATUS_SUCCESS = Carrega o status do Contactto, se ele é ativo ou trial

tenantStatusReducer = Carrega e retorna um objeto onde mostra qual o estado atual do Contactto
se está ativo ou versão trial
 */
export default function globalConfigurationReducer(state = initialState.tenantStatus, action) {
  if (action.type === types.LOAD_TENANT_STATUS_SUCCESS) {
    return { status: action.status };
  }

  if (action.type === types.RELOAD_TENANT_STATUS_SUCCESS) {
    return { status: action.status };
  }
  return state;
}
