import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const AttendAttendants = ({
  attendants,
  attendantId,
  setContacted,
  setPerson,
 }) => (
   <div className="table-responsive">
     <div className="custom-table-wrapper">
       <div className="table-headers">
         <div className="custom-table-header flex-basis-30">
           <p><FormattedMessage id="client.page.name" defaultMessage="NOME" /></p>
         </div>
         <div className="custom-table-header flex-basis-30">
           <p><FormattedMessage id="attendant.form.phone" defaultMessage="TELEFONE" /></p>
         </div>
         <div className="custom-table-header flex-basis-30">
           <p><FormattedMessage id="contact.page.answered" defaultMessage="ATENDEU" /></p>
         </div>
         <div className="custom-table-header flex-basis-30">
           <p><FormattedMessage id="contact.page.contacted" defaultMessage="CONTATADO" /></p>
         </div>
       </div>
       <div className="table-bar" />
       {attendants.filter(attendant => attendant.is_active).map(attendant => (
         <div className="custom-table-body">
           <div className="custom-table-row">
             <div className="custom-table-cell username-cell flex-basis-30">
               <span className="mobile-column-name">
               <FormattedMessage id="client.page.name" defaultMessage="NOME" />
               </span>
               <div className="center-wrapper">
                 <span className="profile_name">{attendant.first_name} {attendant.last_name}</span>
               </div>
             </div>
             <div className="custom-table-cell flex-basis-30">
               <span className="mobile-column-name">
               <FormattedMessage id="attendant.form.phone" defaultMessage="TELEFONE" />
               </span>
               <div className="center-wrapper">
                 <p>{!attendant.user_info.mobile ? '-' : attendant.user_info.mobile}</p>
               </div>
             </div>
             <div className="custom-table-cell flex-basis-30">
               <span className="mobile-column-name">
               <FormattedMessage id="contact.page.answered" defaultMessage="ATENDEU" />
               </span>
               <div className="center-wrapper">
                 <p>
                   <input
                     type="checkbox"
                     disabled={(attendantId && attendantId !== attendant.id)}
                     checked={
                       (attendantId && attendantId === attendant.id)
                     }
                     name={`${attendant.id}`}
                     onChange={(ev) => {
                       const att = {
                         type: 'attendant',
                         att: attendant,
                         checked: ev.target.checked,
                       };
                       setPerson(att);
                       if (ev.target.checked && !document.querySelector(`input#attendant${attendant.id}`).checked) {
                         document.querySelector(`input#attendant${attendant.id}`).checked = true;
                         setContacted(att);
                       }
                     }}
                   />
                 </p>
               </div>
             </div>

             <div className="custom-table-cell flex-basis-30">
               <span className="mobile-column-name"> 
               <FormattedMessage id="contact.page.contacted" defaultMessage="CONTATADO" />  </span>
               <div className="center-wrapper">
                 <p>
                   <input
                     id={`attendant${attendant.id}`}
                     type="checkbox"
                     name={`${attendant.id}`}
                     onChange={(ev) => {
                       const att = {
                         type: 'attendant',
                         att: attendant,
                         checked: ev.target.checked,
                       };
                       setContacted(att);
                     }}
                   />
                 </p>
               </div>
             </div>
           </div>
         </div>
       ))}
     </div>
   </div>
);

AttendAttendants.propTypes = {
  attendants: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          first_name: PropTypes.string,
          last_name: PropTypes.string,
          is_active: PropTypes.bool,
        }),
      ),
    }),
  ).isRequired,
  attendantId: PropTypes.number.isRequired,
  contactId: PropTypes.number.isRequired,
  setContacted: PropTypes.func.isRequired,
  setPerson: PropTypes.func.isRequired,
};

export default AttendAttendants;
