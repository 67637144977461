import React from 'react';
import PropTypes from 'prop-types';

const TwoWayAudio = ({ className, height, width }) => (
  <svg className={className} fill="#fff" width={width} height={height} viewBox="100 100 841.891 595.279" enableBackground="new 0 0 526.347 520.844" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        fill="transparent" d="M715.855,298.139c0,162.871-132.039,294.91-294.91,294.91S126.034,461.01,126.034,298.139
                        S258.074,3.228,420.945,3.228S715.855,135.268,715.855,298.139z"
      />
      <g>
        <g>
          <path
            fill="#FCFCFC" d="M374.994,469.938v-74.304c-0.137-12.642-10.413-22.858-23.062-23.056h-40.085
                                c-2.979-0.408-5.692-1.955-7.579-4.259c-10.08-10.08-20.971-36.317-20.629-70.181c-0.137-18.674,3.447-37.197,10.754-54.371
                                c6.283-13.87,13.528-20.092,17.848-20.092h40.101c12.71,0,22.987-10.27,23.056-22.911v-0.068v-74.319
                                c-0.145-12.642-10.414-22.843-23.056-23.047l-43.003-0.076c-49.559,0-88.916,85.596-88.916,194.945
                                c-0.341,76.335,19.812,145.985,51.181,177.347c9.603,10.763,23.124,17.128,37.53,17.659l43.004-0.076
                                c6.086-0.061,11.898-2.576,16.166-6.881C372.553,481.913,374.994,476.108,374.994,469.938z M351.802,481.518l-16.771-0.348v0.137
                                l-26.231-0.062c-11.095-0.606-21.509-5.699-28.876-13.93c-29.141-29.148-47.951-95.556-47.951-169.116
                                c0-99.057,35.379-183.235,77.297-183.319h25.762l16.703-0.197c6.283,0,11.361,5.07,11.361,11.361v74.312
                                c0,6.291-5.01,11.292-11.293,11.353c0,0,0,0-0.068,0h-17.113l-0.068,0.281l-22.638,0.136
                                c-10.292-0.068-20.63,9.799-28.481,26.837c-8.041,18.606-12.172,38.751-12.035,58.964c0,32.255,9.943,63.632,24.555,78.154
                                c4.048,4.608,9.801,7.396,15.962,7.715l22.843,0.076v0.137h17.113c6.215,0.137,11.156,5.139,11.346,11.354v74.303
                                C363.293,476.169,358.154,481.368,351.802,481.518z"
          />
        </g>
        <path
          id="telephone_receive_3_" fill="#FCFCFC" d="M591.97,291.44H391.887l42.548-42.533c2.426-2.834,2.092-7.109-0.758-9.526
                            c-2.501-2.16-6.291-2.16-8.791,0l-54.092,54.091c-2.629,2.622-2.629,6.897-0.053,9.519c0,0,0,0,0.053,0.075l54.092,54.099
                            c2.85,2.425,7.094,2.092,9.549-0.758c2.152-2.485,2.152-6.275,0-8.776l-42.548-42.67H591.97c3.714,0,6.761-3.031,6.761-6.76
                            C598.731,294.471,595.684,291.44,591.97,291.44z"
        />
        <path
          id="telephone_receive_2_" fill="#FCFCFC" d="M429.373,305.112h200.084l-42.533,42.518c-2.44,2.834-2.092,7.109,0.742,9.534
                            c2.502,2.168,6.291,2.168,8.777,0l54.113-54.099c2.622-2.621,2.622-6.881,0.061-9.526c0,0,0,0-0.061-0.068l-54.113-54.091
                            c-2.835-2.44-7.095-2.1-9.52,0.743c-2.168,2.494-2.168,6.275,0,8.784l42.533,42.677H429.373c-3.729,0-6.76,3.039-6.76,6.769
                            C422.613,302.05,425.644,305.112,429.373,305.112z"
        />
      </g>
    </g>
  </svg>
);

TwoWayAudio.defaultProps = {
  width: '64',
  height: '64',
  className: '',
};

TwoWayAudio.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
};

export default TwoWayAudio;
