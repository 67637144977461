import React, { useState }  from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import style from './history.module.scss';
import { historyActionType } from '../../../../BLL/dictionary';

export function ClientChangedRow(props) {
  const [expanded, expandInfo] = useState(false);
  const rowClient = props.log.client;
  const nextClient = props.index === props.array.length -1 ? {} : props.array[props.index + 1].client;
  const client = Object.assign({}, rowClient, {
    account: nextClient.account === rowClient.account ? '' : rowClient.account,
    firstName: nextClient.firstName === rowClient.firstName ? '' : rowClient.firstName,
    lastName: nextClient.lastName === rowClient.lastName ? '' : rowClient.lastName,
    isTest: nextClient.isTest === rowClient.isTest ? undefined : rowClient.isTest,
    street: nextClient.street === rowClient.street ? '' : rowClient.street,
    number: nextClient.number === rowClient.number ? '' : rowClient.number,
    district: nextClient.district === rowClient.district ? '' : rowClient.district,
    complement: nextClient.complement === rowClient.complement ? '' : rowClient.complement,
    zipCode: nextClient.zipCode === rowClient.zipCode ? '' : rowClient.zipCode,
    city: nextClient.city === rowClient.city ? '' : rowClient.city,
    state: nextClient.state === rowClient.state ? '' : rowClient.state,
    cpf: nextClient.cpf === rowClient.cpf ? '' : rowClient.cpf,
    rg: nextClient.rg === rowClient.rg ? '' : rowClient.rg,
    birthdate: nextClient.birthdate === rowClient.birthdate ? '' : rowClient.birthdate,
    accountPhone: nextClient.accountPhone === rowClient.accountPhone ? '' : rowClient.accountPhone,
    contactPhone: nextClient.contactPhone === rowClient.contactPhone ? '' : rowClient.contactPhone,
    keybox: nextClient.keyBox === rowClient.keyBox ? '' : rowClient.keyBox,
    healthPlan: nextClient.healthPlan === rowClient.healthPlan ? '' : rowClient.healthPlan,
    generalInfo: nextClient.generalInfo === rowClient.generalInfo ? '' : rowClient.generalInfo,
    hospital: nextClient.hospital === rowClient.hospital ? '' : rowClient.hospital,
    gender: nextClient.gender === rowClient.gender ? '' : rowClient.gender,
    limitations: nextClient.limitations === rowClient.limitations ? '' : rowClient.limitations,
    medications: nextClient.medications === rowClient.medications ? '' : rowClient.medications,
    removalPlan: nextClient.removalPlan === rowClient.removalPlan ? '' : rowClient.removalPlan,
    imei: nextClient.imei === rowClient.imei ? '' : rowClient.imei,
    equipmentId: nextClient.equipmentId === rowClient.equipmentId ? undefined : rowClient.equipmentId,
    groupId: nextClient.groupId === rowClient.groupId ? undefined : rowClient.groupId,
  });
  let actionType = '';
  if (client.isDeleted && !nextClient.isDeleted) {
    actionType = 'Inativação';
  } else if (!client.isDeleted && nextClient.isDeleted) {
    actionType = 'Reativação';
  } else {
    actionType = historyActionType(props.log.actionType)
  }

  console.log(rowClient);
  console.log(nextClient);

  return (
    <div className={style.tableRowWrapper} style={{ height: `${expanded ? 'auto' : '3rem'}`, overflow: 'hidden' }}>
      <div className={style.tableRow}>
        <div className={style.tableBodyColumn}>
          {actionType}
        </div>
        <div className={style.bodyDataColumn}>{moment(props.log.actionDate).format('DD/MM/YYYY HH:mm:ss')}</div>
        <div className={style.bodyChangedByColumn}>
          {props.log.actionUser ? props.log.actionUser.firstName : ''} {props.log.actionUser ? props.log.actionUser.lastName : 'Contactto Vendas'}
        </div>
        <div className={style.bodyToggleButton}>
          <button className={style.toggleButton} onClick={() => expandInfo(!expanded)}>
            { !expanded
              ? <FormattedMessage id="history.expand" defaultMessage="Expandir" />
              : <FormattedMessage id="history.hide" defaultMessage="Ocultar" />
            }
          </button>
        </div>
      </div>
      <div className={style.rowExtraInfoWrapper}>
        {client.account &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>
              <FormattedMessage id="client.detail.accountNumber" defaultMessage="Número de conta" />:
            </span>
            <span className={style.infoText}>{client.account}</span>
          </div>
        }
        {client.isTest !== undefined &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>
              <FormattedMessage id="client.detail.testUser" defaultMessage="Usuário de testes" />:
            </span>
            <span className={style.infoText}>
              {client.isTest
                ? <FormattedMessage id="general.yes" defaultMessage="Sim" />
                : <FormattedMessage id="general.no" defaultMessage="Não" />
              }
            </span>
          </div>
        }
        {client.firstName &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>
              <FormattedMessage id="client.page.name" defaultMessage="Nome" />:
            </span>
            <span className={style.infoText}>{client.firstName}</span>
          </div>
        }
        {client.lastName &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>
              <FormattedMessage id="client.page.lastName" defaultMessage="Sobrenome" />:
            </span>
            <span className={style.infoText}>{client.lastName}</span>
          </div>
        }
        {client.cpf &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>CPF:</span>
            <span className={style.infoText}>{client.cpf}</span>
          </div>
        }
        {client.rg &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>RG:</span>
            <span className={style.infoText}>{client.rg}</span>
          </div>
        }
        {client.birthdate &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>
              <FormattedMessage id="client.detail.birthdate" defaultMessage="Data de Nascimento" />:
            </span>
            <span className={style.infoText}>{client.birthdate}</span>
          </div>
        }
        {client.keybox &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>Keybox:</span>
            <span className={style.infoText}>{client.keybox}</span>
          </div>
        }
        {client.imei &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>IMEI:</span>
            <span className={style.infoText}>{client.imei}</span>
          </div>
        }
        {client.generalInfo &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>
              <FormattedMessage id="client.detail.observations" defaultMessage="Observações" />:
            </span>
            <span className={style.infoText}>{client.generalInfo}</span>
          </div>
        }
        {client.healthPlan &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>
              <FormattedMessage id="client.detail.healthPlan" defaultMessage="Plano de saúde" />:
            </span>
            <span className={style.infoText}>{client.healthPlan}</span>
          </div>
        }
        {client.hospital &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>Hospital:</span>
            <span className={style.infoText}>{client.hospital}</span>
          </div>
        }
        {client.limitations &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>
              <FormattedMessage id="client.detail.limitations" defaultMessage="Limitações" />:
            </span>
            <span className={style.infoText}>{client.limitations}</span>
          </div>
        }
        {client.medications &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>
              <FormattedMessage id="client.detail.medications" defaultMessage="Medicamentos" />:
            </span>
            <span className={style.infoText}>{client.medications}</span>
          </div>
        }
        {client.removalPlan &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>
              <FormattedMessage id="client.detail.removalPlan" defaultMessage="Plano de remoção" />:
            </span>
            <span className={style.infoText}>{client.removalPlan}</span>
          </div>
        }
        {client.street &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>
              <FormattedMessage id="client.detail.street" defaultMessage="Logradouro" />:
            </span>
            <span className={style.infoText}>{client.street}</span>
          </div>
        }
        {client.number &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>
              <FormattedMessage id="client.detail.number" defaultMessage="Número" />:
            </span>
            <span className={style.infoText}>{client.number}</span>
          </div>
        }
        {client.complement &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>
              <FormattedMessage id="client.detail.complement" defaultMessage="Complemento" />:
            </span>
            <span className={style.infoText}>{client.complement}</span>
          </div>
        }
        {client.district &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>
              <FormattedMessage id="client.detail.district" defaultMessage="Bairro" />:
            </span>
            <span className={style.infoText}>{client.district}</span>
          </div>
        }
        {client.zipCode &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>
              <FormattedMessage id="client.detail.zipCode" defaultMessage="CEP" />:
            </span>
            <span className={style.infoText}>{client.zipCode}</span>
          </div>
        }
        {client.city &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>
              <FormattedMessage id="client.detail.city" defaultMessage="Cidade" />:
            </span>
            <span className={style.infoText}>{client.city}</span>
          </div>
        }
        {client.state &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>
              <FormattedMessage id="client.detail.state" defaultMessage="Estado" />:
            </span>
            <span className={style.infoText}>{client.state}</span>
          </div>
        }
        {client.accountPhone &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>
              <FormattedMessage id="client.detail.accountPhone" defaultMessage="Telefone do Equipamento" />:
            </span>
            <span className={style.infoText}>{client.accountPhone}</span>
          </div>
        }
        {client.contactPhone &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>
              <FormattedMessage id="client.detail.contactPhone" defaultMessage="Telefone de Contato" />:
            </span>
            <span className={style.infoText}>{client.contactPhone}</span>
          </div>
        }
        {client.equipmentId && props.equipment.length > 0 &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>
              <FormattedMessage id="client.detail.equipment" defaultMessage="Equipamento" />:
            </span>
            <span className={style.infoText}>{props.equipment.find((eq) => eq.id === client.equipmentId).name}</span>
          </div>
        }
        {client.groupId && props.companies.length > 0 &&
          <div className={style.extraInfoTextWrapper}>
            <span className={style.infoLabel}>
              <FormattedMessage id="client.detail.company" defaultMessage="Empresa" />:
            </span>
            <span className={style.infoText}>{props.companies.find((comp) => comp.id === client.groupId)?.name}</span>
          </div>
        }
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    equipment: state.equipment.length > 0 ? state.equipment[0].data : [],
    companies: state.groups.filter(grp => ![1, 2, 3, 4].includes(grp.id)),
  };
}

export default connect(mapStateToProps)(ClientChangedRow);
