import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { callStates } from '../../../../../BLL/globals';
import ModalDraggable from '../../../../Modal/ModalDraggable';
import style from './attendHeader.module.scss';


const AlexaStateComponent = ({ clientMessages, replyMessage, setReplyMessage, setCallState, sendResponseToAlexa}) => {
  const [showReplyInput, setShowReplyInput] = useState(false);

  return (
    <div className={style['call-alexa-container']}>
      {!showReplyInput ?
        <div className={style['btn-content']}>
          <button className={style['btn-call']} onClick={() => setShowReplyInput(true)}>
            <FormattedMessage id="attend.open.takeCall" defaultMessage="ATENDER CHAMADO" />
          </button>
        </div> :
        <ModalDraggable closeModal={() => setShowReplyInput(false)} headerTitle="RETORNO AO USUÁRIO">
          <div className={style['call-alexa-modal-container']}>
            {clientMessages.map((elm) => (
              <div key={elm.id ? elm.id : elm.message} className={style['call-client-message']}>
                <span className={style['call-message-label']}>Mensagem do usuário: </span>
                <span>
                  {!elm.message 
                    ?  <FormattedMessage id="attend.open.emptyMessage" defaultMessage="MENSAGEM VAZIA" />
                    : elm.message
                  }
                  </span>
              </div>
            ))}
            <div className={style['btn-reply-container']}>
              <textarea
                type="textarea"
                name="reply"
                value={replyMessage}
                onChange={(e) => setReplyMessage(e.target.value)}
                className="form-control"
                maxLength="1024"
                rows="4"
                cols="50"
              />
              <button
                className={style['btn-send']}
                onClick={() => {
                  // setCallState(callStates.CLOSED);
                  sendResponseToAlexa();
                }}
              >
                  <FormattedMessage id="attend.open.replyMessage" defaultMessage="RESPONDER" />
              </button>
            </div>
          </div>
        </ModalDraggable>
      }
    </div>
  );
};

AlexaStateComponent.propTypes = {
  replyMessage: PropTypes.string.isRequired,
  setReplyMessage: PropTypes.func.isRequired,
  setCallState: PropTypes.func.isRequired,
  sendResponseToAlexa: PropTypes.func.isRequired,
  clientMessages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    message: PropTypes.string
  })).isRequired
}

export default AlexaStateComponent;
