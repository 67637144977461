import * as types from './actionTypes';
import * as errorsActions from './errorsActions';
import * as globals from '../globals/globals';
import { host, getDomain, getToken } from '../BLL/environment';

export function loadClientsSuccess(results, ordering, page, filter, pageUrl) {
  return { type: types.LOAD_CLIENTS_SUCCESS, results, ordering, page, filter, pageUrl };
}

export function setClientPage(pageNumber, pageUrl) {
  return { type: types.SET_CLIENT_PAGE, pageNumber, pageUrl };
}

export function newClientPage(pageNumber, newPage, pageUrl) {
  return { type: types.RECEIVE_NEW_CLIENT_PAGE, pageNumber, newPage, pageUrl };
}

export function clientUpdated(client) {
  return { type: types.EDIT_CLIENT, client };
}

export function loadClients(ordering = globals.clientsOrdering, filter = '') {
  const domain = getDomain();
  const token = getToken();

  let url = `https://${domain}${host()}/api/clients-list/?ordering=is_deleted,${ordering}&limit=${globals.pagination}`;
  if (filter) {
    url = `${url}&${filter}`;
  }
  return (dispatch, getState, { axios }) => Promise.resolve(
    axios.get(url, {
      headers: { Authorization: `Token ${token}` },
    }).then((res) => {
      const page = 1;
      dispatch(loadClientsSuccess(
        res.data,
        ordering,
        page,
        filter,
        url,
      ));
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(errorsActions.logout());
        }
      }
    }),
  );
}

export function reloadClients() {
  const domain = getDomain();
  const token = getToken();

  return (dispatch, getState, { axios }) => {
    const { clients } = getState();
    return Promise.resolve(
      axios.get(clients.activeUrl, {
        headers: { Authorization: `Token ${token}` },
      }).then(res => dispatch(loadClientsSuccess(
        res.data,
        clients.ordering,
        clients.activePage,
        clients.filter,
        clients.activeUrl,
      ))),
    );
  };
}

export function changePage(pageNumber, pageUrl) {
  const token = getToken();

  return (dispatch, getState, { axios }) => {
    const { clients } = getState();
    const activePage = clients.pages
      ? clients.pages.find(data => data.page === pageNumber)
      : undefined;
    if (activePage) {
      return Promise.resolve(dispatch(setClientPage(pageNumber, pageUrl)));
    }
    return Promise.resolve(
      axios.get(pageUrl, {
        headers: { Authorization: `Token ${token}` },
      }).then((res) => {
        dispatch(newClientPage(pageNumber, res.data, pageUrl));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch(errorsActions.logout());
          }
        }
      }),
    );
  };
}

export function editClient(client) {
  const domain = getDomain();
  const token = getToken();

  return (dispatch, getState, { axios }) => new Promise((resolve, reject) =>
    axios({
      method: 'put',
      responseType: 'json',
      url: `https://${domain}${host()}/api/clients/${client.id}/`,
      data: client,
      headers: { Authorization: `Token ${token}` },
    })
    .then(() => {
      const { clients } = getState();
      if (clients.pages) {
        resolve(dispatch(reloadClients()));
      } else {
        resolve(dispatch({ client }));
      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          reject(dispatch(errorsActions.logout()));
        }
        return reject(error);
      }
    }),
  );
}

export function createClient(client) {
  const domain = getDomain();
  const token = getToken();

  return (dispatch, getState, { axios }) => new Promise((resolve, reject) =>
    axios({
      method: 'post',
      responseType: 'json',
      url: `https://${domain}${host()}/api/clients/`,
      data: client,
      headers: { Authorization: `Token ${token}` },
    })
    .then(() => {
      const { clients } = getState();
      if (clients.activePage) {
        resolve(dispatch(reloadClients()));
      } else {
        resolve(dispatch(loadClients()));
      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          return reject(dispatch(errorsActions.logout()));
        }
        return reject(error);
      }
      return reject(error);
    }),
  );
}
