import moment from 'moment';

export function getRemainingTime(serverTime, openTimestamp, requestedTime) {
  if (serverTime && openTimestamp) {
    const elapsedTime = moment(serverTime).diff(moment(openTimestamp), 'seconds');
    let timerSeconds = requestedTime * 60;
    timerSeconds -= elapsedTime;
    return timerSeconds;
  }
  return null;
}