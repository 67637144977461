import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
// import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import gql from 'graphql-tag';
import RenderClientHistory from './ClientComponent';
import RenderContactsHistory from './ContactsComponent';
import RenderTransmitterHistory from './TransmittersComponent';
import RenderSecondaryAuditLog from './SecondaryComponent';
import User from '../../../../icons/User';


import style from './history.module.scss';
import '../../../../css/contactto.scss';

const clientName = gql`
  query($token: String!, $id: Int) {
    client(token: $token, id: $id) {
      firstName
      lastName
    }
  }
`;

const RenderClientHeader = clientId => (
  <Query query={clientName} variables={{ token: sessionStorage.activeToken, id: clientId }}>
    {({ loading, error, data }) => {
      if (loading) return (<div className={style.headerWrapper}> Carregando Header </div>);
      if (error) return `Error ${error}`;
      return (
        <div className={style.headerWrapper}>
          <span className={style.headerTitle}>
            <FormattedMessage id="history.header" defaultMessage="VISUALIZAR HISTÓRICO DE" />
          </span>
          <span className={style.headerSubtitle}>  &nbsp;
            {data.client.firstName} {data.client.lastName}
          </span>
        </div>
      );
    }}
  </Query>
);

RenderClientHeader.propTypes = {
  clientId: PropTypes.number.isRequired,
};


const HistoryComponent = (props) => {
  const options = {
    showClient: 1,
    showSecondary: 2,
    showContacts: 3,
    showTransmitters: 4
  }
  const [selectedOption, setSelectedOption] = useState(options.showClient);

  return (
    <div className="contactto-middle-content" style={{ overflow: 'hidden' }}>
      {RenderClientHeader(parseInt(props.match.params.clientId, 10))}
      <div className={style.buttonsContainer}>
        <button
          className={`${style.changeButton} ${selectedOption === options.showClient ? style.buttonSelected : ''}`}
          onClick={() => setSelectedOption(options.showClient)}
        >
          <User
            fill="#fff"
            width="1.1rem"
            height="1.1rem"
            style={{marginTop: '-0.3rem'}} />
          <FormattedMessage id="history.user" defaultMessage="Usuário" />
        </button>
        <button
          className={`${style.changeButton} ${selectedOption === options.showSecondary ? style.buttonSelected : ''}`}
          onClick={() => setSelectedOption(options.showSecondary)}
        >
          <i className="fas fa-user-friends" style={{ fontSize: '0.9rem', color: '#fff', paddingTop: '0.1rem' }} />&nbsp;
          Usuário secundário
        </button>
        <button
          className={`${style.changeButton} ${selectedOption === options.showContacts ? style.buttonSelected : ''}`}
          onClick={() => setSelectedOption(options.showContacts)}
        >
          <i className="fas fa-users" style={{ fontSize: '0.9rem', color: '#fff', paddingTop: '0.1rem' }} />&nbsp;
          <FormattedMessage id="history.contacts" defaultMessage="Contatos" />
        </button>
        <button
          className={`${style.changeButton} ${selectedOption === options.showTransmitters ? style.buttonSelected : ''}`}
          onClick={() => setSelectedOption(options.showTransmitters)}
        >
          <i className="fas fa-broadcast-tower" style={{ fontSize: '0.9rem', color: '#fff', paddingTop: '0.1rem' }} />&nbsp;
          <FormattedMessage id="history.transmitters" defaultMessage="Transmissores" />
        </button>
      </div>
      {selectedOption === options.showClient &&
        <RenderClientHistory clientId={parseInt(props.match.params.clientId, 10)} />
      }
      {selectedOption === options.showSecondary &&
        <RenderSecondaryAuditLog clientId={parseInt(props.match.params.clientId, 10)} />
      }
      {selectedOption === options.showContacts &&
        <RenderContactsHistory clientId={parseInt(props.match.params.clientId, 10)} />
      }
      {selectedOption === options.showTransmitters &&
        <RenderTransmitterHistory clientId={parseInt(props.match.params.clientId, 10)} />
      }
    </div>
  );
}

export default HistoryComponent;
