import * as types from '../actions/actionTypes';
import initialState from './initialState';

/*
  Inputs:
    state = Estado atual do objeto, caso não seja passado é utilizado o estado default do arquivo(initalstate.equipment)
    action = Ação aplicada sobre o objeto

  Types:
    LOAD_EQUIPMENT_SUCCESS = Carregamento de equipamentos realizado com sucesso

   equipmentReducer:
    Retornado array de equipamentos.
*/
export default function equipmentReducer(
  state = initialState.equipment,
  action,
) {
  if (action.type === types.LOAD_EQUIPMENT_SUCCESS) {
    return [
      ...state,
      {
        data: action.equipment,
      },
    ];
  }
  return state;
}
