import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import style from './reminderModal.module.scss';

const ReminderControls = ({ disabled, closeReminder, rescheduleReminder, toggleReturnCallModal, canReceiveCall, openExtensionCaller }) => {
  console.log(disabled)
  return (
    <>
      {canReceiveCall && (
        <div className={style['reminder-button-container']}>
          <button
            className={style['reminder-btn']}
            onClick={toggleReturnCallModal}
            type="button"
          >
            <span className={style['reminder-button-bold']}>
              <FormattedMessage id="attend.open.callUser" defaultMessage="RETORNAR PARA USUÁRIO" />
            </span>
          </button>
        </div>
      )}
      <div className={style['reminder-button-container']} style={{ marginTop: '20px' }}>
        <button
          className={style['reminder-btn']}
          onClick={openExtensionCaller}
          type="button"
        >
          <span className={style['reminder-button-bold']}>
            <FormattedMessage id="attend.open.extensionCall" defaultMessage="CHAMADAS DE EXTENSÃO" />
          </span>
        </button>
      </div>
      <div className={style['reminder-button-container']} style={{ marginTop: '20px' }}>
        {disabled && (
          <span className={style['reminder-help-label']}>
            <FormattedMessage id="reminder.reminderWarning" defaultMessage="Por favor, digite seu relatório antes de concluír." />
          </span>
        )}
        <button
          disabled={disabled}
          className={`${style['reminder-btn']} ${disabled ? style['reminder-btn-disabled'] : ''}`}
          onClick={closeReminder}
          type="button"
        >
          <span className={style['reminder-button-bold']}>
            <FormattedMessage id="reminder.closeBtn" defaultMessage="CONCLUIR" />
          </span>
        </button>
      </div>
      <div className={style['reminder-button-container']}>
        <button
          className={style['reminder-btn']}
          onClick={rescheduleReminder}
          type="button"
        >
          <span className={style['reminder-button-bold']}>
            <FormattedMessage id="reminder.rescheduleBtn" defaultMessage="REPROGRAMAR" />
          </span>
        </button>
      </div>
    </>
  );
};

ReminderControls.propTypes = {
  closeReminder: PropTypes.func.isRequired,
  rescheduleReminder: PropTypes.func.isRequired,
  toggleReturnCallModal: PropTypes.func.isRequired,
  openExtensionCaller: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  canReceiveCall: PropTypes.bool.isRequired,
}

export default ReminderControls;
