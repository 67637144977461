import moment from 'moment';

export default class CallStopwatch {
  constructor(start) {
    this.start = start;
  }

  count() {
    const now = Date.now();
    const counter = now - this.start > 0 ? now - this.start : 0;
    return moment(counter).format('mm:ss');
  }

}
