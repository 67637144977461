import React from 'react';
import PropTypes from 'prop-types';

const FemaleIcon = ({ height, className, width, fill, transform }) =>
  <svg
    className={className}
    width={width}
    height={height}
    fill={fill}
    transform={transform}
    viewBox="0 0 140 140"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M95.1,93.9c-2.2,0-4.3,0-6.4,0c-2,0-4.1,0-6.2,0c0,0.3-0.1,0.7-0.1,1c0,12.6,0,25.2,0,37.8c0,0.9-0.2,1.9-0.6,2.8
  c-1.1,2.1-3.4,3.1-5.6,2.5c-2.3-0.6-3.8-2.5-3.8-4.9c0-12.6,0-25.3,0-37.9c0-0.3,0-0.7,0-1.1c-1,0-2,0-3,0c0,0.4,0,0.9,0,1.3
  c0,12.4,0,24.8,0,37.2c0,1.8-0.4,3.3-1.9,4.5c-1.7,1.3-3.5,1.5-5.4,0.6c-1.9-0.9-2.8-2.5-2.8-4.5c-0.1-2.3,0-4.6,0-6.8
  c0-10.4,0-20.9,0-31.3c0-0.3,0-0.6,0-1c-4.2,0-8.3,0-12.6,0c4.3-17.2,8.6-34.2,12.9-51.4c-1,0-1.8,0-2.7,0c-0.6,2.1-1.2,4.3-1.7,6.5
  c-1.1,4.2-2.3,8.5-3.4,12.7c-0.8,3.1-1.6,6.3-2.4,9.4c-0.5,2.1-1.9,3.2-3.9,3.3c-1.8,0-3.5-1.1-3.8-2.9c-0.2-1.1,0-2.4,0.3-3.6
  c1-4,2.1-7.9,3.2-11.9c1.7-6.1,3.2-12.2,5-18.3c1.2-4.1,4.2-6.8,8.2-8.3c1.5-0.5,3.1-0.8,4.6-0.8c5.8-0.1,11.7,0,17.5,0
  c2.1,0,3.9,0.8,5.7,1.8c2.4,1.4,4.3,3.4,5.4,5.9c0.7,1.7,1.1,3.5,1.6,5.3c2.5,9,4.9,18,7.4,27.1c0.8,2.9-0.7,5.2-3.5,5.5
  c-1.9,0.2-3.7-1-4.2-3.1c-0.9-3.5-1.9-7.1-2.8-10.6c-1.6-6-3.2-12-4.8-18.1c-0.9,0-1.8,0-2.7,0C86.5,59.7,90.8,76.8,95.1,93.9z"
      />
    </g>
    <g>
      <path
        d="M82,14.4c0,6.1-5,11.1-11.1,11.1c-6.1,0-11.1-5-11.1-11.1c0-6.1,5-11,11.1-11.1C76.8,3.2,82.1,8.2,82,14.4z"
      />
    </g>
  </svg>;

FemaleIcon.defaultProps = {
  height: '24',
  className: '',
  width: '24',
  fill: '#00b8cb',
  transform: 'translate(0,0)',
};

FemaleIcon.propTypes = {
  height: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
  transform: PropTypes.string,
};

export default FemaleIcon;
