import React from 'react';
import PropTypes from 'prop-types';

const AlarmEmerConsIco = ({ height, svgClass, width, fill, transform }) => (
  <svg className={svgClass} width={width} height={height} fill={fill} transform={transform} viewBox="0 0 140 140" xmlns="http://www.w3.org/2000/svg">
    <g id="Mvz9hg_1_">
      <g>
        <g>
          <path
            d="M63.4,23.5c0-1.6,0-3.1,0-4.6c0-4.2,3.4-7.7,7.5-7.7c4.1,0,7.5,3.4,7.5,7.7c0,1.5,0,3,0,4.3c3.1,1.2,6.2,2,9,3.5
c13.5,6.9,20.4,18.1,20.7,33.2c0.2,8,0.9,15.8,4,23.2c2,4.7,5.1,8.6,8.6,12.2c3.9,4.1,2.6,10.4-2.6,12.3c-1,0.4-2.1,0.5-3.2,0.5
c-29.4,0-58.8,0-88.2,0c-3.5,0-6-1.5-7.5-4.8c-1.3-2.9-0.6-5.7,2-8.3C28.2,88.2,32,79.9,33,70.3c0.5-4.9,0.4-9.8,1-14.7
C35.5,40.3,46.6,27.8,61.6,24C62.2,23.9,62.7,23.7,63.4,23.5z"
          />
          <path d="M85.7,115.6c0.3,6.9-5.3,14.8-14.9,14.9c-8.2,0-15.3-7.3-14.7-14.9C65.9,115.6,75.8,115.6,85.7,115.6z" />
        </g>
      </g>
    </g>
  </svg>
);

AlarmEmerConsIco.defaultProps = {
  height: '75',
  svgClass: '',
  width: '75',
  fill: '#D8342B',
  transform: 'translate(0,0)',
};

AlarmEmerConsIco.propTypes = {
  height: PropTypes.string,
  svgClass: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
  transform: PropTypes.string,
};

export default AlarmEmerConsIco;
