import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { returnColor } from '../../../../BLL/general';
import {
  alarmBackgroundColor,
  alarmFooterIconColor,
  zoneBackgroundColor,
  zoneFooterIconColor,
  lightGrayBackgroundColor,
  grayFooterIconColor
} from '../../../../BLL/globals';
import AttendsClosedCard from '../../../Cards/Attends/AttendsClosedCard';
import CustomCardHOC from '../../../Cards/CustomCardHOC';

import style from './closedAttends.module.scss';

const ClosedAttends = ({ attends, props, lang, history, showIcons, useZoneColors }) => {
  const attendsLength = attends ? attends.length : 0;
  return (
    <div className="container-fluid" style={{ float: 'left' }}>
      <div className="row" style={{ height: '100%', border: 'none' }}>
        <div className={style.title}>
          <FormattedMessage id="dashboard.closed.header" defaultMessage={'ÚLTIMOS {attendsLength} ATENDIMENTOS'} values={{ attendsLength: attendsLength > 6 ? 6 : attendsLength }} />
          <hr className="hrcolor" />
        </div>
      </div>
      <div className="row" style={{ height: '100%', border: 'none' }}>
        {attendsLength > 0 ? (
          attends.slice(0, 6).map((closed) => {
            let theme = closed.test_attend ? '#59D7F2' : returnColor(alarmBackgroundColor, closed.highest_priority -1);
            let iconColor = returnColor(alarmFooterIconColor, closed.highest_priority -1);
            let clientName = `${closed.client_first_name.split(' ')[0]} ${closed.client_last_name.split(' ').pop()}${closed.test_attend ? ' --Test--' : ''}`;
            if (closed.is_from_secondary && closed.secondary_first_name) {
              clientName = `${closed.secondary_first_name.split(' ')[0]} ${closed.secondary_last_name.split(' ').pop()}${closed.test_attend ? ' --Test--' : ''}`
            }
            if (useZoneColors) {
              if (closed.highest_code === '101' && closed.tr_zone) {
                theme = returnColor(zoneBackgroundColor, parseInt(closed.tr_zone) -1);
                iconColor = returnColor(zoneFooterIconColor, parseInt(closed.tr_zone) -1);
              } else if (closed.highest_code === '100') {
                const redColor = 3;
                theme = returnColor(zoneBackgroundColor, redColor);
                iconColor = returnColor(zoneFooterIconColor, redColor);

              } else {
                theme = lightGrayBackgroundColor;
                iconColor = grayFooterIconColor;
              }
            }
            const AttendsClose = (

              <AttendsClosedCard
                key={closed.id}
                attend_id={closed.id}
                attend_ts={closed.open_ts}
                alarmCode={closed.highest_code}
                tr_description={closed.tr_description}
                iconColor={iconColor}
                lang={lang}
                showIcons={showIcons}
                attendant={
                  closed.attendant
                    ? `${closed.att_first_name} ${closed.att_last_name}`
                    : closed.master_name
                }
              />
            );

            return (
              <div className="col-12 col-md-6 col-lg-4 col-xl-4" style={{ cursor: 'pointer', height: '100%' }} data-testid={`attend-closed-${closed.id}`}>
                <CustomCardHOC
                  key={closed.id}
                  maxHeight="1.4rem"
                  headerFontSize="1.1rem"
                  headers={[clientName]}
                  onClick={() => {
                    history.push(`/app/attends/closed/${closed.id}`);
                  }}
                  childComponent={[AttendsClose]}
                  theme={theme}
                />
              </div>
            );
          })
        ) : (
          <div className={style.empty}>
            <div className={style.text}>
              <FormattedMessage id="dashboard.closed.noAttends" defaultMessage="Nenhum atendimento fechado" />
            </div>
          </div>
        )}

      </div>
    </div>
  );
};

ClosedAttends.propTypes = {
  attends: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      cli_info: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
      }),
      master_user_id: PropTypes.number,
      open_ts: PropTypes.string,
      tenant_from: PropTypes.string,
    }),
  ).isRequired,
  lang: PropTypes.string.isRequired,
};

export default ClosedAttends;
