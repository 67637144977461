export const LOAD_CLIENTS_SUCCESS = 'LOAD_CLIENTS_SUCCESS';
export const NEW_CLIENT = 'NEW_CLIENT';
export const EDIT_CLIENT = 'EDIT_CLIENT';
export const RECEIVE_NEW_CLIENT_PAGE = 'RECEIVE_NEW_CLIENT_PAGE';
export const SET_CLIENT_PAGE = 'SET_CLIENT_PAGE';
export const IMPORT_CLIENTS_SUCCESS = 'IMPORT_CLIENTS_SUCCESS';

export const LOAD_TRANSMITTERS_SUCCESS = 'LOAD_TRANSMITTERS_SUCCESS';
export const NEW_TRANSMITTER = 'NEW_TRANSMITTER';
export const EDIT_TRANSMITTER = 'EDIT_TRANSMITTER';
export const REMOVE_TRANSMITTER = 'REMOVE_TRANSMITTER';

export const LOAD_CONTACTS_SUCCESS = 'LOAD_CONTACTS_SUCCESS';
export const NEW_CONTACT = 'NEW_CONTACT';
export const EDIT_CONTACT = 'EDIT_CONTACT';
export const REMOVE_CONTACT = 'REMOVE_CONTACT';

export const LOAD_ATTENDANTS_SUCCESS = 'LOAD_ATTENDANTS_SUCCESS';
export const EDIT_ATTENDANT = 'EDIT_ATTENDANT';
export const NEW_ATTENDANT = 'NEW_ATTENDANT';
export const TOGGLE_ATTENDANT = 'TOGGLE_ATTENDANT';

export const LOGOUT = 'LOGOUT';
export const REMOVE_ERROR = 'REMOVE_ERROR';

export const SET_LOGGED_ATTENDANT = 'SET_LOGGED_ATTENDANT';

export const LOAD_ATTENDS_SUCCESS = 'LOAD_ATTENDS_SUCCESS';
export const RECEIVE_NEW_ATTEND_PAGE = 'RECEIVE_NEW_ATTEND_PAGE';
export const SET_ATTEND_PAGE = 'SET_ATTEND_PAGE';
export const EDIT_ATTEND = 'EDIT_ATTEND';

export const LOAD_ALARMS_SUCCESS = 'LOAD_ALARMS_SUCCESS';
export const EDIT_ALARM = 'EDIT_ALARM';
export const NEW_ALARM = 'NEW_ALARM'

export const AUTH_LOGIN_USER_REQUEST = 'AUTH_LOGIN_USER_REQUEST';
export const AUTH_LOGIN_USER_SUCCESS = 'AUTH_LOGIN_USER_SUCCESS';
export const AUTH_LOGIN_USER_FAILURE = 'AUTH_LOGIN_USER_FAILURE';
export const AUTH_LOGOUT_USER = 'AUTH_LOGOUT_USER';

export const SET_ATTTENDANT_BUSY_STATUS = 'SET_ATTTENDANT_BUSY_STATUS';

export const LOAD_EQUIPMENT_SUCCESS = 'LOAD_EQUIPMENT_SUCCESS';

export const ADD_BROWSER_DIMENTIONS = 'ADD_BROWSER_DIMENTIONS';

export const RELOAD_LOCAL_CONFIGURATIONS = 'RELOAD_LOCAL_CONFIGURATIONS';
export const RELOAD_GLOBAL_CONFIGURATIONS = 'RELOAD_GLOBAL_CONFIGURATIONS';
export const SET_LOCAL_CONFIGURATIONS = 'SET_LOCAL_CONFIGURATIONS';
export const SET_GLOBAL_CONFIGURATIONS = 'SET_GLOBAL_CONFIGURATIONS';

export const LOAD_TENANT_STATUS_SUCCESS = 'LOAD_TENANT_STATUS_SUCCESS';
export const RELOAD_TENANT_STATUS_SUCCESS = 'RELOAD_TENANT_STATUS_SUCCESS';
export const LOAD_TENANT_STATUS_FAILURE = 'LOAD_TENANT_STATUS_FAILURE';

export const LOAD_GROUPS_SUCCESS = 'LOAD_GROUPS_SUCCESS';

export const LOCALE_SET = 'LOCALE_SET';
export const SET_RELOAD_CLIENTS = 'SET_RELOAD_CLIENTS';

export const LOAD_REMINDERS_SUCCESS = 'LOAD_REMINDERS_SUCCESS';
export const RELOAD_REMINDERS_SUCCESS = 'RELOAD_REMINDERS_SUCCESS';

export const LOAD_SERVER_CONFIG_SUCCESS = 'LOAD_SERVER_CONFIG_SUCCESS';
export const LOAD_SERVER_LIST_SUCCESS = 'LOAD_SERVER_LIST_SUCCESS';

export const LOAD_ATTEND_TAGS_SUCCESS = 'LOAD_ATTEND_TAGS_SUCCESS';

export const LOAD_DASHBOARD_SUCCESS = 'LOAD_DASHBOARD_SUCCESS';
export const LOAD_OPEN_DASHBOARD_SUCCESS = 'LOAD_OPEN_DASHBOARD_SUCCESS';
export const NEW_ATTEND_DASHBOARD = 'NEW_ATTEND_DASHBOARD';
export const UPDATE_ATTEND_DASHBOARD = 'UPDATE_ATTEND_DASHBOARD';
export const CLOSE_ATTEND_DASHBOARD = 'CLOSE_ATTEND_DASHBOARD';
export const LOADING_DASHBOARD_ATTENDS = 'LOADING_DASHBOARD_ATTENDS';

export const LOAD_BUSY_USERS_SUCCESS = 'LOAD_BUSY_USERS_SUCCESS';
export const REMOVE_BUSY_USER = 'REMOVE_BUSY_USER';
export const ADD_BUSY_USER = 'ADD_BUSY_USER';

export const AUTH_RESET_STORE = 'AUTH_RESET_STORE';

export const ADD_LOWER_ALARM = 'ADD_LOWER_ALARM';
export const REMOVE_LOWER_ALARM = 'REMOVE_LOWER_ALARM';

export const TOGGLE_REMINDER_COMPONENT = 'TOGGLE_REMINDER_COMPONENT';
