import * as types from './actionTypes';
import * as errorsActions from './errorsActions';
import * as globals from '../globals/globals';
import { host, getDomain, getToken } from '../BLL/environment';

export function loadContactsSuccess(contacts, clientId, ordering) {
  return { type: types.LOAD_CONTACTS_SUCCESS, contacts, clientId, ordering };
}

export function contactAdded(contact, clientId) {
  return { type: types.NEW_CONTACT, contact, clientId };
}

export function contactUpdated(contact, clientId) {
  return { type: types.EDIT_CONTACT, contact, clientId };
}

export function contactRemoved(contact, clientId) {
  return { type: types.REMOVE_CONTACT, contact, clientId };
}


export function loadContacts(clientId, ordering = globals.contactsOrdering) {
  const domain = getDomain();
  const token = getToken();

  return (dispatch, getState, { axios }) => Promise.resolve(
    axios({
      method: 'get',
      url: `https://${domain}${host()}/api/clients/${clientId}/contacts/?ordering=is_deleted,${ordering}`,
      headers: { Authorization: `Token ${token}` },
    })
    .then((contacts) => {
        dispatch(loadContactsSuccess(contacts.data, clientId, ordering));
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(errorsActions.logout());
        }
      }
    }),
  );
}

export function createContact(clientId, contact) {
  const domain = getDomain();
  const token = getToken();

  return (dispatch, getState, { axios }) => new Promise((resolve, reject) => 
    axios({
      method: 'post',
      responseType: 'json',
      url: `https://${domain}${host()}/api/clients/${clientId}/contacts/`,
      data: contact,
      headers: { Authorization: `Token ${token}` },
    })
    .then((res) => resolve(dispatch(contactAdded(res.data, clientId))))
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          reject(dispatch(errorsActions.logout()));
        }
      }
      return reject(error);
    }),
  );
}

export function editContact(clientId, contact) {
  const domain = getDomain();
  const token = getToken();

  return (dispatch, getState, { axios }) => new Promise((resolve, reject) =>
    axios({
      method: 'put',
      responseType: 'json',
      url: `https://${domain}${host()}/api/clients/${clientId}/contacts/${contact.id}/`,
      data: contact,
      headers: { Authorization: `Token ${token}` },
    })
    .then(() => resolve(dispatch(contactUpdated(contact, clientId))))
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          reject(dispatch(errorsActions.logout()));
        }
      }
      return reject(error);
    }),
  );
}

export function deleteContact(clientId, contact) {
  const domain = getDomain();
  const token = getToken();

  return (dispatch, getState, { axios }) => new Promise((resolve, reject) =>
    axios({
      method: 'delete',
      responseType: 'json',
      url: `https://${domain}${host()}/api/clients/${clientId}/contacts/${contact.id}/`,
      headers: { Authorization: `Token ${token}` },
    })
    .then(() => resolve(dispatch(contactUpdated(contact, clientId))))
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(errorsActions.logout());
        }
      }
      return reject(error);
    }),
  );
}

export function destroyContact(clientId, contact) {
  const domain = getDomain();
  const token = getToken();

  return (dispatch, getState, { axios }) => new Promise((resolve, reject) =>
    axios({
      method: 'delete',
      responseType: 'json',
      url: `https://${domain}${host()}/api/clients/${clientId}/contacts/${contact.id}/destroy/`,
      headers: { Authorization: `Token ${token}` },
    })
    .then(() => resolve(dispatch(contactRemoved(contact, clientId))))
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(errorsActions.logout());
        }
      }
      return reject(error);
    }),
  );
}
