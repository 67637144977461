/* eslint-disable import/no-anonymous-default-export */
export default {
  en: [
    {
      name: 'Afghanistan',
      code: 'AF',
    },
    {
      name: 'Åland Islands',
      code: 'AX',
    },
    {
      name: 'Albania',
      code: 'AL',
    },
    {
      name: 'Algeria',
      code: 'DZ',
    },
    {
      name: 'American Samoa',
      code: 'AS',
    },
    {
      name: 'AndorrA',
      code: 'AD',
    },
    {
      name: 'Angola',
      code: 'AO',
    },
    {
      name: 'Anguilla',
      code: 'AI',
    },
    {
      name: 'Antarctica',
      code: 'AQ',
    },
    {
      name: 'Antigua and Barbuda',
      code: 'AG',
    },
    {
      name: 'Argentina',
      code: 'AR',
    },
    {
      name: 'Armenia',
      code: 'AM',
    },
    {
      name: 'Aruba',
      code: 'AW',
    },
    {
      name: 'Australia',
      code: 'AU',
    },
    {
      name: 'Austria',
      code: 'AT',
    },
    {
      name: 'Azerbaijan',
      code: 'AZ',
    },
    {
      name: 'Bahamas',
      code: 'BS',
    },
    {
      name: 'Bahrain',
      code: 'BH',
    },
    {
      name: 'Bangladesh',
      code: 'BD',
    },
    {
      name: 'Barbados',
      code: 'BB',
    },
    {
      name: 'Belarus',
      code: 'BY',
    },
    {
      name: 'Belgium',
      code: 'BE',
    },
    {
      name: 'Belize',
      code: 'BZ',
    },
    {
      name: 'Benin',
      code: 'BJ',
    },
    {
      name: 'Bermuda',
      code: 'BM',
    },
    {
      name: 'Bhutan',
      code: 'BT',
    },
    {
      name: 'Bolivia',
      code: 'BO',
    },
    {
      name: 'Bosnia and Herzegovina',
      code: 'BA',
    },
    {
      name: 'Botswana',
      code: 'BW',
    },
    {
      name: 'Bouvet Island',
      code: 'BV',
    },
    {
      name: 'Brazil',
      code: 'BR',
    },
    {
      name: 'British Indian Ocean Territory',
      code: 'IO',
    },
    {
      name: 'Brunei Darussalam',
      code: 'BN',
    },
    {
      name: 'Bulgaria',
      code: 'BG',
    },
    {
      name: 'Burkina Faso',
      code: 'BF',
    },
    {
      name: 'Burundi',
      code: 'BI',
    },
    {
      name: 'Cambodia',
      code: 'KH',
    },
    {
      name: 'Cameroon',
      code: 'CM',
    },
    {
      name: 'Canada',
      code: 'CA',
    },
    {
      name: 'Cape Verde',
      code: 'CV',
    },
    {
      name: 'Cayman Islands',
      code: 'KY',
    },
    {
      name: 'Central African Republic',
      code: 'CF',
    },
    {
      name: 'Chad',
      code: 'TD',
    },
    {
      name: 'Chile',
      code: 'CL',
    },
    {
      name: 'China',
      code: 'CN',
    },
    {
      name: 'Christmas Island',
      code: 'CX',
    },
    {
      name: 'Cocos (Keeling) Islands',
      code: 'CC',
    },
    {
      name: 'Colombia',
      code: 'CO',
    },
    {
      name: 'Comoros',
      code: 'KM',
    },
    {
      name: 'Congo',
      code: 'CG',
    },
    {
      name: 'Congo, Democratic Republic',
      code: 'CD',
    },
    {
      name: 'Cook Islands',
      code: 'CK',
    },
    {
      name: 'Costa Rica',
      code: 'CR',
    },
    {
      name: 'Cote D\'Ivoire',
      code: 'CI',
    },
    {
      name: 'Croatia',
      code: 'HR',
    },
    {
      name: 'Cuba',
      code: 'CU',
    },
    {
      name: 'Cyprus',
      code: 'CY',
    },
    {
      name: 'Czech Republic',
      code: 'CZ',
    },
    {
      name: 'Denmark',
      code: 'DK',
    },
    {
      name: 'Djibouti',
      code: 'DJ',
    },
    {
      name: 'Dominica',
      code: 'DM',
    },
    {
      name: 'Dominican Republic',
      code: 'DO',
    },
    {
      name: 'Ecuador',
      code: 'EC',
    },
    {
      name: 'Egypt',
      code: 'EG',
    },
    {
      name: 'El Salvador',
      code: 'SV',
    },
    {
      name: 'Equatorial Guinea',
      code: 'GQ',
    },
    {
      name: 'Eritrea',
      code: 'ER',
    },
    {
      name: 'Estonia',
      code: 'EE',
    },
    {
      name: 'Ethiopia',
      code: 'ET',
    },
    {
      name: 'Falkland Islands (Malvinas)',
      code: 'FK',
    },
    {
      name: 'Faroe Islands',
      code: 'FO',
    },
    {
      name: 'Fiji',
      code: 'FJ',
    },
    {
      name: 'Finland',
      code: 'FI',
    },
    {
      name: 'France',
      code: 'FR',
    },
    {
      name: 'French Guiana',
      code: 'GF',
    },
    {
      name: 'French Polynesia',
      code: 'PF',
    },
    {
      name: 'French Southern Territories',
      code: 'TF',
    },
    {
      name: 'Gabon',
      code: 'GA',
    },
    {
      name: 'Gambia',
      code: 'GM',
    },
    {
      name: 'Georgia',
      code: 'GE',
    },
    {
      name: 'Germany',
      code: 'DE',
    },
    {
      name: 'Ghana',
      code: 'GH',
    },
    {
      name: 'Gibraltar',
      code: 'GI',
    },
    {
      name: 'Greece',
      code: 'GR',
    },
    {
      name: 'Greenland',
      code: 'GL',
    },
    {
      name: 'Grenada',
      code: 'GD',
    },
    {
      name: 'Guadeloupe',
      code: 'GP',
    },
    {
      name: 'Guam',
      code: 'GU',
    },
    {
      name: 'Guatemala',
      code: 'GT',
    },
    {
      name: 'Guernsey',
      code: 'GG',
    },
    {
      name: 'Guinea',
      code: 'GN',
    },
    {
      name: 'Guinea-Bissau',
      code: 'GW',
    },
    {
      name: 'Guyana',
      code: 'GY',
    },
    {
      name: 'Haiti',
      code: 'HT',
    },
    {
      name: 'Heard Island and Mcdonald Islands',
      code: 'HM',
    },
    {
      name: 'Holy See (Vatican City State)',
      code: 'VA',
    },
    {
      name: 'Honduras',
      code: 'HN',
    },
    {
      name: 'Hong Kong',
      code: 'HK',
    },
    {
      name: 'Hungary',
      code: 'HU',
    },
    {
      name: 'Iceland',
      code: 'IS',
    },
    {
      name: 'India',
      code: 'IN',
    },
    {
      name: 'Indonesia',
      code: 'ID',
    },
    {
      name: 'Iran',
      code: 'IR',
    },
    {
      name: 'Iraq',
      code: 'IQ',
    },
    {
      name: 'Ireland',
      code: 'IE',
    },
    {
      name: 'Isle of Man',
      code: 'IM',
    },
    {
      name: 'Israel',
      code: 'IL',
    },
    {
      name: 'Italy',
      code: 'IT',
    },
    {
      name: 'Jamaica',
      code: 'JM',
    },
    {
      name: 'Japan',
      code: 'JP',
    },
    {
      name: 'Jersey',
      code: 'JE',
    },
    {
      name: 'Jordan',
      code: 'JO',
    },
    {
      name: 'Kazakhstan',
      code: 'KZ',
    },
    {
      name: 'Kenya',
      code: 'KE',
    },
    {
      name: 'Kiribati',
      code: 'KI',
    },
    {
      name: 'Korea (North)',
      code: 'KP',
    },
    {
      name: 'Korea (South)',
      code: 'KR',
    },
    {
      name: 'Kosovo',
      code: 'XK',
    },
    {
      name: 'Kuwait',
      code: 'KW',
    },
    {
      name: 'Kyrgyzstan',
      code: 'KG',
    },
    {
      name: 'Laos',
      code: 'LA',
    },
    {
      name: 'Latvia',
      code: 'LV',
    },
    {
      name: 'Lebanon',
      code: 'LB',
    },
    {
      name: 'Lesotho',
      code: 'LS',
    },
    {
      name: 'Liberia',
      code: 'LR',
    },
    {
      name: 'Libyan Arab Jamahiriya',
      code: 'LY',
    },
    {
      name: 'Liechtenstein',
      code: 'LI',
    },
    {
      name: 'Lithuania',
      code: 'LT',
    },
    {
      name: 'Luxembourg',
      code: 'LU',
    },
    {
      name: 'Macao',
      code: 'MO',
    },
    {
      name: 'Macedonia',
      code: 'MK',
    },
    {
      name: 'Madagascar',
      code: 'MG',
    },
    {
      name: 'Malawi',
      code: 'MW',
    },
    {
      name: 'Malaysia',
      code: 'MY',
    },
    {
      name: 'Maldives',
      code: 'MV',
    },
    {
      name: 'Mali',
      code: 'ML',
    },
    {
      name: 'Malta',
      code: 'MT',
    },
    {
      name: 'Marshall Islands',
      code: 'MH',
    },
    {
      name: 'Martinique',
      code: 'MQ',
    },
    {
      name: 'Mauritania',
      code: 'MR',
    },
    {
      name: 'Mauritius',
      code: 'MU',
    },
    {
      name: 'Mayotte',
      code: 'YT',
    },
    {
      name: 'Mexico',
      code: 'MX',
    },
    {
      name: 'Micronesia',
      code: 'FM',
    },
    {
      name: 'Moldova',
      code: 'MD',
    },
    {
      name: 'Monaco',
      code: 'MC',
    },
    {
      name: 'Mongolia',
      code: 'MN',
    },
    {
      name: 'Montserrat',
      code: 'MS',
    },
    {
      name: 'Morocco',
      code: 'MA',
    },
    {
      name: 'Mozambique',
      code: 'MZ',
    },
    {
      name: 'Myanmar',
      code: 'MM',
    },
    {
      name: 'Namibia',
      code: 'NA',
    },
    {
      name: 'Nauru',
      code: 'NR',
    },
    {
      name: 'Nepal',
      code: 'NP',
    },
    {
      name: 'Netherlands',
      code: 'NL',
    },
    {
      name: 'Netherlands Antilles',
      code: 'AN',
    },
    {
      name: 'New Caledonia',
      code: 'NC',
    },
    {
      name: 'New Zealand',
      code: 'NZ',
    },
    {
      name: 'Nicaragua',
      code: 'NI',
    },
    {
      name: 'Niger',
      code: 'NE',
    },
    {
      name: 'Nigeria',
      code: 'NG',
    },
    {
      name: 'Niue',
      code: 'NU',
    },
    {
      name: 'Norfolk Island',
      code: 'NF',
    },
    {
      name: 'Northern Mariana Islands',
      code: 'MP',
    },
    {
      name: 'Norway',
      code: 'NO',
    },
    {
      name: 'Oman',
      code: 'OM',
    },
    {
      name: 'Pakistan',
      code: 'PK',
    },
    {
      name: 'Palau',
      code: 'PW',
    },
    {
      name: 'Palestinian Territory, Occupied',
      code: 'PS',
    },
    {
      name: 'Panama',
      code: 'PA',
    },
    {
      name: 'Papua New Guinea',
      code: 'PG',
    },
    {
      name: 'Paraguay',
      code: 'PY',
    },
    {
      name: 'Peru',
      code: 'PE',
    },
    {
      name: 'Philippines',
      code: 'PH',
    },
    {
      name: 'Pitcairn',
      code: 'PN',
    },
    {
      name: 'Poland',
      code: 'PL',
    },
    {
      name: 'Portugal',
      code: 'PT',
    },
    {
      name: 'Puerto Rico',
      code: 'PR',
    },
    {
      name: 'Qatar',
      code: 'QA',
    },
    {
      name: 'Reunion',
      code: 'RE',
    },
    {
      name: 'Romania',
      code: 'RO',
    },
    {
      name: 'Russian Federation',
      code: 'RU',
    },
    {
      name: 'Rwanda',
      code: 'RW',
    },
    {
      name: 'Saint Helena',
      code: 'SH',
    },
    {
      name: 'Saint Kitts and Nevis',
      code: 'KN',
    },
    {
      name: 'Saint Lucia',
      code: 'LC',
    },
    {
      name: 'Saint Pierre and Miquelon',
      code: 'PM',
    },
    {
      name: 'Saint Vincent and the Grenadines',
      code: 'VC',
    },
    {
      name: 'Samoa',
      code: 'WS',
    },
    {
      name: 'San Marino',
      code: 'SM',
    },
    {
      name: 'Sao Tome and Principe',
      code: 'ST',
    },
    {
      name: 'Saudi Arabia',
      code: 'SA',
    },
    {
      name: 'Senegal',
      code: 'SN',
    },
    {
      name: 'Serbia',
      code: 'RS',
    },
    {
      name: 'Montenegro',
      code: 'ME',
    },
    {
      name: 'Seychelles',
      code: 'SC',
    },
    {
      name: 'Sierra Leone',
      code: 'SL',
    },
    {
      name: 'Singapore',
      code: 'SG',
    },
    {
      name: 'Slovakia',
      code: 'SK',
    },
    {
      name: 'Slovenia',
      code: 'SI',
    },
    {
      name: 'Solomon Islands',
      code: 'SB',
    },
    {
      name: 'Somalia',
      code: 'SO',
    },
    {
      name: 'South Africa',
      code: 'ZA',
    },
    {
      name: 'South Georgia and the South Sandwich Islands',
      code: 'GS',
    },
    {
      name: 'Spain',
      code: 'ES',
    },
    {
      name: 'Sri Lanka',
      code: 'LK',
    },
    {
      name: 'Sudan',
      code: 'SD',
    },
    {
      name: 'Suriname',
      code: 'SR',
    },
    {
      name: 'Svalbard and Jan Mayen',
      code: 'SJ',
    },
    {
      name: 'Swaziland',
      code: 'SZ',
    },
    {
      name: 'Sweden',
      code: 'SE',
    },
    {
      name: 'Switzerland',
      code: 'CH',
    },
    {
      name: 'Syrian Arab Republic',
      code: 'SY',
    },
    {
      name: 'Taiwan, Province of China',
      code: 'TW',
    },
    {
      name: 'Tajikistan',
      code: 'TJ',
    },
    {
      name: 'Tanzania',
      code: 'TZ',
    },
    {
      name: 'Thailand',
      code: 'TH',
    },
    {
      name: 'Timor-Leste',
      code: 'TL',
    },
    {
      name: 'Togo',
      code: 'TG',
    },
    {
      name: 'Tokelau',
      code: 'TK',
    },
    {
      name: 'Tonga',
      code: 'TO',
    },
    {
      name: 'Trinidad and Tobago',
      code: 'TT',
    },
    {
      name: 'Tunisia',
      code: 'TN',
    },
    {
      name: 'Turkey',
      code: 'TR',
    },
    {
      name: 'Turkmenistan',
      code: 'TM',
    },
    {
      name: 'Turks and Caicos Islands',
      code: 'TC',
    },
    {
      name: 'Tuvalu',
      code: 'TV',
    },
    {
      name: 'Uganda',
      code: 'UG',
    },
    {
      name: 'Ukraine',
      code: 'UA',
    },
    {
      name: 'United Arab Emirates',
      code: 'AE',
    },
    {
      name: 'United Kingdom',
      code: 'GB',
    },
    {
      name: 'United States',
      code: 'US',
    },
    {
      name: 'United States Minor Outlying Islands',
      code: 'UM',
    },
    {
      name: 'Uruguay',
      code: 'UY',
    },
    {
      name: 'Uzbekistan',
      code: 'UZ',
    },
    {
      name: 'Vanuatu',
      code: 'VU',
    },
    {
      name: 'Venezuela',
      code: 'VE',
    },
    {
      name: 'Viet Nam',
      code: 'VN',
    },
    {
      name: 'Virgin Islands, British',
      code: 'VG',
    },
    {
      name: 'Virgin Islands, U.S.',
      code: 'VI',
    },
    {
      name: 'Wallis and Futuna',
      code: 'WF',
    },
    {
      name: 'Western Sahara',
      code: 'EH',
    },
    {
      name: 'Yemen',
      code: 'YE',
    },
    {
      name: 'Zambia',
      code: 'ZM',
    },
    {
      name: 'Zimbabwe',
      code: 'ZW',
    },
  ],
  pt: [
    {
      name: 'Afeganistão',
      code: 'AF',
    },
    {
      name: 'África do Sul',
      code: 'ZA',
    },
    {
      name: 'Albânia',
      code: 'AL',
    },
    {
      name: 'Alemanha',
      code: 'DE',
    },
    {
      name: 'Andorra',
      code: 'AD',
    },
    {
      name: 'Angola',
      code: 'AO',
    },
    {
      name: 'Anguilla',
      code: 'AI',
    },
    {
      name: 'Antártida',
      code: 'AQ',
    },
    {
      name: 'Antígua e Barbuda',
      code: 'AG',
    },
    {
      name: 'Arábia Saudita',
      code: 'SA',
    },
    {
      name: 'Argélia',
      code: 'DZ',
    },
    {
      name: 'Argentina',
      code: 'AR',
    },
    {
      name: 'Armênia',
      code: 'AM',
    },
    {
      name: 'Aruba',
      code: 'AW',
    },
    {
      name: 'Austrália',
      code: 'AU',
    },
    {
      name: 'Áustria',
      code: 'AT',
    },
    {
      name: 'Azerbaijão',
      code: 'AZ',
    },
    {
      name: 'Bahamas',
      code: 'BS',
    },
    {
      name: 'Bahrein',
      code: 'BH',
    },
    {
      name: 'Bangladesh',
      code: 'BD',
    },
    {
      name: 'Barbados',
      code: 'BB',
    },
    {
      name: 'Belarus',
      code: 'BY',
    },
    {
      name: 'Bélgica',
      code: 'BE',
    },
    {
      name: 'Belize',
      code: 'BZ',
    },
    {
      name: 'Benin',
      code: 'BJ',
    },
    {
      name: 'Bermudas',
      code: 'BM',
    },
    {
      name: 'Bolívia',
      code: 'BO',
    },
    {
      name: 'Bósnia-Herzegóvina',
      code: 'BA',
    },
    {
      name: 'Botsuana',
      code: 'BW',
    },
    {
      name: 'Brasil',
      code: 'BR',
    },
    {
      name: 'Brunei',
      code: 'BN',
    },
    {
      name: 'Bulgária',
      code: 'BG',
    },
    {
      name: 'Burkina Fasso',
      code: 'BF',
    },
    {
      name: 'Burundi',
      code: 'BI',
    },
    {
      name: 'Butão',
      code: 'BT',
    },
    {
      name: 'Cabo Verde',
      code: 'CV',
    },
    {
      name: 'Camarões',
      code: 'CM',
    },
    {
      name: 'Camboja',
      code: 'KH',
    },
    {
      name: 'Canadá',
      code: 'CA',
    },
    {
      name: 'Canárias',
      code: 'IC',
    },
    {
      name: 'Cazaquistão',
      code: 'KZ',
    },
    {
      name: 'Ceuta e Melilla',
      code: 'EA',
    },
    {
      name: 'Chade',
      code: 'TD',
    },
    {
      name: 'Chile',
      code: 'CL',
    },
    {
      name: 'China',
      code: 'CN',
    },
    {
      name: 'Chipre',
      code: 'CY',
    },
    {
      name: 'Cingapura',
      code: 'SG',
    },
    {
      name: 'Colômbia',
      code: 'CO',
    },
    {
      name: 'Comores',
      code: 'KM',
    },
    {
      name: 'Congo',
      code: 'CG',
    },
    {
      name: 'Coréia do Norte',
      code: 'KP',
    },
    {
      name: 'Coréia do Sul',
      code: 'KR',
    },
    {
      name: 'Costa do Marfim',
      code: 'CI',
    },
    {
      name: 'Costa Rica',
      code: 'CR',
    },
    {
      name: 'Croácia',
      code: 'HR',
    },
    {
      name: 'Cuba',
      code: 'CU',
    },
    {
      name: 'Curaçao',
      code: 'CW',
    },
    {
      name: 'Diego Garcia',
      code: 'DG',
    },
    {
      name: 'Dinamarca',
      code: 'DK',
    },
    {
      name: 'Djibuti',
      code: 'DJ',
    },
    {
      name: 'Dominica',
      code: 'DM',
    },
    {
      name: 'Egito',
      code: 'EG',
    },
    {
      name: 'El Salvador',
      code: 'SV',
    },
    {
      name: 'Emirados Árabes Unidos',
      code: 'AE',
    },
    {
      name: 'Equador',
      code: 'EC',
    },
    {
      name: 'Eritréia',
      code: 'ER',
    },
    {
      name: 'Eslováquia',
      code: 'SK',
    },
    {
      name: 'Eslovênia',
      code: 'SI',
    },
    {
      name: 'Espanha',
      code: 'ES',
    },
    {
      name: 'Estados Unidos',
      code: 'US',
    },
    {
      name: 'Estônia',
      code: 'EE',
    },
    {
      name: 'Etiópia',
      code: 'ET',
    },
    {
      name: 'Fiji',
      code: 'FJ',
    },
    {
      name: 'Filipinas',
      code: 'PH',
    },
    {
      name: 'Finlândia',
      code: 'FI',
    },
    {
      name: 'França',
      code: 'FR',
    },
    {
      name: 'Gabão',
      code: 'GA',
    },
    {
      name: 'Gâmbia',
      code: 'GM',
    },
    {
      name: 'Gana',
      code: 'GH',
    },
    {
      name: 'Geórgia',
      code: 'GE',
    },
    {
      name: 'Gibraltar',
      code: 'GI',
    },
    {
      name: 'Grã-Bretanha (Reino Unido, UK)',
      code: 'GB',
    },
    {
      name: 'Granada',
      code: 'GD',
    },
    {
      name: 'Grécia',
      code: 'GR',
    },
    {
      name: 'Groelândia',
      code: 'GL',
    },
    {
      name: 'Guadalupe',
      code: 'GP',
    },
    {
      name: 'Guam (Território dos Estados Unidos)',
      code: 'GU',
    },
    {
      name: 'Guatemala',
      code: 'GT',
    },
    {
      name: 'Guernsey',
      code: 'GG',
    },
    {
      name: 'Guiana',
      code: 'GY',
    },
    {
      name: 'Guiana Francesa',
      code: 'GF',
    },
    {
      name: 'Guiné',
      code: 'GN',
    },
    {
      name: 'Guiné Equatorial',
      code: 'GQ',
    },
    {
      name: 'Guiné-Bissau',
      code: 'GW',
    },
    {
      name: 'Haiti',
      code: 'HT',
    },
    {
      name: 'Holanda',
      code: 'NL',
    },
    {
      name: 'Honduras',
      code: 'HN',
    },
    {
      name: 'Hong Kong',
      code: 'HK',
    },
    {
      name: 'Hungria',
      code: 'HU',
    },
    {
      name: 'Iêmen',
      code: 'YE',
    },
    {
      name: 'Ilha Bouvet',
      code: 'BV',
    },
    {
      name: 'Ilha de Ascensão',
      code: 'AC',
    },
    {
      name: 'Ilha de Clipperton',
      code: 'CP',
    },
    {
      name: 'Ilha de Man',
      code: 'IM',
    },
    {
      name: 'Ilha Natal',
      code: 'CX',
    },
    {
      name: 'Ilha Pitcairn',
      code: 'PN',
    },
    {
      name: 'Ilha Reunião',
      code: 'RE',
    },
    {
      name: 'Ilhas Aland',
      code: 'AX',
    },
    {
      name: 'Ilhas Cayman',
      code: 'KY',
    },
    {
      name: 'Ilhas Cocos',
      code: 'CC',
    },
    {
      name: 'Ilhas Cook',
      code: 'CK',
    },
    {
      name: 'Ilhas Faroes',
      code: 'FO',
    },
    {
      name: 'Ilhas Geórgia do Sul e Sandwich do Sul',
      code: 'GS',
    },
    {
      name: 'Ilhas Heard e McDonald (Território da Austrália)',
      code: 'HM',
    },
    {
      name: 'Ilhas Malvinas',
      code: 'FK',
    },
    {
      name: 'Ilhas Marianas do Norte',
      code: 'MP',
    },
    {
      name: 'Ilhas Marshall',
      code: 'MH',
    },
    {
      name: 'Ilhas Menores dos Estados Unidos',
      code: 'UM',
    },
    {
      name: 'Ilhas Norfolk',
      code: 'NF',
    },
    {
      name: 'Ilhas Salomão',
      code: 'SB',
    },
    {
      name: 'Ilhas Seychelles',
      code: 'SC',
    },
    {
      name: 'Ilhas Tokelau',
      code: 'TK',
    },
    {
      name: 'Ilhas Turks e Caicos',
      code: 'TC',
    },
    {
      name: 'Ilhas Virgens (Estados Unidos)',
      code: 'VI',
    },
    {
      name: 'Ilhas Virgens (Inglaterra)',
      code: 'VG',
    },
    {
      name: 'Índia',
      code: 'IN',
    },
    {
      name: 'Indonésia',
      code: 'ID',
    },
    {
      name: 'Irã',
      code: 'IR',
    },
    {
      name: 'Iraque',
      code: 'IQ',
    },
    {
      name: 'Irlanda',
      code: 'IE',
    },
    {
      name: 'Islândia',
      code: 'IS',
    },
    {
      name: 'Israel',
      code: 'IL',
    },
    {
      name: 'Itália',
      code: 'IT',
    },
    {
      name: 'Jamaica',
      code: 'JM',
    },
    {
      name: 'Japão',
      code: 'JP',
    },
    {
      name: 'Jersey',
      code: 'JE',
    },
    {
      name: 'Jordânia',
      code: 'JO',
    },
    {
      name: 'Kiribati',
      code: 'KI',
    },
    {
      name: 'Kosovo',
      code: 'XK',
    },
    {
      name: 'Kuait',
      code: 'KW',
    },
    {
      name: 'Laos',
      code: 'LA',
    },
    {
      name: 'Lesoto',
      code: 'LS',
    },
    {
      name: 'Letônia',
      code: 'LV',
    },
    {
      name: 'Líbano',
      code: 'LB',
    },
    {
      name: 'Libéria',
      code: 'LR',
    },
    {
      name: 'Líbia',
      code: 'LY',
    },
    {
      name: 'Liechtenstein',
      code: 'LI',
    },
    {
      name: 'Lituânia',
      code: 'LT',
    },
    {
      name: 'Luxemburgo',
      code: 'LU',
    },
    {
      name: 'Macau',
      code: 'MO',
    },
    {
      name: 'Macedônia (República Yugoslava)',
      code: 'MK',
    },
    {
      name: 'Madagascar',
      code: 'MG',
    },
    {
      name: 'Malásia',
      code: 'MY',
    },
    {
      name: 'Malaui',
      code: 'MW',
    },
    {
      name: 'Maldivas',
      code: 'MV',
    },
    {
      name: 'Mali',
      code: 'ML',
    },
    {
      name: 'Malta',
      code: 'MT',
    },
    {
      name: 'Marrocos',
      code: 'MA',
    },
    {
      name: 'Martinica',
      code: 'MQ',
    },
    {
      name: 'Maurício',
      code: 'MU',
    },
    {
      name: 'Mauritânia',
      code: 'MR',
    },
    {
      name: 'Mayotte',
      code: 'YT',
    },
    {
      name: 'México',
      code: 'MX',
    },
    {
      name: 'Micronésia',
      code: 'FM',
    },
    {
      name: 'Moçambique',
      code: 'MZ',
    },
    {
      name: 'Moldova',
      code: 'MD',
    },
    {
      name: 'Mônaco',
      code: 'MC',
    },
    {
      name: 'Mongólia',
      code: 'MN',
    },
    {
      name: 'Montenegro',
      code: 'ME',
    },
    {
      name: 'Montserrat',
      code: 'MS',
    },
    {
      name: 'Myanma',
      code: 'MM',
    },
    {
      name: 'Namíbia',
      code: 'NA',
    },
    {
      name: 'Nauru',
      code: 'NR',
    },
    {
      name: 'Nepal',
      code: 'NP',
    },
    {
      name: 'Nicarágua',
      code: 'NI',
    },
    {
      name: 'Níger',
      code: 'NE',
    },
    {
      name: 'Nigéria',
      code: 'NG',
    },
    {
      name: 'Niue',
      code: 'NU',
    },
    {
      name: 'Noruega',
      code: 'NO',
    },
    {
      name: 'Nova Caledônia',
      code: 'NC',
    },
    {
      name: 'Nova Zelândia',
      code: 'NZ',
    },
    {
      name: 'Omã',
      code: 'OM',
    },
    {
      name: 'Países Baixos Caribenhos',
      code: 'BQ',
    },
    {
      name: 'Palau',
      code: 'PW',
    },
    {
      name: 'Palestina',
      code: 'PS',
    },
    {
      name: 'Panamá',
      code: 'PA',
    },
    {
      name: 'Papua-Nova Guiné',
      code: 'PG',
    },
    {
      name: 'Paquistão',
      code: 'PK',
    },
    {
      name: 'Paraguai',
      code: 'PY',
    },
    {
      name: 'Peru',
      code: 'PE',
    },
    {
      name: 'Polinésia Francesa',
      code: 'PF',
    },
    {
      name: 'Polônia',
      code: 'PL',
    },
    {
      name: 'Porto Rico',
      code: 'PR',
    },
    {
      name: 'Portugal',
      code: 'PT',
    },
    {
      name: 'Qatar',
      code: 'QA',
    },
    {
      name: 'Quênia',
      code: 'KE',
    },
    {
      name: 'Quirguistão',
      code: 'KG',
    },
    {
      name: 'República Centro-Africana',
      code: 'CF',
    },
    {
      name: 'República Democrática do Congo',
      code: 'CD',
    },
    {
      name: 'República Dominicana',
      code: 'DO',
    },
    {
      name: 'República Tcheca',
      code: 'CZ',
    },
    {
      name: 'Romênia',
      code: 'RO',
    },
    {
      name: 'Ruanda',
      code: 'RW',
    },
    {
      name: 'Rússia (antiga URSS) - Federação Russa',
      code: 'RU',
    },
    {
      name: 'Saara Ocidental',
      code: 'EH',
    },
    {
      name: 'Saint-Pierre e Miquelon',
      code: 'PM',
    },
    {
      name: 'Samoa Americana',
      code: 'AS',
    },
    {
      name: 'Samoa Ocidental',
      code: 'WS',
    },
    {
      name: 'San Marino',
      code: 'SM',
    },
    {
      name: 'Santa Helena',
      code: 'SH',
    },
    {
      name: 'Santa Lúcia',
      code: 'LC',
    },
    {
      name: 'São Bartolomeu',
      code: 'BL',
    },
    {
      name: 'São Cristóvão e Névis',
      code: 'KN',
    },
    {
      name: 'São Martim',
      code: 'MF',
    },
    {
      name: 'São Martinho',
      code: 'SX',
    },
    {
      name: 'São Tomé e Príncipe',
      code: 'ST',
    },
    {
      name: 'São Vicente e Granadinas',
      code: 'VC',
    },
    {
      name: 'Senegal',
      code: 'SN',
    },
    {
      name: 'Serra Leoa',
      code: 'SL',
    },
    {
      name: 'Sérvia',
      code: 'RS',
    },
    {
      name: 'Síria',
      code: 'SY',
    },
    {
      name: 'Somália',
      code: 'SO',
    },
    {
      name: 'Sri Lanka',
      code: 'LK',
    },
    {
      name: 'Suazilândia',
      code: 'SZ',
    },
    {
      name: 'Sudão',
      code: 'SD',
    },
    {
      name: 'Sudão do Sul',
      code: 'SS',
    },
    {
      name: 'Suécia',
      code: 'SE',
    },
    {
      name: 'Suíça',
      code: 'CH',
    },
    {
      name: 'Suriname',
      code: 'SR',
    },
    {
      name: 'Svalbard',
      code: 'SJ',
    },
    {
      name: 'Tadjiquistão',
      code: 'TJ',
    },
    {
      name: 'Tailândia',
      code: 'TH',
    },
    {
      name: 'Taiwan',
      code: 'TW',
    },
    {
      name: 'Tanzânia',
      code: 'TZ',
    },
    {
      name: 'Território Britânico do Oceano índico',
      code: 'IO',
    },
    {
      name: 'Territórios do Sul da França',
      code: 'TF',
    },
    {
      name: 'Timor-Leste',
      code: 'TL',
    },
    {
      name: 'Togo',
      code: 'TG',
    },
    {
      name: 'Tonga',
      code: 'TO',
    },
    {
      name: 'Trinidad e Tobago',
      code: 'TT',
    },
    {
      name: 'Tristão da Cunha',
      code: 'TA',
    },
    {
      name: 'Tunísia',
      code: 'TN',
    },
    {
      name: 'Turcomenistão',
      code: 'TM',
    },
    {
      name: 'Turquia',
      code: 'TR',
    },
    {
      name: 'Tuvalu',
      code: 'TV',
    },
    {
      name: 'Ucrânia',
      code: 'UA',
    },
    {
      name: 'Uganda',
      code: 'UG',
    },
    {
      name: 'Uruguai',
      code: 'UY',
    },
    {
      name: 'Uzbequistão',
      code: 'UZ',
    },
    {
      name: 'Vanuatu',
      code: 'VU',
    },
    {
      name: 'Vaticano',
      code: 'VA',
    },
    {
      name: 'Venezuela',
      code: 'VE',
    },
    {
      name: 'Vietnã',
      code: 'VN',
    },
    {
      name: 'Wallis e Futuna',
      code: 'WF',
    },
    {
      name: 'Zâmbia',
      code: 'ZM',
    },
    {
      name: 'Zimbábue',
      code: 'ZW',
    },
  ],
};
