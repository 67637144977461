import React from 'react';
import PropTypes from 'prop-types';

const TecnoLogoNew = ({ height, svgClass, width }) => (
  <svg
    className={svgClass} xmlns="http://www.w3.org/2000/svg" width={width} height={height}
    viewBox="0 0 122.411 120.832"
  >
    <path d="m74.655 119.63c12.086-2.564 21.8-8.099 30.06-16.36 10.934-10.933 17.696-26.038 17.696-42.722 0-15.638-5.88-29.945-15.691-40.617-1.324-1.438-2.952-3.455-2.952-3.455l-15 16.865 11.414 10.226s3.281-0.161 5.363 1.847c3.134 3.023 5.72 9.522 5.72 15.135 0 13.607-5.517 25.925-14.431 34.84-3.836 3.838-6.49 5.984-10.357 3.316-1.199-0.827-1.163-1.066-1.163-1.066l-15.048 0.053v22.571zm-26.899 0c-12.084-2.564-21.799-8.099-30.06-16.36-10.932-10.933-17.696-26.039-17.696-42.722 0-15.638 5.881-29.945 15.693-40.617 1.323-1.438 2.952-3.455 2.952-3.455l15 16.865-11.416 10.225s-3.28-0.161-5.362 1.847c-3.133 3.023-5.719 9.522-5.719 15.135 0 13.607 5.515 25.925 14.43 34.84 3.836 3.838 6.49 5.984 10.357 3.316 1.201-0.827 1.164-1.066 1.164-1.066l14.454 0.053v22.447zm13.154-119.63c14.972 0 28.675 5.447 39.231 14.471l-7.122 8.575c-8.623-7.416-19.843-11.9-32.109-11.9-12.268 0-23.488 4.483-32.111 11.899l-7.121-8.575c10.554-9.023 24.257-14.47 39.232-14.47zm5.574 120.58c-1.836 0.168-3.694 0.254-5.574 0.254s-3.739-0.086-5.574-0.254v-11.206c1.83 0.209 3.688 0.313 5.574 0.313 1.884 0 3.744-0.104 5.574-0.313zm-11.149-112.16h11.148v103.52h-11.148z" fill="#fff" />
  </svg>
);

TecnoLogoNew.defaultProps = {
  height: '24',
  svgClass: '',
  width: '24',
};

TecnoLogoNew.propTypes = {
  height: PropTypes.string,
  svgClass: PropTypes.string,
  width: PropTypes.string,
};

export default TecnoLogoNew;
