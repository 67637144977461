import * as types from './actionTypes';
import * as errorsActions from './errorsActions';
import { host, getDomain, getToken } from '../BLL/environment';

export function loadTenantStatusSuccess(status) {
  return { type: types.LOAD_TENANT_STATUS_SUCCESS, status };
}

export function reloadTenantStatusSuccess(status) {
  return { type: types.RELOAD_TENANT_STATUS_SUCCESS, status };
}

export function loadTenantStatusFailure(error, message) {
  return {
    type: types.LOAD_TENANT_STATUS_FAILURE,
    payload: {
      status: error,
      statusText: message,
    },
  };
}


export function loadTenantStatus(reload = false) {
  const domain = getDomain();
  const token = getToken();

  return (dispatch, getState, { axios }) => new Promise((resolve, reject) =>
    axios({
      method: 'get',
      responseType: 'json',
      url: `https://${domain}${host()}/api/status/`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    })
      .then(res => resolve(dispatch(loadTenantStatusSuccess(res.data))))
      .catch((error) => {
        if (error && typeof error.response !== 'undefined' && error.response.status === 401) {
          return reject(error.response);
        }
        return reject(dispatch(loadTenantStatusFailure('Connection Error', 'An error occurred while sending your data!')));
      }),
  );
}

export function activateTenant(data) {
  const domain = getDomain();
  const token = getToken();

  return (dispatch, getState, { axios }) => new Promise((resolve, reject) =>
    axios({
      method: 'put',
      responseType: 'json',
      url: `https://${domain}${host()}/api/activate/`,
      data,
      headers: { Authorization: `Token ${token}` },
    })
    .then(() => resolve(dispatch(loadTenantStatus(true))))
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          return reject(dispatch(errorsActions.logout()));
        }
        return reject(error.response);
      }
      return reject({});
    }),
  );
}
