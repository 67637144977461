import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import ModalReturnCall from '../ModalReturnCall';
import ModalDraggable from '../ModalDraggable';
import ReminderInfo from './ReminderInfo';
import ContactInfo from './ContactInfo';
import ReminderControls from './ReminderControls';
import ReminderExtensionCall from './ReminderExtensionCall';
import { broadcast } from '../../../BLL/general';
import { getRemainingTime } from '../../../BLL/timerHelper';
import * as remindersActions from '../../../actions/reminderActions';
import messages from "../../../BLL/locale/messages";
import style from './reminderModal.module.scss';

const ReminderWrapperComponent = (props) => {
  const { openReminderComponent } = props;

  const dispatch = useDispatch();
  const reminders = useSelector(state => state.reminders);
  const equipment = useSelector(state => state.equipment);
  const lang = useSelector(state => state.locale.lang)
  const loggedAttendant = useSelector(state => state.loggedAttendant);
  const updateReminder = (reminder) => dispatch(remindersActions.updateReminder(reminder));

  const [index, setIndex] = useState(0);
  const [limit, setLimit] = useState(1024);
  const [report, setReport] = useState('');
  const [showReturnCallModal, setShowReturnCallModal] = useState(false);
  const [showExtensionCallModal, setShowExtensionCallModal] = useState(false);
  const [selectedReminder, setSelectedReminder] = useState(undefined);

  useEffect(() => {
    if (reminders?.data.length > 0) {
      if (!selectedReminder) {
       setSelectedReminder(reminders.data[0]);
      } else if (!reminders.data.some(rem => selectedReminder.id === rem.id)) {
        setSelectedReminder(reminders.data[0]);
        setReport('');
        setLimit(1024);
        setIndex(0);
      }

    }
  }, [reminders, selectedReminder]);

  useEffect(() => {
    if (reminders.serverTime && selectedReminder) {
      let timerSeconds = getRemainingTime(reminders.serverTime, selectedReminder.open_ts,  selectedReminder.requested_time);

      const interval = setInterval(() => {
        if (timerSeconds <= 0) {
          openReminderComponent();
          clearInterval(interval);
        }
        timerSeconds -= 1;
      }, 1000);

      return () => clearInterval(interval);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reminders.serverTime, selectedReminder]);

  useEffect(() => {
    if (reminders.data.length === 0) {
      props.toggleHasReminders();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reminders?.data.length])

  const updateReport = (event) => {
    const textareaLen = parseInt(document.querySelector('textarea').getAttribute('maxlength'), 10);
    setReport(event.target.value);
    setLimit(textareaLen - event.target.value.length);
  };

  const toServerData = (reminder, isLastReminder) => {
    return Object.assign(
      {},
      reminder,
      { report },
      { status: 'CLOSED' },
      { close_attendant: loggedAttendant.id },
      { isLastReminder }
    );
  };

  const cycleReminders = (value) => {
    const len = reminders?.data.length;
    let distance = value % len;
    const newIndex = (index + distance + len) % len;
    setReport('');
    setLimit(1024);
    setSelectedReminder(reminders.data[newIndex]);
    setIndex(newIndex);
  };

  const closeReminder = () => {
    setShowExtensionCallModal(false);
    const data = toServerData(selectedReminder, true);
    updateReminder(data).then(() => {
      if (!data.is_individual) {
        broadcast(axios, props.channelId, {
          type: 'reminders',
          update: 'reminders',
          ownerAttendantId: loggedAttendant.id,
          userId: loggedAttendant.id
        });
      }
      setReport('');
      setLimit(1024);
      setSelectedReminder(undefined);
      setIndex(0);
      props.toggleReminderComponent();
    });
  };

  const rescheduleReminder = () => {
    props.hangupSip();
    setShowExtensionCallModal(false);
    const data = toServerData(selectedReminder, false);
    updateReminder(data).then(() => {
      if (!data.is_individual) {
        broadcast(axios, props.channelId, {
          type: 'reminders',
          update: 'reminders',
          ownerAttendantId: loggedAttendant.id,
          userId: loggedAttendant.id
        });
      }
      setReport('');
      setLimit(1024);
      setSelectedReminder(undefined);
      setIndex(0);
      props.toggleReminderComponent();
      props.setReminderAttendId(selectedReminder.attend);
      props.openReminderNote();
    });
  };

  const disabled = !(report) || showExtensionCallModal;

  let userEquipment = undefined;
  if (selectedReminder?.client_equipment_id) {
    userEquipment = equipment[0].data.find(eq => eq.id === selectedReminder.client_equipment_id);
  }

  return (
    <div className={`${style['reminder-container']} ${props.hideReminder ? style['reminder-hide-modal'] : ''}`}>
      {showReturnCallModal &&
        <ModalReturnCall
          clientPhone={selectedReminder.client_phone}
          clientAccount={selectedReminder.client_account}
          attendId={`${selectedReminder.attend}-reminder-${selectedReminder.id}`}
          startCall={props.startCall}
          sendPERSPassword={props.sendPERSPassword}
          hangupSip={props.hangupSip}
          sipStackType={props.sipStackType}
          closeModal={() => setShowReturnCallModal(!showReturnCallModal)}
          equipment={userEquipment}
        />
      }
      {showExtensionCallModal &&
        <ModalDraggable
          closeModal={() => {
            setShowExtensionCallModal(false);
            props.hangupSip();
          }}
          headerTitle={`${messages[lang]['extension.headerTitleReminder']} ${selectedReminder.attend}`}
        >
          <ReminderExtensionCall
            selectedReminder={selectedReminder}
            startCall={props.startCall}
            hangupSip={props.hangupSip}
          />
        </ModalDraggable>
      }
      <div className={style['reminder-hide-container']}>
        <button className={style['reminder-hide-button']} onClick={props.toggleReminderComponent}>
          <i className={`fas fa-minus ${style['reminder-btnIcon']}`} aria-hidden="true" />
        </button>
      </div>
      <div className={style['reminder-arrow-container']}>
        <button disabled={showExtensionCallModal} className={`paginate-arrow paginate-arrow-left ${style['btn-outline']}`} onClick={() => cycleReminders(1)} />
        <button disabled={showExtensionCallModal} className={`paginate-arrow paginate-arrow-right ${style['btn-outline']}`} onClick={() => cycleReminders(-1)} />
      </div>
      <div className={style['reminder-header']}>
        <span className={style['reminder-title']}>
          <FormattedMessage id="reminder.header" defaultMessage="LEMBRETE PARA O ATENDIMENTO" />
          {selectedReminder && selectedReminder.attend}
        </span>
        <span className={style['reminder-subtitle']}>
          {selectedReminder && selectedReminder.client_full_name}
        </span>
      </div>
      {selectedReminder && <ReminderInfo reminder={selectedReminder} />}
      <div className={style['reminder-text-container']} >
      <label className={style['reminder-text-label']} htmlFor="comments">
          <FormattedMessage id="reminder.reminderReport" defaultMessage="Relatório" />:
        </label>
        <div className={style['reminder-text-block']}>
          <textarea
            id="comments"
            type="textarea"
            name="comments"
            className={style['reminder-textarea']}
            value={report}
            maxLength="1024"
            onChange={updateReport}
            rows="4"
            cols="50"
          />
        </div>
        <label className={style['reminder-text-limit']} htmlFor="title">
          {limit}
        </label>
      </div>
      {selectedReminder?.contacts_info.length > 0 &&
        <div className={style['reminder-info-container']}>
          <label className={style['reminder-text-label']} htmlFor="contacts">
            <FormattedMessage id="reminder.reminderContactList" defaultMessage="Lista de Contatos" />:
          </label>
          <div className={style['reminder-contacts-wrapper']}>
            {selectedReminder && selectedReminder.contacts_info.map((contact, index) => (
              <ContactInfo contact={contact} index={index} lang={lang}/>
              ))}
          </div>
        </div>
      }
      <ReminderControls
        disabled={disabled}
        closeReminder={closeReminder}
        rescheduleReminder={rescheduleReminder}
        openExtensionCaller={() => setShowExtensionCallModal(!showExtensionCallModal)}
        toggleReturnCallModal={() => setShowReturnCallModal(!showReturnCallModal)}
        canReceiveCall={selectedReminder?.client_can_receive_call}
      />
    </div>
  )

}

ReminderWrapperComponent.propTypes = {
  hideReminder: PropTypes.bool.isRequired,
  channelId: PropTypes.string.isRequired,
  openReminderComponent: PropTypes.func.isRequired,
  toggleHasReminders: PropTypes.func.isRequired,
  setReminderAttendId: PropTypes.func.isRequired,
  openReminderNote: PropTypes.func.isRequired,
  startCall: PropTypes.func.isRequired,
  sendPERSPassword: PropTypes.func.isRequired,
  hangupSip: PropTypes.func.isRequired,
}

export default ReminderWrapperComponent;
