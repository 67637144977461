import React from 'react';
import PropTypes from 'prop-types';

const User = ({ height, svgClass, width, fill }) => (
  <svg className={svgClass} fill={fill} width={width} height={height} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);

User.defaultProps = {
  height: '24',
  svgClass: '',
  width: '24',
  fill: '#000000'
};

User.propTypes = {
  height: PropTypes.string,
  svgClass: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
};

export default User;
