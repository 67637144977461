export const email = value => {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(value);
}

export const cpf = value => {
  let strCPF,
      Soma,
      Resto;
  if(/^\d\d\d[.]\d\d\d[.]\d\d\d-\d\d$/.test(value)){
      let [, first, second, third, fourth] =
              /^(\d\d\d)[.](\d\d\d)[.](\d\d\d)-(\d\d)$/
              .exec(value);

      strCPF = first + second + third + fourth; 
  }
  else{
      strCPF = value;
  }
  Soma = 0;
  if (strCPF == "00000000000") return false;
  
  for (let i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;
  
  if ((Resto == 10) || (Resto == 11))  Resto = 0;
  if (Resto != parseInt(strCPF.substring(9, 10)) ) return false;
  
  Soma = 0;
  for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;
  
  if ((Resto == 10) || (Resto == 11))  Resto = 0;
  if (Resto != parseInt(strCPF.substring(10, 11) ) ) return false;
  return true;
};

export const date = value => {
  let maxYear = new Date().getFullYear() - 18;
  if(/^\d\d\d\d-\d\d-\d\d$/.test(value)){
    var [, year, month, day] = /^(\d\d\d\d)-(\d\d)-(\d\d)$/.exec(value) 
    return `${day}/${month}/${year}` 
  } else if(/^\d\d[/]\d\d[/]\d\d\d\d$/.test(value)) {
    var [, day, month, year] = /^(\d\d)[/](\d\d)[/](\d\d\d\d)$/.exec(value)    
    var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ]
    
    // Adjust for leap years
    if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
      monthLength[1] = 29
    if(year <= maxYear && month > 0 && month <= 12 && day > 0 && day < monthLength[month-1]){
      return true
    } else {
      if(day < 1){
        day = "01"
      }
      else if(day > monthLength[month-1]){
        day = monthLength[month-1]
      }
      if(month < 1){
        month = "01"
      }
      else if(month > 12){
        month = 12
      }
      if(year > maxYear){
        year = maxYear
      }
      return `${day}/${month}/${year}`
    }  
  }
};

export const onlyDigit = value => {
  if(typeof value == "number"){
    value = value.toString()
  }
  let arr = value.split(''),
    val = arr.filter((el) => /\d/.test(el)).join('')

  if(val !== value){
    return val
  }
  else{
    return true
  }
};

// module.exports = {
// 	// regex from http://stackoverflow.com/questions/46155/validate-email-address-in-javascript
// 	email: function(value) {
//     var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//     return re.test(value);
//   	},

//   	cpf: function(cpf){
//         let strCPF,
//             Soma,
//             Resto;
//         if(/^\d\d\d[.]\d\d\d[.]\d\d\d-\d\d$/.test(cpf)){
//             let [, first, second, third, fourth] =
//                     /^(\d\d\d)[.](\d\d\d)[.](\d\d\d)-(\d\d)$/
//                     .exec(cpf);

//             strCPF = first + second + third + fourth; 
//         }
//         else{
//             strCPF = cpf;
//         }

//             Soma = 0;
//             if (strCPF == "00000000000") return false;
            
//             for (let i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
//             Resto = (Soma * 10) % 11;
            
//             if ((Resto == 10) || (Resto == 11))  Resto = 0;
//             if (Resto != parseInt(strCPF.substring(9, 10)) ) return false;
            
//             Soma = 0;
//             for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
//             Resto = (Soma * 10) % 11;
            
//             if ((Resto == 10) || (Resto == 11))  Resto = 0;
//             if (Resto != parseInt(strCPF.substring(10, 11) ) ) return false;
//             return true;
//     },

//     // http://stackoverflow.com/questions/6177975/how-to-validate-date-with-format-mm-dd-yyyy-in-javascript
//     date: function(value){
//     	let maxYear = new Date().getFullYear() - 18
//         if(/^\d\d\d\d-\d\d-\d\d$/.test(value)){
//             var [, year, month, day] = /^(\d\d\d\d)-(\d\d)-(\d\d)$/.exec(value) 
//             return `${day}/${month}/${year}` 
//         }
//     	else if(/^\d\d[/]\d\d[/]\d\d\d\d$/.test(value)){
//             var [, day, month, year] = /^(\d\d)[/](\d\d)[/](\d\d\d\d)$/.exec(value)    
//             var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ]

//             // Adjust for leap years
//             if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
//                 monthLength[1] = 29

//             if(year <= maxYear && month > 0 && month <= 12 && day > 0 && day < monthLength[month-1]){
//                 // return ""
//                 return true
//             }
//             else{
//                 if(day < 1){
//                   day = "01"
//                 }
//                 else if(day > monthLength[month-1]){
//                     day = monthLength[month-1]
//                 }
//                 if(month < 1){
//                     month = "01"
//                 }
//                 else if(month > 12){
//                     month = 12
//                 }
//                 if(year > maxYear){
//                     year = maxYear
//                 }
//                 return `${day}/${month}/${year}`
//             }  
//     	}
    	

//     	// return year <= maxYear && month > 0 && month <= 12 && day > 0 && day < monthLength[month-1]

//     },

//     onlyDigit(value){
//         if(typeof value == "number"){
//             value = value.toString()
//         }
//         let arr = value.split(''),
//             val = arr.filter((el) => /\d/.test(el)).join('')

//         if(val !== value){
//             return val
//         }
//         else{
//             return true
//         }
//     }

// }
