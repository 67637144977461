import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import style from './reportButtonComponent.module.scss';

const Button = ({ text, url }) => (
  <Link
    className={style.btn}
    to={url}
    style= {{marginTop: '0.5rem'}}
  >
  <i className="fas fa-file-alt"></i>  {text}
  </Link>
);

Button.defaultProps = {
  url: '/',
  text: 'botão',
};

Button.propTypes = {
  url: PropTypes.string,
  text: PropTypes.object,
};

export default Button;
