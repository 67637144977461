import React from 'react';
import PropTypes from 'prop-types';

const TransexIcon = ({ height, className, width, fill, transform }) =>
  <svg
    className={className}
    width={width}
    height={height}
    fill={fill}
    transform={transform}
    viewBox="0 0 140 140"
    xmlns="http://www.w3.org/2000/svg"
  >
   <path d="M58,95.6c-4.8,0-9.4,0-14.3,0c5.3-17,10.4-33.7,15.6-50.4c-2-0.7-2.4-0.5-3,1.4C53.6,54.8,51,63,48.4,71.3
				c-0.3,1.1-0.7,2.1-1.2,3.1c-1.3,2.4-3.5,3.3-6.1,2.5c-2.4-0.7-3.4-2.3-2.7-4.8c3.2-10.8,6.4-21.6,9.8-32.4
				c2-6.3,8.4-10.8,15.1-10.7c7,0.1,14,0.2,21,0.3c7.5,0.1,13.8,6,13.9,13.5c0.2,11.7,0,23.5-0.1,35.2c0,2.9-1.4,4.3-4.1,4.5
				c-3.1,0.3-5-0.9-5.6-3.7c-0.2-0.8-0.2-1.7-0.3-2.6c0-9.7,0-19.5,0-29.2c0-2,0-2-2.2-1.7c0,0.6-0.1,1.3-0.1,1.9c0,27,0,54-0.1,81
				c0,1.6,0,3.2-0.2,4.7c-0.7,4.5-5.5,6.7-9.2,4.4c-1.4-0.9-2.1-2.1-2.1-3.7c-0.1-2.8-0.2-5.5-0.2-8.3c0-9.3,0-18.6,0.1-27.9
				c0-1.6-0.4-2-2-1.9c-3,0.2-3,0.1-3,3.1c0,11.3,0.1,22.6,0.2,33.8c0,3.3-1.6,5.2-4.7,5.5c-3.6,0.3-5.8-1.1-6.1-4.6
				c-0.3-3-0.2-6.1-0.2-9.2c-0.1-9.1-0.1-18.2-0.2-27.2C58.1,96.7,58,96.3,58,95.6z"/>
			<path d="M73.3,27.4c-6.7,0-12-5.4-12-12.1c0-6.7,5.4-12,12.1-12c6.7,0,12.1,5.4,12,12.1C85.4,22.1,80,27.4,73.3,27.4z"/>
  </svg>;

TransexIcon.defaultProps = {
  height: '24',
  className: '',
  width: '24',
  fill: '#00b8cb',
  transform: 'translate(0,0)',
};

TransexIcon.propTypes = {
  height: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
  transform: PropTypes.string,
};

export default TransexIcon;
