import React from 'react';
import PropTypes from 'prop-types';

const AlarmGeo3OutIco = ({ height, svgClass, width, fill, transform }) => (
  <svg className={svgClass} width={width} height={height} fill={fill} transform={transform} viewBox="0 0 140 140" xmlns="http://www.w3.org/2000/svg">
<g>
	<g>
		<path d="M70.9,132.2h-0.2c-3.3,0-6.5-0.2-9.7-0.7l0.6-3.9c3,0.5,6.1,0.7,9.1,0.7h0.2V132.2z M80.9,131.4l-0.6-3.8
			c3.1-0.5,6.2-1.2,9.1-2.2l1.2,3.7C87.5,130.1,84.2,130.9,80.9,131.4z M51.2,129.2c-3.2-1-6.3-2.3-9.3-3.8l1.8-3.5
			c2.8,1.4,5.7,2.6,8.7,3.6L51.2,129.2z M99.9,125.2l-1.8-3.5c2.8-1.4,5.5-3.1,8-4.9l2.3,3.1C105.7,121.9,102.8,123.7,99.9,125.2z
			 M33.4,120.2c-2.7-1.9-5.3-4.1-7.6-6.5l2.7-2.8c2.2,2.2,4.6,4.2,7.2,6.1L33.4,120.2z M19.2,106.2c-2-2.7-3.7-5.5-5.3-8.5l3.5-1.8
			c1.4,2.8,3.1,5.5,5,8L19.2,106.2z M127.7,97.2l-3.5-1.8c1.4-2.8,2.6-5.7,3.6-8.7l3.7,1.2C130.5,91.2,129.2,94.3,127.7,97.2z
			 M10.1,88.5c-1-3.2-1.8-6.4-2.4-9.7l3.8-0.6c0.5,3.1,1.3,6.2,2.2,9.1L10.1,88.5z M133.8,78.2l-3.8-0.6c0.5-3,0.7-6.2,0.7-9.3v-0.1
			h3.9v0.1C134.5,71.7,134.3,75,133.8,78.2z M6.9,68.8l0-0.4c0-3.2,0.2-6.4,0.7-9.6l3.9,0.6c-0.4,3-0.7,6-0.7,9l0,0.4L6.9,68.8z
			 M129.9,58.9c-0.5-3.1-1.2-6.2-2.2-9.1l3.7-1.2c1,3.2,1.8,6.4,2.3,9.7L129.9,58.9z M13.5,50.2l-3.7-1.2c1-3.2,2.3-6.3,3.8-9.3
			l3.5,1.7C15.7,44.3,14.5,47.3,13.5,50.2z M124.1,41.1c-1.4-2.8-3.1-5.5-4.9-8l3.1-2.3c2,2.7,3.7,5.6,5.2,8.5L124.1,41.1z M22,33.5
			l-3.2-2.3c1.9-2.7,4.1-5.3,6.5-7.7l2.8,2.7C25.8,28.5,23.8,30.9,22,33.5z M113.1,26c-2.2-2.2-4.6-4.3-7.2-6.1l2.3-3.2
			c2.7,2,5.3,4.1,7.6,6.5L113.1,26z M35.1,20.1L32.8,17c2.7-2,5.5-3.8,8.5-5.3l1.8,3.5C40.3,16.6,37.6,18.3,35.1,20.1z M97.9,15
			c-2.8-1.4-5.7-2.6-8.7-3.6l1.2-3.7c3.2,1,6.3,2.3,9.3,3.8L97.9,15z M51.7,11.5l-1.2-3.7c3.2-1.1,6.4-1.9,9.7-2.4l0.6,3.8
			C57.8,9.8,54.7,10.5,51.7,11.5z M80.1,9.2c-3.1-0.5-6.2-0.7-9.4-0.7l-0.5,0l0-3.9l0.5,0c3.3,0,6.7,0.3,10,0.8L80.1,9.2z"/>
	</g>
</g>
<g id="lXSZgC_1_">
	<g>
		<g>
			<path d="M32.4,64.6c0.7,2.9,1.4,5.4,2,8c0.3,1.1,0.5,2.3,0.9,3.4c0.3,0.9,0,1.5-0.6,2.2c-2.3,2.4-4.5,4.9-6.8,7.3
				c-2,2.1-3.2,4.5-3.8,7.4c-1.1,5-2.4,9.9-3.7,14.9c-0.8,3.1,1.1,6.3,4.1,7c3.3,0.8,6.4-1,7.2-4.2c1.2-4.6,2.3-9.1,3.4-13.7
				c0.6-2.4,2.2-4.3,3.9-6.1c3.1-3.3,6.1-6.7,9.2-10c3.6-3.8,4.4-8.1,3-13.1c-0.9-3.1-1.6-6.2-2.4-9.6c3.4,1.5,7,1.9,8.5,5.8
				c0.4,1,1,2.1,1.8,2.9c1.9,2,4.9,2.3,7.1,0.9c2.4-1.5,3.5-4.4,2.4-7c-2.1-5.4-5.4-9.7-11-11.7c-4-1.5-7.9-3-12-4
				c-10.1-2.4-20.3,3.5-23.5,13.4c-0.1,0.4-0.3,0.8-0.4,1.2c-0.5,3.2-2.3,5.3-5.4,6.4c-2.4,0.9-3.6,2.9-3.5,5.5c0,4,4.1,6.9,7.8,5.4
				c2.1-0.8,4.1-2,6.1-3.1c2.4-1.4,3.8-3.6,4.7-6.2C31.8,66.6,32.1,65.8,32.4,64.6z"/>
			<path d="M54.5,82.6c-2.7,3-5.4,5.9-8,8.7c-0.6,0.6-0.8,1.1-0.4,1.9c0.6,1.4,1.2,2.8,1.7,4.3c0.8,2.2,2.1,3.9,3.7,5.5
				c3.4,3.4,6.7,6.7,10.1,10.1c3.3,3.2,8.4,2,9.9-2.3c0.8-2.3,0.1-4.3-1.5-5.9c-2.5-2.6-5-5.2-7.7-7.7c-2.1-2-3.8-4.3-4.8-7.1
				C56.6,87.6,55.5,85.1,54.5,82.6z"/>
			<path d="M43.3,32.8c0-4.8-4-8.9-8.8-8.8c-5.4,0.1-8.8,4.2-8.9,8.8c0,4.8,4,8.8,8.8,8.8C39.3,41.7,43.3,37.6,43.3,32.8z"/>
		</g>
	</g>
</g>
<path d="M117.4,100.7c-11.1,0-20,9-20,20s9,20,20,20s20-9,20-20S128.4,100.7,117.4,100.7z M117.5,131.8c-4.8,0-8.1-2.9-8-7.9h4.2
	c0.1,2.3,1.3,4.3,3.8,4.3c2,0,3.5-1.4,3.5-3.3c0-3.2-2.9-3.2-5-3.2v-3.1c1.8,0.1,4.6-0.2,4.6-2.7c0-1.8-1.5-2.9-3-2.9
	c-2.2,0-3.3,1.6-3.3,3.8h-4.2c0.1-4.4,3-7.5,7.5-7.5c3.5,0,7.3,2.1,7.3,6c0,2.1-1,3.8-3,4.4v0.1c2.4,0.6,3.8,2.5,3.8,4.8
	C125.6,129.3,121.8,131.8,117.5,131.8z"/>
  </svg>
);

AlarmGeo3OutIco.defaultProps = {
  height: '75',
  svgClass: '',
  width: '75',
  fill: '#008D53',
  transform: 'translate(0,0)',
};

AlarmGeo3OutIco.propTypes = {
  height: PropTypes.string,
  svgClass: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
  transform: PropTypes.string,
};

export default AlarmGeo3OutIco;
