import React from 'react';
import PropTypes from 'prop-types';
import messages from '../../BLL/locale/messages';

class SelectInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      select: this.props.dictionary ? this.props.dictionary(this.props.value, 'fromServer') : this.props.value,
      items: [],
      disabled: this.props.disabled ? this.props.disabled : false,
      selectClass: this.props.selectClass ? this.props.selectClass : '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.setItems = this.setItems.bind(this);
  }

  componentDidMount() {
    if (typeof this.props.items === 'string') {
      const items = this.setDictionary(this.props.items.split('-'));
      this.setItems(items);
    } else {
      const items = this.setDictionary(this.props.items);
      this.setItems(items);
    }
  }

  UNSAFE_componentWillReceiveProps({ items, value, disabled }) {
    if (value !== this.props.value) {
      this.setState({ select: this.props.dictionary ? this.props.dictionary(value, 'fromServer') : value });
    }
    if (items !== this.props.items) {
      if (typeof this.props.items === 'string') {
        this.setState({
          items: this.setDictionary(this.props.items.split('-')),
        });
      } else {
        this.setState({
          items: this.setDictionary(items),
        });
      }
    }
    if (disabled !== this.props.disabled) {
      this.setState({
        disabled,
      });
    }
  }

  setItems(items) {
    this.setState({
      items,
    });
  }

  setDictionary(arr) {
    if (this.props.dictionary) {
      return arr.map(el => this.props.dictionary(el, 'fromServer'));
    }

    return arr;
  }

  handleChange(event) {
    let val = event.target.value;
    this.setState({
      select: val,
    });
    if (this.props.dictionary) {
      val = this.props.dictionary(val, 'toServer');
    }
    const ev = { target: { value: val, name: event.target.name } };

    this.props.onChange(ev, event.target.value !== '', this.props.index);
  }

  render() {
    const { disabled, items, select, selectClass } = this.state;
    const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
    const other = messages[lang]['general.other'];
    return (
      <select
        id={this.props.id}
        className={selectClass}
        name={this.props.name}
        disabled={disabled}
        value={select}
        onChange={this.handleChange}
      >
        {this.props.hasOther && <option value="-">{other}</option> }
        {this.props.emptyOption && <option value="-" >-</option>}
        {items && items.map(item =>
          <option key={item.code ? item.code : item} value={item.code ? item.code : item} >
            {item.name ? item.name : item}
          </option>,
        )}
      </select>
    );
  }
}

SelectInput.defaultProps = {
  index: 0,
  selectClass: 'form-control',
  disabled: false,
  dictionary: null,
  emptyOption: true,
  hasOther: false,
};

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  selectClass: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  dictionary: PropTypes.func,
  items: PropTypes.oneOfType(
    [
      PropTypes.node,
      PropTypes.array
    ]
  ).isRequired,
  index: PropTypes.number,
  value: PropTypes.node.isRequired,
  emptyOption: PropTypes.bool,
  hasOther: PropTypes.bool,
};

export default SelectInput;
