import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow, MarkerClusterer } from '@react-google-maps/api';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import Loading from '../../../../icons/Loading';
import dotIcon, { firstIcon, lastIcon } from '../../../../icons/trackerIcons';
import messagesTracker from '../../../../BLL/locale/messagesIHelpEventTypes';
import config from '../../../../BLL/config';

import style from './tracker.module.scss';
import '../../../../css/middle.scss';

const MapPoint = (props) => {
  const { pointList, lang, lastGeneralData, equipment } = props;
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: config.googleMapsApiKey
  });
  const [map, setMap] = useState(null);
  const [selectedElement, setSelectedElement] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [showInfoWindow, setShowInfoWindow] = useState(true);
  const center = useMemo(() => {
    if (pointList?.length > 0) {
      return ({
        lat: parseFloat(pointList[0].LATITUDE),
        lng: parseFloat(pointList[0].LONGITUDE),
      })
    }
  }, [pointList]);

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback((map) => {
    setMap(null);
  }, []);

  if (!isLoaded || !pointList?.length > 0) {
    return <Loading text={<FormattedMessage id="attend.list.loading" defaultMessage="Carregando" />} />
  }

  console.log(equipment);

  return (
    <div className={style.mapWrapper}>
      <div className={style.mapHeaderContainer}>
        <div className={style.generalInfoContainer}>
          {lastGeneralData &&
            <div className={style.mapHeaderSubtitleContainer}>
              <span className={style.headerSubtitle}><FormattedMessage id="client.tracker.battery" defaultMessage="Bateria"/>: {lastGeneralData.BATTERY}%</span>|
              <span className={style.headerSubtitle}><FormattedMessage id="client.tracker.signalStrength" defaultMessage="Força do sinal"/>: {lastGeneralData.SIGNAL_STRENGTH}</span>|
              <span className={style.headerSubtitle}>
                <FormattedMessage id="client.tracker.charging" defaultMessage="Carregando"/>: {
                  lastGeneralData.IS_CHARGING ? <FormattedMessage id="general.yes" defaultMessage="Sim" /> : <FormattedMessage id="general.no" defaultMessage="Não" />
                }
              </span>|
              <span className={style.headerSubtitle}>
                <FormattedMessage id="client.tracker.completeCharge" defaultMessage="Carga completa"/>: {
                  lastGeneralData.CHARGE_COMPLETE ? <FormattedMessage id="general.yes" defaultMessage="Sim" /> : <FormattedMessage id="general.no" defaultMessage="Não" />
                }
              </span>|
              <span className={style.headerSubtitle}><FormattedMessage id="client.tracker.lastRead" defaultMessage="Última leitura" />: {moment(parseInt(lastGeneralData.TIMESTAMP, 10) * 1000).format('YYYY-MM-DD HH:mm:ss')}</span>
            </div>
          }
        </div>
          {equipment && equipment.name.toLowerCase().includes('ev07b')
            ?
              <div className={style.mapButtonContainer}>
                <button className={style.updateLocationBtn}  style={{margin : '0.3rem 0'}} onClick={props.sendSingleLocate}>
                  <span style={{ paddingRight: '1rem'}}><i className="fas fa-redo-alt fa-lg" aria-hidden="true" /></span>
                  <FormattedMessage id="client.tracker.singleLocate" defaultMessage="Atualizar Localização" />
                </button>
                <button className={style.continuousLocationBtn}  style={{margin : '0.3rem 0'}} onClick={props.sendContinuousLocate}>
                  <span style={{ paddingRight: '1rem'}}><i className="fas fa-stopwatch fa-lg" aria-hidden="true" /></span>
                  <FormattedMessage id="client.tracker.continueLocate" defaultMessage="Localização Contínua" />
                </button>
                <button className={style.generalInfoLastBtn}  style={{margin : '0.3rem 0'}} onClick={props.fetchLatestPoint}>
                  <span style={{ paddingRight: '1rem'}}> <i className="fas fa-map-marker-alt fa-lg" aria-hidden="true" /></span><FormattedMessage id="client.tracker.latest" defaultMessage="Última Posição" />
                </button>
              </div>
            :
              <div className={style.mapButtonContainer}>
                <button className={style.generalInfoLastBtn}  style={{margin : '0.3rem 0'}} onClick={props.fetchLatestPoint}>
                  <span style={{ paddingRight: '1rem'}}> <i className="fas fa-map-marker-alt fa-lg" aria-hidden="true" /></span><FormattedMessage id="client.tracker.latest" defaultMessage="Última Posição" />
                </button>
              </div>
          }
      </div>
      <GoogleMap
        mapContainerClassName={style.leafletContainer}
        center={center}
        zoom={16}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          disableDefaultUI: true,
          clickableIcons: false
        }}
      >
        <MarkerClusterer>
          {((clusterer) =>
            pointList.map((point, index) => {
              let sendDate = '';
              if (point.SENDTIME) {
                sendDate = moment(parseInt(point.SENDTIME, 10)).local().format('YYYY-MM-DD HH:mm:ss');
              } else if (point.DATETIME) {
                sendDate = moment(point.DATETIME).local().format('YYYY-MM-DD HH:mm:ss')
              } else {
                sendDate = moment(parseInt(point.TIMESTAMP, 10) * 1000).local().format('YYYY-MM-DD HH:mm:ss');
              }
              let icon = dotIcon;
              if (index === 0) {
                icon = firstIcon;
              } else if (index === pointList.length -1) {
                icon = lastIcon;
              }

              return (
                <Marker
                  icon={icon}
                  clusterer={(index !== 0 && index !== pointList.length -1) && clusterer}
                  key={index}
                  position={{
                    lat: parseFloat(point.LATITUDE),
                    lng: parseFloat(point.LONGITUDE),
                  }}
                  onClick={() => {
                    setSelectedElement(point);
                    setActiveMarker(index);
                  }}
                >
                  {selectedElement && index === activeMarker &&
                    <InfoWindow
                      visible={showInfoWindow}
                      onCloseClick={() => {
                        setSelectedElement(null);
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>
                          <FormattedMessage id="client.tracker.sendTime" defaultMessage="Data de envio" />: {sendDate}
                        </span>
                        {point.BATTERY &&
                          <span>
                            <FormattedMessage id="client.tracker.battery" defaultMessage="Bateria" />: {point.BATTERY}%
                          </span>
                        }
                        {point.ACCURACY &&
                          <span>
                            <FormattedMessage id="client.tracker.accuracy" defaultMessage="Precisão" />: {point.ACCURACY}m
                          </span>
                        }
                        {point.DIRECTION !== undefined &&
                          <span>
                            <FormattedMessage id="client.tracker.direction" defaultMessage="Direção" />: {point.DIRECTION}°
                          </span>
                        }
                        {point.GPSACCURACY &&
                          <span>
                            <FormattedMessage id="client.tracker.accuracy" defaultMessage="Precisão" />: {point.GPSACCURACY}m
                          </span>
                        }
                        {point.ONCHARGER &&
                          <span>
                            <FormattedMessage id="client.tracker.charging" defaultMessage="Carregando" />: {
                              point.ONCHARGER === 'true'
                                ? <FormattedMessage id="general.yes" defaultMessage="Sim" />
                                : <FormattedMessage id="general.no" defaultMessage="Não" />
                            }
                          </span>
                        }
                        {point.ALTITUDE &&
                          <span>
                            <FormattedMessage id="client.tracker.altitude" defaultMessage="Sinal Celular" />: {point.ALTITUDE}
                          </span>
                        }
                        {point.SPEED &&
                          <span>
                            <FormattedMessage id="client.tracker.speed" defaultMessage="Velocidade" />: {point.SPEED} Km/h
                          </span>
                        }
                        {point.CELLSIGNAL &&
                          <span>
                            <FormattedMessage id="client.tracker.cellSignal" defaultMessage="Sinal Celular" />: {point.CELLSIGNAL}
                          </span>
                        }
                        {point.EVENTTYPE &&
                          <span>
                            <FormattedMessage id="client.tracker.eventType" defaultMessage="Tipo de Evento" />: {messagesTracker[lang][point.EVENTTYPE]}
                          </span>
                        }
                        {point.FROM_WIFI !== undefined &&
                          <span>
                            <FormattedMessage id="client.tracker.fromWifi" defaultMessage="Tipo do Sinal" />: {point.FROM_WIFI ? 'Wi-Fi' : 'GPS'}
                          </span>
                        }
                        <span>{point.ADDRESS}</span>
                      </div>
                    </InfoWindow>
                  }
                </Marker>
              );
            })
          )}
        </MarkerClusterer>

      </GoogleMap>

    </div>
  );
}

export default MapPoint;
