import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import ContactRow from './ContactRow';
import ContactMobileRow from './ContactMobileRow';
import Order from '../../../icons/Order';
import Paginate from '../../Common/Paginate';
import ToggleButton from '../../Common/ToggleButton';
import ConfirmRemoveModal from '../../Modal/ModalConfirmRemove';
import * as contactsActions from '../../../actions/contactsActions';
import { getDomain, host, getToken } from '../../../BLL/environment';
import { getBrowserMaxWidth, broadcast } from '../../../BLL/general';
import broadcastMessages from '../../../BLL/locale/messagesBroadcast';

import style from './contactPage.module.scss';

import '../../../css/icons.scss';
import '../../../css/middle.scss';
import '../../../css/buttons.scss';
 
import '../../../css/listPage.scss';

const ContactPage = (props) => {
  const browserWidth = getBrowserMaxWidth();
  const numPerPage = 50;
  const [contact, setContact] = useState('');
  const [orderedContacts, setOrderedContacts] = useState([]);
  const [client, setClient] = useState(undefined);
  const [page, setPage] = useState(1);
  const [ordering, setOrdering] = useState('priority');
  const [showModal, setShowModal] = useState(false);
  const [iconDetail, setIconDetail] = useState('');
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    loadClient(parseInt(props.match.params.clientId, 10));
  }, [props.match.params.clientId]);

  useEffect(() => {
    setContactList(ordering);

  }, [props.contacts])

  const loadClient = (clientId) => {
    axios.get(`https://${getDomain()}${host()}/api/clients/${clientId}/`, {
      headers: { Authorization: `Token ${getToken()}` },
    }).then(res => setClient(res.data))
    .catch(err => console.log(err));
  };

  const setModalInfo = (show, newContact) => {
    setShowModal(show);
    setContact(Object.assign({}, newContact));
  };

  const orderByName = () => {
    const newOrdering = ordering === 'first_name,last_name,priority' ? '-first_name,-last_name,-priority' : 'first_name,last_name,priority';
    setOrdering(newOrdering);
    setContactList(newOrdering);
  };

  const orderByPriority = () => {
    const newOrdering = ordering === 'priority' ? '-priority' : 'priority';
    setOrdering(newOrdering);
    setContactList(newOrdering);
  };

  const setContactList = (newOrdering) => {
    console.log('Setting contact list', props.contacts);
    const contactsOrdered = props.contacts
      ? props.contacts.filter(
          c => c.ordering === newOrdering && c.clientId === props.match.params.clientId
        )[0]
      : null;
    console.log(contactsOrdered);
    if (contactsOrdered) {
      setOrderedContacts(contactsOrdered.data);
    } else {
      props.loadContacts(props.match.params.clientId, newOrdering);
    }
  }

  const toggleContact = () => {
    const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
    const serverContact = Object.assign({}, contact, { is_deleted: !contact.is_deleted });
    const text = `${broadcastMessages[lang]['broadcast.contact']} ${serverContact.first_name} ${serverContact.last_name} ${
      serverContact.is_deleted ? broadcastMessages[lang]['broadcast.deleted'] : broadcastMessages[lang]['broadcast.recovered']}`;
    if (serverContact.is_deleted) {
      props.deleteContact(props.match.params.clientId, serverContact).then(() => {
        broadcast(axios, props.channelId, {
          type: 'contact',
          method: 'toggle',
          userId: props.loggedAttendantId,
          clientId: props.match.params.clientId,
          contact: serverContact,
          text,
        });
        props.history.push(`/app/contacts/list/${props.match.params.clientId}`);
      });
    } else {
      props.editContact(props.match.params.clientId, serverContact).then(() => {
        broadcast(axios, props.channelId, {
          type: 'contact',
          method: 'toggle',
          userId: props.loggedAttendantId,
          clientId: props.match.params.clientId,
          contact: serverContact,
          text,
        });
        props.history.push(`/app/contacts/list/${props.match.params.clientId}`);
      });
    }
    setModalInfo(false, '');
  }

  const contacts = orderedContacts;
  const svgNameRotation = ordering === '-first_name,-last_name,-priority' ? 'icons-order--rotate' : '';
  const svgPriorityRotation = ordering === '-priority' ? style.rotate : '';
  return (
    <div className="contactto-middle-content" style={{ overflowX: 'hidden' }}>
      {client &&
        <div className={style.divTitleHeader}>
          <div className={style.title}>
            <FormattedMessage id="contact.page.header" defaultMessage="CONTATOS DE" />
            <span style={{ color: '#b4b4b4', marginLeft: '7px' }}>
              {`${client.first_name} ${client.last_name}`.toUpperCase()}
            </span>
          </div>
        </div>
      }
      {(props.isAdmin || props.isOwner || props.isManager) &&
        <div className={style.divButtonHeader}>
          <Link to={`/app/contacts/form/add/${props.match.params.clientId}`} className={style['btn-add']}>
            <b>+ <FormattedMessage id="general.add" defaultMessage="ADICIONAR" /></b>
          </Link>
          <div className={style['toggle-container']}>
            <ToggleButton isChecked={isChecked} handleChange={() => setIsChecked(!isChecked)} />
          </div>
        </div>
      }
      {contacts &&
        <div className={style.table}>
          <Paginate
            outerClass={style.paginate}
            numberOfElements={contacts.length}
            numPerPage={numPerPage}
            setPage={setPage}
            page={page}
          />
          {showModal &&
            <ConfirmRemoveModal
              removeAction={toggleContact}
              setModalInfo={setModalInfo}
            />
          }
          <div className={style.header}>
            <div className={style.priority}>
              <button
                className={style['header-column']}
                onClick={orderByPriority}
                style={{ color: ordering === 'first_name,last_name,priority' || ordering === '-first_name,-last_name,-priority' ? '#00b8cb' : '#333' }}
              >
                <FormattedMessage id="contact.page.priority" defaultMessage="Prioridade" /><Order width="15" height="10" className={svgPriorityRotation} />
              </button>
            </div>
            <div className={`${style.nameHeader}`}>
              <button
                className={style['header-column']}
                onClick={orderByName}
                style={{ color: ordering === 'first_name,last_name,priority' || ordering === '-first_name,-last_name,-priority' ? '#00b8cb' : '#333' }}
              >
                <FormattedMessage id="contact.page.name" defaultMessage="Nome" /><Order width="15" height="10" className={svgNameRotation} />
              </button>
            </div>
            <div
              className={`${(props.isAdmin || props.isOwner || props.isManager)
                ? style.adminActions : style.otherActions}`}
            >
              {iconDetail}
            </div>
          </div>
          <div className={style['table-body']}>
            {browserWidth > 920 
              ? contacts.map(cont => (cont.is_deleted === false
                || (isChecked === true && cont.is_deleted === true
                  && (props.isAdmin || props.isOwner || props.isManager) === true)
                  ? <ContactRow
                    key={cont.id}
                    contact={cont}
                    showModal={showModal}
                    setTextIconDetail={setIconDetail}
                    setModalInfo={setModalInfo}
                    isAdmin={props.isAdmin}
                    isOwner={props.isOwner}
                    isManager={props.isManager}
                  />
                  : null
              ))
              : contacts.map(cont => (cont.is_deleted === false
                || (isChecked === true && cont.is_deleted === true
                  && (props.isAdmin || props.isOwner || props.isManager) === true)
                  ? <ContactMobileRow
                    key={cont.id}
                    contact={cont}
                    showModal={showModal}
                    setTextIconDetail={setIconDetail}
                    setModalInfo={setModalInfo}
                    isAdmin={props.isAdmin}
                    isOwner={props.isOwner}
                    isManager={props.isManager}
                  />
                  : null
              ))
            }
          </div>
        </div>
      }
    </div>
  );
}

ContactPage.propTypes = {
  channelId: PropTypes.string.isRequired,
  loggedAttendantId: PropTypes.number.isRequired,
  params: PropTypes.shape({
    clientId: PropTypes.string.isRequired,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool.isRequired,
  isManager: PropTypes.bool.isRequired,
  editContact: PropTypes.func.isRequired,
  deleteContact: PropTypes.func.isRequired,
  loadContacts: PropTypes.func.isRequired,
  contacts: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          first_name: PropTypes.string,
          last_name: PropTypes.string,
          priority: PropTypes.number,
          client: PropTypes.number,
          is_deleted: PropTypes.boolean,
        }),
    ).isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    contacts: state.contacts,
    params: ownProps.params,
    loggedAttendantId: state.loggedAttendant.id ? state.loggedAttendant.id : 0,
    isAdmin: state.loggedAttendant.groups ?
            state.loggedAttendant.groups[0] === 1 :
            false,
    isOwner: state.loggedAttendant.groups ?
            state.loggedAttendant.groups[0] === 4 :
            false,
    isManager: state.loggedAttendant.groups ? state.loggedAttendant.groups[0] === 2 : false,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    editContact: (clientId, contact) => dispatch(contactsActions.editContact(clientId, contact)),
    deleteContact: (clientId, contact) => dispatch(contactsActions.deleteContact(clientId, contact)),
    loadContacts: (clientId, ordering, reload) =>
            dispatch(contactsActions.loadContacts(clientId, ordering, reload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactPage);
