import React from 'react';
import PropTypes from 'prop-types';

const AlarmVoiceReqIco = ({ height, svgClass, width, fill, transform }) => (
  <svg className={svgClass} width={width} height={height} fill={fill} transform={transform} viewBox="0 0 140 140" xmlns="http://www.w3.org/2000/svg">
<g id="NsDrZq.tif">
<g>
<g>
<path d="M93,56.6c0,6,0,12,0,17.9c0,9-3.9,15.8-11.8,20c-7.9,4.2-15.7,3.5-23-1.5c-6-4.1-9.3-10.1-9.3-17.4
c-0.2-12.7-0.1-25.5,0-38.2c0-12.7,12.5-23.3,25.1-21.2c10.4,1.7,17.6,8.9,19,19.3c0.3,2.3,0.1,4.7,0.1,7
C93,47.3,93,51.9,93,56.6z"/>
<path d="M65.2,122.8c0-2.7,0-5.2,0-7.6c-2.7-0.7-5.4-1.2-7.9-2.2c-14.8-5.9-23.5-17-26.3-32.4c-1-5.5-0.6-11.3-0.7-17
c0-1.7,1.5-3.2,3.4-3.3c1.4-0.1,2.8-0.1,4.1,0c2.2,0.2,3.5,1.7,3.5,3.9c0.1,4.5-0.1,9,0.3,13.5c1.5,13.9,12.7,26.1,28,26.8
C84,105,97.1,94.8,99.6,80.5c0.8-4.6,0.6-9.4,0.6-14c0.1-5.6,0.6-6.2,6.2-6.2c3.7,0,4.9,1.2,4.8,4.8c-0.1,4.8,0.2,9.6-0.3,14.4
c-1.6,13.9-8.8,24.2-20.9,31.1c-3.9,2.2-8.1,3.6-12.5,4.3c-0.3,0.1-0.7,0.2-1.1,0.3c0,2.5,0,5,0,7.7c1.8,0,3.6,0,5.5,0
c2.4,0,4.7,0,7.1,0c2.5,0,3.9,1.5,4,4c0,1,0,1.9,0,2.9c0,2.6-1.4,4.1-4.1,4.1c-1.9,0-3.8,0-5.7,0c-9.9,0-19.8-0.1-29.8,0
c-2.7,0-5.1-1.2-4.7-4.7c0.1-0.8,0-1.5,0-2.3c0-2.6,1.4-4.1,4.1-4.1C56.9,122.8,61,122.8,65.2,122.8z"/>
</g>
</g>
</g>
  </svg>
);

AlarmVoiceReqIco.defaultProps = {
  height: '75',
  svgClass: '',
  width: '75',
  fill: '#3D94CC',
  transform: 'translate(0,0)',
};

AlarmVoiceReqIco.propTypes = {
  height: PropTypes.string,
  svgClass: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
  transform: PropTypes.string,
};

export default AlarmVoiceReqIco;
