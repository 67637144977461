import React from 'react';
import PropTypes from 'prop-types';

const AlarmAtHomeIco = ({ height, svgClass, width, fill, transform }) => (
  <svg className={svgClass} width={width} height={height} fill={fill} transform={transform} viewBox="0 0 140 140" xmlns="http://www.w3.org/2000/svg">
<path d="M124.3,67.5l-17.6-14.1c0,0,0,0,0,0L92.2,41.7c0,0,0,0,0,0c0,0,0,0,0,0L71.7,25.2c-0.8-0.7-2-0.7-2.8,0L48.5,41.7
	c0,0,0,0,0,0L34.5,53c0,0,0,0,0,0L16.9,67.2c-0.7,0.6-1,1.6-0.7,2.5c0.3,0.9,1.2,1.5,2.1,1.5h15.4V116c0,1.2,1,2.2,2.2,2.2h69.4
	c1.2,0,2.2-1,2.2-2.2V71.3l15.4,0.1c0,0,0,0,0,0c0.9,0,1.8-0.6,2.1-1.5C125.3,69.1,125,68.1,124.3,67.5z M105.3,66.9
	C105.3,66.9,105.3,66.9,105.3,66.9c-0.2,0-0.3,0-0.5,0c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.2,0-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1
	c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.1,0.1-0.2,0.2
	c0,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1-0.1,0.2c0,0.1-0.1,0.2-0.1,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2
	c0,0,0,0,0,0c0,0,0,0,0,0v44.7H38.1V68.9c0-1.2-1-2.2-2.2-2.2H24.6l12.7-10.2c0,0,0,0,0,0l14-11.3c0,0,0,0,0,0c0,0,0,0,0,0l19-15.4
	l19.1,15.4c0,0,0,0,0,0l14.5,11.7c0,0,0,0,0,0c0,0,0,0,0,0l12.6,10.1L105.3,66.9z"/>
<g id="kIU9vP.tif">
	<g>
		<g>
			<path d="M63,106.5c0-1.6,0-3.2,0-4.8c0.1-2,0.1-3.8-2.1-5.1c-1-0.6-1.6-2.5-1.7-3.8c-0.3-4.5-0.5-9.1-0.5-13.6
				c0-5,2.9-7.5,7.9-7.1c2.7,0.2,5.4,0.2,8,0c5.1-0.5,8.1,2.1,8.1,7.2c0,3.9,0.3,7.9-0.3,11.7c-0.3,2.3-2,4.4-3,6.6
				c-0.4,0.9-1.1,1.8-1.1,2.7c-0.1,4.1,0,8.2-0.1,12.4c-0.1,3.8-1.2,4.9-4.9,5c-2,0-4,0-6,0c-2.7,0-4.1-1.5-4.2-4.1
				C62.9,111.1,63,108.8,63,106.5z"/>
			<path d="M62.9,60.9c0.1-4.4,3.7-8,8-7.8c3.8,0.1,7.6,4,7.6,7.9c0.1,4.1-4,8.3-8.1,8C66.7,68.6,62.3,65.4,62.9,60.9z"/>
		</g>
	</g>
</g>
  </svg>
);

AlarmAtHomeIco.defaultProps = {
  height: '75',
  svgClass: '',
  width: '75',
  fill: '#3D94CC',
  transform: 'translate(0,0)',
};

AlarmAtHomeIco.propTypes = {
  height: PropTypes.string,
  svgClass: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
  transform: PropTypes.string,
};

export default AlarmAtHomeIco;
