import React, { Component } from 'react';
import style from './arrow.scss';

export default class Arrow extends Component {
  constructor(props) {
    super(props);

    this.renderArrow = this.renderArrow.bind(this);
    this.clickLeftArrow = this.clickLeftArrow.bind(this);
    this.clickRightArrow = this.clickRightArrow.bind(this);
    this.calcHeadersSize = this.calcHeadersSize.bind(this);
  }

  calcHeadersSize() {
    if (this.props.headers.length === 1) {
      return 46;
    } else if (this.props.headers.length === 2) {
      return 60;
    }
    return 80;
  }

  clickLeftArrow() {
    this.props.onClick();
  }

  clickRightArrow() {
    this.props.onClick();
  }

  renderArrow() {
    if (this.props.side === 'left') {
      return (
        <div className={style.arrowWrapper} onClick={this.clickLeftArrow}>
          <svg
            width={this.calcHeadersSize()}
            height={this.calcHeadersSize()}
            viewBox="0 0 50 80"
            style={{ marginTop: this.props.marginTop }}
          >
            <polyline
              className={style.poligon}
              fill="none"
              stroke="#FFFFFF"
              strokeWidth="1"
              strokeLinecap="round"
              strokeLinejoin="round"
              points="45.63,75.8 0.375,38.087 45.63,0.375 "
            />
          </svg>
        </div>
      );
    }
    return (
      <div onClick={this.clickRightArrow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={this.calcHeadersSize()}
          height={this.calcHeadersSize()}
          viewBox="0 0 50 80"
          style={{ marginTop: this.props.marginTop }}
        >
          <polyline
            className={style.poligon}
            fill="none"
            stroke="#FFFFFF"
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
            points="0.375,0.375 45.63,38.087 0.375,75.8 "
          />
        </svg>
      </div>
    );
  }

  render() {
    return this.renderArrow();
  }
}
