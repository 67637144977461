import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import { registerLocale } from 'react-datepicker';
import br from 'date-fns/locale/pt-BR';
import us from 'date-fns/locale/en-US';
import moment from 'moment';
import { host, getDomain, getToken } from '../../../../BLL/environment';
import ProgrammingComponent from './TrackeriHelpProgram';
import Geofence from './TrackeriHelpGeofence';
import InNumbers from './TrackeriHelpInNumbers';
import MedicationReminders from './TrackeriHelpMedications';
import MapPoint from './TrackerMapPoint';
import HistoryMenu from './TrackerHistoryMenu';
import CustomModal from '../../../Modal/CustomModal';
import Order from '../../../../icons/Order';
import * as clientsActions from '../../../../actions/clientsActions';

import style from './tracker.module.scss';
import '../../../../css/middle.scss';
import './stylesheets/datepicker-cssmodules.scss';

registerLocale('pt-br', br);
registerLocale('en-us', us);

const TrackeriHelp = (props) => {
  const [startDate, setStartDate] = useState(moment().locale('pt-br').subtract(1, 'hours').toDate());
  const [endDate, setEndDate] = useState(moment().locale('pt-br').toDate());
  const [pointList, setPointList] = useState([]);
  const [trackerParams, setTrackerParams] = useState({});
  const [client, setClient] = useState(undefined);
  const [programming, setProgramming] = useState({});
  const [showMap, setShowMap] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [showProgramming, setShowProgramming] = useState(false);
  const [showInNumbers, setShowInNumbers] = useState(false);
  const [showGeofence, setShowGeofence] = useState(false);
  const [showMedicationsMenu, setShowMedicationsMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [disableProgramming, setDisableProgramming] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    fetchClient(parseInt(props.match.params.clientId, 10));
  }, [props.match.params.clientId]);

  useEffect(() => {
    if (client) {
      fetchLatestPoint();
      fetchParams();
    }
  }, [client])

  const fetchClient = (clientId) => {
    axios.get(`https://${getDomain()}${host()}/api/clients/${clientId}/`, {
      headers: { Authorization: `Token ${getToken()}` },
    }).then(res => setClient(Object.assign({}, res.data)))
    .catch(error => console.log(error));
  };

  const fetchLatestPoint = () => {
    axios({
      url: `https://${getDomain()}${host()}/api/clients/${client.id}/tracker-iot/last/`,
      method: 'GET',
      responseType: 'json',
      headers: { Authorization: `Token ${getToken()}` },
    }).then((res) => {
      if (res.data.length > 0) {
        setPointList(res.data);
        setErrorMessage('');
      } else {
        const newErrorMessage = 'ESTE DISPOSITIVO NÃO TEM DADOS PARA O DIA DE HOJE, VERIFIQUE A BATERIA DO SEU SISTEMA';
        const errorComponent = (<FormattedMessage
          id="client.tracker.errorEmptyPoint"
          defaultMessage={newErrorMessage}
        />);
        setPointList([]);
        setErrorMessage(errorComponent);
        disableProgramming(true);
      }
    }).catch((err) => {
      let newErrorMessage = '';
      let errorComponent = {};
      if (err.response.data.imei) {
        newErrorMessage = 'ESTE USUÁRIO NÃO ESTÁ COM O IMEI CADASTRADO.\n';
        errorComponent = <FormattedMessage id="client.tracker.errorNoIMEI" defaultMessage={newErrorMessage} />;
      } else if (err.response.data.noData) {
        newErrorMessage = 'NENHUM PONTO ACHADO PARA ESTE DISPOSITIVO. \n VERIFIQUE SE ESTE RASTREADOR ESTÁ LIGADO';
        errorComponent = <FormattedMessage id="client.tracker.ihelpNoData" defaultMessage={newErrorMessage} />;
      } else {
        newErrorMessage = 'ERRO INESPERADO';
        errorComponent = <FormattedMessage id="client.tracker.errorUnexpected" defaultMessage={newErrorMessage} />;
      }
      setErrorMessage(errorComponent);
    });
  };

  const fetchParams = () => {
    axios({
      url: `https://${getDomain()}${host()}/api/clients/${client.id}/tracker-iot/program/`,
      method: 'GET',
      responseType: 'json',
      headers: { Authorization: `Token ${getToken()}` },
    }).then((res) => {
      if (res.data.length > 0) {
        setDisableProgramming(false);
        setTrackerParams(JSON.parse(res.data[0].MESSAGE));
        setProgramming(res.data[0]);
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  const fetchPoints = () => {
    const startDateString = moment(startDate).format('YYYY-MM-DD');
    const endDateString = moment(endDate).format('YYYY-MM-DD');
    const params = `?start_date=${startDateString}&start_hour=${startDate.getHours()}&end_date=${endDateString}&end_hour=${endDate.getHours()}`;
    if (client) {
      axios({
        url: `https://${getDomain()}${host()}/api/clients/${client.id}/tracker-iot/${params}`,
        method: 'GET',
        responseType: 'json',
        headers: { Authorization: `Token ${getToken()}` },
      }).then((res) => {
        if (res.data.length > 0) {
          setPointList(res.data);
          setErrorMessage('');
        } else {
          const newErrorMessage = 'SEM DADOS PARA AS DATAS ENTRE {startDate} AS {startHour} HORAS E {endDate} AS {endHour} HORAS';
          const errorComponent = (<FormattedMessage
            id="client.tracker.errorEmptyPointsDates"
            defaultMessage={newErrorMessage}
            values={{
              startDate: startDateString,
              endDate: endDateString,
              startHour: startDate.getHours(),
              endHour: endDate.getHours(),
            }}
          />);
          setPointList([]);
          setErrorMessage(errorComponent);
        }
      }).catch(err => console.log(err));
    }
  };

  const sendParams = (params) => {
    if (client) {
      axios({
        url: `https://${getDomain()}${host()}/api/clients/${client.id}/tracker-iot/program/`,
        method: 'PUT',
        responseType: 'json',
        data: params,
        headers: { Authorization: `Token ${getToken()}` },
      }).then((res) => {
        if (res.data) {
          setShowModal(true);
        }
      }).catch(err => console.log(err));
    }
  };

  const openMap = () => {
    setShowMap(!showMap);
    setShowGeofence(false);
  };

  const openHistory = () => {
    setShowHistory(!showHistory);
    setShowGeofence(false);
  }

  const openGeofence = () => {
    setShowGeofence(!showGeofence);
    setShowHistory(false);
  }

  const canProgram = props.loggedAttendantGroup !== 3;
  const rotateMap = showMap ? style.rotate : '';
  const rotateHistory = showHistory ? style.rotate : '';
  const rotateProgram = showProgramming ? style.rotate : '';
  const rotateGeofences = showGeofence ? style.rotate : '';
  const rotateInNumbers = showInNumbers ? style.rotate : '';
  const rotateMedications = showMedicationsMenu ? style.rotate : '';
  const fullName = client ? `${client.first_name} ${client.last_name}`.toUpperCase() : '';

  return (
    <div>
      <div className={showModal ? style.modal : style.hideModal}>
        <CustomModal
          text={<FormattedMessage id="client.tracker.configsSaved" defaultMessage="Configurações salvas com sucesso!" />}
          btnText="OK"
          btnAction={() => setShowModal(false)}
        />
      </div>
      <div className="contactto-middle-content">
        <div className={style.trackerTitle}>
          <span className="blue"><FormattedMessage id="client.tracker.trackerFrom" defaultMessage="RASTREADOR DE " /></span>
          <span className="grey">{fullName}</span>
        </div>
        <div className={style.trackerContent}>
          <div className={style.trackerMenu}>
            <div style={{ width: '100%', float: 'left' }}>
              <button className={style.trackerMenuOption} onClick={openMap}><FormattedMessage id="client.tracker.mapHeader" defaultMessage="Mapa" /><Order width="25" height="15" className={`${style.trackerSvg} ${rotateMap}`} /></button>
            </div>
            {showMap &&
              <MapPoint
                pointList={pointList}
                lang={!localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang}
                fetchLatestPoint={fetchLatestPoint}
              />
            }
            <div style={{ width: '100%', float: 'left', marginTop: '25px' }}>
              <button className={style.trackerMenuOption} onClick={openHistory}><FormattedMessage id="client.tracker.history" defaultMessage="Histórico" /><Order width="25" height="15" className={`${style.trackerSvg} ${rotateHistory}`} /></button>
            </div>
            {showHistory &&
              <HistoryMenu
                startDate={startDate}
                endDate={endDate}
                handleStartChange={setStartDate}
                handleEndChange={setEndDate}
                fetchPoints={fetchPoints}
                lang={!localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang}
              />
            }
            {canProgram &&
              <div style={{ width: '100%', float: 'left', marginTop: '25px' }}>
                <button
                  className={`${style.trackerMenuOption} ${disableProgramming ? style.disabled : ''}`}
                  onClick={() => setShowProgramming(!showProgramming)}
                  disabled={disableProgramming}
                >
                  <FormattedMessage id="client.tracker.programming" defaultMessage="Programação" /><Order width="25" height="15" className={`${style.trackerSvg} ${rotateProgram}`} />
                </button>
              </div>
            }
            {showProgramming &&
              <ProgrammingComponent params={trackerParams} sendParams={sendParams} />
            }
            {canProgram &&
              <div style={{ width: '100%', float: 'left', marginTop: '25px' }}>
                <button
                    className={`${style.trackerMenuOption} ${disableProgramming ? style.disabled : ''}`}
                    onClick={() => setShowInNumbers(!showInNumbers)}
                    disabled={disableProgramming}
                  >
                    <FormattedMessage id="client.tracker.inNumbers" defaultMessage="Números de entrada" /><Order width="25" height="15" className={`${style.trackerSvg} ${rotateInNumbers}`} />
                  </button>
              </div>
            }
            {showInNumbers &&
              <InNumbers params={trackerParams} sendParams={sendParams} />
            }
            {canProgram &&
              <div style={{ width: '100%', float: 'left', marginTop: '25px' }}>
                <button
                  className={`${style.trackerMenuOption} ${disableProgramming ? style.disabled : ''}`}
                  onClick={() => setShowMedicationsMenu(!showMedicationsMenu)}
                  disabled={disableProgramming}
                >
                  <FormattedMessage id="client.tracker.medicationOption" defaultMessage="Programar Lembretes de medicamento" />
                  <Order width="25" height="15" className={`${style.trackerSvg} ${rotateMedications}`} />
                </button>
              </div>
            }
            {showMedicationsMenu &&
              <MedicationReminders params={trackerParams} sendParams={sendParams} />
            }
            {canProgram &&
              <div style={{ width: '100%', float: 'left', marginTop: '25px' }}>
                <button
                  className={`${style.trackerMenuOption} ${disableProgramming ? style.disabled : ''}`}
                  onClick={openGeofence}
                  disabled={disableProgramming}
                >
                  <FormattedMessage id="client.tracker.geofences" defaultMessage="Cercas Geográficas" /><Order width="25" height="15" className={`${style.trackerSvg} ${rotateGeofences}`} />
                </button>
              </div>
            }
          </div>
          {!showGeofence && (errorMessage
            ? <div className={style.errorWrapper}>
              <div className={style.trackerError}>{errorMessage}</div>
            </div>
            : null)
          }
          {showGeofence &&
            <Geofence programmingData={programming} params={trackerParams} sendParams={sendParams} />
          }
        </div>
      </div>
    </div>
  );
};

TrackeriHelp.propTypes = {
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          account: PropTypes.string,
          first_name: PropTypes.string,
          last_name: PropTypes.string,
          is_test: PropTypes.bool,
          equipment_id: PropTypes.number,
          equipment_name: PropTypes.string,
        }),
      ),
      ordering: PropTypes.string,
    }),
  ).isRequired,
  params: PropTypes.shape({
    clientId: PropTypes.string.isRequired,
  }).isRequired,
  loadClients: PropTypes.func.isRequired,
  loggedAttendantGroup: PropTypes.number.isRequired,
  lang: PropTypes.string.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    clients: state.clients,
    loggedAttendantGroup: state.loggedAttendant.groups[0],
    lang: state.locale.lang,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadClients: params => dispatch(clientsActions.loadClients(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackeriHelp);
