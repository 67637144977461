import * as types from '../actions/actionTypes';
import initialState from './initialState';

/*
Inputs:
  state = Estado atual do objeto, caso não seja passado, é utilizado o estado padrão do arquivo
  (initialState.importInfo) que é um objeto
  action = Ação aplicada sobre o objeto
Types:
  IMPORT_CLIENTS_SUCCESS = Realiza a ação de importação de clientes

importInfoReducer = Retorna um objeto com informações de clientes que foram importados com
sucesso e clientes que tiveram erro de importação (Utilizado na importação de clientes por csv)
 */
export default (state = initialState.importInfo, action) => {
  if (action.type === types.IMPORT_CLIENTS_SUCCESS) {
    return Object.assign({}, state, action.importInfo);
  }
  return state;
};
