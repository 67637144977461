import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import detail from '../../../css/detail.module.scss';
import { broadcast } from '../../../BLL/general';
import { getDomain, host, getToken } from '../../../BLL/environment';
import countryList from '../../../BLL/locale/messagesCountries';
import * as general from '../../../BLL/general';
import { validateCPF } from '../../../BLL/validate';
import SelectInput from '../../Common/SelectInput';
import formStyle from '../../../css/form.module.scss';
import styles from './activate.module.scss';
import '../../../css/formPage.scss';

const Activate = (props) => {
  const countries = countryList[props.lang];
  const [isSaving, setIsSaving] = useState(false);
  const [state, setState] = useState({
    cpf: '',
    zip_code: '',
    street: '',
    number: '',
    district: '',
    complement: '',
    city: '',
    country: 'BR',
    state: 'RS'
  });
  const [validator, setValidator] = useState({
    cpf: {
      validate: true,
      message: ''
    },
    zip_code: {
      validate: true,
      message: ''
    },
    street: {
      validate: true,
      message: ''
    },
    number: {
      validate: true,
      message: ''
    },
    district: {
      validate: true,
      message: ''
    },
    city: {
      validate: true,
      message: ''
    }
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    const newState = state;
    switch (id) {
      case 'country': {
        newState[id] = value;
        if (value !== 'BR') {
          newState.state = '-';
        }
        break;
      }
      case 'cpf': {
        newState[id] = general.cpfMask(value);
        break;
      }
      case 'zip_code': {
        newState[id] = state.country === 'BR' ? general.zipCode(value) : value;
        break;
      }
      default: {
        newState[id] = value;
        break;
      }
    }
    setState(Object.assign({}, newState));
    checkValidation(id, value);
  };

  const checkValidation = (id, value) => {
    const newValidator = validator;
    switch (id) {
      case 'zip_code': {
        const validate = general.removeNonDigit(value).length === 8;
        newValidator[id].validate = validate;
        if (!validate) {
          newValidator[id].message = <FormattedMessage id="client.error.zipCode" defaultMessage="Campo deve possuir 8 números" />;
        } else {
          newValidator[id].message = '';
        }
        break;
      }
      case 'cpf': {
        const arr = value.split('');
        const val = arr.filter(el => /\d/.test(el)).join('');
        const mask = '000.000.000-00';
        const valueLength = value.length;
        if (valueLength > 0) {
          if (valueLength < mask.length) {
            newValidator[id].validate = false;
            newValidator[id].message = <FormattedMessage id="client.error.cpfInvalidLen" defaultMessage="O cpf precisa ter 11 dígitos" />;
          } else if (!validateCPF(val)) {
            newValidator[id].validate = false;
            newValidator[id].message = <FormattedMessage id="client.error.cpfInvalid" defaultMessage="Número de cpf inválido" />;
          } else {
            newValidator[id].validate = valueLength === 0 || valueLength === mask.length;
            newValidator[id].message = '';
          }
        } else {
          newValidator[id].validate = valueLength === 0 || valueLength === mask.length;
          newValidator[id].message = '';
        }
        break;
      }
      default: {
        break;
      }
    }
    setValidator(Object.assign({}, newValidator));
  }

  const activatePlan = () => {
    const values = {
      cpf_cnpj: general.removeNonDigit(state.cpf, true),
      street: state.street,
      number: state.number,
      district: state.district,
      complement: state.complement,
      zip_code: general.removeNonDigit(state.zip_code, true),
      city: state.city,
      state: state.state,
      country: state.country,
    };
    const data = JSON.stringify(values);
    const URL = `https://${getDomain()}${host()}/api/activate/`;
    setIsSaving(true);

    axios({
      url: URL,
      method: 'put',
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.token}`,
      },
    }).then((res) => {
      broadcast(axios, props.channelId, {
        type: 'activation',
        update: 'activate plan',
        text: 'Atualização na versão do plano. Você será deslogado.',
      }).then(() => {
            console.log('efetuou o broadcast');
      });
    }).catch((err) => {
      console.log('Erro na ativação do cliente', err);
    });
  }

  const activeSubmit = Object.keys(validator).every(el => {
    return state[el] !== '' && validator[el].validate;
  });

  return (
    <div className="contactto-middle-content" style={{ overflowX: 'hidden' }}>
      <div className="form-title">
        <FormattedMessage id="activate.header" defaultMessage="ATIVAR ASSINATURA" />
      </div>
      <div className={styles.container}>
        <div className={`${detail['container-title']} col-12`} style={{ float: 'left', marginBottom: '10px' }}>
          <FormattedMessage id="activate.registrationData" defaultMessage="DADOS DE CADASTRO" />
        </div>
        
        <div className={styles.content}>
          {/* CPF - CEP */}
          <div className="col-12">
            <div className="form-row">
              <div className="col-sm-12 col-md-6">
                <div className="form-group">
                  <label htmlFor="cpf" className="text-muted">CPF/CNPJ: <span className={styles.required}>*</span></label>
                  <input
                    id="cpf"
                    type="text"
                    onChange={handleChange}
                    className={`form-control ${!validator.cpf.validate ? formStyle['input--error'] : ''}`}
                    value={state.cpf}
                  />
                  {!validator.cpf.validate && validator.cpf.message &&
                    <div className={`${formStyle.message} form-text-error`}>
                      {validator.cpf.message}
                    </div>
                  }
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="form-group">
                  <label htmlFor="zip_code" className="text-muted">CEP: <span className={styles.required}>*</span></label>
                  <input
                    id="zip_code"
                    type="text"
                    onChange={handleChange}
                    className={`form-control ${!validator.zip_code.validate ? formStyle['input--error'] : ''}`}
                    value={state.zip_code}
                  />
                  {!validator.zip_code.validate && validator.zip_code.message &&
                    <div className={`${formStyle.message} form-text-error`}>
                      {validator.zip_code.message}
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          {/*  Street */}
          <div className="col-12">
            <div className="form-row">
              <div className="col-sm-12">
                <div className="form-group">
                  <label htmlFor="street" className="text-muted">
                    <FormattedMessage id="activate.street" defaultMessage="Logradouro" />: <span className={styles.required}>*</span>
                  </label>
                  <input id="street" type="text" onChange={handleChange} className="form-control" value={state.street}/>
                </div>
              </div>
            </div>
          </div>
          {/* Number - Complement */}
          <div className="col-12">
            <div className="form-row">
              <div className="col-sm-12 col-md-6">
                <div className="form-group">
                  <label htmlFor="number" className="text-muted">
                    <FormattedMessage id="activate.number" defaultMessage="Número" />: <span className={styles.required}>*</span>
                  </label>
                  <input id="number" type="number" onChange={handleChange} className="form-control" value={state.number}/>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="form-group">
                  <label htmlFor="complement" className="text-muted">
                    <FormattedMessage id="activate.complement" defaultMessage="Complemento" />:
                  </label>
                  <input id="complement" type="text" onChange={handleChange} className="form-control" value={state.complement}/>
                </div>
              </div>
            </div>
          </div>
          {/* District - City */}
          <div className="col-12">
            <div className="form-row">
              <div className="col-sm-12 col-md-6">
                <div className="form-group">
                  <label htmlFor="district" className="text-muted">
                    <FormattedMessage id="activate.district" defaultMessage="Bairro" />: <span className={styles.required}>*</span>
                  </label>
                  <input id="district" type="text" onChange={handleChange} className="form-control" value={state.district}/>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="form-group">
                  <label htmlFor="city" className="text-muted">
                    <FormattedMessage id="activate.city" defaultMessage="Cidade" />: <span className={styles.required}>*</span>
                  </label>
                  <input id="city" type="city" onChange={handleChange} className="form-control" value={state.city}/>
                </div>
              </div>
            </div>
          </div>
          {/* State - Country */}
          <div className="col-12">
            <div className="form-row">
              <div className="col-sm-12 col-md-6">
                <div className="form-group">
                  <label htmlFor="state" className="text-muted"><FormattedMessage id="activate.state" defaultMessage="Estado" />:</label>
                  <SelectInput
                    id="state"
                    name="state"
                    className={`custom-select ${styles['select--enable']}`}
                    value={state.state}
                    onChange={handleChange}
                    items={state.country === 'BR' ? [
                      '-',
                      'RS',
                      'AC',
                      'AL',
                      'AM',
                      'AP',
                      'BA',
                      'CE',
                      'DF',
                      'ES',
                      'GO',
                      'MA',
                      'MG',
                      'MS',
                      'MT',
                      'PA',
                      'PB',
                      'PE',
                      'PI',
                      'PR',
                      'RJ',
                      'RN',
                      'RO',
                      'RR',
                      'SC',
                      'SP',
                      'SE',
                      'TO',
                    ]
                    : ['-']}
                  />
                </div>

              </div>
              <div className="col-sm-12 col-md-6">
                <div className="form-group">
                  <label htmlFor="country" className="text-muted"><FormattedMessage id="activate.country" defaultMessage="País" />:</label>
                  <select
                    id="country"
                    name="country"
                    value={state.country}
                    onChange={handleChange}
                    className={`custom-select ${styles['select--enable']}`}
                  >
                    {countries.map(country => 
                      <option key={country.code} value={country.code} >
                        {country.name}
                      </option>
                    )}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.container}>
        {/* Container de botão */}
        <div className={styles.content}>
          <div className={styles.row50}>
            <button
              disabled={!activeSubmit && isSaving}
              className={`${styles.btn} ${!activeSubmit ? styles.btnDisabled : ''}`}
              onClick={() => activatePlan()}
            >
              <FormattedMessage id="activate.sign" defaultMessage="ASSINAR" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    lang: state.locale.lang,
  };
}

export default connect(mapStateToProps)(Activate);
