import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import OpenAttends from './OpenAttends/OpenAttends';
import ClosedAttends from './ClosedAttends/ClosedAttends';
import Loading from '../../../icons/Loading';
import Alarms from '../../Cards/Alarms/AlarmsCard';
import CustomCardHOC from '../../Cards/CustomCardHOC';
import AttendByDayChart from '../../Charts/AttendByDayChart';
import ClientTotalMonthly from '../../Charts/ClientTotalMonthlyChart';

import { getMonth } from '../../../BLL/dictionary';
import { host, getDomain, getToken } from '../../../BLL/environment';

import '../../../css/listPage.scss';
import style from './dashboard.module.scss'

const DashboardPage = (props) => {
  const attendsDashboard = useSelector(state => state.attendsDashboard);
  const user = useSelector(state => state.loggedAttendant);
  const browser = useSelector(state => state.browser);
  const localConfiguration = useSelector(state => state.localConfiguration.local);
  const globalConfiguration = useSelector(state => state.globalConfiguration.global);
  const lang = useSelector(state => state.locale.lang);

  const [totalVoiceReport, setTotalVoiceReport] = useState({});
  const [dailyTotalReport, setDailyTotalReport] = useState([])
  const [clientsTotalMonthlyReport, setClientsTotalMonthlyReport] = useState([]);
  const [attendsOpen, setAttendsOpen] = useState([]);
  const [attendsClosed, setAttendsClosed] = useState([]);
  const [urlCurrDate, setUrlCurrDate] = useState(undefined);
  const [urlPrevDate, setUrlPrevDate] = useState(undefined);
  const [showClosedAttends, setShowClosedAttends ] = useState(false);
  const [showAttendsCount, setShowAttendsCount] = useState(false);

  useEffect(() => {
    fetchAttendsTotalVoiceReport();
    setTotalVoiceReportDates();
  }, []);

  useEffect(() => {
    if (attendsDashboard) {
      setAttendsOpen(attendsDashboard.attendsOpen);
      setAttendsClosed(attendsDashboard.attendsClosed);
    }
  }, [attendsDashboard]);

  useEffect(() => {
    if (localConfiguration) {
      setShowClosedAttends(localConfiguration.last_attends);
      setShowAttendsCount(localConfiguration.attends_quantity);
    }
  }, [localConfiguration]);

  const fetchAttendsTotalVoiceReport = () => {
    axios.get(`https://${getDomain()}${host()}/api/reports/attends-tot-voice/`, {
      headers: { Authorization: `Token ${getToken()}` },
    }).then((res) => {
      if (res.data) {
        setTotalVoiceReport(Object.assign(res.data));
      }
    }).catch((err) => {
      throw err;
    });
  };

  const fetchDailyTotalAttendsReport = () => {
    if (dailyTotalReport.length <= 0) {
      axios.get(`https://${getDomain()}${host()}/api/reports/attends-daily-total/`, {
        headers: { Authorization: `Token ${getToken()}` },
      }).then((res) => {
        if (res.data.length > 0) {
          setDailyTotalReport(Object.assign(res.data));
        }
      }).catch((err) => {
        throw err;
      });
    }
  };

  const fetchClientTotalMonthly = () => {
    if (clientsTotalMonthlyReport.length <= 0) {
      axios.get(`https://${getDomain()}${host()}/api/reports/clients-total-month/`, {
        headers: { Authorization: `Token ${getToken()}` },
      }).then((res) => {
        setClientsTotalMonthlyReport(res.data.data);
      }).catch((err) => {
        console.log(err.response);
      })
    }
  };

  const setTotalVoiceReportDates = () => {
    const currDate = new Date();
    const prevDate = new Date(currDate.getFullYear(), currDate.getMonth() - 1);
    const currLastDay = new Date(currDate.getFullYear(), currDate.getMonth() + 1, 0, 23, 59, 59);
    // Must add 1 to month, JS date use months as list
    const currFirst = `start=${currDate.getFullYear()}-${
      currDate.getMonth() + 1 >= 10
        ? currDate.getMonth() + 1
        : `0${currDate.getMonth() + 1}`
      }-01`;
    const currLast = `&end=${currLastDay.getFullYear()}-${
      currLastDay.getMonth() + 1 >= 10
        ? currDate.getMonth() + 1
        : `0${currDate.getMonth() + 1}`
      }-${currLastDay.getDate()}`;

    const prevLastDay = new Date(prevDate.getFullYear(), prevDate.getMonth() + 1, 0, 23, 59, 59);
    // Must add 1 to month, JS date use months as list
    const prevFirst = `start=${prevDate.getFullYear()}-${
      prevDate.getMonth() + 1 >= 10
        ? prevDate.getMonth() + 1
        : `0${prevDate.getMonth() + 1}`
      }-01`;
    const prevLast = `&end=${prevLastDay.getFullYear()}-${
      prevLastDay.getMonth() + 1 >= 10
        ? prevDate.getMonth() + 1
        : `0${prevDate.getMonth() + 1}`
      }-${prevLastDay.getDate()}`;
    setUrlCurrDate(`${currFirst}${currLast}`);
    setUrlPrevDate(`${prevFirst}${prevLast}`);
  };

  const totalVoiceReportComponent = (current, previous, month) => {
    const AlarmsCard = (
      <Alarms
        alarms={totalVoiceReport}
        current={current}
        previous={previous}
        month={month}
        urlCurrDate={urlCurrDate}
        urlPrevDate={urlPrevDate}
      />
    );
    return (
      <div className={style.cards}>
        <CustomCardHOC
          headers={[<FormattedMessage id="dashboard.card.quantity" defaultMessage="" />]}
          onClick={() => { }}
          childComponent={[AlarmsCard]}
          theme="#ffffff"
          minHeight="190px"
        />
      </div>
    );
  };

  const dailyTotalReportComponent = () => {
    if (dailyTotalReport.length > 0) {
      return (
        <div className={style.attendChart}>
          <AttendByDayChart
            numberOfAttends={dailyTotalReport}
            width="100%"
            height="240px"
          />
        </div>
      );
    }
    return <Loading text={<FormattedMessage id="attend.list.loading" defaultMessage="Carregando" />} />;
  };

  const clientsTotalMonthlyReportComponent = () => {
    if (clientsTotalMonthlyReport.length > 0) {
      return (
        <div className={style.clientChart}>
          <ClientTotalMonthly
            report={clientsTotalMonthlyReport}
            width="100%"
            height="240px"
          />
        </div>
      );
    }
    return <Loading text={<FormattedMessage id="attend.list.loading" defaultMessage="Carregando" />} />;
  }

  let month = [];
  if (lang) {
    month = getMonth(lang);
  } else {
    month = getMonth('pt');
  }
  const date = new Date();
  const current = date.getMonth();
  const previous = date.getMonth() === 0 ? 11 : date.getMonth() - 1;

  return(
    <div className="contactto-middle-content">
      {attendsOpen &&
        <OpenAttends
          key={attendsOpen.id}
          user={user}
          channelId={props.channelId}
          lang={lang}
          attends={attendsOpen}
          setUserBusy={props.setUserBusy}
          setTextIconDetail={() => {}}
          setEmptyText={() => {}}
          history={props.history}
          showIcons={globalConfiguration?.show_icons}
          useZoneColors={globalConfiguration?.use_alarm_zones}
          {...browser}
        />
      }
      {showClosedAttends &&
        <ClosedAttends
          className="col-sm-12 col-md-6 col-lg-4 col-xl-2"
          attends={attendsClosed}
          width={browser.width}
          lang={lang}
          props={props}
          history={props.history}
          showIcons={globalConfiguration?.show_icons}
          useZoneColors={globalConfiguration?.use_alarm_zones}
          minHeight="190px"
        />
      }
      {showAttendsCount &&
        <div className="contactto-nav-wrapper">
          <div className="middle-content">
            <span className="paddingtitle"><FormattedMessage id="dashboard.tabs.header" defaultMessage="RESUMO DOS ATENDIMENTOS" /></span>
            <hr className="hrcolor" />
          </div>
          <div className="contactto-nav-tab">
            <div className="nav nav-tabs nav-justified" id="nav-tab" role="tablist">
              <button
                className="nav-item nav-link"
                id="nav-quantity-tab"
                data-toggle="tab"
                href="#nav-quantity"
                role="tab"
                aria-controls="nav-quantity"
                aria-selected="true"
              >
                <FormattedMessage id="dashboard.tabs.quantity" defaultMessage="QUANTIDADE" />
              </button>
              {!globalConfiguration.use_alarm_zones &&
                <button
                  className="nav-item nav-link"
                  onClick={() => fetchDailyTotalAttendsReport()}
                  id="nav-priority-tab"
                  data-toggle="tab"
                  href="#nav-priority"
                  role="tab"
                  aria-controls="nav-priority"
                  aria-selected="false"
                >
                  <FormattedMessage id="dashboard.tabs.bypriority" defaultMessage="POR PRIORIDADE" />
                </button>
              }
              <button
                className="nav-item nav-link"
                onClick={() => fetchClientTotalMonthly()}
                id="nav-client-monthly-tab"
                data-toggle="tab"
                href="#nav-client-monthly"
                role="tab"
                aria-controls="nav-client-monthly"
                aria-selected="false"
              >
                <FormattedMessage id="dashboard.tabs.userstotal" defaultMessage="TOTAL USUÁRIOS" />
              </button>
            </div>
          </div>
          <div className="tab-content" id="nav-tabContent">
            <div className="tab-pane active" id="nav-quantity" role="tabpanel" aria-labelledby="nav-quantity-tab">
              {totalVoiceReportComponent(current, previous, month)}
            </div>
            {!globalConfiguration.use_alarm_zones &&
              <div className="tab-pane" id="nav-priority" role="tabpanel" aria-labelledby="nav-priority-tab">
                {dailyTotalReportComponent()}
              </div>
            }
            <div className="tab-pane" id="nav-client-monthly" role="tabpanel" aria-labelledby="nav-client-monthly-tab">
              {clientsTotalMonthlyReportComponent()}
            </div>
          </div>
        </div>
      }
    </div>
  );
}

DashboardPage.propTypes = {
  channelId: PropTypes.string.isRequired,
  setUserBusy: PropTypes.func.isRequired,
};

export default DashboardPage;
