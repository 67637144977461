import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import * as general from '../../../BLL/general';

const TransmitterComponent = ({ transmitter, creator, modifier, titleClass, persNumber, useForSecondary }) => (
  <div>
    <div className="block">
      <div className={`block-title ${titleClass}`}><FormattedMessage id="transmitters.form.informations" defaultMessage="INFORMAÇÕES" /></div>
      <div className="block-content">
        { !!persNumber &&
        <div className="block-content-container contactto-100">
          <span className="block-field col-sm-12 col-md-3"><FormattedMessage id="contact.page.accountNumber" defaultMessage="Número da conta" />: </span>
          <span className="block-value col-sm-12 col-md-9">{persNumber}</span>
        </div>
                    }
        <div className="block-content-container contactto-100">
          <span className="block-field col-sm-12 col-md-3"><FormattedMessage id="transmitters.identifier" defaultMessage="Identificador TX" /> </span>
          <span className="block-value col-sm-12 col-md-9">{transmitter.identifier}</span>
        </div>
        <div className="block-content-container contactto-100">
          <span className="block-field col-sm-12 col-md-3"><FormattedMessage id="transmitters.zone" defaultMessage="Zona" /> </span>
          <span className="block-value col-sm-12 col-md-9">{transmitter.zone}</span>
        </div>
        <div className="block-content-container contactto-100">
          <span className="block-field col-sm-12 col-md-3"><FormattedMessage id="transmitters.description" defaultMessage="Descrição" /> </span>
          <span className="block-value col-sm-12 col-md-9">{transmitter.description}</span>
        </div>
        <div className="block-content-container contactto-100">
          <span className="block-field col-sm-12 col-md-3">
            <FormattedMessage id="transmitters.detail.useForUser2" defaultMessage="Utilizar para o usuário 2" />: 
          </span>
          <span className="block-value col-sm-12 col-md-9">
            {useForSecondary
              ? <FormattedMessage id="client.detail.testUserYes" defaultMessage="Sim" />
              : <FormattedMessage id="client.detail.testUserNo" defaultMessage="Não" />
            }
          </span>
        </div>
        <div className="block-content-container contactto-100">
          <span className="block-field col-sm-12 col-md-3"><FormattedMessage id="transmitters.observations" defaultMessage="Observações" />: </span>
          <span className="block-value col-sm-12 col-md-9">{transmitter.general_info ? transmitter.general_info : '-'}</span>
        </div>
      </div>
    </div>
    <div className="block">
      <div className="block-title">LOGS</div>
      <div className="block-content">
        <div className="block-content-container block-content-container--100">
          <span className="block-field col-sm-12 col-md-3"><FormattedMessage id="logs.createdDate" defaultMessage="Data de criação" />: </span>
          <span className="block-value col-sm-12 col-md-9">{transmitter.created_date ? general.formatOnlyNumbersDate(transmitter.created_date) : '-'}</span>
        </div>
        <div className="block-content-container block-content-container--100">
          <span className="block-field col-sm-12 col-md-3"><FormattedMessage id="logs.createdBy" defaultMessage="Criado por" />: </span>
          <span className="block-value col-sm-12 col-md-9">{creator}</span>
        </div>
        <div className="block-content-container block-content-container--100">
          <span className="block-field col-sm-12 col-md-3"><FormattedMessage id="logs.modifiedDate" defaultMessage="Data de modificação" />: </span>
          <span className="block-value col-sm-12 col-md-9">{transmitter.created_date ? general.formatOnlyNumbersDate(transmitter.modified_date) : '-'}</span>
        </div>
        <div className="block-content-container block-content-container--100">
          <span className="block-field col-sm-12 col-md-3"><FormattedMessage id="logs.modifiedBy" defaultMessage="Modificado por" />: </span>
          <span className="block-value col-sm-12 col-md-9">{modifier}</span>
        </div>
      </div>
    </div>
  </div>
);

TransmitterComponent.defaultProps = {
  titleClass: '',
  persNumber: '',
  creator: '-',
  modifier: '-',
};

TransmitterComponent.propTypes = {
  transmitter: PropTypes.shape({
    id: PropTypes.number,
    zone: PropTypes.string,
    client: PropTypes.number,
    description: PropTypes.string,
    identifier: PropTypes.string,
    general_info: PropTypes.string,
    created_date: PropTypes.string,
    modified_date: PropTypes.string,
    created_by: PropTypes.number,
    modified_by: PropTypes.number,
  }).isRequired,
  titleClass: PropTypes.string,
  persNumber: PropTypes.string,
  creator: PropTypes.string,
  modifier: PropTypes.string,
};

export default TransmitterComponent;
