import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import User from './HeaderUser';
import SipStatus from './HeaderSipStatus';
import HeaderDomain from './HeaderDomain';
import HeaderActions from './HeaderActions';
import Trial from './Trial/Trial';

import style from './header.module.scss';
import '../../css/print.scss';

const Header = (props) => (
  <header className={`${style.wrapper} no-print`}>
    <div className={style.content}>
      {props.status === 'trial' &&
        <Trial activate={props.activate} isAdmin={props.isAdmin} isOwner={props.isOwner} />
      }
      {sessionStorage.activeDomain &&
        <HeaderDomain history={props.history}/>
      }
      <HeaderActions
        sipEvtListener={props.sipEvtListener}
        evtListener={props.evtListener}
        sessionListener={props.sessionListener}
        audioAction={props.audioAction}
        turnOffAlarmAudio={props.turnOffAlarmAudio}
        hangupSip={props.hangupSip}
      />
      {props.isSysUser &&
        <div className={style.iconContainer}>
          <button id="chat-button" className={style.iconButton} onClick={props.toggleChat}>
            <i
              className={`far fa-comment-dots fa-3x ${style.iconActive}`}
              style={{ fontSize: '35px' }}
              aria-hidden="true"
            />
            {props.newChatMessage && <div className={style.chatDot}></div>}
          </button>
        </div>
      }
      <div className={style.iconContainer}>
        <button id="reminder-button" className={style.iconButton} onClick={props.reminders && props.toggleReminder}>
          {props.reminders && props.reminders.data.length > 0 &&
            <div className={style.reminderDot}>
              <span className={style.reminderNumber}>{props.reminders.data.length}</span>
            </div>
          }
          <i
            className={`far fa-clock fa-3x ${props.reminders && props.reminders.data.length > 0
              ? style.iconActive
              : style.remindersDeactivated}`}
            style={{ fontSize: '35px' }}
            aria-hidden="true"
          />
        </button>
      </div>
      <div className={style.localeContainer}>
        <button id="header-pt-button" className={`${style.localeButton} ${props.lang === 'pt' ? style.localeChosen : ''}`} onClick={() => props.setLocale('pt')}>PT</button>
        <span> | </span>
        <button id="header-en-button" className={`${style.localeButton} ${props.lang === 'en' ? style.localeChosen : ''}`} onClick={() => props.setLocale('en')}>EN</button>
      </div>
      {!props.hasWebsocketConnection &&
        <div className={`${style.icon} 'no-print'`}>
          <i className="fas fa-exclamation fa-2x" style={{ color: '#fff', lineHeight: '.9', display: 'flex' }} aria-hidden="true" />
          <span className={style.iconText}>
            <FormattedMessage id="header.icon.connection" defaultMessage="Conexão com o servidor interrompida, aguarde 30 segundos ou recarregue a página." />
          </span>
        </div>
      }
      <div className={style.phone}>
        <SipStatus
          sipSessionType={props.sipSessionType}
          sipStackType={props.sipStackType}
        />
      </div>
      <div className={style.user}>
        <User
          attendantName={props.attendantName}
          logout={props.logout}
          toggleAdminModal={props.toggleAdminModal}
        />
      </div>
    </div>
  </header>
)

Header.defaultProps = {
  sipSessionType: undefined,
  sipStackType: undefined,
  hasWebsocketConnection: true,
  microphoneConnection: true,
  microphoneName: '',
  newChatMessage: false,
  isSysUser: false,
};

Header.propTypes = {
  hasWebsocketConnection: PropTypes.bool,
  newChatMessage: PropTypes.bool,
  isSysUser: PropTypes.bool,
  attendantName: PropTypes.string.isRequired,
  toggleReminder: PropTypes.func.isRequired,
  sipSessionType: PropTypes.string,
  sipStackType: PropTypes.string,
  audioAction: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  activate: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool.isRequired,
  setLocale: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  reminders: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        status: PropTypes.string,
      }),
    ),
    serverTime: PropTypes.string
  }).isRequired,
  toggleAdminModal: PropTypes.func.isRequired,
  toggleChat: PropTypes.func.isRequired,
  turnOffAlarmAudio: PropTypes.func.isRequired,
};

export default Header;
