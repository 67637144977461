import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import style from './clientPage.module.scss';

const ClientPageButtonComponent = ({
  clientList,
  filter,
  status,
  browserHistory,
  removeFilter,
  showFilterModal,
  editFilter,
}) => (
  <div className={style['btn-content']}>
    <button
      id="client-button-add"
      className={`${style['btn-add']} ${(status === 'trial' && clientList && clientList.length >= 3) ? style.disabled : ''}`}
      disabled={status === 'trial' && clientList && clientList.length >= 3}
      onClick={() => browserHistory.push('/app/clients/form/add')}
      type="button"
    >
      <div>
        <span className={style['btn--bold']}>
          + <FormattedMessage id="general.add" defaultMessage="ADICIONAR" />
        </span>
      </div>
    </button>
    {filter ?
      <div style={{ float: 'left' }}>
        <button
          id="client-button-remove-filter"
          className={style['btn-add']}
          style={{ clear: 'none', marginLeft: '5px' }}
          type="button"
          onClick={() => removeFilter()}
        >
          <div>
            <span className={style['btn--bold']}>
              <i
                className="fa fa-filter"
                aria-hidden="true"
                style={{ transition: '.3s ease', lineHeight: '1.2rem' }}
              /> <FormattedMessage id="client.page.undoFilt1" defaultMessage="DESFAZER" />
            </span>
          </div>
        </button>
        <button
          id="client-button-edit-filter"
          className={style['btn-add']}
          style={{ clear: 'none', marginLeft: '5px' }}
          type="button"
          onClick={() => editFilter()}
        >
          <div>
            <span className={style['btn--bold']}>
              <i
                class="fas fa-pen"
                aria-hidden="true"
                style={{ transition: '.3s ease', lineHeight: '1.2rem', marginRight: '5px' }}
              />
              <FormattedMessage id="client.page.editFilt" defaultMessage="EDITAR FILTRO" />
            </span>
          </div>
        </button>
      </div>
      :
      <button
        id="client-button-filter"
        className={style['btn-add']}
        style={{ clear: 'none', marginLeft: '5px' }}
        type="button"
        onClick={() => showFilterModal()}
      >
        <div>
          <span className={style['btn--bold']}>
            <i
              className="fa fa-filter"
              aria-hidden="true"
              style={{ transition: '.3s ease' }}
            /> <FormattedMessage id="client.page.filter1" defaultMessage="FILTRAR" />
          </span>
        </div>
      </button>
    }
  </div>
);

ClientPageButtonComponent.propTypes = {
  removeFilter: PropTypes.func.isRequired,
  showFilterModal: PropTypes.func.isRequired,
  editFilter: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  browserHistory: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  clientList: PropTypes.arrayOf(
    PropTypes.shape({
      next: PropTypes.string,
      previous: PropTypes.string,
      results: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          first_name: PropTypes.string,
          last_name: PropTypes.string,
          account: PropTypes.string,
          equipment_id: PropTypes.number,
        }),
      ),
    }),
  ).isRequired,
};

export default ClientPageButtonComponent;
