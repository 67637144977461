import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { FormattedMessage } from "react-intl";
import InputContainer from "../../Common/InputContainer";
import SelectInput from "../../Common/SelectInput2";
import CheckboxInput from "../../Common/CheckboxInput";
import ErrorComponent from "../../Common/ErrorComponent";
import ConfirmRemoveModal from "../../Modal/ModalConfirm";
import Telephone from "./ContactPhone";
import { getDomain, host, getToken } from "../../../BLL/environment";
import { contactActionPriority } from "../../../BLL/dictionary";
import { brazilCountryStateList } from "../../../BLL/globals";
import { validateMobile } from "../../../BLL/validate";
import countryCodes from "../../../BLL/locale/countryCodes";
import * as general from "../../../BLL/general";
import * as contactsActions from "../../../actions/contactsActions";

import broadcastMessages from "../../../BLL/locale/messagesBroadcast";
import countryList from "../../../BLL/locale/messagesCountries";
import relationshipList from "../../../BLL/locale/messagesRelationships";

import "../../../css/block.scss";
import "../../../css/formPage.scss";
import "../../../css/form.scss";
import "../../../css/contactto.scss";
import style from "./contactForm.module.scss";
import messagesRelationships from "../../../BLL/locale/messagesRelationships";

const ContactForm = (props) => {
  const lang = !localStorage.contacttoLang ? "pt" : localStorage.contacttoLang;
  const [isSaving, setIsSaving] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [client, setClient] = useState(undefined);
  const [priorityList, setPriorityList] = useState([]);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorCode, setErrorCode] = useState(0);
  const [phones, setPhones] = useState([{ phone_type: "Primary", phone: "" }]);
  const [phoneValidator, setPhoneValidator] = useState([
    { phone_type: true, phone: false, message: "" },
  ]);
  const [selectedRelationship, setSelectedRelationship] = useState(
    messagesRelationships[lang][messagesRelationships[lang].length - 1]
  );
  const [contact, setContact] = useState({
    id: undefined,
    first_name: "",
    last_name: "",
    priority: "",
    relationship: "",
    client: props.match.params.clientId,
    email: "",
    street: "",
    number: "",
    complement: "",
    district: "",
    city: "",
    state: "RS",
    country: "BR",
    has_keys: false,
    zip_code: "",
    phones: [],
    actions: [],
    is_whitelisted: false,
    whitelist_email: false,
    is_contractor: false,
  });
  const [ isFamily, setIsFamily ] = useState(false)
  const [validator, setValidator] = useState({
    first_name: { validate: true, message: "" },
    last_name: { validate: true, message: "" },
    priority: { validate: true, message: "" },
    relationship: { validate: true, message: "" },
    email: { validate: true, message: "" },
  });
  const [actions, setActions] = useState([
    {
      priority: 1,
      receive_sms: false,
      receive_email: false,
      receive_whatsapp: false,
    },
    {
      priority: 2,
      receive_sms: false,
      receive_email: false,
      receive_whatsapp: false,
    },
    {
      priority: 3,
      receive_sms: false,
      receive_email: false,
      receive_whatsapp: false,
    },
    {
      priority: 4,
      receive_sms: false,
      receive_email: false,
      receive_whatsapp: false,
    },
  ]);
  const [actionValidator, setActionValidator] = useState({
    whatsAppCellphone: { isValid: true, message: "" },
  });
  const [countryStateList, setCountryStateList] = useState(
    brazilCountryStateList
  );

  const { loadContacts } = props;

  useEffect(() => {
    if (
      messagesRelationships[lang].some((rel) => rel === contact.relationship)
    ) {
      setSelectedRelationship(contact.relationship);
    } else {
      setSelectedRelationship(
        messagesRelationships[lang][messagesRelationships[lang].length - 1]
      );
    }
  }, [contact.relationship, lang]);

  useEffect(() => {
    loadClient(props.match.params.clientId);
    loadContacts(props.match.params.clientId);
  }, [loadContacts, props.match.params.clientId]);

  useEffect(() => {
    if (props.contacts.length > 0) {
      setContactUp(props.contacts);
    }
  }, [props.contacts.length]);

  useEffect(() => {
    const primaryPhone = phones.find((p) => p.phone_type === "Primary");
    if (primaryPhone) {
      let cellPhoneValidation = true;
      const phoneValue = general.removeNonDigit(primaryPhone.phone, true);
      if (contact.country === "BR" || contact.country === "Brasil") {
        cellPhoneValidation = validateMobile(phoneValue);
      }
      const hasWhatsAppMarked = actions.some(
        (action) => action.receive_whatsapp
      );

      if (hasWhatsAppMarked && !cellPhoneValidation) {
        setActionValidator(
          Object.assign({
            whatsAppCellphone: {
              isValid: false,
              message:
                "Para utilizar o WhatsApp, o telefone PRIMÁRIO precisa ser um celular.",
            },
          })
        );
      } else {
        setActionValidator(
          Object.assign({ whatsAppCellphone: { isValid: true, message: "" } })
        );
      }
    }
  }, [actions, phones, contact.country]);

  const closeErrorModal = () => {
    setError(false);
    setErrorMsg("");
    setErrorCode(0);
  };

  const loadClient = (clientId) => {
    axios
      .get(`https://${getDomain()}${host()}/api/clients/${clientId}/`, {
        headers: { Authorization: `Token ${getToken()}` },
      })
      .then((res) => setClient(res.data))
      .catch((error) => console.log(error));
  };

  const setContactUp = (contactList) => {
    const { method } = props.match.params;
    const contactId =
      method === "edit" && parseInt(props.match.params.contactId, 10);
    const clientContacts = contactList.find(
      (contacts) => contacts.clientId === props.match.params.clientId
    );
    const newPriorityList = clientContacts.data.map((cont) => cont.priority);
    if (method === "edit") {
      const setUpContact = clientContacts.data.find((c) => c.id === contactId);
      let countryList = brazilCountryStateList;
      if (setUpContact.country !== "BR") {
        countryList = ["-"];
      }
      setIsFamily(setUpContact.is_family);
      const phoneList = setUpContact.phones.map((el) =>
        Object.assign({}, el, {
          phone: general.renderPhoneDigit(el.phone, setUpContact.country),
        })
      );
      setContact(
        Object.assign({}, setUpContact, {
          zip_code: general.setMask(setUpContact.zip_code, "00000-000"),
        })
      );
      setPhones(phoneList.sort((a, b) => a.id - b.id));
      setPhoneValidator(
        setUpContact.phones.map(() => ({
          phone_type: true,
          phone: true,
          message: "",
        }))
      );
      setActions([...setUpContact.actions]);
      setCountryStateList(countryList);
    }
    setPriorityList(newPriorityList);
  };

  const setContactState = (ev) => {
    const field = ev.target.name;
    const value = ev.target.value;

    setFormValue({ field, value });
    setValidation({ field, value });
  };

  const setFormValue = ({ field, value }) => {
    let newContact = Object.assign({}, contact);
    switch (field) {
      case "zip_code": {
        newContact[field] =
          newContact === "BR" ? general.zipCode(value) : value;
        break;
      }
      case "priority": {
        newContact[field] = parseInt(value, 10);
        break;
      }
      default:
        newContact[field] = value;
    }
    setContact(Object.assign({}, newContact));
  };

  const setRelationship = (e) => {
    const value = e.target.value;
    setSelectedRelationship(e.target.value);
    // If different than Other/Outro (last item in message relationship list)
    if (
      value !==
      messagesRelationships[lang][messagesRelationships[lang].length - 1]
    ) {
      setContact(Object.assign({}, contact, { relationship: value }));
      setValidator(
        Object.assign({}, validator, {
          relationship: { validate: true, message: "" },
        })
      );
    }
  };

  const setValidation = ({ field, value }) => {
    let newValidator = Object.assign({}, validator);
    switch (field) {
      case "first_name":
      case "last_name":
      case "email": {
        const validLength = value.length >= 2;
        newValidator[field].validate = validLength;
        if (!validLength) {
          newValidator[field].message = (
            <FormattedMessage
              id="client.error.atLeastTwoChar"
              defaultMessage="Campo deve possuir pelo menos dois caracteres"
            />
          );
        } else {
          newValidator[field].validate = true;
          newValidator[field].message = "";
        }
        break;
      }
      case "relationship": {
        const validLength = value.length >= 2;
        newValidator[field].validate = validLength;
        if (!validLength) {
          newValidator[field].message = (
            <FormattedMessage
              id="client.error.atLeastTwoChar"
              defaultMessage="Campo deve possuir pelo menos dois caracteres"
            />
          );
        } else {
          const firstCharUppercase = value[0] === value[0].toUpperCase();
          if (firstCharUppercase) {
            newValidator[field].validate = true;
            newValidator[field].message = "";
          } else {
            newValidator[field].validate = false;
            newValidator[field].message = (
              <FormattedMessage
                id="client.error.firstCharUppercase"
                defaultMessage="Primeira letra precisa ser maiúscula"
              />
            );
          }
        }
        break;
      }
      case "priority": {
        const onPriorityList = priorityList.includes(parseInt(value, 10));
        newValidator[field].validate = !onPriorityList;
        if (onPriorityList) {
          newValidator[field].message = (
            <FormattedMessage
              id="contact.error.priorityAlreadyTaken"
              defaultMessage="Outro contato já possui essa prioridade."
            />
          );
        } else {
          newValidator[field].validate = true;
          newValidator[field].message = "";
        }
        break;
      }
      default:
        break;
    }
    setValidator(Object.assign({}, newValidator));
  };

  const setContactCountry = (ev) => {
    const field = ev.target.name;
    const value = ev.target.value;

    const newContact = Object.assign({}, contact);

    if (field === "country" && newContact.country !== value) {
      newContact.country = value;
      newContact.zip_code = "";

      if (value === "BR") {
        newContact.state = "AC";
        setCountryStateList([
          "AC",
          "AL",
          "AM",
          "AP",
          "BA",
          "CE",
          "DF",
          "ES",
          "GO",
          "MA",
          "MG",
          "MS",
          "MT",
          "PA",
          "PB",
          "PE",
          "PI",
          "PR",
          "RJ",
          "RN",
          "RO",
          "RR",
          "RS",
          "SC",
          "SP",
          "SE",
          "TO",
        ]);
      } else {
        newContact.state = "-";
        setCountryStateList(["-"]);
      }
      setPhones(
        phones.map((phone) => ({
          phone: "",
          phone_type: phone.phone_type,
          contact: newContact.id,
        }))
      );
      setPhoneValidator(
        phoneValidator.map(() => ({
          phone_type: true,
          phone: false,
          message: "",
        }))
      );
      setContact(Object.assign({}, newContact));
    }
  };

  const handlePhone = (event, isValid, index) => {
    const name = event.target.name;
    const value = event.target.value ? event.target.value : " ";

    const newPhoneList = phones.map((el, i) => {
      if (i === index) {
        if (name === "phone_type") {
          return Object.assign({}, el, { phone_type: value });
        }
        if (name === "phone") {
          return Object.assign({}, el, { phone: value });
        }
        if (name === "country_code") {
          return Object.assign({}, el, { country_code: value });
        }
      }
      return Object.assign({}, el);
    });

    const newPhoneValidator = phoneValidator.map((validator, i) => {
      if (i === index) {
        if (name === "phone_type") {
          if (value === "Primary") {
            const hasMultiplePrimaries =
              newPhoneList.filter((phone) => phone.phone_type === "Primary")
                .length > 1;
            if (hasMultiplePrimaries) {
              return Object.assign(
                {},
                validator,
                { phone_type: false },
                { message: "Multiplos telefones primários detectados" }
              );
            }
          }

          const hasOnePrimary = newPhoneList.some(
            (p) => p.phone_type === "Primary"
          );

          if (!hasOnePrimary) {
            return Object.assign(
              {},
              validator,
              { phone_type: false },
              { message: "Necessário pelo menos um telefone primário" }
            );
          }
          return Object.assign(
            {},
            validator,
            { phone_type: isValid },
            { message: "" }
          );
        }
        if (name === "phone") {
          return Object.assign({}, validator, { phone: isValid });
        }
      }
      return Object.assign({}, validator);
    });

    setPhones(newPhoneList);
    setPhoneValidator(newPhoneValidator);
  };

  const handleActions = (event, index) => {
    const name = event.target.name;
    const value = event.target.value;

    const newActions = actions.map((el, i) => {
      if (i === index) {
        if (name === "receive_sms") {
          return Object.assign({}, el, { receive_sms: value });
        }
        if (name === "receive_email") {
          return Object.assign({}, el, { receive_email: value });
        }
        if (name === "receive_whatsapp") {
          return Object.assign({}, el, { receive_whatsapp: value });
        }
      }
      return Object.assign({}, el);
    });

    setActions(newActions);
  };

  const processError = (err) => {
    const response = err.response;
    if (response) {
      const errCode = response.status;
      let errMsg = "";
      if (response.data) {
        console.log(response.data);
        const dataKey = Object.keys(response.data)[0];
        switch (dataKey) {
          case "email": {
            errMsg = (
              <FormattedMessage
                id="contact.error.email"
                defaultMessage="email digitado não está correto"
              />
            );
            break;
          }
          case "non_field_errors":
            if (response.data[dataKey][0] === "No primary phone") {
              errMsg = <FormattedMessage
                id="contact.error.noPrimary"
                defaultMessage="Este contato não possui telefones primários"
              />
            } else if (response.data[dataKey][0] === "Two primary phones") {
              errMsg = <FormattedMessage
                id="contact.error.twoPrimaries"
                defaultMessage="Este contato possui dois telefones primários"
              />
            } else {
              errMsg = response.data[dataKey][0];
            }
            break;
          default: {
            if (response.data[dataKey] === 'Family adm already exists') {
              errMsg = <FormattedMessage
                id="contact.error.familyAdmExists"
                defaultMessage="Já existe outro contato como familiar administrador"
              />
            } else {
              errMsg = response.data[dataKey][0];
            }
            break;
          }
        }
      }
      setError(true);
      setIsSaving(false);
      setErrorCode(errCode);
      setErrorMsg(errMsg);
    }
  };

  const saveContact = () => {
    console.log(isFamily);
    const { method } = props.match.params;
    const lang = !localStorage.contacttoLang
      ? "pt"
      : localStorage.contacttoLang;
    const serverPhones = phones.map((el) =>
      Object.assign({}, el, { phone: general.removeNonDigit(el.phone, "join") })
    );
    const serverActions = actions.map((el) => Object.assign({}, el));
    const serverContact = Object.assign(
      {},
      contact,
      { number: contact.number ? contact.number : 0 },
      { zip_code: general.removeNonDigit(contact.zip_code, true) },
      { actions: serverActions },
      { phones: serverPhones },
      { family_adm_flag: isFamily }
    );
    const text = `${broadcastMessages[lang]["broadcast.contact"]} ${
      contact.first_name
    } ${contact.last_name} ${
      method === "edit"
        ? broadcastMessages[lang]["broadcast.updated"]
        : broadcastMessages[lang]["broadcast.added"]
    }`;
    setIsSaving(true);
    if (method === "edit") {
      props
        .editContact(props.match.params.clientId, serverContact)
        .then(() => {
          general.broadcast(axios, props.channelId, {
            type: "contact",
            method: "toggle",
            clientId: props.match.params.clientId,
            userId: props.loggedAttendantId,
            contact: serverContact,
            text,
          });
          props.history.push(
            `/app/contacts/list/${props.match.params.clientId}`
          );
        })
        .catch((err) => processError(err));
    } else {
      props
        .createContact(props.match.params.clientId, serverContact)
        .then((res) => {
          general.broadcast(axios, props.channelId, {
            type: "contact",
            method: "create",
            clientId: props.match.params.clientId,
            userId: props.loggedAttendantId,
            contact: res.contact,
            text,
          });
          props.history.push(
            `/app/contacts/list/${props.match.params.clientId}`
          );
        })
        .catch((err) => processError(err));
    }
  };

  const destroyContact = () => {
    const text = `${broadcastMessages[lang]["broadcast.contact"]} ${contact.first_name} ${contact.last_name} ${broadcastMessages[lang]["broadcast.deleted"]}`;
    props
      .destroyContact(props.match.params.clientId, contact)
      .then(() => {
        general.broadcast(axios, props.channelId, {
          type: "contact",
          method: "edit",
          clientId: props.match.params.clientId,
          userId: props.loggedAttendantId,
          contact,
          text,
        });
        props.history.push(`/app/contacts/list/${props.match.params.clientId}`);
      })
      .catch((err) => console.log(err));
  };

  const addPhone = () => {
    const newPhones = [
      ...phones,
      {
        phone_type: "",
        phone: "",
        contact: props.match.params.contactId,
      },
    ];
    const newPhoneValidator = [
      ...phoneValidator,
      {
        phone_type: false,
        phone: false,
        message: "",
      },
    ];

    setPhones(newPhones);
    setPhoneValidator(newPhoneValidator);
  };

  const removePhone = (event, index) => {
    const newPhones = [...phones.slice(0, index), ...phones.slice(index + 1)];
    const newPhoneValidator = [
      ...phoneValidator.slice(0, index),
      ...phoneValidator.slice(index + 1),
    ];
    setPhones(newPhones);
    setPhoneValidator(newPhoneValidator);
  };
  const isFormValidated = Object.keys(validator).every(
    (el) => contact[el] !== "" && validator[el].validate
  );
  const arePhonesValidated = Object.keys(phoneValidator).every(
    (el) =>
      phoneValidator[el].phone === true &&
      phoneValidator[el].phone_type === true
  );
  const isWhatsAppValid = actionValidator.whatsAppCellphone.isValid;
  const haveOnePrimary = phones.some((p) => p.phone_type === "Primary");
  const activeSaveBtn =
    isFormValidated && arePhonesValidated && haveOnePrimary && isWhatsAppValid;
  const title =
    props.match.params.method === "edit" ? (
      <FormattedMessage
        id="contact.form.editHeader"
        defaultMessage="EDITAR CONTATO DE"
      />
    ) : (
      <FormattedMessage
        id="contact.form.addHeader"
        defaultMessage="ADICIONAR CONTATO DE"
      />
    );
  return (
    <>
      {error && (
        <ErrorComponent
          errorCode={errorCode}
          errorMsg={errorMsg}
          handleButton={closeErrorModal}
        />
      )}

      {showWarningModal && (
        <ConfirmRemoveModal
          action={destroyContact}
          setModalInfo={() => setShowWarningModal(false)}
          message="Aviso, esta opção vai excluir este contato de emergência, você tem certeza disso?"
        />
      )}

      <div className="contactto-middle-content" style={{ overflowX: "hidden" }}>
        {client && (
          <div className={style.title}>
            {title}{" "}
            <span className="grey">
              {`${client.first_name} ${client.last_name}`.toUpperCase()}
            </span>
          </div>
        )}

        {contact && (
          <form style={{ marginTop: "2rem" }}>
            <div className="block-title">
              <FormattedMessage
                id="contact.form.personal"
                defaultMessage="PESSOAL"
              />
            </div>
            <div className="col-sm-12 form-row">
              {client && (
                <div
                  className="form-group col-12"
                  style={{ paddingTop: "1rem" }}
                >
                  <span className="text-muted">
                    <FormattedMessage
                      id="contact.form.account"
                      defaultMessage="Conta"
                    />
                    :{" "}
                  </span>
                  <span className="col-6">{client.account}</span>
                </div>
              )}
              <div className="form-group form-row col-12">
                <div className="form-group col-sm-12 col-md-6">
                  <InputContainer
                    name="first_name"
                    maxLength="50"
                    onChange={setContactState}
                    title={
                      <FormattedMessage
                        id="inputContainer.firstName"
                        defaultMessage="Nome: "
                      />
                    }
                    validator={validator.first_name}
                    isRequired
                    value={contact.first_name}
                  />
                </div>
                <div className="form-group col-sm-12 col-md-6">
                  <InputContainer
                    name="last_name"
                    maxLength="50"
                    onChange={setContactState}
                    title={
                      <FormattedMessage
                        id="inputContainer.lastName"
                        defaultMessage="Sobrenome: "
                      />
                    }
                    validator={validator.last_name}
                    isRequired
                    value={contact.last_name}
                  />
                </div>
                <div className="form-group col-sm-12 col-md-3">
                  <InputContainer
                    name="priority"
                    maxLength="2"
                    onChange={setContactState}
                    title={
                      <FormattedMessage
                        id="inputContainer.priority"
                        defaultMessage="Prioridade: "
                      />
                    }
                    validator={validator.priority}
                    isRequired
                    type="number"
                    value={contact.priority}
                  />
                </div>
                <div className="form-group col-sm-12 col-md-3">
                  <label className="text-muted" htmlFor="has_keys">
                    <FormattedMessage
                      id="contact.form.isContractor"
                      defaultMessage="Contratante do Serviço"
                    />
                    :
                  </label>
                  <SelectInput
                    selectClass="form-control"
                    items={[false, true]}
                    dictionary={general.booleanPT}
                    value={contact.is_contractor}
                    onChange={setContactState}
                    emptyOption={false}
                    name="is_contractor"
                  />
                </div>
                <div className="form-group col-sm-12 col-md-3">
                  <label className="text-muted" htmlFor="has_keys">
                    <FormattedMessage
                      id="contact.form.isFamily"
                      defaultMessage="Familiar Administrador"
                    />
                    :
                  </label>
                  <SelectInput
                    selectClass="form-control"
                    items={[false, true]}
                    dictionary={general.booleanPT}
                    value={isFamily}
                    onChange={(e) => setIsFamily(e.target.value)}
                    emptyOption={false}
                    name="is_family"
                  />
                </div>
                <div className="form-group col-sm-12 col-md-3">
                  <label className="text-muted" htmlFor="has_keys">
                    <FormattedMessage
                      id="contact.form.hasKeys"
                      defaultMessage="Possui chaves"
                    />
                    :
                  </label>
                  <SelectInput
                    selectClass="form-control"
                    items={[false, true]}
                    dictionary={general.booleanPT}
                    value={contact.has_keys}
                    onChange={setContactState}
                    name="has_keys"
                  />
                </div>
                <div className="form-group col-sm-12">
                  <InputContainer
                    name="email"
                    maxLength="100"
                    onChange={setContactState}
                    title="Email: "
                    validator={validator.email}
                    isRequired
                    value={contact.email}
                  />
                </div>
                <div className="form-group col-sm-12 col-md-3">
                  <label className="text-muted" htmlFor="relationshipList">
                    <FormattedMessage
                      id="inputContainer.relationship"
                      defaultMessage="Relação: "
                    />
                    <span className="form-required">*</span>
                  </label>
                  <SelectInput
                    id="relationshipList"
                    selectClass="form-control"
                    items={relationshipList[lang]}
                    value={selectedRelationship}
                    emptyOption={false}
                    onChange={(e) => setRelationship(e)}
                    name="relationshipList"
                  />
                </div>
                {selectedRelationship ===
                  messagesRelationships[lang][
                    messagesRelationships[lang].length - 1
                  ] && (
                  <div className="form-group col-sm-12 col-md-9">
                    <InputContainer
                      name="relationship"
                      maxLength="20"
                      onChange={setContactState}
                      title={
                        <FormattedMessage
                          id="inputContainer.relationshipIndicate"
                          defaultMessage="Indique a relação: "
                        />
                      }
                      validator={validator.relationship}
                      isRequired
                      value={contact.relationship}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="block-title">
              <FormattedMessage
                id="contact.form.contactInfo"
                defaultMessage="CONTATO"
              />
            </div>
            <div className="col-sm-12 form-row">
              <div
                className="form-group form-row col-12"
                style={{ paddingTop: "1rem" }}
              >
                <div className="form-group col-sm-6 col-md-3">
                  <InputContainer
                    name="street"
                    maxLength="50"
                    onChange={setContactState}
                    title={
                      <FormattedMessage
                        id="inputContainer.street"
                        defaultMessage="Logradouro: "
                      />
                    }
                    value={contact.street}
                  />
                </div>
                <div className="form-group col-sm-6 col-md-3">
                  <InputContainer
                    name="number"
                    maxLength="10"
                    onChange={setContactState}
                    title={
                      <FormattedMessage
                        id="inputContainer.number"
                        defaultMessage="Número: "
                      />
                    }
                    value={contact.number}
                  />
                </div>
                <div className="form-group col-sm-6 col-md-3">
                  <InputContainer
                    name="complement"
                    maxLength="30"
                    onChange={setContactState}
                    title={
                      <FormattedMessage
                        id="inputContainer.complement"
                        defaultMessage="Complemento: "
                      />
                    }
                    value={contact.complement}
                  />
                </div>
                <div className="form-group col-sm-6 col-md-3">
                  <InputContainer
                    name="district"
                    maxLength="30"
                    onChange={setContactState}
                    title={
                      <FormattedMessage
                        id="inputContainer.district"
                        defaultMessage="Bairro: "
                      />
                    }
                    value={contact.district}
                  />
                </div>
                <div className="form-group col-sm-6 col-md-3">
                  <InputContainer
                    name="city"
                    maxLength="30"
                    onChange={setContactState}
                    title={
                      <FormattedMessage
                        id="inputContainer.city"
                        defaultMessage="Cidade: "
                      />
                    }
                    value={contact.city}
                  />
                </div>
                <div className="form-group col-sm-6 col-md-3">
                  <label className="text-muted" htmlFor="state">
                    <FormattedMessage
                      id="contact.form.state"
                      defaultMessage="Estado"
                    />
                    :
                    {contact.country === "BR" && (
                      <span className="form-required">*</span>
                    )}
                  </label>
                  <SelectInput
                    selectClass="form-control"
                    items={countryStateList}
                    value={contact.state}
                    emptyOption={false}
                    onChange={setContactState}
                    name="state"
                  />
                </div>
                <div className="form-group col-sm-6 col-md-3">
                  <InputContainer
                    name="zip_code"
                    maxLength="15"
                    onChange={setContactState}
                    title={
                      <FormattedMessage
                        id="inputContainer.zipCode"
                        defaultMessage="CEP: "
                      />
                    }
                    value={contact.zip_code}
                  />
                </div>
                <div className="form-group col-sm-6 col-md-3">
                  <label className="text-muted" htmlFor="country">
                    <FormattedMessage
                      id="general.country"
                      defaultMessage="País"
                    />
                    :{" "}
                  </label>
                  <SelectInput
                    selectClass="form-control"
                    items={countryList[lang]}
                    value={contact.country}
                    onChange={setContactCountry}
                    name="country"
                  />
                </div>
              </div>
              <div className="col-12 form-row">
                {phones &&
                  phones.map((el, index) => (
                    <div key={`phone-${index}`} className="col-md-6">
                      <Telephone
                        onChange={handlePhone}
                        phone={el}
                        index={index}
                        validator={phoneValidator[index]}
                        country={contact.country}
                        removePhone={(ev) => removePhone(ev, index)}
                      />
                    </div>
                  ))}
              </div>
              <div
                className="form-group form-row"
                style={{ paddingRight: "5px", paddingLeft: "5px" }}
              >
                <button
                  type="button"
                  className="btn btn--add-contact-phone btn--color-green btn--right col-12"
                  onClick={addPhone}
                >
                  <span style={{ paddingRight: "0.5rem" }}>
                    <i className="fas fa-phone" aria-hidden="true"></i>
                  </span>{" "}
                  <FormattedMessage
                    id="contact.form.addPhoneButton"
                    defaultMessage="Adicionar novo telefone"
                  />
                </button>
              </div>
            </div>

            <div className="block-title">
              <FormattedMessage
                id="contact.form.actionsHeader"
                defaultMessage="AÇÕES"
              />
            </div>
            <div className="col-sm-12 form-row">
              <div className="form-group form-row">
                <div className="form-group col-12">
                  <div className="form-row">
                    <div className="col-6" />
                    <div
                      className="text-muted small col-2"
                      style={{
                        textTransform: "uppercase",
                        paddingLeft: "15px",
                      }}
                    >
                      <small>Email</small>
                    </div>
                    <div
                      className="text-muted small col-2"
                      style={{
                        textTransform: "uppercase",
                        paddingLeft: "15px",
                      }}
                    >
                      <small>SMS</small>
                    </div>
                    {props.isSysUser &&
                      props.globalConfiguration?.can_use_whatsapp && (
                        <div
                          className="text-muted small col-2"
                          style={{
                            textTransform: "uppercase",
                            paddingLeft: "15px",
                          }}
                        >
                          <small>Whatsapp</small>
                        </div>
                      )}
                  </div>
                </div>
                {actions &&
                  actions
                    .sort((actA, actB) => actA.priority - actB.priority)
                    .map((action, index) => (
                      <div
                        key={`action${action.priority}`}
                        className="block-content-container contactto-100"
                      >
                        <div className="left text-muted col-6">
                          {contactActionPriority(action.priority, "fromServer")}
                        </div>
                        <div className="col-2">
                          <CheckboxInput
                            name="receive_email"
                            className={`${style.checkbox}`}
                            checked={actions[index].receive_email}
                            onChange={(ev) => handleActions(ev, index)}
                          />
                        </div>
                        <div className="col-2">
                          <CheckboxInput
                            name="receive_sms"
                            className={`${style.checkbox}`}
                            checked={actions[index].receive_sms}
                            onChange={(ev) => handleActions(ev, index)}
                          />
                        </div>
                        {props.isSysUser &&
                          props.globalConfiguration?.can_use_whatsapp && (
                            <div className="col-2">
                              <CheckboxInput
                                name="receive_whatsapp"
                                className={`${style.checkbox}`}
                                checked={actions[index].receive_whatsapp}
                                onChange={(ev) => handleActions(ev, index)}
                              />
                            </div>
                          )}
                      </div>
                    ))}
                <div className="contactto-100" style={{ marginBottom: "15px" }}>
                  <div className={style["action-error"]}>
                    {actionValidator.whatsAppCellphone.message}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 row" style={{ marginBottom: "15px" }}>
              <button
                type="button"
                className={`btn btn--form btn--color-red ${
                  props.isSysAdmin && props.match.params.method === "edit"
                    ? "col-4"
                    : "col-6"
                }`}
                onClick={() =>
                  props.history.push(
                    `/app/contacts/list/${props.match.params.clientId}`
                  )
                }
              >
                <span>
                  <i
                    className="far fa-window-close"
                    aria-hidden="true"
                    style={{ paddingRight: "1rem", paddingTop: "0.2rem" }}
                  />
                </span>
                <FormattedMessage
                  id="contact.form.cancel"
                  defaultMessage="Cancelar"
                />
              </button>
              {activeSaveBtn ? (
                <button
                  type="button"
                  disabled={isSaving}
                  className={`btn btn--form btn--color-green
                    ${
                      props.isSysAdmin && props.match.params.method === "edit"
                        ? "col-4"
                        : "col-6"
                    } ${isSaving ? "btn--color-invalid" : ""}`}
                  onClick={saveContact}
                >
                  <span>
                    {" "}
                    <i
                      className="far fa-save fa-lg"
                      aria-hidden="true"
                      style={{ paddingRight: "1rem", paddingTop: "0.2rem" }}
                    />
                  </span>{" "}
                  <FormattedMessage
                    id="contact.form.save"
                    defaultMessage="Salvar"
                  />
                </button>
              ) : (
                <button
                  type="button"
                  className={`btn btn--form btn--color-gray ${
                    props.isSysAdmin && props.match.params.method === "edit"
                      ? "col-4"
                      : "col-6"
                  } ${style.savetip}`}
                >
                  <span>
                    {" "}
                    <i
                      className="far fa-save fa-lg"
                      aria-hidden="true"
                      style={{ paddingRight: "1rem", paddingTop: "0.2rem" }}
                    />
                  </span>
                  <FormattedMessage
                    id="contact.form.save"
                    defaultMessage="Salvar"
                  />
                  <span className={style["savetip-container"]}>
                    <span
                      className={style["savetip-text"]}
                      style={{ marginBottom: "10px" }}
                    >
                      <FormattedMessage
                        id="form.info.save"
                        defaultMessage="Você precisa preencher as seguintes informações para poder salvar:"
                      />
                    </span>
                    {!isFormValidated && (
                      <span className={style["savetip-text"]}>
                        <FormattedMessage
                          id="form.info.contact_personal"
                          defaultMessage="Pessoal - Nome, sobrenome, prioridade, relação e email"
                        />
                      </span>
                    )}
                    {!arePhonesValidated && haveOnePrimary && (
                      <span className={style["savetip-text"]}>
                        <FormattedMessage
                          id="form.info.contact_phone"
                          defaultMessage="Telefone - Pelo menos um primário"
                        />
                      </span>
                    )}
                  </span>
                </button>
              )}
              {props.isSysAdmin && props.match.params.method === "edit" && (
                <button
                  type="button"
                  className={`btn btn--form ${style["exclude-button--color-gray"]} col-4`}
                  onClick={() => {
                    setShowWarningModal(true);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  <span style={{ fontWeight: "bold", color: "white" }}>
                    <i
                      className="fas fa-user-times"
                      style={{ paddingRight: "1rem", paddingTop: "0.2rem" }}
                    />
                    <FormattedMessage
                      id="form.button.exclude"
                      defaultMessage="Excluir"
                    />
                  </span>
                </button>
              )}
            </div>
          </form>
        )}
      </div>
    </>
  );
};

ContactForm.propTypes = {
  loggedAttendantId: PropTypes.number.isRequired,
  channelId: PropTypes.string.isRequired,
  isSysAdmin: PropTypes.bool.isRequired,
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string,
      street: PropTypes.string,
      number: PropTypes.number,
      complement: PropTypes.string,
      district: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      country: PropTypes.string,
      zip_code: PropTypes.string,
      has_keys: PropTypes.bool,
      relationship: PropTypes.string,
      priority: PropTypes.number,
      client: PropTypes.number,
      is_active: PropTypes.bool,
      created_date: PropTypes.string,
      modified_date: PropTypes.string,
      actions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          priority: PropTypes.number,
          contact: PropTypes.number,
          receive_sms: PropTypes.bool,
          receive_email: PropTypes.bool,
        })
      ),
      phones: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          phone_type: PropTypes.string,
          phone: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  editContact: PropTypes.func.isRequired,
  createContact: PropTypes.func.isRequired,
  loadContacts: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    contacts: state.contacts,
    params: ownProps.params,
    equipment: state.equipment.length > 0 && state.equipment[0].data,
    loggedAttendantId: state.loggedAttendant.id ? state.loggedAttendant.id : 0,
    isSysUser: state.loggedAttendant.groups.length === 1,
    isSysAdmin: state.loggedAttendant.groups
      ? (state.loggedAttendant.groups[0] === 1 ||
          state.loggedAttendant.groups[0] === 4) &&
        state.loggedAttendant.groups.length === 1
      : false,
    globalConfiguration: state.globalConfiguration.global,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadContacts: (clientId, ordering) =>
      dispatch(contactsActions.loadContacts(clientId, ordering)),
    editContact: (clientId, contact) =>
      dispatch(contactsActions.editContact(clientId, contact)),
    createContact: (clientId, contact) =>
      dispatch(contactsActions.createContact(clientId, contact)),
    destroyContact: (clientId, contact) =>
      dispatch(contactsActions.destroyContact(clientId, contact)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
