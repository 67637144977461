import React from 'react';
import PropTypes from 'prop-types';

const Speak = ({ height, className, width }) => (
  <svg
    className={className}
    fill="#fff"
    width={width}
    height={height}
    viewBox="100 100 841.891 595.279"
    enableBackground="new 0 0 526.347 520.844"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fill="transparent" d="M720.085,297.64c0,165.207-133.933,299.14-299.14,299.14c-165.206,0-299.14-133.933-299.14-299.14
                        c0-165.207,133.934-299.14,299.14-299.14C586.152-1.5,720.085,132.433,720.085,297.64z"
      />
      <g>
        <g>
          <path
            fill="#FAFBFC" d="M405.048,471.902v-75.414c-0.139-12.839-10.562-23.201-23.394-23.402h-40.698
                                c-3.014-0.414-5.758-1.982-7.688-4.32c-10.225-10.239-21.264-36.854-20.926-71.24c-0.139-18.935,3.506-37.739,10.916-55.167
                                c6.381-14.068,13.723-20.38,18.12-20.38h40.683c12.9,0,23.332-10.424,23.401-23.262v-0.062v-75.416
                                c-0.139-12.838-10.571-23.193-23.401-23.401l-43.643-0.069c-50.292,0-90.236,86.87-90.236,197.832
                                c-0.339,77.406,20.11,148.164,51.945,179.99c9.747,10.916,23.477,17.374,38.092,17.912l43.643-0.077
                                c6.172-0.062,12.068-2.599,16.396-6.995C402.579,484.048,405.048,478.068,405.048,471.902z M381.515,483.556l-17.021-0.261v0.123
                                l-26.621-0.062c-11.256-0.631-21.826-5.766-29.306-14.13c-29.574-29.581-48.647-96.972-48.647-171.626
                                c0-100.53,35.894-185.962,78.43-186.032h26.145l16.943-0.2c6.389,0,11.539,5.143,11.539,11.524v75.415
                                c0,6.381-5.081,11.462-11.462,11.524c0,0,0,0-0.077,0h-17.357l-0.062,0.276l-22.986,0.139
                                c-10.432-0.069-20.933,9.948-28.889,27.245c-8.165,18.866-12.354,39.314-12.224,59.833c0,32.726,10.094,64.583,24.915,79.313
                                c4.113,4.674,9.947,7.502,16.197,7.841l23.187,0.062v0.139h17.366c6.312,0.139,11.316,5.212,11.523,11.531v75.415
                                C393.177,478.144,387.966,483.495,381.515,483.556z"
          />
        </g>
        <path
          id="telephone_send_1_" fill="#FAFBFC" d="M624.713,300.346c1.03-2.537,0.477-5.489-1.521-7.479l-54.891-54.898
                            c-2.891-2.467-7.195-2.129-9.686,0.753c-2.184,2.545-2.184,6.381,0,8.925l43.234,43.227H398.805c-3.775,0-6.857,3.091-6.857,6.857
                            c0,3.783,3.082,6.873,6.857,6.873h203.044l-43.158,43.158c-2.875,2.46-3.213,6.796-0.753,9.671s6.796,3.229,9.687,0.753
                            c0.276-0.199,0.538-0.477,0.738-0.753l54.904-54.905C623.867,301.914,624.359,301.176,624.713,300.346z"
        />
      </g>
    </g>
  </svg>
);

Speak.defaultProps = {
  height: '64',
  className: '',
  width: '64',
};

Speak.propTypes = {
  height: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
};

export default Speak;
