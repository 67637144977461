import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import Paginate from '../../Common/Paginate';
import { getBrowserMaxWidth, broadcast } from '../../../BLL/general';
import * as globals from '../../../BLL/globals';
import broadcastMessages from '../../../BLL/locale/messagesBroadcast';
import TableHeader from './ClientPageTableHeader';
import MobileHeader from './ClientPageMobileTableHeader';
import ButtonComponent from './ClientPageButtonComponent';
import FilterComponent from './ClientFilter';
import ClientRow from './ClientRow';
import ClientMobileRow from './ClientMobileRow';
import ConfirmRemoveModal from '../../Modal/ModalConfirm';
import ModalFilter from '../../Modal/ModalClientFilter';
import useQuery from '../../Hooks/useLocationQuery';

import Loading from '../../../icons/Loading';
import ToggleButton from '../../Common/ToggleButton';

import * as clientsActions from '../../../actions/clientsActions';
import * as attendsActions from '../../../actions/attendsActions';

import style from './clientPage.module.scss';
import '../../../css/icons.scss';
import '../../../css/middle.scss';
import '../../../css/listPage.scss';

const ClientPage = (props) => {
  const queryParams = useQuery();
  const browserWidth = getBrowserMaxWidth();
  const numPerPage = 50;
  const {
    clients,
    loggedUser,
    companies,
    equipment,
    status,
    lang,
    isOwner,
    isAdmin,
    isManager
  } = useSelector(state => ({
    clients: state.clients,
    loggedUser: state.loggedAttendant,
    companies: state.groups.filter(grp => ![1, 2, 3, 4].includes(grp.id)),
    equipment: state.equipment,
    status: state.tenantStatus ? state.tenantStatus.status.status : 'active',
    lang: state.locale.lang,
    isOwner: state.loggedAttendant.groups ? state.loggedAttendant.groups[0] === 4 : false,
    isAdmin: state.loggedAttendant.groups ? state.loggedAttendant.groups[0] === 1 : false,
    isManager: state.loggedAttendant.groups ? state.loggedAttendant.groups[0] === 2 : false,
  }));
  const queryAccount = queryParams.get('account');

  const dispatch = useDispatch();

  const loadClients = (ordering, filter) => dispatch(clientsActions.loadClients(ordering, filter));
  const changePage = (pageNumber, pageUrl) => dispatch(clientsActions.changePage(pageNumber, pageUrl));
  const editClient = client => dispatch(clientsActions.editClient(client));
  const loadAttends = (ordering, filter) => dispatch(attendsActions.loadAttends(ordering, filter));

  const [loading, setLoading] = useState(true);
  const [clientList, setClientList ] = useState([]);
  const [selectedClient, setSelectedClient] = useState({});
  const [filter, setFilter] = useState('');
  const [activePage, setActivePage] = useState(1);
  const [ordering, setOrdering] = useState('');
  const [totalClients, setTotalClients] = useState(0);
  const [activeClients, setActiveClients] = useState(0);
  const [next, setNext] = useState('');
  const [previous, setPrevious] = useState('');
  const [iconDetail, setIconDetail] = useState('');
  const [showDeleteRestoreButton, setDeleteRestoreButton] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [showConfirmRemoveModal, setShowConfirmRemoveModal] = useState(false);
  const [showInactives, setShowInactives] = useState(false);
  const [confirmMsg, setConfirmModalMsg] = useState('');
  
  useEffect(() => {
    const firstLoad = (ordering, filter) => dispatch(clientsActions.loadClients(ordering, filter));

    if (!clients || Object.keys(clients).length === 0) {
      if (queryAccount) {
        firstLoad(globals.clientsOrdering, `&account=${queryAccount}`);
      } else {
        firstLoad(globals.clientsOrdering);
      }
    }
    const activeClients = clients.pages
      ? clients.pages.find(data => data.page === clients.activePage)
      : [];
    console.log(clients);
    setClientList(activeClients.results);
    setNext(activeClients.next);
    setPrevious(activeClients.previous);
    setActivePage(clients.activePage);
    setLoading(false);
  }, [dispatch, clients, queryAccount]);

  useEffect(() => setOrdering(clients.ordering), [clients.ordering]);
  useEffect(() => setFilter(clients.filter), [clients.filter]);
  useEffect(() => setTotalClients(clients.count), [clients.count]);
  useEffect(() => setActiveClients(clients.active), [clients.active]);

  useEffect(() => {
    const loadQueryClients = (ordering, filter) => dispatch(clientsActions.loadClients(ordering, filter));

    if (queryAccount) {
      setLoading(true);
      setActivePage(1);
      loadQueryClients(globals.clientsOrdering, `&account=${queryAccount}`);
    }

  }, [queryAccount, dispatch]);

  const removeFilter = () => {
    setLoading(true);
    loadClients();
  };

  const filterClients = (filter) => {
    setShowFilter(false);
    setLoading(true);
    setActivePage(1);
    loadClients(ordering, filter)
  };

  const changeCurrentPage = (page) => {
    setLoading(true);
    const pageUrl = page > activePage ? next : previous;
    changePage(page, pageUrl)
  };

  const setConfirmModalInformations = ({ showModal, client = {} }) => {
    if (showModal === false && showDeleteRestoreButton === false) {
      setDeleteRestoreButton(!showDeleteRestoreButton);
    }
    setShowConfirmRemoveModal(showModal);
    setSelectedClient(Object.assign({}, client));
    if (!client.is_deleted) {
      setConfirmModalMsg(
        <FormattedMessage
          id="client.modal.inactivateMsg"
          defaultMessage="Você está inativando a conta {clientAccount}. Tem certeza que deseja realizar esta ação?"
          values={{
            clientAccount: client.account
          }}
        />
      )
    } else {
      setConfirmModalMsg(
        <FormattedMessage
          id="client.modal.reactivateMsg"
          defaultMessage="Você está reativando a conta {clientAccount}, se não houver outro usuário com esta conta os caracteres extras vão desaparecer, caso contrário, os caracteres vão precisar serem removidos manualmente. Tem certeza que deseja realizar esta ação?"
          values={{
            clientAccount: client.account
          }}
        />
      )
    }
  };

  const setTextIconDetail = (iconDetail) => {
    setIconDetail(iconDetail);
  };

  const orderByAccount = () => {
    const updatedOrdering = ordering === 'account' ? '-account' : 'account';
    setLoading(true);
    setOrdering(updatedOrdering);
    loadClients(updatedOrdering, filter);
  };

  const orderByName = () => {
    const updatedOrdering = ordering === 'first_name,last_name,account' ? '-first_name,-last_name,-account' : 'first_name,last_name,account';
    setLoading(true);
    setOrdering(updatedOrdering);
    loadClients(updatedOrdering, filter);
  };

  const toggleClient = () => {
    // console.log(selectedClient);
    // let client = clientList.find(cl => cl.id === selectedClient.client_id);
    // client.is_deleted = !client.is_deleted;
    const client = Object.assign(
      {},
      clientList.find(cl => cl.id === selectedClient.client_id),
      { is_deleted: !selectedClient.is_deleted},
    );
    const text = `${broadcastMessages[lang]['broadcast.client']} ${client.first_name} ${client.last_name} ${
      client.is_deleted ? broadcastMessages[lang]['broadcast.deleted'] : broadcastMessages[lang]['broadcast.recovered']}`;

    editClient(client).then(() => {
      setDeleteRestoreButton(!showDeleteRestoreButton);
      broadcast(axios, props.channelId, {
        type: 'client',
        method: 'toggle',
        userId: loggedUser.id,
        client,
        text
      });
    });
    setConfirmModalInformations(false, {});
  };

  const checkUserRow = (client) => {
    const user = loggedUser;
    const isSystemUser = user.groups.length === 1;
    if (!client.is_deleted) {
      return isSystemUser || user.groups.includes(client.group_id);
    }
    return showInactives && (
      (isSystemUser && (isAdmin || isOwner || isManager))
      || (user.groups.includes(client.group_id) && isAdmin)
    );
  };

  const performFilter = (clientId) => {
    const updatedFilter = `client=${clientId}`;
    loadAttends(globals.attendsOrdering, updatedFilter).then(() => {
      props.history.push('/app/attends');
    });
  };

  console.log(clientList);

  return (
    <div className="contactto-middle-content" style={{ overflow: 'hidden' }}>
      <div className={style.title}>
        <FormattedMessage id="client.page.header" defaultMessage="USUÁRIOS" />
        <FilterComponent filter={filter} lang={lang} equipment={equipment} companies={companies} />
        <ButtonComponent
          clientList={clientList}
          filter={filter}
          status={status}
          browserHistory={props.history}
          removeFilter={removeFilter}
          showFilterModal={() => setShowFilter(true)}
          editFilter={() => setShowFilter(true)}
        />
      </div>
      {showFilter &&
        <ModalFilter
          filter={filter}
          filterClients={filterClients}
          closeModal={() => setShowFilter(false)}
        />
      }
      {(isAdmin || isOwner || isManager) &&
        <div className={style['toggle-container']}>
          <ToggleButton
            isChecked={showInactives}
            hasPagination={clientList > numPerPage}
            handleChange={() => setShowInactives(!showInactives)}
          />
        </div>
      }
      <div className={`${style.table}`}>
        <Paginate
          outerClass={style['paginate-wrapper']}
          numberOfElements={showInactives ? totalClients : activeClients}
          numPerPage={numPerPage}
          setPage={changeCurrentPage}
          page={activePage}
        />
        {showConfirmRemoveModal &&
          <ConfirmRemoveModal
            action={toggleClient}
            setModalInfo={setConfirmModalInformations}
            message={confirmMsg}
          />
        }
        { browserWidth > 980
          ? 
            <TableHeader
              isAdmin={isAdmin}
              isOwner={isOwner}
              ordering={ordering}
              orderByAccount={orderByAccount}
              orderByName={orderByName}
              iconDetail={iconDetail}
            />
          :
            <MobileHeader
              isAdmin={isAdmin}
              isOwner={isOwner}
              ordering={ordering}
              orderByAccount={orderByAccount}
              orderByName={orderByName}
              iconDetail={iconDetail}
            />
        }
        <div className={style['table-body']} id="client-table-body">
          {loading
            ? <Loading text={<FormattedMessage id="attend.list.loading" defaultMessage="Carregando" />} />
            : clientList && clientList.map(client => (
              checkUserRow(client)
              ?
                <div key={`${client.id}${client.is_secondary ? `-${client.client_id}` : ''}`}>
                  { browserWidth > 980
                    ?
                      <ClientRow
                        key={`${client.id}-${client.client_id}`}
                        client={client}
                        setTextIconDetail={setTextIconDetail}
                        setModalInfo={setConfirmModalInformations}
                        isAdmin={isAdmin}
                        isOwner={isOwner}
                        isManager={isManager}
                        recoverClient={() => {}}
                        showModal={showConfirmRemoveModal}
                        buttonStatus={showDeleteRestoreButton}
                        setButtonStatus={() => setDeleteRestoreButton(!showDeleteRestoreButton)}
                        performFilter={performFilter}
                      />
                    :
                      <ClientMobileRow
                        key={client.id}
                        client={client}
                        setTextIconDetail={setTextIconDetail}
                        setModalInfo={setConfirmModalInformations}
                        isAdmin={isAdmin}
                        isOwner={isOwner}
                        isManager={isManager}
                        recoverClient={() => {}}
                        showModal={showConfirmRemoveModal}
                        buttonStatus={showDeleteRestoreButton}
                        setButtonStatus={() => setDeleteRestoreButton(!showDeleteRestoreButton)}
                        performFilter={performFilter}
                      />
                  }
                </div>
              : null
            ))
          }
        </div>
      </div>
    </div>
  );
};

ClientPage.propTypes = {
  channelId: PropTypes.string.isRequired, 
}

export default ClientPage;
