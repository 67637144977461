import React from 'react';
import PropTypes from 'prop-types';

const AlarmLostFireIco = ({ height, svgClass, width, fill, transform }) => (
  <svg className={svgClass} width={width} height={height} fill={fill} transform={transform} viewBox="0 0 140 140" xmlns="http://www.w3.org/2000/svg">
<path d="M131.7,110.4c-4.2-7.2-8.3-14.4-12.4-21.6C106.1,66,93,43.3,79.8,20.5c-2-3.4-5.1-5.1-9-5.1s-7,1.7-9,5.1
	c-10,17.3-20,34.6-29.9,51.9c-7.2,12.5-14.5,25-21.6,37.6c-0.9,1.6-1.7,3.4-1.8,5.2c-0.4,6.4,4.3,11.2,10.8,11.2
	c17.2,0,34.3,0,51.5,0c17.1,0,34.2,0,51.4,0c1.4,0,2.9-0.2,4.2-0.7C132.3,123.4,135.2,116.4,131.7,110.4z M71.6,117.9
	c-4.3-2.1-8.7-3.9-12.8-6.3c-6.8-4.1-11.8-9.8-13.2-18c-0.2-1-0.4-1.9-0.5-2.9c0-2.1,0-4.2,0-6.3c0.6-3.1,1-6.2,1.9-9.2
	c1.3-4.1,3-8.1,4.7-11.9c0.1,2.6,0,5.3,0.5,7.8c0.5,3,2.6,4.8,5.6,5.6c7.6,1.9,14.2-3.2,14.9-11.4c0.6-7-1.3-15.5-6.6-21.4
	c0.1,0,0.3-0.1,0.3-0.1c9.1,4.7,16.5,11,19.3,21.4c1.9,7.3,0.5,14.3-2.9,20.9c-1.8,3.6-4.1,6.9-6.4,10.2c-3.6,4.9-6.2,10.1-5.3,16.3
	c0.2,1.8,0.8,3.5,1.1,5.3C72.1,117.9,71.8,117.9,71.6,117.9z M96.6,93.6c-0.3,1.3-0.6,2.5-0.9,3.8c-1.4,4.6-4.3,8.2-8.2,11
	c-3.9,2.8-7.8,5.5-11.7,8.4c-1.4-3-1.4-6.1-0.5-9.3c1.1-3.8,3.1-7.1,5.7-10.2c5.3-6.3,7.9-13.7,9-21.7c0.1-1,0-2.1,0-3.4
	c3.2,4.9,5.5,9.8,6.4,15.3c0.1,0.5,0.3,1.1,0.4,1.6C96.6,90.6,96.6,92.1,96.6,93.6z"/>
<g>
	<path d="M131.9,135.9c-1.3,0-2.6-0.5-3.5-1.5L6.2,12.2c-2-2-2-5.1,0-7.1c2-2,5.1-2,7.1,0l122.2,122.2c2,2,2,5.1,0,7.1
		C134.5,135.4,133.2,135.9,131.9,135.9z"/>
</g>
  </svg>
);

AlarmLostFireIco.defaultProps = {
  height: '75',
  svgClass: '',
  width: '75',
  fill: '#F69B38',
  transform: 'translate(0,0)',
};

AlarmLostFireIco.propTypes = {
  height: PropTypes.string,
  svgClass: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
  transform: PropTypes.string,
};

export default AlarmLostFireIco;
