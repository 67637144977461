import * as types from './actionTypes';

export function localeSet(lang) {
  return { type: types.LOCALE_SET, lang };
}

export function setLocale(lang) {
  localStorage.contacttoLang = lang;
  return (dispatch, getState, { axios }) => new Promise((resolve, reject) =>
    resolve(dispatch(localeSet(lang))),
  );
}
