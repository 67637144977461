import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function alarmsReducer(state = initialState.alarms, action) {
  let newState = []
  let alarmsList = [];
  switch (action.type) {
    case types.LOAD_ALARMS_SUCCESS:
      return [{
        ordering: action.ordering,
        data: action.alarms,
      }];
    case types.EDIT_ALARM:
      alarmsList = state;
      newState = alarmsList.map(dataList => {
        if (dataList.data && dataList.data.length > 0) {
          return {
            ordering: dataList.ordering,
            data: dataList.data.map(alarm => alarm.id === action.alarm.id ? action.alarm : alarm),
          };
        }
        return dataList;
      });
      return newState;
    case types.NEW_ALARM:
      alarmsList = state;
      newState = alarmsList.map(dataList => {
        return {ordering: dataList.ordering, data: [...dataList.data, action.alarm]};
      });
      return newState;
    default:
      return state;
  }
}



// /*
//   Inputs:
//     state = Estado atual do objeto, caso não seja passado é utilizado o estado default do arquivo(initalstate.alarms)
//     action = Ação aplicada sobre o objeto

//   Types:
//     LOAD_ALARMS_SUCCESS = Alarmes carregados com sucesso

//   hasOrdering:
//     Alarme vindo do state

//    AlarmReducer:
//     Identifica o alarme presente no state se necessita de ordenação e retorna o alarme ordernado ou não.
// */
// export default function alarmsReducer(state = initialState.alarms, action) {
//   const hasOrdering = state.some(alarms => alarms.ordering === action.ordering);
//   if (action.type === types.LOAD_ALARMS_SUCCESS && !hasOrdering) {
//     return [
//       ...state,
//       {
//         ordering: action.ordering,
//         data: action.alarms,
//       },
//     ];
//   }
//   if (action.type === types.RELOAD_ALARMS_SUCCESS) {
//     return [
//       {
//         ordering: action.ordering,
//         data: action.alarms,
//       },
//     ];
//   }
//   return state;
// }
