import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import * as statusActions from '../../actions/userStatusActions';
import * as attendsActions from '../../actions/attendsActions';
import '../../css/buttons.scss';

const CallButton = (props) => {
  const { attend } = props;

  const isBusy = useSelector(state => state.status.length > 0 ? state.status.some(value => value.userId === state.loggedAttendant.id) : false);
  const user = useSelector(state => state.loggedAttendant);
  const globalConfiguration = useSelector(state => state.globalConfiguration.global);

  const dispatch = useDispatch();
  const addUserIntoBusy = (userId, attendId) => dispatch(statusActions.addUserIntoBusy(userId, attendId));
  const editAttend = (attend) => dispatch(attendsActions.editAttend(attend));

  const [isLoading, setLoading] = useState(false);

  const redirectToAttendDetail = (attend) => {
    props.setUserBusy(true);
    props.history.push(
      `/app/attends/${attend.close_ts ? 'closed' : 'call'}/${attend.id}/`,
    )
  }

  const takeEmergency = () => {
    if (!attend.close_ts) {
      setLoading(true);
      addUserIntoBusy(user.id, attend.id);
      const newAttend = Object.assign(
        {},
        attend,
        { 
          status: 'IN_PROGRESS',
          attendant: user.id,
          audio_call: attend.audio_call
        }
      );
      editAttend(newAttend).then(() => {
        redirectToAttendDetail(attend);
      }).catch((error) => {
        if (error.response?.data.already_taken) {
          setLoading(false);
        }
      })
    } else {
      setLoading(false);
    }
  };

  const closeAttend = () => {
    if (!attend.close_ts) {
      const newAttend = Object.assign(
        {},
        attend,
        {
          attend_ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
          status: 'CLOSE',
          attendant: user.id,
          audio_call: attend.audio_call
        }
      )
      editAttend(newAttend);
    }
  };

  const setButton = () => {
    let buttonClass = '';
    let isDisabled = false;
    let buttonClick = () => {};
    let buttonText = 'CARREGANDO';
    
    const useAlarmZones = globalConfiguration.use_alarm_zones;
    const loadingClass = 'btn btn--attend-emergency btn--color-loading btn-mobile';
    const orangeClass = 'btn btn--attend-emergency btn--color-orange btn-mobile';
    const blueClass = 'btn btn--attend-emergency btn--color-tecno btn-mobile';
    const greenClass = 'btn btn--attend-emergency btn--color-green btn-mobile';
    const invalid = 'btn btn--attend-emergency btn--color-invalid btn-mobile';
    const { height, borderRadius } = props;

    if (isLoading) {
      buttonClass = loadingClass;
      isDisabled = isBusy;
    }

    if (!attend.attend_ts) {
      if (useAlarmZones) {
        const attendZone = attend.tr_zone ? parseInt(attend.tr_zone) % 4 : 1;

        buttonClass = (attendZone === 0 || attend.highest_code === '100') && isBusy ? invalid : greenClass;
        isDisabled = (attendZone === 0 || attend.highest_code === '100') ? isBusy : false;
        buttonClick = (attendZone === 0 || attend.highest_code === '100') ? takeEmergency : closeAttend;
        buttonText = ((attendZone === 0 || attend.highest_code === '100')
          ? <FormattedMessage id="call.button.answer" defaultMessage="ATENDER" />
          : <FormattedMessage id="call.button.close" defaultMessage="FECHAR" />
        );

      } else {
        buttonClass = attend.highest_priority < 2 && isBusy ? invalid : greenClass;
        isDisabled = attend.highest_priority < 2 ? isBusy : false;
        buttonClick = attend.highest_priority < 2 ? takeEmergency : closeAttend;
        buttonText = (attend.highest_priority < 2
          ? <FormattedMessage id="call.button.answer" defaultMessage="ATENDER" />
          : <FormattedMessage id="call.button.close" defaultMessage="FECHAR" />
        );
      }
    } else if (!attend.close_ts) {
      buttonClick = () => redirectToAttendDetail(attend);
      if (attend.attendant === user.id || (attend.attendant && attend.attendant.id === user.id)) {
        buttonClass = greenClass;
        buttonText = <FormattedMessage id="call.button.resume" defaultMessage="RETOMAR" />;
      } else {
        buttonClass = orangeClass;
        buttonText = <FormattedMessage id="call.button.view" defaultMessage="VISUALIZAR" />;
      }
    } else {
      buttonClick = () => redirectToAttendDetail(attend);
      buttonClass = blueClass;
      buttonText = <FormattedMessage id="call.button.details" defaultMessage="DETALHES" />;
    }

    return (
      <button
        type="button"
        style={{ height, borderRadius, position: 'inherit' }}
        className={buttonClass}
        disabled={isDisabled}
        onClick={buttonClick}
      >
        {buttonText}
      </button>
    )

  }

  return (
    <div className={props.containerClass}>
      { setButton() }
    </div>
  );
}

CallButton.defaultProps = {
  isBusy: false,
  height: '',
  borderRadius: '',
};

CallButton.propTypes = {
  height: PropTypes.string,
  borderRadius: PropTypes.string,
  channelId: PropTypes.string.isRequired,
  isBusy: PropTypes.bool,
  attend: PropTypes.shape({
    id: PropTypes.number,
    client: PropTypes.number,
    attendant: PropTypes.number,
    open_ts: PropTypes.string,
    close_ts: PropTypes.string,
    status: PropTypes.string,
    audio_call: PropTypes.bool,
    attend_ts: PropTypes.string,
    highest_priority: PropTypes.number,
  }).isRequired,
  setUserBusy: PropTypes.func.isRequired,
  containerClass: PropTypes.string.isRequired,
};

export default CallButton;
