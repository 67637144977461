import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import AlarmComponent from './Alarm/AlarmComponent';
import AttendPeriodComponent from './Attend/AttendPeriodComponent';
import AttendTagComponent from './Attend/AttendTagComponent';
import AttendantComponent from './Attendant/AttendantComponent';
import ClientByCompanyComponent from './Client/ClientByCompanyComponent';
import ClientMonthlyFollowupComponent from './Client/ClientMonthlyFollowupComponent';
import ClientTotalMonthlyComponent from './Client/ClientTotalMonthlyComponent';
import ClientLengthOfStayComponent from './Client/ClientLengthOfStayComponent';
import Order from '../../../icons/Order';
import * as alarmsActions from '../../../actions/alarmsActions';
import * as attendantsActions from '../../../actions/attendantsActions';
import * as globals from '../../../BLL/globals';
import { getBrowserMaxWidth } from '../../../BLL/general';

import style from './reportPage.module.scss';

const ReportPage = (props) => {
  const color = '#ffffff';
  const alarmsOrdering = globals.alarmsOrdering;
  const attendantsOrdering = globals.attendantsOrdering;

  const lang = useSelector(state => state.locale.lang);
  const attendants = useSelector(state => state.attendants);
  const alarms = useSelector(state => state.alarms);
  const isCompanyMember = useSelector(state => state.loggedAttendant.groups.length > 1);
  const attendTags = useSelector(state => state.attendTags);
  const globalConfiguration = useSelector(state => state.globalConfiguration.global);

  const dispatch = useDispatch();
  
  const [alarmsOrdered, setAlarmsOrdered] = useState([]);
  const [attendantsOrdered, setAttendantsOrdered] = useState([]);
  const [showClientMonthlyFollowupComponent, setShowClientMonthlyFollowupComponent] = useState(false);
  const [showAlarmComponent, setShowAlarmComponent] = useState(false);
  const [showAttendantsComponent, setShowAttendantsComponent] = useState(false);
  const [showUsersByCompanyComponent, setShowUsersByCompanyComponent] = useState(false);
  const [showRetainTimeComponent, setShowRetainTimeComponent] = useState(false);
  const [showAttendByTimeComponent, setShowAttendByTimeComponent] = useState(false);
  const [showUsersByMonthComponent, setShowUsersByMonthComponent] = useState(false);
  const [showAttendsByTypeComponent, setShowAttendsByTypeComponent] = useState(false);

  useEffect(() => {
    const loadAlarms = (ordering) => dispatch(alarmsActions.loadAlarms(ordering));
    const loadAttendants = (ordering) => dispatch(attendantsActions.loadAttendants(ordering));

    const setAlarmList = (alList) => {
      const orderedAlarms = alList.find(a => a.ordering === alarmsOrdering);
      if (orderedAlarms) {
        setAlarmsOrdered(orderedAlarms.data);
      } else {
        loadAlarms(alarmsOrdering);
      }
    };

    const setAttendantList = (attList) => {
      const orderedAttendants = attList.find(a => a.ordering === attendantsOrdering);
      if (orderedAttendants) {
        setAttendantsOrdered(orderedAttendants.data);
      } else {
        loadAttendants(attendantsOrdering);
      }
    };

    setAlarmList(alarms);
    setAttendantList(attendants);
  }, [alarms, attendants, attendantsOrdering, alarmsOrdering, dispatch]);

  const rotateClientsFollowup = showClientMonthlyFollowupComponent ? style.rotate : '';
  const rotateAlarms = showAlarmComponent ? style.rotate : '';
  const rotateAttendants = showAttendantsComponent ? style.rotate : '';
  const rotateUsersByCompany = showUsersByCompanyComponent ? style.rotate : '';
  const rotateRetainTime = showRetainTimeComponent ? style.rotate : '';
  const rotateAttendByTime = showAttendByTimeComponent ? style.rotate : '';
  const rotateUsersByMonth = showUsersByMonthComponent ? style.rotate : '';
  const rotateAttendsByType = showAttendsByTypeComponent ? style.rotate : '';
  const useAlarmZones = globalConfiguration?.use_alarm_zones;

  return (
    <div className="contactto-middle-content" style={{ overflowY: 'auto' }}>
      <div className={style.title}>
        <FormattedMessage id="reports.page.header" defaultMessage="RELATÓRIOS" />
      </div>

      {/* Relatório Acompanhamento mensal de Usuários */}
      <div style={{ width: '100%', float: 'left' }}>
        <button className={style['detail-btn']} onClick={() => setShowClientMonthlyFollowupComponent(!showClientMonthlyFollowupComponent)}>
          <FormattedMessage id="reports.page.monthlyClientRepHeader" defaultMessage="Acompanhamento mensal de Usuários" />
          <Order width="25" height="15" className={`${style.trackerSvg} ${rotateClientsFollowup}`} />
        </button>
      </div>
      {showClientMonthlyFollowupComponent &&
        <div className={style.cards}>
          <div className={style['card-block']} style={{ float: 'left' }}>
            <ClientMonthlyFollowupComponent themeColor={color} style={{ width: '100%' }} />
          </div>
        </div>
      }

      {/* Relatório de Alarmes */}
      {!useAlarmZones &&
        <div style={{ width: '100%', float: 'left' }}>
          <button className={style['detail-btn']} onClick={() => setShowAlarmComponent(!showAlarmComponent)}>
            <FormattedMessage id="reports.page.alarmsRepHeader" defaultMessage="Alarmes" />
            <Order width="25" height="15" className={`${style.trackerSvg} ${rotateAlarms}`} />
          </button>
        </div>
      }

      {showAlarmComponent &&
        <div className={style.cards}>
          {alarmsOrdered.length > 0 && (
            <div className={style['card-block']} style={{ float: 'right' }}>
              <AlarmComponent
                alarms={alarmsOrdered}
                themeColor={color}
                browserWidth={getBrowserMaxWidth()}
                style={{ width: '100%' }}
              />
            </div>
          )}
        </div>
      }

      {/* Relatório de Atendentes */}
      {!isCompanyMember &&
        <div style={{ width: '100%', float: 'left' }}>
          <button className={style['detail-btn']} onClick={() => setShowAttendantsComponent(!showAttendantsComponent)}>
            <FormattedMessage id="reports.page.attendantsRepHeader" defaultMessage="Atendentes" />
            <Order width="25" height="15" className={`${style.trackerSvg} ${rotateAttendants}`} />
          </button>
        </div>
      }
      {showAttendantsComponent &&
        <div className={style.cards}>
          {attendantsOrdered.length > 0 && (
            <div className={style['card-block']} style={{ float: 'left' }}>
              <AttendantComponent
                attendants={attendantsOrdered}
                themeColor={color}
                browserWidth={getBrowserMaxWidth()}
                style={{ width: '100%' }}
              />
            </div>
          )}
        </div>
      }

      {/* Relatório Atendimento por período */}
      <div style={{ width: '100%', float: 'left' }}>
        <button className={style['detail-btn']} onClick={() => setShowAttendByTimeComponent(!showAttendByTimeComponent)}>
          <FormattedMessage id="reports.page.attsByPeriodRepHeader" defaultMessage="Atendimento por Período" />
          <Order width="25" height="15" className={`${style.trackerSvg} ${rotateAttendByTime}`} />
        </button>
      </div>
      {showAttendByTimeComponent &&
        <div className={style.cards}>
          <div className={style['card-block']} style={{ float: 'right' }}>
            <AttendPeriodComponent themeColor={color} browserWidth={getBrowserMaxWidth()} style={{ width: '100%' }} />
          </div>
        </div>
      }
    
      {/* Relatório atendimentos por tipo */}
      <div style={{ width: '100%', float: 'left' }}>
        <button className={style['detail-btn']} onClick={() => setShowAttendsByTypeComponent(!showAttendsByTypeComponent)}>
          <FormattedMessage id="reports.page.attendTagsRepHeader" defaultMessage="Atendimento por Tipo" />
          <Order width="25" height="15" className={`${style.trackerSvg} ${rotateAttendsByType}`} />
        </button>
      </div>
      {showAttendsByTypeComponent &&
        <div className={style.cards} style={{ marginBottom: '5rem' }}>
          <div className={style['card-block']} style={{ float: 'left' }}>
            <AttendTagComponent
              attendTags={attendTags}
              browserWidth={getBrowserMaxWidth()}
              lang={lang}
              themeColor={color}
              style={{ width: '100%' }}
            />
          </div>
        </div>
      }

      {/* Relatório Tempo de permanência */}
      <div style={{ width: '100%', float: 'left' }}>
        <button className={style['detail-btn']} onClick={() => setShowRetainTimeComponent(!showRetainTimeComponent)}>
          <FormattedMessage id="reports.page.lengthOfStayRepHeader" defaultMessage="Tempo de Permanência" />
          <Order width="25" height="15" className={`${style.trackerSvg} ${rotateRetainTime}`} />
        </button>
      </div>
      {showRetainTimeComponent &&
        <div className={style.cards}>
          <div className={style['card-block']} style={{ float: 'left' }}>
            <ClientLengthOfStayComponent themeColor={color} style={{ width: '100%' }}/>
          </div>
        </div>
      }

      {/* Relatório Total de Clientes por Empresa */}
      {!isCompanyMember &&
        <div style={{ width: '100%', float: 'left' }}>
          <button className={style['detail-btn']} onClick={() => setShowUsersByCompanyComponent(!showUsersByCompanyComponent)}>
            <FormattedMessage id="reports.page.clientByCompany" defaultMessage="Total de Clientes por Empresa" />
            <Order width="25" height="15" className={`${style.trackerSvg} ${rotateUsersByCompany}`} />
          </button>
        </div>
      }
      {showUsersByCompanyComponent &&
        <div className={style.cards}>
          <div className={style['card-block']} style={{ float: 'right' }}>
            <ClientByCompanyComponent themeColor={color} lang={lang} style={{ width: '100%' }} />
          </div>
        </div>
      }

      {/* Relatório Total usuários mês */}
      <div style={{ width: '100%', float: 'left' }}>
        <button className={style['detail-btn']} onClick={() => setShowUsersByMonthComponent(!showUsersByMonthComponent)}>
          <FormattedMessage id="reports.page.totalClientsRepHeader" defaultMessage="Total de Usuários por Mês" />
          <Order width="25" height="15" className={`${style.trackerSvg} ${rotateUsersByMonth}`} />
        </button>
      </div>
      {showUsersByMonthComponent &&
          <div className={style.cards}>
            <div className={style['card-block']} style={{ float: 'right' }}>
              <ClientTotalMonthlyComponent themeColor={color} style={{ width: '100%' }} />
            </div>
          </div>
        }
    
    </div>
  );
}

ReportPage.propTypes = {
  isPaid: PropTypes.bool.isRequired
};

export default ReportPage;
