import React from "react";
import PropTypes from 'prop-types';

const AlarmGeo4OutIco = ({ height, svgClass, width, fill, transform }) => {
  return (
    <svg
      className={svgClass}
      width={width}
      height={height}
      fill={fill}
      transform={transform}
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 141.7 141.7"
      version="1.1"
      viewBox="0 0 141.7 141.7"
      xmlSpace="preserve"
    >
      <path d="M70.9 132.2h-.2c-3.3 0-6.5-.2-9.7-.7l.6-3.9c3 .5 6.1.7 9.1.7h.2v3.9zm10-.8l-.6-3.8c3.1-.5 6.2-1.2 9.1-2.2l1.2 3.7c-3.1 1-6.4 1.8-9.7 2.3zm-29.7-2.2c-3.2-1-6.3-2.3-9.3-3.8l1.8-3.5c2.8 1.4 5.7 2.6 8.7 3.6l-1.2 3.7zm-17.8-9c-2.7-1.9-5.3-4.1-7.6-6.5l2.7-2.8c2.2 2.2 4.6 4.2 7.2 6.1l-2.3 3.2zm-14.2-14c-2-2.7-3.7-5.5-5.3-8.5l3.5-1.8c1.4 2.8 3.1 5.5 5 8l-3.2 2.3zm108.5-9l-3.5-1.8c1.4-2.8 2.6-5.7 3.6-8.7l3.7 1.2c-1 3.3-2.3 6.4-3.8 9.3zM10.1 88.5c-1-3.2-1.8-6.4-2.4-9.7l3.8-.6c.5 3.1 1.3 6.2 2.2 9.1l-3.6 1.2zm123.7-10.3l-3.8-.6c.5-3 .7-6.2.7-9.3v-.1h3.9v.1c-.1 3.4-.3 6.7-.8 9.9zM6.9 68.8v-.4c0-3.2.2-6.4.7-9.6l3.9.6c-.4 3-.7 6-.7 9v.4H6.9zm123-9.9c-.5-3.1-1.2-6.2-2.2-9.1l3.7-1.2c1 3.2 1.8 6.4 2.3 9.7l-3.8.6zM13.5 50.2L9.8 49c1-3.2 2.3-6.3 3.8-9.3l3.5 1.7c-1.4 2.9-2.6 5.9-3.6 8.8zm110.6-9.1c-1.4-2.8-3.1-5.5-4.9-8l3.1-2.3c2 2.7 3.7 5.6 5.2 8.5l-3.4 1.8zM22 33.5l-3.2-2.3c1.9-2.7 4.1-5.3 6.5-7.7l2.8 2.7c-2.3 2.3-4.3 4.7-6.1 7.3zm91.1-7.5c-2.2-2.2-4.6-4.3-7.2-6.1l2.3-3.2c2.7 2 5.3 4.1 7.6 6.5l-2.7 2.8zm-78-5.9L32.8 17c2.7-2 5.5-3.8 8.5-5.3l1.8 3.5c-2.8 1.4-5.5 3.1-8 4.9zM97.9 15c-2.8-1.4-5.7-2.6-8.7-3.6l1.2-3.7c3.2 1 6.3 2.3 9.3 3.8L97.9 15zm-46.2-3.5l-1.2-3.7c3.2-1.1 6.4-1.9 9.7-2.4l.6 3.8c-3 .6-6.1 1.3-9.1 2.3zm28.4-2.3c-3.1-.5-6.2-.7-9.4-.7h-.5V4.6h.5c3.3 0 6.7.3 10 .8l-.6 3.8z"></path>
      <g>
        <path d="M32.4 64.6c.7 2.9 1.4 5.4 2 8 .3 1.1.5 2.3.9 3.4.3.9 0 1.5-.6 2.2-2.3 2.4-4.5 4.9-6.8 7.3-2 2.1-3.2 4.5-3.8 7.4-1.1 5-2.4 9.9-3.7 14.9-.8 3.1 1.1 6.3 4.1 7 3.3.8 6.4-1 7.2-4.2 1.2-4.6 2.3-9.1 3.4-13.7.6-2.4 2.2-4.3 3.9-6.1 3.1-3.3 6.1-6.7 9.2-10 3.6-3.8 4.4-8.1 3-13.1-.9-3.1-1.6-6.2-2.4-9.6 3.4 1.5 7 1.9 8.5 5.8.4 1 1 2.1 1.8 2.9 1.9 2 4.9 2.3 7.1.9 2.4-1.5 3.5-4.4 2.4-7-2.1-5.4-5.4-9.7-11-11.7-4-1.5-7.9-3-12-4-10.1-2.4-20.3 3.5-23.5 13.4-.1.4-.3.8-.4 1.2-.5 3.2-2.3 5.3-5.4 6.4-2.4.9-3.6 2.9-3.5 5.5 0 4 4.1 6.9 7.8 5.4 2.1-.8 4.1-2 6.1-3.1 2.4-1.4 3.8-3.6 4.7-6.2.4-1 .7-1.8 1-3z"></path>
        <path d="M54.5 82.6c-2.7 3-5.4 5.9-8 8.7-.6.6-.8 1.1-.4 1.9.6 1.4 1.2 2.8 1.7 4.3.8 2.2 2.1 3.9 3.7 5.5l10.1 10.1c3.3 3.2 8.4 2 9.9-2.3.8-2.3.1-4.3-1.5-5.9-2.5-2.6-5-5.2-7.7-7.7-2.1-2-3.8-4.3-4.8-7.1-.9-2.5-2-5-3-7.5zM43.3 32.8c0-4.8-4-8.9-8.8-8.8-5.4.1-8.8 4.2-8.9 8.8 0 4.8 4 8.8 8.8 8.8 4.9.1 8.9-4 8.9-8.8z"></path>
      </g>
      <path d="M115.5 120.2L115.5 111.4 109.6 120.2z"></path>
      <path d="M115.8 96.8c-11.1 0-20 9-20 20s9 20 20 20 20-9 20-20-9-20-20-20zm7.7 27.8h-3.2v5.2h-4.8v-5.2H105v-4.3l11.2-16.4h4.1v16.3h3.2v4.4z"></path>
    </svg>
  );
};

AlarmGeo4OutIco.defaultProps = {
  height: '75',
  svgClass: '',
  width: '75',
  fill: '#008D53',
  transform: 'translate(0,0)',
};

AlarmGeo4OutIco.propTypes = {
  height: PropTypes.string,
  svgClass: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
  transform: PropTypes.string,
};

export default AlarmGeo4OutIco;