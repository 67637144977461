import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { ErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryComponent from './components/Error/GlobalErrorBoundaryComponent.jsx';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from 'react-apollo';
import App from './AppV3.jsx';
import LoginPage from './components/Login/LoginPage';
import DevTools from './components/Common/DevTools';
import reportWebVitals from './reportWebVitals';
import configureStore from './store/configureStore';
import * as localeActions from './actions/localeActions';
import { host, getDomain } from './BLL/environment.js';
import './index.css';

const isProduction = process.env.NODE_ENV === 'production';

// Sentry.init({
//   dsn: "https://cb06e4cc4b1f10a24aee55cf68360c40@o4505953378566144.ingest.sentry.io/4505953392197632",
// });

const client = new ApolloClient({
  uri: `https://${getDomain()}${host()}/graphql/`,
});

export const redirect = () => {
  if(!localStorage.token) {
    return '/login';
  }
  return '/app';
};

const store = configureStore();

if (localStorage.contacttoLang) {
  store.dispatch(localeActions.localeSet(localStorage.contacttoLang));
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<ErrorBoundary FallbackComponent={ErrorBoundaryComponent}>
  <ApolloProvider client={client}>
    <Provider store={store}>
      <div className="app__container">
        <BrowserRouter>
          <Switch>
            <Route exact path="/login" component={LoginPage}/>
            <Route path="/app" component={App} />
            <Route render={() => <Redirect to={redirect()} />} />
          </Switch>
        </BrowserRouter>
        {!isProduction && <DevTools />}
      </div>
    </Provider>
  </ApolloProvider>
</ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
