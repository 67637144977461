import * as types from './actionTypes';
import * as errorsActions from './errorsActions';
import * as globals from '../globals/globals';
import { host, getDomain, getToken } from '../BLL/environment';

export function loadTransmittersSuccess(transmitters, clientId, ordering) {
  return { type: types.LOAD_TRANSMITTERS_SUCCESS, transmitters, clientId, ordering };
}

export function transmitterAdded(transmitter, clientId) {
  return { type: types.NEW_TRANSMITTER, transmitter, clientId };
}

export function transmitterUpdated(transmitter, clientId) {
  return { type: types.EDIT_TRANSMITTER, transmitter, clientId };
}

export function transmitterDeleted(transmitterId, clientId) {
  return { type: types.REMOVE_TRANSMITTER, transmitterId, clientId };
}

export function loadTransmitters(clientId, ordering = globals.transmittersOrdering) {
  const domain = getDomain();
  const token = getToken();

  return (dispatch, getState, { axios }) => Promise.resolve(
    axios({
      method: 'get',
      url: `https://${domain}${host()}/api/clients/${clientId}/transmitter/?ordering=${ordering}`,
      headers: { Authorization: `Token ${token}` },
    })
    .then((resp) => {
      dispatch(loadTransmittersSuccess(resp.data, clientId, ordering));
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(errorsActions.logout());
        }
      }
    }),
  );
}

export function createTransmitter(clientId, transmitter) {
  const domain = getDomain();
  const token = getToken();

  return (dispatch, getState, { axios }) => new Promise((resolve, reject) =>
    axios({
      method: 'post',
      responseType: 'json',
      url: `https://${domain}${host()}/api/clients/${clientId}/transmitter/`,
      data: transmitter,
      headers: { Authorization: `Token ${token}` },
    })
    .then(res => resolve(dispatch(transmitterAdded(res.data, clientId))))
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          reject(dispatch(errorsActions.logout()));
        }
      }
      return reject(error);
    }),
  );
}

export function editTransmitter(clientId, transmitter) {
  const domain = getDomain();
  const token = getToken();

  return (dispatch, getState, { axios }) => new Promise((resolve, reject) =>
    axios({
      method: 'put',
      responseType: 'json',
      url: `https://${domain}${host()}/api/clients/${clientId}/transmitter/${transmitter.id}/`,
      data: transmitter,
      headers: { Authorization: `Token ${token}` },
    })
    .then(() => resolve(dispatch(transmitterUpdated(transmitter, clientId))))
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          reject(dispatch(errorsActions.logout()));
        }
      }
      return reject(error);
    }),
  )
}

export function deleteTransmitter(clientId, transmitterId) {
  const domain = getDomain();
  const token = getToken();

  return (dispatch, getState, { axios }) => Promise.resolve(
    axios({
      method: 'delete',
      url: `https://${domain}${host()}/api/clients/${clientId}/transmitter/${transmitterId}/`,
      headers: { Authorization: `Token ${token}` },
    })
    .then(() => dispatch(transmitterDeleted(transmitterId, clientId)))
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(errorsActions.logout());
        }
      }
    }),
  );
}
