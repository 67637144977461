import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ErrorBoundary } from "react-error-boundary";
import { useSelector } from "react-redux";
import { FormattedMessage } from 'react-intl';
import SoftphoneKeyboard from "../../../Common/SoftphoneKeyboard";
import ExtensionCaller from "./ExtensionCaller";
import ComponentFailed from "../../../Error/ComponentFailedErrorBoundary";
import * as general from '../../../../BLL/general';
import styles from "./attendExtensionCall.module.scss";
import messages from "../../../../BLL/locale/messages";

const ContactComponent = ({ contact, handleSelectButton }) => {
  const { lang } = useSelector((state) => ({
    lang: state.locale.lang
  }));
  const [data, setData] = useState(undefined);

  useEffect(() => {
    if (contact) {
      const primaryPhone = contact.phones.find(
        (phone) => phone.phone_type === "Primary"
      );

      setData({
        name: `${contact.first_name} ${contact.last_name}`,
        phone: primaryPhone.phone
      })
    }
  }, [contact]);

  const handleSelect = (ev) => {
    console.log(ev.target.value)
    setData((prevState) => ({
      ...prevState,
      phone: ev.target.value
    }))
  }

  const handleSelectClick = () => {
    handleSelectButton(data, 'contact');
  }

  return (
    <div className={styles['contact-info']}>
      <div className={styles['contact-container']}>
        <div>
          <span className={styles.label}><FormattedMessage id="contact.page.name" defaultMessage="Nome" />:</span> <span>{contact.first_name} {contact.last_name}</span>
        </div>
        <div>
          <span className={styles.label}>
            <FormattedMessage id="extension.phone" defaultMessage="Telefone" />:
          </span>
          {data &&
            <select value={data.phone} onChange={(e) => handleSelect(e)} className={styles['extension-select']}>
              {contact.phones.map((elm) => (
                <option id={elm.id} value={elm.phone}>
                  {
                    elm.phone.length > 10 ? general.setMask(elm.phone, '(00) 0 0000-0000')  : general.setMask(elm.phone, '(00) 0000-0000')
                  } - {messages[lang][`contact.form.${elm.phone_type.toLowerCase()}`]}
                </option>
              ))}
            </select>
          }
        </div>
      </div>
      <button onClick={() => handleSelectClick()} className={styles['select-btn']}>
        <FormattedMessage id="extension.select" defaultMessage="SELECIONAR" />
      </button>
    </div>
  );
};

const AttendExtensionCall = ({ client, contacts, startCall, hangupSip, attendId, sipEventType }) => {
  const { lang, equipmentList } = useSelector((state) => ({
    lang: state.locale.lang,
    equipmentList: state.equipment.length > 0 ? state.equipment[0].data : [],
  }));

  const outsideEquipmentList = equipmentList.filter(equipment => !equipment.name.includes('PERS'))

  const [selectedLine, setSelectedContact] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [showSoftphoneKeyboard, setShowSoftphoneKeyboard] = useState(false);

  const handleSelectButton = (data, type, phoneType="") => {
    let line = {
      type,
      phone: "",
      name: messages[lang]['extension.own'],
    };
    if (type === "client") {
      switch (phoneType) {
        case 'contact':
          line.phone = data.contact_phone
          break;
        case 'extra':
          line.phone = data.extra_phone
          break;
        default:
          line.phone = data.account_phone;
          break;
      }
    } else {
      line.phone = data.phone;
      line.name = data.name;
    }
    setSelectedContact(line);
    setShowDetails(true);
  };

  const handleCustomPhoneConfirm = (customPhone) => {
    if (customPhone) {
      setSelectedContact({
        type: 'custom',
        phone: customPhone,
        name: 'custom',
      });
      setShowDetails(true);
    }
  };

  const handleBackButton = () => {
    setShowDetails(false);
  };

  console.log("Client info", outsideEquipmentList)

  return showDetails ? (
    <div className={styles.container}>
      <ExtensionCaller
        selectedLine={selectedLine}
        handleBackButton={handleBackButton}
        clientAccount={client.account}
        attendId={attendId}
        startCall={startCall}
        hangupSip={hangupSip}
        sipEventType={sipEventType}
      />
    </div>
  ) : (
    <div className={styles.container}>
        <div className={styles["client-info"]}>
          <div className={styles["client-container"]}>
            <span className={styles['client-title']}>
              <FormattedMessage id="extension.clientInfo" defaultMessage="INFORMAÇÕES DO USUÁRIO" />
            </span>
            <div>
              <span className={styles.label}><FormattedMessage id="client.page.columnAccount" defaultMessage="CONTA"/>:</span> <span>{client.account}</span>
            </div>
            {client.account_phone && outsideEquipmentList.some(equipment => equipment.id === client.equipment_id) &&
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                <div>
                  <span className={styles.label}><FormattedMessage id="client.detail.accountPhone" defaultMessage="Telefone do equipamento"/>:</span> <span>{client.account_phone.length > 10 ? general.setMask(client.account_phone, '(00) 0 0000-0000') : general.setMask(client.account_phone, '(00) 0000-0000')}</span>              
                </div>
                <button onClick={() => handleSelectButton(client, "client", "account")} className={styles['select-btn']} style={{ marginTop: '0' }}>
                  <FormattedMessage id="extension.select" defaultMessage="SELECIONAR" />
                </button>
              </div>
            }
            {client.contact_phone &&
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                <div>
                  <span className={styles.label}><FormattedMessage id="client.detail.contactPhone" defaultMessage="Telefone de contato"/> 1:</span> <span>{client.contact_phone.length > 10 ? general.setMask(client.contact_phone, '(00) 0 0000-0000') : general.setMask(client.contact_phone, '(00) 0000-0000')}</span>              
                </div>
                <button onClick={() => handleSelectButton(client, "client", "contact")} className={styles['select-btn']} style={{ marginTop: '0' }}>
                  <FormattedMessage id="extension.select" defaultMessage="SELECIONAR" />
                </button>
              </div>
            }
            {client.extra_phone &&
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                <div>
                  <span className={styles.label}><FormattedMessage id="client.detail.contactPhone" defaultMessage="Telefone de contato"/> 2:</span> <span>{client.extra_phone.length > 10 ? general.setMask(client.extra_phone, '(00) 0 0000-0000') : general.setMask(client.extra_phone, '(00) 0000-0000')}</span>              
                </div>
                <button onClick={() => handleSelectButton(client, "client", "extra")} className={styles['select-btn']} style={{ marginTop: '0' }}>
                  <FormattedMessage id="extension.select" defaultMessage="SELECIONAR" />
                </button>
              </div>
            }
            {!client.contact_phone && !client.extra_phone && <FormattedMessage id="extension.noContactPhone" defaultMessage="Este usuário não possui telefone de contato" />}
          </div>
        </div>
      <hr />
      <span className={styles['contact-title']}>
        <FormattedMessage id="attend.open.contactsHeader" defaultMessage="CONTATOS DE EMERGÊNCIA" />
      </span>
      <ErrorBoundary FallbackComponent={ComponentFailed}>
        {contacts?.filter((contact) => !contact.is_deleted).map((contact) => (
          <ContactComponent key={contact.id} contact={contact} handleSelectButton={handleSelectButton}/>
        ))}
      </ErrorBoundary>
      <hr />
      <span className={styles['contact-title']}>
        <FormattedMessage id="extension.softphoneTitle" defaultMessage="TECLADO DE TELEFONE" />
      </span>
      <div className={styles['softphone-container']}>
        <button
          onClick={() => setShowSoftphoneKeyboard(!showSoftphoneKeyboard)}
          className={styles['select-btn']}
        >
          {showSoftphoneKeyboard ? 
            <FormattedMessage id="extension.softphoneClose" defaultMessage="FECHAR" /> :
            <FormattedMessage id="extension.softphoneOpen" defaultMessage="ABRIR" />}
        </button>
      </div>
      {showSoftphoneKeyboard && (
        <SoftphoneKeyboard onConfirm={handleCustomPhoneConfirm} />
      )}
    </div>
  );
};

AttendExtensionCall.propTypes = {
  startCall: PropTypes.func.isRequired,
  hangupSip: PropTypes.func.isRequired,
  attendId: PropTypes.number.isRequired,
  sipEventType: PropTypes.string.isRequired,
  client: PropTypes.shape({
    id: PropTypes.number,
    account: PropTypes.string,
    account_phone: PropTypes.string,
    contact_phone: PropTypes.string,
    extra_phone: PropTypes.string,
  }).isRequired,
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      phones: PropTypes.arrayOf(
        PropTypes.shape({
          phone_type: PropTypes.string,
          phone: PropTypes.string,
        })
      ),
      priority: PropTypes.number,
    })
  ).isRequired,
};

export default AttendExtensionCall;
