import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import MapComponent from '../../../Map/MapComponentV2';
import messagesTracker from '../../../../BLL/locale/messagesIHelpEventTypes';
import style from './attendMap.module.scss';

const AttendMap = ({
  pointList,
  loadLatestPoint,
  errorComponent,
  attend,
  client,
  lang,
  isAttendOpen,
  equipment,
}) => {
  const firstPoint = pointList[pointList.length - 1];
  let evtMsg = '-';
  if (firstPoint) {
    if (firstPoint.SOS_ALARM === 1) {
      evtMsg = 'Alarme SOS';
    } else if (firstPoint.OVERSPEED === 1) {
      evtMsg = 'Alarme de velocidade';
    } else if (firstPoint.FALLDOWN === 1) {
      evtMsg = 'Alarme de queda';
    } else {
      evtMsg = 'Coordenadas';
    }
  }
  return (
    <div className={style.row}>
      {errorComponent
        ?
          <div className={style.mapErrorComp}>
            {isAttendOpen && equipment.name.toLowerCase() !== 'contactto sos - app' &&
              <button
                className={style.mapErrorBtn}
                onClick={() => loadLatestPoint(client.id, attend.id)}
              >
                <span style={{ paddingRight: '0.5rem' }}><i className="fas fa-sync-alt" aria-hidden="true" /></span>
                <FormattedMessage id="client.tracker.updateLoc" defaultMessage="Atualizar Localização" />
              </button>
            }
            <div className={style.mapErrorMsg}>
              {errorComponent}
            </div>
          </div>
        :
          <div style={{ width: '100%', float: 'left' }}>
            <div style={{ float: 'left', width: 'auto', margin: '1rem' }} >
              <span style={{ color: '#00b8cb', textTransform: 'uppercase', fontSize: '0.7rem', fontWeight: 'bold' }}><FormattedMessage id="client.tracker.event" defaultMessage="Evento" />: </span>
              {pointList.length > 0 &&
                pointList[pointList.length - 1].EVTTYPE
                  ? messagesTracker[lang][pointList[pointList.length - 1].EVTTYPE]
                  : evtMsg
              }
              {/* {pointList.length > 0 &&
                pointList[pointList.length - 1].EVENTTYPE
                  ? messagesTracker[lang][pointList[pointList.length - 1].EVENTTYPE]
                  : evtMsg
              } */}
            </div>
            <MapComponent pointsList={pointList} height="520px" clientId={client.id} />
          </div>
      }
    </div>
  );
};

AttendMap.propTypes = {
  isAttendOpen: PropTypes.bool.isRequired,
  lang: PropTypes.string.isRequired,
  attend: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  client: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  pointList: PropTypes.arrayOf(
    PropTypes.shape({
      gsm_signal: PropTypes.number,
      direction: PropTypes.string,
      battery: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      lot: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      address: PropTypes.string,
    }),
  ).isRequired,
  loadLatestPoint: PropTypes.func.isRequired,
  errorComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export default AttendMap;
