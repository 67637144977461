import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import SelectInput from '../../../Common/SelectInput2';
import CheckboxInput from '../../../Common/CheckboxInput';
import { fallDetectionMode } from '../../../../BLL/dictionary';

import formStyle from '../../../../css/form.module.scss';
import style from './programming.module.scss';
import '../../../../css/block.scss';

const TrackeriHelpProgram = (props) => {
  const [params, setParams] = useState({});

  useEffect(() => {
    setParams(props.params.param);
  }, [props.params]);

  const setParameters = (event) => {
    const value = event.target.value;
    const param = event.target.name;
    setParams(prevState => ({
      ...prevState,
      [param]: value
    }));
  };

  const convertAndSendParams = () => {
    const commands = [{
      code: 'fd',
      params: `${params.FDMODE},20`,
    },
    {
      code: 'sosbreadcrumb',
      params: `${params.SOSBREADCRUMB ? 'T' : 'F'}`,
    },
    {
      code: 'autopost',
      params: `${params.AUTOPOST}`,
    },
    {
      code: 'voipcalling',
      params: `${!params.VOIPCALLING ? false : params.VOIPCALLING}`,
    },
    {
      code: 'getparams',
      params: '',
    }];
    props.sendParams(commands);
  };

  console.log('RENDER', params);
  return (
    <div className={style.trackerMenuContainer}>
      {params &&
        <div>
          <div className={style.trackerMenuValue}>
            <span className={`${style.trackerMenuText} col-sm-12 col-md-6 col-lg-6`}><FormattedMessage id="client.tracker.fdmode" defaultMessage="Detecção de Quedas" />: </span>
            <div className={`${style.blockValue} col-sm-12 col-md-6 col-lg-6`}>
              <SelectInput
                dictionary={fallDetectionMode}
                items={[-1, 0, 1, 2]}
                selectClass={`${style.selectInput} ${
                  formStyle['select--enable'] 
                } custom-select`}
                value={params.FDMODE}
                onChange={setParameters}
                name="FDMODE"
                emptyOption={false}
              />
            </div>
          </div>
          <div className={style.trackerMenuValue}>
            <span className={`${style.trackerMenuText} col-sm-12 col-md-6 col-lg-6`}><FormattedMessage id="client.tracker.sosbreadcrumb" defaultMessage="Enviar posição durante SOS a cada 30 segundos" />: </span>
            <CheckboxInput name="SOSBREADCRUMB" className={`${style.checkbox} col-sm-12 col-md-6 col-lg-6`} checked={params.SOSBREADCRUMB === 'T'} onChange={setParameters} />
          </div>
          <div className={style.trackerMenuValue}>
            <span className={`${style.trackerMenuText} col-sm-12 col-md-6 col-lg-6`}><FormattedMessage id="client.tracker.voipcalling" defaultMessage="Fazer chamadas por VoIP (Voice over IP)" />: </span>
            <CheckboxInput name="VOIPCALLING" className={`${style.checkbox} col-sm-12 col-md-6 col-lg-6`} checked={params.VOIPCALLING} onChange={setParameters} />
          </div>
          <div className={style.trackerMenuValue} >
            <span className={`${style.trackerMenuText} col-sm-12 col-md-6 col-lg-6`}> <FormattedMessage id="client.tracker.autopost" defaultMessage="Intervalo de postagem de localização" />: </span>
            <div className={`${style.blockValue} col-sm-12 col-md-6 col-lg-6 row`}>
              <SelectInput
                items={[1, 5, 15, 30, 60, 120, 240]}
                selectClass={`form-control ${style.selectInput} ${
                  formStyle['select--enable']}
                  ${style.selectInterval}
                  `}
                value={params.AUTOPOST}
                onChange={setParameters}
                name="AUTOPOST"
              />  <span className={style.trackerMenuText} style={{ marginLeft: '5px', float: 'left' }}><FormattedMessage id="client.tracker.minutes" defaultMessage="Minutos" /></span>
            </div>
          </div>
          <div className="col-12">
          <button className={style.trackerMenuBtn} onClick={() => convertAndSendParams()}>
          <span><i className="far fa-save fa-lg" aria-hidden="true" style={{ paddingRight: '0.5rem', paddingTop: '0.2rem' }} /></span>
            <FormattedMessage id="client.tracker.saveSettings" defaultMessage="Salvar Configurações" />
          </button>
          </div>
        </div>
      }
    </div>
  );
};

TrackeriHelpProgram.propTypes = {
  sendParams: PropTypes.func.isRequired,
  params: PropTypes.shape({
    param: PropTypes.shape({
      VOUT1: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      VOUT2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      VOUT1TIME: PropTypes.number,
      VOUT2TIME: PropTypes.number,
      VIN1: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      VIN2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      VIN1AUTO: PropTypes.bool,
      VIN2AUTO: PropTypes.bool,
      FDMODE: PropTypes.number,
      GEO1MODE: PropTypes.number,
      GEO1LAT: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      GEO1LON: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      GEO1RADIUS: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      GEO2MODE: PropTypes.number,
      GEO2LAT: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      GEO2LON: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      GEO2RADIUS: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      GEO3MODE: PropTypes.number,
      GEO3LAT: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      GEO3LON: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      GEO3RADIUS: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      SOSBREADCRUMB: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
      AUTOPOST: PropTypes.number,
      SUBSCRIPTIONTOPIC: PropTypes.string,
    }),
  }).isRequired,
};


export default TrackeriHelpProgram;
