/* eslint-disable import/no-anonymous-default-export */
export default {
  en: {
    'app.activate.message': 'Activating this account your current users will be charged. Continue?',
    'app.currency': 'US$',
    'toggle.button.inactives': 'Inactive',
    'logs.createdDate': 'Created date',
    'logs.createdBy': 'Created by',
    'logs.modifiedDate': 'Modifed date',
    'logs.modifiedBy': 'Modified by',
    'attendant.attendantsHeader': 'SYSTEM CONTACTS',
    'attendant.userName': 'Username',
    'attendant.group': 'Group',
    'attendant.country': 'Country',
    'paginate.of': 'of',
    'general.firstName': 'First Name',
    'general.lastName': 'Last Name',
    'general.yes': 'Yes',
    'general.no': 'No',
    'general.admin': 'Admin',
    'general.manager': 'Manager',
    'general.operator': 'Operator',
    'general.owner': 'Owner',
    'general.actionsHeader': 'ACTIONS',
    'general.personalInfoHeader': 'PERSONAL INFORMATION',
    'general.highPriority': 'High Priority',
    'general.mediumPriority': 'Medium Priority',
    'general.lowPriority': 'Low Priority',
    'general.sysPriority': 'System Alarm',
    'general.columnView': 'View',
    'general.columnEdit': 'Edit',
    'general.columnDeactivate': 'Deactivate',
    'general.columnRecover': 'Recover',
    'general.columnDelete': 'Delete',
    'general.clientColumn': 'User',
    'general.attendant': 'Attendant',
    'general.resolvedBy': 'Solved By',
    'general.alarmType': 'Alarm Type',
    'general.opening': 'Start Time',
    'general.closure': 'End Time',
    'general.accountNumber': 'Account number',
    'general.fullName': 'Name',
    'general.cellphone': 'Cellphone',
    'general.saveButton': 'SAVE',
    'general.add': 'ADD',
    'general.allPlans': 'All Plans',
    'general.country': 'Country',
    'general.other': 'Other',
    'general.emptyList': 'Empty List',
    'general.company': 'Company',
    'error.atLeastTwoChar': 'This field must have at least two characters',
    'error.cellPhoneNumbers': 'This field must have 11 number',
    'inputContainer.username': 'Username: ',
    'inputContainer.firstName': 'First Name: ',
    'inputContainer.lastName': 'Last Name: ',
    'inputContainer.mobile': 'Mobile Phone: ',
    'inputContainer.password': 'Password: ',
    'inputContainer.newPassword': 'New Password',
    'inputContainer.confirmPassword': 'Confirm Password: ',
    'inputContainer.oldPassword': 'Old Password',
    'inputContainer.accountNumber': 'Account Number: ',
    'inputContainer.birthdate': 'Birthdate: ',
    'inputContainer.equipment': 'Equipment: ',
    'inputContainer.typeOfAccount': 'Type of Account: ',
    'inputContainer.healthPlan': 'Insurance: ',
    'inputContainer.limitations': 'Limitations: ',
    'inputContainer.medications': 'Medications: ',
    'inputContainer.removalPlan': 'Removal Plan: ',
    'inputContainer.observations': 'Observations: ',
    'inputContainer.state': 'State: ',
    'inputContainer.street': 'Street: ',
    'inputContainer.number': 'Number: ',
    'inputContainer.complement': 'Complement: ',
    'inputContainer.district': 'District: ',
    'inputContainer.zipCode': 'Zip Code: ',
    'inputContainer.city': 'City: ',
    'inputContainer.accountPhone': 'Device Number: ',
    'inputContainer.useVoIP': 'Use VoIP:',
    'inputContainer.contactPhone': 'Contact Phone {number}: ',
    'inputContainer.company': 'Company: ',
    'inputContainer.gender': 'Gender: ',
    'inputContainer.relationship': 'Relationship: ',
    'inputContainer.priority': 'Priority: ',
    'inputContainer.account': 'Account: ',
    'inputContainer.attendId': 'Identification: ',
    'inputContainer.appNumber': 'App Number',
    'inputContainer.internalComments': 'Internal Comments:',
    'dashboard.open.header': 'OPEN ALARMS',
    'dashboard.open.noAttends': 'No open alarms',
    'dashboard.open.attendant': 'No Attendant',
    'dashboard.closed.header': 'LAST {attendsLength} ALARMS',
    'dashboard.closed.noAttends': 'No closed alarms',
    'dashboard.tabs.header': 'SUMMARY',
    'dashboard.tabs.quantity': 'QUANTITY',
    'dashboard.tabs.bypriority': 'BY PRIORITY',
    'dashboard.tabs.priority': 'PRIORITY',
    'dashboard.tabs.subhigh': 'HIGH',
    'dashboard.tabs.submedium': 'MEDIUM',
    'dashboard.tabs.sublow': 'LOW',
    'dashboard.tabs.subsystem': 'SYSTEM',
    'dashboard.tabs.userstotal': 'USERS TOTAL',
    'dashboard.card.quantity': 'Number of alarms',
    'dashboard.card.attendsPriority': 'Number of Alarms by Priority',
    'dashboard.card.clientsMonthly': 'Users Total Monthly',
    'card.alarms.month': ' Month: ',
    'card.alarms.totalAtt': ' Number of Alarms: ',
    'card.alarms.totalVoice': ' Audio Calls: ',
    'sidebar.dashboard': 'Dashboard',
    'sidebar.users': 'Users',
    'sidebar.attends': 'Alarms',
    'sidebar.reports': 'Reports',
    'sidebar.profile': 'My profile',
    'sidebar.manage': 'Manage',
    'sidebar.config': 'Settings',
    'sidebar.logout': 'Logout',
    'header.user.edit': 'Edit',
    'header.user.logout': 'Logout',
    'header.user.messages': 'Messages',
    'header.icon.connection': 'Connection with the server was interrupted, wait 30 seconds or reload the page.',
    'header.icon.blockedPhone': 'Your microphone is blocked, please unblock your microphone. Click on the icon to know more.',
    'header.icon.activePhone': 'The name of your microphone is: {microphone}. If this is not your microphone, click on the icon.',
    'header.searchbar.search': 'Search',
    'header.trial.info': ' TRIAL VERSION ',
    'header.trial.sign': ' SIGN ',
    'header.domain.add': 'ADD DOMAIN',
    'call.button.answer': 'ANSWER',
    'call.button.resume': 'RESUME',
    'call.button.view': 'VIEW',
    'call.button.details': 'DETAILS',
    'call.button.close': 'CLOSE',
    'modal.confirm.defaultMessage': 'Are you sure you want to perform this action?',
    'modal.confirm.yes': 'YES',
    'modal.confirm.no': 'NO',
    'modal.confirm.reminderClose': 'Are you sure you want to close this reminder window?',
    'modal.notification.solveAttend': 'Close Alarm',
    'form.button.cancel': 'Cancel',
    'form.button.save': 'Save',
    'form.button.exclude': 'Delete',
    'form.info.save': 'You need to fill the following informations to be able to save:',
    'form.info.contact_personal': 'Personal - First name, last name, priority, relationship and email',
    'form.info.contact_phone': 'Phone - At least one primary phone',
    'form.info.client_personal': 'Personal - Account number, first name, last name, birthdate',
    'form.info.client_address': 'Address - Street, number, district, city, zip code',
    'form.info.client_phone_normal': 'Phone - Equipment phone',
    'form.info.client_phone_ihelp': 'Phone or IMEI - Equipment phone OR IMEI',
    'form.info.client_phone_sosapp': 'Phone and App Number - Equipment Phone AND App Number',
    'client.page.header': 'USERS',
    'client.page.headerSubtitle': 'Filtered by',
    'client.page.addAcc': 'ACCOUNT',
    'client.page.impAcc1': 'IMPORT',
    'client.page.impAcc2': 'ACCOUNT',
    'client.page.undoFilt1': 'UNDO',
    'client.page.undoFilt2': 'FILTER',
    'client.page.editFilt': 'EDIT FILTER',
    'client.page.filter1': 'FILTER',
    'client.page.filter2': 'USERS',
    'client.page.columnAccount': 'Account',
    'client.page.columnTracker': 'Tracker',
    'client.page.columnView': 'View',
    'client.page.columnContacts': 'Contacts',
    'client.page.columnAlarms': 'Alarms',
    'client.page.columnTransmitters': 'Sensors',
    'client.page.columnEdit': 'Edit',
    'client.page.columnDeactivate': 'Deactivate',
    'client.page.columnRecover': 'Recover',
    'client.page.company': 'Company',
    'client.page.name': 'Name',
    'client.page.lastName': 'Last Name',
    'client.page.equipment': 'Equipment',
    'client.page.noTracker': 'No Tracker',
    'client.form.primaryUserInfo': 'PERSONAL INFORMATION USER - 1',
    'client.form.secondaryUserInfo': 'PERSONAL INFORMATION USER - 2',
    'client.form.personalInfoHeader': 'ACCOUNT INFORMATION',
    'client.form.editHeader': 'EDIT USER',
    'client.form.addHeader': 'ADD USER',
    'client.form.testOpt': 'Test user: ',
    'client.form.equipmentError': 'This IMEI is already being used somewhere else.',
    'client.form.equipmentErrorAccount': 'This IMEI is being used by the account {clientAccount}',
    'client.form.addressHeader': 'ADDRESS',
    'client.form.noCompany': 'No Company',
    'client.form.select': 'Select',
    'client.form.male': 'Male',
    'client.form.female': 'Female',
    'client.form.notApplicable': 'Not Applicable',
    'client.form.placeholderText': 'This text will not appear to the operators during an emergency.',
    'client.error.accountNumber': 'This field must have 4 characters',
    'client.error.accountExists': 'Account number already exists',
    'client.error.invalidMail': 'Invalid Email',
    'client.error.atLeastTwoChar': 'This field must have at least two characters',
    'client.error.firstCharUppercase': 'The first character must be an uppercase letter',
    'client.error.atLeastOneNum': 'This field must have at least one number',
    'client.error.appNumber': 'This field must have seven characters',
    'client.error.dateFormat': 'This field must be in the format dd/mm/yyyy',
    'client.error.invalidCellphone': 'Cellphone number invalid',
    'client.error.cellPhoneNumbers': 'This field must have 11 numbers',
    'client.error.cellPhoneNumbersMU': 'This field must have 11 numbers',
    'client.error.regularPhones': 'This field must have 10 or 11 numbers',
    'client.error.regularPhonesMU': 'This field must have 10 or 11 numbers',
    'client.error.phoneNumbersGB': 'This field must have 12 numbers',
    'client.error.zipCode': 'This field must have 8 numbers',
    'client.error.cpfInvalidLen': 'The cpf field must have 11 numbers',
    'client.error.cpfInvalid': 'Invalid cpf number',
    'client.error.accountOutOfRange': 'The account number is outside this company\'s account range',
    'client.error.ataExists': 'The number of this ATA (VoIP) is already being used elsewhere',
    'client.formMessage.equipment': 'This user is marked with {equipmentName} but there is a cellphone in the account phone.\n\nSave anyway?',
    'client.formMessage.testClient': 'This user is marked with test, starting now his calls will not open voice channel untils this option is unchecked.\n\nSave anyway?',
    'client.formMessage.voip': 'Is user is marked as VoIP, make sure his equipment is connected to an ATA equipment to be able to make emergency calls.',
    'client.detail.mainHeader': 'VIEWING INFORMATION ',
    'client.detail.testUser': 'Test user',
    'client.detail.accountType': 'Account Type',
    'client.detail.doesNotHave': 'Does not have',
    'client.detail.testUserYes': 'Yes',
    'client.detail.testUserNo': 'No',
    'client.detail.birthdate': 'Birthdate',
    'client.detail.observations': 'Observations',
    'client.detail.healthPlan': 'Insurance plan',
    'client.detail.limitations': 'Limitations',
    'client.detail.medications': 'Medications',
    'client.detail.removalPlan': 'Removal plan',
    'client.detail.equipment': 'Equipment',
    'client.detail.company': 'Company',
    'client.detail.street': 'Street',
    'client.detail.number': 'Number',
    'client.detail.complement': 'Complement',
    'client.detail.district': 'District',
    'client.detail.zipCode': 'Zip Code',
    'client.detail.city': 'City',
    'client.detail.state': 'State',
    'client.detail.accountPhone': 'Device Phone',
    'client.detail.contactPhone': 'Contact Phone',
    'client.detail.accountNumber': 'Account Number',
    'client.detail.indepCall': 'Make single call',
    'client.detail.name': 'Full name',
    'client.detail.gender': 'Gender',
    'client.detail.age': 'Age',
    'client.tracker.mapHeader': 'MAP',
    'client.tracker.inNumbers': 'Inbound Numbers',
    'client.tracker.inboundNumber': 'Inbound Number',
    'client.tracker.inboundTooltip': 'Activate/Deactivate automatic answer. When deactivated, it will be needed to press the SOS button to answer a call.',
    'client.tracker.geofenceMapHeader': 'GEO-FENCE MAP',
    'client.tracker.geofenceMode': 'Mode',
    'client.tracker.geofenceRadius': 'Radius',
    'client.tracker.meters': 'Meters',
    'client.tracker.toKnowMore': 'TO KNOW MORE',
    'client.tracker.clickHere': ' CLICK HERE ',
    'client.tracker.begining': 'Start: ',
    'client.tracker.end': 'End: ',
    'client.tracker.search': 'Search',
    'client.tracker.trackerFrom': 'TRACKER FROM ',
    'client.tracker.history': 'History',
    'client.tracker.programming': 'Programming',
    'client.tracker.geofences': 'Geo-Fences',
    'client.tracker.geofence': 'Geo-Fence',
    'client.tracker.fdmode': 'Fall detection mode',
    'client.tracker.voipcalling': 'Make calls by VoIP (Voice over IP)',
    'client.tracker.sosbreadcrumb': 'SOS Breadcrumb every 30 seconds',
    'client.tracker.sosbreadcrumbint': 'SOS Breadcrumb interval',
    'client.tracker.autopost': 'Location send interval',
    'client.tracker.vin1': 'Allowed inbound phone number 1',
    'client.tracker.vin2': 'Allowed inbound phone number 2',
    'client.tracker.disabled': 'Disabled',
    'client.tracker.enabled': 'Enabled',
    'client.tracker.highSensitivity': 'High Sensitivity',
    'client.tracker.mediumHighSensitivity': 'Medium-High Sensitivity',
    'client.tracker.normalSensitivity': 'Normal Sensitivity',
    'client.tracker.enterPerimeter': 'Alert on enter perimeter',
    'client.tracker.exitPerimeter': 'Alert on exit perimeter',
    'client.tracker.bothPerimeter': 'Alert on both enter/exit perimeter',
    'client.tracker.seconds': 'Seconds',
    'client.tracker.minutes': 'Minutes',
    'client.tracker.saveSettings': 'Save Settings',
    'client.tracker.saveFence': 'Save Fence',
    'client.tracker.simplified': 'Simplified Data',
    'client.tracker.latest': 'Latest Position',
    'client.tracker.errorNoIMEI': 'THIS USER DOES NOT HAVE IMEI REGISTERED.\n',
    'client.tracker.errorNoData': 'THERE IS NO DATA FOR THE SELECTED DAY.\n\n THE TRACKER MUST BE CONFIGURED WITH THE CONTACTTO.CARE SERVER.\n\n\n',
    'client.tracker.errorNoDataLatest': 'NO DATA FOUND.\n\n THE TRACKER MUST BE CONFIGURED WITH THE CONTACTTO.CARE SERVER.\n\n\n',
    'client.tracker.errorNoUser': 'THIS USER DOES NOT EXIST',
    'client.tracker.errorUnexpected': 'UNEXPECTED ERROR',
    'client.tracker.errorNoSimpleToday': 'IT IS NOT POSSIBLE TO SEARCH FOR TODAY\'S SIMPLIFIED DATA.\n THIS INFORMATION IS ONLY AVAILABLE FOR PAST DAYS.',
    'client.tracker.errorEmptyPointsDates': 'NO DATA FOR THE DATES BETWEEN {startDate} AT {startHour} HOURS AND {endDate} - {endHour} AT HOURS',
    'client.tracker.errorEmptyPointsHours': 'NO DATA FOR THE DATES BETWEEN {startDate} AT {startHour} HOURS AND {endDate} - {endHour} AT HOURS',
    'client.tracker.errorEmptyPoint': 'THIS DEVICE DOESN\'T HAVE DATA FOR TODAY, PLEASE CHECK THE DEVICE\'S BATTERY',
    'client.tracker.ihelpNoData': 'NO DATA FOR THIS DEVICE. \n VERIFY IF THE DEVICE IS ONLINE',
    'client.tracker.battery': 'Battery',
    'client.tracker.timestamp': 'Timestamp',
    'client.tracker.charging': 'Charging',
    'client.tracker.cellSignal': 'Cell Signal',
    'client.tracker.eventType': 'Event Type',
    'client.tracker.searchAddress': 'Enter an address for this perimeter',
    'client.tracker.configsSaved': 'Configuration successfully saved!',
    'client.tracker.configsSent': 'Request successfully sent!',
    'client.tracker.igoConfigSaved': 'Configurations sent to the device. \n Please, wait 5 minutes for the equipment to be updated.',
    'client.tracker.location': 'Location',
    'client.tracker.updateLoc': 'Update Location',
    'client.tracker.event': 'Event',
    'client.tracker.powerSav': 'Power Saving',
    'client.tracker.voiceMon': 'Listening Mode',
    'client.tracker.ledInd': 'Deactivate LED Indicator',
    'client.tracker.overspeed': 'Overspeed alarm',
    'client.tracker.fallDetection': 'Fall Detection',
    'client.tracker.gsmTooltip': 'Activate/Deactivate location by GSM when the GPS satellite is not available. Increase the battery consumption.',
    'client.tracker.powerSavTooltip': 'Activate/Deactivate smart power saving mode. When deactivated, the device will send it\'s location according to the selected posting interval.',
    'client.tracker.voiceMonTooltip': 'Activate/Deactivate listening mode. Registered numbers in the device can make silent calls and listen to what is happening with the device without alerting the user.',
    'client.tracker.ledIndTooltip': 'Activate/Deactivate LED indicators, the side LEDs will turn on/off, the device will function normally.',
    'client.tracker.fallDetectionTooltip': 'Activate/Deactivate fall detection. The device will send an SMS to the registered phones that a fall has occured without pressing any button.',
    'client.tracker.autopostTooltip': 'Select the time interval that the device will send it\'s location. The default is that the device will send data every 3 minutes when in movement and 10 minutes when still.',
    'client.tracker.overspeedTooltip': 'Select the speed (in km/h) to activate the overspeed alarm or turn off this function. When the speed limit is exceeded, the device will send an SMS to the registered numbers.',
    'client.tracker.medicationTimer': 'Time',
    'client.tracker.medicationAck': 'Medication Acknowledgement',
    'client.tracker.reminderActive': 'Active Reminder',
    'client.tracker.saveMed': 'Save Reminder',
    'client.tracker.medication': 'Reminder',
    'client.tracker.medicationOption': 'Program Medication Reminders',
    'client.tracker.saveInbound': 'Save Inbound Numbers',
    'client.tracker.hour': 'Hour',
    'client.tracker.sendTime': 'Point time',
    'client.tracker.gpsAccuracy': 'GPS Accuracy',
    'client.tracker.geoNotConfigured': 'Please type an address in the searchbar to configure this Geofence.',
    'client.tracker.falldownActivate': 'Activate falldown detection',
    'client.tracker.motionActivate': 'Activate motion detection',
    'client.tracker.noMotionActivate': 'Activate no motion detection',
    'client.tracker.overspeedActivate': 'Activate overspeed detection',
    'client.tracker.tiltAlertActivate': 'Activate tilt detection',
    'client.tracker.sensitivity': 'Sensitivity',
    'client.tracker.movmentActivationPeriod': 'Time to activate (in seconds)',
    'client.tracker.movementAlertCooldown': 'Cooldown between alarms (in seconds)',
    'client.tracker.noMovementStaticPeriod': 'Time still for inactivity detection (in seconds, between 300 and 36000)',
    'client.tracker.detectionSpeed': 'Speed for detection (KM/h)',
    'client.tracker.tiltAlertAngle': 'Angle limit (in degrees)',
    'client.tracker.tiltTimeTreshold': 'Time still for tilt detection (in seconds, between 10 and 3600)',
    'client.tracker.date': 'Date',
    'client.tracker.accuracy': 'Accuracy',
    'client.tracker.direction': 'Direction',
    'client.tracker.altitude': 'Altitude',
    'client.tracker.speed': 'Speed',
    'client.tracker.signalStrength': 'Signal strength',
    'client.tracker.completeCharge': 'Charge complete',
    'client.tracker.lastRead': 'Last read',
    'client.tracker.alarmClock': 'Program reminders',
    'client.tracker.reminder': 'Reminder',
    'client.tracker.medicationAudio': 'Medication reminder audio',
    'client.tracker.appointmentAudio': 'Appointment reminder audio',
    'client.tracker.noonAudio': '12 o clock audio',
    'client.tracker.wakeUpAudio': 'Wake up reminder audio',
    'client.tracker.audioSelection': 'Audio Selection',
    'client.tracker.audioDuration': 'Audio Duration',
    'client.tracker.daySelection': 'Repeat on days',
    'client.tracker.saveReminder': 'Save Reminder',
    'client.tracker.monAbbrv': 'MON',
    'client.tracker.tueAbbrv': 'TUE',
    'client.tracker.wedAbbrv': 'WED',
    'client.tracker.thuAbbrv': 'THU',
    'client.tracker.friAbbrv': 'FRI',
    'client.tracker.satAbbrv': 'SAT',
    'client.tracker.sunAbbrv': 'SUN',
    'client.import.importHeader': 'Insert a csv or excel type file',
    'client.import.importClick': ' click or drag the file',
    'client.import.noFileSelected': ' No file was selected.',
    'client.import.invalidExtension': ' File with invalid extension.',
    'client.import.standardFile': ' Download the standard format from the ',
    'client.import.help': 'The columns that have an * are mandatory',
    'client.import.file': 'file',
    'client.import.cancel': 'CANCEL',
    'client.import.send': 'SEND',
    'client.importInfo.header': 'IMPORT RESULTS',
    'client.importInfo.clients': 'Users',
    'client.importInfo.success': '  Successfully Imported: ',
    'client.importInfo.notImported': '  Not Imported (error): ',
    'client.importInfo.line': 'Line',
    'client.importInfo.name': 'Name',
    'client.importInfo.account': 'Account',
    'client.importInfo.contacts': 'contacts',
    'client.importInfo.downloadReport': 'Download the full report',
    'client.filter.first_name': 'First Name',
    'client.filter.last_name': 'Last Name',
    'client.filter.account': 'Account',
    'client.filter.group_id': 'Company',
    'client.filter.equipment_ids': 'Use Tracker',
    'client.filter.noGroup': 'No Company',
    'client.modal.inactivateMsg': 'You are inactivating the account {clientAccount}. Are you sure you want to perform this action?',
    'client.modal.reactivateMsg': 'You are reactivating the account {clientAccount}, if there is no other user with this account the extra charaactes will be erased, otherwise the extra charactes will need to be manually removed. Are you sure you want to perform this action?',
    'filter.header': 'FILTER USERS',
    'filter.useTracker': 'Use Tracker',
    'filter.company': 'Company',
    'filter.ignore': 'Ignore',
    'filter.btn': 'FILTER',
    'contact.page.header': 'CONTACTS FROM ',
    'contact.page.accountNumber': 'Account number',
    'contact.page.addCont': 'CONTACT',
    'contact.page.priority': 'Priority',
    'contact.page.name': 'Name',
    'contact.page.phoneOne': 'Phone 1',
    'contact.page.phoneTwo': 'Phone 2',
    'contact.page.answered': 'Answered',
    'contact.page.contacted': 'Contacted',
    'contact.form.editHeader': 'EDIT CONTACT OF',
    'contact.form.addHeader': 'ADD CONTACT OF',
    'contact.form.personal': 'PERSONAL',
    'contact.form.account': 'Account',
    'contact.form.priority': 'Priority',
    'contact.form.relationship': 'Relationship',
    'contact.form.hasKeys': 'Has Keys',
    'contact.form.contactInfo': 'CONTACT',
    'contact.form.zipCode': 'Zip Code',
    'contact.form.street': 'Street',
    'contact.form.district': 'District',
    'contact.form.number': 'Number',
    'contact.form.complement': 'Complement',
    'contact.form.city': 'City',
    'contact.form.state': 'State',
    'contact.form.addPhoneButton': 'Add new phone',
    'contact.form.actionsHeader': 'ACTIONS',
    'contact.form.cancel': 'Cancel',
    'contact.form.save': 'Save',
    'contact.form.phone': 'Telephone',
    'contact.form.type': 'Type',
    'contact.form.primary': 'Primary',
    'contact.form.work': 'Work',
    'contact.form.home': 'Home',
    'contact.form.cellphone': 'Cellphone',
    'contact.form.requestTracking': 'CAN REQUEST TRACKING:',
    'contact.form.requestTrackingMsg': 'CAN REQUEST THE TRACK COMMAND BY SMS TO RECEIVE THE LAST SYSTEM POSITION',
    'contact.form.isContractor': 'Contractor',
    'contact.form.isFamily': 'Familiar Admin',
    'contact.error.email': 'incorrect email',
    'contact.error.twoPrimaries': 'This contact has two primary phones',
    'contact.error.familyAdmExists': 'There is another contact marked as familiar administrator',
    'contact.error.noPrimary': 'This contact does not have primary phones',
    'contact.detail.mainHeader': 'VIEWING INFORMATION FROM THE CONTACT OF',
    'transmitters.zone': 'Zone: ',
    'transmitters.description': 'Description: ',
    'transmitter.form.selectUser': 'Utilize for user 2',
    'transmitters.details.header': 'SENSOR DETAILS',
    'transmitter.detail.identifier': 'Identifier',
    'transmitter.detail.description': 'Description',
    'transmitter.detail.observations': 'Observations',
    'transmitter.error.zone': 'Field needs 3 characters',
    'transmitters.detail.useForUser2': 'Utilize for user 2',
    'transmitters.identifier': 'TX identifier: ',
    'transmitters.observations': 'Observations',
    'transmitters.page.header': 'AUXILIARY SENSORS & DEVICES FROM',
    'transmitters.page.transmitterAdd': 'SENSOR',
    'transmitters.form.informations': 'INFORMATION',
    'transmitters.form.editTransmitter': 'EDIT SENSOR',
    'transmitters.form.addTransmitter': 'ADD SENSOR',
    'attend.list.mainHeader': 'ALARMS',
    'attend.list.loading': 'Loading',
    'attend.list.isTest': 'Test',
    'attend.list.clientAttendsFrom': 'ALARMS FROM',
    'attend.detail.callType': 'ATTEND TYPE',
    'attend.detail.comments': 'COMMENTS',
    'attend.detail.systemAttendants': 'SYSTEM ATTENDANTS',
    'attend.detail.mainHeader': 'ALARM',
    'attend.detail.birthdate': 'Birthdate',
    'attend.detail.clientAddress': 'Address',
    'attend.detail.clientAccount': 'Account',
    'attend.detail.clientCompany': 'Company',
    'attend.detail.clientAccountPhone': 'Device number',
    'attend.detail.clientContactPhone': 'User phone',
    'attend.detail.clientHealthplan': 'Insurance',
    'attend.detail.clientRemoval': 'Removal | Ambulance',
    'attend.detail.limitations': 'Limitations',
    'attend.detail.medications': 'Medications',
    'attend.detail.clientObservations': 'Observations',
    'attend.detail.doesNotHave': 'Does not have',
    'attend.detail.clientHeader': 'USER',
    'attend.detail.alarmHeader': 'ALARMS',
    'attend.detail.alarmName': 'Name',
    'attend.detail.alarmZone': 'ZONE',
    'attend.detail.alarmDate': 'DATE',
    'attend.detail.alarmNoRecord': '{alarmCode} - no record',
    'attend.detail.reportHeader': 'COMMENTS',
    'attend.detail.event': 'Event',
    'attend.detail.contactedHeader': 'CONTACTED PEOPLE',
    'attend.detail.noContacted': 'No contacted people',
    'attend.detail.noReminders': 'No reminders',
    'attend.detail.attendTag': 'Call Type',
    'attend.detail.clientDetailHeader': 'USER DETAILS',
    'attend.detail.alarmDetailHeader': 'ALARM DETAILS',
    'attend.detail.alarmType': 'TYPE',
    'attend.detail.alarmCategory': 'CATEGORY',
    'attend.detail.detailsHeader': 'DETAILS',
    'attend.detail.locationHeader': 'LOCATION',
    'attend.detail.emergencyContacts': 'EMERGENCY CONTACTS',
    'attend.detail.attendants': 'ATTENDANTS',
    'attend.detail.city': 'City',
    'attend.detail.zipCode': 'ZIP',
    'attend.detail.eventCategory': 'Event',
    'attend.detail.restorationCategory': 'Restoration',
    'attend.detail.primaryHeader': 'DETAILS USER - 1',
    'attend.detail.secondaryHeader': 'DETAILS USER - 2',
    'attend.detail.audioInfo': 'EXTRA AUDIO INFORMATION',
    'attend.description.sosAlarm': 'SOS Button',
    'attend.description.falldownAlarm': 'Falldown detection',
    'attend.description.overspeedAlarm': 'Overspeed detection',
    'attend.closed.errorNoPoints': 'COORDINATES NOT FOUND FOR THIS ATTEND',
    'attend.closed.reportNoComment': 'No comments',
    'attend.closed.infoLabel': 'INFO DETAILS',
    'attend.closed.clientMessageLabel': 'MESSAGE LOG',
    'attend.closed.clientMessage': 'MESSAGE',
    'attend.closed.clientMessageReply': 'REPLY',
    'attend.closed.resolvedBy': 'SOLVED BY',
    'attend.closed.infoOpening': 'Open time',
    'attend.closed.infoAttend': 'Attend time',
    'attend.closed.infoClosure': 'Close time',
    'attend.closed.infoHeader': 'INFORMATION',
    'attend.closed.emergencyContact': 'EMERGENCY CONTACT',
    'attend.closed.attendant': 'ATTENDANT',
    'attend.closed.contactedPersons': 'CONTACTED PERSONS',
    'attend.closed.remindersHeader': 'REMINDERS',
    'attend.closed.searchingFor': 'Searching...',
    'attend.closed.noAudio': 'No audio',
    'attend.closed.audioDate': 'AUDIO DATE',
    'attend.closed.audioOrigin': 'ATTENDANT',
    'attend.closed.audioDestiy': 'DESTINY',
    'attend.closed.audioStatus': 'STATUS',
    'attend.closed.audioType': 'TYPE',
    'attend.closed.audioDuration': 'DURATION',
    'attend.closed.downloadAudio': 'DOWNLOAD',
    'attend.closed.audioTypeAttend': 'Alarm',
    'attend.closed.audioTypeReminder': 'Reminder',
    'attend.closed.audioLoading': 'Loading...',
    'attend.open.takeCall': 'TAKE CALL',
    'attend.open.callDuration': 'CALL DURATION',
    'attend.open.hangUp': 'HANGUP',
    'attend.open.timeToAnswer': 'TIME TO ANSWER',
    'attend.open.takeAttend': 'TAKE ALARM',
    'attend.open.callEnded': 'CALL ENDED',
    'attend.open.attended': 'Attended',
    'attend.open.contacted': 'Contacted',
    'attend.open.contactsHeader': 'EMERGENCY CONTACTS',
    'attend.open.speak': 'Speak',
    'attend.open.listen': 'Listen',
    'attend.open.speakAndListen': 'Speak and Listen',
    'attend.open.systemAttendants': 'System Attendants',
    'attend.open.errorNoPoints': 'IT WAS NOT POSSIBLE TO FIND COORDINATES FOR THIS USER. \n PLEASE, WAIT A FEW MOMENTS AND THEN CLICK IN THE UPTADE LOCATION BUTTON\n',
    'attend.open.closedCall': 'Call Ended',
    'attend.open.years': 'years',
    'attend.open.saveButton': 'SAVE CALL',
    'attend.open.noAudioAlarm': 'This call doesn\'t have audio 🙉',
    'attend.open.callUser': 'RETURN TO USER',
    'attend.open.extensionCall': 'EXTENSION CALLS',
    'attend.open.emptyMessage': 'EMPTY MESSAGE',
    'attend.open.replyMessage': 'REPLY',
    'attend.open.replyCancel': 'CANCEL',
    'attend.open.connecting': 'Connecting...',
    'attend.open.stillConnecting': 'We are still trying to connect, you can hangup if you want',
    'attend.open.calling': 'Making the call, wait a little bit',
    'attend.open.callConnected': 'Call connected',
    'attend.open.callNotConnected': 'No connection with the call server',
    'attend.open.toSaveTooltip': 'To save',
    'attend.open.toSaveTooltipInstruction': 'Finish the call and choose the Attend Type',
    'attend.modal.emergencyCall': 'Emergency Call',
    'attend.tag.emergencyCall': 'Emergency Call',
    'attend.tag.testCall': 'Test Call',
    'attend.tag.techSupport': 'Support Call',
    'attend.tag.accidentalCall': 'Accidental Call',
    'attend.tag.commFail': 'Communication Fail',
    'attend.warning.cancel': 'Warning messages disabled.',
    'attend.warning.sent': 'Warning messages sent.',
    'attend.warning.sendingIn': 'Sending warning messages in {seconds} seconds...',
    'attend.warning.cancelMessages': 'Cancel messages',
    'attendant.form.phone': 'Telephone',
    'attendant.form.passwordChanged': 'Your password was changed, please relogin.',
    'attendant.form.passwordHeader': 'PASSWORD',
    'attendant.form.changePassword': 'Change Password',
    'attendant.form.unauthorized': 'You don\'t have authorization to view this page',
    'attendant.form.editAttendantHeader': 'EDIT ATTENDANT',
    'attendant.form.addAttendantHeader': 'ADD ATTENDANT',
    'attendant.form.changePassModalHeader': 'Password Change',
    'attendant.list.attendAdd': 'ATTENDANT',
    'attendant.error.invalidEmail': 'Invalid Email',
    'attendant.error.emailUsed': 'This Email is already being used, please use another',
    'attendant.error.usernameIncorrect': 'This field must be written in the format "name" or "name.othername"',
    'attendant.error.usernameUsed': 'Username already registered, please use another',
    'attendant.error.passwordEightCharacters': 'This field must have at lease 8 characters',
    'attendant.error.passwordMustBeEqual': 'Field must match the password field',
    'attendant.error.oldPassIncorrect': 'Your old password was incorrect',
    'management.header': 'MANAGEMENT',
    'management.attendantsOption': 'ATTENDANTS',
    'management.alarmsOption': 'ALARMS',
    'management.monitoringOption': 'MONITORING',
    'management.companiesOption': 'COMPANIES',
    'management.trackersOption': 'TRACKERS',
    'management.iot.header': 'Internet of Things Devices',
    'management.iot.lastEntry': 'Last Entry',
    'alarms.addAlarm': 'ALARM',
    'alarms.name': 'Name',
    'alarms.code': 'Code',
    'alarms.priority': 'Priority',
    'alarms.editHeader': 'EDIT ALARM',
    'alarms.addHeader': 'ADD ALARM',
    'alarms.informations': 'INFORMATION',
    'companies.addCompany': 'COMPANY',
    'companies.addPerson': 'STAFF',
    'companies.edit': 'EDIT',
    'companies.delete': 'DELETE',
    'companies.editHeader': 'EDIT COMPANY',
    'companies.addHeader': 'ADD COMPANY',
    'companies.name': 'Name',
    'companies.remove': 'REMOVE COMPANY',
    'companies.form.ranges': 'ACCOUNT RANGES',
    'companies.form.maxAccount': 'Max account',
    'companies.form.minAccount': 'Min account',
    'companies.form.addRange': 'Add Range',
    'companies.error.minMax': 'Minimum account should not be higher than maximum account',
    'companies.error.noMinOrMax': 'Minimum or maximum account not sent (empty)',
    'companies.error.rangeUsed': 'This account range is already being used or it is within another range',
    'company.delete.warningHeader': 'WARNING',
    'company.delete.warningMsg': 'To completely remove a company, first, you need to inactivate it\'s staff and exclude all users (or change their company).',
    'company.delete.warning': 'Have you done those actions? Do you want to continue?',
    'company.delete.deleteUsers': 'Delete staff accesses (login, email)?',
    'company.delete.loading': 'Please, wait...',
    'company.delete.success': 'Company removed successfully!',
    'company.delete.errorTitle': 'OOPS! An error occurred when removing this company.',
    'company.delete.errorHasClients': 'This company still has Users. Also check inactive users.',
    'company.delete.errorHasUsers': 'This company still has active staff members.',
    'companies.addPersonHeader': 'ADD STAFF',
    'companies.editPersonHeader': 'EDIT STAFF',
    'config.viewAttendantsList': 'View Attendants List',
    'config.viewContactsList': 'View Contacts List',
    'config.openAlarms': 'Open Alarms (Screen)',
    'config.globalOptHeader': 'ADMINISTRATIVE CONFIGURATION',
    'config.micandaudioconfig': 'MICROPHONE AND AUDIO CONFIGURATION',
    'config.viewResume': 'View Alarms Summary',
    'config.viewClosedAttends': 'View Closed Alarms',
    'config.viewQuantityAttends': 'View Number of Alarms Card',
    'config.viewGraphicCards': 'View Graphics Card',
    'config.dashboard': 'Dashboard',
    'config.localOptHeader': 'DASHBOARD CONFIGURATION',
    'config.twoGlobalUncheck': 'You can\'t disable two global attends settings. Pleae enable one.',
    'config.saveError': 'Errors occurred while saving',
    'config.saveSuccess': 'Settings saved!',
    'config.openTickets': 'Open Alarms (Screen)',
    'config.audioEcho': 'Echo test',
    'config.hangupAudio': 'Hangup Test',
    'config.makeAudio': 'Test',
    'reports.generateReport': 'Generate Report',
    'reports.selectYear': 'Select the Year',
    'reports.reportOf': 'REPORT OF',
    'reports.selectedcode': 'SELECTED CODE',
    'reports.attendHeader': 'CALLS',
    'reports.ofType': 'BY TYPE',
    'reports.accountHeader': 'ACCOUNT',
    'reports.generatePDF': 'Generate PDF',
    'reports.company': 'Company',
    'reports.client': 'User',
    'reports.account': 'Account',
    'reports.alarm': 'Alarm',
    'reports.category': 'Category',
    'reports.event': 'Event',
    'reports.restoration': 'Restoration',
    'reports.date': 'Date',
    'reports.opening': 'Open Time',
    'reports.closure': 'Close Time',
    'reports.duration': 'Duration',
    'reports.attendant': 'Attendant',
    'reports.solvedBy': 'Solved by',
    'reports.emptyReport': 'Empty report',
    'reports.reportButton': 'Report',
    'reports.graphicButton': 'Graphic',
    'reports.page.header': 'REPORTS',
    'reports.page.usersRepHeader': 'Users',
    'reports.page.alarmsRepHeader': 'Alarms',
    'reports.page.attendantsRepHeader': 'Attendants',
    'reports.page.attByAnywhereRepHeader': 'Answered by Anywhere',
    'reports.page.lengthOfStayRepHeader': 'Days Active',
    'reports.page.attsByPeriodRepHeader': 'Alarms by Period',
    'reports.page.totalPlansRepHeader': 'Total Plans',
    'reports.page.clientsByPlanRepHeader': 'Users by Plan',
    'reports.page.monthlyClientRepHeader': 'Monthly User Update',
    'reports.page.totalClientsRepHeader': 'Total Users by Month',
    'reports.page.clientByCompany': 'Total Users by Company',
    'reports.page.attendTagsRepHeader': 'Calls by Type',
    'reports.clients.selectClient': 'Select the User',
    'reports.clients.totalAlarms': 'TOTAL ALARMS',
    'reports.alarms.selectPriority': 'Select the Priority',
    'reports.alarms.selectAlarmCode': 'Select the Alarm Code',
    'reports.alarms.alarmsOf': 'ALARMS OF',
    'reports.attendants.selectAttendant': 'Select the Attendant',
    'reports.attendants.attendsOf': 'ALARMS OF',
    'reports.attendants.group': 'GROUP',
    'reports.attendants.totalAttends': 'TOTAL OF ALARMS',
    'reports.plans.selectPlan': 'Select the Plan',
    'reports.anywhere.answeredHeader': 'ALARMS ANSWERED BY ANYWHERE',
    'reports.anywhere.numberOfAttends': 'Number of Alarms',
    'reports.length.lengthOfStayHeader': 'USERS ACTIVE DAYS',
    'reports.length.daysLenght': 'Number of Days',
    'reports.length.createdDate': 'Created Date',
    'reports.length.deletedDate': 'Deleted Date',
    'reports.length.activeDays': 'Active Days',
    'reports.attends.attendsHeader': 'ATTENDS',
    'reports.attends.hasAudio': 'Audio',
    'reports.attends.selectTag': 'Select the Call Type',
    'reports.userTracking.header': 'MONTHLY USER UPDATE',
    'reports.userTracking.month': 'Month',
    'reports.userTracking.addedUsers': 'Added Users',
    'reports.userTracking.deletedUsers': 'Removed Users',
    'reports.userTracking.totalMonth': 'Total Monthly Users',
    'reports.userTracking.added': 'Added',
    'reports.userTracking.inactivated': 'Inactivated',
    'reports.userTracking.reactivated': 'Reactivated',
    'reports.userTracking.disabled': 'Disabled',
    'reports.userTotal.header': 'TOTAL USERS BY MONTH',
    'reports.userTotal.month': 'Month',
    'reports.userTotal.totalMonth': 'Total of Users Monthly',
    'reports.totalPlans.header': 'TOTAL PLANS VALUE',
    'reports.totalPlans.totalHeader': 'Total',
    'reports.totalPlans.plan': 'Plan',
    'reports.totalPlans.numberOfUsers': 'Number of Users',
    'reports.totalPlans.totalValue': 'Total Value',
    'reports.totalPlans.chartHeader': 'TOTAL PLANS VALUE',
    'reports.planDetails.header': 'PLAN DETAILS',
    'reports.planDetails.value': 'Value',
    'reports.planDetails.plan': 'Plan',
    'reports.companies.header': 'TOTAL USERS BY COMPANY',
    'reports.companies.name': 'Company name',
    'reports.companies.id': 'Identification',
    'reports.companies.total': 'User total',
    'monitoring.header': 'MONITORING',
    'monitoring.selectWhoMessage': 'Chose below who do you want to make your monitoring',
    'monitoring.errorTest': 'You are in the Trial version, this option is disabled.',
    'monitoring.anywhereName': 'Name',
    'monitoring.solicitationDate': 'Solicitation date',
    'monitoring.noCompanySelected': 'No company selected',
    'monitoring.historic': 'HISTORIC',
    'monitoring.approvalDate': 'Approval date',
    'monitoring.deletionDate': 'Deletion date',
    'monitoring.modalMessage': 'When requesting the monitoring of {setMasterName}, once it is accepted, $: 45,00 will be charged for each active customer on this Contactto.care. \nDo you want to do this? \nNOTE: IF YOUR CONTACTTO.CARE DOES NOT USE PLANS, IGNORE THIS MESSAGE',
    'monitoring.pending': 'Pending',
    'monitoring.approved': 'Approved',
    'monitoring.deleted': 'Deleted',
    'monitoring.noApproval': 'No approval',
    'activate.header': 'ACTIVATE CONTACTTO.CARE',
    'activate.registrationData': 'REGISTRATION DATA',
    'activate.zipCode': 'Zip Code',
    'activate.street': 'Street',
    'activate.number': 'Number',
    'activate.district': 'District',
    'activate.complement': 'Complement',
    'activate.city': 'City',
    'activate.state': 'State',
    'activate.country': 'Country',
    'activate.actions': 'ACTIONS',
    'activate.sign': 'SIGN',
    'date.startDate': 'Start Date',
    'search.header': 'SEARCH RESULTS FOR',
    'search.alarms': 'Alarms',
    'search.clients': 'Users',
    'search.contacts': 'Contacts',
    'search.transmitters': 'Sensors',
    'search.attendants': 'Attendants',
    'search.noResult': 'No results found',
    'date.endDate': 'End Date',
    'reminder.header': 'REMINDER FOR THE ALARM ',
    'reminder.reminderAttendant': 'Attendant',
    'reminder.reminderTime': 'Requested time',
    'reminder.reminderOpening': 'Start date',
    'reminder.reminderTitle': 'Reminder',
    'reminder.reminderReport': 'Report',
    'reminder.reminderContactList': 'Contact List',
    'reminder.timer': 'Timer',
    'reminder.informAll': 'Inform all operators?',
    'reminder.warning': 'Please, type a reminder and choose a timer.',
    'reminder.reminderWarning': 'Please, type your report before closing.',
    'reminder.btn': 'Create',
    'reminder.closeBtn': 'CLOSE',
    'reminder.rescheduleBtn': 'RESCHEDULE',
    'attend.reminder.createReminder': 'Create Reminder',
    'attend.reminder.message': 'Thank you! \n Your reminder has been generated! Afther the time is up, you will be notified. \n You can close this alarm and do another task.',
    'attend.reminder.openAttendant': 'Opened by',
    'attend.reminder.closeAttendant': 'Closed by',
    'attend.reminder.title': 'Reminder',
    'attend.reminder.opening': 'Open Time',
    'attend.reminder.close': 'Close Time',
    'attend.reminder.requestedTime': 'Requested Time',
    'attend.reminder.minutes': 'Minutes',
    'attend.reminder.report': 'Report',
    'attend.reminder.cardHeader': 'REMINDER NUMBER {reminderNumber}',
    'attend.filter.header': 'FILTER ALARMS',
    'attend.filter.button1': 'FILTER',
    'attend.filter.button2': 'ALARMS',
    'attend.filter.isTest': 'Test Users',
    'attend.filter.notTest': 'Normal Users',
    'attend.filter.hasAudio': 'Has audio',
    'attend.filter.company': 'Company',
    'attend.filter.attendTag': 'Call Type',
    'attend.filter.filteredBy': 'Filtered by',
    'attend.filter.id': 'Identification',
    'attend.filter.noAudio': 'No Audio',
    'history.header': 'VIEW LOG HISTORY OF',
    'history.selectContact': 'Select the desired Contact',
    'history.user': 'User',
    'history.contacts': 'Contacts',
    'history.transmitters': 'Sensors',
    'history.type': 'Type',
    'history.date': 'Date',
    'history.modifiedBy': 'Modified By',
    'history.transmitter': 'Sensor',
    'history.expand': '+ Expand',
    'history.hide': '- Hide',
    'history.before': 'Before',
    'history.after': 'After',
    'history.actionType.changed': 'Change',
    'history.actionType.deleted': 'Deletion',
    'history.actionType.created': 'Creation',
    'history.contact': 'Contact',
    'history.contact.phone': 'Phone',
    'history.contact.action': 'Action',
    'history.empty': 'Empty',
    'history.changedField': 'Changed Field',
    'error.component.title': 'Oops! It seems that an error has occurred',
    'error.component.errorcodetitle': 'Error code',
    'error.component.infotitle': 'Information',
    'chat.error.unavailable': 'This option is unavailable at the moment.',
    'chat.error.toActivate': 'To activate the chat, logout of the platform and access it again.',
    'chat.error.loggingOut': 'To logout, use the top-right menu - where your name is - and click on "Logout".',
    'chat.error.supportEmail': 'If the chat remains unavailable, send an email to suporte@tecnosenior.zendesk.com detailing the issue.',
    'return.makeCallLabel': 'Return to user',
    'return.makeCallButton': 'MAKE CALL',
    'return.firstHangupLabel': 'Wait 3 to 4 rings and hangup this call',
    'return.firstHangupButton': 'HANGUP',
    'return.waitSeconds': 'Waiting {duration} seconds...',
    'return.waitToneLabel': 'Wait until the end of the tone',
    'return.sendPasswordButton': 'SEND PASSWORD',
    'return.ongoingCallLabel': 'Ongoing Call',
    'return.reSendPasswordButton': 'SEND PASSWORD AGAIN',
    'return.hangupCall': 'HANGUP',
    'ata.header.port': 'Port',
    'ata.status.unknown': 'Unknown',
    'ata.status.unreachable': 'Unreachable',
    'extension.headerTitle': 'EXTENSION CALLS',
    'extension.headerTitleReminder': 'EXTENSION CALLS - REMINDER FOR THE ALARM',
    'extension.clientInfo': 'USER INFORMATION',
    'extension.client': 'User',
    'extension.phone': 'Phone',
    'extension.select': 'SELECT',
    'extension.selectClientContactPhone': 'SELECT CONTACT PHONE',
    'extension.own': 'Himself/Herself',
    'extension.makeCallLabel': 'CALLING',
    'extension.contact': 'Emergency Contact',
    'extension.noContactPhone': 'This user does not have a contact phone',
    'extension.makeCall': 'MAKE CALL',
    'extension.custom': 'Extra number',
    'extension.back': 'BACK',
    'extension.softphoneTitle': 'PHONE KEYBOARD',
    'extension.softphoneOpen': 'OPEN',
    'extension.softphoneClose': 'CLOSE',
    'extension.softphoneInput': 'Type the desired number',
    'extension.softphoneConfirm': 'Confirm'
  },
  pt: {
    'app.activate.message': 'Ativando esta conta, seus usuários atuais passarão a ser cobrados. Continuar?',
    'app.currency': 'R$',
    'toggle.button.inactives': 'Inativos',
    'logs.createdDate': 'Data de criação',
    'logs.createdBy': 'Criado por',
    'logs.modifiedDate': 'Data de modificação',
    'logs.modifiedBy': 'Modificado por',
    'attendant.attendantsHeader': 'CONTATOS DE SISTEMA',
    'attendant.userName': 'Nome do Atendente',
    'attendant.group': 'Grupo',
    'attendant.country': 'País',
    'paginate.of': 'de',
    'general.firstName': 'Nome',
    'general.lastName': 'Sobrenome',
    'general.yes': 'Sim',
    'general.no': 'Não',
    'general.admin': 'Administrador',
    'general.manager': 'Gerente',
    'general.operator': 'Operador',
    'general.owner': 'Proprietário',
    'general.actionsHeader': 'AÇÕES',
    'general.personalInfoHeader': 'INFORMAÇÕES PESSOAIS',
    'general.highPriority': 'Alta Prioridade',
    'general.mediumPriority': 'Média Prioridade',
    'general.lowPriority': 'Baixa Prioridade',
    'general.sysPriority': 'Alarme de Sistema',
    'general.allPriorities': 'Todas as Prioridades',
    'general.columnView': 'Visualizar',
    'general.columnEdit': 'Editar',
    'general.columnDeactivate': 'Desativar',
    'general.columnRecover': 'Recuperar',
    'general.columnDelete': 'Deletar',
    'general.clientColumn': 'Usuário',
    'general.attendant': 'Atendente',
    'general.resolvedBy': 'Resolvido por',
    'general.alarmType': 'Tipo de Alarme',
    'general.opening': 'Abertura',
    'general.closure': 'Fechamento',
    'general.accountNumber': 'Número da conta',
    'general.fullName': 'Nome',
    'general.cellphone': 'Celular',
    'general.saveButton': 'SALVAR',
    'general.add': 'ADICIONAR',
    'general.allPlans': 'Todos os Planos',
    'general.country': 'País',
    'general.other': 'Outro',
    'general.emptyList': 'Lista Vazia',
    'general.company': 'Empresa',
    'error.atLeastTwoChar': 'Campo deve possuir pelo menos dois caracteres',
    'error.cellPhoneNumbers': 'Campo deve possuir 11 números',
    'inputContainer.username': 'Nome de usuário: ',
    'inputContainer.firstName': 'Nome: ',
    'inputContainer.lastName': 'Sobrenome: ',
    'inputContainer.mobile': 'Celular: ',
    'inputContainer.password': 'Senha: ',
    'inputContainer.newPassword': 'Nova Senha',
    'inputContainer.confirmPassword': 'Confirmar Senha: ',
    'inputContainer.oldPassword': 'Senha Antiga',
    'inputContainer.accountNumber': 'Número da conta: ',
    'inputContainer.birthdate': 'Data de nascimento: ',
    'inputContainer.equipment': 'Equipamento: ',
    'inputContainer.typeOfAccount': 'Tipo de Conta: ',
    'inputContainer.healthPlan': 'Plano de Saúde: ',
    'inputContainer.limitations': 'Limitações: ',
    'inputContainer.medications': 'Medicamentos: ',
    'inputContainer.removalPlan': 'Plano de Remoção: ',
    'inputContainer.observations': 'Observações: ',
    'inputContainer.state': 'Estado: ',
    'inputContainer.street': 'Logradouro: ',
    'inputContainer.number': 'Número: ',
    'inputContainer.complement': 'Complemento: ',
    'inputContainer.district': 'Bairro: ',
    'inputContainer.zipCode': 'CEP: ',
    'inputContainer.city': 'Cidade: ',
    'inputContainer.accountPhone': 'Número do equipamento: ',
    'inputContainer.useVoIP': 'Usa VoIP:',
    'inputContainer.contactPhone': 'Telefone de contato {number}: ',
    'inputContainer.company': 'Empresa: ',
    'inputContainer.gender': 'Sexo: ',
    'inputContainer.relationship': 'Relação: ',
    'inputContainer.priority': 'Prioridade: ',
    'inputContainer.account': 'Conta: ',
    'inputContainer.attendId': 'Identificação: ',
    'inputContainer.appNumber': 'Número App:',
    'inputContainer.internalComments': 'Comentários Internos:',
    'dashboard.open.header': 'ATENDIMENTOS ABERTOS',
    'dashboard.open.noAttends': 'Nenhum atendimento aberto',
    'dashboard.open.attendant': 'Sem Atendente',
    'dashboard.closed.header': 'ÚLTIMOS {attendsLength} ATENDIMENTOS',
    'dashboard.closed.noAttends': 'Nenhum atendimento fechado',
    'dashboard.tabs.header': 'RESUMO DOS ATENDIMENTOS',
    'dashboard.tabs.quantity': 'QUANTIDADE',
    'dashboard.tabs.bypriority': 'POR PRIORIDADE',
    'dashboard.tabs.priority': 'PRIORIDADE',
    'dashboard.tabs.subhigh': 'ALTA',
    'dashboard.tabs.submedium': 'MEDIA',
    'dashboard.tabs.sublow': 'BAIXA',
    'dashboard.tabs.subsystem': 'SISTEMA',
    'dashboard.tabs.userstotal': 'TOTAL USUÁRIOS',
    'dashboard.card.quantity': 'Quantidade de Atendimentos',
    'dashboard.card.attendsPriority': 'Número de Atendimentos por Prioridade',
    'dashboard.card.clientsMonthly': 'Total de Usuários por Mês',
    'card.alarms.month': ' Mês: ',
    'card.alarms.totalAtt': ' Total de Atendimentos: ',
    'card.alarms.totalVoice': ' Chamadas com Voz: ',
    'sidebar.dashboard': 'Painel',
    'sidebar.users': 'Usuários',
    'sidebar.attends': 'Atendimentos',
    'sidebar.reports': 'Relatórios',
    'sidebar.profile': 'Meu perfil',
    'sidebar.manage': 'Gerenciar',
    'sidebar.config': 'Configurações',
    'sidebar.logout': 'Sair',
    'header.user.edit': 'Editar',
    'header.user.logout': 'Sair',
    'header.user.messages': 'Avisos',
    'header.icon.connection': 'Conexão com o servidor interrompida, aguarde 30 segundos ou recarregue a página.',
    'header.icon.blockedPhone': 'Seu microfone está bloqueado, por favor desbloqueie seu microfone. Clique no ícone para saber mais.',
    'header.icon.activePhone': 'O nome do seu microfone é: {microphone}. Se este não é seu microfone, clique no ícone.',
    'header.searchbar.search': 'Buscar',
    'header.trial.info': ' VERSÃO DE TESTES ',
    'header.trial.sign': ' ASSINAR ',
    'header.domain.add': 'ADICIONAR DOMÍNIO',
    'call.button.answer': 'ATENDER',
    'call.button.resume': 'RETOMAR',
    'call.button.view': 'VISUALIZAR',
    'call.button.details': 'DETALHES',
    'call.button.close': 'FECHAR',
    'modal.confirm.defaultMessage': 'Tem certeza que deseja realizar esta ação?',
    'modal.confirm.yes': 'SIM',
    'modal.confirm.no': 'NÃO',
    'modal.confirm.reminderClose': 'Você deseja fechar esta janela de lembrete?',
    'modal.notification.solveAttend': 'Fechar Atendimento',
    'form.button.cancel': 'Cancelar',
    'form.button.save': 'Salvar',
    'form.button.exclude': 'Excluir',
    'form.info.save': 'Você precisa preencher as seguintes informações para poder salvar:',
    'form.info.contact_personal': 'Pessoal - Nome, sobrenome, prioridade, relação e email',
    'form.info.contact_phone': 'Telefone - Pelo menos um primário',
    'form.info.client_personal': 'Informações pessoais - Número da conta, nome, sobrenome, data de nascimento',
    'form.info.client_address': 'Endereço - Logradouro, número, bairro, cidade, CEP',
    'form.info.client_phone_normal': 'Telefone - Telefone do equipamento',
    'form.info.client_phone_ihelp': 'Telefone ou IMEI - Telefone do equipamento OU IMEI do equipamento',
    'form.info.client_phone_sosapp': 'Telefone e Número do App - Telefone do equipamento E Número do App',
    'client.page.header': 'USUÁRIOS',
    'client.page.headerSubtitle': 'Filtrados por',
    'client.page.addAcc': 'CONTA',
    'client.page.impAcc1': 'IMPORTAR',
    'client.page.impAcc2': 'CONTA',
    'client.page.undoFilt1': 'DESFAZER',
    'client.page.undoFilt2': 'FILTRO',
    'client.page.editFilt': 'EDITAR FILTRO',
    'client.page.filter1': 'FILTRAR',
    'client.page.filter2': 'USUÁRIOS',
    'client.page.columnAccount': 'Conta',
    'client.page.columnTracker': 'Rastreador',
    'client.page.columnView': 'Visualizar',
    'client.page.columnContacts': 'Contatos',
    'client.page.columnAlarms': 'Alarmes',
    'client.page.columnTransmitters': 'Transmissores',
    'client.page.columnEdit': 'Editar',
    'client.page.columnDeactivate': 'Desativar',
    'client.page.columnRecover': 'Recuperar',
    'client.page.company': 'Empresa',
    'client.page.name': 'Nome',
    'client.page.lastName': 'Sobrenome',
    'client.page.equipment': 'Equipamento',
    'client.page.noTracker': 'Sem Rastreador',
    'client.form.primaryUserInfo': 'Informações pesoais usuário - 1',
    'client.form.secondaryUserInfo': 'Informações pesoais usuário - 2',
    'client.form.personalInfoHeader': 'INFORMAÇÕES DA CONTA',
    'client.form.editHeader': 'EDITAR USUÁRIO',
    'client.form.addHeader': 'ADICIONAR USUÁRIO',
    'client.form.testOpt': 'Usuário de testes: ',
    'client.form.equipmentError': 'Este IMEI já está sendo utilizado em outro lugar.',
    'client.form.equipmentErrorAccount': 'Este IMEI já está sendo utilizado pela conta {clientAccount}.',
    'client.form.addressHeader': 'ENDEREÇO',
    'client.form.noCompany': 'Sem empresa',
    'client.form.select': 'Selecionar',
    'client.form.male': 'Masculino',
    'client.form.female': 'Feminino',
    'client.form.notApplicable': 'Não Aplicável',
    'client.form.placeholderText': 'Este texto não aparecerá para os operadores durante o atendimento.',
    'client.error.accountNumber': 'Campo deve possuir pelo menos 4 caracteres',
    'client.error.accountExists': 'O número de conta já existe',
    'client.error.invalidMail': 'Email inválido',
    'client.error.atLeastTwoChar': 'Campo deve possuir pelo menos dois caracteres',
    'client.error.firstCharUppercase': 'A primeira letra precisa ser maiúscula',
    'client.error.atLeastOneNum': 'Campo deve possuir pelo menos um número',
    'client.error.appNumber': 'Campo deve possuir sete caracteres',
    'client.error.dateFormat': 'Campo deve estar no formato dd/mm/aaaa',
    'client.error.invalidCellphone': 'Número de celular inválido',
    'client.error.cellPhoneNumbers': 'Campo deve possuir 11 números',
    'client.error.cellPhoneNumbersMU': 'Campo deve possuir 11 números',
    'client.error.regularPhones': 'Campo deve possuir 10 ou 11 números',
    'client.error.regularPhonesMU': 'Campo deve possuir 10 ou 11 números',
    'client.error.phoneNumbersGB': 'Campo deve possuir 12 números',
    'client.error.zipCode': 'Campo deve possuir 8 números',
    'client.error.cpfInvalidLen': 'O cpf precisa ter 11 dígitos',
    'client.error.cpfInvalid': 'Número de cpf inválido',
    'client.error.accountOutOfRange': 'O número de conta está fora da faixa de contas desta empresa',
    'client.error.ataExists': 'O número deste ATA (VoIP) já está sendo utilizado em outro lugar',
    'client.formMessage.equipment': 'Cliente está marcado com {equipmentName} mas está com um celular.\n\nSalvar mesmo assim?',
    'client.formMessage.testClient': 'Este cliente está marcado como teste, a partir de agora as chamadas dele não abrirão canal de voz, até que esta opção seja desmarcada.\n\nSalvar mesmo assim?',
    'client.formMessage.voip': 'Este usuário está marcado como VoIP, certifique-se que este equipamento está conectado a um equipamento do tipo ATA para poder realizar chamadas de emergência.',
    'client.detail.mainHeader': 'VISUALIZAR INFORMAÇÕES ',
    'client.detail.testUser': 'Usuário de testes',
    'client.detail.accountType': 'Tipo de Conta',
    'client.detail.doesNotHave': 'Não Possui',
    'client.detail.testUserYes': 'Sim',
    'client.detail.testUserNo': 'Não',
    'client.detail.birthdate': 'Data de nascimento',
    'client.detail.observations': 'Observações',
    'client.detail.healthPlan': 'Plano de saúde',
    'client.detail.limitations': 'Limitações',
    'client.detail.medications': 'Medicamentos',
    'client.detail.removalPlan': 'Plano de remoção',
    'client.detail.equipment': 'Equipamento',
    'client.detail.company': 'Empresa', 
    'client.detail.street': 'Logradouro',
    'client.detail.number': 'Número',
    'client.detail.complement': 'Complemento',
    'client.detail.district': 'Bairro',
    'client.detail.zipCode': 'CEP',
    'client.detail.city': 'Cidade',
    'client.detail.state': 'Estado',
    'client.detail.accountPhone': 'Telefone do Equipamento',
    'client.detail.contactPhone': 'Telefone de Contato',
    'client.detail.accountNumber': 'Número de Conta',
    'client.detail.indepCall': 'Realizar chamada independente',
    'client.detail.name': 'Nome completo',
    'client.detail.gender': 'Sexo',
    'client.detail.age': 'Idade',
    'client.tracker.mapHeader': 'MAPA',
    'client.tracker.inNumbers': 'Números de Entrada',
    'client.tracker.inboundNumber': 'Número de Entrada',
    'client.tracker.inboundTooltip': 'Ativa/Desativa atendimento automático. Caso seja desativado, será necessário o usuário pressionar a tecla de SOS para atender.',
    'client.tracker.geofenceMapHeader': 'MAPA DE ZONAS DE SEGURANÇA',
    'client.tracker.geofenceMode': 'Modo',
    'client.tracker.geofenceRadius': 'Raio',
    'client.tracker.meters': 'Metros',
    'client.tracker.toKnowMore': 'PARA SABER MAIS',
    'client.tracker.clickHere': ' CLIQUE AQUI ',
    'client.tracker.begining': 'Início: ',
    'client.tracker.end': 'Fim: ',
    'client.tracker.search': 'Procurar',
    'client.tracker.trackerFrom': 'RASTREADOR DE ',
    'client.tracker.history': 'Histórico',
    'client.tracker.programming': 'Programação',
    'client.tracker.geofences': 'Zonas de Segurança',
    'client.tracker.geofence': 'Zona de Segurança',
    'client.tracker.fdmode': 'Modo de detecção de quedas',
    'client.tracker.voipcalling': 'Fazer chamadas por VoIP (Voice over IP)',
    'client.tracker.sosbreadcrumb': 'Enviar posição durante SOS a cada 30 segundos',
    'client.tracker.sosbreadcrumbint': 'Intervalo de posição durante SOS',
    'client.tracker.autopost': 'Intervalo de postagem de localização',
    'client.tracker.vin1': 'Telefone permitido para receber ligações 1',
    'client.tracker.vin2': 'Telefone permitido para receber ligações 2',
    'client.tracker.simplified': 'Dados simplificados',
    'client.tracker.disabled': 'Desabilitado',
    'client.tracker.enabled': 'Habilitado',
    'client.tracker.highSensitivity': 'Sensitividade Alta',
    'client.tracker.mediumHighSensitivity': 'Sensitividade Média-Alta',
    'client.tracker.normalSensitivity': 'Sensitividade Normal',
    'client.tracker.enterPerimeter': 'Alerta na entrada do perímetro',
    'client.tracker.exitPerimeter': 'Alerta na saída do perímetro',
    'client.tracker.bothPerimeter': 'Alerta na entrada/saída do perímetro',
    'client.tracker.seconds': 'Segundos',
    'client.tracker.minutes': 'Minutos',
    'client.tracker.saveSettings': 'Salvar Configurações',
    'client.tracker.saveFence': 'Salvar Zona',
    'client.tracker.latest': 'Última Posição',
    'client.tracker.errorNoIMEI': 'ESTE USUÁRIO NÃO ESTÁ COM O IMEI CADASTRADO.\n',
    'client.tracker.errorNoData': 'NÃO HÁ DADOS PARA O DIA SELECIONADO.\n\n O RASTREADOR DEVE SER CONFIGURADO COM O SERVIDOR DO CONTACTTO.CARE.\n\n\n',
    'client.tracker.errorNoDataLatest': 'NÃO FORAM ENCONTRADOS DADOS.\n\n O RASTREADOR DEVE SER CONFIGURADO COM O SERVIDOR DO CONTACTTO.CARE.\n\n\n',
    'client.tracker.errorNoUser': 'ESTE USUÁRIO NÃO EXISTE',
    'client.tracker.errorUnexpected': 'ERRO INESPERADO',
    'client.tracker.errorNoSimpleToday': 'NÃO É POSSÍVEL PESQUISAR POR DADOS SIMPLIFICADOS PARA A DATA DE HOJE.\n ESSA INFORMAÇÃO ESTÁ DISPONÍVEL APENAS PARA DIAS ANTERIORES.',
    'client.tracker.errorEmptyPointsDates': 'SEM DADOS PARA AS DATAS ENTRE {startDate} AS {startHour} HORAS E {endDate} AS {endHour} HORAS',
    'client.tracker.errorEmptyPointsHours': 'SEM DADOS PARA AS DATAS ENTRE {startDate} AS {startHour} HORAS E {endDate} AS {endHour} HORAS',
    'client.tracker.errorEmptyPoint': 'ESTE DISPOSITIVO NÃO TEM DADOS PARA O DIA DE HOJE, VERIFIQUE A BATERIA DO SEU SISTEMA',
    'client.tracker.ihelpNoData': 'NENHUM PONTO ACHADO PARA ESTE DISPOSITIVO. \n VERIFIQUE SE ESTE RASTREADOR ESTÁ LIGADO',
    'client.tracker.battery': 'Bateria',
    'client.tracker.timestamp': 'Data',
    'client.tracker.charging': 'Carregando',
    'client.tracker.cellSignal': 'Sinal Celular',
    'client.tracker.eventType': 'Tipo de Evento',
    'client.tracker.searchAddress': 'Pesquise o endereço desejado',
    'client.tracker.configsSaved': 'Configurações salvas com sucesso!',
    'client.tracker.configsSent': 'Requisição enviada com sucesso!',
    'client.tracker.igoConfigSaved': 'Configurações enviadas para o dispositivo. \n Por favor, aguarde 5 minutos para que o equipamento seja atualizado.',
    'client.tracker.location': 'Localização',
    'client.tracker.updateLoc': 'Atualizar Localização',
    'client.tracker.event': 'Evento',
    'client.tracker.powerSav': 'Economia de Energia',
    'client.tracker.voiceMon': 'Modo Escuta',
    'client.tracker.ledInd': 'Desativar Indicador de LED',
    'client.tracker.overspeed': 'Alarme de alta velocidade',
    'client.tracker.fallDetection': 'Detecção de Quedas',
    'client.tracker.gsmTooltip': 'Ativa/Desativa localização via GSM quando o satélite GPS não está disponível. Aumento do consumo de bateria.',
    'client.tracker.powerSavTooltip': 'Ativa/Desativa modo de economia inteligente de energia. Ao desativar essa função, o dispositivo envia sua localização de acordo com o intervalo de postagem selecionado.',
    'client.tracker.voiceMonTooltip': 'Ativa/Desativa modo escuta. Números cadastrados no dispositivo podem realizar chamadas silenciosas e ouvir o que está acontecendo com o dispositivo sem que seja indicada a chamada.',
    'client.tracker.ledIndTooltip': 'Ativa/Desativa indicação dos LEDS, os LEDS laterais vão se apagar/acender, o dispositivo funciona normalmente.',
    'client.tracker.fallDetectionTooltip': 'Ativa/Desativa detecção de quedas. Dispositivo vai avisar telefones cadastrados que ocorreu uma queda sem necessidade do botão ser pressionado.',
    'client.tracker.autopostTooltip': 'Selecionar o intervalo de tempo para que o dispositivo enviará a sua localização. A configuração padrão do dispositivo é enviar a cada 3 minutos quando em movimento e a cada 10 minutos quando em repouso.',
    'client.tracker.overspeedTooltip': 'Selecionar a velocidade desejada para ativar o alarme de alta velocidade ou Desligar essa função. Quando a velocidade for excedida, o dispositivo irá enviar SMS aos contatos cadastrados.',
    'client.tracker.medicationTimer': 'Horário',
    'client.tracker.medicationAck': 'Reconhecimento de Medicação',
    'client.tracker.reminderActive': 'Lembrete Ativo',
    'client.tracker.saveMed': 'Salvar Lembrete',
    'client.tracker.medication': 'Lembrete',
    'client.tracker.medicationOption': 'Programar Lembretes de medicamento',
    'client.tracker.saveInbound': 'Salvar Telefones de Entrada',
    'client.tracker.hour': 'Hora',
    'client.tracker.sendTime': 'Data do ponto',
    'client.tracker.gpsAccuracy': 'Precisão do GPS',
    'client.tracker.geoNotConfigured': 'Por favor digite um endereço na barra de pesquisa para configurar a zona de segurança.',
    'client.tracker.falldownActivate': 'Ativar detecção de quedas',
    'client.tracker.motionActivate': 'Ativar detecção de movimento',
    'client.tracker.noMotionActivate': 'Ativar detecção de inatividade',
    'client.tracker.overspeedActivate': 'Ativar detecção excesso de velocidade',
    'client.tracker.tiltAlertActivate': 'Ativar detecção de inclinação',
    'client.tracker.sensitivity': 'Sensitividade',
    'client.tracker.movmentActivationPeriod': 'Tempo para ativação (em segundos)',
    'client.tracker.movementAlertCooldown': 'Tempo para envio entre alarmes (em segundos)',
    'client.tracker.noMovementStaticPeriod': 'Tempo parado para detecção de inatividade (em segundos, entre 300 e 36000)',
    'client.tracker.detectionSpeed': 'Velocidade para detecção (KM/h)',
    'client.tracker.tiltAlertAngle': 'Limite de Ângulo (em Graus)',
    'client.tracker.tiltTimeTreshold': 'Tempo parado para detecção de inclinação (em segundos, entre 10 e 3600)',
    'client.tracker.date': 'Data',
    'client.tracker.accuracy': 'Precisão',
    'client.tracker.direction': 'Direção',
    'client.tracker.altitude': 'Altitude',
    'client.tracker.speed': 'Velocidade',
    'client.tracker.signalStrength': 'Força do sinal',
    'client.tracker.completeCharge': 'Carga completa',
    'client.tracker.lastRead': 'Última leitura',
    'client.tracker.alarmClock': 'Programar lembretes',
    'client.tracker.reminder': 'Lembrete',
    'client.tracker.medicationAudio': 'Não esqueça sua medicação!',
    'client.tracker.appointmentAudio': 'Não esqueça seu compromisso!',
    'client.tracker.noonAudio': 'São doze horas!',
    'client.tracker.wakeUpAudio': 'É hora de despertar!',
    'client.tracker.audioSelection': 'Seleção de Áudio',
    'client.tracker.audioDuration': 'Duração do  Áudio',
    'client.tracker.daySelection': 'Repetir nos dias',
    'client.tracker.saveReminder': 'Salvar Lembrete',
    'client.tracker.monAbbrv': 'SEG',
    'client.tracker.tueAbbrv': 'TER',
    'client.tracker.wedAbbrv': 'QUA',
    'client.tracker.thuAbbrv': 'QUI',
    'client.tracker.friAbbrv': 'SEX',
    'client.tracker.satAbbrv': 'SAB',
    'client.tracker.sunAbbrv': 'DOM',
    'client.import.importHeader': 'Insira um arquivo do tipo csv ou excel',
    'client.import.importClick': ' clique ou arraste o arquivo',
    'client.import.noFileSelected': ' Nenhum arquivo foi selecionado.',
    'client.import.invalidExtension': ' Arquivo com extensão inválida.',
    'client.import.standardFile': ' Baixar o formato padrão do ',
    'client.import.help': 'As colunas com um * são obrigatórias',
    'client.import.file': 'arquivo',
    'client.import.cancel': 'CANCELAR',
    'client.import.send': 'ENVIAR',
    'client.importInfo.header': 'RESULTADO DA IMPORTAÇÃO',
    'client.importInfo.clients': 'Clientes',
    'client.importInfo.success': '  Importados com Sucesso: ',
    'client.importInfo.notImported': '  Não Importados (erro): ',
    'client.importInfo.line': 'Linha',
    'client.importInfo.name': 'Nome',
    'client.importInfo.account': 'Conta',
    'client.importInfo.contacts': 'Contatos',
    'client.importInfo.downloadReport': 'Faça o download do relatório',
    'client.filter.first_name': 'Nome',
    'client.filter.last_name': 'Sobrenome',
    'client.filter.account': 'Conta',
    'client.filter.group_id': 'Empresa',
    'client.filter.equipment_ids': 'Usa Rastreador',
    'client.filter.noGroup': 'Sem Empresa',
    'client.modal.inactivateMsg': 'Você está inativando a conta {clientAccount}. Tem certeza que deseja realizar esta ação?',
    'client.modal.reactivateMsg': 'Você está reativando a conta {clientAccount}, se não houver outro usuário com esta conta os caracteres extras vão desaparecer, caso contrário, os caracteres vão precisar serem removidos manualmente. Tem certeza que deseja realizar esta ação?',
    'filter.header': 'FILTRAR USUÁRIOS',
    'filter.useTracker': 'Usa Rastreador',
    'filter.company': 'Empresa',
    'filter.ignore': 'Ignorar',
    'filter.btn': 'FILTRAR',
    'contact.page.header': 'CONTATOS DE',
    'contact.page.accountNumber': 'Número da conta',
    'contact.page.addCont': ' CONTATO',
    'contact.page.priority': 'Prioridade',
    'contact.page.name': 'Nome',
    'contact.page.phoneOne': 'Telefone 1',
    'contact.page.phoneTwo': 'Telefone 2',
    'contact.page.answered': 'Atendeu',
    'contact.page.contacted': 'Contatado',
    'contact.form.editHeader': 'EDITAR CONTATO DE',
    'contact.form.addHeader': 'ADICIONAR CONTATO DE',
    'contact.form.personal': 'PESSOAL',
    'contact.form.account': 'Conta',
    'contact.form.priority': 'Prioridade',
    'contact.form.relationship': 'Relação',
    'contact.form.hasKeys': 'Possui chaves',
    'contact.form.contactInfo': 'CONTATO',
    'contact.form.zipCode': 'CEP',
    'contact.form.street': 'Logradouro',
    'contact.form.district': 'Bairro',
    'contact.form.number': 'Número',
    'contact.form.complement': 'Complemento',
    'contact.form.city': 'Cidade',
    'contact.form.state': 'Estado',
    'contact.form.addPhoneButton': 'Adicionar novo telefone',
    'contact.form.actionsHeader': 'AÇÕES',
    'contact.form.cancel': 'Cancelar',
    'contact.form.save': 'Salvar',
    'contact.form.phone': 'Telefone',
    'contact.form.type': 'Tipo',
    'contact.form.primary': 'Primário',
    'contact.form.work': 'Trabalho',
    'contact.form.home': 'Casa',
    'contact.form.cellphone': 'Celular',
    'contact.form.requestTracking': 'PODE SOLICITAR TRACKING:',
    'contact.form.requestTrackingMsg': 'Pode solicitar o comando track por sms para receber a última localização do sistema',
    'contact.form.isContractor': 'Contratante',
    'contact.form.isFamily': 'Familiar Administrador',
    'contact.error.email': 'email digitado não está correto',
    'contact.error.twoPrimaries': 'Este contato possui dois telefones primários',
    'contact.error.familyAdmExists': 'Já existe outro contato como familiar administrador',
    'contact.error.noPrimary': 'Este contato não possui telefones primários',
    'contact.detail.mainHeader': 'VISUALIZAR INFORMAÇÕES DO CONTATO DE',
    'transmitters.zone': 'Zona: ',
    'transmitters.description': 'Descrição: ',
    'transmitter.form.selectUser': 'Utilizar para o usuário 2',
    'transmitters.details.header': 'DETALHES DO TRANSMISSOR',
    'transmitter.detail.identifier': 'Identificador',
    'transmitter.detail.description': 'Descrição',
    'transmitter.detail.observations': 'Observações',
    'transmitter.error.zone': 'Campo precisa de 3 caracteres',
    'transmitters.detail.useForUser2': 'Utilizar para o usuário 2',
    'transmitters.identifier': 'Identificador TX: ',
    'transmitters.observations': 'Observações',
    'transmitters.page.header': 'TRANSMISSORES DE',
    'transmitters.page.transmitterAdd': 'TRANSMISSOR',
    'transmitters.form.informations': 'INFORMAÇÕES',
    'transmitters.form.editTransmitter': 'EDITAR TRANSMISSOR',
    'transmitters.form.addTransmitter': 'ADICIONAR TRANSMISSOR',
    'attend.list.mainHeader': 'ATENDIMENTOS',
    'attend.list.loading': 'Carregando...',
    'attend.list.isTest': 'Teste',
    'attend.list.clientAttendsFrom': 'ATENDIMENTOS DE ',
    'attend.detail.callType': 'TIPO DE ATENDIMENTO',
    'attend.detail.comments': 'COMENTÁRIOS',
    'attend.detail.systemAttendants': 'ATENDENTES DO SISTEMA',
    'attend.detail.mainHeader': 'ATENDIMENTO',
    'attend.detail.birthdate': 'Data de Nascimento',
    'attend.detail.clientAddress': 'Endereço',
    'attend.detail.clientAccount': 'Conta',
    'attend.detail.clientCompany': 'Empresa',
    'attend.detail.clientAccountPhone': 'Número do Equipamento',
    'attend.detail.clientContactPhone': 'Telefone do Usuário',
    'attend.detail.clientHealthplan': 'Plano de Saúde',
    'attend.detail.clientRemoval': 'Remoção | Ambulância',
    'attend.detail.limitations': 'Limitações',
    'attend.detail.medications': 'Medicamentos',
    'attend.detail.clientObservations': 'Observações',
    'attend.detail.doesNotHave': 'Não possui',
    'attend.detail.clientHeader': 'USUÁRIO',
    'attend.detail.alarmHeader': 'ALARMES',
    'attend.detail.alarmName': 'Nome',
    'attend.detail.alarmZone': 'ZONA',
    'attend.detail.alarmDate': 'DATA',
    'attend.detail.alarmNoRecord': '{alarmCode} - sem cadastro',
    'attend.detail.reportHeader': 'COMENTÁRIOS',
    'attend.detail.event': 'Evento',
    'attend.detail.contactedHeader': 'PESSOAS CONTATADAS',
    'attend.detail.noContacted': 'Sem pessoas contatadas',
    'attend.detail.noReminders': 'Sem lembretes',
    'attend.detail.attendTag': 'Tipo do Atendimento',
    'attend.detail.clientDetailHeader': 'DETALHES DO USUÁRIO',
    'attend.detail.alarmDetailHeader': 'DETALHES DO ALARME',
    'attend.detail.alarmType': 'TIPO',
    'attend.detail.alarmCategory': 'CATEGORIA',
    'attend.detail.detailsHeader': 'DETALHES',
    'attend.detail.locationHeader': 'LOCALIZAÇÃO',
    'attend.detail.emergencyContacts': 'CONTATOS DE EMERGÊNCIA',
    'attend.detail.attendants': 'ATENDENTES',
    'attend.detail.city': 'Cidade',
    'attend.detail.zipCode': 'CEP',
    'attend.detail.eventCategory': 'Evento',
    'attend.detail.restorationCategory': 'Restauração',
    'attend.detail.primaryHeader': 'DETALHES DO USUÁRIO - 1',
    'attend.detail.secondaryHeader': 'DETALHES DO USUÁRIO - 2',
    'attend.detail.audioInfo': 'INFORMAÇÔES DE ÁUDIOS EXTRAS',
    'attend.closed.infoLabel': 'DETALHES DO ATENDIMENTO',
    'attend.closed.clientMessageLabel': 'HISTÓRICO DE MENSAGENS',
    'attend.closed.clientMessage': 'MENSAGEM',
    'attend.closed.clientMessageReply': 'RESPOSTA',
    'attend.closed.resolvedBy': 'RESOLVIDO POR',
    'attend.closed.infoOpening': 'Hora de abertura',
    'attend.closed.infoAttend': 'Hora de atendimento',
    'attend.closed.infoClosure': 'Hora de fechamento',
    'attend.closed.infoHeader': 'INFORMAÇÕES',
    'attend.closed.emergencyContact': 'CONTATO DE EMERGÊNCIA',
    'attend.closed.attendant': 'ATENDENTE',
    'attend.closed.contactedPersons': 'PESSOAS CONTATADAS',
    'attend.closed.remindersHeader': 'LEMBRETES',
    'attend.closed.searchingFor': 'Procurando...',
    'attend.closed.noAudio': 'Sem áudio',
    'attend.closed.audioDate': 'DATA DO ÁUDIO',
    'attend.closed.audioOrigin': 'ATENDENTE',
    'attend.closed.audioDestiy': 'DESTINO',
    'attend.closed.audioStatus': 'STATUS',
    'attend.closed.audioType': 'TIPO',
    'attend.closed.audioDuration': 'DURAÇÃO',
    'attend.closed.downloadAudio': 'BAIXAR',
    'attend.closed.audioTypeAttend': 'Atendimento',
    'attend.closed.audioTypeReminder': 'Lembrete',
    'attend.closed.audioLoading': 'Carregando...',
    'attend.open.takeCall': 'ATENDER CHAMADO',
    'attend.open.callDuration': 'DURAÇÃO DA CHAMADA',
    'attend.open.hangUp': 'DESLIGAR',
    'attend.open.timeToAnswer': 'TEMPO PARA ATENDER',
    'attend.open.takeAttend': 'PEGAR ATENDIMENTO',
    'attend.open.callEnded': 'CHAMADA ENCERRADA',
    'attend.open.attended': 'Atendido',
    'attend.open.contacted': 'Contatado',
    'attend.open.contactsHeader': 'CONTATOS DE EMERGÊNCIA',
    'attend.open.speak': 'Falar',
    'attend.open.listen': 'Ouvir',
    'attend.open.speakAndListen': 'Falar e Ouvir',
    'attend.open.systemAttendants': 'Atendentes do Sistema',
    'attend.open.errorNoPoints': 'NÃO FOI POSSÍVEL ACHAR AS COORDENADAS PARA ESTE USUÁRIO. \n POR FAVOR, ESPERE ALGUNS SEGUNDOS E CLIQUE NO BOTÃO DE ATUALIZAR LOCALIZAÇÃO.\n',
    'attend.open.closedCall': 'Chamada Encerrada',
    'attend.open.years': 'anos',
    'attend.open.saveButton': 'SALVAR ATENDIMENTO',
    'attend.open.noAudioAlarm': 'Esta chamada não contém áudio 🙉',
    'attend.open.callUser': 'RETORNAR PARA USUÁRIO',
    'attend.open.extensionCall': 'CHAMADAS DE EXTENSÃO',
    'attend.open.emptyMessage': 'MENSAGEM VAZIA',
    'attend.open.replyMessage': 'RESPONDER',
    'attend.open.replyCancel': 'CANCELAR',
    'attend.open.connecting': 'Conectando...',
    'attend.open.stillConnecting': 'Ainda estamos tentando conectar, você pode desligar se preferir',
    'attend.open.calling': 'Realizando ligação, aguarde um instante',
    'attend.open.callConnected': 'Chamada conectada',
    'attend.open.callNotConnected': 'Sem conexão com o servidor de atendimentos',
    'attend.open.toSaveTooltip': 'Para salvar',
    'attend.open.toSaveTooltipInstruction': 'Encerre a chamada e escolha o Tipo de Atendimento',
    'attend.modal.emergencyCall': 'Chamada de Emergência',
    'attend.tag.emergencyCall': 'Chamada de Emergência',
    'attend.tag.testCall': 'Chamada de Teste',
    'attend.tag.techSupport': 'Suporte Técnico',
    'attend.tag.accidentalCall': 'Chamada Acidental',
    'attend.tag.commFail': 'Falha de Comunicação',
    'attend.warning.cancel': 'Mensagens de aviso canceladas.',
    'attend.warning.sent': 'Mensagens de aviso enviadas.',
    'attend.warning.sendingIn': 'Enviando mensagens de aviso em {seconds} segundos...',
    'attend.warning.cancelMessages': 'Cancelar envio',
    'attend.closed.errorNoPoints': 'COORDENADAS NÃO ENCONTRADAS PARA ESTE ATENDIMENTO',
    'attend.closed.reportNoComment': 'Sem comentários',
    'attend.description.sosAlarm': 'Botão SOS',
    'attend.description.falldownAlarm': 'Detecção de queda',
    'attend.description.overspeedAlarm': 'Excesso de velocidade',
    'attendant.form.phone': 'Telefone',
    'attendant.form.passwordChanged': 'Sua senha foi alterada, acesse novamente.',
    'attendant.form.passwordHeader': 'SENHA',
    'attendant.form.changePassword': 'Alterar Senha',
    'attendant.form.unauthorized': 'Você não tem autorização para acessar essa página',
    'attendant.form.editAttendantHeader': 'EDITAR ATENDENTE',
    'attendant.form.addAttendantHeader': 'ADICIONAR ATENDENTE',
    'attendant.form.changePassModalHeader': 'Troca de Senha',
    'attendant.list.attendAdd': 'ATENDENTE',
    'attendant.error.invalidEmail': 'Email inválido',
    'attendant.error.emailUsed': 'Email já cadastrado, por favor digite outro',
    'attendant.error.usernameIncorrect': 'Campo deve ser escrito no formato "nome" ou "nome.outronome"',
    'attendant.error.usernameUsed': 'Nome de usuário já cadastrado, por favor digite outro',
    'attendant.error.passwordEightCharacters': 'Campo deve possuir pelo menos 8 caracteres',
    'attendant.error.passwordMustBeEqual': 'Campo deve ser igual ao campo de senha',
    'attendant.error.oldPassIncorrect': 'Senha antiga incorreta',
    'management.header': 'GERENCIAR',
    'management.attendantsOption': 'ATENDENTES',
    'management.alarmsOption': 'ALARMES',
    'management.monitoringOption': 'MONITORAMENTO',
    'management.companiesOption': 'EMPRESAS',
    'management.trackersOption': 'RASTREADORES',
    'management.iot.header': 'Dispositivos da Internet das Coisas',
    'management.iot.lastEntry': 'Último Registro',
    'alarms.addAlarm': 'ALARME',
    'alarms.name': 'Nome',
    'alarms.code': 'Código',
    'alarms.priority': 'Prioridade',
    'alarms.editHeader': 'EDITAR ALARME',
    'alarms.addHeader': 'ADICIONAR ALARME',
    'alarms.informations': 'INFORMAÇÕES',
    'companies.addCompany': 'EMPRESA',
    'companies.addPerson': 'COLABORADOR',
    'companies.edit': 'EDITAR',
    'companies.delete': 'DELETAR',
    'companies.editHeader': 'EDITAR EMPRESA',
    'companies.addHeader': 'ADICIONAR EMPRESA',
    'companies.name': 'Nome',
    'companies.remove': 'REMOVER EMPRESA',
    'companies.form.ranges': 'FAIXAS DE CONTA',
    'companies.form.maxAccount': 'Conta máxima',
    'companies.form.minAccount': 'Conta mínima',
    'companies.form.addRange': 'Adicionar Faixa',
    'companies.error.minMax': 'Conta mínima não pode ser maior do que conta máxima',
    'companies.error.noMinOrMax': 'Conta mínima ou máxima não enviada',
    'companies.error.rangeUsed': 'Esta faixa de contas já está sendo utilizada ou está dentro de outra faixa',
    'company.delete.warningHeader': 'AVISO',
    'company.delete.warningMsg': 'Para remover uma empresa por completo, primeiro, você precisa inativar seus colaboradores e excluir todos os seus usuários (ou alterar sua empresa).',
    'company.delete.warning': 'Você já realizou estas ações? Deseja prosseguir?',
    'company.delete.deleteUsers': 'Excluir acessos dos colaboradores (login, email)?',
    'company.delete.loading': 'Aguarde...',
    'company.delete.success': 'Empresa removida com sucesso!',
    'company.delete.errorTitle': 'OPS! Ocorreu um erro ao remover esta empresa.',
    'company.delete.errorHasClients': 'Esta empresa ainda possui usuários. Verifique também usuários inativos.',
    'company.delete.errorHasUsers': 'Esta empresa ainda possui colaboradores ativos.',
    'companies.addPersonHeader': 'ADICIONAR COLABORADOR',
    'companies.editPersonHeader': 'EDITAR COLABORADOR',
    'config.viewAttendantsList': 'Visualizar Lista de Atendentes',
    'config.viewContactsList': 'Visualizar Lista de Contatos',
    'config.openAlarms': 'Atendimentos Abertos (Tela)',
    'config.globalOptHeader': 'CONFIGURAÇÕES ADMINISTRATIVAS',
    'config.micandaudioconfig': 'CONFIGURAÇÃO DE MICROFONE E ÁUDIO',
    'config.viewClosedAttends': 'Visualizar Atendimentos Fechados',
    'config.viewResume': 'Visualizar Resumo dos Atendimentos',
    'config.viewQuantityAttends': 'Visualizar Quantidade de Atendimentos',
    'config.viewGraphicCards': 'Visualizar Card de Gráficos',
    'config.dashboard': 'Painel',
    'config.localOptHeader': 'CONFIGURAÇÃO DASHBOARD',
    'config.twoGlobalUncheck': 'Não é possível desabilitar as duas configurações globais de atendimento. Por favor habilite uma.',
    'config.saveError': 'Ocorreram erros ao salvar as configurações',
    'config.saveSuccess': 'Configurações salvas!',
    'config.openTickets': 'Atendimentos Abertos (Tela)',
    'config.audioEcho': 'Teste de eco',
    'config.hangupAudio': 'Desligar Teste',
    'config.makeAudio': 'Testar',
    'reports.generateReport': 'Gerar Relatório',
    'reports.selectYear': 'Selecione o Ano',
    'reports.reportOf': 'RELATÓRIO DE',
    'reports.selectedcode': 'CÓDIGO SELECIONADO',
    'reports.attendHeader': 'ATENDIMENTOS',
    'reports.ofType': 'DO TIPO',
    'reports.accountHeader': 'CONTA',
    'reports.generatePDF': 'Gerar PDF',
    'reports.company': 'Empresa',
    'reports.client': 'Usuário',
    'reports.account': 'Conta',
    'reports.alarm': 'Alarme',
    'reports.category': 'Categoria',
    'reports.event': 'Evento',
    'reports.restoration': 'Restauração',
    'reports.date': 'Data',
    'reports.opening': 'Abertura',
    'reports.closure': 'Encerramento',
    'reports.duration': 'Duração',
    'reports.attendant': 'Atendente',
    'reports.solvedBy': 'Resolvido por',
    'reports.emptyReport': 'Relatório vazio',
    'reports.reportButton': 'Relatório',
    'reports.graphicButton': 'Gráfico',
    'reports.page.header': 'RELATÓRIOS',
    'reports.page.usersRepHeader': 'Usuários',
    'reports.page.alarmsRepHeader': 'Alarmes',
    'reports.page.attendantsRepHeader': 'Atendentes',
    'reports.page.attByAnywhereRepHeader': 'Atendidos pelo Anywhere',
    'reports.page.lengthOfStayRepHeader': 'Tempo de Permanência',
    'reports.page.attsByPeriodRepHeader': 'Atendimentos por Período',
    'reports.page.totalPlansRepHeader': 'Total de Planos',
    'reports.page.clientsByPlanRepHeader': 'Usuários por Plano',
    'reports.page.monthlyClientRepHeader': 'Acompanhamento mensal de Usuários',
    'reports.page.totalClientsRepHeader': 'Total de Usuários por Mês',
    'reports.page.clientByCompany': 'Total de Usuários por Empresa',
    'reports.page.attendTagsRepHeader': 'Atendimentos por Tipo',
    'reports.clients.totalAlarms': 'TOTAL DE ALARMES',
    'reports.clients.selectClient': 'Selecione o Usuário',
    'reports.alarms.selectPriority': 'Selecione a Prioridade',
    'reports.alarms.selectAlarmCode': 'Selecione o Código de Alarme',
    'reports.alarms.alarmsOf': 'ALARMES DE',
    'reports.attendants.selectAttendant': 'Selecione o Atendente',
    'reports.attendants.attendsOf': 'ATENDIMENTOS DE',
    'reports.attendants.group': 'GRUPO',
    'reports.attendants.totalAttends': 'TOTAL DE ATENDIMENTOS',
    'reports.plans.selectPlan': 'Selecione o Plano',
    'reports.anywhere.answeredHeader': 'ATENDIMENTOS REALIZADOS PELO ANYWHERE',
    'reports.anywhere.numberOfAttends': 'Número de Atendimentos',
    'reports.length.lengthOfStayHeader': 'TEMPO DE PERMANÊNCIA DOS USUÁRIOS',
    'reports.length.daysLenght': 'Dias de Permanência',
    'reports.length.createdDate': 'Data de Criação',
    'reports.length.deletedDate': 'Data de Deleção',
    'reports.length.activeDays': 'Dias Ativos',
    'reports.attends.attendsHeader': 'ATENDIMENTOS',
    'reports.attends.hasAudio': 'Áudio',
    'reports.attends.selectTag': 'Selecione o Tipo de Atendimento',
    'reports.userTracking.header': 'ACOMPANHAMENTO MENSAL DE USUÁRIOS',
    'reports.userTracking.month': 'Mês',
    'reports.userTracking.addedUsers': 'Usuários Adicionados',
    'reports.userTracking.deletedUsers': 'Usuários Removidos',
    'reports.userTracking.totalMonth': 'Total de Usuários Mês',
    'reports.userTracking.added': 'Adicionados',
    'reports.userTracking.inactivated': 'Inativados',
    'reports.userTracking.reactivated': 'Reativados',
    'reports.userTracking.disabled': 'Desativados',
    'reports.userTotal.header': 'TOTAL DE USUÁRIOS POR MÊS',
    'reports.userTotal.month': 'Mês',
    'reports.userTotal.totalMonth': 'Total de Usuários Mensal',
    'reports.totalPlans.header': 'VALOR TOTAL DOS PLANOS',
    'reports.totalPlans.totalHeader': 'Total',
    'reports.totalPlans.plan': 'Plano',
    'reports.totalPlans.numberOfUsers': 'Número de Usuários',
    'reports.totalPlans.totalValue': 'Valor Total',
    'reports.totalPlans.chartHeader': 'VALOR TOTAL DOS PLANOS',
    'reports.planDetails.header': 'DETALHES DOS PLANOS',
    'reports.planDetails.value': 'Valor',
    'reports.planDetails.plan': 'Plano',
    'reports.companies.header': 'TOTAL DE USUÁRIOS POR EMPRESA',
    'reports.companies.name': 'Nome da empresa',
    'reports.companies.id': 'Identificação',
    'reports.companies.total': 'Total de usuários',
    'monitoring.header': 'MONITORAMENTO',
    'monitoring.selectWhoMessage': 'Escolha abaixo quem você quer que faça seu monitoramento',
    'monitoring.errorTest': 'Você está utilizando a versão de testes, esta opção está desabilitada.',
    'monitoring.anywhereName': 'Nome',
    'monitoring.solicitationDate': 'Data de solicitação',
    'monitoring.noCompanySelected': 'Nenhuma empresa selecionada',
    'monitoring.historic': 'HISTÓRICO',
    'monitoring.approvalDate': 'Data de aprovação',
    'monitoring.deletionDate': 'Data de deleção',
    'monitoring.modalMessage': 'Ao solicitar o monitoramento de {setMasterName}, assim que este for aceito, serão cobrados R$: 45,00 extras para cada cliente ativo neste Contactto.care. \nDeseja realizar esta ação? \nNOTA: SE SEU CONTACTTO.CARE NÃO UTILIZA PLANOS, IGNORE ESTA MENSAGEM',
    'monitoring.pending': 'Pendente',
    'monitoring.approved': 'Aprovado',
    'monitoring.deleted': 'Deletado',
    'monitoring.noApproval': 'Sem aprovação',
    'activate.header': 'ATIVAR CONTACTTO.CARE',
    'activate.registrationData': 'DADOS DE CADASTRO',
    'activate.zipCode': 'CEP',
    'activate.street': 'Logradouro',
    'activate.number': 'Número',
    'activate.district': 'Bairro',
    'activate.complement': 'Complemento',
    'activate.city': 'Cidade',
    'activate.state': 'Estado',
    'activate.country': 'País',
    'activate.actions': 'AÇÕES',
    'activate.sign': 'ASSINAR',
    'search.header': 'RESULTADO DE BUSCAS PARA',
    'search.alarms': 'Alarmes',
    'search.clients': 'Usuários',
    'search.contacts': 'Contatos',
    'search.transmitters': 'Transmissores',
    'search.attendants': 'Atendentes',
    'search.noResult': 'Nenhum resultado encontrado',
    'date.startDate': 'Data Inicial',
    'date.endDate': 'Data Final',
    'reminder.header': 'LEMBRETE PARA O ATENDIMENTO ',
    'reminder.reminderAttendant': 'Atendente',
    'reminder.reminderTime': 'Tempo solicitado',
    'reminder.reminderOpening': 'Data de início',
    'reminder.reminderTitle': 'Lembrete',
    'reminder.reminderReport': 'Relatório',
    'reminder.reminderContactList': 'Lista de Contatos',
    'reminder.timer': 'Tempo',
    'reminder.informAll': 'Informar todos os atendentes?',
    'reminder.warning': 'Por favor, digite um lembrete e escolha um tempo.',
    'reminder.reminderWarning': 'Por favor, digite seu relatório antes de concluír.',
    'reminder.btn': 'Gerar',
    'reminder.closeBtn': 'CONCLUIR',
    'reminder.rescheduleBtn': 'REPROGRAMAR',
    'attend.reminder.createReminder': 'Gerar Lembrete',
    'attend.reminder.message': 'Obrigado! \n Seu lembrete foi gerado! Após terminar o tempo, você será avisado. \n Você pode fechar este atendimento para realizar outra tarefa.',
    'attend.reminder.openAttendant': 'Solicitado por',
    'attend.reminder.closeAttendant': 'Fechado por',
    'attend.reminder.title': 'Lembrete',
    'attend.reminder.opening': 'Abertura',
    'attend.reminder.close': 'Fechamento',
    'attend.reminder.requestedTime': 'Tempo Solicitado',
    'attend.reminder.minutes': 'Minutos',
    'attend.reminder.report': 'Relatório',
    'attend.reminder.cardHeader': 'LEMBRETE NÚMERO {reminderNumber}',
    'attend.filter.header': 'FILTRAR ATENDIMENTOS',
    'attend.filter.button1': 'FILTRAR',
    'attend.filter.button2': 'ATENDIMENTOS',
    'attend.filter.isTest': 'Usuários de Teste',
    'attend.filter.notTest': 'Usuários Normais',
    'attend.filter.hasAudio': 'Tem áudio',
    'attend.filter.company': 'Empresa',
    'attend.filter.attendTag': 'Tipo de Atendimento',
    'attend.filter.filteredBy': 'Filtrados por',
    'attend.filter.id': 'Identificação',
    'attend.filter.noAudio': 'Sem Áudio',
    'history.header': 'VISUALIZAR HISTÓRICO DE',
    'history.selectContact': 'Selecione o Contato desejado',
    'history.user': 'Usuário',
    'history.contacts': 'Contatos',
    'history.transmitters': 'Transmissores',
    'history.type': 'Tipo',
    'history.date': 'Data',
    'history.modifiedBy': 'Modificado Por',
    'history.transmitter': 'Transmissor',
    'history.expand': '+ Expandir',
    'history.hide': '- Ocultar',
    'history.before': 'Antes',
    'history.after': 'Depois',
    'history.actionType.changed': 'Alteração',
    'history.actionType.deleted': 'Deleção',
    'history.actionType.created': 'Criação',
    'history.contact': 'Contato',
    'history.contact.phone': 'Telefone',
    'history.contact.action': 'Ação',
    'history.empty': 'Vazio',
    'history.changedField': 'Campo Alterado',
    'error.component.title': 'Oops! Parece que ocorreu um erro ao tentar salvar',
    'error.component.errorcodetitle': 'Código do erro',
    'error.component.infotitle': 'Informações',
    'chat.error.unavailable': 'Opção indisponível no momento.',
    'chat.error.toActivate': 'Para ativar o seu chat, faça o logout da plataforma e acesse novamente.',
    'chat.error.loggingOut': 'Para fazer logout, utilize o menu superior a direita - onde está o seu nome - e clique em "Sair".',
    'chat.error.supportEmail': 'Se o chat permanecer indisponível, envie um email para suporte@tecnosenior.zendesk.com relatando a situação.',
    'return.makeCallLabel': 'Retorno ao usuário',
    'return.makeCallButton': 'REALIZAR LIGAÇÃO',
    'return.firstHangupLabel': 'Aguarde 3-4 toques e desligue esta chamada',
    'return.firstHangupButton': 'DESLIGAR',
    'return.waitSeconds': 'Aguardando {duration} segundos...',
    'return.waitToneLabel': 'Aguarde o fim do tom',
    'return.sendPasswordButton': 'ENVIAR SENHA',
    'return.ongoingCallLabel': 'Chamada em andamento',
    'return.reSendPasswordButton': 'ENVIAR SENHA NOVAMENTE',
    'return.hangupCall': 'DESLIGAR',
    'ata.header.port': 'Porta',
    'ata.status.unknown': 'Desconhecido',
    'ata.status.unreachable': 'Inacessível',
    'extension.headerTitle': 'CHAMADAS DE EXTENSÃO',
    'extension.headerTitleReminder': 'CHAMADAS DE EXTENSÃO - LEMBRETE PARA O ATENDIMENTO',
    'extension.clientInfo': 'INFORMAÇÕES DO USUÁRIO',
    'extension.client': 'Usuário',
    'extension.phone': 'Telefone',
    'extension.select': 'SELECIONAR',
    'extension.selectClientAccountPhone': 'NRO DO EQUIPAMENTO',
    'extension.selectClientContactPhone': 'NRO DE CONTATO',
    'extension.own': 'O(a) próprio(a)',
    'extension.makeCallLabel': 'LIGANDO PARA',
    'extension.contact': 'Contato de Emergência',
    'extension.noContactPhone': 'Este usuário não possui telefone de contato',
    'extension.makeCall': 'REALIZAR LIGAÇÃO',
    'extension.custom': 'Número extra',
    'extension.back': 'VOLTAR',
    'extension.softphoneTitle': 'TECLADO DE TELEFONE',
    'extension.softphoneOpen': 'ABRIR',
    'extension.softphoneClose': 'FECHAR',
    'extension.softphoneInput': 'Digite o número desejado',
    'extension.softphoneConfirm': 'Confirmar'
  },
};
