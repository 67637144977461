import * as types from '../actions/actionTypes';
import initialState from './initialState';


/*
Inputs:
  state = Estado atual do objeto, caso não seja passado, é utilizado o estado padrão do arquivo
  (initialState.globalConfigurations) que é um objeto
  action = Ação aplicada sobre o objeto
Types:
  RELOAD_GLOBAL_CONFIGURATIONS = Realiza a ação de recarregar as configurações globais

globalConfigurationReducer = Recarrega as configurações globais do contactto
(Utiliza no atendimento)
 */
export default function globalConfigurationReducer(
  state = initialState.globalConfigurations,
  action,
) {
  if (action.type === types.RELOAD_GLOBAL_CONFIGURATIONS) {
    return { global: action.global };
  }
  return state;
}
