import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import DashboardPage from './Dashboard/DashboardPage';
import ClientPage from './Clients/ClientPage';
import ClientDetail from './Clients/ClientDetail';
import ClientForm from './Clients/Form/ClientForm';
import ClientTrackeriHelp from './Clients/Trackers/TrackeriHelp';
import ClientTrackerIGO from './Clients/Trackers/TrackerIGO';
import ClientTrackerClimax from './Clients/Trackers/TrackerClimax';
import ClientTrackerEV07B from './Clients/Trackers/TrackerEv07b';
import HistoryComponent from './Clients/History/HistoryComponent';
import ContactPage from './Contacts/ContactPage';
import ContactDetail from './Contacts/ContactDetail';
import ContactForm from './Contacts/ContactForm';
import TransmitterPage from './Transmitters/TransmitterPage';
import TransmitterDetail from './Transmitters/TransmitterDetail';
import TransmitterForm from './Transmitters/TransmitterForm';
import AttendsPage from './Attends/AttendsPage';
import AttendClosed from './Attends/AttendClosed';
import AttendOpen from './Attends/AttendOpen';
import AttendantManagement from './Manage/Attendants/AttendantManagement';
import AlarmManagement from './Manage/Alarms/AlarmManagement';
import CompaniesManagement from './Manage/Companies/CompanyPage';
import OnlineAttendantsPage from './Manage/OnlineAttendants/OnlinePage';
import IoTDeviceList from './Manage/internetOfThings/IoTDeviceList';
import AtaDeviceList from './Manage/AtaList/AtaDeviceList';
import UserForm from './User/UserForm';
import UserCompanyForm from './User/UserCompanyForm';
import AlarmForm from './Manage/Alarms/AlarmForm';
import CompanyForm from './Manage/Companies/CompanyForm';
import Configuration from './Configuration/Configuration';
import ReportPage from './Reports/ReportPage';
import ReportAlarm from './Reports/Alarm/ReportAlarm';
import ReportAttend from './Reports/Attend/ReportAttendPeriod';
import ReportAttendsByTag from './Reports/Attend/ReportAttendsByTag';
import ReportAttendant from './Reports/Attendant/ReportAttendant';
import ReportLengthStay from './Reports/Client/ReportLengthOfStay';
import ReportClienthMonthlyFollowup from './Reports/Client/ReportClientMonthlyFollowup';
import ReportClientTotalMonthly from './Reports/Client/ReportClientTotalMonthly';
import ReportCompanyDetails from './Reports/Companies/ReportCompanyDetails';
import ReportClientByCompany from './Reports/Companies/ReportClientByCompany';
import ClientLocationPage from './Manage/ClientLocation/ClientLocationPage';
import Activate from './Activation/Activate';
import QuickSightPage from './Manage/Quicksight/QuicksightPage';

import { redirect } from '../../index';
import '../../css/middle.scss';
import '../../css/print.scss';

const Middle = (props) => {
  const passEmergencyToChildren = () => {
    const {
      app,
      attendSip,
      channelId,
      clearList,
      extendCall,
      hangupSip,
      muteAudio,
      mutePhone,
      sendDTMF,
      testEchoSip,
      startCall,
      sendPERSPassword,
      setUserBusy,
      takeEmergency,
      toggleAudio,
      user,
      status,
      isPaid,
      isOwner,
      isAdmin,
      sipStackType,
    } = props;
    return React.Children.map(props.app, (child) => {
      if (child.type.name === 'DashboardPage') {
        return React.cloneElement(child, {
          clearList,
          toggleAudio,
          channelId,
          user,
          setUserBusy,
          isAdmin,
          sipStackType,
        });
      }
      return React.cloneElement(child, {
        user,
        attendSip,
        hangupSip,
        extendCall,
        clearList,
        channelId,
        setUserBusy,
        takeEmergency,
        muteAudio,
        mutePhone,
        sendDTMF,
        testEchoSip,
        startCall,
        sendPERSPassword,
        status,
        isPaid,
        isOwner,
        isAdmin,
        sipStackType,
      });
    });
  };
  return (
    <div className={`middle print ${props.showChat && 'middle-with-chat'}`} onClick={props.closeSidebar}>
      {passEmergencyToChildren()}
      <Switch>
        <Route exact path="/app" render={compProps => <DashboardPage {...compProps} {...props} />} />
        {/* Clients */}
        <Route path="/app/clients/form/:method/:clientId?" render={compProps => <ClientForm {...compProps} {...props} />} />
        <Route path="/app/clients/detail/:id?" render={compProps => <ClientDetail {...compProps} {...props} />} />
        <Route path="/app/clients/igo/:clientId" render={compProps => <ClientTrackerIGO {...compProps} {...props} />} />
        <Route path="/app/clients/ihelp/:clientId" render={compProps => <ClientTrackeriHelp {...compProps} {...props} />} />
        <Route path="/app/clients/climax - mobile/:clientId" render={compProps => <ClientTrackerClimax {...compProps} {...props} />} />
        <Route path="/app/clients/ev07b/:clientId" render={compProps => <ClientTrackerEV07B {...compProps} {...props} />} />
        <Route path="/app/clients/ev07b - cb/:clientId" render={compProps => <ClientTrackerEV07B {...compProps} {...props} />} />
        <Route path="/app/clients/logs/:clientId?" render={compProps => <HistoryComponent {...compProps} {...props} />} />
        <Route path="/app/clients" render={compProps => <ClientPage {...compProps} {...props}/>} />
        {/* Contacts */}
        <Route path="/app/contacts/form/:method/:clientId/:contactId?" render={compProps => <ContactForm {...compProps} {...props} />} />
        <Route path="/app/contacts/list/:clientId" render={compProps => <ContactPage {...compProps} {...props} /> } />
        <Route path="/app/contacts/detail/:clientId/:contactId" render={compProps => <ContactDetail {...compProps} {...props} />} />
        {/* Transmitters */}
        <Route path="/app/transmitters/form/:method/:clientId/:transmitterId?" render={compProps => <TransmitterForm {...compProps} {...props} /> } />
        <Route path="/app/transmitters/list/:clientId" render={compProps => <TransmitterPage {...compProps} {...props} /> } />
        <Route path="/app/transmitters/detail/:clientId/:transmitterId" render={compProps => <TransmitterDetail {...compProps} {...props} />} />
        {/* AttendsPage */}
        <Route path="/app/attends/closed/:id" render={compProps => <AttendClosed {...compProps} {...props}/>} />
        <Route path="/app/attends/call/:id" render={compProps => <AttendOpen {...compProps} {...props}/>} />
        <Route path="/app/attends" render={compProps => <AttendsPage {...compProps} {...props} />} />
        {/* Reports */}
        <Route path="/app/reports/list/clients-company-details/:companyId/:filter" render={compProps => <ReportCompanyDetails {...compProps} {...props} />} />
        <Route path="/app/reports/list/clients-company/:filter" render={compProps => <ReportClientByCompany {...compProps} {...props} />} />
        <Route path="/app/reports/list/attends/:filter" render={compProps => <ReportAttend {...compProps} {...props} />} />
        <Route path="/app/reports/list/tags/:filter" render={compProps => <ReportAttendsByTag {...compProps} {...props} />} />
        <Route path="/app/reports/list/alarms/:filter" render={compProps => <ReportAlarm {...compProps} {...props} />} />
        <Route path="/app/reports/list/clients-length/" render={compProps => <ReportLengthStay {...compProps} {...props} />} />
        <Route path="/app/reports/list/clients/total/month/:filter" render={compProps => <ReportClientTotalMonthly {...compProps} {...props} />} />
        <Route path="/app/reports/list/clients-month/:filter" render={compProps => <ReportClienthMonthlyFollowup {...compProps} {...props} />} />
        <Route path="/app/reports/list/attendants/:filter" render={compProps => <ReportAttendant {...compProps} {...props} />} />
        <Route path="/app/reports" render={compProps => <ReportPage {...compProps} {...props} />} />
        {/* Management */}
        <Route exact path="/app/activate" render={compProps => <Activate {...compProps} {...props} /> } />
        <Route path="/app/configuration" render={compProps => <Configuration {...compProps} {...props} /> } />
        <Route path="/app/manage/alarms" render={compProps => <AlarmManagement {...compProps} {...props} />} />
        <Route path="/app/alarms/form/:method/:alarmId?" render={compProps => <AlarmForm {...compProps} {...props} />} />
        <Route path="/app/manage/companies" render={compProps => <CompaniesManagement {...compProps} {...props} />} />
        <Route path="/app/companies/form/:method/:companyId?" render={compProps => <CompanyForm {...compProps} {...props} />} />
        <Route path="/app/manage/trackerlist" render={compProps => <IoTDeviceList {...compProps} {...props} />} />
        <Route path="/app/manage/ataList" render={compProps => <AtaDeviceList {...compProps} {...props} />} />
        <Route path="/app/manage/attend" render={compProps => <AttendantManagement {...compProps} {...props} />} />
        <Route path="/app/user/form/:method/:userId?" render={compProps => <UserForm {...compProps} {...props} /> } />
        <Route path="/app/companies/:companyId/user/form/:method/:userId?" render={compProps => <UserCompanyForm {...compProps} {...props} />} />
        <Route path="/app/manage/quicksight" render={compProps => <QuickSightPage {...compProps} {...props} />} />
        <Route path="/app/manage/online-attendants" render={compProps => <OnlineAttendantsPage {...compProps} {...props} />} />
        <Route path="/app/manage/client-location" render={compProps => <ClientLocationPage {...compProps} {...props} />} />
        <Route render={() => <Redirect to={redirect()} />} />
      </Switch>
    </div>
  );

}

Middle.defaultProps = {
  sipStackType: '',
};

Middle.propTypes = {
  app: PropTypes.node.isRequired,
  attendSip: PropTypes.func.isRequired,
  channelId: PropTypes.string.isRequired,
  clearList: PropTypes.func.isRequired,
  extendCall: PropTypes.func.isRequired,
  hangupSip: PropTypes.func.isRequired,
  muteAudio: PropTypes.func.isRequired,
  mutePhone: PropTypes.func.isRequired,
  sendDTMF: PropTypes.func.isRequired,
  testEchoSip: PropTypes.func.isRequired,
  startCall: PropTypes.func.isRequired,
  sendPERSPassword: PropTypes.func.isRequired,
  setUserBusy: PropTypes.func.isRequired,
  takeEmergency: PropTypes.func.isRequired,
  toggleAudio: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  isPaid: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool.isRequired,
  sipStackType: PropTypes.string,
};

export default Middle;
