import React from 'react';
import PropTypes from 'prop-types';

const MobileIcon = ({ height, className, width, fill, transform }) =>
  <svg
    className={className}
    width={width}
    height={height}
    fill={fill}
    transform={transform}
    viewBox="0 0 140 140"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="lXSZgC_1_">
      <path
        d="M83.6,63.8c-1,4.1-2,7.7-2.9,11.3c-0.4,1.6-0.7,3.3-1.2,4.8c-0.4,1.3-0.1,2.1,0.8,3.1c3.3,3.4,6.4,6.9,9.7,10.4 c2.8,3,4.5,6.4,5.4,10.4c1.5,7.1,3.5,14,5.2,21.1c1.1,4.4-1.6,8.9-5.8,9.9c-4.7,1.2-9.1-1.4-10.2-6c-1.6-6.5-3.2-12.9-4.9-19.4 c-0.9-3.5-3.1-6.1-5.5-8.7c-4.4-4.7-8.6-9.5-13-14.1c-5.1-5.4-6.3-11.5-4.2-18.5c1.3-4.3,2.2-8.8,3.5-13.5 c-4.7,2.1-10,2.7-12,8.3c-0.5,1.5-1.4,2.9-2.5,4.1c-2.7,2.9-7,3.3-10.1,1.3c-3.4-2.2-4.9-6.2-3.4-10c3-7.6,7.6-13.7,15.5-16.6 c5.6-2.1,11.2-4.3,16.9-5.7C79,32.6,93.4,41,98,54.9c0.2,0.5,0.4,1.1,0.5,1.6c0.7,4.6,3.2,7.4,7.7,9.1c3.3,1.2,5,4.1,5,7.8 c0,5.6-5.8,9.7-11,7.6c-3-1.2-5.8-2.8-8.6-4.4c-3.4-2-5.4-5.1-6.7-8.7C84.5,66.6,84.1,65.4,83.6,63.8z"
      />
      <path
        d="M52.4,89.2c3.9,4.2,7.6,8.3,11.4,12.4c0.8,0.9,1.1,1.6,0.5,2.7c-0.9,2-1.7,4-2.4,6.1c-1.1,3.1-2.9,5.6-5.2,7.8 c-4.8,4.7-9.5,9.5-14.3,14.3c-4.6,4.6-11.8,2.9-13.9-3.2c-1.1-3.2-0.1-6,2.2-8.4c3.6-3.7,7.1-7.4,10.9-10.9 c3-2.9,5.3-6.1,6.7-10.1C49.4,96.3,51,92.8,52.4,89.2z"
      />
      <path
        d="M68.2,18.7c0-6.8,5.6-12.5,12.4-12.4c7.6,0.1,12.5,6,12.5,12.5c0,6.8-5.6,12.4-12.4,12.4C73.9,31.3,68.2,25.6,68.2,18.7z"
      />
    </g>
  </svg>;

MobileIcon.defaultProps = {
  height: '24',
  className: '',
  width: '24',
  fill: '#00b8cb',
  transform: 'translate(0,0)',
};

MobileIcon.propTypes = {
  height: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
  transform: PropTypes.string,
};

export default MobileIcon;
