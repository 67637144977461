import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { FormattedMessage } from 'react-intl';
import * as general from "../../../BLL/general"
import messages from "../../../BLL/locale/messages";
import styles from "./reminderModal.module.scss";

const ContactComponent = ({ contact, handleSelectButton }) => {
  const { lang } = useSelector((state) => ({
    lang: state.locale.lang
  }));
  const [data, setData] = useState(undefined);

  useEffect(() => {
    if (contact) {
      const primaryPhone = contact.phone_list.find(
        (phone) => phone.phone_type === "Primary"
      );

      setData({
        name: `${contact.first_name} ${contact.last_name}`,
        phone: primaryPhone.phone
      })
    }
  }, [contact]);

  const handleSelect = (ev) => {
    console.log(ev.target.value)
    setData((prevState) => ({
      ...prevState,
      phone: ev.target.value
    }))
  };

  const handleSelectClick = () => {
    handleSelectButton('contact', data);
  };

  return (
    <div className={styles['extension-contact-info']}>
      <div className={styles['extension-contact-container']}>
        <div>
          <span className={styles['extension.label']}><FormattedMessage id="contact.page.name" defaultMessage="Nome" />:</span> <span>{contact.first_name} {contact.last_name}</span>
        </div>
        <div>
          <span className={styles['extension.label']}>
            <FormattedMessage id="extension.phone" defaultMessage="Telefone" />:
          </span>
          {data &&
            <select value={data.phone} onChange={(e) => handleSelect(e)} className={styles['extension-select']}>
              {contact.phone_list.map((elm) => (
                <option id={elm.id} value={elm.phone}>
                  {
                    elm.phone.length > 10 ? general.setMask(elm.phone, '(00) 0 0000-0000')  : general.setMask(elm.phone, '(00) 0000-0000')
                  } - {messages[lang][`contact.form.${elm.phone_type.toLowerCase()}`]}
                </option>
              ))}
            </select>
          }
        </div>
      </div>
      <button onClick={() => handleSelectClick()} className={styles['extension-select-btn']}>
        <FormattedMessage id="extension.select" defaultMessage="SELECIONAR" />
      </button>
    </div>
  );
};

ContactComponent.propTypes = {
  handleSelectButton: PropTypes.func.isRequired,
  contact: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    phone_list: PropTypes.arrayOf(PropTypes.shape({
      phone: PropTypes.string,
      phone_type: PropTypes.string
    }))
  }).isRequired,
}

export default ContactComponent;
