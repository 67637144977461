import * as types from './actionTypes';
import * as errorsActions from './errorsActions';
import * as globals from '../globals/globals';
import { host, getDomain, getToken } from '../BLL/environment';

export function loadEquipmentSuccess(equipment) {
  return { type: types.LOAD_EQUIPMENT_SUCCESS, equipment };
}

export function loadEquipment() {
  const domain = getDomain();
  const token = getToken();

  return (dispatch, getState, { axios }) => Promise.resolve(
    axios.get(`https://${domain}${host()}/api/equipment/`, {
      headers: { Authorization: `Token ${token}` },
    }).then((equipment) => {
      const equipmentData = equipment.data.length > 0 ? equipment.data : -1;
      dispatch(loadEquipmentSuccess(equipment.data));
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(errorsActions.logout());
        }
      }
    }),
  );
}
