import React from 'react';
import PropTypes from 'prop-types';

const AlarmLostMed1Ico = ({ height, svgClass, width, fill, transform }) => (
  <svg className={svgClass} width={width} height={height} fill={fill} transform={transform} viewBox="0 0 140 140" xmlns="http://www.w3.org/2000/svg">
    <g id="WTx1kz_1_">
      <path d="m2.6 82.6v-26.6c0-13.4 10.3-24.7 23.9-26.2 13.1-1.4 25.6 7.8 28.3 20.8 0.4 1.8 0.6 3.7 0.6 5.6v52.9c0 12.3-8.7 23-20.7 25.6-15.6 3.4-30.7-7.5-32.1-23.5-0.4-4.3-0.1-8.7-0.1-13 0.1-5.1 0.1-10.4 0.1-15.6zm37.7-0.1c0-0.4 0.1-0.6 0.1-0.8 0-8.6 0.1-17.3-0.1-25.9 0-1.8-0.7-3.8-1.6-5.5-2.5-4.4-7.9-6.4-12.7-5-4.9 1.4-8.1 5.7-8.1 10.9v25 1.3h22.4z"  />
      <path d="m101.3 60.2c17.9 0.3 32.6 12.2 36.4 29.8 2.3 10.6-0.1 20.5-6.3 29.5-0.9 1.3-2.1 1.4-3.4 0.1-4.2-4.2-8.4-8.4-12.6-12.5l-36.4-36.4c-1.4-1.5-1.3-2.5 0.4-3.7 6-4.4 14.2-6.9 21.9-6.8z"  />
      <path d="m63.1 98.1c0.1-7.9 2.4-15.1 6.8-21.6 1.2-1.7 2.3-1.9 3.8-0.3 6.5 6.5 13 12.9 19.5 19.4 9.6 9.6 19.2 19.3 28.9 28.9 2.2 2.2 2.1 2.9-0.5 4.7-22.7 15.1-53.1 2-57.9-24.8-0.4-2.1-0.4-4.2-0.6-6.3z"  />
    </g>
    <g aria-label="1"><path d="m131.77 2.9027v29.121h-8.0469v-19.082q-1.9531 1.4844-3.7891 2.4023-1.8164 0.91797-4.5703 1.7578v-6.5234q4.0625-1.3086 6.3086-3.1445 2.2461-1.8359 3.5156-4.5312z"  /></g>
  </svg>
);

AlarmLostMed1Ico.defaultProps = {
  height: '75',
  svgClass: '',
  width: '75',
  fill: '#008D53',
  transform: 'translate(0,0)',
};

AlarmLostMed1Ico.propTypes = {
  height: PropTypes.string,
  svgClass: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
  transform: PropTypes.string,
};

export default AlarmLostMed1Ico;
