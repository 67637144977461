import React from 'react';
import PropTypes from 'prop-types';

const AlarmEnergyIco = ({ height, svgClass, width, fill, transform }) => (
  <svg className={svgClass} width={width} height={height} fill={fill} transform={transform} viewBox="0 0 140 140" xmlns="http://www.w3.org/2000/svg">
<g id="XM1L4e_1_">
	<g>
		<g>
			<path d="M31.2,69c-1.4,0-2.6,0-3.7,0c-2.6-0.1-4.2-1.7-4.2-4.3c0-2.4,0-4.9,0-7.3c0-2.6,1.6-4.2,4.2-4.3c1.2,0,2.3,0,3.5,0
				c27.6,0,55.2,0,82.8,0c2,0,4.2,0.4,4.5,2.3c0.5,3.8,0.4,7.6,0,11.4c-0.1,1.6-2,2.2-3.7,2.3c-1.2,0-2.4,0-3.8,0c0,2.6,0,5.1,0,7.6
				c0,18.6-12,34.3-30.1,38.9c-1.4,0.4-1.8,0.9-1.8,2.3c0.1,7,0,13.9,0,20.9c0,0.6,0,1.2,0,1.9c-5.3,0-10.5,0-16,0
				c0-0.5,0-1.1,0-1.7c0-7.1,0-14.2,0-21.4c0-1.1-0.3-1.6-1.4-1.9c-15.8-4.4-25.7-14.5-29.5-30.5c-0.6-2.4-0.7-4.9-0.9-7.4
				C31.1,74.9,31.2,72,31.2,69z"/>
			<path d="M55,49c-5.3,0-10.4,0-15.9,0c0-0.6,0-1.1,0-1.7c0-8.7,0-17.3,0-26c0-4.4,3.6-8,7.9-8.1c4.4-0.1,8.1,3.6,8.1,8.1
				c0,9,0,18.1,0,27.1C55.1,48.6,55.1,48.7,55,49z"/>
			<path d="M102.8,49c-5.4,0-10.6,0-15.9,0c0-0.6-0.1-1.1-0.1-1.6c0-8.7,0-17.4,0-26.2c0-4.4,3.7-8,8-8s8,3.7,8,8c0,8.7,0,17.3,0,26
				C102.8,47.8,102.8,48.3,102.8,49z"/>
		</g>
	</g>
</g>
  </svg>
);

AlarmEnergyIco.defaultProps = {
  height: '75',
  svgClass: '',
  width: '75',
  fill: '#008D53',
  transform: 'translate(0,0)',
};

AlarmEnergyIco.propTypes = {
  height: PropTypes.string,
  svgClass: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
  transform: PropTypes.string,
};

export default AlarmEnergyIco;
