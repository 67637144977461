import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import messageTags from '../../../../BLL/locale/messagesTags';

import style from './attendInformation.module.scss';
import '../../../../css/attendComponent.scss';
import { useSelector } from 'react-redux';

const AttendInformation = ({ open, attend, close, tagId, lang, toggleEdit, isEditing, setTag, updateTag, isAdmin, aditionalAudioList, clientMessages }) => {
  const { tagList } = useSelector(state => ({
    tagList: state.attendTags
  }));

  return (
    <div className={style['information-wrapper']}>
      <div className="col-sm-12 col-md-8 col-lg-8">
        <span className="component-header" >
          <FormattedMessage id="attend.closed.infoLabel" defaultMessage="DETALHES DO ATENDIMENTO" />
        </span>
        <div className="component-row">
          <span className="component-label"><FormattedMessage id="attend.closed.infoOpening" defaultMessage="Hora de abertura" /></span>
          <span className="component-text">{moment(open).format('HH:mm:ss - DD/MM/YYYY')}</span>
        </div>
        <div className="component-row">
          <span className="component-label"><FormattedMessage id="attend.closed.infoAttend" defaultMessage="Hora de atendimento" /></span>
          <span className="component-text">{moment(attend).format('HH:mm:ss - DD/MM/YYYY')}</span>
        </div>
        <div className="component-row">
          <span className="component-label"><FormattedMessage id="attend.closed.infoClosure" defaultMessage="Hora de fechamento" /></span>
          <span className="component-text">{moment(close).format('HH:mm:ss - DD/MM/YYYY')}</span>
        </div>
        {isEditing
        ?
          <div className="component-row">
            <span className="component-label"><FormattedMessage id="attend.detail.attendTag" defaultMessage="TIPO DE ATENDIMENTO" /></span>
            <div className="component-text">
              <select
                className="custom-select"
                name="tag-select"
                value={tagId}
                onChange={setTag}
              >
                {tagList && tagList.map(tag =>
                  <option key={tag.id} value={tag.id}>
                    {messageTags[lang][tag.id]}
                  </option>)
                }
              </select>
            </div>
            <button className={style['info-btn-save']}  onClick={() => updateTag()}>
              <i className="far fa-save" />
            </button>
            <button className={style['info-btn-cancel']} onClick={() => toggleEdit()}>
              <i className="far fa-window-close" />
            </button>
          </div>
        :
          <div className="component-row">
            <span className="component-label"><FormattedMessage id="attend.detail.attendTag" defaultMessage="TIPO DE ATENDIMENTO" /></span>
            <span className="component-text">{messageTags[lang][tagId]}</span>
            {isAdmin &&
              <button className={style['info-btn-edit']} onClick={() => toggleEdit()}>
                <i className="far fa-edit" />
              </button>
            }
          </div>
        }
      </div>
      {clientMessages.length > 0 &&
        <div className="col-sm-12 col-md-4 col-lg-4">
          <span className="component-header" >
            <FormattedMessage id="attend.closed.clientMessageLabel" defaultMessage="HISTÓRICO DE MENSAGENS" />
          </span>
          {clientMessages.map((elm) => (
            <div className={style['chat-row']}>
              <span className={style.label}>
                {elm.from_client
                  ? <FormattedMessage id="attend.closed.clientMessage" defaultMessage="MENSAGEM" />
                  : <FormattedMessage id="attend.closed.clientMessageReply" defaultMessage="RESPOSTA" />
                }
              </span>
              <span className={style.value}>
                {!elm.message
                  ? <FormattedMessage id="attend.open.emptyMessage" defaultMessage="MENSAGEM VAZIA" />
                  : elm.message
                }
              </span>
            </div>
          ))}
        </div>
      }
    </div>
  )
};

AttendInformation.defaultProps = {
  isEditing: false,
};

AttendInformation.propTypes = {
  close: PropTypes.string.isRequired,
  open: PropTypes.string.isRequired,
  attend: PropTypes.string.isRequired,
  tagId: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  isEditing: PropTypes.bool,
};

export default AttendInformation;
