import React from 'react';
import PropTypes from 'prop-types';

const AlarmAutoTestFailIco = ({ height, svgClass, width, fill, transform }) => (
  <svg className={svgClass} width={width} height={height} fill={fill} transform={transform} viewBox="0 0 140 140" xmlns="http://www.w3.org/2000/svg">
<g id="_x33_EuA83.tif">
<g>
<g>
<path d="M68.9,12.7c0-3.2,0-6.3,0-9.4c0-1.3,0.8-2.2,1.9-2.2c1.3,0,2,1.1,2,2.2c-0.1,3.1,0,6.2,0,9.4c0.3,0,0.6,0,0.9,0
c4.2,0,8.4,0,12.6,0c6.4,0,11.6,5.2,11.6,11.6c0,1.3,0,2.5,0,3.8c1.3,0,2.4,0,3.6,0c2.5,0,4.1,1.6,4.1,4.1c0,5.1,0,10.2,0,15.2
c0,2.2-1.7,3.9-3.8,3.9c-1.3,0-2.5,0-3.9,0c0,1.3,0,2.6,0,3.9c0,3.5-2.3,6.6-5.8,7.4C91.5,62.9,90.7,63,90,63
c-12.8,0-25.5,0-38.3,0c-4.6,0-7.9-3.4-7.9-8c0-1.2,0-2.3,0-3.6c-1.3,0-2.4,0-3.6,0c-2.5,0-4.1-1.6-4.1-4.1c0-5.1,0-10.2,0-15.2
c0-2.2,1.7-3.9,3.8-3.9c1.3,0,2.5,0,3.9,0c0-1.3,0-2.5,0-3.7c0-3.1,1-5.8,3.1-8c2.3-2.4,5.1-3.7,8.4-3.7c4.2,0,8.5,0,12.7,0
C68.3,12.7,68.5,12.7,68.9,12.7z M70.8,59.1c1.3,0,2.6,0,3.9,0c5,0,10,0,15.1,0c2.6,0,4.2-1.6,4.2-4.2c0-10.1,0-20.1,0-30.2
c0-0.7-0.1-1.5-0.3-2.2c-0.9-3.6-3.9-5.8-7.8-5.8c-10.1,0-20.2,0-30.3,0c-0.7,0-1.5,0.1-2.2,0.2c-3.5,0.9-5.9,3.9-5.9,7.5
c0,10.1,0,20.2,0,30.3c0,2.9,1.5,4.4,4.5,4.4C58.4,59.1,64.6,59.1,70.8,59.1z M43.7,47.4c0-5.2,0-10.3,0-15.3c-1.3,0-2.5,0-3.7,0
c0,5.1,0,10.2,0,15.3C41.2,47.4,42.4,47.4,43.7,47.4z M98,32c0,5.2,0,10.3,0,15.4c1.3,0,2.5,0,3.7,0c0-5.1,0-10.2,0-15.3
c-0.2,0-0.3,0-0.4,0C100.2,32,99.2,32,98,32z"/>
<path d="M63.1,32c0,2.2-1.7,3.9-3.8,3.9c-2.2,0-3.9-1.7-3.9-3.8c0-2.2,1.7-3.9,3.8-3.9C61.4,28.1,63.1,29.9,63.1,32z"/>
<path d="M78.6,32c0-2.1,1.8-3.9,3.9-3.8c2.1,0,3.8,1.7,3.8,3.9c0,2.1-1.8,3.9-3.9,3.8C80.3,35.9,78.6,34.2,78.6,32z"/>
<path d="M63.1,47.5c0,1.3,0,2.5,0,3.8c-2.6,0-5.1,0-7.7,0c0-1.3,0-2.5,0-3.8C58,47.5,60.5,47.5,63.1,47.5z"/>
<path d="M74.7,47.5c0,1.3,0,2.5,0,3.8c-2.6,0-5.1,0-7.7,0c0-1.3,0-2.5,0-3.8C69.6,47.5,72.1,47.5,74.7,47.5z"/>
<path d="M78.6,47.5c2.6,0,5.1,0,7.7,0c0,1.3,0,2.5,0,3.8c-2.6,0-5.1,0-7.7,0C78.6,50.1,78.6,48.8,78.6,47.5z"/>
</g>
</g>
</g>
<path d="M107.9,66c-17.3,0-34.7,0-52,0c-7.5,0-14.9,0-22.4,0c-6.4,0-11.4,4.2-12.5,10.4c-0.1,0.7-0.1,1.4-0.1,2.1
c0,16.5,0,33,0,49.5c0,7.5,5.4,12.9,12.9,12.9c12.3,0,24.7,0,37,0c12.4,0,24.8,0,37.2,0c6.5,0,11.5-4.2,12.6-10.5
c0.1-0.7,0.1-1.4,0.1-2.1c0-16.5,0-33,0-49.5C120.8,71.4,115.4,66,107.9,66z M83.3,125.3c-4.5,0-9-0.1-13.4,0
c-3.5,0.1-5.5-0.2-5.3-5.3c0.2-3,1.1-4.1,4.1-4.1c4.4,0,8.8,0,13.3,0c0.4,0,0.8,0,1.3,0.1C83.3,119.1,83.3,122.1,83.3,125.3z
 M87.5,102.8c1.6,1.6,1.6,4.1,0,5.7c-0.8,0.8-1.8,1.2-2.8,1.2s-2-0.4-2.8-1.2l-11-11l-11,11c-0.8,0.8-1.8,1.2-2.8,1.2
c-1,0-2-0.4-2.8-1.2c-1.6-1.6-1.6-4.1,0-5.7l11-11l-11-11c-1.6-1.6-1.6-4.1,0-5.7c1.6-1.6,4.1-1.6,5.7,0l11,11l11-11
c1.6-1.6,4.1-1.6,5.7,0c1.6,1.6,1.6,4.1,0,5.7l-11,11L87.5,102.8z M108.3,121.4c-0.1,2.8-1.2,3.9-4,3.9c-4.5,0-8.9,0-13.4,0
c-0.4,0-0.8,0-1.3,0c0-3.2,0-6.2,0-9.3c4.5,0,8.9,0.1,13.2,0C106.7,115.9,108.5,116.2,108.3,121.4z"/>
  </svg>
);

AlarmAutoTestFailIco.defaultProps = {
  height: '75',
  svgClass: '',
  width: '75',
  fill: '#F69B38',
  transform: 'translate(0,0)',
};

AlarmAutoTestFailIco.propTypes = {
  height: PropTypes.string,
  svgClass: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
  transform: PropTypes.string,
};

export default AlarmAutoTestFailIco;
