import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import SelectInput from '../../Common/SelectInput2';
import TextInput from '../../Common/TextInputV2';
import Delete from '../../../icons/Delete';
import { phonePT } from '../../../BLL/general';
import countryCodes from '../../../BLL/locale/countryCodes';
import formStyle from '../../../css/form.module.scss';
import style from './contactForm.module.scss';
import '../../../css/formPage.scss';

const ContactPhone = ({ index, onChange, phone, removePhone, country, validator }) => {
  const selectedCountryCode = !phone.country_code ? countryCodes.find(elm => elm.code === country)?.dial_code : phone.country_code;
  const handlePhone = (ev, isValid) => {
    onChange(ev, isValid, index);
  };
  const telephoneSelect = ['Home', 'Primary', 'Work', 'Cellphone'];
  const btnStyle = { border: 0, padding: 0, backgroundColor: 'transparent' };
  let masks = '';
  switch (country) {
    case 'Brasil':
    case 'BR': {
      masks = ['(00) 0000-0000', '(00) 0 0000-0000'];
      break;
    }
    case 'GB': {
      masks = '(00) 0000 000000';
      break;
    }
    case 'MU': {
      masks = '00000000';
      break;
    }
    default:
      masks = '';
      break;
  }

  return (
    <div className="form-row">
      <div className="form-group col-sm-12 col-md-3">
        <label className="text-muted" htmlFor={`phone_type${index}`} data-testid={`type-${index}`}>
          <FormattedMessage id="contact.form.countryCode" defaultMessage="Código País" />: <span className="form-required">*</span>
        </label>
        <select
          id={`country-code-${index}`}
          className="form-control col-11"
          name="country_code"
          onChange={handlePhone}
          value={selectedCountryCode ? selectedCountryCode : '+55'}
        >
          {countryCodes.map(elm =>
            <option key={`${elm.code}-${index}`} value={elm.dial_code}>
              {elm.dial_code === '+1' ? `${elm.code}/US` :elm.code} {elm.dial_code}
            </option>
          )}
        </select>

      </div>
      <div className="form-group col-sm-12 col-md-6">
        <label className="text-muted" htmlFor={`phone${index}`} data-testid={`phone-${index}`}>
          <FormattedMessage id="contact.form.phone" defaultMessage="Telefone" />: <span className="form-required">*</span>
        </label>
        {phone.phone !== undefined && (
          <TextInput
            id={`phone${index}`}
            key={`input${index}`}
            type="text"
            name="phone"
            required
            value={phone.phone}
            mask={masks}
            minLength={!masks ? 2 : 12}
            onChange={handlePhone}
            onPaste
            onDrop
            inputClass="form-control"
            errorClass="form-control is-invalid"
            textErrorClass={style['form-text-error']}
          />
      )}

      </div>
      <div className="form-group col-sm-12 col-md-3">
        <label className="text-muted" htmlFor={`phone_type${index}`} data-testid={`type-${index}`}>
          <FormattedMessage id="contact.form.type" defaultMessage="Tipo" />: <span className="form-required">*</span>
        </label>
        {phone.phone_type !== undefined && (
        <span className="form-group">
          <SelectInput
            id={`phone_type${index}`}
            selectClass="form-control col-11"
            items={telephoneSelect}
            dictionary={phonePT}
            value={phone.phone_type}
            onChange={onChange}
            name="phone_type"
            index={index}
          />
          {!!index &&
          <span className="form-icon-delete" >
            <button type="button" style={btnStyle} onClick={removePhone} data-testid={`delete-phone-${index}`}>
              <Delete className="list-table-body-action-icons clickable" width="26" height="26" dataPos={index} />
            </button>
          </span>
          }
        </span>

      )}
        <div className={`${formStyle.message} form-text-error`} style={{ width: '100%', textAlign: 'left' }}>
          {validator.message}
        </div>
      </div>
    </div>

  );
};

ContactPhone.defaultProps = {
  onChange: () => {},
  country: 'BR',
};

ContactPhone.propTypes = {
  index: PropTypes.number.isRequired,
  country: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  phone: PropTypes.shape({
    phone: PropTypes.string,
    phone_type: PropTypes.string,
  }).isRequired,
  removePhone: PropTypes.func.isRequired,
  validator: PropTypes.shape({
    phone_type: PropTypes.bool,
    phone: PropTypes.bool,
    message: PropTypes.string
  }).isRequired,
};

export default ContactPhone;
