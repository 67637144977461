import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import ArrowDown from '../../icons/ArrowDown';
import '../../css/user.scss';
import '../../css/contactto.scss';

const User = ({ attendantName, logout, toggleAdminModal, loggedAttendant }) => {
  const companyId = loggedAttendant.groups.length > 1 ? loggedAttendant.groups.filter(grp => ![1,2,3,4].includes(grp))[0] : undefined;
  const editUrl = companyId ? `/app/companies/${companyId}/user/form/edit/${loggedAttendant.id}` : `/app/user/form/edit/${loggedAttendant.id}`;
  return (
  <nav className="user-menu">
    <ul className="user-menu-list">
      <li className="user-menu-list-items">
        <Link to="/app/user" className="user-container">
          <div id="user-container__icon" className="fas fa-user-cog fa-2x" />
          <div id="header-user-name" className="user-container__text">{attendantName}</div>
          <ArrowDown className="user-container__down" />
        </Link>
      </li>
      <li className="user-menu-list-items">
        <button id="header-user-messages" className="user-menu-list-items__text" onClick={toggleAdminModal} >
          <FormattedMessage id="header.user.messages" defaultMessage="Avisos" />
        </button>
      </li>
      <li className="user-menu-list-items">
        <Link id="header-user-edit" className="user-menu-list-items__text" to={editUrl}><FormattedMessage id="header.user.edit" defaultMessage="Editar" /></Link>
      </li>
      <li className="user-menu-list-items">
        <Link id="header-user-logout" className="user-menu-list-items__text" to="/" onClick={logout}><FormattedMessage id="header.user.logout" defaultMessage="Sair" /></Link>
      </li>
    </ul>
  </nav>
)};

User.propTypes = {
  attendantName: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
  toggleAdminModal: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    loggedAttendant: state.loggedAttendant,
  };
}

export default connect(mapStateToProps)(User);
