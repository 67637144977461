import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Edit from '../../../icons/Edit';

import style from './contactPage.module.scss';
import '../../../css/row.scss';

class ContactMobileRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      showModal: false,
    };
    this.toggleLogExtra = this.toggleLogExtra.bind(this);
  }

  toggleLogExtra() {
    this.setState(prevState => ({
      expanded: !prevState.expanded,
    }));
  }

  render() {
    const { expanded } = this.state;
    const {
      contact,
      isAdmin,
      isOwner,
      isManager,
      setModalInfo,
      showModal,
    } = this.props;
    const list = `/app/contacts/detail/${contact.id}`;
    const contacts = `/app/contacts/list/${contact.id}`;
    const contactAttend = `/app/contacts/attends/${contact.id}`;
    const transmissors = `/app/transmissors/list/${contact.id}`;
    const edit = `/app/contacts/form/edit/${contact.id}`;
    const logs = `/app/contacts/logs/${contact.id}`;
    const btnStyle = { border: 0, backgroundColor: 'transparent', padding: 0 };
    return (
      <div className={style.tableRowWrapper} style={{ height: `${expanded ? 'auto' : ''}`, overflow: 'hidden' }}>
        <div
          className={`${style.tableRow} ${this.props.contact.is_deleted ? style.removed : ''}`}
          style={{ paddingRight: '20px' }}
        >
          <div className={`${style.column} ${style.name}`}>
            {contact.first_name} {contact.last_name}
            {contact.is_contractor &&
              <i class="fas fa-dollar-sign" style={{ marginLeft: '30px', color: '#415057' }}/>
            }
          </div>
          <div className={`${style.column} ${style.toggleButtonWrapper}`}>
            <button className={style.toggleButton} onClick={this.toggleLogExtra}>
              { !expanded
                ? '+'
                : '-'
              }
            </button>
          </div>
        </div>
        <div className={style.buttonMenuWrapper}>
    <Link
          className={style.actions}
          onMouseOver={() => this.props.setTextIconDetail(<FormattedMessage id="general.columnView" defaultMessage="Visualizar" />)}
          onMouseOut={() => this.props.setTextIconDetail('')}
          to={`/app/contacts/detail/${contact.client}/${contact.id}`}
        >
           <i className="far fa-eye" style={{ fontSize: 23, color: '#666', paddingTop: 3 }} />
          {/* <DetailIcon className={style.icons} width="28" height="28" /> */}
        </Link>
      {/* <Link
        className={style.actions}
        onMouseOver={() => setTextIconDetail(<FormattedMessage id="general.columnView" defaultMessage="Visualizar" />)}
        onMouseOut={() => setTextIconDetail('')}
        to={`/app/contacts/detail/${contact.client}/${contact.id}`}
      >
        <DetailIcon className={style.icons} width="28" height="28" />
      </Link> */}
      {(isAdmin || isOwner || isManager) &&
        <Link
          className={style.actions}
          onMouseOver={() => this.props.setTextIconDetail(<FormattedMessage id="general.columnEdit" defaultMessage="Editar" />)}
          onMouseOut={() => this.props.setTextIconDetail('')}
          to={`/app/contacts/form/edit/${contact.client}/${contact.id}`}
               >
          <Edit className={style.icons} width="28" height="28" />
        </Link>
      }
      {(isAdmin || isOwner || isManager) &&
      <button
        onMouseOver={() =>
          this.props.setTextIconDetail(contact.is_deleted === false ? <FormattedMessage id="general.columnDeactivate" defaultMessage="Desativar" /> : <FormattedMessage id="general.columnRecover" defaultMessage="Recuperar" />)}
        onMouseOut={() => this.props.setTextIconDetail('')}
        onClick={() => {
          if (!showModal) {
            setModalInfo(true, contact);
          }
        }}
        className={style.actions}
        style={{ border: 0, backgroundColor: 'transparent', padding: 0 }}>
        {contact.is_deleted === false ?
        <div className={style.actions}>
        <i className="far fa-trash-alt" style={{ fontSize: 23, color: '#666', paddingTop: 3 }} />
        </div>
        :
        <div className={style.actions}>
          <i className="fas fa-trash-restore-alt" style={{ fontSize: 23, color: '#666', paddingTop: 3 }} />
            {/* <Restore className={style.icons} width="28" height="28" /> */}
                    </div>
            }
      </button>}
        </div>
      </div>
    );
  }
}

ContactMobileRow.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    street: PropTypes.string,
    number: PropTypes.number,
    complement: PropTypes.string,
    district: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    zip_code: PropTypes.string,
    has_keys: PropTypes.bool,
    relationship: PropTypes.string,
    priority: PropTypes.number,
    is_deleted: PropTypes.bool,
    client: PropTypes.number,
    phones: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        phone_type: PropTypes.string,
        phone: PropTypes.string,
        contact: PropTypes.number,
      }),
    ),
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        priority: PropTypes.number,
        contact: PropTypes.number,
        receive_sms: PropTypes.bool,
        receive_email: PropTypes.bool,
      }),
    ),
    created_date: PropTypes.string,
    modified_date: PropTypes.string,
    created_by: PropTypes.number,
    modified_by: PropTypes.number,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool.isRequired,
  isManager: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  setModalInfo: PropTypes.func.isRequired,
};

export default ContactMobileRow;
