import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function attendsReducer(state = initialState.clients, action) {
  switch (action.type) {
    case types.LOAD_ATTENDS_SUCCESS: {
      const results = Object.assign({}, action.results, { page: action.page });
      return {
        activePage: action.page,
        activeUrl: action.pageUrl,
        pages: [results],
        ordering: action.ordering,
        filter: action.filter,
        count: action.results.count,
        active: action.results.active,
        reload: false,
      };
    }
    case types.RECEIVE_NEW_ATTEND_PAGE: {
      const results = Object.assign({}, action.newPage, { page: action.pageNumber });
      const newState = Object.assign({}, state, {
        pages: [...state.pages, results],
        activePage: action.pageNumber,
        activeUrl: action.pageUrl,
      },
      {reload: false});
      return newState;
    }
    case types.SET_ATTEND_PAGE: {
      const newState = Object.assign({}, state, {
        activePage: action.pageNumber, activeUrl: action.pageUrl
      },
      {reload: false});
      return newState;
    }
    case types.EDIT_ATTEND: {
      if (!state.pages) {
        return state;
      }
      const newState = Object.assign({}, state, {
        pages: state.pages.map(dataList => (
          Object.assign({}, dataList, {
            results: dataList.results.map(attend => {
              if (attend.id === action.attend.id) {
                return action.attend;
              }
              return attend;
            }),
          })
        )),
      },
      {reload: true});
      return newState;
    }
    default:
      return state;
  }
}

// import * as types from '../actions/actionTypes';
// import initialState from './initialState';

// /*
//   Inputs:
//     state = Estado atual do objeto, caso não seja passado é utilizado o estado default do arquivo(initalstate.attends)
//     action = Ação aplicada sobre o objeto

//   Types:
//     LOAD_ATTENDS_SUCCESS = Atendimentos carregados
//     RELOAD_ATTENDS_SUCCESS = Atendimentos recarregados com sucesso

//    attendsReducer:
//     Caso atendimento seja carregado com sucesso, retorna a listagem de atendimentos ordernados,
//       a próxima página caso exista, a página corrente, a contagem total de páginas, dados da página corrente, atendimentos fechados.
// */
// export default function attendsReducer(state = initialState.attends, action) {
//   if (action.type === types.LOAD_ATTENDS_SUCCESS) {
//     const attendsOrdering =
//       state.length > 0
//         ? state.filter(attends => attends.ordering === action.ordering)[0]
//         : undefined;
//     if (!attendsOrdering) {
//       return [
//         ...state,
//         {
//           ordering: action.ordering,
//           isFiltered: action.isFiltered,
//           filter: action.filter,
//           list: [
//             {
//               page: action.page,
//               next: action.next,
//               count: action.count,
//               attendsOrdered: action.attendsOrdered,
//               attendsClosed: action.attendsClosed,
//             },
//           ],
//         },
//       ];
//     }
//     const updatedOrdering = Object.assign(
//       {},
//       {
//         ordering: action.ordering,
//         isFiltered: action.isFiltered,
//         filter: action.filter,
//         list: [
//           ...attendsOrdering.list,
//           {
//             page: action.page,
//             next: action.next,
//             count: action.count,
//             attendsOrdered: action.attendsOrdered,
//             attendsClosed: action.attendsClosed,
//           },
//         ],
//       },
//     );
//     return [
//       ...state.filter(attends => attends.ordering !== action.ordering),
//       updatedOrdering,
//     ];
//   } else if (action.type === types.RELOAD_ATTENDS_SUCCESS) {
//     return [
//       {
//         ordering: action.ordering,
//         isFiltered: action.isFiltered,
//         filter: action.filter,
//         list: [
//           {
//             page: action.page,
//             next: action.next,
//             count: action.count,
//             attendsOrdered: action.attendsOrdered,
//             attendsClosed: action.attendsClosed,
//           },
//         ],
//       },
//     ];
//   } else if (action.type === types.ERASE_ATTEND_LIST) {
//     return [];
//   }
//   return state;
// }
