import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import SelectInput from '../../../Common/SelectInput2';
import CheckboxInput from '../../../Common/CheckboxInput';
import formStyle from '../../../../css/form.module.scss';
import style from './programming.module.scss';
import Tooltip from '../../../Tooltips/TooltipContainer';
import '../../../../css/block.scss';


const IGOProgrammingComponent = ({ trackerParams, setParameters, convertAndSendParams }) => (
  <div className={style.trackerMenuContainer}>
    {trackerParams &&
      <div>
        <div className={style.trackerMenuValue}>
          <span className={`${style.trackerMenuText} col-6`}>GSM
          : </span>
          <div className={`${style.blockValue} col-3`}>
            <CheckboxInput name="GSM" className={style.checkbox} checked={trackerParams.GSM === '1'} onChange={setParameters} /></div>
                    <span className={style.tooltipContainer}>
            <Tooltip tooltipMessage={<FormattedMessage id="client.tracker.gsmTooltip" defaultMessage="Ativa/Desativa localização via GSM quando o satélite GPS não está disponível. Aumento do consumo de bateria." />} /> </span>
        </div>
        <div className={style.trackerMenuValue}>
          <span className={`${style.trackerMenuText} col-6`}>
            <FormattedMessage id="client.tracker.powerSav" defaultMessage="Economia de Energia" />: </span>
          <div className={`${style.blockValue} col-3`}>
            <CheckboxInput name="POWER_SAVING" className={style.checkbox} checked={trackerParams.POWER_SAVING === '1'} onChange={setParameters} />
          </div>
          <span className={style.tooltipContainer}> <Tooltip tooltipMessage={<FormattedMessage id="client.tracker.powerSavTooltip" defaultMessage="Ativa/Desativa modo de economia inteligente de energia. Ao desativar essa função, o dispositivo envia sua localização de acordo com o intervalo de postagem selecionado." />} /></span>
        </div>
        <div className={style.trackerMenuValue}>
        <span className={`${style.trackerMenuText} col-6`}>
            <FormattedMessage id="client.tracker.voiceMon" defaultMessage="Modo Escuta" />: </span>
            <div className={`${style.blockValue} col-3`}>
                  <CheckboxInput name="VOICE_MONITOR" className={style.checkbox} checked={trackerParams.VOICE_MONITOR === '1'} onChange={setParameters} />
        </div>
        <span className={style.tooltipContainer}><Tooltip tooltipMessage={<FormattedMessage id="client.tracker.voiceMonTooltip" defaultMessage="Ativa/Desativa modo escuta. Números cadastrados no dispositivo podem realizar chamadas silenciosas e ouvir o que está acontecendo com o dispositivo sem que seja indicada a chamada." />} /></span>
        
        </div>
        <div className={style.trackerMenuValue}>
        <span className={`${style.trackerMenuText} col-6`}>
            <FormattedMessage id="client.tracker.ledInd" defaultMessage="Desativar LEDs" />
            : </span>
            <div className={`${style.blockValue} col-3`}>
                  <CheckboxInput name="LED_INDICATOR" className={style.checkbox} checked={trackerParams.LED_INDICATOR === '1'} onChange={setParameters} />
      </div>
      <span className={style.tooltipContainer}><Tooltip tooltipMessage={<FormattedMessage id="client.tracker.ledIndTooltip" defaultMessage="Ativa/Desativa indicação dos LEDS, os LEDS laterais vão se apagar/acender, o dispositivo funciona normalmente." />} />
        </span>
        </div>
        <div className={style.trackerMenuValue}>
        <span className={`${style.trackerMenuText} col-6`}>
          <FormattedMessage id="client.tracker.fallDetection" defaultMessage="Detecção de Quedas" />
          : </span>
          <div className={`${style.blockValue} col-3`}>
                  <CheckboxInput name="FALL_DETECTION" className={style.checkbox} checked={trackerParams.FALL_DETECTION === '1'} onChange={setParameters} />
       </div>
       <span className={style.tooltipContainer}><Tooltip tooltipMessage={<FormattedMessage id="client.tracker.fallDetectionTooltip" defaultMessage="Ativa/Desativa detecção de quedas. Dispositivo vai avisar telefones cadastrados que ocorreu uma queda sem necessidade do botão ser pressionado." />} /></span>
        </div>
        <div className={style.trackerMenuValue}>
        <span className={`${style.trackerMenuText} col-6`}>
          <FormattedMessage id="client.tracker.autopost" defaultMessage="Intervalo de postagem de localização" />
    :</span>
            <div className={`${style.blockValue} col-5`}>
           <select
              className={`${style.selectInput} ${
                formStyle['select--enable']}
                ${style.selectInterval}
                 custom-select`}
              name="INTERVAL"
              value={trackerParams.deviceInterval}
              onChange={setParameters}
            >
              <option value="003">30</option>
              <option value="005">50</option>
              <option value="010">100</option>
              <option value="030">300</option>
              <option value="060">600</option>
            </select>
            <div style={{ marginLeft: '5px', float: 'left', color: '#b4b4b4' }}><FormattedMessage id="client.tracker.seconds" defaultMessage="Segundos" /></div>
          </div>
          <span className={style.tooltipContainer}>
            <Tooltip tooltipMessage={<FormattedMessage id="client.tracker.autopostTooltip" defaultMessage="Selecionar o intervalo de tempo para que o dispositivo enviará a sua localização. A configuração padrão do dispositivo é enviar a cada 3 minutos quando em movimento e a cada 10 minutos quando em repouso." />} /></span>
        </div>
        <div className={style.trackerMenuValue}>
        <span className={`${style.trackerMenuText} col-6`}>
          <FormattedMessage id="client.tracker.overspeed" defaultMessage="Alarme de alta velocidade" />
   : </span>
          <div className={`${style.blockValue} col-5`}>
            <SelectInput
              items={['50', '60', '70', '80', '90', '100']}
              selectClass={`${style.selectInput} ${
                formStyle['select--enable']}
                ${style.selectInterval}
                custom-select`}
              value={trackerParams.OVERSPEED_INDICATOR}
              onChange={setParameters}
              name="OVERSPEED_INDICATOR"
            /> <div style={{ marginLeft: '5px', float: 'left', color: '#b4b4b4' }}> Km/h</div>
          </div>
          <span className={style.tooltipContainer}>
               <Tooltip tooltipMessage={<FormattedMessage id="client.tracker.overspeedTooltip" defaultMessage="Selecionar a velocidade desejada para ativar o alarme de alta velocidade ou Desligar essa função. Quando a velocidade for excedida, o dispositivo irá enviar SMS aos contatos cadastrados." />} /></span>
        </div>
        <div className="col-12">
        <button className={style.trackerMenuBtn} onClick={convertAndSendParams}>
        <span><i className="far fa-save fa-lg" aria-hidden="true" style={{ paddingRight: '0.5rem', paddingTop: '0.2rem' }} /></span>
           <FormattedMessage id="client.tracker.saveSettings" defaultMessage="Salvar Configurações" />
        </button>
        </div>
      </div>
    }
  </div>
);

IGOProgrammingComponent.propTypes = {
  trackerParams: PropTypes.shape({
    A1: PropTypes.string,
    B1: PropTypes.string,
    C1: PropTypes.string,
    DEVICEID: PropTypes.string,
    EVENTTYPE: PropTypes.string,
    OVERSPEED_INDICATOR: PropTypes.string,
    INTERVAL: PropTypes.string,
    FALL_DETECTION: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    GSM: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    AGPS: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    LED_INDICATOR: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    POWER_SAVING: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    VOICE_MONITOR: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    deviceInterval: PropTypes.string,
  }).isRequired,
  setParameters: PropTypes.func.isRequired,
  convertAndSendParams: PropTypes.func.isRequired,
};

export default IGOProgrammingComponent;
