/* eslint-disable import/no-anonymous-default-export */
export default {
  en: [
    'Son',
    'Daughter',
    'Grandson',
    'Granddaughter',
    'Son-in-law',
    'Daughter-in-law',
    'Friend (M)',
    'Friend (F)',
    'Nephew',
    'Niece',
    'Neighbor (M)',
    'Neighbor (F)',
    'Brother',
    'Sister',
    'Brother-in-law',
    'Sister-in-law',
    'Cousin (M)',
    'Cousin (F)',
    'Ambulance',
    'Lobby',
    'Not informed',
    'Other'
  ],
  pt: [
    'Filho',
    'Filha',
    'Neto',
    'Neta',
    'Genro',
    'Nora',
    'Amigo',
    'Amiga',
    'Sobrinho',
    'Sobrinha',
    'Vizinho',
    'Vizinha',
    'Irmão',
    'Irmã',
    'Cunhado',
    'Cunhada',
    'Primo',
    'Prima',
    'Ambulância',
    'Portaria',
    'Não informado',
    'Outro'
  ]
}
