import * as types from './actionTypes';
import * as errorsActions from './errorsActions';
import * as globals from '../globals/globals';
import { host, getDomain, getToken } from '../BLL/environment';

export function loadAlarmsSuccess(alarms, ordering) {
  return { type: types.LOAD_ALARMS_SUCCESS, alarms, ordering };
}

export function newAlarm(alarm) {
  return { type: types.NEW_ALARM, alarm };
}

export function updatedAlarm(alarm) {
  return { type: types.EDIT_ALARM, alarm };
}

export function loadAlarms(ordering = globals.alarmsOrdering) {
  const domain = getDomain();
  const token = getToken();

  return (dispatch, getState, { axios }) => Promise.resolve(
    axios.get(
      `https://${domain}${host()}/api/alarm-event-types/?ordering=is_deleted,${ordering}`,
      { headers: { Authorization: `Token ${token}` } },
    ).then((alarms) => {
      dispatch(loadAlarmsSuccess(alarms.data, ordering));
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(errorsActions.logout());
        }
      }
    }),
  );
}

export function createAlarm(alarm) {
  const domain = getDomain();
  const token = getToken();

  return (dispatch, getState, { axios }) => new Promise((resolve, reject) => 
    axios({
      method: 'post',
      responseType: 'json',
      url: `https://${domain}${host()}/api/alarm-event-types/`,
      data: alarm,
      headers: { Authorization: `Token ${token}` },
    })
    .then((res) => resolve(dispatch(newAlarm(res.data))))
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          return reject(dispatch(errorsActions.logout()));
        }
        return reject(error.response);
      }
      return reject({});
    }),
  );
}

export function editAlarm(alarm) {
  const domain = getDomain();
  const token = getToken();

  return (dispatch, getState, { axios }) => new Promise((resolve, reject) => 
    axios({
      method: 'put',
      responseType: 'json',
      url: `https://${domain}${host()}/api/alarm-event-types/${alarm.id}/`,
      data: alarm,
      headers: { Authorization: `Token ${token}` },
    })
    .then(() => resolve(dispatch(updatedAlarm(alarm))))
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          return reject(dispatch(errorsActions.logout()));
        }
        return reject(error.response);
      }
      return reject({});
    }),
  );
}
