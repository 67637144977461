import React, { Component } from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import '../../css/contactto.scss';
import './attendByDayChart.scss';

import CustomChart from './CustomChart';

class AttendByDayChart extends Component {
  constructor(props) {
    super(props);
    this.alta = [];
    this.media = [];
    this.baixa = [];
    this.sistema = [];
    this.dias = [];
    if (localStorage.contacttoLang === 'pt') {
      this.highLabel = 'Alta';
      this.mediumLabel = 'Média';
      this.lowLabel = 'Baixa';
      this.systemLabel = 'Sistema';
    }
    if (localStorage.contacttoLang === 'en') {
      this.highLabel = 'High';
      this.mediumLabel = 'Medium';
      this.lowLabel = 'Low';
      this.systemLabel = 'System';
    }
    this.props.numberOfAttends.map((el) => {
      this.dias.push(moment(el.day).format('DD/MM'));
      this.alta.push(el.priority_1);
      this.media.push(el.priority_2);
      this.baixa.push(el.priority_3);
      this.sistema.push(el.priority_4);
      return this;
    });

    this.datasets = [
      {
        label: this.highLabel,
        backgroundColor: '#EC8981',
        backgroundColorHover: '#3e95cd',
        data: this.alta,
        fill: true,
      },
      {
        label: this.mediumLabel,
        backgroundColor: '#F9BE7D',
        backgroundColorHover: '#3e95cd',
        data: this.media,
        fill: true,
      },
      {
        label: this.lowLabel,
        backgroundColor: '#59C599',
        backgroundColorHover: '#3e95cd',
        data: this.baixa,
        fill: true,
      },
      {
        label: this.systemLabel,
        backgroundColor: '#59D7F2',
        backgroundColorHover: '#3e95cd',
        data: this.sistema,
        fill: true,
      },
    ];
  }

  render() {
    return (
      <div className="left" style={{ height: this.props.height, width: this.props.width, maxHeight: '600px' }}>
        <CustomChart data={this.datasets} labels={this.dias} displayLegend={false} isMultiBar />
        <div className="legend-wrapper">
          <span className="legend-header" style={{ marginRight: '1rem' }}>
            {<FormattedMessage id="dashboard.tabs.priority" defaultMessage="PRIORIDADE" />}
          </span>
          <span className="legend-content" style={{ backgroundColor: '#EC8981' }}>
            {<FormattedMessage id="dashboard.tabs.subhigh" defaultMessage="ALTA" />}
          </span>
          <span className="legend-content" style={{ backgroundColor: '#F9BE7D' }}>
            {<FormattedMessage id="dashboard.tabs.submedium" defaultMessage="MEDIA" />}
          </span>
          <span className="legend-content" style={{ backgroundColor: '#59C599' }}>
            {<FormattedMessage id="dashboard.tabs.sublow" defaultMessage="BAIXA" />}
          </span>
          <span className="legend-content" style={{ backgroundColor: '#59D7F2' }}>
            {<FormattedMessage id="dashboard.tabs.subsystem" defaultMessage="SISTEMA" />}
          </span>
        </div>
      </div>
    );
  }
}

AttendByDayChart.defaultProps = {
  height: '350px',
  width: '350px',
  numberOfAttends: '',
};

AttendByDayChart.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  numberOfAttends: PropTypes.arrayOf(
    PropTypes.shape({
      dias: PropTypes.string,
      alta: PropTypes.number,
      media: PropTypes.number,
      baixa: PropTypes.number,
      sistema: PropTypes.number,
    }),
  ),
};

export default AttendByDayChart;
