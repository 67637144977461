import React from 'react';
import PropTypes from 'prop-types';
import '../css/priority.scss';

const PriorityIcon = ({ className, height, width }) => (
  <svg className={className} width={width} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill="none" d="M0,0h24v24H0V0z" />
    <path d="M1,21h22L12,2L1,21z M13,18h-2v-2h2V18z M13,14h-2v-4h2V14z" />
  </svg>
);

PriorityIcon.defaultProps = {
  className: '',
  height: '24',
  width: '24',
};

PriorityIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default PriorityIcon;
