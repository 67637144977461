import React from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker, START_DATE } from 'react-dates';
import messages from '../../../BLL/locale/messages';
import 'react-dates/lib/css/_datepicker.css';

class DateRangePickerWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: START_DATE,
      startDate: null,
      endDate: null,
      resetDate: this.props.resetDate,
    };

    this.onDatesChange = this.onDatesChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps({ resetDate }) {
    if (resetDate !== this.props.resetDate && resetDate === true) {
      this.setState({
        startDate: null,
        endDate: null,
      });
    }
  }

  onDatesChange({ startDate, endDate }) {
    this.props.setDate(startDate, endDate);
    this.setState({ startDate, endDate });
  }

  onFocusChange(focusedInput) {
    this.props.focusedInput(focusedInput);
    this.setState({ focusedInput });
  }

  render() {
    const { focusedInput, startDate, endDate, resetDate } = this.state;
    const localStyle = {
      width: 'auto',
    };
    const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
    const style = Object.assign({}, localStyle, this.props.style);
    return (
      <div className="left contactto-100" style={style}>
        <DateRangePicker
          onDatesChange={this.onDatesChange}
          onFocusChange={this.onFocusChange}
          focusedInput={focusedInput}
          startDate={startDate}
          endDate={endDate}
          startDatePlaceholderText={messages[lang]['date.startDate']}
          endDatePlaceholderText={messages[lang]['date.endDate']}
          displayFormat="DD/MM/YYYY"
          isOutsideRange={() => false}
          minimumNights={0}
          orientation={this.props.orientation}
          daySize={35}
          className="daterangepicker-container"
        />
      </div>
    );
  }
}

DateRangePickerWrapper.defaultProps = {
  setDate: () => {},
  resetDate: false,
  orientation: 'horizontal',
  focusedInput: () => {},
  style: {},
};

DateRangePickerWrapper.propTypes = {
  setDate: PropTypes.func,
  resetDate: PropTypes.bool,
  orientation: PropTypes.string,
  focusedInput: PropTypes.func,
  style: PropTypes.shape({
    color: PropTypes.string,
  })
};

export default DateRangePickerWrapper;
