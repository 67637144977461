import * as types from './actionTypes';
import { host, getDomain, getToken } from '../BLL/environment';

export function globalConfig(global) {
  return {
    type: types.RELOAD_GLOBAL_CONFIGURATIONS,
    global,
  };
}


export function loadGlobalConfiguration() {
  const domain = getDomain();
  const token = getToken();

  return (dispatch, state, { axios }) => new Promise((resolve, reject) => {
    return axios({
      method: 'get',
      responseType: 'json',
      url: `https://${domain}${host()}/api/global-config/`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      resolve(dispatch(globalConfig(res.data)));
    })
    .catch((error) => {
      // console.log('ERRO AO REALIZAR O REALIZAR GLOBAL DAS CONFIGURAÇÕES');
      reject(error);
    });
  });
}
