import React from 'react';
import PropTypes from 'prop-types';

const AlarmEmerSensIco = ({ height, svgClass, width, fill, transform }) => (
  <svg className={svgClass} width={width} height={height} fill={fill} transform={transform} viewBox="0 0 140 140" xmlns="http://www.w3.org/2000/svg">
<g>
<path d="M70.9,60.8c-6.3,0-8.7,5.4-8.7,10.9c0,5.3,2.4,10.7,8.7,10.7c6.3,0,8.7-5.4,8.7-10.7C79.5,66.2,77.2,60.8,70.9,60.8z"/>
<path d="M70.9,4.7C34.3,4.7,4.7,34.3,4.7,70.9c0,36.5,29.6,66.1,66.1,66.1S137,107.4,137,70.9C137,34.3,107.4,4.7,70.9,4.7z
 M39.1,88.3c-7.1,0-13.7-3.5-13.6-11.4h6.8c0,4.2,3.3,5.9,7.1,5.9c2.5,0,6.2-0.7,6.2-4c0-3.4-4.8-4-9.5-5.3
c-4.7-1.3-9.5-3.1-9.5-9.1c0-6.5,6.2-9.7,11.9-9.7c6.7,0,12.8,2.9,12.8,10.3h-6.8c-0.2-3.8-3-4.8-6.3-4.8c-2.2,0-4.8,0.9-4.8,3.6
c0,2.5,1.5,2.8,9.5,4.8c2.3,0.6,9.5,2.1,9.5,9.3C52.4,84,47.8,88.3,39.1,88.3z M70.9,88.3c-9.9,0-15.7-7.4-15.7-16.6
c0-9.5,5.8-16.8,15.7-16.8s15.7,7.4,15.7,16.8C86.6,81,80.7,88.3,70.9,88.3z M102.9,88.3c-7.1,0-13.7-3.5-13.6-11.4h6.8
c0,4.2,3.3,5.9,7.1,5.9c2.5,0,6.2-0.7,6.2-4c0-3.4-4.8-4-9.5-5.3c-4.7-1.3-9.5-3.1-9.5-9.1c0-6.5,6.2-9.7,11.9-9.7
c6.7,0,12.8,2.9,12.8,10.3h-6.8c-0.2-3.8-3-4.8-6.3-4.8c-2.2,0-4.8,0.9-4.8,3.6c0,2.5,1.5,2.8,9.5,4.8c2.3,0.6,9.5,2.1,9.5,9.3
C116.2,84,111.6,88.3,102.9,88.3z"/>
</g>
  </svg>
);

AlarmEmerSensIco.defaultProps = {
  height: '75',
  svgClass: '',
  width: '75',
  fill: '#D8342B',
  transform: 'translate(0,0)',
};

AlarmEmerSensIco.propTypes = {
  height: PropTypes.string,
  svgClass: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
  transform: PropTypes.string,
};

export default AlarmEmerSensIco;
