import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import style from './attendComments.module.scss';
import '../../../../css/detailPage.scss';

const AttendComments = ({ report, isEditing, toggleEdit, setReport, updateReport, isAdmin }) => (
  <div className={style['comment-wrapper']}>
    {isEditing
      ?
      <div style={{ width: '100%', float: 'left' }}>
        <textarea
          name="report"
          className={style['text-area']}
          onChange={setReport}
          style={{ border: '1px solid #ccc' }}
          value={!report ? '' : report}
          maxLength="1024"
          rows="10"
          cols="50"
        />
        <button className={style['comment-btn-save']} onClick={() => updateReport()}>
          <i className="far fa-save" />
        </button>
        <button className={style['comment-btn-cancel']} onClick={() => toggleEdit()}>
          <i className="far fa-window-close" />
        </button>
      </div>
      :
      <div style={{ width: '100%', float: 'left' }}>
        <div className={style.comment}>
          {!report ? <FormattedMessage id="attend.closed.reportNoComment" defaultMessage="Sem comentários" /> : report}
        </div>
        {isAdmin &&
          <button className={style['comment-btn-edit']} onClick={() => toggleEdit()}>
            <i className="far fa-edit" />
          </button>
        }
        
      </div>
    }
  </div>
);

AttendComments.defaultProps = {
  isEditing: false,
  isAdmin: false,
};

AttendComments.propTypes = {
  report: PropTypes.string.isRequired,
  isEditing: PropTypes.bool,
  isAdmin: PropTypes.bool,
};

export default AttendComments;
