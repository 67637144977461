const initialState = {
  alarms: [],
  attends: {},
  attendsDashboard: {
    attendsOpen: [],
    attendsClosed: []
  },
  attendants: [],
  auth: {
    token: null,
    userName: null,
    status: null,
    isPaid: false,
    isAuthenticated: false,
    isAuthenticating: false,
    configuration: null,
    userConfiguration: null,
    statusText: null,
  },
  clients: {},
  contacts: [],
  errors: [],
  loggedAttendant: null,
  equipment: [],
  search: [],
  status: [],
  transmitters: [],
  browser: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  importInfo: {
    clientsNotOk: [],
    clientsOk: 0,
  },
  localConfigurations: {
    attends_quantity: null,
    last_attends: null,
    monitored_tenants: null,
    priority_attends: null,
  },
  globalConfigurations: {
    attendant_list: null,
    contacts_list: null,
  },
  tenantStatus: null,
  locale: {
    lang: 'pt',
  },
  asteriskServer: {
    realm: null,
    name: null,
    server: null,
  },
  serverList: [],
  attendTags: [],
  groups: [],
  reminders: {
    serverTime: null,
    data: [],
  },
  lowerPriorityAttends: [],
  showReminderComponent: false,
};

export default initialState;
