import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IntlProvider, FormattedMessage } from "react-intl";
import axios from "axios";
import moment from "moment";
import usePageReloadDetection from "./components/Hooks/usePageReloadDetection";

import Header from "./components/Header/Header";
import Sidebar from "./components/Sidebar/SidebarV2";
import Middle from "./components/Middle/Middle";
import RocketChat from "./components/Chat/RocketChat";
import EmergencyAudioControl from "./components/Common/AudioControlV2";
import ModalAlarm from "./components/Modal/ModalAlarm";
import ModalConfirm from "./components/Modal/ModalConfirm";
import ModalNotification from "./components/Modal/ModalNotificationV2";
import ModalReminder from "./components/Modal/Reminders/ReminderComponent";
// import ModalReminder from './components/Modal/ModalReminder';
import ModalReminderNote from "./components/Modal/ModalReminderNote";
import ModalWarning from "./components/Modal/ModalWarning";

import * as authActions from "./actions/authActions";
import * as attendantsActions from "./actions/attendantsActions";
import * as loggedAttendantActions from "./actions/loggedAttendantActions";
import * as localeActions from "./actions/localeActions";
import * as alarmsActions from "./actions/alarmsActions";
import * as attendsActions from "./actions/attendsActions";
import * as attendsDashboardActions from "./actions/attendsDashboardActions";
import * as clientsActions from "./actions/clientsActions";
import * as contactsActions from "./actions/contactsActions";
import * as transmittersActions from "./actions/transmittersActions";
import * as equipmentActions from "./actions/equipmentActions";
import * as browserActions from "./actions/browserActions";
import * as tenantStatusActions from "./actions/tenantStatusActions";
import * as statusActions from "./actions/userStatusActions";
import * as groupsActions from "./actions/groupsActions";
import * as remindersActions from "./actions/reminderActions";
import * as attendTagsActions from "./actions/attendTagsActions";
import * as globalConfigurationActions from "./actions/globalConfigurationsActions";
import * as localConfigurationActions from "./actions/localConfigurationsActions";
import * as lowerPriorityAttendsActions from "./actions/lowerPriorityAttendsActions";
import * as reminderComponentActions from "./actions/reminderComponentActions";

import messages from "./BLL/locale/messages";
import { host, getDomain, getToken, getInfo } from "./BLL/environment";
import { broadcast } from "./BLL/general";

import "./css/style.scss";
import "./css/table.scss";
import "./css/attends.scss";
import "./css/fonts.css";
import "./css/contactto.scss";
import "./css/loading.scss";
import "./css/mixins/_default_buttons.scss";
import "./css/buttons.scss";
import "./css/modal.scss";
import "./css/scrollbar.css";

import "./pushstream/pushstream";
import CustomModal from "./components/Modal/CustomModal";

const App = (props) => {
  const pageReloaded = usePageReloadDetection();
  const notificationList = useSelector((state) => state.lowerAlarms);
  const attendants = useSelector((state) => state.attendants);
  const attends = useSelector((state) => state.attends);
  const clients = useSelector((state) => state.clients);
  const loggedAttendant = useSelector((state) => state.loggedAttendant);
  const lang = useSelector((state) => state.locale.lang);
  const reminders = useSelector((state) => state.reminders);
  const busyUsers = useSelector((state) => state.status);
  const globalConfiguration = useSelector((state) => state.globalConfiguration);

  const dispatch = useDispatch();
  // Attendants Actions
  const loadAttendants = () => dispatch(attendantsActions.loadAttendants());
  const setLoggedAttendant = (attendant) =>
    dispatch(loggedAttendantActions.setLoggedAttendant(attendant));
  const newAttendant = (att) => dispatch(attendantsActions.newAttendant(att));
  const updatedAttendant = (att) =>
    dispatch(attendantsActions.updatedAttendant(att));
  const loadGroups = () => dispatch(groupsActions.loadGroups());
  const loadBusyUsers = () => dispatch(statusActions.loadBusyUsers());
  const addUserIntoBusy = (userId, attendId) =>
    dispatch(statusActions.addUserIntoBusy(userId, attendId));
  const removeUserFromBusy = (userId) =>
    dispatch(statusActions.removeUserFromBusy(userId));
  // Attend Actions
  const attendUpdated = (attend) =>
    dispatch(attendsActions.attendUpdated(attend));
  const reloadAttends = () => dispatch(attendsActions.reloadAttends());
  const loadDashboardAttends = () =>
    dispatch(attendsDashboardActions.loadDashboardAttends());
  const newAttendDashboard = (attend) =>
    dispatch(attendsDashboardActions.newAttendDashboard(attend));
  const updateAttendDashboard = (attend) =>
    dispatch(attendsDashboardActions.updateAttendDashboard(attend));
  const closeAttendDashboard = (attend) =>
    dispatch(attendsDashboardActions.closeAttendDashboard(attend));
  const editAttend = (attend, callback) =>
    dispatch(attendsActions.editAttend(attend, callback));
  const loadAttendTags = () => dispatch(attendTagsActions.loadAttendTags());
  const addLowerAlarm = (attend) =>
    dispatch(lowerPriorityAttendsActions.addLowerAlarm(attend));
  const removeLowerAlarm = (index) =>
    dispatch(lowerPriorityAttendsActions.removeLowerAlarm(index));
  // Alarms Actions
  const updatedAlarm = (alarm) => dispatch(alarmsActions.updatedAlarm(alarm));
  const newAlarm = (alarm) => dispatch(alarmsActions.newAlarm(alarm));
  // Client Actions
  const clientUpdated = (client) =>
    dispatch(clientsActions.clientUpdated(client));
  const reloadClients = () => dispatch(clientsActions.reloadClients());
  // Contact Actions
  const loadContacts = (clientId, ordering) =>
    dispatch(contactsActions.loadContacts(clientId, ordering));
  const contactAdded = (contact, clientId) =>
    dispatch(contactsActions.contactAdded(contact, clientId));
  const contactUpdated = (contact, clientId) =>
    dispatch(contactsActions.contactUpdated(contact, clientId));
  const contactRemoved = (contact, clientId) =>
    dispatch(contactsActions.contactRemoved(contact, clientId));
  // Transmitter Actions
  const transmitterUpdated = (transmitter, clientId) =>
    dispatch(transmittersActions.transmitterUpdated(transmitter, clientId));
  const transmitterAdded = (transmitter, clientId) =>
    dispatch(transmittersActions.transmitterAdded(transmitter, clientId));
  const transmitterDeleted = (transmitterId, clientId) =>
    dispatch(transmittersActions.transmitterDeleted(transmitterId, clientId));
  // Equipment Actions
  const loadEquipment = () => dispatch(equipmentActions.loadEquipment());
  // Reminders Actions
  const loadReminders = () => dispatch(remindersActions.loadReminders());
  const createReminder = (reminder) =>
    dispatch(remindersActions.createReminder(reminder));
  const toggleReminderComponent = () =>
    dispatch(reminderComponentActions.toggleReminderComponent());
  // Config actions
  const authLogoutAndRedirect = () =>
    dispatch(authActions.authLogoutAndRedirect());
  const setLocale = (locale) => dispatch(localeActions.setLocale(locale));
  const addBrowserDimentions = ({ width, height }) =>
    dispatch(browserActions.addBrowserDimentions({ width, height }));

  const loadTenantStatus = () =>
    dispatch(tenantStatusActions.loadTenantStatus());

  const loadGlobalConfig = (url) =>
    dispatch(globalConfigurationActions.loadGlobalConfiguration(url));
  const loadLocalConfig = (user) =>
    dispatch(localConfigurationActions.loadLocalConfiguration(user));
  // Auth Actions
  const resetStore = () => dispatch(authActions.authResetStore());

  let notificationAudio = new Audio(
    "https://s3-sa-east-1.amazonaws.com/contactto-frontend/sounds/secondary_alarm3.mp3"
  );
  notificationAudio.volume = 1;
  let reminderAudio = new Audio(
    "https://s3-sa-east-1.amazonaws.com/contactto-frontend/sounds/secondary_alarm2.mp3"
  );
  reminderAudio.volume = 1;
  // const { lang } = props;
  const refNotificationList = useRef();
  const refEmergencyList = useRef();
  const attendReloadInterval = useRef();
  const [openedPushstream, setPushstream] = useState(undefined);
  const [user, setUser] = useState(undefined);
  const [tenantStatus, setTenantStatus] = useState("");
  const [tenantIsPaid, setTenantIsPaid] = useState(true);
  const [tenantIsActive, setTenantIsActive] = useState(true);
  const [adminMessage, setAdminMessage] = useState(undefined);
  const [showAdmMessageModal, setShowAdmMessageModal] = useState(false);
  const [hasReminders, setHasReminders] = useState(false);
  const [showReminderComponent, setShowReminderComponent] = useState(false);
  const [showAttendModal, setShowAttendModal] = useState(false);
  const [showReminderNote, setShowReminderNote] = useState(false);
  const [showActivationModal, setShowActivationModal] = useState(false);
  const [showInteractionModal, setShowInteractionModal] = useState(false);
  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [newChatMessage, setNewChatMessage] = useState(false);
  const [audioAction, setAudioAction] = useState("");
  const [reminderAttendId, setReminderAttendId] = useState(undefined);
  const [emergencyList, setEmergencyList] = useState([]);
  const [updateList, setUpdateList] = useState([]);
  const [updateInfoNotificationText, setUpdateInfoNotificationText] = useState("");
  const [channelId, setChannelId] = useState("");
  const [hasWebsocketConnection, setHasWebsocketConnection] = useState(false);
  const [sipStack, setSipStack] = useState(undefined);
  const [sipStackType, setSipStackType] = useState(undefined);
  const [sipSessionType, setSipSessionType] = useState(undefined);
  const [sipEventType, setSipEventType] = useState(undefined); // Type for the call session
  const [sipCall, setSipCall] = useState(undefined);

  // For testing purposes
  const [sipEvtListener, setSipEventListener] = useState(undefined);
  const [evtListener, setEventListener] = useState(undefined);
  const [sessionListener, setSessionListener] = useState(undefined);

  useEffect(() => {
    if (pageReloaded && window.location.pathname === '/app') {
      setShowInteractionModal(true);
    }
  }, [pageReloaded]);

  useEffect(() => {
    if (localStorage.domainInfo === "{}" || !localStorage.domainInfo) {
      props.history.push("/login");
    } else {
      if (lang && !localStorage.contacttoLang) {
        localStorage.setItem("contacttoLang", lang);
      }

      if (sessionStorage.activeDomain) {
        document.title = `${document.title.split(" ")[0]} - ${
          sessionStorage.activeDomain
        }`;
      }

      // Load essencial information at the start of the app (Channel ID, what status the tenant is, the equipment, etc...)
      const { isPaid, isActive } = getInfo();
      setTenantIsPaid(isPaid);
      setTenantIsActive(isActive);

      if (attendants.length === 0) {
        loadAttendants();
      }

      fetchChannelId();
      fetchTenantStatus();
      fetchAdminMessages();
      loadEquipment();
      loadBusyUsers();
      loadGroups();
      loadAttendTags();
      loadDashboardAttends();
      loadGlobalConfig();

      // Create an event listener to deal with browser redimensioning
      window.addEventListener("resize", () => {
        addBrowserDimentions({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      });
    }

    let debug = false;
    if (window.location.hostname.indexOf("homologation") !== -1) {
      debug = true;
    } else if (
      window.location.hostname === "localhost" ||
      window.location.hostname === "172.20.31.143"
    ) {
      debug = true;
    }

    if (!debug) {
      if (!window.console) window.console = {};
      var methods = ["log", "debug", "warn", "info"];
      for (var i = 0; i < methods.length; i++) {
        console[methods[i]] = function () {};
      }
    }
  }, []);

  useEffect(() => {
    const loadOpenDashboardAttends = () => 
      dispatch(attendsDashboardActions.loadOpenDashboardAttends());
    if (!attendReloadInterval.current) {
      const interval = setInterval(() => {
        console.log('Reloading dashboard attends');
        loadOpenDashboardAttends();
      }, 15 * 60 * 1000); // Every 15 minutes
      attendReloadInterval.current = interval;
    }

    return () => {
      const currentAttendReloadInterval = attendReloadInterval.current;
      clearInterval(currentAttendReloadInterval);
    }
  }, [dispatch]);

  useEffect(() => {
    // When the attendant list is filled, search for the attendant that just logged in and add it to the Redux Store
    if (attendants.length > 0) {
      if (localStorage.domainInfo) {
        const localUser = JSON.parse(localStorage.domainInfo)[
          sessionStorage.activeDomain
        ].user;
        const loggedUser = attendants[0].data.find(
          (att) => att.id === localUser.id
        );
        setLoggedAttendant(loggedUser);
      }
    }
  }, [attendants]);

  useEffect(() => {
    // After the logged attendant store value is filled, create the SIP stack and load that attendant
    // reminders and local configurations
    if (loggedAttendant) {
      setUser(Object.assign({}, loggedAttendant));
      if (loggedAttendant.user_info) {
        createSipStack(loggedAttendant);
      }
      loadReminders();
      loadLocalConfig(loggedAttendant.id);
    }
  }, [loggedAttendant]);

  useEffect(() => {
    let heartbeatInterval;
    let socket;
    let reconnectTimeout;
  
    const connectHeartbeat = () => {
      // Clear any existing reconnect timeout
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
      }
  
      // Close any existing WebSocket and clear any existing heartbeat
      if (socket) {
        socket.close();
      }
      if (heartbeatInterval) {
        clearInterval(heartbeatInterval);
      }
  
      socket = new WebSocket(`wss://${getDomain()}${host()}:8080/heartbeat`);
  
      const sendHeartbeat = () => {
        console.log("Sending heartbeat");
        const domain = getDomain();
        socket.send(
          JSON.stringify({ type: 'heartbeat', user_id: loggedAttendant.id, schema: domain })
        );
      };
  
      heartbeatInterval = setInterval(sendHeartbeat, 30000);
  
      // Define reconnection logic
      const reconnect = () => {
        console.log('Attempting to reconnect...');
        reconnectTimeout = setTimeout(connectHeartbeat, 10000); // 10-second delay
      };
  
      // Handle WebSocket closing or error by attempting to reconnect
      socket.onclose = reconnect;
      socket.onerror = reconnect;
    };
  
    if (loggedAttendant) {
      connectHeartbeat();
    }
  
    return () => {
      if (socket) {
        socket.close();
      }
      if (heartbeatInterval) {
        clearInterval(heartbeatInterval);
      }
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
      }
    };
  }, [loggedAttendant]);

  useEffect(() => {
    // Set up the HTTP Push stream from Nginx
    if (channelId && user && globalConfiguration.global) {
      createPushstream(channelId);
    }
  }, [channelId, user, globalConfiguration]);

  useEffect(() => {
    if (reminders.data.length > 0) {
      setHasReminders(true);
    }
  }, [reminders]);

  useEffect(() => {
    refNotificationList.current = notificationList;
  }, [notificationList]);

  useEffect(() => {
    refEmergencyList.current = emergencyList;
  }, [emergencyList]);

  // Auth block
  const logout = () => {
    authLogoutAndRedirect().then(() => {
      logoutUser();
    });
  };

  const fetchTenantStatus = () => {
    loadTenantStatus()
      .then((res) => setTenantStatus(res.status.status))
      .catch((error) => {
        console.log(error);
        if (error.data && error.data.trialLimit) {
          logoutUser();
        }
      });
  };

  const fetchAdminMessages = () => {
    axios
      .get(`https://${getDomain()}${host()}/api/admin-messages/`, {
        headers: { Authorization: `Token ${getToken()}` },
      })
      .then((resp) => {
        if (resp.data) {
          const expireAt = moment(resp.data.expireat);
          const serverTime = moment(resp.data.server);
          const expires = expireAt.diff(serverTime, "seconds");
          setAdminMessage(resp.data);
          setShowAdmMessageModal(true);
          setTimeout(() => {
            setAdminMessage(undefined);
            setShowAdmMessageModal(false);
          }, expires * 1000);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          setAdminMessage(undefined);
          setShowAdmMessageModal(false);
        }
      });
  };

  const logoutUser = () => {
    let newDomainList = JSON.parse(localStorage.domainList).filter(
      (domain) => domain !== sessionStorage.activeDomain
    );
    let newDomainInfoObj = JSON.parse(localStorage.domainInfo);
    delete newDomainInfoObj[sessionStorage.activeDomain];
    localStorage.setItem("domainInfo", JSON.stringify(newDomainInfoObj));
    localStorage.setItem("domainList", JSON.stringify(newDomainList));
    sessionStorage.removeItem("channelId");
    // There is still tenants in the list
    if (newDomainList.length > 0) {
      sessionStorage.setItem("activeDomain", newDomainList[0]);
      sessionStorage.setItem(
        "activeToken",
        newDomainInfoObj[newDomainList[0]].token
      );
      props.history.push("/app");
      window.location.reload();
    } else {
      sessionStorage.removeItem("activeDomain");
      sessionStorage.removeItem("activeToken");
      props.history.push("/login");
      resetStore();
      window.location.reload();
    }
  };

  const fetchChannelId = () => {
    if (sessionStorage.channelId) {
      const storageChannelId = sessionStorage.channelId;
      setChannelId(storageChannelId);
    } else {
      axios
        .get(`https://${getDomain()}${host()}/api/alarm-channel/`, {
          headers: { Authorization: `Token ${sessionStorage.activeToken}` },
        })
        .then((res) => {
          console.log(res);
          const newChannelId = res.data.channel_id;
          sessionStorage.setItem("channelId", newChannelId);
          setChannelId(newChannelId);
        });
    }
  };

  // Pushstream HTTP Push block
  const createPushstream = (channelId) => {
    const statusOpen = 2;
    // Create a new pushstream with newer props everytime something changes
    if (openedPushstream) {
      openedPushstream.disconnect();
    }

    const pushstream = new PushStream({ // eslint-disable-line no-undef
      host: `${getDomain()}${host()}`,
      port: "",
      useSSL: true,
      modes: "websocket",
      reconnectOnChannelUnavailableInterval: 10000,
    });

    pushstream.onmessage = messageReceived;
    pushstream.onerror = () => {
      setHasWebsocketConnection(false);
    };
    pushstream.onstatuschange = () => {
      if (pushstream.readyState === statusOpen) {
        loadDashboardAttends();
        setHasWebsocketConnection(true);
      }
    };
    pushstream.addChannel(`${channelId}`);
    pushstream.connect();
    setPushstream(pushstream);
  };

  const messageReceived = (obj) => {
    // Process the HTTP Push message received depending on the Type variable
    console.log(obj);
    const type = obj.type;
    const text = obj.text;
    const isDifferentUser = obj.userId
      ? obj.userId !== loggedAttendant.id
      : false;
    if (type !== "activation" && type !== "admCall" && type !== "client") {
      if (text) {
        setUpdateInfoNotificationText(text);
        setTimeout(() => {
          setUpdateInfoNotificationText("");
        }, 2000);
      }
    }

    switch (type) {
      case "activation": {
        if (text) {
          setUpdateInfoNotificationText(text);
          setTimeout(() => {
            setUpdateInfoNotificationText("");
            logout();
          }, 5000);
        }
        break;
      }
      case "admCall": {
        // When the administrator takes the attend to him/herself
        // TODO: Change admCall to a better text
        if (obj.oldUser === loggedAttendant.id) {
          setUpdateInfoNotificationText(text);
          setTimeout(() => {
            setUpdateInfoNotificationText("");
            window.location.reload();
          }, 10000);
        }
        break;
      }
      case "alarm": {
        if (obj.method === "edit" && isDifferentUser) {
          updatedAlarm(obj.alarm);
        } else if (obj.method === "create" && isDifferentUser) {
          newAlarm(obj.alarm);
        }
        break;
      }
      case "attend": {
        // Attends have a second layer of type, using the status variable
        processAttendMessage(obj);
        break;
      }
      case "attendant": {
        if (obj.method === "edit" && isDifferentUser) {
          updatedAttendant(obj.attendant);
        } else if (obj.method === "create" && isDifferentUser) {
          newAttendant(obj.attendant);
        } else if (obj.method === "toggle") {
          loadAttendants();
        }
        break;
      }
      case "client": {
        const isSysOrCompanyUser =
          loggedAttendant.groups.length === 1 ||
          loggedAttendant.groups.includes(obj.client?.group_id);
        const hasClientsLoaded = clients.pages ? true : false;

        if (obj.text && isSysOrCompanyUser) {
          setUpdateInfoNotificationText(text);
          setTimeout(() => {
            setUpdateInfoNotificationText("");
          }, 2000);
        }

        if (
          obj.method === "edit" &&
          isDifferentUser &&
          isSysOrCompanyUser &&
          hasClientsLoaded
        ) {
          clientUpdated(obj.client);
        } else if (
          obj.method === "create" &&
          isDifferentUser &&
          isSysOrCompanyUser &&
          hasClientsLoaded
        ) {
          reloadClients();
        } else if (obj.method === "toggle") {
          reloadClients();
        }
        break;
      }
      case "config": {
        loadGlobalConfig();
        loadLocalConfig(loggedAttendant.id);
        break;
      }
      case "contact": {
        if (obj.method === "edit" && isDifferentUser) {
          contactUpdated(obj.contact, obj.clientId);
        } else if (obj.method === "create" && isDifferentUser) {
          contactAdded(obj.contact, obj.clientId);
        } else if (obj.method === "toggle") {
          loadContacts(obj.clientId);
        } else if (obj.method === "destroy") {
          contactRemoved(obj.contact, obj.clientId);
        }
        break;
      }
      case "message": {
        fetchAdminMessages();
        break;
      }
      case "reminders": {
        if (isDifferentUser && loggedAttendant.groups.length === 1) {
          loadReminders();
        }
        break;
      }
      case "transmitter": {
        if (obj.method === "edit" && isDifferentUser) {
          transmitterUpdated(obj.transmitter, obj.clientId);
        } else if (obj.method === "create" && isDifferentUser) {
          transmitterAdded(obj.transmitter, obj.clientId);
        } else if (obj.method === "delete") {
          transmitterDeleted(obj.transmitterId);
        }
        break;
      }
      default:
        break;
    }
  };

  const processAttendMessage = (obj) => {
    console.log(obj);
    const isSysOrCompanyUser =
      user.groups.length === 1 || user.groups.includes(obj.client.group_id);
    const indexNotification = refNotificationList.current.findIndex(
      (el) => el.attend.id === obj.attend.id
    );
    const indexEmergency = refEmergencyList.current.findIndex(
      (el) => el.attend.id === obj.attend.id
    );
    const useAlarmZones = globalConfiguration.global.use_alarm_zones;
    const playSecondaryAudio = globalConfiguration.global.secondary_alarm_sound;

    switch (obj.status) {
      case "open": {
        const canPlayAudio =
          window.location.pathname.indexOf("/app/attends/call") === -1;
        if (isSysOrCompanyUser) {
          newAttendDashboard(obj.attend);
        }

        if (attends && Object.keys(attends).length > 0) {
          reloadAttends();
        }

        if (useAlarmZones) {
          const attendZone = obj.attend.tr_zone
            ? parseInt(obj.attend.tr_zone) % 4
            : 1;
          const highestCode = obj.attend.highest_code;
          if (
            highestCode === "101" &&
            attendZone > 0 &&
            canPlayAudio &&
            playSecondaryAudio &&
            isSysOrCompanyUser
          ) {
            playSecondaryAlarm(6, 350);
          }

          if (
            (highestCode === "101" && attendZone === 0) ||
            highestCode === "100"
          ) {
            if (isSysOrCompanyUser) {
              const newEmergencyList = [
                ...refEmergencyList.current.filter(
                  (e) => e.attend.id !== obj.attend.id
                ),
                obj,
              ];
              setEmergencyList(newEmergencyList);
              setShowAttendModal(true);
              setAudioAction(canPlayAudio ? "start" : "stop");
            }
            const attendIndex = notificationList.findIndex(
              (el) => el.attend.id === obj.attend.id
            );
            removeLowerAlarm(attendIndex);
          } else if (
            refEmergencyList.current.every(
              (el) => el.attend.id !== obj.attend.id
            ) &&
            isSysOrCompanyUser
          ) {
            addLowerAlarm(obj);
          }
        } else {
          if (
            !obj.alarmEvent.some((el) => el.event_type.priority === 1) &&
            canPlayAudio &&
            playSecondaryAudio &&
            isSysOrCompanyUser
          ) {
            console.log("Playing notification");
            playSecondaryAlarm(6, 350);
          }
          if (obj.alarmEvent.some((el) => el.event_type.priority === 1)) {
            if (isSysOrCompanyUser) {
              const newEmergencyList = [
                ...refEmergencyList.current.filter(
                  (e) => e.attend.id !== obj.attend.id
                ),
                obj,
              ];
              setEmergencyList(newEmergencyList);
              setShowAttendModal(true);
              setAudioAction(canPlayAudio ? "start" : "stop");
            }
            const attendIndex = notificationList.findIndex(
              (el) => el.attend.id === obj.attend.id
            );
            removeLowerAlarm(attendIndex);
          } else if (
            refEmergencyList.current.every(
              (el) => el.attend.id !== obj.attend.id
            ) &&
            isSysOrCompanyUser
          ) {
            addLowerAlarm(obj);
          }
        }
        break;
      }
      case "in_progress": {
        if (isSysOrCompanyUser) {
          updateAttendDashboard(obj.attend);
          setAudioAction("stop");
        }
        if (attends && Object.keys(attends).length > 0) {
          attendUpdated(obj.attend);
        }
        if (obj.attend.attendant !== loggedAttendant.id) {
          addUserIntoBusy(obj.attend.attendant, obj.attend.id);
        }
        if (indexNotification !== -1) {
          removeLowerAlarm(indexNotification);
        }
        if (indexEmergency !== -1) {
          removeEmergencyItem(indexEmergency);
        }
        break;
      }
      case "close": {
        if (isSysOrCompanyUser) {
          closeAttendDashboard(obj.attend);
        }

        if (obj.attend.attendant !== loggedAttendant.id) {
          removeUserFromBusy(obj.attend.attendant);
        }

        clearUpdateList(obj.attend.id);
        if (attends && Object.keys(attends).length > 0) {
          attendUpdated(obj.attend);
        }

        break;
      }
      default:
        break;
    }
  };

  const playSecondaryAlarm = (
    numberOfTimes = 1,
    delay = 100,
    firstTime = true
  ) => {
    if (firstTime) {
      notificationAudio.play();
    }
    setTimeout(() => {
      if (!firstTime) {
        notificationAudio.play();
      }
      numberOfTimes--;
      if (numberOfTimes > 0) {
        playSecondaryAlarm(numberOfTimes, delay, false);
      }
    }, delay);
  };

  // Attend notification block
  const clearUpdateList = (attendId) => {
    if (attendId) {
      const notificationIndex = refNotificationList.current.findIndex(
        (el) => el.attend.id === attendId
      );
      const emergencyIndex = refEmergencyList.current.findIndex(
        (el) => el.attend.id === attendId
      );

      if (notificationIndex !== -1) {
        removeLowerAlarm(notificationIndex);
      }

      if (emergencyIndex !== -1) {
        removeEmergencyItem(emergencyIndex);
      }
    }
    setUpdateList([]);
  };

  const removeEmergencyItem = (index) => {
    const newEmergencyList = [
      ...refEmergencyList.current.slice(0, index),
      ...refEmergencyList.current.slice(index + 1),
    ];
    if (newEmergencyList.length === 0) {
      setAudioAction("stop");
    }
    setEmergencyList(newEmergencyList);
  };

  // SIP Stack block
  const createSipStack = (user) => {
    let pwd = `contactto#${user.id}#${user.user_info.extension_id}@${sessionStorage.activeDomain}`;
    let realm = "";
    let impi = "";
    let impu = "";
    let password = "";
    if (window.location.hostname.indexOf("homologation") !== -1) {
      // HOMOLOGATION
      // realm = "asterisk-homologation.contactto.care";
      // impi = `${user.user_info.extension_id}`;
      // impu = `sip:${user.user_info.extension_id}@${realm}`;
      // password = pwd;
      realm = 'tsc.asterisk.contactto.care';
      impi = "919877";
      impu = `sip:919877@${realm}`;
      password = "contactto#26#190@meninodeus";
    } else if (
      window.location.hostname === "localhost" ||
      window.location.hostname === "172.20.31.143"
    ) {
      // DEVELOPMENT
      // realm = "172.20.31.143";
      realm = 'tsc.asterisk.contactto.care';
      // realm = 'asterisk-homologation.contactto.care';
      // realm = 'asterisk-development.contactto.care';
      impi = "919876";
      impu = `sip:919876@${realm}`;
      // impi = "90001";
      // impu = `sip:90001@${realm}`;
      // password = 'teste#softphone'
      // password = "contactto@test";
      password = "contactto#26#190@meninodeus";
    } else {
      // PROD
      realm = "tsc.asterisk.contactto.care";
      impi = `${user.user_info.extension_id}`;
      impu = `sip:${user.user_info.extension_id}@${realm}`;
      password = pwd;
    }
    const stack = new SIPml.Stack({ // eslint-disable-line no-undef
      impi: impi,
      impu: impu,
      password: password,
      realm: `https://${realm}`,
      display_name: "Asterisk",
      websocket_proxy_url: `wss://${realm}:8089/ws`,
      outbound_proxy_url: `udp://${realm}:5060`,
      ice_servers: [{ url: "stun:null" }],
      enable_rtcweb_breaker: false,
      events_listener: { events: "*", listener: eventListener },
      sip_headers: [
        { name: "User-Agent", value: "IM-client/OMA1.0 sipML5-v1.0.0.0" },
        { name: "Organization", value: "Tecnosenior" },
      ],
    });
    stack.start();
    setSipStack(stack);
  };

  const eventListener = (e) => {
    console.log("Event Listener", e);
    // Testing purposes
    setEventListener(e);
    setSipStackType(e.type);
    if (e.type === "started" && sipStack) {
      try {
        const sipSessionRegister = sipStack.newSession("register", {
          expires: 200,
          events_listener: { events: "*", listener: sipEventListener },
          sip_caps: [
            { name: "+g.oma.sip-im", value: null },
            { name: "+audio", value: null },
            { name: "language", value: '"en,fr"' },
          ],
        });
        sipSessionRegister.register();
      } catch (e) {
        console.error(e);
      }
    } else if (e.type === "i_new_call") {
      console.log("New Call");
    } else if (e.type === "failed_to_start") {
      setTimeout(() => {
        createSipStack(loggedAttendant);
      }, 30000);
    } else if (e.type === "stopped") {
      setTimeout(() => {
        createSipStack(loggedAttendant);
      }, 30000);
    }
  };

  const sipEventListener = (e) => {
    console.log("Sip event listener", e);
    // Testing purposes
    setSipEventListener(e);
    setSipSessionType(e.type);
    switch (e.type) {
      case "connecting": {
        console.log("Connecting");
        break;
      }
      case "connected": {
        console.log("Connected");
        break;
      }
      case "terminating": {
        break;
      }
      case "terminated": {
        console.log("Call terminated")
        setSessionListener(undefined);
        break;
      }
      case "transport_error": {
        setTimeout(() => {
          createSipStack(loggedAttendant);
        }, 30000);
        break;
      }
      default:
        break;
    }
  };

  const makeSipCall = (number) => {
    const newSipCall = sipStack.newSession("call-audio", {
      audio_remote: document.getElementById("audio_sip"),
      events_listener: { events: '*', listener: onSipEventSession }
    });
    setSipCall(newSipCall);
    newSipCall.call(number);
  };

  const onSipEventSession = (e) => {
    console.log('SIP session listener', e);
    // Testing purposes
    setSessionListener(e);
    if (e?.type) {
      setSipEventType(e.type);
      if (e.type === 'connected') {
        setSipEventListener(undefined)
      }
    }
  }

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const sendPERSPassword = async () => {
    if (sipCall) {
      const dtmfSequence = [1, 2, 3, 4, "#"];
      for (const dtmf of dtmfSequence) {
        sipCall.dtmf(dtmf);
      }
    } else {
      console.log("Error, no call to send password");
    }
};

  const hangupSip = async () => {
    if (sipCall) {
      sipCall.dtmf(9);
      await sleep(2000);
      sipCall.hangup({
        events_listener: { events: "*", listener: sipEventListener },
      });
      setSipCall(undefined);
      setSipEventType(undefined);
    } else {
      console.log("Error, nothing to hangup.");
      setSipEventType(undefined);
    }
  };

  const extendCall = () => {
    if (sipCall) {
      sipCall.dtmf(8);
    } else {
      console.log("Error, no call to extend");
    }
  };

  const muteAudio = () => {
    if (sipCall) {
      sipCall.dtmf(1);
    } else {
      console.log("Error, no call to mute");
    }
  };

  const sendDTMF = (tone) => {
    if (sipCall) {
      sipCall.dtmf(tone);
    } else {
      console.log("Error, no call to send dtmf");
    }
  };

  const mutePhone = () => {
    if (sipCall) {
      sipCall.dtmf(3);
    } else {
      console.log("Error, no call to mute phone");
    }
  };

  const takeEmergency = (type) => {
    if (type !== "stop-audio") {
      setUserIsBusy(true);
    }
    setAudioAction("stop");
  };

  const setUserIsBusy = (isBusy) => {
    setAudioAction(isBusy ? "stop" : "");
  };

  // Reminders block
  const createReminderFunction = (reminder) => {
    const data = reminder;
    data.status = "OPEN";
    data.attend = reminderAttendId;
    data.open_attendant = loggedAttendant.id;
    createReminder(data).then(() => {
      setShowReminderNote(false);
      if (!data.is_individual) {
        broadcast(axios, channelId, {
          type: "reminders",
          update: "reminders",
          ownerAttendantId: loggedAttendant.id,
          userId: loggedAttendant.id,
        });
      }
    });
  };

  if (!localStorage.domainInfo || localStorage.domainInfo === "{}") {
    return <></>;
  }

  const rcToken =
    sessionStorage.activeDomain &&
    JSON.parse(localStorage.domainInfo)[sessionStorage.activeDomain].rc_token;
  const deactivatedRC = sessionStorage.deactivatedRC; // Only for testing with cypress, deactivating RC will decrease the load time

  return (
    <IntlProvider locale={lang} messages={messages[lang]} key={lang}>
      <div className="app__container">
        {!deactivatedRC && user && user.groups.length === 1 && (
          <RocketChat
            showChat={showChat}
            rcToken={rcToken}
            toggleNewMessage={() => setNewChatMessage(showChat ? false : true)}
          />
        )}
        {showAdmMessageModal && adminMessage && (
          <ModalWarning
            message={adminMessage[`message_${lang}`]}
            onClick={() => setShowAdmMessageModal(!showAdmMessageModal)}
          />
        )}
        {showInteractionModal &&
          <CustomModal
            text="O sistema foi recarregado ou recém aberto. Por favor, clique em FECHAR para continuar"
            btnText="FECHAR"
            btnAction={() => setShowInteractionModal(false)}
          />

        }
        <div
          className={`update-info-notification ${
            updateInfoNotificationText ? "update-info-notification--active" : ""
          }`}
        >
          {updateInfoNotificationText}
        </div>
        {channelId && user && (
          <ModalAlarm
            emergencyList={emergencyList}
            takeEmergency={takeEmergency}
            userId={user.id}
            editAttend={editAttend}
            pathname={props.location.pathname}
            setUserBusy={setUserIsBusy}
            busyUsers={busyUsers}
            history={props.history}
            showAttendModal={showAttendModal}
            toggleAttendModal={() => setShowAttendModal(!showAttendModal)}
            loggedAttendant={user}
          />
        )}
        {hasReminders && (
          <div
            id="modal-reminder-container"
            className={`reminder__container ${
              !showReminderComponent && "reminder__hide"
            }`}
          >
            <ModalReminder
              hideReminder={!showReminderComponent}
              openReminderComponent={() => {
                if (!showReminderComponent) {
                  reminderAudio.play();
                }
                setShowReminderComponent(true);
              }}
              toggleReminderComponent={() =>
                setShowReminderComponent(!showReminderComponent)
              }
              openReminderNote={() => setShowReminderNote(true)}
              setReminderAttendId={setReminderAttendId}
              channelId={channelId}
              toggleHasReminders={() => setHasReminders(!hasReminders)}
              sipStackType={sipStackType}
              startCall={makeSipCall}
              sendPERSPassword={sendPERSPassword}
              hangupSip={hangupSip}
            />
          </div>
        )}
        {showReminderNote && (
          <ModalReminderNote
            attendId={reminderAttendId}
            createReminder={createReminderFunction}
            closeModal={() => setShowReminderNote(!setShowReminderNote)}
          />
        )}
        {showActivationModal && (
          <ModalConfirm
            message={
              <FormattedMessage
                id="app.activate.message"
                defaultMessage="Ativando esta conta, seus usuários atuais passarão a ser cobrados. Continuar?"
              />
            }
            setModalInfo={() => setShowActivationModal(false)}
            action={() => {
              setShowActivationModal(false);
              props.history.push("/app/activate");
            }}
          />
        )}
        {user && (
          <Header
            attendantName={`${user.first_name} ${user.last_name}`}
            lang={lang}
            logout={logout}
            status={tenantStatus}
            setLocale={setLocale}
            sipStackType={sipStackType}
            sipSessionType={sipSessionType}
            hasWebsocketConnection={hasWebsocketConnection}
            isSysUser={user.groups.length === 1}
            isAdmin={user.groups[0] === 1}
            isOwner={user.groups[0] === 4}
            toggleChat={() => {
              setShowChat(!showChat);
              setNewChatMessage(false);
            }}
            newChatMessage={newChatMessage}
            toggleReminder={() =>
              setShowReminderComponent(!showReminderComponent)
            }
            toggleAdminModal={() =>
              setShowAdmMessageModal(!showAdmMessageModal)
            }
            reminders={reminders}
            activate={() => setShowActivationModal(true)}
            history={props.history}
            audioAction={audioAction}
            turnOffAlarmAudio={() => setAudioAction("stop")}
            sipEvtListener={sipEvtListener}
            evtListener={evtListener}
            sessionListener={sessionListener}
            hangupSip={hangupSip}
          />
        )}
        {user && (
          <Sidebar
            expanded={sidebarExpanded}
            id={user.id}
            toggleSidebar={() => setSidebarExpanded(!sidebarExpanded)}
            isManagement={
              user.groups[0] === 2 ||
              user.groups[0] === 1 ||
              user.groups[0] === 4
            }
            isFromCompany={user.groups.length > 1}
            isManager={user.groups[0] === 2}
            isAdmin={user.groups[0] === 1}
            isOwner={user.groups[0] === 4}
          />
        )}
        {channelId && user && (
          <Middle
            app={props.children}
            channelId={channelId}
            toggleAudio={setAudioAction}
            clearList={clearUpdateList}
            user={user}
            sipStackType={sipStackType}
            sipEventType={sipEventType}
            showChat={showChat}
            attendSip={makeSipCall}
            hangupSip={hangupSip}
            extendCall={extendCall}
            setUserBusy={setUserIsBusy}
            takeEmergency={takeEmergency}
            muteAudio={muteAudio}
            mutePhone={mutePhone}
            sendDTMF={sendDTMF}
            testEchoSip={() => makeSipCall("0001echo")}
            startCall={makeSipCall}
            sendPERSPassword={sendPERSPassword}
            status={tenantStatus}
            isPaid={tenantIsPaid}
            isActive={tenantIsActive}
            isAdmin={user.groups[0] === 1}
            isOwner={user.groups[0] === 4}
          />
        )}
        {channelId && user && window.location.pathname !== "/app" && (
          <ModalNotification channelId={channelId} />
        )}
        <EmergencyAudioControl action={audioAction} />
      </div>
    </IntlProvider>
  );
};

export default App;
