import { combineReducers } from 'redux';
import * as types from '../actions/actionTypes';
import alarms from './alarmsReducer';
// import attendance from './attendanceReducer';
import attendants from './attendantsReducer';
import attends from './attendsReducer';
import attendsDashboard from './attendsDashboardReducer';
import auth from './authReducer';
import browser from './browserReducer';
import clients from './clientsReducer';
import contacts from './contactsReducer';
import errors from './errorsReducer';
import loggedAttendant from './loggedAttendantReducer';
import transmitters from './transmittersReducer';
import status from './userStatusReducer';
import equipment from './equipmentReducer';
import importInfo from './importInfoReducer';
import localConfiguration from './localConfigurationReducer';
import globalConfiguration from './globalConfigurationReducer';
import globalServer from './globalServerReducer';
import serverList from './asteriskServerReducer';
import attendTags from './attendTagsReducer';
import tenantStatus from './tenantStatusReducer';
import locale from './localeReducer';
import groups from './groupsReducer';
import reminders from './remindersReducer';
import lowerAlarms from './lowerPriorityAttendsReducer';
import reminderComponentReducer from './reminderComponentReducer';

const appReducer = combineReducers({
  showReminderComponent: reminderComponentReducer,
  alarms,
  attendants,
  attends,
  attendsDashboard,
  auth,
  browser,
  clients,
  localConfiguration,
  globalConfiguration,
  globalServer,
  serverList,
  contacts,
  errors,
  loggedAttendant,
  transmitters,
  status,
  equipment,
  importInfo,
  tenantStatus,
  locale,
  groups,
  reminders,
  attendTags,
  lowerAlarms
});

const rootReducer = (state, action) => {
  if (action.type === types.AUTH_RESET_STORE) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
}

export default rootReducer;
