import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import '../../css/toggleButton.scss';

const ToggleButton = ({ isChecked, handleChange, hasPagination, text, top }) => {
  const toggle = isChecked === true ? 'slider slider--checked round' : 'slider round';
  return (
    <div className={`toggle-wrapper ${hasPagination ? 'pagination' : ''}`}>
      <div style={{ float: 'left', color: '#999', transform: 'translate(-15%, 40%)', top: '50%' }}>{text}</div>
      <button id="toggle-button" className="switch" onClick={handleChange}>
        <input className="switch--input" type="checkbox" />
        <div className={toggle} />
      </button>
    </div>
  );
};

ToggleButton.defaultProps = {
  text: <FormattedMessage id="toggle.button.inactives" defaultMessage="Inativos" />,
  hasPagination: false,
  top: '',
};

ToggleButton.propTypes = {
  hasPagination: PropTypes.bool,
  // text: PropTypes.string,
  text: PropTypes.shape({
    key: PropTypes.number,
  }),
  top: PropTypes.string,
  isChecked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default ToggleButton;
