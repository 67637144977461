import React from 'react';
import PropTypes from 'prop-types';

const AlarmBatConIcon = ({ height, svgClass, width, fill, transform }) => (
  <svg className={svgClass} width={width} height={height} fill={fill} transform={transform} viewBox="0 0 140 140" xmlns="http://www.w3.org/2000/svg">
    <g id="elbEFr.tif">
      <g>
        <g>
          <path
            d="M55.2,113.2c-7.3,0-12.5-5.2-12.5-12.4c0-15.9,0-31.8,0-47.7c0-0.7,0-1.3,0.1-2c1.1-6,5.9-10,12.1-10.1
c7.2,0,14.3,0,21.5,0c16.7,0,33.4,0,50,0c7.2,0,12.4,5.2,12.4,12.4c0,15.9,0,31.8,0,47.7c0,0.7,0,1.3-0.1,2
c-1.1,6-5.9,10.1-12.1,10.1c-11.9,0-23.9,0-35.8,0C78.9,113.2,67.1,113.2,55.2,113.2z M108.9,98.1c0.4,0,0.8,0,1.2,0
c4.3,0,8.6,0,12.9,0c2.7,0,3.7-1.1,3.8-3.8c0.3-5-1.5-5.3-5.2-5.2c-4.2,0.1-8.4,0-12.8,0C108.9,92.2,108.9,95.1,108.9,98.1z
 M102.7,89.2c-0.4,0-0.8-0.1-1.2-0.1c-4.3,0-8.5,0-12.8,0c-2.9,0-3.8,1.1-4,3.9c-0.2,4.9,1.7,5.1,5.1,5.1c4.2-0.1,8.5,0,12.9,0
C102.7,95.1,102.7,92.2,102.7,89.2z"
          />
        </g>
      </g>
    </g>
    <g id="bXdaTx.tif">
      <g>
        <g>
          <path
            d="M9.1,44.4c0-0.4,0-0.7,0-1c0-1.5,1.2-2.6,2.7-2.6c5.3,0,10.7,0,16,0c1.8,0,2.9,1.1,2.9,2.9c0,0.2,0,0.4,0,0.7
c0.6,0,1.1,0,1.7,0c3.2,0,5.5,2.3,5.5,5.5c0,6.4,0,12.8,0,19.2c0,12.6,0,25.2,0,37.9c0,2.8-1.7,4.9-4.4,5.5
c-0.4,0.1-0.7,0.1-1.1,0.1c-8.3,0-16.5,0-24.8,0c-3.3,0-5.6-2.3-5.6-5.6c0-8.8,0-17.6,0-26.4c0-10.2,0-20.4,0-30.5
c0-2.8,1.8-5,4.5-5.5C7.2,44.3,8.1,44.4,9.1,44.4z M9.1,105.3c7.2,0,14.3,0,21.4,0c0-17.9,0-35.8,0-53.7c-2.4,0-4.7,0-7.1,0
c0-1.2,0-2.4,0-3.5c-2.4,0-4.7,0-7.1,0c0,1.2,0,2.3,0,3.6c-2.4,0-4.8,0-7.1,0C9.1,69.5,9.1,87.4,9.1,105.3z"
          />
          <path d="M12.7,101.8c0-4.8,0-9.5,0-14.3c4.8,0,9.5,0,14.3,0c0,4.8,0,9.5,0,14.3C22.2,101.8,17.5,101.8,12.7,101.8z" />
        </g>
      </g>
    </g>
  </svg>
);

AlarmBatConIcon.defaultProps = {
  height: '75',
  svgClass: '',
  width: '75',
  fill: '#008D53',
  transform: 'translate(0,0)',
};

AlarmBatConIcon.propTypes = {
  height: PropTypes.string,
  svgClass: PropTypes.string,
  width: PropTypes.string,
  fill: PropTypes.string,
  transform: PropTypes.string,
};

export default AlarmBatConIcon;
