import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function remindersReducer(state = initialState.reminders, action) {
  if (action.type === types.LOAD_REMINDERS_SUCCESS) {
    return {
      serverTime: action.serverTime,
      data: [
        ...state.data,
        action.reminders,
      ],
    };
  }

  if (action.type === types.RELOAD_REMINDERS_SUCCESS) {
    return {
      serverTime: action.serverTime,
      data: [...action.reminders],
    };
  }

  return state;
}
