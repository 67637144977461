import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Select from 'react-select';
import { FormattedMessage } from 'react-intl';
import DateRangePickerWrapper from '../ReportDateWrapper';
import ReportButton from '../ReportButtonComponent';

import style from '../reportComponent.module.scss';

const AttendantComponent = (props) => {
  const [attendantOptions, setAttendantOptions] = useState([]);
  const [attendant, setAttendant] = useState(0);
  const [url, setUrl] = useState('');
  const [urlDate, setUrlDate] = useState('');
  const [focusedInput, setFocusedInput] = useState(null);
  const [selectFocus, setSelectFocus] = useState(false);

  useEffect(() => {
    if (props.attendants.length > 0) {
      setAttendantOptions(
        props.attendants.map(attendant => (
          {
            value: attendant.id,
            label: `${attendant.first_name} ${attendant.last_name}`,
            name: `${attendant.first_name} ${attendant.last_name}`,
          }
        ))
      );
    }
  }, [props.attendants]);

  const setDate = (startDate, endDate) => {
    const firstDate = startDate
      ? `&start=${startDate.format('YYYY-MM-DD')}`
      : '&start=2012-11-28';
    const lastDate = endDate
      ? `&end=${endDate.format('YYYY-MM-DD')}`
      : `&end=${moment().format('YYYY-MM-DD')}`;
    setUrlDate(`${firstDate}${lastDate}`);
  };

  const handleAttendant = (attendant) => {
    const { value } = attendant;
    setAttendant(attendant);
    setUrl(`/app/reports/list/attendants/attendant=${value}`);
  };

  return (
    <div className={style.wrapper} style={focusedInput ? { height: '400px' } : {}}>
      <div className={style.code} style={selectFocus ? { height: '230px' } : {}}>
        <Select
          value={attendant}
          options={attendantOptions}
          name="client"
          onChange={handleAttendant}
          simpleValue
          placeholder={<FormattedMessage id="reports.attendants.selectAttendant" defaultMessage="Selecione o Atendente" />}
          onClose={() => setSelectFocus(false)}
          onOpen={() => setSelectFocus(true)}
          clearable={false}
        />
      </div>
      {!!attendant &&
        <DateRangePickerWrapper
          focusedInput={(focused) => setFocusedInput(focused)}
          setDate={setDate}
          resetDate={urlDate === ''}
          orientation={props.browserWidth > 390 ? 'horizontal' : 'vertical'}
          style={{ marginTop: '10px', width: '100%' }}
        />
      }
      {!!attendant && !focusedInput &&
        <ReportButton
          themeColor={props.themeColor}
          url={`${url}${urlDate}`}
          text={<FormattedMessage id="reports.generateReport" defaultMessage="Gerar Relatório" />}
        />
      }
    </div>
  );
};

AttendantComponent.propTypes = {
  themeColor: PropTypes.string.isRequired,
  attendants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      username: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string,
      password: PropTypes.string,
      groups: PropTypes.arrayOf(PropTypes.number),
      is_active: PropTypes.boolean,
      is_staff: PropTypes.boolean,
      is_superuser: PropTypes.boolean,
      user_info: PropTypes.shape({
        id: PropTypes.number,
        user: PropTypes.number,
        phone: PropTypes.string,
        mobile: PropTypes.string,
        email: PropTypes.string,
        extension_id: PropTypes.number,
      }),
      actions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          priority: PropTypes.number,
          user: PropTypes.number,
          receive_sms: PropTypes.boolean,
          receive_email: PropTypes.boolean,
        }),
      ),
    }),
  ).isRequired,
  browserWidth: PropTypes.number.isRequired,
};

export default AttendantComponent;
