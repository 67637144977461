import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Select from 'react-select';
import { FormattedMessage } from 'react-intl';
import DateRangePickerWrapper from '../ReportDateWrapper';
import ReportButton from '../ReportButtonComponent';
import messageTags from '../../../../BLL/locale/messagesTags';

import style from '../reportComponent.module.scss';

const AttendTagComponent = (props) => {
  const [tagId, setTagId] = useState(0);
  const [url, setUrl] = useState('');
  const [urlDate, setUrlDate] = useState('');
  const [focusedInput, setFocusedInput] = useState(null);
  const [selectFocus, setSelectFocus] = useState(false);
  const tagOptions = props.attendTags.map(tag => ({
    value: tag.id,
    label: messageTags[props.lang][tag.id],
    name: messageTags[props.lang][tag.id],
  }));

  const setDate = (startDate, endDate) => {
    const firstDate = startDate
      ? `&start=${startDate.format('YYYY-MM-DD')}`
      : '&start=2012-11-28';
    const lastDate = endDate
      ? `&end=${endDate.format('YYYY-MM-DD')}`
      : `&end=${moment().format('YYYY-MM-DD')}`;
    setUrlDate(`${firstDate}${lastDate}`);
  };

  const handleTagChange = (tag) => {
    setTagId(tag);
    setUrl(`/app/reports/list/tags/tag=${tag.value}`);
  };
  
  return (
    <div className={style.wrapper} style={focusedInput ? { height: 'auto' } : {}}>
      <Select
        value={tagId}
        options={tagOptions}
        name="tag"
        onChange={handleTagChange}
        simpleValue
        placeholder={<FormattedMessage id="reports.attends.selectTag" defaultMessage="Selecione o Tipo de Atendimento" />}
        onClose={() => setSelectFocus(false)}
        onOpen={() => setSelectFocus(true)}
        clearable={false}
      />
      {tagId !== 0 &&
        <DateRangePickerWrapper
          focusedInput={(focused) => setFocusedInput(focused)}
          setDate={setDate}
          resetDate={urlDate === ''}
          orientation={props.browserWidth > 390 ? 'horizontal' : 'vertical'}
          style={{ marginTop: '10px', width: '100%' }}
        />
      }
      {!!tagId && !focusedInput &&
        <ReportButton
          themeColor={props.themeColor}
          url={`${url}${urlDate}`}
          text={<FormattedMessage id="reports.generateReport" defaultMessage="Gerar Relatório" />}
        />
      }
    </div>
  );
};

AttendTagComponent.propTypes = {
  themeColor: PropTypes.string.isRequired,
  browserWidth: PropTypes.number.isRequired,
  attendTags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })).isRequired,
  lang: PropTypes.string.isRequired,
  browserWidth: PropTypes.number.isRequired,
};

export default AttendTagComponent;
