import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import Loading from '../../../../icons/Loading';

import { ataStatusLang } from '../../../../BLL/dictionary';
import style from './ataDeviceList.module.scss';

const allClientAta = gql`
  query($token: String!, $ordering: String) {
    allClientAta(token: $token, ordering: $ordering) {
      clientId
      account
      fullName
      companyId
      number
      status
      port
      isDeleted
    }
  }
`;

const RenderAtaData = ({order}) => {
  const { loading, error, data } = useQuery(allClientAta, {
    variables: {
      token: sessionStorage.activeToken,
      ordering: order
    },
    pollInterval: 30000
  });

  if (loading) {
    return (<Loading text={<FormattedMessage id="attend.list.loading" defaultMessage="Carregando" />} />);
  }

  if (error) {
    return (<div>Error -> {error}</div>)
  }

  return (
    <div className={style.body}>
      {data.allClientAta.length > 0
        ? data.allClientAta.sort((clientA, clientB) => clientA.isDeleted - clientB.isDeleted).map(client => (
          <Link to={`/app/clients/detail/${client.clientId}`} key={client.clientId} className={`${style.row} ${client.isDeleted && style.removed}`}>
            <div className={style.tableAtaField}>
              {client.number}
            </div>
            <div className={style.tableAccountField}>
              {client.account}
            </div>
            <div className={style.tableNameField}>
              {client.fullName.split(' ')[0]} {client.fullName.split(' ').slice(-1)[0]}
            </div>
            <div className={style.tablePortField}>
              {client.port}
            </div>
            <div className={style.tableStatusField}>
              {ataStatusLang(client.status.trim())}
            </div>
          </Link>
        ))
        : <div className={style.row}> <FormattedMessage id="general.emptyList" defaultMessage="Lista Vazia" /> </div>
      }
    </div>
  );
};


RenderAtaData.propTypes = {
  order: PropTypes.string.isRequired,
};

export default RenderAtaData;
