import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  onlyDigit,
  validateCPF,
  validateDate,
  validateMobile,
  validateMobileGB,
  validateEmail,
} from '../../../BLL/validate';
import * as general from '../../../BLL/general';

// If the client is not from brazil, the phone, address and zip-code validation changes
// Doesn't validate them
export const validateClientGeneral = (
equipmentList,
validator,
uniqueFields,
client,
{ name, value }) => {
  const newValidator = validator;
  switch (name) {
    case 'account': {
      if (value.length < 4) {
        newValidator[name].validate = false;
        newValidator[name].message =
          <FormattedMessage id="client.error.accountNumber" defaultMessage="Campo deve possuir pelo menos 4 caracteres" />;
      } else if (
        uniqueFields.findIndex(el => el.account === value) !== -1
      ) {
        newValidator[name].validate = false;
        newValidator[name].message = <FormattedMessage id="client.error.accountExists" defaultMessage="O número de conta já existe" />;
      } else {
        newValidator[name].validate = true;
        newValidator[name].message = '';
      }
      break;
    }
    case 'email': {
      const validate = validateEmail(value);
      newValidator[name].validate = validate;
      if (!validate) {
        newValidator[name].message = <FormattedMessage id="client.error.invalidMail" defaultMessage="Email inválido" />;
      } else {
        newValidator[name].validate = true;
        newValidator[name].message = '';
      }
      break;
    }
    case 'city':
    case 'district':
    case 'first_name':
    case 'street':
    case 'last_name': {
      const validate = value.length >= 2;
      newValidator[name].validate = validate;
      if (!validate) {
        newValidator[name].message =
          <FormattedMessage id="client.error.atLeastTwoChar" defaultMessage="Campo deve possuir pelo menos dois caracteres" />;
      } else {
        newValidator[name].validate = true;
        newValidator[name].message = '';
      }
      break;
    }
    case 'number': {
      const validate = value.length >= 1;
      newValidator[name].validate = validate;
      if (!validate) {
        newValidator[name].message =
          <FormattedMessage id="client.error.atLeastOneNum" defaultMessage="Campo deve possuir pelo menos um número" />;
      } else {
        newValidator[name].validate = true;
        newValidator[name].message = '';
      }
      break;
    }
    case 'birthdate': {
      const validate = value.length === 10;
      newValidator[name].validate = validate;
      if (!validate) {
        newValidator[name].message = <FormattedMessage id="client.error.dateFormat" defaultMessage="Campo deve estar no formato dd/mm/aaaa" />;
      } else {
        newValidator[name].validate = true;
        newValidator[name].message = '';
      }
      break;
    }
    case 'account_phone': {
      const validate = value.length >= 2;
      newValidator[name].validate = validate;
      if (!validate) {
        newValidator[name].message =
          <FormattedMessage id="client.error.atLeastTwoChar" defaultMessage="Campo deve possuir pelo menos dois caracteres" />;
      }
      break;
    }
    case 'imei': {
      const sosAppId = equipmentList.length > 0 ? equipmentList.find(equipment => equipment.name.toLowerCase() === 'contactto sos - app').id : 0;
      if (client.use_voip && client.equipment_id === sosAppId) {
        const isValid = value.length === 7;
        newValidator[name].validate = isValid;
        if (!isValid) {
          newValidator[name].message = <FormattedMessage id="client.error.appNumber" defaultMessage="Campo deve possuir sete caracteres" />;
        }
      }
      break;
    }
    default:
      break;
  }
  return newValidator;
};

export const validateClientBR = (
equipmentList,
validator,
uniqueFields,
client,
{ name, value }) => {
  const newValidator = validateClientGeneral(
    equipmentList,
    validator,
    uniqueFields,
    client,
    { name, value },
  );
  switch (name) {
    case 'account_phone': {
      if (!client.use_voip) {
        const length = general.removeNonDigit(value).length;
        if ([3, 4, 5].includes(client.equipment_id)) {
          if (length === 11) {
            const validate = validateMobile(
              general.removeNonDigit(value, true),
            );
            newValidator[name].validate = validate;
            newValidator[name].message = '';
            if (!validate) {
              newValidator[name].message = <FormattedMessage id="client.error.invalidCellphone" defaultMessage="Número de celular inválido" />;
            }
          } else {
            newValidator[name].validate = false;
            newValidator[name].message = <FormattedMessage id="client.error.cellPhoneNumbers" defaultMessage="Campo deve possuir 11 números" />;
          }
        } else {
          const validate = length === 10 || length === 11;
          newValidator[name].validate = validate;
          if (!validate) {
            newValidator[name].message = <FormattedMessage id="client.error.regularPhones" defaultMessage="Campo deve possuir 10 ou 11 números" />;
          }
        }
      }
      break;
    }
    case 'zip_code': {
      const validate = general.removeNonDigit(value).length === 8;
      newValidator[name].validate = validate;
      if (!validate) {
        newValidator[name].message = <FormattedMessage id="client.error.zipCode" defaultMessage="Campo deve possuir 8 números" />;
      }
      break;
    }
    case 'cpf': {
      const arr = value.split('');
      const val = arr.filter(el => /\d/.test(el)).join('');
      const mask = '000.000.000-00';
      const valueLength = value.length;
      if (valueLength > 0) {
        if (valueLength < mask.length) {
          newValidator[name].validate = false;
          newValidator[name].message = <FormattedMessage id="client.error.cpfInvalidLen" defaultMessage="O cpf precisa ter 11 dígitos" />;
        } else if (!validateCPF(val)) {
          newValidator[name].validate = false;
          newValidator[name].message = <FormattedMessage id="client.error.cpfInvalid" defaultMessage="Número de cpf inválido" />;
        } else {
          newValidator[name].validate = valueLength === 0 || valueLength === mask.length;
          newValidator[name].message = '';
        }
      } else {
        newValidator[name].validate = valueLength === 0 || valueLength === mask.length;
        newValidator[name].message = '';
      }
      break;
    }
    default:
      break;
  }
  return newValidator;
};

// If the client is from the Mauritius Island, some validations change
export const validateClientMU = (
equipmentList,
validator,
uniqueFields,
client,
{ name, value }) => {
  const newValidator = validateClientGeneral(
    equipmentList,
    validator,
    uniqueFields,
    client,
    { name, value },
  );
  switch (name) {
    case 'account_phone': {
      const length = general.removeNonDigit(value).length;
      if ([3, 4, 5].includes(client.equipment_id)) {
        if (length === 11) {
          newValidator[name].validate = true;
          newValidator[name].message = '';
        } else {
          newValidator[name].validate = false;
          newValidator[name].message = <FormattedMessage id="client.error.cellPhoneNumbersMU" defaultMessage="Campo deve possuir 11 números" />;
        }
      } else {
        const validate = length === 10 || length === 11;
        newValidator[name].validate = validate;
        if (!validate) {
          newValidator[name].message = <FormattedMessage id="client.error.regularPhonesMU" defaultMessage="Campo deve possuir 10 ou 11 números" />;
        }
      }
      break;
    }
    case 'number': {
      newValidator[name].validate = true;
      newValidator[name].message = '';
      break;
    }
    default:
      break;
  }
  return newValidator;
};


// If the client is from the United Kingdom, some validations change
export const validateClientGB = (
equipmentList,
validator,
uniqueFields,
client,
{ name, value }) => {
  const newValidator = validateClientGeneral(
    equipmentList,
    validator,
    uniqueFields,
    client,
    { name, value },
  );
  switch (name) {
    case 'account_phone': {
      const length = general.removeNonDigit(value).length;
      if (length === 12) {
        if ([3, 4, 5].includes(client.equipment_id)) {
          const validate = validateMobileGB(general.removeNonDigit(value, true));
          newValidator[name].validate = true;
          newValidator[name].message = '';
          if (!validate) {
            newValidator[name].validate = false;
            newValidator[name].message = <FormattedMessage id="client.error.invalidCellphone" defaultMessage="Número de celular inválido" />;
          }
        } else {
          newValidator[name].validate = true;
          newValidator[name].message = '';
        }
      } else {
        newValidator[name].validate = false;
        newValidator[name].message = <FormattedMessage id="client.error.phoneNumbersGB" defaultMessage="Campo deve possuir 12 números" />;
      }
      break;
    }
    default:
      break;
  }
  return newValidator;
};

export const validateSecondaryGeneral = (validator, { name, value }) => {
  const newValidator = validator;
  switch (name) {
    case 'first_name':
    case 'last_name': {
      const validate = value.length >= 2;
      newValidator[name].validate = validate;
      if (!validate) {
        newValidator[name].message =
          <FormattedMessage id="client.error.atLeastTwoChar" defaultMessage="Campo deve possuir pelo menos dois caracteres" />;
      } else {
        newValidator[name].validate = true;
        newValidator[name].message = '';
      }
      break;
    }
    case 'birthdate': {
      const validate = value.length === 10;
      newValidator[name].validate = validate;
      if (!validate) {
        newValidator[name].message = <FormattedMessage id="client.error.dateFormat" defaultMessage="Campo deve estar no formato dd/mm/aaaa" />;
      } else {
        newValidator[name].validate = true;
        newValidator[name].message = '';
      }
      break;
    }
    default:
      break;
  }
  return newValidator;
};


export const validateSecondaryBR = (validator, { name, value }) => {
    const newValidator = validateSecondaryGeneral(validator, { name, value });
    switch (name) {
      case 'cpf': {
        const arr = value.split('');
        const val = arr.filter(el => /\d/.test(el)).join('');
        const mask = '000.000.000-00';
        const valueLength = value.length;
        if (valueLength > 0) {
          if (valueLength < mask.length) {
            newValidator[name].validate = false;
            newValidator[name].message = <FormattedMessage id="client.error.cpfInvalidLen" defaultMessage="O cpf precisa ter 11 dígitos" />;
          } else if (!validateCPF(val)) {
            newValidator[name].validate = false;
            newValidator[name].message = <FormattedMessage id="client.error.cpfInvalid" defaultMessage="Número de cpf inválido" />;
          } else {
            newValidator[name].validate = valueLength === 0 || valueLength === mask.length;
            newValidator[name].message = '';
          }
        } else {
          newValidator[name].validate = valueLength === 0 || valueLength === mask.length;
          newValidator[name].message = '';
        }
        break;
      }
      default:
        break;
    }
    return newValidator;
  };
