import React from 'react';
import PropTypes from 'prop-types';
import { checkMonth } from '../../BLL/dictionary';
import messages from '../../BLL/locale/messages';

import '../../css/contactto.scss';

import CustomChart from './CustomChart';

const MonthlyFollowupChart = ({ height, report, width }) => {
  const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
  const added = [];
  const inactivated = [];
  const reactivated = [];
  const disabled = [];
  const month = [];

  const data = [
    ...report.map(rpt => [
      month.push(checkMonth(rpt.month)),
      added.push(rpt.added ? rpt.added : 0),
      inactivated.push(rpt.inactivations ? rpt.inactivations : 0),
      reactivated.push(rpt.reactivations ? rpt.reactivations : 0),
      disabled.push(rpt.disabled ? rpt.disabled : 0),
    ]),
  ];

  const datasets = [
    {
      label: messages[lang]['reports.userTracking.added'],
      backgroundColor: '#46b474',
      backgroundColorHover: '#46b474',
      data: added,
      fill: true,
    },
    {
      label: messages[lang]['reports.userTracking.inactivated'],
      backgroundColor: '#ef5d58',
      backgroundColorHover: '#ef5d58',
      data: inactivated,
      fill: true,
    },
    {
      label: messages[lang]['reports.userTracking.reactivated'],
      backgroundColor: '#095289',
      backgroundColorHover: '#095289',
      data: reactivated,
      fill: true,
    },
    {
      label: messages[lang]['reports.userTracking.disabled'],
      backgroundColor: '#808285',
      backgroundColorHover: '#808285',
      data: disabled,
      fill: true,
    },
  ];

  return (
    <div className="left" style={{ height, width, maxHeight: '600px' }}>
      <CustomChart labels={month} data={datasets} isMultiBar />
    </div>
  );
};

MonthlyFollowupChart.defaultProps = {
  height: '350px',
  width: '350px',
};

MonthlyFollowupChart.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

export default MonthlyFollowupChart;
