import * as types from '../actions/actionTypes';
import initialState from './initialState';


export default function attendsDashboardReducer(state = initialState.attendsDashboard, action) {
  let openAttends = [];
  let newState = {};
  switch (action.type) {
    case types.LOAD_DASHBOARD_SUCCESS:
      return {
        attendsOpen: action.open,
        attendsClosed: action.closed,
      };
    case types.LOAD_OPEN_DASHBOARD_SUCCESS:
      newState = Object.assign({}, state, { attendsOpen: action.payload });
      return newState;
    case types.NEW_ATTEND_DASHBOARD:
      openAttends = [action.attend, ...state.attendsOpen];
      // console.log(state.attendsOpen.filter(att => att.id !== action.attend.id));
      // openAttends = state.attendsOpen.filter(att => att.id !== action.attend.id).concat([action.attend]);
      newState = Object.assign({}, state, { attendsOpen: openAttends });
      return newState;
    case types.UPDATE_ATTEND_DASHBOARD:
      openAttends = state.attendsOpen;
      newState = Object.assign({}, state, {
        attendsOpen: openAttends.map((attend) => { 
          if (attend.id === action.attend.id) {
            return action.attend;
          }
          return attend;
        }),
      });
      return newState;
    case types.CLOSE_ATTEND_DASHBOARD: {
      openAttends = state.attendsOpen;
      let closedAttends = [...state.attendsClosed];
      const attIndex = closedAttends.findIndex(att => att.id === action.attend.id);
      if (attIndex === -1) {
        closedAttends = [action.attend, ...closedAttends];
      }

      newState = Object.assign(
        {},
        state,
        { attendsOpen: openAttends.filter(attend => attend.id !== action.attend.id) },
        { attendsClosed: closedAttends.sort((attA, attB) => attA.id > attB.id ) },
      );
      return newState;
    }
    default:
      return state;
  }
}
