import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import ReportAttendByTagRow from './ReportAttendByTagRow';
import Paginate from '../../../Common/Paginate';
import Order from '../../../../icons/Order';
import Loading from '../../../../icons/Loading';
import Download from '../../../../icons/Download';
import { host, getDomain, getToken } from '../../../../BLL/environment';
import { naturalSort } from '../../../../BLL/general';
import messageTags from '../../../../BLL/locale/messagesTags';
import style from './reportAttend.module.scss';

const ReportAttendBytTag = (props) => {
  const numPerPage = 50;
  const [report, setReport] = useState(undefined);
  const [reportTag, setReportTag] = useState('');
  const [page, setPage] = useState(1);
  const [ordering, setOrdering] = useState('-id');
  const [loading, setLoading] = useState(true);
  const [csvUrl, setCsvUrl] = useState('');
  const [pdfUrl, setPdfUrl] = useState('');

  useEffect(() => {
    if (props.match.params.filter) {
      fetchAttendsReport(props.match.params.filter);
    }
  }, [props.match.params.filter]);

  const fetchAttendsReport = (filter) => {
    axios({
      method: 'GET',
      url: `https://${getDomain()}${host()}/api/reports/attends-tags/?${filter}`,
      headers: { Authorization: `Token ${getToken()}` },
    }).then((resp) => {
      setReport(resp.data.data.sort((a, b) => b.attend_id - a.attend_id));
      setCsvUrl(resp.data.csv_url);
      setPdfUrl(resp.data.pdf_url);
      setPage(1);
      setLoading(false);
      setReportTag(resp.data.data && resp.data.tag_id);
    }).catch((err) => {
      console.log(err.response);
    })
  };

  const handleIdChange = (newOrder) => {
    if (ordering !== newOrder) {
      setReport(report.sort((a, b) => a.attend_id - b.attend_id));
      setOrdering(newOrder);
    } else {
      setReport(report.sort((a, b) => b.attend_id - a.attend_id));
      setOrdering(`-${newOrder}`);
    }
  };

  const handleOrderChange = (newOrder) => {
    if (ordering !== newOrder) {
      setReport(report.sort((a, b) => {
        const nameA = `${a[newOrder].toLowerCase()}${a.attend_id}`
        const nameB = `${b[newOrder].toLowerCase()}${b.attend_id}`;
        return naturalSort(nameA, nameB)
      }));
      setOrdering(newOrder);
    } else {
      setReport(report.sort((a, b) => {
        const nameA = `${a[newOrder].toLowerCase()}${a.attend_id}`
        const nameB = `${b[newOrder].toLowerCase()}${b.attend_id}`;
        return naturalSort(nameB, nameA)
      }));
      setOrdering(`-${newOrder}`);
    }
  };

  const firstItem = (page - 1) * numPerPage;
  const lastItem = page * numPerPage;
  const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
  const tagName = messageTags[lang][reportTag];

  return (
    <div className="contactto-middle-content scroll print">
      <div className={`${style.title} col-sm-12 col-md-9 row`}>
        <FormattedMessage id="reports.reportOf" defaultMessage="RELATÓRIO DE" /> &nbsp;
        <FormattedMessage id="reports.attendHeader" defaultMessage="ATENDIMENTOS" />&nbsp;
          <FormattedMessage id="reports.ofType" defaultMessage="DO TIPO" />&nbsp;
        <br/><span className={style.subtitle}>{tagName}</span>
      </div>
      <div className="col-sm-12 col-md-3 row">
        <div style={{ marginLeft: '5px' }}>
          <a href={csvUrl}>
            <div className={`${style.download} no-print`} title="Download">
              <Download fill="#fff" width="33px" height="33px" />
            </div>
          </a>
        </div>
        <div style={{ marginLeft: '5px' }}>
          <a href={pdfUrl}>
            <div className={`${style.download} no-print`} title="PDF" style={{ padding: '8px' }}>
              <span style={{ color: "#fff" }}><i className="far fa-file-pdf fa-lg" /></span>
            </div>
          </a>
        </div>
      </div>
      {loading
        ? <Loading text={<FormattedMessage id="attend.list.loading" defaultMessage="Carregando" />} />
        : report && 
          <div className={`${style.table} print`}>
            <Paginate
              outerClass={style.paginate}
              numberOfElements={report.length}
              numPerPage={numPerPage}
              setPage={(elm) => setPage(elm)}
              page={page}
            />
            <div className={style.header}>
              <div className={style.id}>
                <button
                  className={style['header-column']}
                  onClick={() => handleIdChange('id')}
                  style={{ color: ordering === 'id' ? '#00b8cb' : '#333' }}
                >
                  ID
                  <Order
                    width="15"
                    height="10"
                    className={`${ordering === 'id' ? style.rotate : ''}  no-print`} 
                  />
                </button>
              </div>
              <div className={style.client}>
                <button
                  className={style['header-column']}
                  onClick={() => handleOrderChange('client')}
                  style={{ color: ordering === 'client' ? '#00b8cb' : '#333' }}
                >
                  <FormattedMessage id="reports.client" defaultMessage="Usuário" /><Order
                    width="15"
                    height="10"
                    className={`${ordering === 'client' ? style.rotate : ''}  no-print`} 
                  />
                </button>
              </div>
              <div className={style.account}>
                <button
                  className={style['header-column']}
                  onClick={() => handleOrderChange('account')}
                  style={{ color: ordering === 'account' ? '#00b8cb' : '#333' }}
                >
                  <FormattedMessage id="reports.account" defaultMessage="Conta" />
                  <Order
                    width="15"
                    height="10"
                    className={`${ordering === 'account' ? style.rotate : ''}  no-print`} 
                  />
                </button>
              </div>
              <div className={style.client}>
                <button
                  className={style['header-column']}
                  onClick={() => handleOrderChange('city')}
                  style={{ color: ordering === 'city' ? '#00b8cb' : '#333' }}
                >
                  <FormattedMessage id="reports.city" defaultMessage="Cidade" /><Order
                    width="15"
                    height="10"
                    className={`${ordering === 'city' ? style.rotate : ''}  no-print`} 
                  />
                </button>
              </div>
              <div className={style.dateOpen}><FormattedMessage id="reports.opening" defaultMessage="Abertura" /></div>
              <div className={style.dateClosed}><FormattedMessage id="reports.closure" defaultMessage="Encerramento" /></div>
              <div className={style.attendant}><FormattedMessage id="reports.attendant" defaultMessage="Atendente" /></div>
              <div className={style.attendant}><FormattedMessage id="reports.company" defaultMessage="Empresa" /></div>
              <div className={style.audioCall}><FormattedMessage id="reports.attends.hasAudio" defaultMessage="Áudio" /></div>
            </div>
            <div className={`${style.body} no-print`}>
              {report.length > 0 ? report.slice(firstItem, lastItem)
                .map(rpt =>
                  <ReportAttendByTagRow key={rpt.attend_id} report={rpt} />,
                )
                :
              <div className={style.row}><FormattedMessage id="reports.emptyReport" defaultMessage="Relatório vazio" /></div>
              }
            </div>
          </div>
      }
      
    </div>
  );
};


ReportAttendBytTag.defaultProps = {
  user: undefined,
};

ReportAttendBytTag.propTypes = {
  params: PropTypes.shape({
    filter: PropTypes.string.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    groups: PropTypes.arrayOf(
      PropTypes.number,
    ),
    is_active: PropTypes.bool,
    is_staff: PropTypes.bool,
    is_superuser: PropTypes.bool,
    user_info: PropTypes.shape({
      id: PropTypes.number,
      user: PropTypes.number,
      phone: PropTypes.string,
      mobile: PropTypes.string,
      email: PropTypes.string,
      extension_id: PropTypes.number,
    }),
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        priority: PropTypes.number,
        user: PropTypes.number,
        receive_sms: PropTypes.bool,
        receive_email: PropTypes.bool,
      }),
    ),
  }),
};


export default ReportAttendBytTag;
