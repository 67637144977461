import React from 'react';
import PropTypes from 'prop-types';
import formStyle from '../../css/form.module.scss';
import '../../css/formPage.scss';

const InputContainer = ({
  id,
  maxLength,
  name,
  onChange,
  title,
  type,
  validator,
  value,
  width,
  disabled,
  isRequired,
}) => (
    <div className="form-group" style={{ width: `${width}%` }}>
      <label className="text-muted" htmlFor={name}>
        {title}
        {isRequired && <span className={formStyle.required}>*</span>}
      </label>
      <input
        id={!id ? name : id}
        className={`form-control ${
          validator && !validator.validate ? formStyle['input--error'] : ''
          } ${disabled ? formStyle.disabled : ''}`}
        maxLength={maxLength}
        name={name}
        onChange={onChange}
        type={type}
        value={value}
        disabled={disabled}
      />
      {validator &&
        !validator.validate &&
        width === 100 && (
          <div className={`${formStyle.message} form-text-error`}>
            {validator.message}
          </div>
        )}
      {validator &&
        !validator.validate &&
        width < 100 && (
          <div className={`${formStyle.message} form-text-error`}>
            {validator.message}
          </div>
        )}
    </div>
  );

InputContainer.defaultProps = {
  id: '',
  maxLength: '',
  type: 'text',
  validator: null,
  width: 100,
  disabled: false,
  isRequired: false,
};

InputContainer.propTypes = {
  id: PropTypes.string,
  isRequired: PropTypes.bool,
  maxLength: PropTypes.string,
  name: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  validator: PropTypes.shape({
    validate: PropTypes.bool,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
  value: PropTypes.node.isRequired,
  width: PropTypes.number,
  disabled: PropTypes.bool,
};

export default InputContainer;
