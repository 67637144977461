import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import style from './reportClientByCompany.module.scss';

const ReportClientByCompanyRow = ({ report, filterDate }) => (
  <Link to={`/app/reports/list/clients-company-details/${report.company_id}/${filterDate}`} className={style.row}>
    <div className={style.name}>{report.company}</div>
    <div className={style['id-header']}>{report.company_id}</div>
    <div className={style['total-clients']}>{report.total}</div>
  </Link>
);

ReportClientByCompanyRow.propTypes = {
  report: PropTypes.shape({
    company: PropTypes.string,
    company_id: PropTypes.number,
    total: PropTypes.number,
  }).isRequired
};

export default ReportClientByCompanyRow;
