import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import LengthOfStayChart from '../../../Charts/LengthOfStayChart';
import ReportLengthStayRow from './ReportLengthOfStayRow';
import Paginate from '../../../Common/Paginate';
import Loading from '../../../../icons/Loading';
import Download from '../../../../icons/Download';
import { host, getDomain, getToken } from '../../../../BLL/environment';
import messages from '../../../../BLL/locale/messages';

import style from './reportLengthOfStay.module.scss';

const ReportLengthOfStay = (props) => {
  const lang = !localStorage.contacttoLang ? 'pt' : localStorage.contacttoLang;
  const numPerPage = 50;
  const [report, setReport] = useState(undefined);
  const [page, setPage] = useState(1);
  const [ordering, setOrdering] = useState('-id');
  const [loading, setLoading] = useState(true);
  const [csvUrl, setCsvUrl] = useState('');
  const [pdfUrl, setPdfUrl] = useState('');
  const [showChart, setShowChart] = useState(false);

  useEffect(() => {
    fetchLoSReport();
  }, []);

  const fetchLoSReport = () => {
    axios({
      method: 'GET',
      url: `https://${getDomain()}${host()}/api/reports/clients-length/`,
      headers: { Authorization: `Token ${getToken()}` },
    }).then((resp) => {
      setReport(resp.data.report);
      setCsvUrl(resp.data.csv_url);
      setPdfUrl(resp.data.pdf_url);
      setLoading(false);
    })
  };

  const firstItem = (page - 1) * numPerPage;
  const lastItem = page * numPerPage;

  return (
    <div className="contactto-middle-content scroll print">
      <div className={`${style.title} col-sm-12 col-md-6 row`}>
        <FormattedMessage id="reports.reportOf" defaultMessage="RELATÓRIO DE" />&nbsp; <span style={{ color: '#999' }}>
          <FormattedMessage id="reports.length.lengthOfStayHeader" defaultMessage="TEMPO DE PERMANÊNCIA DOS USUÁRIOS" />
        </span>
      </div>
      <div className="col-sm-12 col-md-6 row">
        <div style={{ marginLeft: '5px' }}>
          <button
            type="button"
            className={`${style.download} no-print`}
            onClick={() => setShowChart(!showChart)}
            style={{ border: 'solid 1px #efefef' }}
            title={`${showChart ? messages[lang]['reports.reportButton'] : messages[lang]['reports.graphicButton']}`}
          >
            <i
              className={`far ${
                showChart ? 'fa-file-alt' : 'fa-chart-bar'
                } fa-lg`}
              aria-hidden="true"
              style={{ transition: '.3s ease' }}
            />
          </button>
        </div>
        <div style={{ marginLeft: '5px' }}>
          <a href={csvUrl}>
            <div className={`${style.download} no-print`} title="Download">
              <Download fill="#fff" width="33px" height="33px" />
            </div>
          </a>
        </div>
        <div style={{ marginLeft: '5px' }}>
          <a href={pdfUrl}>
            <div className={`${style.download} no-print`} title="PDF" style={{ padding: '8px' }}>
              <span style={{ color: "#fff" }}><i className="far fa-file-pdf fa-lg" /></span>
            </div>
          </a>
        </div>
      </div>
      {loading
        ? <Loading text={<FormattedMessage id="attend.list.loading" defaultMessage="Carregando" />} />
        : (report &&
            (!showChart
              ?
                <div className={`${style.table} print`}>
                  <Paginate
                    outerClass={style.paginate}
                    numberOfElements={report.length}
                    numPerPage={numPerPage}
                    setPage={(elm) => setPage(elm)}
                    page={page}
                  />
                  <div className={style.header}>
                    <div className={`${style['header-column']} ${style.account}`}>
                      <FormattedMessage id="general.accountNumber" defaultMessage="Nº da Conta" />
                    </div>
                    <div className={`${style['header-column']} ${style.user}`}>
                      <FormattedMessage id="reports.client" defaultMessage="Usuário" />
                    </div>
                    <div className={`${style['header-column']} ${style.day}`}>
                      <FormattedMessage id="reports.length.daysLenght" defaultMessage="Dias de Permanência" />
                    </div>
                    <div className={`${style['header-column']} ${style['date-created']}`}>
                      <FormattedMessage id="reports.length.createdDate" defaultMessage="Data de Criação" />
                    </div>
                    <div className={`${style['header-column']} ${style['date-deleted']}`}>
                      <FormattedMessage id="reports.length.deletedDate" defaultMessage="Data de Deleção" />
                    </div>
                  </div>
                  <div className={`${style.body} no-print`}>
                    {report.length > 0 ? (
                      report
                        .slice(firstItem, lastItem)
                        .map(rpt => (
                          <ReportLengthStayRow key={rpt.account} report={rpt} />
                        ))
                      ) :
                          <div className={style.body}>
                            <FormattedMessage id="reports.emptyReport" defaultMessage="Relatório vazio" />
                          </div>
                    }
                  </div>
                </div>
              :
                <div>
                  {ReportLengthStayRow.length > 0 && (
                    <LengthOfStayChart
                      report={report}
                      width="100%"
                      height={`${
                        35 * report.length < 600 ? 600 : 35 * report.length
                        }px`}
                    />
                  )}
                </div>
            )
          )
      }
    </div>
  );

};

ReportLengthOfStay.defaultProps = {
  user: undefined,
};

ReportLengthOfStay.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.number),
    is_active: PropTypes.bool,
    is_staff: PropTypes.bool,
    is_superuser: PropTypes.bool,
    user_info: PropTypes.shape({
      id: PropTypes.number,
      user: PropTypes.number,
      phone: PropTypes.string,
      mobile: PropTypes.string,
      email: PropTypes.string,
      extension_id: PropTypes.number,
    }),
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        priority: PropTypes.number,
        user: PropTypes.number,
        receive_sms: PropTypes.bool,
        receive_email: PropTypes.bool,
      }),
    ),
  }),
};

export default ReportLengthOfStay;
