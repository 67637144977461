import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useSelector, RootStateOrAny } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { getDomain, host, getToken } from '../../../BLL/environment';
import * as general from '../../../BLL/general';
import * as format from '../../../BLL/format';
import ModalReturnCall from '../../Modal/ModalReturnCall';
import countryList from '../../../BLL/locale/messagesCountries'
import FemaleIcon from '../../../icons/FemaleIcon'
import MaleIcon from '../../../icons/MaleIcon';
import UnisexIcon from '../../../icons/TransexIcon';
import Loading from '../../../icons/Loading';

import style from './clientDetail.module.scss';

import '../../../css/middle.scss';
import '../../../css/table.scss';

import '../../../css/detailPage.scss';
import '../../../css/block.scss';

const ClientDetail = (props) => {
  const { attendantList, lang, equipment, isSysAdm } = useSelector(
    (state: RootStateOrAny) => ({
      attendantList: state.attendants,
      lang: state.locale.lang,
      equipment: state.equipment.length > 0 ? state.equipment[0].data : [],
      isSysAdm: state.loggedAttendant.groups.some(group => group === 1 || group === 4)
    })
  );

  const [client, setClient] = useState(undefined);
  const [secondaryClient, setSecondaryClient] = useState(undefined);
  const [userAudioList, setUserAudioList] = useState([])
  const [userAudioCount, setUserAudioCount] = useState(0);
  const [userAudioNext, setUserAudioNext] = useState(undefined);
  const [userAudioPrev, setUserAudioPrev] = useState(undefined);
  const [showReturnCallModal, setShowReturnCallModal] = useState(false);

  useEffect(() => {
    const clientId = parseInt(props.match.params.id, 10);
    axios.get(`https://${getDomain()}${host()}/api/clients/${clientId}/`, {
      headers: { Authorization: `Token ${getToken()}` },
    })
      .then((res) => {
        setClient(res.data)
        const secondary = res.data?.secondary[0];
        if (secondary) {
          setSecondaryClient(secondary);
        }
      })
      .catch(error => console.log(error));
  }, [props.match.params]);

  useEffect(() => {
    if (client) {
      const url = `https://${getDomain()}${host()}/api/clients/${client.id}/extension-audio/?limit=3`;
      axios.get(url, {
        headers: { Authorization: `Token ${getToken()}` },
      })
        .then((res) => {
          if (res.data) {
            setUserAudioCount(res.data.count);
            setUserAudioList(res.data.results);
            setUserAudioNext(res.data.next);
            setUserAudioPrev(res.data.previous);
          }
        })
        .catch(error => console.log(error))
    }
  }, [client]);

  if (!client) {
    return (
      <Loading text={<FormattedMessage id="attend.list.loading" defaultMessage="Carregando" />} />
    );
  }

  const attendants = attendantList.length > 0 ? [...attendantList[0].data] : [];
  const country = client && countryList[lang]?.find(ctry => (
    ctry.code === client.country
  ));
  const creator = client ?
      attendants.filter(attendant => attendant.id === client.created_by)[0] : undefined;
  const modifier = client ?
    attendants.filter(attendant => attendant.id === client.modified_by)[0] : undefined;
  const clientEquipment = equipment.length > 0 ? equipment.find(equip => equip.id === client.equipment_id) : undefined;
  const canMakeCall = (client.use_voip && clientEquipment?.name.includes('PERS')) || clientEquipment?.name.toLowerCase().includes('climax');

  console.log(secondaryClient);

  return (
    <div className="contactto-middle-content">
      {showReturnCallModal &&
        <ModalReturnCall
          clientPhone={client.account_phone}
          clientAccount={client.account}
          startCall={props.startCall}
          sendPERSPassword={props.sendPERSPassword}
          hangupSip={props.hangupSip}
          muteAudio={props.muteAudio}
          mutePhone={props.mutePhone}
          autoCall={() => props.sendDTMF(clientEquipment?.name.toLowerCase().includes('climax') ? 2 : 8)}
          sipStackType={props.sipStackType}
          closeModal={() => setShowReturnCallModal(!showReturnCallModal)}
          isIndependent={true}
          equipment={clientEquipment}
        />
      }
      <div className="detail-container">
        <div className="detail-title">
          <FormattedMessage id="client.detail.mainHeader" defaultMessage="VISUALIZAR INFORMAÇÕES " /> - {client.account}
        </div>
        <div className="detail-info-header col-sm-12 row" style={{ marginBottom: '15px' }}>
          <div className="col-sm-12 col-md-6">
            {canMakeCall && !showReturnCallModal &&
              <div className={style['detail-button-container']}>
                <button
                  onClick={() => setShowReturnCallModal(!showReturnCallModal)}
                  type="button"
                  className={style['detail-call-btn']}
                >
                  <FormattedMessage id="client.detail.indepCall" defaultMessage="Realizar chamada independente" />
                </button>
              </div>
            }
          </div>
        </div>

        <div className="detail-info">
          <div className="block">
            <div className="block-title"><FormattedMessage id="client.form.personalInfoHeader" defaultMessage="INFORMAÇÕES DA CONTA" /></div>
            <div className="col-12 row" style={{ padding: '1rem 0' }}>
              <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-4 block-field">
                  <FormattedMessage id="client.detail.accountNumber" defaultMessage="Número de Conta" />:</div>
                <div className="col-sm-12 col-md-8 block-value">{client.account}</div>
              </div>
              <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-4 block-field">
                  <FormattedMessage id="client.detail.testUser" defaultMessage="Usuário de testes" />:</div>
                <div className="col-sm-12 col-md-8 block-value">
                  {client.is_test
                    ? <FormattedMessage id="client.detail.testUserYes" defaultMessage="Sim" />
                    : <FormattedMessage id="client.detail.testUserNo" defaultMessage="Não" />
                  }
                </div>
              </div>
              <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-4 block-field">Keybox: </div>
                <div className="col-sm-12 col-md-8 block-value">
                  {client.key_box
                    ? client.key_box
                    : <FormattedMessage id="client.detail.doesNotHave" defaultMessage="Não Possui" />
                  }
                </div>
              </div>
              <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-4 block-field">
                  <FormattedMessage id="inputContainer.equipment" defaultMessage="Equipamento: " />
                </div>
                <div className="col-sm-12 col-md-8 block-value">{clientEquipment.name}</div>
              </div>
              <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-4 block-field">IMEI: </div>
                <div className="col-sm-12 col-md-8 block-value">{client.imei ? client.imei : '-'}</div>
              </div>
              <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-4 block-field">
                  <FormattedMessage id="inputContainer.useVoIP" defaultMessage="Usa VoIP: " />
                </div>
                <div className="col-sm-12 col-md-8 block-value">
                  {client.use_voip
                    ? <FormattedMessage id="client.detail.testUserYes" defaultMessage="Sim" />
                    : <FormattedMessage id="client.detail.testUserNo" defaultMessage="Não" />
                  }
                </div>
              </div>
              <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-4 block-field">
                  <FormattedMessage id="inputContainer.accountPhone" defaultMessage="Número do equipamento" />
                </div>
                <div className="col-sm-12 col-md-8 block-value">
                  {client.use_voip 
                    ? client.account_phone
                    : general.renderPhoneDigit(client.account_phone, client.country)
                  }
                </div>
              </div>
              <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-4 block-field">
                  <FormattedMessage id="inputContainer.company" defaultMessage="Empresa: " />
                </div>
                <div className="col-sm-12 col-md-8 block-value">
                  {client.group_id ? client.group_name : '-'}
                </div>
              </div>
              <div className="col-sm-12 col-md-12" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-2 block-field">
                  <FormattedMessage id="inputContainer.internalComments" defaultMessage="Comentários internos: " />
                </div>
                <div className="col-sm-12 col-md-10 block-value">
                  {client.internal_comments
                    ? <>{client.internal_comments}</>
                    : <FormattedMessage id="client.detail.doesNotHave" defaultMessage="Não Possui" />
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="block">
            <div className="block-title"><FormattedMessage id="client.form.addressHeader" defaultMessage="ENDEREÇO" /></div>
            <div className="col-12 row" style={{ padding: '1rem 0' }}>
              <div className="col-sm-12 col-md-8" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-3 block-field">
                  <FormattedMessage id="client.detail.street" defaultMessage="Logradouro" />:
                </div>
                <div className="col-sm-12 col-md-9 block-value">{client.street}</div>
              </div>
              <div className="col-sm-12 col-md-4" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-6 block-field">
                  <FormattedMessage id="general.country" defaultMessage="País" />: 
                </div>
                <div className="col-sm-12 col-md-6 block-value">
                  {
                    country ? country.name : '-'
                  }
                </div>
              </div>

              <div className="col-sm-12 col-md-4" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-6 block-field">
                  <FormattedMessage id="client.detail.number" defaultMessage="Número" />:
                </div>
                <div className="col-sm-12 col-md-6 block-value">{client.number === 0 ? '-' : client.number}</div>
              </div>
              <div className="col-sm-12 col-md-4" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-6 block-field">
                  <FormattedMessage id="client.detail.complement" defaultMessage="Complemento" />:
                </div>
                <div className="col-sm-12 col-md-6 block-value">{client.complement ? client.complement : '-'}</div>
              </div>
              <div className="col-sm-12 col-md-4" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-6 block-field">
                  <FormattedMessage id="client.detail.district" defaultMessage="Bairro" />:
                </div>
                <div className="col-sm-12 col-md-6 block-value">{client.district}</div>
              </div>
              <div className="col-sm-12 col-md-4" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-6 block-field">
                  <FormattedMessage id="client.detail.zipCode" defaultMessage="CEP" />:
                </div>
                <div className="col-sm-12 col-md-6 block-value">{client.zip_code ? general.setMask(client.zip_code, '00000-000') : '-'}</div>
              </div>
              <div className="col-sm-12 col-md-4" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-6 block-field">
                  <FormattedMessage id="client.detail.city" defaultMessage="Cidade" />:
                </div>
                <div className="col-sm-12 col-md-6 block-value">{client.city}</div>
              </div>
              <div className="col-sm-12 col-md-4" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-6 block-field">
                  <FormattedMessage id="client.detail.state" defaultMessage="Estado" />:
                </div>
                <div className="col-sm-12 col-md-6 block-value">{client.state}</div>
              </div>
              <div className="col-sm-12 col-md-8" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-3 block-field">
                  <FormattedMessage id="client.detail.contactPhone" defaultMessage="Telefone de contato" /> 1:
                </div>
                <span className="block-value">
                  {client.contact_phone
                    ? general.renderPhoneDigit(client.contact_phone, client.country)
                    : <FormattedMessage id="client.detail.doesNotHave" defaultMessage="Não Possui" />
                  }
                </span>
              </div>
              <div className="col-sm-12 col-md-4" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-6 block-field">
                  <FormattedMessage id="client.detail.contactPhone" defaultMessage="Telefone de contato" /> 2:
                </div>
                <span className="block-value">
                  {client.extra_phone
                    ? general.renderPhoneDigit(client.extra_phone, client.country)
                    : <FormattedMessage id="client.detail.doesNotHave" defaultMessage="Não Possui" />
                  }
                </span>
              </div>
            </div>
          </div>

          <div className="block">
            <div className="block-title"><FormattedMessage id="client.form.primaryUserInfo" defaultMessage="INFORMAÇÕES PESSOAIS USUÁRIO - 1" /></div>
            <div className="col-12 row" style={{ padding: '1rem 0' }}>
              <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-4 block-field">
                  <FormattedMessage id="client.detail.name" defaultMessage="Nome completo" />:</div>
                <div className="col-sm-12 col-md-8 block-value">{client.first_name} {client.last_name}</div>
              </div>
              <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-4 block-field">
                  <FormattedMessage id="client.detail.gender" defaultMessage="Sexo" />:</div>
                <div className="col-sm-12 col-md-8 block-value">
                  {client.gender === 'm' && <FormattedMessage id="client.form.male" defaultMessage="Masculino" />}
                  {client.gender === 'f' && <FormattedMessage id="client.form.female" defaultMessage="Feminino" />}
                  {client.gender !== 'f' && client.gender !== 'm' && <FormattedMessage id="client.form.notApplicable" defaultMessage="Não aplicável" />}
                </div>
              </div>
              <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-4 block-field">
                  <FormattedMessage id="client.detail.birthdate" defaultMessage="Data de nascimento" />:
                </div>
                <div className="col-sm-12 col-md-8 block-value">{format.date(client.birthdate)}</div>
              </div>
              
              <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-4 block-field">
                  <FormattedMessage id="client.detail.age" defaultMessage="Idade" />:
                </div>
                <div className="col-sm-12 col-md-8 block-value">{moment().year() - moment(client.birthdate).year()}</div>
              </div>

              <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-4 block-field">CPF: </div>
                <div className="col-sm-12 col-md-8 block-value">
                  {client.cpf
                    ? general.setMask(client.cpf, '000.000.000-00')
                    : <FormattedMessage id="client.detail.doesNotHave" defaultMessage="Não Possui" />
                  }
                </div>
              </div>
              <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-4 block-field">RG: </div>
                <div className="col-sm-12 col-md-8 block-value">
                  {client.rg
                    ? client.rg
                    : <FormattedMessage id="client.detail.doesNotHave" defaultMessage="Não Possui" />
                  }
                </div>
              </div>
              
              <div className="col-sm-12 col-md-12" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-2 block-field">
                  <FormattedMessage id="inputContainer.observations" defaultMessage="Observações: " /></div>
                <div className="col-sm-12 col-md-10 block-value">
                  {client.general_info
                    ? client.general_info
                    : <FormattedMessage id="client.detail.doesNotHave" defaultMessage="Não Possui" />}
                </div>
              </div>
              <div className="col-sm-12 col-md-12" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-2 block-field">
                  <FormattedMessage id="inputContainer.healthPlan" defaultMessage="Plano de Saúde: " />
                </div>
                <div className="col-sm-12 col-md-10 block-value">
                  {client.health_plan
                    ? client.health_plan
                    : <FormattedMessage id="client.detail.doesNotHave" defaultMessage="Não Possui" />}
                </div>
              </div>
              <div className="col-sm-12 col-md-12" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-2 block-field">Hospital:</div>
                <div className="col-sm-12 col-md-10 block-value">
                  {client.hospital
                    ? client.hospital
                    : <FormattedMessage id="client.detail.doesNotHave" defaultMessage="Não Possui" />}
                </div>
              </div>
              <div className="col-sm-12 col-md-12" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-2 block-field">
                  <FormattedMessage id="inputContainer.limitations" defaultMessage="Limitações: " />
                </div>
                <div className="col-sm-12 col-md-10 block-value">
                  {client.limitations
                    ? client.limitations
                    : <FormattedMessage id="client.detail.doesNotHave" defaultMessage="Não Possui" />}
                </div>
              </div>
              <div className="col-sm-12 col-md-12" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-2 block-field">
                  <FormattedMessage id="inputContainer.medications" defaultMessage="Medicações: " />
                </div>
                <div className="col-sm-12 col-md-10 block-value">
                  {client.medications
                    ? client.medications
                    : <FormattedMessage id="client.detail.doesNotHave" defaultMessage="Não Possui" />}
                </div>
              </div>
              <div className="col-sm-12 col-md-12" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-2 block-field">
                  <FormattedMessage id="inputContainer.removalPlan" defaultMessage="Plano de Remoção: " />
                </div>
                <div className="col-sm-12 col-md-10 block-value">
                  {client.removal_plan
                    ? client.removal_plan
                    : <FormattedMessage id="client.detail.doesNotHave" defaultMessage="Não Possui" />}
                </div>
              </div>
            </div>
          </div>
          {secondaryClient &&
            <div className="block">
              <div className="block-title"><FormattedMessage id="client.form.secondaryUserInfo" defaultMessage="INFORMAÇÕES PESSOAIS USUÁRIO - 2" /></div>
              <div className="col-12 row" style={{ padding: '1rem 0' }}>
                <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                  <div className="col-sm-12 col-md-4 block-field">
                    <FormattedMessage id="client.detail.name" defaultMessage="Nome completo" />:</div>
                  <div className="col-sm-12 col-md-8 block-value">{secondaryClient.first_name} {secondaryClient.last_name}</div>
                </div>
                <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                  <div className="col-sm-12 col-md-4 block-field">
                    <FormattedMessage id="client.detail.gender" defaultMessage="Sexo" />:</div>
                  <div className="col-sm-12 col-md-8 block-value">
                    {secondaryClient.gender === 'm' && <FormattedMessage id="client.form.male" defaultMessage="Masculino" />}
                    {secondaryClient.gender === 'f' && <FormattedMessage id="client.form.female" defaultMessage="Feminino" />}
                    {secondaryClient.gender !== 'f' && secondaryClient.gender !== 'm' && <FormattedMessage id="client.form.notApplicable" defaultMessage="Não aplicável" />}
                  </div>
                </div>
                <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                  <div className="col-sm-12 col-md-4 block-field">
                    <FormattedMessage id="client.detail.birthdate" defaultMessage="Data de nascimento" />:</div>
                  <div className="col-sm-12 col-md-8 block-value">{format.date(secondaryClient.birthdate)}</div>
                </div>
                <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                  <div className="col-sm-12 col-md-4 block-field">
                    <FormattedMessage id="client.detail.age" defaultMessage="Idade" />:
                  </div>
                  <div className="col-sm-12 col-md-8 block-value">{moment().year() - moment(secondaryClient.birthdate).year()}</div>
                </div>
                <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-4 block-field">CPF: </div>
                <div className="col-sm-12 col-md-8 block-value">
                  {secondaryClient.cpf
                    ? general.setMask(secondaryClient.cpf, '000.000.000-00')
                    : <FormattedMessage id="client.detail.doesNotHave" defaultMessage="Não Possui" />
                  }
                </div>
              </div>
              <div className="col-sm-12 col-md-6" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-4 block-field">RG: </div>
                <div className="col-sm-12 col-md-8 block-value">
                  {secondaryClient.rg
                    ? secondaryClient.rg
                    : <FormattedMessage id="client.detail.doesNotHave" defaultMessage="Não Possui" />
                  }
                </div>
              </div>
                <div className="col-sm-12 col-md-12" style={{ borderBottom: '1px solid #babbbd' }}>
                  <div className="col-sm-12 col-md-2 block-field">
                    <FormattedMessage id="inputContainer.observations" defaultMessage="Observações: " /></div>
                  <div className="col-sm-12 col-md-10 block-value">
                    {secondaryClient.general_info
                      ? secondaryClient.general_info
                      : <FormattedMessage id="client.detail.doesNotHave" defaultMessage="Não Possui" />}
                  </div>
                </div>
                <div className="col-sm-12 col-md-12" style={{ borderBottom: '1px solid #babbbd' }}>
                  <div className="col-sm-12 col-md-2 block-field">
                    <FormattedMessage id="inputContainer.healthPlan" defaultMessage="Plano de Saúde: " />
                  </div>
                  <div className="col-sm-12 col-md-10 block-value">
                    {secondaryClient.health_plan
                      ? secondaryClient.health_plan
                      : <FormattedMessage id="client.detail.doesNotHave" defaultMessage="Não Possui" />}
                  </div>
                </div>
                <div className="col-sm-12 col-md-12" style={{ borderBottom: '1px solid #babbbd' }}>
                  <div className="col-sm-12 col-md-2 block-field">Hospital:</div>
                  <div className="col-sm-12 col-md-10 block-value">
                    {secondaryClient.hospital
                      ? secondaryClient.hospital
                      : <FormattedMessage id="client.detail.doesNotHave" defaultMessage="Não Possui" />}
                  </div>
                </div>
                <div className="col-sm-12 col-md-12" style={{ borderBottom: '1px solid #babbbd' }}>
                  <div className="col-sm-12 col-md-2 block-field">
                    <FormattedMessage id="inputContainer.limitations" defaultMessage="Limitações: " />
                  </div>
                  <div className="col-sm-12 col-md-10 block-value">
                    {secondaryClient.limitations
                      ? secondaryClient.limitations
                      : <FormattedMessage id="client.detail.doesNotHave" defaultMessage="Não Possui" />}
                  </div>
                </div>
                <div className="col-sm-12 col-md-12" style={{ borderBottom: '1px solid #babbbd' }}>
                  <div className="col-sm-12 col-md-2 block-field">
                    <FormattedMessage id="inputContainer.medications" defaultMessage="Medicações: " />
                  </div>
                  <div className="col-sm-12 col-md-10 block-value">
                    {secondaryClient.medications
                      ? secondaryClient.medications
                      : <FormattedMessage id="client.detail.doesNotHave" defaultMessage="Não Possui" />}
                  </div>
                </div>
                <div className="col-sm-12 col-md-12" style={{ borderBottom: '1px solid #babbbd' }}>
                  <div className="col-sm-12 col-md-2 block-field">
                    <FormattedMessage id="inputContainer.removalPlan" defaultMessage="Plano de Remoção: " />
                  </div>
                  <div className="col-sm-12 col-md-10 block-value">
                    {secondaryClient.removal_plan
                      ? secondaryClient.removal_plan
                      : <FormattedMessage id="client.detail.doesNotHave" defaultMessage="Não Possui" />}
                  </div>
                </div>
              </div>
            </div>
          }
          <div className="block">
            <div className="block-title">LOGS</div>
            <div className="col-12 row" style={{ padding: '1rem 0' }}>
              <div className="col-sm-12 col-md-8" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-3 block-field">
                  <FormattedMessage id="logs.createdDate" defaultMessage="Data de criação" />:
                </div>
                <div className="col-sm-12 col-md-6 block-value">
                  {client.modified_date ? general.formatOnlyNumbersDate(client.created_date) : '-'}
                </div>
              </div>
              <div className="col-sm-12 col-md-4" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-6 block-field">
                  <FormattedMessage id="logs.createdBy" defaultMessage="Criado por" />:
                </div>
                {creator
                  ? <div className="col-sm-12 col-md-6 block-value">{`${creator.first_name} ${creator.last_name}`}</div>
                  : <div className="col-sm-12 col-md-6 block-value"> - </div>
                }
              </div>
              <div className="col-sm-12 col-md-8" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-3 block-field">
                  <FormattedMessage id="logs.modifiedDate" defaultMessage="Data de modificação" />:
                </div>
                <div className="col-sm-12 col-md-6 block-value">
                  {client.modified_date
                    ? general.formatOnlyNumbersDate(client.modified_date)
                    : '-'
                  }
                </div>
              </div>
              <div className="col-sm-12 col-md-4" style={{ borderBottom: '1px solid #babbbd' }}>
                <div className="col-sm-12 col-md-6 block-field">
                  <FormattedMessage id="logs.modifiedBy" defaultMessage="Modificado por" />:
                </div>
                {modifier
                  ? <div className="col-sm-12 col-md-6 block-value">{`${modifier.first_name} ${modifier.last_name}`}</div>
                  : <div className="col-sm-12 col-md-6 block-value"> - </div>
                }
              </div>
            </div>
          </div>
          {userAudioList && userAudioList.length > 0 &&
            <div className="block">
              <div className="block-title">ÚLTIMOS 3 ÁUDIOS</div>
              {userAudioList.map((audio) => 
                <div key={audio.id} className="col-12 row" style={{ padding: '1rem 0' }}>
                  <div className="col-sm-12 col-md-8" style={{ borderBottom: '1px solid #babbbd' }}>
                    <div className="col-sm-12 col-md-3 block-field">
                      <FormattedMessage id="client.detail.audioDate" defaultMessage="Data do áudio" />:
                    </div>
                    <div className="col-sm-12 col-md-6 block-value">
                      {audio.audio_ts ? general.formatOnlyNumbersDate(audio.audio_ts) : '-'}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4" style={{ borderBottom: '1px solid #babbbd' }}>
                    <div className="col-sm-12 col-md-6 block-field">
                      <FormattedMessage id="client.detail.audioUser" defaultMessage="Atendente responsável" />:
                    </div>
                    {audio.user_name 
                      ? <div className="col-sm-12 col-md-6 block-value">
                          {audio.user_name}
                          {isSysAdm &&
                            <a className={style['detail-btn-dl']} style={{ color: 'white', padding: '5px' }} href={audio.audio_s3_file}>
                              <i className="fas fa-download" />
                            </a>
                          }
                        </div>
                      : <div className="col-sm-12 col-md-6 block-value"> - </div>
                    }
                  </div>
                </div>
              )}
            </div>
          }
        </div>
      </div>
    </div>
  );
}

ClientDetail.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  lang: PropTypes.string.isRequired,
  isSysAdm: PropTypes.bool.isRequired,
};

export default ClientDetail;
