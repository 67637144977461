import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Edit from '../../../icons/Edit';

import style from './contactPage.module.scss';

const ContactRow = ({
  contact,
  isAdmin,
  isOwner,
  isManager,
  showModal,
  setTextIconDetail,
  setModalInfo,
}) => (
  <div id={`contact-table-row-container-${contact.priority}`} className={`${style.row} ${contact.is_deleted ? style.removed : ''}`}>
    <div className={`${style.column} ${style.priority}`}>
      {contact.priority}
    </div>
    <Link
      id={`contact-table-body-details-${contact.priority}`}
      className={`${style.actions} ${style.nameBtn}`}
      to={`/app/contacts/detail/${contact.client}/${contact.id}`}
    >
      <div className={`${style.column} ${style.name}`}>
        {`${contact.first_name} ${contact.last_name}`}
        {contact.is_contractor &&
          <i class="fas fa-dollar-sign" style={{ marginLeft: '30px', color: '#415057' }}/>
        }
        {contact.is_family &&
          <i class="fas fa-users-cog" style={{ marginLeft: '30px', color: '#415057' }}/>
        }
      </div>
    </Link>
    <div className={`${(isAdmin || isOwner || isManager) ? style.adminActions : style.otherActions}`}>
      {(isAdmin || isOwner || isManager) &&
        <Link
          id={`contact-table-body-edit-${contact.priority}`}
          className={style.actions}
          onMouseOver={() => setTextIconDetail(<FormattedMessage id="general.columnEdit" defaultMessage="Editar" />)}
          onMouseOut={() => setTextIconDetail('')}
          to={`/app/contacts/form/edit/${contact.client}/${contact.id}`}
          style={{ paddingTop: '0.4rem' }}
        >
          <Edit className={style.icons} width="28" height="28" />
        </Link>
      }
      {(isAdmin || isOwner || isManager) &&
        <button
          id={`contact-table-body-delete-restore-${contact.priority}`}
          onMouseOver={() =>
            setTextIconDetail(contact.is_deleted === false ? <FormattedMessage id="general.columnDeactivate" defaultMessage="Desativar" /> : <FormattedMessage id="general.columnRecover" defaultMessage="Recuperar" />)}
          onMouseOut={() => setTextIconDetail('')}
          onClick={() => {
            if (!showModal) {
              setModalInfo(true, contact);
            }
          }}
          className={style.actions}
          style={{ border: 0, backgroundColor: 'transparent', padding: 0 }}>
          {contact.is_deleted === false ?
            <div className={style.actions}>
              <i className="far fa-trash-alt" style={{ fontSize: 23, color: '#666', paddingTop: 3 }} />
            </div>
            :
            <div className={style.actions}>
              <i className="fas fa-trash-restore-alt" style={{ fontSize: 23, color: '#666', paddingTop: 3 }} />
              {/* <Restore className={style.icons} width="28" height="28" /> */}
            </div>
          }
        </button>}
    </div>
  </div>
  );

ContactRow.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    street: PropTypes.string,
    number: PropTypes.number,
    complement: PropTypes.string,
    district: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    zip_code: PropTypes.string,
    has_keys: PropTypes.bool,
    relationship: PropTypes.string,
    priority: PropTypes.number,
    is_deleted: PropTypes.bool,
    client: PropTypes.number,
    phones: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        phone_type: PropTypes.string,
        phone: PropTypes.string,
        contact: PropTypes.number,
      }),
    ),
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        priority: PropTypes.number,
        contact: PropTypes.number,
        receive_sms: PropTypes.bool,
        receive_email: PropTypes.bool,
      }),
    ),
    created_date: PropTypes.string,
    modified_date: PropTypes.string,
    created_by: PropTypes.number,
    modified_by: PropTypes.number,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool.isRequired,
  isManager: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  setTextIconDetail: PropTypes.func.isRequired,
  setModalInfo: PropTypes.func.isRequired,
};

export default ContactRow;
