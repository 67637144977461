import React from 'react';
import PropTypes from 'prop-types';
import '../../css/contactto.scss';

import CustomChart from './CustomChart';

const ClientByCompanyChart = ({ height, report, width }) => {
  const names = [];
  const values = [];
  const rows = [
    ...report.map((rpt) => {
      names.push(rpt.company);
      values.push(rpt.total);
    }),
  ];

  return (
    <div className="left" style={{ height, width, maxHeight: '600px' }}>
      <CustomChart labels={names} data={values} />
    </div>
  );
};

ClientByCompanyChart.defaultProps = {
  height: '350px',
  width: '350px',
};

ClientByCompanyChart.propTypes = {
  height: PropTypes.string,
  report: PropTypes.arrayOf(
    PropTypes.shape({
      total: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  width: PropTypes.string,
};

export default ClientByCompanyChart;
